// @flow
// 1.21 En kontaktperson med rollen sökande måste ha en epostadress angiven

import { BLOCKING, UPPRATTARE_MASTE_HA_EPOSTADRESS } from './constants'
import { getNamn } from '../../../../utils/contactsHelper'
import { Validation } from './model/Validation'

function createError (invalidContacts): ?Array<Validation> {
  const invalidContactsName = invalidContacts.map(contacts => getNamn(contacts))
  if (invalidContacts) {
    return invalidContactsName.map((name) => (new Validation(UPPRATTARE_MASTE_HA_EPOSTADRESS, `Upprättare ${name} saknar e-postadress.`, 'contacts', BLOCKING, undefined)))
  }
  return undefined
}

const upprattareUtanEpostadress = contact => {
  if (!contact.data.rollUpprattare) {
    return false
  }
  if (contact.type === 'contacts/CONTACT_TYPE_INDIVIDUAL') {
    return !contact.data.epost
  } else if (contact.type === 'contacts/CONTACT_TYPE_ORGANIZATION') {
    return (!contact.data.orgEmail) && (!contact.data.orgKontaktpersonEmail)
  }
  return false
}

function validate (state: Object): ?Array<Validation> {
  const contacts = state.contacts.contactList

  const invalidContacts = contacts.filter(upprattareUtanEpostadress)

  if (invalidContacts.length > 0) {
    return createError(invalidContacts)
  }
  return undefined
}

export default validate
