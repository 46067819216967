import { Dispatch, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { formProperties } from 'redux/formProperties'
import { initFormData } from 'redux/finalForm'
import { Form } from 'react-final-form'
import { createValidator, required } from 'utils/validation'
import FormStateToRedux from 'redux/FormStateToRedux'
import { CheckboxFinalForm, Condition, TextAreaFinalForm, WhenFieldChanges } from '../../General/forms/finalForm'
//@ts-ignore
import { FormFieldset, PageSubContent } from 'redet-react-components'

type SammanlaggningProps = {
  initFormData: (atgard: string) => {}
}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: any) => {
  return { initFormData: (formField: string) => ownProps.initFormData || dispatch(initFormData(formField)) }
}

export let validateSammanlaggningForm = function (values: any) {
  return createValidator(values,
    {
      annatBeskrivning: values?.annat ? [required] : [],
    })
}

function SammanlaggningForm(props: SammanlaggningProps) {
  
  const [_formDataLoaded, setFormDataLoaded] = useState(false);
  const [_initData, setInitData] = useState({});
  
  useEffect(() => {
    const res = props.initFormData(formProperties.sammanlaggning.name)
    setFormDataLoaded(true);
    setInitData(res);
  }, [])

    return (
      <PageSubContent title={"Sammanläggning"} headerLevel={4} id="fastighetsforandring-sammanlaggning" headerId="fastighetsforandring-sammanlaggningHeader">
        <br/>
        <Form
          onSubmit={() => {
          }}
          initialValues={_initData}
          subscription={{ submitting: true, pristine: true }}
          validate={values => validateSammanlaggningForm(values)}>
          {() => {
            return (
              <form onSubmit={() => {
              }}>
                {_formDataLoaded && <FormStateToRedux form={formProperties.sammanlaggning.name}/>}
                <fieldset>
                  <FormFieldset title={'Vad ska den nya fastigheten användas till?'}>
                    {/* @ts-ignore */}
                    <CheckboxFinalForm field={'helarsbostad'} label={'Helårsbostad'}
                                       id={'sammanlaggningHelarsbostadId'}/>
                    {/* @ts-ignore */}
                    <CheckboxFinalForm field={'fritidsbostad'} label={'Fritidsbostad'}
                                       id={'sammanlaggningFritidsbostadId'}/>
                    {/* @ts-ignore */}
                    <CheckboxFinalForm field={'mindreOdling'} id={'sammanlaggningMindreOdlingId'}
                                       label={'Bostad med mark för mindre odling/djurhållning/husbehovsved'}/>
                    {/* @ts-ignore */}
                    <CheckboxFinalForm field={'industriverksamhet'} label={'Industriverksamhet'}
                                       id={'sammanlaggningIndustriverksamhetId'}/>
                    {/* @ts-ignore */}
                    <CheckboxFinalForm field={'skogsbruk'} label={'Jord-/skogsbruk'} id={'sammanlaggningSkogsbrukId'}/>
                    {/* @ts-ignore */}
                    <CheckboxFinalForm field={'annat'} label={'Annat'} id={'sammanlaggningAnnatId'}/>
                    {/* @ts-ignore */}
                    <Condition when={'annat'} is={true}>
                      <TextAreaFinalForm
                        id={'annatBeskrivningId'}
                        field={'annatBeskrivning'}
                        label={'Beskriv vad den nya fastigheten ska användas till'}
                        characterLimit={4000}
                        mandatory={true}
                      />
                    </Condition>
                    <WhenFieldChanges field={'annat'} becomes={false} set={'annatBeskrivning'} to={undefined}/>
                  </FormFieldset>
                </fieldset>
              </form>
            )
          }}
        </Form>
      </PageSubContent>
    )
}


export default connect(undefined, mapDispatchToProps)(SammanlaggningForm)
