import { useRef, useState } from 'react'
import { WizardNavigationButtonToolbar } from '../../../components/wizard/WizardFormElements'
import {
  BIFOGADHANDLINGTYPES,
  MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING,
  MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL,
  MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING
} from '../../../utils/globalConstants'
import { createFileHash, filesEqual, sumAndFormatFileSize } from '../../../helpers/fileHelpers'
//@ts-ignore
import {ButtonTop, Felruta, FormFieldset, ListGroup, ListItem, ListItemActionButton, VarningsRuta} from 'redet-react-components'
import { logInfo } from '../../../utils/log'
import { BIFOGADHANDLINGTYPE_NOT_SELECTED } from 'utils/globalConstants'

type Props = {
  isLastStep: boolean,
  selectFile: (file: File[]) => {},
  removeSelectedFile: (file: File) => {},
  instructionText: string,
  startUpload: () => {},
  clearFileUploadErrorMessages: () => {},
  selectedFiles: File[],
  fileUploadErrorMessages: string[],
  nextStepButtonText: string,
   //Injectas med clone element från WizardDialogStep :sick!
   nextStep?: () => void
   previousStep?: () => void,
   attachmentType: string,
   ansokan?: boolean,
   komplettering?: boolean
}

UploadFilesStep2Wizard.defaultProps = {
  fileUploadErrorMessages: []
}
function UploadFilesStep2Wizard(props: Props) {
  
  const [selectedFiles, setSelectedFiles] = useState<File[]>(props.selectedFiles || []);
  const [warningMessages, setWarningMessages] = useState<string[]>([]);
  const filesSelectedInputRef = useRef<HTMLInputElement>(null);

  function filterFilesByDuplicates (files: File[]) {
    const filteredFiles: File[] = []
    let errorMessage = null
    files.forEach(file => {
      // Only add the files that aren't already added and filter out any duplicated files selected (though this should have been prevented by the OS already)
      if (!(selectedFiles.some(selectedFile => filesEqual(selectedFile, file)) || filteredFiles.some(filteredFile => filesEqual(filteredFile, file)))) {
        filteredFiles.push(file)
      } else {
        errorMessage = 'Du har valt samma fil flera gånger, dubbletter har därför filtrerats bort.'
      }
    })
    return { filteredFiles, errorMessage }
  }

  function filterDistinctFileFormat (files: File[]) {
    // Don't allow mixing PDF- and image-files into the same Bifogad handling
    let filteredFiles: File[] = []
    let errorMessage = null

    const imageFiles = files.filter(matchImageContentTypes())
    const pdfFiles = files.filter(matchPdfContentType())

    // Check if the files selected for upload contains mixed file formats
    if (imageFiles.length > 0 && pdfFiles.length > 0) {
      errorMessage = 'En bifogad handling kan bara innehålla filer av samma typ. Skapa separata bifogade handlingar för att ladda upp filer av olika typ.'
    } else {
      if (selectedFiles && selectedFiles.length > 0) {
        const numberOfImageFilesAlreadyUploaded = selectedFiles.filter(matchImageContentTypes()).length
        const numberOfPdfFilesAlreadyUploaded = selectedFiles.filter(matchPdfContentType()).length

        if (imageFiles.length > 0 && numberOfPdfFilesAlreadyUploaded > 0) {
          errorMessage = 'Det finns redan minst en PDF-fil vald. Skapa en separat bifogad handling för bildfiler.'
        }

        if (pdfFiles.length > 0 && numberOfImageFilesAlreadyUploaded > 0) {
          errorMessage = 'Det finns redan minst en bildfil vald. Skapa en separat bifogad handling för PDF-filer.'
        }
      }
    }

    if (errorMessage === null) {
      filteredFiles = imageFiles.concat(pdfFiles)
    }

    return { filteredFiles, errorMessage }
  }

  function matchPdfContentType () {
    return (file: File) => file.type && file.type === 'application/pdf'
  }

  function matchImageContentTypes () {
    return (file: File) => file.type && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif')
  }

  function filterFilesByType (files: File[]) {
    // Only add files with the right type
    const filteredFiles = files.filter(file => file.type && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'application/pdf'))
    let errorMessage = null

    if (filteredFiles.length !== files.length) {
      errorMessage = 'En eller flera av de filer som du valt har en otillåten filtyp och har därför filterats bort'
    }

    return { filteredFiles, errorMessage }
  }

  function filterFilesByCount (files: File[]) {
    let filteredFiles = files
    let errorMessage = null

    if ((selectedFiles.length + files.length) > MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING) {
      errorMessage = `En bifogad handling får max innehålla ${MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING} filer`
      filteredFiles = files.slice(0, MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING - selectedFiles.length)
      logInfo(`En bifogad handling får max innehålla ${MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING} filer`)
    }

    return { filteredFiles, errorMessage }
  }

  function filterFilesBySize (files: File[]) {
    let filteredFiles = files
    let errorMessage = null
    const maxFileSizeInBytes = MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL * 1024 * 1024

    if (filteredFiles.some(file => file.size > maxFileSizeInBytes)) {
      errorMessage = `En eller fler av de valda filerna har tagits bort eftersom maxstorleken på ${MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL}MB överskreds.`
      filteredFiles = files.filter(file => file.size <= maxFileSizeInBytes)
    }

    return { filteredFiles, errorMessage }
  }

  function removeSelected (file: File) {
    setSelectedFiles([...selectedFiles.filter(selectedFile => selectedFile !== file)])
    if (props.removeSelectedFile)
      props.removeSelectedFile(file)
    if (props.clearFileUploadErrorMessages)
      props.clearFileUploadErrorMessages()
  }

  function fileDropped (event: any) {
    preventDefault(event)
    addFilesToList(event.dataTransfer.files)
  }

  function filterFilesByAccumulatedSize (files: File[]) {
    const filteredFiles: File[] = []
    let errorMessage = null
    const maxTotalFileSizeInBytes = MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING * 1024 * 1024

    files.forEach(file => {
      const alreadyAddedFilesTotalSize = selectedFiles.map(selectedFile => selectedFile.size).reduce((x, y) => x + y, 0)
      const filesAlreadyPassedFilterTotalSize = filteredFiles.map(filePassed => filePassed.size).reduce((x, y) => x + y, 0)
      if ((alreadyAddedFilesTotalSize + filesAlreadyPassedFilterTotalSize + file.size) <= maxTotalFileSizeInBytes) {
        filteredFiles.push(file)
      } else {
        errorMessage = `En eller fler av de valda filerna har tagits bort eftersom maxstorleken på ${MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING}MB överskreds.`
      }
    })

    return { filteredFiles, errorMessage }
  }

  function addFilesToList (files: File[]) {

    setWarningMessages([])
    let filesToAdd = Array.from(files)

    // Remember that the filter order is important for the behaviour
    const filterMethods = [
      filterFilesByDuplicates,
      filterFilesByType,
      filterDistinctFileFormat,
      filterFilesBySize,
      filterFilesByAccumulatedSize,
      filterFilesByCount
    ]
    filterMethods.forEach(filterMethod => {
      const filterResult = filterMethod(filesToAdd)
      filesToAdd = filterResult.filteredFiles

      if (filterResult.errorMessage) {
        setWarningMessages( ([...warningMessages, filterResult.errorMessage]))
        }
    })
    setSelectedFiles([...selectedFiles, ...filesToAdd])
    if (props.selectFile) {
      props.selectFile(filesToAdd)
    }
  }

  function onFileAdded (event: any) {
    if (event.target.files.length > 0) {
      addFilesToList(event.target.files)
      // Clear the input control once we have its data so that it can be reused without any odd behaviour
      event.target.value = null
    }
  }

  function preventDefault (event: any) {
    event.preventDefault()
    event.stopPropagation()
  }

  function warningMessageAboutFiles(text: string) {
    const fileTypeWarning = (props.attachmentType === BIFOGADHANDLINGTYPE_NOT_SELECTED ? '' : text + BIFOGADHANDLINGTYPES.find(handling => handling.value === props.attachmentType)?.visningsnamn);
    return props.attachmentType ? fileTypeWarning : props.attachmentType;
  }

  const nextStep = (selectedFiles.length !== 0 ? () => props.startUpload() : undefined);

    return (
      <>
        <FormFieldset title={'Välj filer'}
                      description={props.instructionText}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div className="file-upload"
               onClick={() => filesSelectedInputRef.current?.click()}
               onDrop={fileDropped}
               onDrag={preventDefault}
               onDragStart={preventDefault}
               onDragEnd={preventDefault}
               onDragOver={preventDefault}
               onDragEnter={preventDefault}
               onDragLeave={preventDefault}>
                            <span className="area">
                                <span className="active">Klicka för att välja eller dra filer hit</span>
                            </span>
            <input id="fileSelectionInput" type="file" ref={filesSelectedInputRef} onChange={onFileAdded}
                   multiple className="multiple-files" aria-label="Ladda upp filer"
                   accept=".gif, .jpg, .jpeg, .png, .pdf" style={{ display: 'none' }}/>
          </div>
        </FormFieldset>
        {(selectedFiles.length > 0) &&
          <ListGroup title={warningMessageAboutFiles('Valda filer för ')} id="selectedBifogadeFiler">
            {selectedFiles.map((file: File, index) => {
              const fileId = createFileHash(file)
              return (
                <ListItem actionButtons={[<ListItemActionButton ariaLabel={'Ta bort'}
                                                                icon={'delete'}
                                                                onClick={() => {removeSelected(file)}}
                                                                id={`${fileId}TaBortKnappId`}
                                                                key={`${fileId}_button_key`}/>]}
                                                                //@ts-ignore
                          description={`${file.name}, ${sumAndFormatFileSize([{ fileSize: file.size }])}`}
                          header={`Fil ${index + 1}`}
                          id={fileId}
                          key={`${fileId}_key`}/>
              )
            })}
          </ListGroup>
        }
        {(selectedFiles.length > 0 && (props.fileUploadErrorMessages.length > 0)) &&
          <Felruta errorMessages={props.fileUploadErrorMessages}/>
        }
        {(warningMessages.length > 0) &&
          <VarningsRuta ariaTitle='Information om validering'>
            {warningMessages.map((warningMessages) =>
              <p key={'key_' + warningMessages.length} className="mandatory">{warningMessages}</p>
            )}
          </VarningsRuta>
        }
        {(selectedFiles.length > 1) &&
            <VarningsRuta ariaTitle={"OBS"}>
              {props.ansokan && 
              <p><strong>OBS!</strong>
              <br/>
                Du har valt att ladda upp mer än en fil till denna handling med typen <strong>{warningMessageAboutFiles('')}</strong>, 
                om du laddar upp mer än en fil så kommer de slås ihop till en handling med samma typ.
                Om du vill ladda upp flera handlingar av annan typ så får du slutföra detta och ladda upp filen eller filerna som en ny separat handling.</p>}
              {props.komplettering && 
              <p><strong>OBS!</strong>
              <br/>
              Du har valt att ladda upp mer än en fil till denna handling med typen <strong>{warningMessageAboutFiles('')}</strong>, 
                om du laddar upp mer än en fil så kommer de slås ihop till en handling med samma typ.
                Om du vill skicka in flera handlingar av annan typ så får du slutföra detta och skicka in filen eller filerna som en separat handling i en ny komplettering.</p>}
            </VarningsRuta>
         }
       {/* @ts-ignore */}
        <WizardNavigationButtonToolbar prevButtonText={'Ändra typ'}
                                       onClickPrevButton={props.previousStep}>
          <ButtonTop id="uploadHandlingButton"
                     text={props.nextStepButtonText}
                     icon={props.isLastStep ? 'check' : 'arrow_forward'}
                     isDisabled={selectedFiles.length === 0}
                     onClick={props.startUpload === undefined ? props.nextStep : nextStep}/>
        </WizardNavigationButtonToolbar>
      </>

    )
  }


export default (UploadFilesStep2Wizard)
