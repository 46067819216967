// @flow
import { BLOCKING, INGA_VALIDERINGSFEL_FINNS_FOR_KONTAKTER } from './constants'
import { CONTACT_TYPE_INDIVIDUAL, CONTACT_TYPE_ORGANIZATION } from '../../contacts'
import {
  organizationContactFormValidations
} from '../../../../views/Contacts/Organization/organizationContactFormValidations'
import {
  individualContactFormValidations
} from '../../../../views/Contacts/Individual/individualContactFormValidations'
import { maste_kompletteras_text } from '../../../../utils/globalConstants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  const hasValidationErrors = function (errors) {
    if (errors === undefined) {
      return false
    }
    return Object.keys(errors)?.length > 0
  }

  const contactList = state.contacts.contactList
  let hasErrors = false
  for (const contact of contactList) {
    if (contact.type === CONTACT_TYPE_ORGANIZATION) {
      const orgErros = organizationContactFormValidations(contact.data, contactList, contact.id, state.validation.errors)
      hasErrors = hasValidationErrors(orgErros)
    }
    if (contact.type === CONTACT_TYPE_INDIVIDUAL) {
      const privErros = individualContactFormValidations(contact.data, contactList, contact.id, state.validation.errors)
      hasErrors = hasValidationErrors(privErros)
    }
    if (hasErrors) {
      break
    }
  }

  if (hasErrors) {
    return new Validation(INGA_VALIDERINGSFEL_FINNS_FOR_KONTAKTER, `Kontaktuppgifter innehåller fel. Se kontaktuppgifter märkta "${maste_kompletteras_text}".`, 'contacts', BLOCKING, undefined)
  }
  return undefined
}

export default validate
