import {ServerValidationError} from './rules/model/ServerValidationError'
import {Validation} from './rules/model/Validation'
import {decorateWithSubmessages, mapToRules} from "./validationService";

export const VALIDATION_RESULT = 'validation/VALIDATION_RESULT'
export const SERVER_VALIDATION_RESULT = 'validation/SERVER_VALIDATION_RESULT'


export type validationState = {
    errors: Validation[],
    webErrors?: Validation[],
    serverErrors?: Validation[],
    validatedOn?: Date
}

const initialState: validationState = {
    errors: [],
    webErrors: [],
    serverErrors: []
}

export function validationResult(validationErrors: Validation[]): Object {
    return {
        type: VALIDATION_RESULT,
        errors: validationErrors
    }
}

export default function validationReducer(state: {
                                              errors: Validation[],
                                              webErrors?: Validation[],
                                              serverErrors?: Validation[]
                                          } = {...initialState},
                                          action: {
                                              type: string;
                                              errors: Validation[] | ServerValidationError[]
                                          }): validationState {
    switch (action.type) {
        case VALIDATION_RESULT: {
            const serverError = state.serverErrors ? state.serverErrors : []
            const allErrors = decorateWithSubmessages([...action.errors as Validation[], ...serverError])
            return {
                ...state,
                errors: allErrors,
                webErrors: action.errors as Validation[],
                validatedOn: new Date()
            }
        }
        case SERVER_VALIDATION_RESULT: {
            const mappedErrors = mapToRules(action.errors as ServerValidationError[])
            const webErrors = state.webErrors ? state.webErrors : []
            const allErrors = decorateWithSubmessages([...webErrors, ...mappedErrors])
            return {
                ...state,
                errors: allErrors,
                serverErrors: mappedErrors,
                validatedOn: new Date()
            }
        }
        default:
            return state
    }
}
