import {
  checkIfCheckboxIsChosen,
  checkIfRadioButtonIsNotChosen,
  createValidator,
  emailRequired
} from '../../../utils/validation'
import { harOmbud } from '../../../utils/contactsHelper'

export function individualContactFormWarningValidation (formValues, contactList, contactId) {
  const individualContactFieldWarningValidation = {
    IDChoice: [],
    saknarBankId: [],
    ombudPersonval: []
  }

  if (formValues.rollSokande && (formValues.rollOmbud === undefined || formValues.rollOmbud === false)) {
    let personHarOmbud = harOmbud(contactList, contactId)
    if (!personHarOmbud) {
      individualContactFieldWarningValidation.IDChoice = [checkIfRadioButtonIsNotChosen('personnummer', 'Företrädare krävs om svenskt personnummer saknas')]
      individualContactFieldWarningValidation.saknarBankId = [checkIfCheckboxIsChosen('Företrädare krävs om BankID saknas')]
      individualContactFieldWarningValidation.epost = [emailRequired('Epost krävs för sökande utan företrädare')]
    }
    if (formValues.rollUpprattare) {
      individualContactFieldWarningValidation.epost = []
    }
  } else if (formValues.rollOmbud && (!formValues.ombudForPersoner || formValues.ombudForPersoner?.length < 1)) {
    individualContactFieldWarningValidation.ombudPersonval = [() => ('En företrädare måste företräda någon')]
  }
  return createValidator(formValues, individualContactFieldWarningValidation)

}