// @flow
// 1.1 En ansökan ska innehålla minst 1 fastighet

import { BLOCKING, MINST_EN_FASTIGHET } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  if (state?.fastigheter?.fastigheterList?.length < 1) {
    return new Validation(MINST_EN_FASTIGHET, 'Du måste välja minst en fastighet.', 'fastighet', BLOCKING, undefined)
  }
  return undefined
}

export default validate
