import {LOAD_SUCCESSFUL} from 'redux/modules/ansokanCRUD';
import {ANSOKAN_FORM_DESTROY} from './sharedActions';
import {
    AutoCompleteOptionsType,
    getAutoCompleteOptions,
    loadFaststighetsinformation,
    loadRegisterbeteckning
} from 'repository/fastighetRepository'
import {Fetcher} from "repository/fetcher";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {
    Fastighet,
    FastighetData,
    Registerbeteckning_Fastighetinfo,
    Registerbeteckning_Regbet,
    TidigareRegisterbeteckning_Fastighetinfo,
    TidigareRegisterbeteckning_Regbet
} from "../../domain/Ansokan";
import {RootState} from 'store';

const REGISTERENHET_SET_SEARCH_STRING = 'registerenhet/SET_SEARCH_STRING';

const REGISTERENHET_AUTOCOMPLETE_LOAD = 'registerenhet/AUTOCOMPLETE_LOAD';
const REGISTERENHET_AUTOCOMPLETE_LOAD_SUCCESS = 'registerenhet/AUTOCOMPLETE_LOAD_SUCCESS';
const REGISTERENHET_AUTOCOMPLETE_LOAD_FAIL = 'registerenhet/AUTOCOMPLETE_LOAD_FAIL';

const REGISTERENHET_LOAD = 'registerenhet/REGISTERENHET_LOAD';
const REGISTERENHET_LOAD_SUCCESS = 'registerenhet/REGISTERENHET_LOAD_SUCCESS';
const REGISTERENHET_LOAD_FAIL = 'registerenhet/REGISTERENHET_LOAD_FAIL';

const LOAD_FASTIGHETSINNEHAV_PENDING = 'registerenhet/LOAD_FASTIGHETSINNEHAV_PENDING';
const LOAD_FASTIGHETSINNEHAV_SUCCESS = 'registerenhet/LOAD_FASTIGHETSINNEHAV_SUCCESS';
const ICKEREGISTRERADE_REGISTERENHET_LOAD_SUCCESS = 'registerenhet/ICKEREGISTRERADE_REGISTERENHET_LOAD_SUCCESS';
const LOAD_FASTIGHETSINNEHAV_FAIL = 'registerenhet/LOAD_FASTIGHETSINNEHAVx§_FAIL';
const SET_FIRST_STEP_FINISHED = 'registerenhet/SET_FIRST_STEP_FINISHED';

export const REGISTERENHET_REMOVE = 'registerenhet/REGISTERENHET_REMOVE';

const initialState = {
    fastighetSearchIsLoading: false,
    fastighetSearchString: '',
    fastighetSearchFeedback: '',
    fastighetAutoCompleteOptions: [],
    fastigheterList: [],
    ickeAgdaFastigheter: [],
    firstStepFinished: false
};

export function mapToFastighetAndSlimDownData(regbet: Registerbeteckning_Regbet): FastighetData {
    function format(beteckning: Registerbeteckning_Regbet) {
        return `${beteckning.registeromrade} ${beteckning.trakt} ${beteckning.block}:${beteckning.enhet}`;
    }

    function mapToTidigareREgbetFastighetinfo(tidigareRegbet: TidigareRegisterbeteckning_Regbet): TidigareRegisterbeteckning_Fastighetinfo {
        return {registeromrade: tidigareRegbet.registeromrade,
            traktnamn: tidigareRegbet.trakt,
            block: tidigareRegbet.block,
            enhet: tidigareRegbet.enhet,
            aktbeteckning: tidigareRegbet.aktbeteckning};
    }

    const fastighetdata: Registerbeteckning_Fastighetinfo = {
        tidigareRegisterbeteckningar: regbet.tidigareRegisterbeteckning ? [mapToTidigareREgbetFastighetinfo(regbet.tidigareRegisterbeteckning)] : [],
        text: regbet.text,
        shortText: regbet.shortText,
        registeromrade: regbet.registeromrade,
        traktnamn: regbet.trakt,
        block: regbet.block,
        enhet: regbet.enhet
    }
    let convertedFastighet : FastighetData= {
        objektidentitet: regbet.objektidentitet,
        registerbeteckning: fastighetdata,
        enhetsomrade: []
    };
    return convertedFastighet;
}

type FastighetFranMongo = {
    "data": {
        "enhetsomrade": [],
        "objektidentitet": string,
        "registerbeteckning": {
            "traktnamn": string,
            "tidigareRegisterbeteckningar": [],
            "shortText": string,
            "registeromrade": string,
            "block": string,
            "text": string,
            "enhet": number
        }
    },
    "registerbeteckning": "HOFORS KALVSNÄS 2:13"
}

export type RegisterbeteckningFranRegbetWrapper = {
    beteckning: Registerbeteckning_Regbet
}

export default function reducer(state: any = initialState, action: any) {
    switch (action.type) {
        case LOAD_SUCCESSFUL:
            return {
                ...state,
                fastigheterList: action.result.data?.fastigheterList ?? [],
                ickeAgdaFastigheter: action.result.data?.ickeAgdaFastigheter ?? [],
                firstStepFinished: action.result.data?.firstStepFinished ?? false,
            };

        case REGISTERENHET_SET_SEARCH_STRING:
            let searchString = '';
            if (action.searchString) {
                searchString = action.searchString.toUpperCase();
            }
            return {
                ...state,
                fastighetSearchString: searchString,
                // Clear any error messages left from the previous search
                fastighetSearchFeedback: ''
            };

        case REGISTERENHET_AUTOCOMPLETE_LOAD_SUCCESS:
            return {
                ...state,
                fastighetAutoCompleteOptions: action.result.options
            };

        case REGISTERENHET_AUTOCOMPLETE_LOAD_FAIL:
            return {
                ...state,
                fastighetAutoCompleteOptions: [],
                fastighetSearchFeedback: action.error,
            };

        case REGISTERENHET_LOAD:
            return {
                ...state,
                fastighetSearchIsLoading: true
            };

        case REGISTERENHET_LOAD_SUCCESS:
            const regbet: Registerbeteckning_Regbet = action.result.beteckning;
            const beteckning = regbet.text;
            const list = [];
            const andraListan = [];
            if (!state.ickeAgdaFastigheter.some((item: Fastighet) => item.registerbeteckning === beteckning)) {
                list.push({registerbeteckning: beteckning, data: mapToFastighetAndSlimDownData(regbet)});
            }

            if (!state.fastigheterList.some((item: Fastighet) => item.registerbeteckning === beteckning)) {
                andraListan.push({registerbeteckning: beteckning, data: mapToFastighetAndSlimDownData(regbet)});
            }

            return {
                ...state,
                fastighetSearchIsLoading: false,
                ickeAgdaFastigheter: [...state.ickeAgdaFastigheter, ...list],
                fastigheterList: [...state.fastigheterList, ...andraListan],
                fastighetSearchString: '',
                fastighetSearchFeedback: '',
            };

        case ICKEREGISTRERADE_REGISTERENHET_LOAD_SUCCESS:
            const regbets: Registerbeteckning_Regbet = action.result.beteckning;
            const betecknings = regbets.text;
            const nyaListan = [];
            if (!state.ickeAgdaFastigheter.some((item: Fastighet) => item.registerbeteckning === betecknings)) {
                nyaListan.push({registerbeteckning: betecknings, data: mapToFastighetAndSlimDownData(regbets)});
            }

            return {
                ...state,
                ickeAgdaFastigheter: [...state.ickeAgdaFastigheter, ...nyaListan],
            };

        case REGISTERENHET_LOAD_FAIL:
            return {
                ...state,
                fastighetSearchIsLoading: false,
                fastighetSearchFeedback: action.error,
            };

        case REGISTERENHET_REMOVE:
            const newFastigheter = state.fastigheterList.filter((currentBeteckning: Fastighet) => {
                return currentBeteckning.registerbeteckning !== action.beteckning;
            });

            const nyLista = state.ickeAgdaFastigheter.filter((currentBeteckning: Fastighet) => {
                return currentBeteckning.registerbeteckning !== action.beteckning;
            });
            return {
                ...state,
                fastigheterList: newFastigheter,
                ickeAgdaFastigheter: nyLista,
            };
        case SET_FIRST_STEP_FINISHED:
            return {
                ...state,
                firstStepFinished: true
            }
        case LOAD_FASTIGHETSINNEHAV_SUCCESS:
            const register: Registerbeteckning_Regbet = action.result.beteckning;
            const registreradFastBeteckning = register.text;
            const nyaRegisteradeFastBeteckningar = [];
            if (!state.fastigheterList.some((item: Fastighet) => item.registerbeteckning === registreradFastBeteckning)) {
                nyaRegisteradeFastBeteckningar.push({
                    registerbeteckning: registreradFastBeteckning,
                    data: mapToFastighetAndSlimDownData(register)
                });
            }
            return {
                ...state,
                fastigheterList: [...state.fastigheterList, ...nyaRegisteradeFastBeteckningar]
            };
        case ANSOKAN_FORM_DESTROY:
            return initialState;

        case LOAD_FASTIGHETSINNEHAV_FAIL:
        case LOAD_FASTIGHETSINNEHAV_PENDING:
            return {
                ...state
            };

        default:
            return state;
    }
}

export function pendingLoadFastighetsinnehav() {
    return {
        type: LOAD_FASTIGHETSINNEHAV_PENDING
    };
}

export function successLoadFastighetsinnehav(result: Registerbeteckning_Regbet) {
    return {
        type: LOAD_FASTIGHETSINNEHAV_SUCCESS,
        shouldTriggerSaveWhenProcessed: true,
        result
    };
}

export function setFirstStepFinished (): any {
    return {
        type: SET_FIRST_STEP_FINISHED,
    }
}

export function failLoadFastighetsinnehav() {
    return {
        type: LOAD_FASTIGHETSINNEHAV_FAIL
    };
}

export function loadingRegisterinformation() {
    return {
        type: REGISTERENHET_LOAD,
    }
}

export function loadFastighetsinformation(beteckning: string) {
    return function action(dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) {
        dispatch(loadingRegisterinformation());
        const fetcher = new Fetcher(dispatch, getState, false);
        return loadFaststighetsinformation(fetcher, beteckning)
            .then(response => {
                return response;
            }).catch(error => {
                throw error;
            });
    }
}

export function loadRegisterbeteckningFunc(beteckning: string, fastighetsInnehav: []) {
    return function action(dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState, false);
        return loadRegisterbeteckning(fetcher, beteckning, fastighetsInnehav)
            .then((response: RegisterbeteckningFranRegbetWrapper) => {
                return response;
            }).catch(error => {
                throw error;
            });
    }
}
export function removeRegisterenhet(beteckning: string) {
    return {
        type: REGISTERENHET_REMOVE,
        beteckning: beteckning,
        shouldTriggerSaveWhenProcessed: true
    };
}

export function setFastighetSearchString(searchString: string) {
    return {
        type: REGISTERENHET_SET_SEARCH_STRING,
        searchString: searchString
    };
}

export function updatingAutoCompleteOptions() {
    return {
        type: REGISTERENHET_AUTOCOMPLETE_LOAD,
    };
}

export function updateAutoCompleteOptionsSuccess(response: AutoCompleteOptionsType | undefined) {
    return {
        type: REGISTERENHET_AUTOCOMPLETE_LOAD_SUCCESS,
        result: response
    };
}

export function updateAutoCompleteOptionsFailed(err: {}) {
    return {
        type: REGISTERENHET_AUTOCOMPLETE_LOAD_FAIL,
        error: err
    };
}


export function updateAutoCompleteOptions(searchString: string, signal: any) {
    return function action(dispatch: ThunkDispatch<any, any, any>, getState: () => {}) {
        dispatch(updatingAutoCompleteOptions());
        const fetcher = new Fetcher(dispatch, getState, false);
        return getAutoCompleteOptions(fetcher, searchString, signal)
            .then(response => {
                dispatch(updateAutoCompleteOptionsSuccess(response));
            })
            .catch(error => {
                dispatch(updateAutoCompleteOptionsFailed(error));
            });
    }
}

export function fetchAutoCompleteOptions(searchString: string, signal?: any) {
    return function action(dispatch: ThunkDispatch<any, any, any>, getState: () => {}) {
        const fetcher = new Fetcher(dispatch, getState, false);
        return getAutoCompleteOptions(fetcher, searchString, signal)
        .then(response => response?.options)
        .catch(error => {
            throw new Error(error)
        })
    }
}

export function pendingAnnanFastighet(result: Object) {
    return {
        type: REGISTERENHET_LOAD,
        result
    };
}

export function successAnnanFastighet(result: Object, beteckning: string) {
    return {
        type: REGISTERENHET_LOAD_SUCCESS,
        shouldTriggerSaveWhenProcessed: true,
        result: result,
        beteckning: beteckning,
    };
}

export function ickeReggadFastighet(result: Object, beteckning: string) {
    return {
        type: ICKEREGISTRERADE_REGISTERENHET_LOAD_SUCCESS,
        shouldTriggerSaveWhenProcessed: true,
        result: result,
        beteckning: beteckning,
    };
}

export function failAnnanFastighet(error: Object) {
    return {
        type: REGISTERENHET_LOAD_FAIL,
        error
    };
}
