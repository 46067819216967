import classNames from "classnames";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnsokanWizardSDGContext } from "./AnsokanWizardSDGContext";
import { useTranslation } from "react-i18next";

type LinkIconProps = {
  to: string;
  icon: string;
  text: string;
  disabled?: boolean;
};

export default function LinkButton(props: LinkIconProps) {

  return (
    <Link
      className={classNames("button-top wizard-step", {
        disabled: props.disabled ? true : false,
      })}
      to={props.to}
      tabIndex={ props.disabled ? -1 : 0}>
      <i className="material-icons">{props.icon}</i>
      {props.text}
    </Link>
  );
}

type WizardSectionLink = {
  text: string,
  sektion: string,
  active?: boolean,
  disable?: boolean
}

export function WizardLinks() {

  const location = useLocation();
  const { fastigheter, ansokan } = useContext(AnsokanWizardSDGContext);
  const url = '/euCitizenApplication';

  const WizardSectionLink = (props: WizardSectionLink) => {
    return (
      <li
        className={classNames({
          active: location.pathname.endsWith(props.sektion),
          disabled: props.disable,
        })}>
        <Link
          to={`${url}/${props.sektion}`}
          aria-disabled={props.disable}
          tabIndex={props.disable ? -1 : 0}
          id={`sektion${props.sektion}`}>
          {props.text}
        </Link>
      </li>
    );
  };

  const { t } = useTranslation();

  return (
    <ul className="step-visualization full-width" id="wizardForumlarDelarId">
      <WizardSectionLink sektion={'pdfApplication'} text={t('wizard.application')}/>
      <WizardSectionLink sektion={'additionalDocuments'} text={t('wizard.additionalDocuments')} disable={!ansokan}/>
      <WizardSectionLink sektion={'realEstates'} text={t('wizard.property')} disable={!ansokan}/>
      <WizardSectionLink sektion={'reviewAndSubmit'} text={t('wizard.submit')} disable={(ansokan && fastigheter.length > 0) ? false : true}/>
    </ul>
  );
}
