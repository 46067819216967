//@ts-ignore
import { SidePanel } from 'redet-react-components'
import {connect, useSelector} from 'react-redux'
import { hide, show, toggle } from '../../redux/modules/help'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import {useEffect, useRef} from "react";
import {RootState} from "../../store";

const mapStateToProps = (store: StoreType) => {
  return {
    chapter: store.help.moment,
    section: store.help.term,
    visaHjalp: store.help.visible,
    compact: store.help.compact
  }
}

type StoreType = {
  help: {
      moment: string,
      term: string,
      visible: boolean,
      compact: boolean
  }
}

const mapDispatchToProps = {
  hide, show, toggle
}

type Props = {
    chapter: string,
    section?: string,
    visaHjalp: boolean,
    toggle?: any,
    compact?: boolean,
    attachToDomElementWithId?: string,
    children?: JSX.Element[]
}

export function Hjalp ({
  chapter,
  section,
  visaHjalp,
  toggle: toggleOpenClosed,
  compact,
  attachToDomElementWithId,
  children
}: Props) {

  const { t } = useTranslation();

  const panelRef = useRef<SidePanel>(null);
  const help = useSelector((store: RootState) => store.help)

  useEffect(() =>{
      if(chapter && section)
          panelRef.current?.scrollIntoView()
  }, [help])

  function toggleKeepOpen () {
    toggleOpenClosed()
  }

  const helpElement = <>
    <SidePanel ref={panelRef}
               panelTitle={t('help.title')}
               panelTitleHeaderLevel={2}
               ariaLabel={t('help.aria')}
               isOpen={visaHjalp}
               id={'helpSidePanelId'}
               sidePanelChapterId={chapter}
               sidePanelSectionIdActive={section}
               isCompact={compact}
               panelButton={{ onClick: () => toggleKeepOpen(), ariaLabel: t('help.panelCloseOpenButtonAriaLabel') }}
               closeButton={{ onClick: () => toggleKeepOpen() }}>
      {children}</SidePanel>

  </>
  return attachToDomElementWithId ? ReactDOM.createPortal(helpElement, document.getElementById(attachToDomElementWithId)!) : helpElement
}

export default connect(mapStateToProps, mapDispatchToProps)(Hjalp)
