import React, {useEffect, useState} from "react"
// @ts-ignore
import { CircleLoading, ModalDialogSub, PageTopContent,
    Accordion,
    Felruta,
    ModalDialogAlert
} from 'redet-react-components'
import {ArendeStatus} from "../../index"
import {useSelector} from "react-redux"
import {RootState} from "../../../store"
import {ArendeBasInfoView} from "./ArendeBasInfoView"
import {ArendeHandlaggareInfoView} from "./ArendeHandlaggareInfoView"
import {useAppDispatch} from "../../../hooks"
import {
    ArendeNotFoundException,
    BehorighetSaknasException,
    LoadArendeRequest
} from "../../../repository/arendeRepository"
import {useLocation, useNavigate} from "react-router-dom"
import {addProgress, endProgress} from "../../../redux/modules/fullscreenLoading"
import {loadArende} from "../../../redux/modules/arende"
import {logError} from "../../../utils/errorHandler"
import {unloadArende} from "../../../redux/modules/arendecommon"
import KompletteraArendeView from "./komplettering/KompletteraArendeView"
import {loadSvarslistor, setArendeAvslutatState} from "../../../redux/modules/arendesvarslistor"
import {ARENDE_STATUS} from "../../../utils/globalConstants"
import {fetchKompletteringar} from "../../../redux/modules/arendeKompletteringar"
import {Svarslista, Svarslistor} from "../../../domain/Svarslistor"
import ArendeHandlingarList from "../ArendeHandlingarList"
import ArendeDagboksanteckningarList from "../ArendeDagboksanteckningarList"
import AvslutadeKompletteringarList from "./komplettering/AvslutadeKompletteringarList"
import AktivaKompletteringarList from "./komplettering/AktivaKompletteringarList"
import BekraftaDelgivningView from "./komplettering/BekraftaDelgivningView";
import {KompletteringAtgard} from "../../../domain/Forratningsarende/KompletteringAtgard";
import {SersamArendeStatus} from "../SersamArendeStatus";
import {Arendetyp} from "../../../domain/Arende";
import {scrollToElementNative} from "../../../utils/scrollToElement";
import AterkallaAnsokanView from "./komplettering/AterkallaAnsokanView";
import {ArendeViewContext} from "./ArendeViewContext";
import {useFeatureToggle} from "../../../hooks/useFeatureToggle";

interface Props {
    arendenummer: string,
    asHandlaggare: boolean
}

function ArendeView({asHandlaggare, arendenummer}: Props) {

    const {showCloseModalConfirmationDialog, setShowCloseModalConfirmationDialog, onConfirmClose} = React.useContext(ArendeViewContext);

    const [showArendeNotFoundModal, setShowArendeNotFoundModal] = useState(false)
    const [showBehorighetSaknasModal, setShowBehorighetSaknasModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [fetchAktiviteterErrorMessage, setFetchAktiviteterErrorMessage] = useState<Error>()

    const arende = useSelector((store: RootState) => store.arende?.arende)
    const isLoadingArende = useSelector((store: RootState) => store.arende?.isLoading)
    const user = useSelector((store: RootState) => store.auth.user)
    const shouldDisplayAtgarder = useSelector((store: RootState) => store.arende?.shouldDisplayAtgarder)
    const svarslistor: Svarslistor = useSelector((store: RootState) => store.arende?.svarslistor)
    const delgivningBekraftelseAtgard: KompletteringAtgard = useSelector((store: RootState) => store.arende?.delgivningBekraftelseAtgard)

    const aterkallandeKompletteringAtgard: KompletteringAtgard = useSelector((store: RootState) => store.arende?.aterkallandeKompletteringAtgard)
    const aterkallandeToggle: boolean = useFeatureToggle('aterkallandeToggle')

    /* aktiviteter */
    const kompletteringar = useSelector((store: RootState) => store.arendeKompletteringar.list)
    const isLoadingKompletteringar = useSelector((store: RootState) => store.arendeKompletteringar.isLoadingList)
    const besvaradeSvarslistor: Svarslista[] = useSelector((store: RootState) => store.arende?.svarslistor?.besvarade ?? [])
    const isLoadingSvarslistor: boolean = useSelector((store: RootState) => store.arende?.svarslistorIsLoading)

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch()

    const [firstPartInPath, setFirstPartInPath] = useState("privat")

    useEffect(() => {
        fetchArende()
        return () => {
            dispatch(unloadArende())
        }
    }, [])

    useEffect(() => {
        if (location.hash && !isLoadingArende &&  !isLoadingSvarslistor && !isLoadingKompletteringar) {
            scrollToElementNative(location.hash.substring(1))
        }
    }, [isLoadingArende, isLoadingSvarslistor, isLoadingKompletteringar, location.hash]);

    useEffect(() => {
        if (arende && !isHandlaggareOrOrganization() && !isArendeClosed()) {
            dispatch(loadSvarslistor(arendenummer))
        }
        if(arende && shouldDisplayAtgarder) {
            fetchAktiviteter()
        }
        if (arende && isArendeClosed()) {
            dispatch(setArendeAvslutatState())
        }
    }, [arende])

    useEffect(() => {
        setFirstPartInPath(location.pathname.split("/").filter(dummy => dummy)[0])
    }, [location]);

    const isHandlaggareOrOrganization = () => asHandlaggare || user?.isOrganization
    const isSERSAM = () => arende?.fastighetsArendeOversikt.arendetyp === Arendetyp.SERSAM
    const isArendeClosed = () => arende?.fastighetsArendeOversikt.status === ARENDE_STATUS.REGISTRERING || arende?.fastighetsArendeOversikt?.status === ARENDE_STATUS.SLUTFORT
    const completedActivitiesExists = () => ((kompletteringar.klara && kompletteringar.klara.length > 0) || (besvaradeSvarslistor && besvaradeSvarslistor.length > 0))

    const displayDelgivning = (): boolean => {
        if (isArendeClosed())
            return false
        if (svarslistor && svarslistor.obesvarade?.length > 0)
            return true
        return delgivningBekraftelseAtgard && (delgivningBekraftelseAtgard.active || delgivningBekraftelseAtgard.inactiveReasons.length === 0 || delgivningBekraftelseAtgard.inactiveReasons.length > 0)
    }

    function fetchArende(): void {
        const userIsOrg = !!user?.isOrganization
        const arendeRequest = new LoadArendeRequest(arendenummer, asHandlaggare)
        dispatch(addProgress())
        dispatch(loadArende(arendeRequest, userIsOrg))
            .catch((error: Error) => {
                handleFetchArendeError(error)
            })
            .finally(() => {
                dispatch(endProgress())
            })
    }

    //queue
    let _fetchAktiviteterCall = Promise.resolve()
    const fetchAktiviteter = async () => {
        _fetchAktiviteterCall = _fetchAktiviteterCall.then(() => dispatch(fetchKompletteringar(arendenummer))
            .catch((err: Error) => {
                setFetchAktiviteterErrorMessage(err)
            }))
        await _fetchAktiviteterCall
    }

    function handleFetchArendeError(error: Error) {
        switch (error.constructor) {
            case ArendeNotFoundException:
                setShowArendeNotFoundModal(true)
                break
            case BehorighetSaknasException:
                setShowBehorighetSaknasModal(true)
                break
            default:
                logError(error, 'ArendeView-Component')
                setShowErrorModal(true)
        }
    }

    return <>
        {arende && <>
            <h1>Pågående ärende</h1>
            {/*status*/}
            {isSERSAM() ?
                <SersamArendeStatus currentStatus={arende.fastighetsArendeOversikt.status}/> :
                <ArendeStatus currentStatus={arende.fastighetsArendeOversikt.status}/>}
            {/*basinfo*/}
            <PageTopContent title={`Ärende ${arendenummer}`}
                            headerLevel={2}
                            headerId={"arendeHeaderId"}
                            id={'section-arende-top-content'}>
                <ArendeBasInfoView arendeOversikt={arende.fastighetsArendeOversikt}/>
                <hr/>
                <h3>Handläggare</h3>
                <ArendeHandlaggareInfoView arendeOversikt={arende.fastighetsArendeOversikt}/>
            </PageTopContent>
            <div className={'panel-group blind'}>
                {/*om anv. är privatperson, visa aktiviteter*/}
                {shouldDisplayAtgarder && <>
                    {/*i sersam ärenden kan man inte komplettera ärendet*/}
                    {!isSERSAM() && <>
                        <KompletteraArendeView arendenummer={arendenummer} arendestatus={arende.fastighetsArendeOversikt.status}/>
                        <hr/>
                    </>}
                    {aterkallandeKompletteringAtgard.active && aterkallandeToggle === true && <>
                        <AterkallaAnsokanView arendenummer={arendenummer}/>
                        <hr/>
                    </>}
                    {displayDelgivning() && <>
                        <BekraftaDelgivningView arendenummer={arendenummer} arendestatus={arende.fastighetsArendeOversikt.status}/>
                    </>}
                </>}
            </div>
            {shouldDisplayAtgarder && kompletteringar.pagaende && kompletteringar.pagaende.length > 0 && <>
                {/*pågående aktiviter*/}
                <Accordion lang={'sv'} title={'Pågående aktiviteter'} id={"pagaende-aktiviteter"} isExpanded={true} data-selenium-id='accordion-pagaende-aktiviteter'>
                    <AktivaKompletteringarList id='arende-pagaende-aktiviteter-list'
                                               kompletteringar={kompletteringar.pagaende}
                                               isReloadingList={isLoadingKompletteringar}
                                               onRefetch={() => fetchAktiviteter()}/>
                    {isLoadingKompletteringar && <CircleLoading isFocusTrapped={false} text={"Laddar pågående aktiviteter..."}/>}
                </Accordion>
            </>}
            {/*handlingar (visas inte för SERSAM)*/}
            {!isSERSAM() && <>
                <Accordion lang={'sv'} title={'Publicerade handlingar'} id={"publicerade-handlingar"} data-selenium-id='accordion-publicerade-handlingar'>
                    <ArendeHandlingarList handlingar={arende.documentDetails}
                                          arendenummer={arende.fastighetsArendeOversikt?.nummer}
                                          asHandlaggare={asHandlaggare}/>
                </Accordion>
            </>}
            {/*dagbok (visas inte för SERSAM)*/}
            {!isSERSAM() && <>
                <Accordion lang={'sv'} title={'Dagbok'} id={"dagbok"} data-selenium-id='accordion-dagbok'>
                    <ArendeDagboksanteckningarList dagboksanteckningar={arende.fastighetsArendeDagbok?.lantmatarDagbokAnteckning}/>
                </Accordion>
            </>}
            {shouldDisplayAtgarder && completedActivitiesExists() && <>
                {/*pågående aktiviter, komplettering och besvarade svarlistor*/}
                <Accordion lang={'sv'} title={'Slutförda aktiviteter'} id={"slutforda-aktiviteter"} data-selenium-id='accordion-slutforda-aktiviteter'>
                    <AvslutadeKompletteringarList id='arende-klara-aktiviteter-list'
                                                  kompletteringar={kompletteringar.klara}
                                                  svarslistor={besvaradeSvarslistor}/>
                </Accordion>
            </>}
            {shouldDisplayAtgarder && fetchAktiviteterErrorMessage && <>
                <Felruta
                    errorMessages={['Ett fel uppstod vid laddning av aktiviteter. Vänligen försök igen om en stund, om felet skulle kvarstå kontakta Lantmäteriets kundcenter.']}/>
                <br/>
            </>}
        </>}

        {/*error dialogs*/}
        {showArendeNotFoundModal &&
                <ModalDialogSub modalTitle="Ärendet kunde inte öppnas"
                              description="Antingen så finns det inget ärende med detta ärendenummer eller så har du inte rättighet att visa det."
                              closeModalCallback={() => navigate(`/${firstPartInPath}`)}/>
        }
        {showBehorighetSaknasModal &&
            <ModalDialogSub modalTitle="Behörighet saknas"
                            description="Du saknar behörighet att visa det här ärendet"
                            closeModalCallback={() => navigate(`/${firstPartInPath}`)}/>}
        {showErrorModal &&
            <ModalDialogAlert
                modalTitle={'Tekniskt fel'}
                ingress={'Ett tekniskt fel uppstod när ärendet skulle visas.'}
                description={<span>Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
                    href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>}
                acceptButton={{
                    text: 'Ok', icon: 'check',
                    onClick: (): any => navigate(`/${firstPartInPath}`)
                }}/>
        }

        {showCloseModalConfirmationDialog &&
            <ModalDialogAlert modalTitle={'Vill du verkligen avsluta?'}
                              ingress={'Om du avslutar kommer alla ändringar att försvinna'}
                              declineButton={{
                                  text: 'Nej', icon: 'clear',
                                  //@ts-ignore
                                  onClick: () => setShowCloseModalConfirmationDialog(false)
                              }}
                              acceptButton={{
                                  text: 'Ja', icon: 'check',
                                  //@ts-ignore
                                  onClick: () => {
                                      onConfirmClose()
                                      setShowCloseModalConfirmationDialog(false)
                                  }
                              }}/>
        }
    </>
}

export default ArendeView