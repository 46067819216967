//@ts-ignore
import {SidePanelChapter} from 'redet-react-components'
import {arendeAktiviteterHelpContent} from '../../../views/Hjalp/texter/arenden/ArendeAktiviteterHelpContent'
import {KontaktaOssHelpContent} from '../../../views/Hjalp/texter/KontaktaOssHelpContent'
import {PersonuppgifterHelpContent} from '../../../views/Hjalp/texter/PersonuppgifterHelpContent'
import {BifogadeHandlingarArendeHelpContent} from './BifogadeHandlingarArendeHelpContent'
import Hjalp from '../../../views/Hjalp/Hjalp'
import {FeedbackHelpContent} from 'views/Hjalp/texter/FeedbackHelpContent'
import {ArendestatusHelpContent} from "../../../views/Hjalp/texter/arenden/ArendestatusHelpContent";
import {useFeatureToggle} from "../../../hooks/useFeatureToggle";

export function ArendeHjalp () {
  const aterkallandeToggle: boolean = useFeatureToggle('aterkallandeToggle')
  return <Hjalp>
    <SidePanelChapter name={'help-status'}>
      {ArendestatusHelpContent}
    </SidePanelChapter>
    <SidePanelChapter name={'help-upload'}>
      {BifogadeHandlingarArendeHelpContent}
    </SidePanelChapter>
    <SidePanelChapter name={'help-komplettering'}>
      {arendeAktiviteterHelpContent(aterkallandeToggle)}
    </SidePanelChapter>
    <SidePanelChapter name={'help-support'}>
      <KontaktaOssHelpContent/>
    </SidePanelChapter>
    <SidePanelChapter name={'help-personuppgift'}>
      <PersonuppgifterHelpContent/>
    </SidePanelChapter>
    <SidePanelChapter name={'help-feedback'}>
      <FeedbackHelpContent/>
    </SidePanelChapter>
  </Hjalp>
}
