import Log from 'utils/log'
import { Snap } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { Collection } from 'ol'

export default class SnapInteraction {
  constructor (mapController, interactionDestructors, featureHandler, ol) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.interactionDestructors = interactionDestructors
    this.featureHandler = featureHandler
    this.ol = ol
  }

  init () {
    const features = new Collection()
    features.extend(this.featureHandler.getDrawingFeatures().getArray())
    features.extend(this.featureHandler.getFastighetFeatures().getArray())

    const onAdd = this.featureHandler.getDrawingFeatures().on('add', (event) => {
      features.push(event.element)
    })

    const onRemove = this.featureHandler.getDrawingFeatures().on('remove', (event) => {
      features.remove(event.element)
    })

    const interaction = new Snap({
      features: features
    })

    this.interactionDestructors.push(() => {
      this.log.debug('Unregister SNAP interaction')
      this.mapController.getMap().removeInteraction(interaction)
      unByKey(onAdd)
      unByKey(onRemove)
    })

    this.log.debug('Register SNAP interaction')
    this.mapController.getMap().addInteraction(interaction)
  }
}
