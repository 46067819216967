import React, {useEffect, useState} from "react";
import {BifogaHandlingContext} from "../BifogaHandlingContext";
import {createFileHash} from "../../../../helpers/fileHelpers";
// @ts-ignore
import {ButtonGroup, ButtonToolbarV2, ButtonTop, FormFieldset, ListGroup, ListItem, VarningsRuta} from "redet-react-components";

type Props = {
    onConfirm: () => void
    //Injectas med clone element från WizardDialogStep :sick!
    previousStep?: () => void
}

export function BifogaHandlingConfirmStep({onConfirm, previousStep}: Props) {

    const {handlingar, validateForm} = React.useContext(BifogaHandlingContext)
    const [validationMessages, setValidationMessages] = useState<string[]>([])

    useEffect(() => {
        setValidationMessages(validateForm() ?? [])
    }, []);

    function onConfirmClick() {
        if (validationMessages.length === 0) {
            onConfirm()
        }
    }

    return (<>
        <FormFieldset title={'Bestyrk handling'}
                      description={'Genom att skriva under med e-legitimation försäkrar du på heder och samvete att handlingen som du bifogat överensstämmer med originalet. ' +
                          'När handlingen är bestyrkt kommer den att skickas in automatiskt.'}>

            <ListGroup title={'Följande filer kommer ingå i handlingen'}
                       id={'confirm-file-list'}
                       headerLevel={4}>
                {handlingar.map(handling => {
                    const id = createFileHash(handling)
                    return <ListItem header={handling.name}
                                     id={id}
                                     key={`confirm-handling-${id}-key`}
                                     hasTableActionButtons={false}/>
                })}
            </ListGroup>
        </FormFieldset>
        {/*navigeringsknappar*/}
        <ButtonToolbarV2>
            <ButtonGroup>
                <ButtonTop text={'Ändra epost'}
                           onClick={previousStep}
                           icon={'arrow_back'}
                           id={'previous-step-button'}
                           ariaLabel={'Tillbaka till epost'}/>
            </ButtonGroup>
            <ButtonGroup>
                <ButtonTop text={'Bestyrk handling'}
                           onClick={() => onConfirmClick()}
                           isActive={validationMessages && validationMessages.length === 0}
                           icon={'create'}
                           id={'confirm-and-sign-button'}
                           ariaLabel={'Påbörja bestyrkan av handling'}/>
            </ButtonGroup>
        </ButtonToolbarV2>
        {/*validering, borde aldrig visas då varje steg validerar i sig..*/}
        {validationMessages && validationMessages.length > 0 && <>
            <br/>
            <VarningsRuta ariaTitle={'Felmeddelande'} errorMessages={validationMessages}/>
        </>}
    </>)
}