import Log from 'utils/log'
import { Pointer } from 'ol/interaction'

export default class DeleteInteraction {
  constructor (mapController, featureHandler, setCursorFunc, ol, interactionDestructors) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.featureHandler = featureHandler
    this.setCursorFunc = setCursorFunc
    this.ol = ol
    this.interactionDestructors = interactionDestructors
  }

  init () {
    const interaction = new Pointer({
      'handleDownEvent': (mapBrowserEvent) => {
        const clickedOnFeature = mapBrowserEvent.map.forEachFeatureAtPixel(mapBrowserEvent.pixel, (feature, layer) => {
          return (layer ? feature : null)
        })

        if (clickedOnFeature) {
          this.featureHandler.getDrawingFeatures().remove(clickedOnFeature)
        }
      }
    })

    this.interactionDestructors.push(() => {
      this.log.debug('Unregister DELETE interaction')
      this.mapController.getMap().removeInteraction(interaction)
      this.setCursorFunc('auto')
    })

    this.log.debug('Register DELETE interaction')
    this.mapController.getMap().addInteraction(interaction)

    this.setCursorFunc('pointer')
  }
}
