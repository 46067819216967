const NEXT_STEP = 'wizardDialog/NEXT_STEP'
const PREVIOUS_STEP = 'wizardDialog/PREVIOUS_STEP'
const GO_TO_STEP = 'wizardDialog/GO_TO_STEP'
// const SET_STEP_INDEX = 'wizardDialog/SET_STEP_INDEX';

const initialState = {
  currentStepIndex: 0,
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case NEXT_STEP:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
      }
    case PREVIOUS_STEP:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex - 1,
      }
    case GO_TO_STEP:
      return {
        ...state,
        currentStepIndex: action.step,
      }
    default:
      return state
  }
}

export function displayNextStep () {
  return {
    type: NEXT_STEP,
  }
}

export function displayPreviousStep () {
  return {
    type: PREVIOUS_STEP,
  }
}

export function goToStep (step) {
  return {
    type: GO_TO_STEP,
    step,
  }
}
