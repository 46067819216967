import {ReactNode} from "react";

export const PageHeader = (props: { title?: ReactNode | string, introduction?: ReactNode, children?: ReactNode }) =>
  <div className="page-header" id={'startPageTopPageHeaderId'}>
    <div className="container">
      {props.title ? <h1>{props.title}</h1> : <h1>Fastighets&shy;bildning</h1>}
      {props.introduction ? props.introduction : <></>}
      {props.children}
    </div>
  </div>
