import {LOAD_SUCCESSFUL as ANSOKAN_LOAD_SUCCESSFUL} from './ansokanCRUD'
import {REGISTERENHET_REMOVE} from './fastigheter'
import {ANSOKAN_FORM_DESTROY} from 'redux/modules/sharedActions'
import {v4 as uuidv4} from 'uuid'
import {
    getFortradareNamnForContact,
    removeContactFromForetradare
} from '../../utils/contactsHelper'

export const ACTION_SAVE_CONTACT = 'contacts/ACTION_SAVE_CONTACT'
export const ACTION_REMOVE_CONTACT = 'contacts/ACTION_REMOVE_CONTACT'
export const ACTION_CREATE_CONTACTS = 'contacts/ACTION_CREATE_CONTACTS'
export const CONTACT_TYPE_INDIVIDUAL = 'contacts/CONTACT_TYPE_INDIVIDUAL'
export const CONTACT_TYPE_ORGANIZATION = 'contacts/CONTACT_TYPE_ORGANIZATION'
export const DISPLAY_MODAL_CONTACT_FORM = 'contact/DISPLAY_MODAL_CONTACT_FORM'
export const USER_CONFIRM_AMOUNT_OF_SOKANDE_IS_INCORRECT = 'contact/USER_CONFIRM_AMOUNT_OF_SOKANDE_IS_INCORRECT'

const initialState = {
    contactList: [],
    showContactModal: false,
    userHasConfirmedAmountOfSokandeIsNotCorrect: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ANSOKAN_LOAD_SUCCESSFUL:
            return {
                ...state,
                contactList: action.result.data.contactList,
                userHasConfirmedAmountOfSokandeIsNotCorrect: action.result?.data?.userHasConfirmedAmountOfSokandeIsNotCorrect === undefined ? false : action.result?.data?.userHasConfirmedAmountOfSokandeIsNotCorrect,
            }

        case ACTION_SAVE_CONTACT:
            let result = null


            let fieldsCopy = JSON.parse(JSON.stringify(action.fields));
            const contactId = fieldsCopy.id
            const contactType = fieldsCopy.type
            const contactIdentifier = fieldsCopy.identifier
            delete fieldsCopy.identifier
            delete fieldsCopy.id
            delete fieldsCopy.type

            // Create new array with all of the other contacts plus an updated copy of the one which was updated
            if (contactId !== undefined) {
                result = [
                    ...state.contactList.filter((contact) => { return contact.id !== contactId }), 
                    {
                        id: contactId, 
                        type: contactType, 
                        identifier: contactIdentifier, 
                        data: fieldsCopy
                    }
                ]
            } else {
                // Create new array with all of the existing contacts plus the one added
                result = [
                    ...state.contactList,
                    {
                        id: action.newUniqueId,
                        type: contactType,
                        identifier: contactIdentifier,
                        data: fieldsCopy
                    }
                ]
            }

            return {
                ...state,
                contactList: result,
                showContactModal: false,
            }

        case ACTION_REMOVE_CONTACT:
            let contactList = [...state.contactList.filter((obj) => {
                return obj.id !== action.contact.id
            })]

            if (getFortradareNamnForContact(contactList, action.contact.id) !== '') {
                const _copyContacts = JSON.parse(JSON.stringify(contactList))
                contactList = removeContactFromForetradare(_copyContacts, action.contact.id)
            }

            return {
                ...state,
                contactList,
            }

        case ACTION_CREATE_CONTACTS:

            // Vackert...
            let copyContacts = JSON.parse(JSON.stringify(state.contactList))

            let existingIdentifierare = new Set(copyContacts
                .map(contact => contact.identifier)
                .filter(identifier => typeof (identifier) !== 'undefined'))


            action.contacts.forEach(actionContact => {
                copyContacts.forEach(sc => {
                    if (sc.identifier === actionContact.identifier) {
                        sc.data.rollSokande = true
                        sc.data.imported = true
                        sc.data.rollAnnanBerord = false
                        sc.data.representerarFastigheter = actionContact.data.representerarFastigheter
                        sc.data.adress = actionContact.data.adress
                        sc.data.postnummer = actionContact.data.postnummer
                        sc.data.postort = actionContact.data.postort
                    }
                })
            })

            return {
                ...state,
                contactList: [
                    ...copyContacts,
                    ...action.contacts.filter(contact => !existingIdentifierare.has(contact.identifier))
                ]
            }

        case ANSOKAN_FORM_DESTROY:
            return initialState

        case REGISTERENHET_REMOVE:
            return {
                ...state,
                // Find any contact that is using the removed fastighet and remove it from the list
                contactList: state.contactList.map((contact) => {
                    return {
                        ...contact, data:
                        {
                            ...contact.data,
                            representerarFastigheter: contact.data.representerarFastigheter ? contact.data.representerarFastigheter.filter(contact2 => contact2.registerbeteckning !== action.beteckning) : []
                        }
                    }
                })
            }

        case DISPLAY_MODAL_CONTACT_FORM:
            return {
                ...state,
                showContactModal: action.show
            }
        case USER_CONFIRM_AMOUNT_OF_SOKANDE_IS_INCORRECT:
            return  {
                ...state,
                userHasConfirmedAmountOfSokandeIsNotCorrect: action.choice
            }

        default:
            return state
    }
}

export function displayContactForm(show) {
    return {
        type: DISPLAY_MODAL_CONTACT_FORM,
        show: show
    }
}

export function setUserHasConfirmedAmountOfSokandeIsNotCorrect (choice) {
    if (choice) {
        return {
            type: USER_CONFIRM_AMOUNT_OF_SOKANDE_IS_INCORRECT,
            choice: true
        }
    } else {
        return {
            type: USER_CONFIRM_AMOUNT_OF_SOKANDE_IS_INCORRECT,
            choice: false
        }
    }
}


export function saveContact(contactToAdd) {
    return {
        type: ACTION_SAVE_CONTACT,
        fields: contactToAdd,
        newUniqueId: uuidv4(),
        shouldTriggerSaveWithLoadAnsokanWhenProcessed: true
    }
}

export function removeContact(contact) {
    return {
        type: ACTION_REMOVE_CONTACT,
        contact,
        shouldTriggerSaveWithLoadAnsokanWhenProcessed: true
    }
}

export function createContacts(contacts) {
    return {
        type: ACTION_CREATE_CONTACTS,
        contacts: contacts,
        shouldTriggerSaveWithLoadAnsokanWhenProcessed: true
    }
}
