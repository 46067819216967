import {SIGNINGINSTANCE_STATUS} from "../domain/signing/SigningStatus";
import {Komplettering, KOMPLETTERINGSTATUS, KOMPLETTERINGTYP} from "../domain/Komplettering";
import {getBifogadHandlingVisningsnamnByType} from "../utils/globalConstants";
import {ListItemStatus} from "../domain/ListItemStatus";

export const ARENDE_LIST_PAGE_SIZE = 10
export const DUMMY_EMAIL_FOR_PHOS = 'noreply@lm.se'
export const DUMMY_EMAIL_FOR_PHOS_OLD = 'e-ansokan-for-fastighetsbildning-dummy-email@lm.se'

export default class KompletteringHelper {

    static getStatusObject = (status?: KOMPLETTERINGSTATUS, kvittensId?: string, signingStatus?: SIGNINGINSTANCE_STATUS): ListItemStatus => {
        if (status === undefined)
            status = KOMPLETTERINGSTATUS.UNKNOWN
        switch (status) {
            case KOMPLETTERINGSTATUS.INSKICKAD:
                return {
                    icon: 'mail_outline',
                    text: kvittensId ? 'Inskickad. Kvittensnummer: ' + kvittensId : 'Inskickad'
                }
            case KOMPLETTERINGSTATUS.UTKAST:
                return {
                    icon: 'warning',
                    text: 'Ej slutförd'
                }
            case KOMPLETTERINGSTATUS.SIGNERAS:
                switch(signingStatus) {
                    //då batchjobbet inte hunnit ändra kompletteringsstatus till AVBÖJD eller UTGÅTT
                    case SIGNINGINSTANCE_STATUS.DECLINED:
                        return {
                            icon: 'block',
                            text: 'Avbruten. Signeringen har avböjts'
                        }
                    case SIGNINGINSTANCE_STATUS.TIMED_OUT:
                        return {
                            icon: 'block',
                            text: 'Avbruten. Bestyrkningstiden har löpt ut'
                        }
                    default: {
                        return {
                            icon: 'schedule',
                            text: 'Pågående'
                        }
                    }
                }
            case KOMPLETTERINGSTATUS.AVBOJD:
                return {
                    icon: 'block',
                    text: 'Avbruten. Signeringen har avböjts'
                }
            case KOMPLETTERINGSTATUS.UTGATT:
                return {
                    icon: 'block',
                    text: 'Avbruten. Bestyrkningstiden har löpt ut'
                }
            default:
                return {
                    icon: 'warning',
                    text: 'Tekniskt fel'
                }
        }
    }

    static getKompletteringTypeTitleByItem = (komplettering: Komplettering): string => {
        return this.getKompletteringTypeTitle(komplettering.typ, komplettering.handlingTyp)
    }

    static getKompletteringTypeTitle = (kompletteringtyp: KOMPLETTERINGTYP, handlingTyp?: string) => {
        let title = 'Okänd typ'
        switch (kompletteringtyp) {
            case KOMPLETTERINGTYP.SKRIVELSE:
                title = 'Meddelande'
                break
            case KOMPLETTERINGTYP.YRKANDE:
                title = 'Yrkande'
                break
            case KOMPLETTERINGTYP.BITRADANDE:
                title = 'Biträdande av ansökan'
                break
            case KOMPLETTERINGTYP.YTTERLIGARE_HANDLING:
                if (handlingTyp)
                    title = getBifogadHandlingVisningsnamnByType(handlingTyp) ?? title
                break
        }
        return title
    }

}