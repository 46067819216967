import {ListItemFlexGroup} from "../../../../General/list/ListItemFlexGroup";
import {ListItemFlex} from "../../../../General/list/ListItemFlex";
import React from "react";
import {getLocalizedDateString} from "../../../../../helpers/dates";
import {ISO8601} from "../../../../../utils/globalConstants";
import {ListItemStatus} from "../../../../../domain/ListItemStatus";

interface Props {
    id?: string
    header: string
    submittedDate?: string
}

export const KompletteringSubmittedListItem = ({id, header, submittedDate, ...rest}: Props) => {

    const formattedDateString = `Inskickad ${submittedDate ? getLocalizedDateString(submittedDate, ISO8601) : ''}`
console.log(header);
    const status: ListItemStatus = {
        icon: header === "Återkallande av ansökan" ? 'close' : 'mail_outline',
        text: formattedDateString
    }

    return (<ListItemFlex data-testid={'komplettering-item'} id={id} {...rest}>
        {{
            items: <ListItemFlexGroup title={header} status={status}></ListItemFlexGroup>
        }}
    </ListItemFlex>)
}
