// @flow
// 1.23 En ansökan ska innehålla minst 1 aktmottagare

import { MINST_EN_AKTMOTTAGARE, WARNING } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  const contacts = state.contacts.contactList
  const aktmottagare = contacts.filter(contact => contact.data.rollAktmottagare)
  if (aktmottagare.length < 1) {
    return new Validation(MINST_EN_AKTMOTTAGARE, 'Du har inte angett någon aktmottagare.', 'contacts', WARNING, undefined)
  }
  return undefined
}

export default validate
