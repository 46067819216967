import { Dispatch, SetStateAction } from "react";
import {
    // @ts-ignore
    Column, FormFieldset, PageSubContent, Row, WizardIntorduction, WizardNavigationButtonToolbar, CircleLoading
} from "redet-react-components";

type Props = {
    closeWizardFunction: () => void
    kvittens: string
    epostKvittens: string
    error: string
    refetchKompletteringarList: () => void
    //Injectas med clone element från WizardDialogStep :sick!
    previousStep?: () => void
    kvittensEmailSuccessfullySent: boolean,
    setError: Dispatch<SetStateAction<string>>
    kompletteringsTyp: string
}

export function InskickadStep(props: Props) {

    const closeModalButton = () => {
        props.closeWizardFunction();
        props.refetchKompletteringarList();
    }

    return (
        <>
            {props.kvittens && props.epostKvittens && !props.error &&
                <>
                    <WizardIntorduction>
                        <PageSubContent title={props.kompletteringsTyp + ' är inskickat till Lantmäterimyndigheten'} headerLevel={3} headerId={'headerInskickadKompletteringId'}>
                            <FormFieldset title={'Bekräftelse'}>
                                <Row>
                                    <Column className={'form-group col-xs-12 col-sm-12 col-xl-12'}>
                                        <label className={'label'}>{props.kompletteringsTyp} skickades in:</label>
                                        <p>{props.kvittens}</p>
                                    </Column>
                                </Row>
                                <Row>
                                    {props.kvittensEmailSuccessfullySent ?
                                    <Column className={'form-group col-xs-12 col-sm-12 col-xl-12'}>
                                        <label className={'label'}>En kvittens har skickats till:</label>
                                        <p>{props.epostKvittens}</p>
                                    </Column>
                                    :
                                    <Column className={'form-group col-xs-12 col-sm-12 col-xl-12'}>
                                        <label className={'label'}>Det gick inte att skicka ett kvittensmail till den angivna epostadressen nedan, men {props.kompletteringsTyp.toLowerCase()} har skickats in korrekt till myndigheten.</label>
                                        <p>{props.epostKvittens}</p>
                                    </Column>
                                    }
                                </Row>
                            </FormFieldset>
                        </PageSubContent>
                    </WizardIntorduction>
                    <WizardNavigationButtonToolbar isPrevButtonDisabled={true}
                                                   nextButtonText={"Stäng"}
                                                   onClickNextButton={() => closeModalButton()}/>
                </>
            }
            {props.error &&
                <><WizardIntorduction>
                    <PageSubContent title={props.kompletteringsTyp + ' skickades inte in till Lantmäterimyndigheten'} headerLevel={3} headerId={'headerInskickadKompletteringId'}>
                        <FormFieldset title={'Tekniskt fel'}>
                            <br/>
                            <div className={'error-group'}>
                                <p className="mandatory" key={`error-1}`}>{
                                    props.error ? props.error :
                                        "Ett okänt fel har uppstått, vänligen navigera tillbaka och kontrollera uppgifterna i ditt " +props.kompletteringsTyp.toLowerCase()+" och försök igen." +
                                        " Du kan även välja att stänga dialogen, men då försvinner ditt upprättade " +props.kompletteringsTyp.toLowerCase()+" och du behöver upprätta det igen."
                                }</p>
                            </div>
                        </FormFieldset>
                    </PageSubContent>
                </WizardIntorduction>
                    <WizardNavigationButtonToolbar
                        prevButtonText={'Förhandsgranska'}
                        onClickPrevButton={() => {props.setError(""); if(props.previousStep) props.previousStep()}}
                        nextButtonText={"Stäng"}
                        onClickNextButton={props.closeWizardFunction}/>
                </>
            }
            {!props.kvittens && !props.error &&
                <>
                    <CircleLoading text={"Skickar in " + props.kompletteringsTyp.toLowerCase() + "..."} isFocusTrapped={false} id="kompletteringInskickadStepLoader"/>
                </>
            }
        </>
    )
}
export default InskickadStep;