//@ts-ignore
import { SidePanelChapterHeading, SidePanelSection } from 'redet-react-components'
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";

export const arendeAktiviteterHelpContent = (aterkallandeToggle: boolean) =>
  <>
    <SidePanelChapterHeading header={'Komplettera ärende'}
                             sectionTitle={'Kompletteringar'}
                             introduction={'Här kan du skicka in ytterligare handlingar eller utföra andra aktiviteter i ditt ärende.' +
                                 ' Se nedan för mer information kring varje aktivitet.'}/>
    <SidePanelSection heading="Skicka in handling" id={'term-komplettering_handling'}>
      <p>
        Börja med att välja typ för den handling du ska lägga till, välj därefter vilka filer som utgör handlingen och
        ska laddas
        upp. Du kan ladda upp följande filformat: JPG, JPEG, GIF, PNG, PDF.
        <br/>
        <br/>
        Du kan välja att ladda upp flera filer som hör till en och samma handling, de uppladdade filerna läggs då samman
        till en
        handling/bilaga. T.ex. om du har tre bilder som vardera utgör en sida en tresidig handling, då väljer du alla
        tre filerna,
        filerna läggs då samman till en handling i tjänsten och motsvarar därmed den ursprungliga handlingen.
        <br/>
        <br/>
        Om du har flera filer som utgör separata handlingar, bifogar du varje handling för sig.
        <br/>
        <br/>
        För varje inskickad handling behöver du bestyrka att den överensstämmer med orginalet. När handlingen är
        uppladdad och bestyrkt
        kommer den att automatiskt skickas in.
      </p>
    </SidePanelSection>
    <SidePanelSection heading="Bjuda in medsökanden" id={'term-bitradande'}>
      <p>
        När du bjuder in någon att bli medsökande kommer hen att bli ombedd att skriva under en biträdandehandling.
        Detta innebär
        att hen blir medsökande till den förrättningsåtgärd som ansökan avser och ansluter sig till de yrkanden och
        önskemål som
        framgår av ansökan samt tillhörande handlingar.
        <br/>
        <br/>
        Du kan bjuda in flera personer att bli medsökande genom att lägga till allas e-postadresser i samma inbjudan.
        <br/>
        <br/>
        Lantmäterimyndigheten tar betalt för den tid som läggs ner på ärendet. Även om ansökan återkallas (tas tillbaka)
        eller om
        åtgärden inte kan genomföras debiteras ni som sökande för upparbetade kostnader. Är ni överens om
        kostnadsfördelningen
        beslutar vi i normalfallet enligt det.
      </p>
    </SidePanelSection>
    <SidePanelSection heading="Skicka in meddelande" id={'term-meddelande'}>
      <p>
        Ett meddelande är en enklare form av kommunikation, att jämställa med ett E-postmeddelande. Det kan vara att du vill meddela nya
        kontaktuppgifter, berätta att ni planerar att delta vid mätarbete eller något annat som inte kräver en formell underskrift.
        Vill du göra ett yrkande - En mer formell begäran att Lantmäterimyndigheten ska besluta på ett visst sätt - Använd då funktionen "Yrkande".
        <br/>
        Du upprättar meddelandet direkt i tjänsten, och väljer om du vill ange telefonnummer och
        e-postadress. Observera att ditt namn alltid kommer att synas i meddelandet.
        När du upprättat meddelandet och angett eventuell e-postadress och telefon får du förhandsgranska
        meddelandet innan den skickas in. Om allt ser bra ut kan du därefter skicka in meddelandet,
        och du får bekräftelse direkt på skärmen att meddelandet är inskickad.
        <br/>
        <br/>
        Observera att alla meddelanden som skickas in blir en allmän handling och kan komma
        publiceras i E-tjänsten för samtliga personer som berörs av ärendet.
      </p>
    </SidePanelSection>
    <SidePanelSection heading="Skicka in yrkande" id={'term-yrkande'}>
      <p>
        Ett yrkande innebär en begäran till Lantmäterimyndigheten att besluta på visst sätt. Ta kontakt med din handläggare
        innan du gör detta om du är osäker på vad det innebär.
        <br/>
        <br/>
        Du kan skicka in ett yrkande ensam eller tillsammans med någon annan, med eller utan kartskiss.
        Oavsett vad du väljer så skriver du yrkandet direkt i tjänsten.
        Tänk på att text kan gå förlorad om du exempelvis av misstag laddar om sidan,
        så om du har ett omfattande yrkande i text kan det vara klokt att skriva detta i ett separat dokument och därefter klippa in det i tjänsten.
        <br/>
        <br/>
        Om du valt att bifoga en kartskiss laddar du upp denna i tjänsten.
        Klicka på "Välj filer" och navigera därefter till den kartfil du vill bifoga.
        Format som stöds är JPG, GIF, PNG och PDF. Det går bra att ladda upp flera kartor om du är i behov av detta.
        <br/>
        <br/>
        Om fler än du själv ska skriva under yrkandet så ska du ange namn och e-postadresser till dessa personer,
        samt din egen e-postadress för kvittens på att yrkandet skickats in. Du kan lägga till fler medyrkare om du vill detta.
        <br/>
        <br/>
        Därefter är yrkandet klart att skrivas under.
        Du klickar på "Skriv under och skicka in", alternativt "Bjud in och skriv under" om fler personer ska skriva under yrkandet.
        Du kommer då till signeringstjänsten där du kan se yrkandet i sin helhet inklusive eventuella kartor, och skriver under med din E-legitimation.
        Om du ensam ska skriva under skickas yrkandet in automatiskt efter underskriften.
        <br/>
        <br/>
        Om fler personer ska skriva under kommer de få en länk skickad till den angivna e-postadressen där de kan se yrkandet i sin helhet och välja att skriva under yrkandet.
        När samtliga medyrkande och du själv skrivit under kommer yrkandet automatiskt att skickas in,
        och ett meddelande om detta kommer att skickas till den e-postadress du angav för kvittenser.
      </p>
    </SidePanelSection>
    <SidePanelSection heading="Bekräfta att du blivit delgiven" id={'term-delgivning'}>
      <p>
        Om handlingar har skickats ut där du ska bekräfta att du är delgiven så tänds knappen "Bekräfta att du blivit delgiven" upp.
        Du får då ange vilket utskick du vill bekräfta, och därefter bekräfta att du tagit del av handlingarna genom att klicka i rutan och därefter klicka "Bekräfta delgivning".
        Du behöver inte bekräfta att du blivit delgiven digitalt om du redan bekräftat på annat sätt - exempelvis genom brev eller kontakt med din handläggare.
        <br/>
        <br/>
        Om du vet att du borde bekräfta att du mottagit en handling men den inte visas i tjänsten, kontakta din handläggare så hjälper vi dig.
        Det är bara vissa handlingar som du behöver bekräfta att du tagit emot, exempelvis kallelse till sammanträde.
      </p>
    </SidePanelSection>
    {aterkallandeToggle ?
    <SidePanelSection heading="Återkalla ansökan" id={'term-aterkallande'}>
      <p>
        Om du vill inte vill att ditt ärende ska fortsätta handläggas kan du återkalla ansökan. Detta innebär att vi
        snarast möjligt avslutar handläggningen, slutfakturerar och avslutar ärendet. Tänk på att vi alltid debiterar de
        kostnaderna vi har upparbetat, även om ansökan återkallas.
        <br/>
        <br/>
        Om ni är flera som ansökt om ärendet behöver normalt samtliga som ansökt genomföra ett återkallande genom att logga
        in i E-tjänsten med sitt eget BankID.
        <br/>
        <br/>
        Observera att det kan finnas situationer då ärendet fortsätter att handläggas även om ett önskemål om
        återkallande inkommer. Vi rekommenderar att du kontaktar din handläggare innan du genomför ett återkallande.
      </p>
    </SidePanelSection>
        :
        <></>
    }
  </>
