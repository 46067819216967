import { Navigation } from '../components'
import { PageHeader } from '../components/General/forms/layoutElements'
//@ts-ignore
import { ButtonToolbar, LinkButtonSub, ModalDialogSub } from 'redet-react-components'

export const LogOutModal = () => {

  //Olika url:er är egentligen redundant, då detta enbart gäller prod, men finns med för testbarhet
  function calculateLogoutUrlForThisEnvironemnt () {
    switch (window.location.hostname) {
      case 'localhost':
        return '/j_spring_security_logout'
      default:
        return '/logout'
    }
  }

  const logOutUrl = calculateLogoutUrlForThisEnvironemnt()
  return (
    <ModalDialogSub modalTitle={'Logga ut'}
                    ingress={'Du är inloggad med användarnamn genom någon annan av Lantmäteriets tjänster. För att skapa en giltig ansökan, vänligen logga in med e-legitimation istället.'}
                    useDefaultToolbar={false}
                    firstFocus={'logoutModalYesButtonId'}
    >
      <ButtonToolbar>
        <div className="button-group">
          <LinkButtonSub text={'Ok'} link={logOutUrl} icon={'check'} id={'logoutModalYesButtonId'}/>
        </div>
      </ButtonToolbar>
    </ModalDialogSub>)
}

const LogoutUsername = () => {

    return (
      <div>
        <header>
          <Navigation/>
          <PageHeader title="Logout"/>
        </header>
        <LogOutModal/>

      </div>
    )
}

export default LogoutUsername;

