import React, {Component} from 'react';
import {connect} from 'react-redux';
import {visaTerm} from 'redux/modules/help';
// @ts-ignore
import {LinkButtonText} from "redet-react-components";

type Props = {
    visaTerm: (moment: string, term?: string) => void,
    label: string,
    moment: string,
    term?: string
}

const mapDispatchToProps = {
    visaTerm,
};

export function TermLank(props: Props) {
   
    const onVisaTermClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        props.visaTerm(props.moment, props.term);
    }

    return (
            <LinkButtonText onClick={onVisaTermClick}
                            text={props.label}
                            icon={"help"}/>
    );
}

export default connect(
    null,
    mapDispatchToProps
// @ts-ignore
)(TermLank);
