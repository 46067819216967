// eslint-disable-next-line
import lantmaterietLogo from '!file-loader!redet-atomic/dist/gfx/logotypes/lantmateriet-rgb.svg'
// eslint-disable-next-line
import klmLogo from '!file-loader!redet-atomic/dist/gfx/logotypes/klm-rgb.svg'
// eslint-disable-next-line
import yourEuropeLogo from '!file-loader!redet-atomic/dist/gfx/sdg/sdg-base-logo-ms-negative-version-panton-one-colour-01.svg'
// @ts-ignore
import {ExternalLink} from 'redet-react-components'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';


export const Footer = () => {

    const { t } = useTranslation();
    const user = useSelector((store: RootState) => store.auth.user)

    const isPrivOrOrg = window.location.href.includes('/privat') || window.location.href.includes('/org');
    const isSdg = user?.isSdg || window.location.href.includes('/sdg');

    return (
        <footer className="page-footer" id="footerId">
            <div className="container">
                <div className="optional-content">
                    <>
                      <h2>{t("footer.title")}</h2>
                      <p>
                        {t("footer.authority_desciption_one")}
                        <br />
                        {t("footer.authority_desciption_two")}
                            {isSdg && <ExternalLink url={'https://www.lantmateriet.se/en/about-lantmateriet/contact-us/find-offices/'} text={t("footer.find_office")}/> }
                            {isPrivOrOrg && <ExternalLink url={t("footer.find_office_link")} text={t("footer.find_office")}/>}
                        </p>
                    </>
                </div>
                <figure>
                    <img src={lantmaterietLogo} alt={t("footer.lm_logo")}/>
                    <img src={klmLogo} alt={t("footer.klm_logo")} />
                    {isSdg && 
                        <a href="https://europa.eu/youreurope/index.htm#en" target="_blank" rel="noopener noreferrer">
                    <img src={yourEuropeLogo} alt={t("footer.eu_logo")}/></a>}
                </figure>
                <p className="small">
                    {t("footer.lm_desciption_one")}
                    <br />
                    {t("footer.lm_desciption_two")}
                    <br />
                    {t("footer.lm_desciption_three")}
                </p>
                {isPrivOrOrg && 
                <p className="small">
                    <ExternalLink text={t("footer.read_more")}
                        url={t("footer.read_more_link")} />
                </p>}
            </div>
        </footer>
    )
}
