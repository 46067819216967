import { maxLength, required } from 'utils/validation'

export const invoiceFieldValidations = {
  adressFaktura: [required, maxLength(100)],
  postnummerFaktura: [required, maxLength(10)],
  postortFaktura: [required, maxLength(100)],
  landFaktura: [maxLength(100)],
}

export const invoiceAlternativeFieldValidations = {
  adressAlternativ: [required, maxLength(100)],
  postnummerAlternativ: [required, maxLength(10)],
  postortAlternativ: [required, maxLength(100)],
  landAlternativ: [maxLength(100)],
}

export default invoiceFieldValidations

