import { useEffect, useState } from 'react'
import { Navigation } from '../components'
import { getLoginUrlPrivatperson } from '../helpers/loginUrl'
import { setCompact, setFullHeight, visaMoment } from '../redux/modules/help'
import { PageHeader } from 'components/General/forms/layoutElements'
//@ts-ignore
import { Helmet } from 'react-helmet'
import { JumpLinkToMain } from '../components/General/JumpLinkToMain'
//@ts-ignore
import { ButtonToolbar, Column, ExternalLink, LinkButtonTop, PageTopContent, Row, SidePanelChapter } from 'redet-react-components'
import Hjalp from '../views/Hjalp/Hjalp'
import { LoginHelpContentPrivatperson } from '../views/Hjalp/texter/login/LoginHelpContentPrivatperson'
import { KontaktaOssHelpContent } from '../views/Hjalp/texter/KontaktaOssHelpContent'
import { PersonuppgifterHelpContent } from '../views/Hjalp/texter/PersonuppgifterHelpContent'
import { IE11modalDialogWarning } from './Ansokan'
import { Lantmateriforattningfilm } from '../components/ansokan/Lantmateriforattningfilm'
import Term from "../views/Hjalp/TermLank";
import i18next from 'i18next'
import { useAppDispatch } from 'hooks'

export default function StartInloggningPrivatperson() {

  const dispatch = useAppDispatch()
  const [urlPrivatperson] = useState(getLoginUrlPrivatperson());

  useEffect(() => {
    i18next.changeLanguage('sv')
    dispatch(setCompact())
    dispatch(visaMoment('help-login-privatperson', undefined, false))
    return () => {
      dispatch(setFullHeight())
    }
  }, [])


  function getTjanstebeskrivning() {
    return <>
      <p className={'introduction'}>
        I den här tjänsten får du hjälp med att göra en ansökan om lantmäteriförrättning. <br/>Du kan också
        följa ett pågående ärende, skicka in handlingar, bjuda in medsökande och bekräfta delgivningar.
      </p>
      <p>Du kan få hjälp med att ansöka
        om <strong>fastighetsreglering</strong>, <strong>avstyckning</strong>, <strong>klyvning</strong>, <strong>sammanläggning</strong>, <strong>särskild gränsutmärkning</strong> och <strong>fastighetsbestämning</strong>.
        Vill du ansöka om någon annan åtgärd, t.ex. bildande av gemensamhetsanläggning
        eller annat så hittar du blanketter för det <ExternalLink
          url={'https://www.lantmateriet.se/blanketter#lantmateriforrattning'}
          text={'här'}/>.
        När du skickat in din ansökan kan du följa ditt ärende tills det är slutfört. Även du som är berörd av
        en annan lantmäteriförrättning kan logga in och följa ärendet här.
      </p>
      <p>
        Om du vill söka <strong>lagfart</strong> för en fastighet du köpt eller fått gör du det <ExternalLink
        url={'https://dafi.lantmateriet.se'} text={'här'}/>.
      </p>
    </>
  }

    const contentList = [{ key: 'login', moment: 'help-login-privatperson', text: 'Hur loggar jag in?' },]

    return (
      <div>
        <JumpLinkToMain/>
        <Helmet>
          <title>Inloggning lantmäteriförrättning</title>
        </Helmet>
        <IE11modalDialogWarning/>
        <header>
          <Navigation contentList={contentList}/>
         {/* @ts-ignore */}
          <PageHeader introduction={getTjanstebeskrivning()}>
            <ButtonToolbar>
              <a className="button-banner" href={urlPrivatperson}><i
                className="material-icons">login</i>Logga in som privatperson</a>
            </ButtonToolbar>
            <Lantmateriforattningfilm/>
          </PageHeader>
        </header>
        <main id="startPageMainContentId">
          <div className="container">
            <Row>
              <Column columns="col-xs-12 col-md-12 col-lg-6 offset-lg-3">
                <PageTopContent headerId={'innanStartHeaderId'} headerLevel={2} title={'Innan du börjar'}
                                description={<>Innan du börjar med en ansökan är det några saker du behöver du
                                  säkerställa:</>}>
                  <ol className="numbered-group">
                    <li><h3>Alla sökande har e-legitimation.</h3>
                      Om någon sökande saknar e-legitimation finns möjlighet att agera företrädare.
                      Företrädaren signerar då ansökan för en eller flera sökandes räkning.
                      Tjänsten är anpassad för att hela flödet ska ske digitalt.
                    </li>
                    <li><h3>Du kan genomföra ansökan digitalt</h3>
                      För att kunna genomföra ansökan helt digitalt behöver du ha tillgång till
                      <ul className="checked-group">
                        <li>E-legitimation</li>
                        <li>E-postadresser för samtliga sökande eller företrädare för sökande som ska signera ansökan</li>
                      </ul>
                    </li>
                    <li><h3>Du kan bifoga handlingar digitalt</h3>
                      För att ansökan ska vara komplett kan du behöva bifoga en eller flera handlingar digitalt.
                      Skanna/fota de
                      orginalhandlingar du har.
                    </li>
                  </ol>
                  <br />
                  <p>
                    Läs mer om <Term label={'inloggning och signatur med e-legitimation'} moment={'help-login-privatperson'} term={'frontpage-help-elegitimation'} />
                  </p>
                  <ButtonToolbar>
                    <div className="button-group">
                      <LinkButtonTop text={'Logga in med e-legitimation'}
                                     link={urlPrivatperson}
                                     icon={'login'}
                                     isActive={true}
                                     id="loginButton"
                      />
                    </div>
                  </ButtonToolbar>
                </PageTopContent>
              </Column>
            </Row>
          </div>
        </main>
        <Hjalp>
          <SidePanelChapter name={'help-login-privatperson'}>
            <LoginHelpContentPrivatperson/>
          </SidePanelChapter>
          <SidePanelChapter name={'help-support'}>
            <KontaktaOssHelpContent/>
          </SidePanelChapter>
          <SidePanelChapter name={'help-personuppgift'}>
            <PersonuppgifterHelpContent/>
          </SidePanelChapter>
        </Hjalp>
      </div>
    )
}


