import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { closeModalDialog } from '../../redux/modules/modalDialog'
import { ButtonSub, ButtonToolbar } from 'redet-react-components'

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    content: state.modalDialog.content,
    callback: state.modalDialog.callback,
    showDeclineButton: state.modalDialog.showDeclineButton,
    disableAcceptButton: state.modalDialog.disableAcceptButton,
    acceptButtonText: state.modalDialog.acceptButtonText,
    acceptButtonTitle: state.modalDialog.acceptButtonTitle,
    declineButtonText: state.modalDialog.declineButtonText,
    modalTitle: state.modalDialog.modalTitle,
    iconConfirm: state.modalDialog.iconConfirm,
    iconDecline: state.modalDialog.iconDecline,

  }
}

const mapDispatchToProps = { closeModalDialog }

/**
 * @deprecated Använd ModalDialogV3*.
 */
export class ModalDialog extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func.isRequired,
    disableAcceptButton: PropTypes.bool.isRequired,
    acceptButtonText: PropTypes.string,
    declineButtonText: PropTypes.string,
    showDeclineButton: PropTypes.bool,
    content: PropTypes.any,
    callback: PropTypes.func,
    iconConfirm: PropTypes.string,
    iconDecline: PropTypes.string,
    acceptButtonTitle: PropTypes.string,
    modalTitle: PropTypes.string,
  }

  constructor (props, context) {
    super(props, context)
    this.acceptButtonRef = React.createRef()
    this.modalRef = React.createRef()
  }

  componentDidMount () {
    window.$(this.modalRef.current).modal({ backdrop: 'static' })
  }

  componentWillUnmount () {
    window.$(this.modalRef.current).modal('hide')
  }

  choiceMade (choice) {
    this.props.closeModalDialog()
    if (this.props.callback) {
      this.props.callback(choice)
    }
  }

  render () {
    return (
      <div className="modal show" id="modal-dialog-id" ref={this.modalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="layout-group-sub">
              <h3 id="modal-label"><span className="text">{this.props.modalTitle}</span></h3>
              {this.props.content}
              {this.props.children}
              <ButtonToolbar>
                {this.props.acceptButtonText &&
                  <ButtonSub ref={this.acceptButtonRef} type="button" className="button-sub"
                             onClick={this.choiceMade.bind(this, true)}
                             data-selenium-id="modalDialogAccept" disabled={this.props.disableAcceptButton}
                             title={this.props.disableAcceptButton && this.props.acceptButtonTitle ? this.props.acceptButtonTitle : ''}
                             icon={this.props.iconConfirm === 'undefined' ? 'check' : this.props.iconConfirm}
                             text={this.props.acceptButtonText}
                  />}
                {this.props.showDeclineButton &&
                  <ButtonSub className="button-sub" type="button" data-selenium-id="modalDialogDecline"
                             onClick={this.choiceMade.bind(this, false)}
                             icon={this.props.iconDecline === 'undefined' ? 'clear' : this.props.iconDecline}
                             text={this.props.declineButtonText}
                  />}
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * @deprecated Använd ModalDialogV3*.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps)(ModalDialog)
