import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {NotiserComponent} from "./NotiserComponent";

const queryClient = new QueryClient();

export function Notiser() {
    return <QueryClientProvider client={queryClient}>
        <NotiserComponent/>
    </QueryClientProvider>
}
