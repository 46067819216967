import {Fetcher} from '../../repository/fetcher'
import {answerSvarslista as repoAnswerSvarslista, svaraPaDelgivning as repoSvaraPaDelgivning, fetchSvarslistor} from '../../repository/svarslistaRepository'
import {Severity} from './model/helpText'

const LOAD_ARENDE_SVARSLISTOR = 'arende/LOAD_ARENDE_SVARSLISTOR'
const LOAD_ARENDE_SVARSLISTOR_SUCCESS = 'arende/LOAD_ARENDE_SVARSLISTOR_SUCCESS'
const LOAD_ARENDE_SVARSLISTOR_FAILED = 'arende/LOAD_ARENDE_SVARSLISTOR_FAILED'
const ARENDE_AVSLUTAT = 'arende/ARENDE_AVSLUTAT'

export const initialState = {
    svarslistor: {besvarade: [], obesvarade: []},
    svarslistorIsLoading: false,
    delgivningBekraftelseAtgard: {
        active: false,
        inactiveReasons: []
    }
}

function splitListInAnswerdAndUnanswerd(svarslistor) {
    let besvarade = []
    let obesvarade = []
    for (const svarslista of svarslistor) {
        if (svarslista.svar !== undefined)
            besvarade.push(svarslista)
        else
            obesvarade.push(svarslista)
    }
    return {besvarade: besvarade, obesvarade: obesvarade}
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD_ARENDE_SVARSLISTOR:
            return {
                ...state,
                svarslistorIsLoading: true
            }
        case LOAD_ARENDE_SVARSLISTOR_SUCCESS:
            return {
                ...state,
                svarslistorIsLoading: false,
                svarslistor: action.svarslistor,
                delgivningBekraftelseAtgard: action.delgivningBekraftelseAtgard,
            }
        case LOAD_ARENDE_SVARSLISTOR_FAILED:
            return {
                ...state,
                svarslistorIsLoading: false,
                error: action.error,
                delgivningBekraftelseAtgard: {
                    active: false,
                    inactiveReasons: [{
                        severity: Severity.ERROR,
                        message: 'Ett fel har inträffat vid inhämtning av delgivningar, var god försök igen senare genom att ladda om sidan.'
                    }]
                }
            }
        case ARENDE_AVSLUTAT:
            return {
                ...state,
                delgivningBekraftelseAtgard: {
                    active: false,
                    inactiveReasons: [{severity: Severity.INFORMATION, message: 'Du kan inte bekräfta delgivning eftersom ärendet är avslutat'}]
                }
            }
        default:
            return state
    }
}

export function answerSvarslista(svarslistaId) {
    return function action(dispatch, getState) {
        const fetcher = new Fetcher(dispatch, getState, false)
        return repoAnswerSvarslista(fetcher, svarslistaId)
            .then(response => {
                return response
            }).catch(error => {
                throw error
            })
    }
}

export function svaraPaDelgivning(id, request) {
    return function action(dispatch, getState) {
        const fetcher = new Fetcher(dispatch, getState, false)
        return repoSvaraPaDelgivning(fetcher, id, request)
            .catch(error => {
                throw error
            })
    }
}

export function loadSvarslistor(arendenummer) {
    return function action(dispatch, getState) {
        if (arendenummer === undefined) {
            dispatch({type: LOAD_ARENDE_SVARSLISTOR_FAILED, error: 'Äredenummeret är odefinierat.'})
            return
        }

        dispatch({type: LOAD_ARENDE_SVARSLISTOR})
        const fetcher = new Fetcher(dispatch, getState, false)
        return fetchSvarslistor(fetcher, arendenummer)
            .then(svarslistor => {
                dispatch(loadSvarslistorSuccess(svarslistor))
            }).catch(error => {
                dispatch({type: LOAD_ARENDE_SVARSLISTOR_FAILED, error: error})
            })
    }
}

export function setArendeAvslutatState() {
    return {
        type: ARENDE_AVSLUTAT
    }
}

export function loadSvarslistorSuccess(svarslistor) {
    const splittedSvarslistor = splitListInAnswerdAndUnanswerd(svarslistor)
    return {
        type: LOAD_ARENDE_SVARSLISTOR_SUCCESS,
        svarslistor: splittedSvarslistor,
        delgivningBekraftelseAtgard: {
            active: splittedSvarslistor.obesvarade.length > 0,
            inactiveReasons: []
        }
    }
}
