function isOldDriftPlatform() {
  return window.location.href.includes("etjanster");
}

function formatToLoginUrl (suffix = "") {
  const match = window.location.href.match(/^(https?:\/\/)?(\w*)(.*)$/)
  const proto = (match[1] !== undefined ? match[1] : window.location.href + '://')
  const host = isOldDriftPlatform() ? (match[2] === 'enak' ? 'enaks' : match[2]) : match[2]
  const query = (match[3] !== undefined ? match[3] : '')
      .replace('/', '')
      .replace('start/privat', '')
      .replace('start/org', '')
      .replace('start/sdg', '')
  return proto + host + query + suffix
}

export function getLoginUrlPrivatperson () {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_IAMMOCK === 'true') {
    return 'http://localhost:8080/login/privat/'
  }
  return formatToLoginUrl("/privat")
}

export function getLoginUrlOrganisation () {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_IAMMOCK === 'true') {
    return 'http://localhost:8080/login/org'
  }
  return formatToLoginUrl(isOldDriftPlatform() ? '/orglogin' : '/org')
}

export function getLoginUrlSDG () {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_IAMMOCK === 'true') {
    return 'http://localhost:8080/login/sdg'
  }
  return formatToLoginUrl('/euCitizenApplication/pdfApplication')
}