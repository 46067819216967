// @ts-ignore
import {SidePanelChapterHeading} from "redet-react-components";

import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {EXTERNAL_LINK, FILE_LINK} from "../../../helpers/LocaleComponentHelper";

export function FullmaktHelpContent() {

    const {t} = useTranslation()

    return <SidePanelChapterHeading header={t('help.powerOfAttorney.title')}>
        <p>
            {t('help.powerOfAttorney.intro')}
            <br/>
            <Trans i18nKey="help.powerOfAttorney.form_link"
                   components={{
                       external_link: <EXTERNAL_LINK aria-label={t('help.powerOfAttorney.link_aria_label')}/>,
                       //TODO when published on lm.se, use external link for eng
                       file_link: <FILE_LINK aria-label={t('help.powerOfAttorney.link_aria_label')}/>
                   }}/>
        </p>
    </SidePanelChapterHeading>
}