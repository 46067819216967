import React from "react"
import {createRoot} from 'react-dom/client'
import 'react-app-polyfill/ie11' // För att stödja e11. https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
import 'react-app-polyfill/stable'
import 'element-closest-polyfill' // Polyfill för att stödja element.closest för Internet Explorer 9-11
import 'polyfill/text-encoding-polyfill' // Polyfill som behövs för att IE11 ska funka med React 18
import 'babel-polyfill' // Polyfil för att IE11 ska funka med React 18 (Symbol is undefined)
import 'core-js/stable' // Polyfill för att IE11 ska funka med React 18
import 'ol/ol.css'
import 'redet-atomic/dist/screen-local.css'
import 'redet-atomic/dist/optionals.css'
import 'redet-atomic/dist/simple-map.css'
import 'redet-atomic/dist/enak.css'
import 'redet-atomic/dist/print.css'
import {Provider} from 'react-redux'
import {CookiesProvider} from 'react-cookie'
import * as serviceWorker from './serviceWorker'
import {createFeatureToggleAPI} from './redux/modules/featureToggle'
import smoothscroll from 'smoothscroll-polyfill'
import browserUpdate from 'browser-update'
import {showBrowserUpdate} from './redux/browserUpdate'
import {startApiMockServer} from './ApiMockServer'
import {ROOT_BODY_CSS_CLASS} from './utils/globalConstants'
import {AxeObserver, logViolations} from 'agnostic-axe'
import 'wicg-inert'
import {store} from './store'
import './i18n.ts'
import Router from "./routes";

window.jQuery = window.$ = require('jquery/dist/jquery.slim.min')
// Jonolas magkänsla säger att detta inte behövs i Bootstrap 4. Kolla upp det när/om vi uppgraderar.
// Detta lägger in bootstraps javascript vilket behövs för att bootsrap-funktioner såsom dropdown-menyer ska funka.
require('bootstrap/dist/js/bootstrap.min')

// Polyfill för smooth scrolling för Safari, IE och Edge
smoothscroll.polyfill()

const render = Component => {
  const container = document.getElementById(ROOT_BODY_CSS_CLASS)
  const root = createRoot(container)
  return root.render(
      <Provider store={store}>
        <CookiesProvider>
            <Component/>
        </CookiesProvider>
      </Provider>
  )
}

render(Router)

if (process.env.REACT_APP_MOCK_API?.toLowerCase() === 'true') {
  startApiMockServer()
}

// if (process.env.NODE_ENV !== 'production') {
// UPDATE 2019 Rewrite - Dessa två nödvändiga?
// window.React = React; // enable debugger
// window.store = store;
//
// UPDATE 2019 Rewrite - Skall aktiveras när migreringen kommer dit.
// window.featureToggle = createFeatureToggleAPI(store);
// }

window.featureToggle = createFeatureToggleAPI(store)

// === Hot Module Reload ===
// Källa: https://medium.com/@brianhan/hot-reloading-cra-without-eject-b54af352c642
if (module.hot) {
  module.hot.accept('./pages/EnakRootComponent', () => {
    const NextApp = require('./pages/EnakRootComponent').default
    render(NextApp)
  })
}
// =========================
if (process.env.NODE_ENV === 'development') {
  const MyAxeObserver = new AxeObserver(logViolations)
  MyAxeObserver.observe(document)
}

browserUpdate({
  required: { e: -1, f: -1, o: -1, s: -1, c: -1 },
  api: 2020.01,
  reminder: 0,
  container: document.getElementById('browser-update-container'),
  onshow: function (infos) {
    store.dispatch(showBrowserUpdate())
  },
  nomessage: true,
  // Note: when adding a custom text attribute it seems the 'url' and 'onclick' properties are disabled, therefore we use an anchor tag instead.
  //text: "",
})

export default // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
