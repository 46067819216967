import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, FormSpy } from 'react-final-form'
import ContactRolesSelection from 'components/contactsRelated/Common/ContactRolesSelection'
import ContactFormActionButtons from 'components/contactsRelated/Common/ContactFormActionButtons'
import IndividualPhoneEmailForm from 'components/contactsRelated/Individual/IndividualPhoneEmailForm'
import IndividualAddressForm from 'components/contactsRelated/Individual/IndividualAddressForm'
import InvoicingForm from 'components/contactsRelated/Common/InvoicingForm'
import ContactFastighetSelection from 'components/contactsRelated/Common/ContactFastighetSelection'
import { CONTACT_TYPE_INDIVIDUAL, saveContact } from 'redux/modules/contacts'
import individualContactFormValidations from './individualContactFormValidations'
import arrayMutators from 'final-form-arrays'
import {
  Condition,
  HiddenInputFinalForm,
  Subscribe,
  ValideringssamanfattningFinalForm,
  Varningssamanfattning,
} from '../../../components/General/forms/finalForm'
import { NO_SCROLLING_CSS_CLASS } from '../../../utils/globalConstants'
import { Column, ModalDialogTop, Row } from 'redet-react-components'
import { displayContactForm } from '../../../redux/modules/contacts'
import { scrollToElementNative } from '../../../utils/scrollToElement'
import GeneralIdForm from '../../../components/contactsRelated/Common/GeneralIdForm'
import OmbudForm from '../../../components/contactsRelated/Common/OmbudForm'
import { individualContactFormWarningValidation } from './individualContactFormWarningValidation'

const faltNamn =
  {
    namn: 'Namn',
    personnummer: 'Personnummer',
    IDChoice: 'ID-nummer',
    ovrigtIdNummer: 'Övrigt ID-Nummer',
    samordningsnummer: 'Samordningsnummer',
    ombudPersonval: 'Roll',
    saknarBankId: 'BankID',
    rules: 'Roll',
    epost: 'Epost',
    rollAktmottagare: 'Roll',
    rollAnnanBerord: 'Roll',
    rollFakturamottagare: 'Roll',
    rollSokande: 'Roll',
    rollUpprattare: 'Roll',
    rollOmbud: 'Roll',
    postnummer: 'Postnummer',
    adress: 'Adress',
    adressFaktura: 'Fakturaadress',
    postnummerFaktura: 'Faktura-postnummer',
    postort: 'Postort',
    postortFaktura: 'Faktura-postort',
    adressAlternativ: 'Alternativ adress',
    postnummerAlternativ: 'Alternativ postnummer',
    postortAlternativ: 'Alternativ postort',
    landAlternativ: 'Alternativ land',
    representerarFastigheter: 'Fastighet',
    optOutFromRepresentingFastighet: 'Företräder ingen fastighet'
  }

const mapStateToProps = (store) => {
  return {
    contactList: store.contacts.contactList,
    ansokanValidations: store.validation.errors,
  }
}

const mapDispatchToProps = {
  saveContact,
  displayContactForm
}

export class IndividualContactWizardForm extends Component {
  static propTypes = {
    saveContact: PropTypes.func.isRequired,
    displayContactForm: PropTypes.func,
    contact: PropTypes.object,
    contactList: PropTypes.array,
    ansokanValidations: PropTypes.array.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
      warnings: []
    }
  }

  isEditingContact = this.props.contact !== undefined

  componentDidMount () {
    document.body.classList.add(NO_SCROLLING_CSS_CLASS)
  }

  componentWillUnmount () {
    document.body.classList.remove(NO_SCROLLING_CSS_CLASS)
  }

  getIdChoice = () => {
    if (!(this.props.contact && this.props.contact.data)) {
      return undefined
    }

    if (this.props.contact.data.IDChoice) {
      return this.props.contact.data.IDChoice
    } else if (this.props.contact.data.personnummer) {
      return 'personnummer'
    } else if (this.props.contact.data.samordningsnummer) {
      return 'samordningsnummer'
    } else if (this.props.contact.data.ovrigtIdNummer) {
      return 'ovrigtIdNummer'
    } else {
      // Om det varken finns IDChoice eller personnummer så defaultar vi till 'personnummer'
      return 'personnummer'
    }
  }

  getFormInitValues () {
    let foretraderFastighet = false
    let initValue
    if (this.isEditingContact) {
      foretraderFastighet = this.props.contact.data !== undefined && this.props.contact.data.representerarFastigheter !== undefined && this.props.contact.data.representerarFastigheter.length > 0
      initValue = {
        ...this.props.contact.data,
        IDChoice: this.getIdChoice(),
        id: this.props.contact.id,
        type: this.props.contact.type,
        identifier: this.props.contact.identifier,
        foretrader: foretraderFastighet ? 'ja' : 'nej',
        optOutFromRepresentingFastighet: this.props.contact.data.optOutFromRepresentingFastighet,
      }
    } else {
      initValue = {
        type: CONTACT_TYPE_INDIVIDUAL,
        IDChoice: 'personnummer',
        foretrader: 'nej',
        isCreatedManually: true
      }
    }
    return initValue
  }

  sparaKontakt = (values) => {
    if (values.rollOmbud === false) {
      delete values.ombudForPersoner
    }
    this.props.saveContact(values)
  }

  closeContactModal = () => {
    this.props.displayContactForm(false)
  }

  render () {
    const initialValues = this.getFormInitValues()
    return (
      <ModalDialogTop
        modalTitle={this.isEditingContact ? 'Komplettera uppgifter om privatpersonen' : 'Lägg till privatperson'}
        useDefaultToolbar={false}
        id={'contact-information'}
        closeModalCallback={this.closeContactModal}
        elementsToAllowFocus={['helpSidePanelId', 'validationSidePanelId']}
        isBig={true}>
        <Form
          onSubmit={this.sparaKontakt}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          subscription={{ submitting: true, pristine: true }}
          validate={values => individualContactFormValidations(values, this.props.contactList, this.props.contact?.id, this.props.ansokanValidations)}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} data-selenium-id="kontaktuppgift-form" aria-labelledby={'modalDialogLabelId'}>
              <FormSpy subscription={{ submitFailed: true, errors: true, values: true }}>
                {({ errors, submitFailed, values }) => {
                  const warnings = individualContactFormWarningValidation(values, this.props.contactList, this.props.contact?.id)
                  if (submitFailed) {
                    scrollToElementNative('contact-informationModalContentId')
                  }
                  return (<>
                    {(submitFailed || this.props.contact !== undefined) &&
                      <ValideringssamanfattningFinalForm validationErrors={errors}
                                                         faltnamnDescription={faltNamn}
                                                         id="kontaktIndividuellValideringssamanfattningsId"/>
                    }
                    {warnings !== undefined &&
                      <Varningssamanfattning
                        validationWarnings={warnings}
                        faltnamnDescription={faltNamn}
                        id="kontaktIndividuellVarningssamanfattningsId"/>
                    }
                    <HiddenInputFinalForm field={'id'}/>
                    <HiddenInputFinalForm field={'type'}/>
                    <HiddenInputFinalForm field={'identifier'}/>
                    <ContactRolesSelection editingId={this.isEditingContact ? this.props.contact?.id : undefined}
                                           contact={this.props.contact}
                                           ombudForPersoner={this.props.ombudForPersoner !== undefined ? this.props.ombudForPersoner : []}
                                           privatPerson={true} />
                    <Row>
                      <Column columns={'col-xs-12 col-sm-6'}>
                        <Subscribe fieldName={'rollOmbud'}>
                          {(prop) => {
                            const ombudChecked = prop.input.value
                            return (
                              <>
                                {!ombudChecked &&
                                  <>
                                    <GeneralIdForm formAutocomplete={initialValues.rollUpprattare}
                                                   contactList={this.props.contactList}
                                                   contactId={this.props.contact?.id}/>
                                  </>
                                }
                                {ombudChecked &&
                                  <OmbudForm formAutocomplete={initialValues.rollUpprattare}
                                             contact={this.props.contact}
                                             warnings={warnings}
                                             contactList={this.props.contactList}/>
                                }
                              </>
                            )
                          }}
                        </Subscribe>
                      </Column>
                      <Column columns="col-xs-12 col-sm-6">
                        <IndividualPhoneEmailForm formAutocomplete={initialValues.rollUpprattare}
                                                  contactList={this.props.contactList}
                                                  contactId={this.props.contact?.id}/>
                      </Column>
                    </Row>
                    <Row>
                      <Column columns="col-xs-12 col-sm-6">
                        <IndividualAddressForm formAutocomplete={initialValues.rollUpprattare}/>
                      </Column>
                      <Column columns="col-xs-12 col-sm-6">
                        <ContactFastighetSelection
                          optOutFromRepresentingFastighet={this.props.contact?.data?.optOutFromRepresentingFastighet === true}
                          agerValdaFastigheter={this.props.contact?.data?.agerValdaFastigheter}
                          importedContact={this.props.contact?.data?.imported}
                        />
                      </Column>
                    </Row>
                    <Condition when={'rollFakturamottagare'} is={true}>
                      <InvoicingForm contactTypeIsOrganization={false} formAutocomplete={initialValues.rollUpprattare}/>
                    </Condition>
                    <ContactFormActionButtons isEditing={this.isEditingContact}/>
                  </>)
                }}
              </FormSpy>
            </form>
          )}
        </Form>
      </ModalDialogTop>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualContactWizardForm)
