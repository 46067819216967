// @flow
export const INGA_VALIDERINGSFEL_FINNS_FOR_ATGARDER = 'INGA_VALIDERINGSFEL_FINNS_FOR_ATGARDER'
export const INGA_VALIDERINGSFEL_FINNS_FOR_KONTAKTER = 'INGA_VALIDERINGSFEL_FINNS_FOR_KONTAKTER'
export const MINST_EN_FASTIGHET = 'MINST_EN_FASTIGHET'
export const MINST_EN_ATGARD = 'MINST_EN_ATGARD'
export const MINST_EN_SOKANDE = 'MINST_EN_SOKANDE'
export const AGANDE_BOR_VARA_SOKANDE = 'AGANDE_BOR_VARA_SOKANDE'
export const SOKANDE_MASTE_HA_EPOSTADRESS = 'SOKANDE_MASTE_HA_EPOSTADRESS'
export const UPPRATTARE_MASTE_HA_EPOSTADRESS = 'UPPRATTARE_MASTE_HA_EPOSTADRESS'
export const MINST_EN_AKTMOTTAGARE = 'MINST_EN_AKTMOTTAGARE'
export const MINST_EN_FAKTURAMOTTAGARE = 'MINST_EN_FAKTURAMOTTAGARE'
export const KOSTNADSFORDELNING_MASTE_ANGES = 'KOSTNADSFORDELNING_MASTE_ANGES'
export const PENDING_FILES = 'PENDING_FILES'
export const MINST_TVA_FASTIGHETER_VID_FASTIGHETSREGLERING = 'MINST_TVA_FASTIGHETER_VID_FASTIGHETSREGLERING'
export const MINST_TVA_FASTIGHETER_VID_SAMMANLAGGNING = 'MINST_TVA_FASTIGHETER_VID_SAMMANLAGGNING'
export const MINST_EN_BIFOGAD_HANDLING = 'MINST_EN_BIFOGAD_HANDLING'
export const MINST_EN_KARTSKISS = 'MINST_EN_KARTSKISS'
export const MINST_EN_BEHORIGHETSHANDLING_VID_OMBUD = 'MINST_EN_BEHORIGHETSHANDLING_VID_OMBUD'
export const MINST_EN_FORETRADARE_VID_OMBUD = 'MINST_EN_FORETRADARE_VID_OMBUD'
export const FORETRADD_MASTE_VARA_SOKANDE = 'FORETRADD_MASTE_VARA_SOKANDE'
export const SOKANDE_MASTE_HA_PERSONNUMMER = 'SOKANDE_MASTE_HA_PERSONNUMMER'
export const ID_NUMMER_MASTE_ANGES = 'ID_NUMMER_MASTE_ANGES'
export const SOKANDE_MASTE_HA_BANKID = 'SOKANDE_MASTE_HA_BANKID'
export const SOKANDE_ORGANISATION_MASTE_HA_OMBUD = 'SOKANDE_ORGANISATION_MASTE_HA_OMBUD'

export const WARNING = 'warning'
export const BLOCKING = 'error'
export type ValidationSeverity = 'error' | 'warning';

// Flow-hack för att kunna få en lista med tillåtna värden.
const ruleCategorys = {
  'contacts': '', // value är inte viktigt/används inte.
  'atgard': '',
  'handlingar': '',
  'kartskiss': '',
  'fastighet': '',
  'unknown': ''
}
export const ruleCategorysList: Array<string> = Object.keys(ruleCategorys)
export type ValidationCategory = $Keys<typeof ruleCategorys>

const ruleLinks = {
  'kontakter': '',
  'forandringar': '',
  'handlingar': '',
  'fastighet': '',
  'kartskiss': ''
}

export const ruleLinksList: Array<string> = Object.keys(ruleLinks)
export type ValidationLink = $Keys<typeof ruleLinks>

export const validationpanelGroupWhitelist =
  [INGA_VALIDERINGSFEL_FINNS_FOR_KONTAKTER,
    MINST_EN_ATGARD,
    MINST_EN_BIFOGAD_HANDLING,
    MINST_EN_FASTIGHET,
    MINST_EN_KARTSKISS,
    MINST_EN_SOKANDE,
    MINST_TVA_FASTIGHETER_VID_FASTIGHETSREGLERING,
    MINST_TVA_FASTIGHETER_VID_SAMMANLAGGNING,
    INGA_VALIDERINGSFEL_FINNS_FOR_ATGARDER]
