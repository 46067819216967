// @ts-ignore
import {ButtonGroup, ButtonSub, ButtonToolbarV2} from "redet-react-components";
import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../hooks";
import {NO_SCROLLING_CSS_CLASS} from "../../../../utils/globalConstants";
import AterkallandeRenderComponent from "../../../aterkallande/AterkallandeRenderComponent";
import {fetchKompletteringar} from "../../../../redux/modules/arendeKompletteringar";
import {logError} from "../../../../utils/errorHandler";
import {ArendeViewContext} from "../ArendeViewContext";

type Props = {
    arendenummer: string
}

export function AterkallaAnsokanView({arendenummer}: Props) {

    const {setShowCloseModalConfirmationDialog, setOnConfirmClose} = React.useContext(ArendeViewContext);

    const [showSkickaInAterkallandeDialog, setShowSkickaInAterkallandeDialog] = useState(false)
    const [aterkallandeInskickad, setAterkallandeInskickad] = useState(false)

    const dispatch = useAppDispatch()

    const closeWizardModal = () => {
        setShowSkickaInAterkallandeDialog(false)
        setAterkallandeInskickad(false) // reset value
    }

    const closeWizardAfterAttemptedSubmit = () => {
        if (!aterkallandeInskickad) {
            setShowCloseModalConfirmationDialog(true)
        } else {
            closeWizardModal()
        }
    }

    useEffect(() => {
        //add och remove scrollingclass when dialog is visible
        if (showSkickaInAterkallandeDialog) {
            setOnConfirmClose(() => closeWizardModal)
            document.body.classList?.add(NO_SCROLLING_CSS_CLASS)
        } else {
            document.body.classList?.remove(NO_SCROLLING_CSS_CLASS)
        }
    }, [showSkickaInAterkallandeDialog])

    function fetchKompletteringarList(): void {
        dispatch(fetchKompletteringar(arendenummer))
            .catch((error: Error) => {
                logError(error, 'AterkallaAnsokanView-Component')
            })
    }

    return (<>
            <h3 id={"aterkallaansokan"} data-testid={'aterkallaansokan'}>Återkallelse av ansökan</h3>
            <p className={"introduction"}>Om du inte vill att ditt ärende ska fortsätta handläggas kan du återkalla ansökan.</p>
            <ButtonToolbarV2 isMultiLine={true} data-testid={'bekrafta-delgivning-buttons'}>
                <ButtonGroup>
                    <ButtonSub text={'Återkalla ansökan'}
                               id='aterkallaAnsokanButton'
                               onClick={() => setShowSkickaInAterkallandeDialog(true)}/>
                </ButtonGroup>
            </ButtonToolbarV2>

            {showSkickaInAterkallandeDialog &&
                <AterkallandeRenderComponent
                    arendenummer={arendenummer}
                    refetchKompletteringarList={async () => fetchKompletteringarList()}
                    closeWizardFunction={async () => closeWizardAfterAttemptedSubmit()}
                    aterkallandeInskickat={async () => setAterkallandeInskickad(true)}/>
            }
        </>
    )
}

export default AterkallaAnsokanView