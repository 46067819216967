//@ts-ignore
import { ExternalLink, SidePanelChapterHeading, SidePanelSection } from 'redet-react-components'

export const FastighetHelpContent = <>
  <SidePanelChapterHeading sectionTitle={'Fastighet'}
                           header={'Ange alla fastigheter som berörs'}
                           introduction={'Här ska du ange vilka fastigheter, samfälligheter och gemensamhetsanläggningar som berörs av den åtgärd du vill genomföra. Du kan välja mellan ' +
                             'de fastigheter du är registrerad ägare på (lagfart). Om en fastighet du inte är registrerad ägare på, en samfällighet' +
                             'eller en gemensamhetsanläggning berörs av förrättningen så kan du söka fram denna genom vår sökfunktion i tjänsten. Börja med att ange Kommunnamn ' +
                             'följt av den unika beteckning som enheten har, t.ex. KATRINEHOLM HÅKANSTORP 1:3.'}>
  </SidePanelChapterHeading>
  <SidePanelSection heading={'Beteckning'} id={'term-fastighetsbeteckning'}>
    <p>
      Känner du inte till beteckningen på berörd fastighet går det bra att kontakta kundcenter på telefon 0771–63 63 63
      eller
      via <ExternalLink url={'https://www.lantmateriet.se/sv/Om-Lantmateriet/Kontakta-oss/'}
                        text={'lantmäteriets webbplats'}/>. Finns
      det
      ett köpekontrakt kan du hitta fastighetsbeteckningen där.
    </p>
  </SidePanelSection>
</>
