import React, { Component, Dispatch, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Term } from 'views'
import { initFormData } from 'redux/finalForm'
import { formProperties } from 'redux/formProperties'
import FormStateToRedux from 'redux/FormStateToRedux'
import { createValidator, required } from 'utils/validation'
import { Form } from 'react-final-form'
import {
  CheckboxFinalForm,
  Condition,
  RadioFinalForm,
  Subscribe,
  TextAreaFinalForm,
  WhenFieldChanges
} from '../../General/forms/finalForm'
//@ts-ignore
import { Column, FormFieldset, Row, PageSubContent } from 'redet-react-components'

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: any) => {
  return { initFormData: (formField: string) => ownProps.initFormData || dispatch(initFormData(formField)) }
}

export let validateFastighetsregleringForm = function (values: any) {
  return createValidator(values,
    {
      bildaServitutBeskrivning: values?.bildaServitut ? [required] : [],
      overforFastighetstillbehorBeskrivning: values?.overforFastighetstillbehor ? [required] : [],
      overforAndelarSamfalligheterBeskrivning: values?.overforAndelarSamfalligheter ? [required] : [],
      berorsRattighetsinnehavareBeskrivning: values?.berorsRattighetsinnehavare ? [required] : [],
    })
}

type Props = {
  initFormData: (atgard: string) => {}
}

export function FastighetsregleringForm(props: Props) {
  
  const [_formDataLoaded, setFormDataLoaded] = useState(false);
  const [_initData, setInitData] = useState({});

  useEffect(() => {
    const res = props.initFormData(formProperties.fastighetsreglering.name)
    setFormDataLoaded(true);
    setInitData({ avtalsservitutFortsatterGalla: 'Vetej', ...res });
  }, [])

  return (
    <PageSubContent title={"Fastighetsreglering"} headerLevel={4} id="fastighetsforandring-fastighetsreglering" headerId="fastighetsforandring-fastighetsregleringHeader">
        <br/>
        <Form
          onSubmit={() => {
          }}
          initialValues={_initData}
          subscription={{ submitting: true, pristine: true }}
          validate={values => validateFastighetsregleringForm(values)}>
          {() => {
            return (
              <form onSubmit={() => {
              }}>
                {_formDataLoaded && <FormStateToRedux form={formProperties.fastighetsreglering.name}/>}

                <fieldset>
                  <Row>
                    <Column columns={'col-xs-12 col-md-6'}>
                      <FormFieldset title={'Marköverföring'}
                                    description={'Ange vilken fastighet som tillförs mark och vilken fastighet som avstår mark'}>
                        <TextAreaFinalForm
                          id={'markoverforingBeskrivningTillFranId'}
                          field={'markoverforingBeskrivningTillFran'}
                          label={'Beskrivning'}
                          mandatory={false}
                          characterLimit={4000}/>
                      </FormFieldset>
                      <FormFieldset
                        title={'Ändamål'}
                        description={
                          <>Beskriv <Term
                            label="ändamålet"
                            moment="help-description"
                            term="term-andamal"/>
                            med marköverföringen och vad de berörda fastigheterna ska användas till efter förrättningen.
                          </>}>
                        <TextAreaFinalForm
                          id={'markoverforingBeskrivningId'}
                          field={'markoverforingBeskrivning'}
                          label={'Beskrivning'}
                          mandatory={false}
                          characterLimit={4000}
                        />
                      </FormFieldset>
                      {/* Samfällighet */}
                      <FormFieldset
                        title={'Andelar i samfällighet'}
                        description={
                          <>Ska andelar i <Term
                            label="samfällighet"
                            moment="help-description"
                            term="term-samfallighet"/>
                            föras över mellan fastigheterna?
                          </>}
                      >
                        <Subscribe fieldName={'overforAndelarSamfalligheter'}>
                          {(props: any) => {
                            const overforAndelarSamfalligheterChecked = props.input.value === '' ? false : props.input.value
                            return (
                              <CheckboxFinalForm field={'overforIngaAndelarIsamfalligheter'}
                              label={'Nej'}
                              id={'fastighetsregleringOverforIngaAndelarIsamfalligheterId'}
                              disabled={overforAndelarSamfalligheterChecked} term={undefined} termPostfixText={undefined} warningMessage={undefined} children={undefined} header={undefined} labelChildren={undefined}                              />
                            )
                          }}
                        </Subscribe>
                        <Subscribe fieldName={'overforIngaAndelarIsamfalligheter'}>
                          {(props: any) => {
                            const overforIngaAndelarIsamfalligheterChecked = props.input.value === '' ? false : props.input.value
                            return (
                              <CheckboxFinalForm
                                field={'bifogadForvarvshandling'}
                                label={'Framgår i bifogad förvärvshandling'}
                                id={'fastighetsregleringBifogadForvarvshandlingId'}
                                disabled={overforIngaAndelarIsamfalligheterChecked} term={undefined} termPostfixText={undefined} warningMessage={undefined} children={undefined} header={undefined} labelChildren={undefined}/>
                            )
                          }}
                        </Subscribe>
                        <Subscribe fieldName={'overforIngaAndelarIsamfalligheter'}>
                          {(props: any) => {
                            const overforIngaAndelarIsamfalligheterChecked = props.input.value === '' ? false : props.input.value
                            return (
                              <>
                                <CheckboxFinalForm
                                  field={'overforAndelarSamfalligheter'}
                                  label={'Ja'}
                                  id={'fastighetsregleringOverforAndelarSamfalligheterId'}
                                  disabled={overforIngaAndelarIsamfalligheterChecked} term={undefined} termPostfixText={undefined} warningMessage={undefined} children={undefined} header={undefined} labelChildren={undefined}                                />
                                <WhenFieldChanges
                                  field={'overforIngaAndelarIsamfalligheter'}
                                  becomes={true}
                                  set={['bifogadForvarvshandling', 'overforAndelarSamfalligheter']}
                                  to={false}
                                />
                                {/* @ts-ignore */}
                                <Condition when={'overforAndelarSamfalligheter'} is={true}>
                                  <TextAreaFinalForm
                                    id={'overforAndelarSamfalligheterBeskrivningId'}
                                    field={'overforAndelarSamfalligheterBeskrivning'}
                                    label={'Ange vilka andelar som ska föras över och till vilken fastighet för respektive samfällighet.'}
                                    mandatory={true}
                                    characterLimit={4000}
                                  />
                                </Condition>
                                <WhenFieldChanges
                                  field={'overforAndelarSamfalligheter'}
                                  becomes={false}
                                  set={'overforAndelarSamfalligheterBeskrivning'}
                                  to={undefined}
                                />
                              </>
                            )
                          }}
                        </Subscribe>
                      </FormFieldset>
                    </Column>
                    <Column columns={'col-xs-12 col-md-6'}>
                      {/* Avtalsrättighet */}
                      <FormFieldset id={'avtalsrattighetHeaderLabelId'} title={'Avtalsrättighet'}
                                    description={<>Ska <Term label="avtalsrättighet"
                                                             moment="help-description"
                                                             term="term-avtalsservitut-markoverforing"/> inom
                                      området som förs över fortsätta att gälla efter marköverföringen?</>}>
                        {/* @ts-ignore */}
                        <RadioFinalForm field={'avtalsservitutFortsatterGalla'}
                                        label={'Ja'} value={'Ja'}
                                        id={'avtalsservitutFortsatterGallaJaId'}/>
                        {/* @ts-ignore */}
                        <RadioFinalForm field={'avtalsservitutFortsatterGalla'} label={'Nej'} value={'Nej'}
                                        id={'avtalsservitutFortsatterGallaNejId'}/>
                        {/* @ts-ignore */}
                        <RadioFinalForm field={'avtalsservitutFortsatterGalla'}
                                        label={'Vet inte / är osäker på vilka rättigheter som berörs'} value={'Vetej'}
                                        id={'avtalsservitutFortsatterGallaVetejId'}/>
                        {/* Berörs Rattighetsinnehavare */}
                        {/* @ts-ignore */}
                        <CheckboxFinalForm field={'berorsRattighetsinnehavare'}
                                           id={'fastighetsregleringBerorsRattighetsinnehavareId'}
                                           label={'Markområdet som förs över berörs av avtalsrättighet som inte är inskriven i fastighetsregistret'}
                                           term={<Term label="oinskriven rättighet" moment="help-description"
                                                       term="term-oinskrivna-rattighetsinnehavare"/>}/>
                        {/* @ts-ignore */}
                        <Condition when={'berorsRattighetsinnehavare'} is={true}>
                          <TextAreaFinalForm field={'berorsRattighetsinnehavareBeskrivning'}
                                             id={'berorsRattighetsinnehavareBeskrivningId'}
                                             label={'Om du vet, ange vem som har rättigheten och vad den avser, t ex arrende.' +
                                               ' Du kan även markera eventuella rättighetsområde i kartskissen. ' +
                                               'Om det finns flera oinskrivna rättigheter kan du ange samtliga här.'}
                                             mandatory={true}
                                             characterLimit={4000}/>
                        </Condition>
                        <WhenFieldChanges field={'berorsRattighetsinnehavare'} becomes={false}
                                          set={'berorsRattighetsinnehavareBeskrivning'} to={undefined}/>
                      </FormFieldset>
                      <FormFieldset title={'Övriga uppgifter'}>
                        {/* Bilda Servitut */}
                        {/* @ts-ignore */}
                        <CheckboxFinalForm field={'bildaServitut'} id={'fastighetsregleringBildaServitutId'}
                                           label={'Ny rättighet ska bildas'}
                                           term={<Term label="servitut" moment="help-description"
                                                       term="term-servitut"/>}/>
                        {/* @ts-ignore */}
                        <Condition when={'bildaServitut'} is={true}>
                          <TextAreaFinalForm id={'bildaServitutBeskrivningId'} field={'bildaServitutBeskrivning'}
                                             label={'Beskriv vad det nya servitutet avser (du kan även rita in det i kartskissen).'}
                                             mandatory={true}
                                             characterLimit={4000}/>
                        </Condition>
                        <WhenFieldChanges field={'bildaServitut'} becomes={false} set={'bildaServitutBeskrivning'}
                                          to={undefined}/>
                        {/* Överför Fastighetstillbehor */}
                        {/* @ts-ignore */}
                        <CheckboxFinalForm field={'overforFastighetstillbehor'}
                                           id={'fastighetsregleringOverforFastighetstillbehorId'}
                                           label={'Fastighetstillbehör ska föras från en fastighet till en annan fastighet'}
                                           term={<Term label="fastighetstillbehör" moment="help-description"
                                                       term="term-fastighetstillbehor"/>}/>
                        {/* @ts-ignore */}
                        <Condition when={'overforFastighetstillbehor'} is={true}>
                          <TextAreaFinalForm
                            id={'overforFastighetstillbehorBeskrivningId'}
                            field={'overforFastighetstillbehorBeskrivning'}
                            label={'Beskriv vilket fastighetstillbehör som ska överföras'}
                            mandatory={true}
                            characterLimit={4000}
                          />
                        </Condition>
                        <WhenFieldChanges
                          field={'overforFastighetstillbehor'}
                          becomes={false}
                          set={'overforFastighetstillbehorBeskrivning'}
                          to={undefined}
                        /></FormFieldset>
                    </Column>
                  </Row>
                </fieldset>
              </form>
            )
          }}
        </Form>
        </PageSubContent>    
    )
}


export default connect(undefined, mapDispatchToProps)(FastighetsregleringForm)
