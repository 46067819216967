import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {User} from 'domain/User'

export const AUTHENTICATE = 'auth/AUTHENTICATE'
export const SET_USER_AGREEMENT_STATUS = 'auth/SET_USER_AGREEMENT_STATUS'
export const STORE_SESSION_COOKIE = 'auth/STORE_SESSION_COOKIE'

export type AuthState = {
  user?: User
  userHasAgreedToTermsOfUsage: boolean
}

const initialState: AuthState = {
  user: undefined,
  userHasAgreedToTermsOfUsage: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUserLoggedIn(state, action: PayloadAction<User>) {
        state.user = action.payload
      },
    storeSessionCookie(state, action: PayloadAction<string>) {
      if(state.user){
        state.user.currentSessionId = action.payload;
      }
    }
  }
})

export const {setUserLoggedIn, storeSessionCookie} = authSlice.actions
export default authSlice.reducer
