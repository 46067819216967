// @flow
// En ansökan med åtgärd fastighetsreglering ska innehålla minst 2 valda fastigheter ENAK-1108

import { BLOCKING, MINST_TVA_FASTIGHETER_VID_SAMMANLAGGNING } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  if (state.form.fastighetsforandring && state.form.fastighetsforandring.sammanlaggning && state.fastigheter.fastigheterList.length < 2) {
    return new Validation(MINST_TVA_FASTIGHETER_VID_SAMMANLAGGNING, 'Du har valt att lägga samman fastigheter och måste då välja minst två fastigheter.', 'fastighet', BLOCKING, undefined)
  }
  return undefined
}

export default validate
