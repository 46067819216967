export const overenskommelse = <>
  <p>Överenskommelse mellan berörda parter som läggs till grund för beslut i förrättningen, t.ex. marköverföring,
    ersättning och kostnadsfördelning.
    <br/>Exempel på handlingar är:</p>

  <ul>
    <li> Överenskommelse om fastighetsreglering</li>
    <li> Överenskommelse om överföring av andel i samfällighet.</li>
  </ul>
</>
