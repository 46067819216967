import {loadSigningStatus, signAbort} from 'repository/kompletteringRepository'
import {fakeLoadSigningStatus, fakeSignAbort} from 'repository/testdata/fakeKompletteringRepository'
import {Fetcher} from '../../repository/fetcher'

export function fetchSigningStatusKomplettering(kompletteringId) {
    return function action(dispatch, getState) {
        const fetcher = new Fetcher(dispatch, getState, false)
        return (getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeLoadSigningStatus(kompletteringId) : loadSigningStatus(fetcher, kompletteringId))
            .then((result) => {
                return result
            }).catch(() => {
                throw Error('Kunde inte hämta signeringsstatus för komplettering.')
            })
    }
}

export function signKompletteringAbort(kompletteringId) {
    return function action(dispatch, getState) {
        const fetcher = new Fetcher(dispatch, getState)
        return (getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeSignAbort() : signAbort(fetcher, kompletteringId))
            .then((kompletteringId) => {
                return kompletteringId
            }).catch(() => {
                throw Error('Kunde inte avbryta signering för komplettering.')
            })
    }
}
