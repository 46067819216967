import { useEffect, useState} from 'react'
import {DynamicCheckboxListOmbud} from '../../General/DynamicCheckboxListOmbud'
import {Contact} from "../../../domain/Contact";
import {CONTACT_TYPE_INDIVIDUAL} from "../../../redux/modules/contacts";

type ContactOmbudProps = {
    contact?: Contact,
    contactList: Contact[],
    warnings: any[]
};

export function ContactOmbudSelection(props: ContactOmbudProps) {

    const [personerSomKanForetradas, setPersonerSomKanForetradas] = useState(new Map())

    useEffect(() => {
        filterPersonerSomKanForetradas()
    }, [])


    const isPersonPrivatPerson = (contact: Contact): boolean => contact.type === CONTACT_TYPE_INDIVIDUAL

    const filterPersonerSomKanForetradas = () => {

        function isCurrentContact(contact: Contact) {
            return props.contact && props.contact.id === contact.id;
        }

        const kanForetradas = new Map()
        props.contactList?.forEach((ansokanContact) => {
            if (!ansokanContact.data.rollOmbud && !isCurrentContact(ansokanContact)) {
                kanForetradas.set(ansokanContact.id, isPersonPrivatPerson(ansokanContact) ? `${ansokanContact.data.namn}` : ansokanContact.data.orgNamn)
            }
        })
        setPersonerSomKanForetradas(kanForetradas)
    }

    return (
        <>
            {personerSomKanForetradas &&
                <DynamicCheckboxListOmbud
                    options={personerSomKanForetradas}
                    warnings={props.warnings}
                />
            }
        </>
    )
}