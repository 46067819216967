import {ArendeFilterType, DefaultArendeFilter} from "../pages/welcome/components/ArendeList/ArendeListFilter";
import {getLocalizedDateString} from "../helpers/dates";

class FilterQueryHelper {

    private static STORAGE_KEY: string = "arendelist-search-filter"

    static storeArendeFilterQuery = (params: URLSearchParams): void => {
        const query = params?.get('filter')
        if (query) {
            sessionStorage.setItem(this.STORAGE_KEY, query)
        }
    }

    static unStoreArendeFilterQuery = (): void => {
        sessionStorage.removeItem(this.STORAGE_KEY)
    }

    static getArendeFilterFromQuery = (params: URLSearchParams): ArendeFilterType | undefined => {
        const filterString = params?.get('filter')
        return filterString != null ?
            this.tryParseArendeFilterType(filterString) :
            undefined
    }

    static getArendeFilterFromStorage = (): ArendeFilterType | undefined => {
        const filterString = sessionStorage.getItem(this.STORAGE_KEY)
        this.unStoreArendeFilterQuery()
        return filterString != null ?
            this.tryParseArendeFilterType(filterString) :
            undefined
    }

    static createUrlQueryString = (filter: ArendeFilterType): string | undefined => {
        if (filter.ankomstDatum || filter.arendenummer || filter.handlaggare || filter.status || filter.fastighetsbeteckning) {
            const query = new URLSearchParams({
                filter: JSON.stringify({...filter, ankomstDatum: getLocalizedDateString(filter.ankomstDatum?.toISOString(), 'yyyy-MM-dd')}, (key, val) => (val == null || val === "") ? undefined : val)
            })
            return query.toString()
        }
        return undefined
    }

    private static tryParseArendeFilterType = (json: string): ArendeFilterType | undefined => {
        const defaultArendeFilter = DefaultArendeFilter
        try {
            const filterObj = JSON.parse(json)
            return {
                ankomstDatum: (filterObj.ankomstDatum && !isNaN(Date.parse(filterObj.ankomstDatum))) ? new Date(filterObj.ankomstDatum) : defaultArendeFilter.ankomstDatum,
                status: filterObj.status || defaultArendeFilter.status,
                arendenummer: filterObj.arendenummer || defaultArendeFilter.arendenummer,
                handlaggare: filterObj.handlaggare || defaultArendeFilter.handlaggare,
                fastighetsbeteckning: filterObj.fastighetsbeteckning || defaultArendeFilter.fastighetsbeteckning
            }
        } catch (err: any) {
            //dismiss
            return undefined
        }
    }
}

export default FilterQueryHelper