import Log from 'utils/log'
import { AjaxClientError, logError } from 'utils/errorHandler'
import jQuery from 'jquery'
import {FaultyPDFFileError} from "./BifogadHandlingException";

const repositoryUrl = '/api/ansokan-service/ansokan'
const log = new Log('BifogadKartskissRepository')

export class TooManyPagesInPdfException {
  constructor (errorMessage = '') {
    this.errorMessage = errorMessage
  }
}

export class UserAbortException {
  constructor (errorMessage = '') {
    this.errorMessage = errorMessage
  }
}

export class ForbiddenBifogadHandlingFiletypeException {
  constructor (errorMessage = '') {
    this.errorMessage = errorMessage
  }
}

export function get (fetcher, ansokanId, bifogadKartskissId) {
  const url = repositoryUrl + '/' + ansokanId + '/bifogadkartskiss/' + bifogadKartskissId
  return fetcher.fetching(url, { cache: 'force-cache' })
    .then((response) => {
      return response.json()
    }).then((bifogadKartskiss) => {
      log.debug('Bifogade kartskisser successfully loaded from server')
      return bifogadKartskiss
    }).catch((error) => {
      // Global ajax error handler will run automatically and display an error message
      log.error('Error encountered while loading list of bifogade kartskisser from server')
      throw error
    })

}

export function list (fetcher, ansokanId) {
  const url = repositoryUrl + '/' + ansokanId + '/bifogadkartskiss'
  return fetcher.fetching(url, { cache: 'no-store' })
    .then((response) => {
      return response.json()
    }).then((bifogadeKartskisser) => {
      log.debug('Bifogade kartskisser successfully loaded from server')
      return bifogadeKartskisser
    }).catch((error) => {
      // Global ajax error handler will run automatically and display an error message
      log.error('Error encountered while loading list of bifogade kartskisser from server')
      throw error
    })
}

export function upload (ajaxDriver, namn, ansokanId, bifogadKartskiss, progressCallback, xhrProxy) {

  const formData = new FormData()
  formData.append('file', bifogadKartskiss)
  formData.append('namn', namn)

  return new Promise((resolve, reject) => {
    xhrProxy.xhr = ajaxDriver({
      method: 'POST',
      url: repositoryUrl + '/' + ansokanId + '/bifogadkartskiss',
      contentType: false,
      cache: false,
      processData: false,
      data: formData,
      global: false,
      xhr: () => {
        const xhr = jQuery.ajaxSettings.xhr()
        xhr.upload.onprogress = progressCallback
        return xhr
      }
    }).done(() => {
      log.debug('Bifogad kartskiss successfully uploaded to server')
      resolve()
    }).fail((jqXHR, textStatus, errorThrown) => {
      // As we want custom kartskiss of errors in this query we're disabling the automatic global
      // ajax error kartskiss with the query option 'global: false' and need to handle it all ourselves.
      if (jqXHR.userForcedAbort) {
        reject(new UserAbortException('User has aborted the upload!'))
      } else {
        log.error('Error encountered while uploading bifogad kartskiss to server')
        const error = new AjaxClientError(repositoryUrl, jqXHR.status, errorThrown, jqXHR.responseText)
        logError(error)

        if (jqXHR.status === 403 && jqXHR.responseText && jqXHR.responseText.includes('InvalidFileTypeForBifogadHandlingException')) {
          reject(new ForbiddenBifogadHandlingFiletypeException('Uploaded file was rejected, one cause for this is that the file contained a MIME-type which is not allowed'))
        } else if (jqXHR.status === 403 && jqXHR.responseText && jqXHR.responseText.includes('TooManyPagesInBifogadKartskissException')) {
          reject(new TooManyPagesInPdfException('Fail'))
        } else if (jqXHR.status === 500 && jqXHR.responseText && jqXHR.responseText.includes('FaultyPDFFileError')) {
          reject(new FaultyPDFFileError('Faulty PDF-file uploaded'))
        } else {
          reject(error)
        }
      }
    })
  })
}

export function remove (fetcher, ansokanId, kartskissId) {
  if (!kartskissId || !ansokanId) {
    throw new Error('The id argument is missing')
  }
  const url = repositoryUrl + '/' + ansokanId + '/bifogadkartskiss/' + kartskissId

  return fetcher.fetching(url, { cache: 'no-store', method: 'DELETE' })
    .then(() => {
      log.info('Bifogad kartskiss successfully deleted on server', kartskissId)
    }).catch((error) => {
      // Global ajax errro handler will run automatically and display an error message
      log.error('Error encountered while deleting bifogad kartskiss on server')
      throw error
    })
}
