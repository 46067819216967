import { createJqueryAjaxDriver, SessionTimeoutError } from '../../utils/repositoryHelpers'

const backendMiddleware = ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    return action(dispatch, getState)
  }

  const { backendPromise, types, callbacks, ...rest } = action // eslint-disable-line no-redeclare
  if (!backendPromise) {
    return next(action)
  }

  const [REQUEST, SUCCESS, FAILURE] = types

  next({ ...rest, type: REQUEST })
  if (callbacks && callbacks.pending) {
    action.callbacks.pending()
  }

  return backendPromise(createJqueryAjaxDriver(getState, dispatch, callbacks), getState()).then(
    (result) => {
      next({ ...rest, result, type: SUCCESS })
      if (callbacks && callbacks.success) {
        callbacks.success(result)
      }
    },
    (error) => {
      next({ ...rest, error, type: FAILURE })
      // If there is a onFail callback call it unless the actual error was
      // a session timeout as there is no point in trying to recover from those
      if (callbacks && callbacks.fail && !(error instanceof SessionTimeoutError)) {
        callbacks.fail(error)
      }
    }
  ).catch((error) => {
    console.error('MIDDLEWARE ERROR:', error) // eslint-disable-line no-console
    next({ ...rest, error, type: FAILURE })
    throw error
  })
}

export default backendMiddleware
