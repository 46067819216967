import {useContext, useEffect, useState} from "react";
import {useAppDispatch} from "hooks";
import {useTranslation} from "react-i18next";
//@ts-ignore
import {ButtonGroup, Column, FormFieldset, ModalDialogAlert, PageTopContent, Row} from "redet-react-components";
import {
    failAnnanFastighet,
    loadRegisterbeteckningFunc,
    mapToFastighetAndSlimDownData,
    RegisterbeteckningFranRegbetWrapper
} from 'redux/modules/fastigheter';
import {LoadFastighetException} from "repository/fastighetRepository";
import {logError} from "utils/errorHandler";
import {Fastighet} from "views";
import FastighetsvalSearchBoxUnconnected from "views/Fastighetsval/FastighetsvalSearchBoxUnconnected";
import {AnsokanWizardSDGContext} from "./AnsokanWizardSDGContext";
import LinkButton from "./LinksForSDG";
import {scrollToMainTag} from "./util";

function ChoiceOfRealEstate() {

    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const {fastigheter, setFastigheter} = useContext(AnsokanWizardSDGContext)
    const [showErrorModal, setShowErrorModal] = useState(false)

    useEffect(() => {
        scrollToMainTag()
    }, []);

    function loadFastighetFromBeteckning(registerbeteckning: string) {
        dispatch(loadRegisterbeteckningFunc(registerbeteckning.trim(), []))
            .then((response: RegisterbeteckningFranRegbetWrapper) => {
                const beteckning = mapToFastighetAndSlimDownData(response.beteckning)
                const fastighetsData = {
                    registerbeteckning: beteckning.registerbeteckning.text,
                    data: beteckning,
                    objektidentitet: beteckning.objektidentitet,
                    enhetsomrade: [] as []
                }
                //Lägger till fastighet i listan om det inte redan finns en med samma objektidentitet
                if (!fastigheter.find(fastigheter => fastigheter.objektidentitet === beteckning.objektidentitet)) {
                    setFastigheter([...fastigheter, fastighetsData])
                }
            })
            .catch((error: any) => {
                if (error instanceof LoadFastighetException) {
                    dispatch(failAnnanFastighet(error.errorMessage))

                } else {
                    error.component = 'ChoiceOfRealEstate.tsx'
                    logError(error, 'ChoiceOfRealEstate-component')
                    setShowErrorModal(true)
                }
            })
    }

    function removeFastighet(registerbeteckning: string) {
        setFastigheter((fastigheter: any) => fastigheter.filter((beteckning: any) => beteckning.registerbeteckning !== registerbeteckning))
    }

    function hideErrorModal() {
        setShowErrorModal(false)
    }

    return (
        <>
            <PageTopContent title={t('property.title')} headerLevel={2} headerId={'realEstateHeaderId'}/>
            <br/>
            <div className="sdg-realestate">
                <Row>
                    <Column columns="col-12 col-lg-6">
                        <FormFieldset>
                            <FastighetsvalSearchBoxUnconnected swedishLetters={true}
                                                            searchFastighetCallback={(fastighetsbeteckning: string) => loadFastighetFromBeteckning(fastighetsbeteckning)}/>
                        </FormFieldset>
                        <Fastighet
                            andraFastigheter={fastigheter}
                            deleteMethod={(beteckning) => removeFastighet(beteckning)}
                            egnaFastigheter={[]}
                        />
                    </Column>
                </Row>
            </div>
            <div className="toolbar mobile-toolbar">
                <ButtonGroup>
                    <LinkButton to={"/euCitizenApplication/additionalDocuments"} icon="arrow_back"
                                text={t('property.backBtn')}/>
                </ButtonGroup>
                <ButtonGroup>
                    <LinkButton to={"/euCitizenApplication/reviewAndSubmit"} disabled={fastigheter.length === 0}
                                icon="arrow_forward" text={t('property.frwrdBtn')}/>
                </ButtonGroup>
            </div>
            {showErrorModal &&
                <ModalDialogAlert
                    modalTitle={t('property.modalTitle')}
                    ingress={t('property.modalDesciption')}
                    description={<span>{t('property.technicalError')} <a
                        href="https://www.lantmateriet.se/kontakt">{t('property.customerService')}</a>.</span>}
                    //@ts-ignore
                    acceptButton={{text: 'Ok', icon: 'check', onClick: hideErrorModal}}
                />
            }
            {/* Ta fram om du vill använda dig av React Query Devtools */}
            {/* {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools initialIsOpen={false} />}  */}
        </>
    )
}

export default (ChoiceOfRealEstate);


