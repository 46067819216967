import avstyckningImg from './pictures/avstyckning.gif'
import avstyckning3dImg from './pictures/3d_fastighet.png'
import fastighetsregleringImg from './pictures/fastighetsreglering.gif'
import klyvningImg from './pictures/klyvning.gif'
import sammanlaggningImg from './pictures/sammanlaggning.gif'
//@ts-ignore
import {ButtonToolbar, ExternalLink, LinkButtonSub, SidePanelChapterHeading, SidePanelSection} from 'redet-react-components'
import React from "react";
import {Term} from "../../../index";

export const ForandringHelpContent = <>
  <SidePanelChapterHeading header={'Beskriv åtgärd'}
                           sectionTitle={'Åtgärder'}
                           introduction={<>Detta är vad vi kallar för yrkande. Här ska du alltså ange vad du vill få
                             genomfört vid
                             lantmäteriförrättningen. Rita in de förändringar du vill göra i kartan. Det kan exempelvis
                             vara ändring av en
                             befintlig
                             gräns eller ett område som ska bli en ny fastighet.
                             <br/>
                             <br/>
                             Välj den åtgärd som stämmer in på vad du vill genomföra och besvara följdfrågorna.
                             Förtydliga gärna dina önskemål
                             med
                             hjälp av fritextrutan. Där kan du till exempel förtydliga den kartskiss du bifogar med
                             texter på kartan.</>}/>
  <SidePanelSection heading="Avstyckning" id={'term-avstyckning'}>
    <p>
      <img src={avstyckningImg} alt={'Bild som visar hur en avstyckning går till'}/>
      Avstyckning är när en bit mark avskiljs från en fastighet och bildar en ny egen fastighet. Det kan exempelvis ske
      i samband med att
      någon
      köpt mark för att bygga ett bostadshus. Avstyckning kan även ske utan att det finns något köp som grund. I samband
      med avstyckningen
      kan
      vi även skapa servitut mellan de berörda fastigheterna. Ett servitut är en rättighet för en fastighet att på ett
      visst sätt använda en
      annan fastighet, till exempel rätt för den nya fastigheten att använda väg för utfart.
      <br/>
    </p>
    <h5>Inteckningsfri avstyckning</h5>
    <p>
      Det kan vara en fördel att göra den avstyckade fastigheten fri från inteckningar som gäller
      i den fastighet marken avskiljs från.
      Detta är särskilt vanligt om den nya fastigheten och den ursprungliga fastigheten inte ska ha samma ägare.
      Om du inte gör den nya fastigheten inteckningsfri gäller eventuella inteckningar i båda fastigheterna.<br/>
      <br/>
      Läs mer:
      <br/>
      <Term
          label="Servitut"
          moment="help-description"
          term="term-servitut"
      />
      <Term
          label="Överföring av fastighetstillbehör"
          moment="help-description"
          term="term-fastighetstillbehor"
      />
      <Term
          label="Oinskriven rättighet"
          moment="help-description"
          term="term-oinskrivna-rattighetsinnehavare"
      />
    </p>
  </SidePanelSection>
  <SidePanelSection heading="Fastighetsreglering" id={'term-fastighetsreglering'}>
    <p>
      <img src={fastighetsregleringImg} alt={'Bild som visar hur en fastighetsreglering går till'}/>
      En fastighetsreglering kan innebära flera olika saker. En hel fastighet, del av en fastighet eller
      fastighetstillbehör kan föras över
      från
      en fastighet till en annan. Fastighetsreglering kan även innebära att en rättighet, ett servitut, skapas för en
      fastighet att använda
      en
      del av en annan fastighet för visst ändamål. Exempel på servitut kan vara att en fastighet har rätt att använda
      väg över
      grannfastigheten.
      Vid en fastighetsreglering kan även en marksamfällighet skapas ändras eller tas bort. En samfällighet innebär att
      du äger viss andel
      av
      mark eller anläggningar utanför din fastighet tillsammans med dina grannar. I en fastighetsreglering kan också
      andelar i en
      samfällighet
      föras över från en fastighet till en annan.<br/>
      <br/>
      Läs mer:
      <br/>
      <Term
          label="Ändamål"
          moment="help-description"
          term="term-andamal"
      />
      <br/>
      <Term
          label="Samfällighet"
          moment="help-description"
          term="term-samfallighet"
      />
      <br/>
      <Term
          label="Avtalsrättighet"
          moment="help-description"
          term="term-avtalsservitut-markoverforing"
      />
      <br/>
      <Term
          label="Oinskriven rättighet"
          moment="help-description"
          term="term-oinskrivna-rattighetsinnehavare"
      />
      <br/>
      <Term
          label="Servitut"
          moment="help-description"
          term="term-servitut"
      />
      <br/>
      <Term
          label="Överföring av fastighetstillbehör"
          moment="help-description"
          term="term-fastighetstillbehor"
      />
    </p>
  </SidePanelSection>
  <SidePanelSection heading="Sammanläggning" id={'term-sammanlaggning'}>
    <p>
      <img src={sammanlaggningImg} alt={'Bild som visar hur en sammanläggning går till'}/>
      Vid sammanläggning läggs fastigheter ihop som har samma ägare och ägs med lika rätt. Det innebär att det vid
      sammanläggning blir färre
      fastigheter än vad som fanns inledningsvis.
      Ett villkor för att sammanläggning ska kunna ske är att den som vill göra sammanläggningen har lagfart på
      fastigheten eller har
      vilande
      lagfart och där det inte finns några villkor som skulle kunna innebär att lagfart inte senare kommer att beviljas.
      Makar som äger var sin fastighet och dessa är giftorättsgods får lägga samman dessa fastigheter. Skulle den ena
      maken äga sin
      fastighet
      som giftorättsgods och den andra som enskild egendom kan dessa inte läggas samman. Giftorättsgods är i motsats
      till enskild egendom
      något
      som ska ingå i en bodelning i samband med äktenskapsskillnad eller om någon av makarna dör. Makars egendom är som
      huvudregel
      giftorättsgods, och man måste registrera ett äktenskapsförord, en gåva mellan makar eller en bodelning för att den
      inte längre ska
      vara
      det. Enskild egendom är egendom som en maka eller make har fått genom gåva, äktenskapsförord eller testamente, om
      den som givit
      egendomen
      skrivit att den ska vara enskild. Enskild egendom ingår inte i en bodelning, utan är makans eller makens
      personliga.
    </p>
  </SidePanelSection>
  <SidePanelSection heading="Klyvning" id={'term-klyvning'}>
    <p>
      <img src={klyvningImg} alt={'Bild som visar hur en klyvning går till'}/>
      Att dela en fastighet som ägs av flera personer kallas klyvning. Vid en klyvning upphör den ursprungliga
      fastigheten att finnas
      samtidigt
      som nya fastigheter bildas. Alla delägare kan få varsin ny fastighet, men det går även att äga någon av de nya
      fastigheterna
      tillsammans
      med annan ursprunglig ägare. Ett exempel: Andersson, Petterson och Lundström äger 1/3 vardera av fastigheten Berga
      1:1. De vill
      upphöra
      med samägandet och i stället ha varsin fastighet. Efter förrättningen äger de varsin fastighet. Det kan även vara
      möjligt att
      Andersson
      och Pettersson äger en fastighet tillsammans efter förrättningen, de kommer då att äga ½ vardera av den nya
      fastigheten. Lundström
      kommer
      att vara ensam ägare på sin nya fastighet.<br/>
      <br/>
      Läs mer:
      <br/>
      <Term
          label="Servitut"
          moment="help-description"
          term="term-servitut"
      />
      <br/>
      <Term
          label="Ändamål"
          moment="help-description"
          term="term-andamal"
      />
      <br/>
      <Term
          label="Klyvningsfastighet"
          moment="help-description"
          term="term-klyvningsfastighet"
      />
      <br/>
      <Term
          label="Samfällighet"
          moment="help-description"
          term="term-samfallighet"
      />
      <br/>
      <Term
          label="Gemensamhetsanläggning"
          moment="help-description"
          term="term-gemensamhetsanlaggning"
      />
    </p>
  </SidePanelSection>
  <SidePanelSection heading="Servitut"
                    id={'term-servitut'}
                    text={'Ett servitut är en rättighet för en fastighet att använda mark eller anläggning inom en annan fastighet för ett visst ändamål. Det kan\n' +
                      '            till exempel innebära en rättighet att använda en väg över grannfastigheten. Ett servitut gäller i normalfallet för all framtid och\n' +
                      '            rättigheten är alltid kopplad till fastigheten oberoende av vem som äger den. Ett servitut bildat vid lantmäteriförrättning kallas för\n' +
                      '            officialservitut. Observera att vi endast får bilda servitut som är nödvändiga för fastighetens ändamål.'}
  />
  <SidePanelSection heading="Överföring av fastighetstillbehör"
                    id={'term-fastighetstillbehor'}
                    text={'Ett fastighetstillbehör kan vara en byggnad, en ledning, ett stängsel eller en annan anläggning som har byggts på fastigheten för ett\n' +
                      '            varaktigt användande. Genom ett beslut av lantmäterimyndigheten kan en byggnad eller annan anläggning som är fastighetstillbehör juridiskt\n' +
                      '            föras över från en fastighet till en annan i samband med att ett servitut bildas. Det kan också ske om ett officialservitut redan finns\n' +
                      '            (se servitut).'}
  />
  <SidePanelSection heading="Oinskriven rättighet"
                    id={'term-oinskrivna-rattighetsinnehavare'}
                    text={'Om någon har rätt att använda den del av fastigheten som berörs av förrättningen, t ex en arrendator eller någon som har servitut, ska du\n' +
                      '            informera oss om detta, eftersom rättigheten kan påverkas av förrättningen.'}
  />
  <SidePanelSection heading="Samfällighet"
                    id={'term-samfallighet'}
                    text={'Det finns många olika samfälligheter men i detta sammanhang avses marksamfälligheter, samfällt fiske eller t ex andelar i\n' +
                      '            häradsallmänningar. Ett markområde som hör till flera olika fastigheter gemensamt är ett exempel på en marksamfällighet.'}
  />
  <SidePanelSection heading="Gemensamhetsanläggning" id={'term-gemensamhetsanlaggning'}>
    <p>
      En fastighet består normalt av ett markområde med tillhörande byggnader. För att en fastighet ska kunna fungera
      behövs ibland tillgång
      till anläggningar utanför den egna fastighetens gränser, till exempel vägar eller vatten- och avloppsanläggningar.
      <br/>
      <br/>
      En gemensamhetsanläggning bildas genom en förrättning hos Lantmäterimyndigheten. Den ägs gemensamt av de
      fastigheter som har behov av
      anläggningen. I förrättningen bestäms hur de delägande fastigheterna ska fördela kostnaderna för anläggningens
      drift och underhåll.
    </p>
  </SidePanelSection>
  <SidePanelSection heading="Särskild Gränsutmärkning" id={'term-gransutmarkning'}>
    <p>
    Särskild gränsutmärkning betyder att Lantmäteriet återställer försvunna markeringar, markerar tidigare omarkerade gränspunkter och sätter om det behövs ut markeringar på nya ställen i gränsens sträckning.
    Gränsmärken som sätts ut vid en särskild gränsutmärkning dokumenteras på förrättningskartan och ett gränsutmärkningsbeslut fattas.
    <br/>
    <br/>
    Utmärkningen blir rättsligt bindande för framtiden.
    En förutsättning för särskild gränsutmärkning är att det inte finns några juridiska tveksamheter om gränsens läge.
    Om det skulle finnas sådana tveksamheter kan istället en Fastighetsbestämning behöva genomföras.
    </p>
  </SidePanelSection>
  <SidePanelSection heading="Fastighetsbestämning" id={'term-fastighetsbestamning'}>
    <p>En fastighetsbestämning kan innebära utredning och beslut om:</p>
    <ul>
      <li>Hur gränserna går mellan olika fastigheter.</li>
      <li>Ett servitut eller en ledningsrätt gäller och i vilken omfattning.</li>
      <li>Byggnader på fastigheten ingår i äganderätten till fastigheten.</li>
      <li>Vilken omfattning en gemensamhetsanläggning har.</li>
    </ul>
  </SidePanelSection>
  <SidePanelSection heading="Vunnit laga kraft"
                    id={'term-vunnit-laga-kraft'}
                    text={'När Lantmäterimyndigheten har meddelat beslut i förrättningen kan du som är berörd av förrättningen klaga (lämna besvär) på besluten.\n' +
                      '            Normalt ska det ske inom fyra veckor räknat från den dag beslut meddelats i förrättningen. Om ingen klagar på besluten vinner\n' +
                      '            förrättningen laga kraft när besvärstiden är slut.'}
  />
  <SidePanelSection heading="Klyvningsfastighet"
                    id={'term-klyvningsfastighet'}
                    text={'Den fastighet som i förrättningen ska delas upp mellan delägarna kallas klyvningsfastighet. Klyvningsfastigheten kommer att ersättas med\n' +
                      '            nya fastigheter. En fastighet som ska klyvas kan bara delas upp till max det antal fastigheter som det finns delägare ifrån början. Dvs\n' +
                      '            ägs fastigheten av fyra delägare går det bara att bilda två, tre eller fyra nya fastigheter. Det är efter förrättningen möjligt att vissa\n' +
                      '            av delägarna fortsätter att äga en av de nybildade fastigheterna tillsammans. Varje delägare får vara ägare till max en av de nybildade\n' +
                      '            fastigheterna.'}
  />
  <SidePanelSection heading="Ändamål"
                    id={'term-andamal'}
                    text={'En fastighets ändamål kan t ex vara permanentbostad, fritidsbostad, jord- och/eller skogsbruksändamål, industri eller bostad med mark för\n' +
                      '            mindre odling/djurhållning/husbehovsved. I förrättningen behöver Lantmäterimyndigheten pröva om fastigheterna blir lämpliga för sitt\n' +
                      '            ändamål.'}
  />
  <SidePanelSection heading="Avtalsrättighet - Marköverföring"
                    id={'term-avtalsservitut-markoverforing'}
                    text={'Vid marköverföring är grundregeln att all mark överförs fri från belastningar så som avtalsservitut, nyttjanderätter eller arrenden. Det\n' +
                      '            är du som markägare som måste begära att dessa rättigheter ska fortsätta att gälla även efter marköverföringen, genom att informera\n' +
                      '            Lantmäterimyndigheten. Om du inte gör en begäran om detta kan du bli skadeståndsskyldig, för den skada en nyttjanderättshavare eller\n' +
                      '            arrendator lider till följd av att rättigheterna inte tryggas i förrättningen.'}
  />
  <SidePanelSection heading="Tredimensionell fastighet" id={'term-avstyckning3d'}>
    <p>
      <img src={avstyckning3dImg} alt={'Bild som visar hur en 3D-avstyckning går till'}/>
      De flesta fastigheter är en yta på marken, men en fastighet kan också vara under jord (som ett garage) eller
      ovanför marken (som en
      ägarlägenhet i ett bostadshus). Garaget och ägarlägenheten är exempel på tredimensionella fastigheter. Fördelarna
      med tredimensionella
      fastigheter är till exempel att byggnader och anläggningar i stadskärnor kan användas effektivare, genom att
      befintliga hus byggs på
      med
      ytterligare våningsplan eller att det byggs bostäder, affärer och kontor över spårområden. En förutsättning för
      att få bilda en
      tredimensionell fastighet är att fastigheten är avsedd att inrymma en byggnad eller annan anläggning eller en del
      av en sådan. Det
      förutsätts också att den tredimensionella fastigheten leder till en mer ändamålsenlig förvaltning av anläggningen
      eller att den behövs
      för
      finansieringen eller uppförandet. En tredimensionell fastighet för bostadsändamål måste inrymma minst tre
      bostadslägenheter, sk
      ägarlägenheter.
    </p>
  </SidePanelSection>
</>
