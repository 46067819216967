import {combineReducers} from 'redux'
import backendMiddleware from 'redux/middleware/backendMiddleware'
import {allOurReducers} from 'redux/reducer'
import finalFormReducer from './redux/finalForm'
import reduxDataLoaderReducer from './redux/ReduxDataLoaderReducer'
import reduceReducers from 'reduce-reducers'
import ansokanRepositoryMiddleware from './redux/middleware/ansokanRepositoryMiddleware'
import validationMiddleware from './redux/middleware/validationMiddleware'
import kartskissMiddleware from './redux/middleware/kartskissMiddleware'
import cleanUpAtgarderMiddleware from './redux/middleware/cleanUpAtgarderMiddleware'
import {configureStore} from '@reduxjs/toolkit'

let middleware = [backendMiddleware,
    kartskissMiddleware,
    validationMiddleware,
    cleanUpAtgarderMiddleware,
];



//Workaround för bugg som gör att rootstate tappar typning: https://github.com/reduxjs/redux-toolkit/issues/1831
const rootReducer = combineReducers({
    form: reduceReducers(reduxDataLoaderReducer, finalFormReducer),
    ...allOurReducers
});


// Don't apply the ansokanRepositoryMiddleware when we're running unit tests
if (process.env.NODE_ENV !== 'test') {
    middleware = [...middleware, ansokanRepositoryMiddleware]
}

if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware, require('redux-immutable-state-invariant').default()]
}

export const createStore = (preloadedState?: any) => {
    return configureStore({
        preloadedState: preloadedState,
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(middleware)
    })
}

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
