import { validationResult } from '../modules/validation/validation'
import validators from '../modules/validation/rules'
import { LOAD_SUCCESSFUL, SAVE_PENDING } from 'redux/modules/ansokanCRUD'
import {
  ACTION_CLOSE_UPLOAD_FORM,
  ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS,
  NO_PENDING_FILES,
  PENDING_FILES
} from 'redux/modules/bifogadHandling'
import { UPDATE_FIELD_STATE } from 'redux/finalForm'
import { ACTION_FETCH_KARTSKISSER_SUCCESS } from '../modules/kartskiss'

// avgör vilka redux-action som ska trigga validering av formuläret
const shouldTriggerValidation = (action) => {
  return action.type === SAVE_PENDING
    || action.type === LOAD_SUCCESSFUL
    || action.type === UPDATE_FIELD_STATE
    || action.type === PENDING_FILES
    || action.type === NO_PENDING_FILES
    || action.type === ACTION_CLOSE_UPLOAD_FORM
    || action.type === ACTION_FETCH_KARTSKISSER_SUCCESS
    || action.type === ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS
}

// redux-middleware för formulärvalidering
// validerar formuläret och skickar en lista med alla valideringsfel
// som en ny redux-action
const validationMiddleware = ({ getState, dispatch }) => next => action => {
  // behandla aktuell action innan eventuell validering
  const result = next(action)

  if (shouldTriggerValidation(action)) {
    const state = getState()
    const errors = validators.flatMap(validationRule => validationRule(state)).filter(validationError => validationError !== undefined)
    dispatch(validationResult(errors))
  }

  return result
}

export default validationMiddleware
