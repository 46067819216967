// @flow
import { BLOCKING, INGA_VALIDERINGSFEL_FINNS_FOR_ATGARDER } from './constants'
import { validateAvstyckningForm } from '../../../../components/Fastighetsforandring/Fastigheter/AvstyckningForm'
import { validateAvstyckning3dForm } from '../../../../components/Fastighetsforandring/Fastigheter/Avstyckning3dForm'
import {
  validateFastighetsregleringForm
} from '../../../../components/Fastighetsforandring/Fastigheter/FastighetsregleringForm'
import { validateKlyvningForm } from '../../../../components/Fastighetsforandring/Fastigheter/KlyvningForm'
import { validateSammanlaggningForm } from '../../../../components/Fastighetsforandring/Fastigheter/SammanlaggningForm'
import { Validation } from './model/Validation'
import { validateGransUtmarkningForm } from 'components/Fastighetsforandring/Fastigheter/GransUtmarkningForm'
import { validateFatighetsBestamningForm } from 'components/Fastighetsforandring/Fastigheter/FastighetsBestamningForm'

const createErrorMessage = function (atgarderWithErrors: Array<string>): string {
  const base = 'Alla fält är inte ifyllda för '
  if (atgarderWithErrors.length === 1) {
    return `${base}${atgarderWithErrors[0]}-åtgärden.`
  } else if (atgarderWithErrors.length === 2) {
    return `${base}åtgärderna ${atgarderWithErrors[0]} och ${atgarderWithErrors[1]}.`
  } else {
    const lastAtgard = atgarderWithErrors.pop()
    return `${base}åtgärderna ${atgarderWithErrors.join(', ')} och ${lastAtgard}.`
  }
}

const hasValidationErrors = function (errors) {
  if (errors === undefined) {
    return false
  }
  return Object.keys(errors)?.length > 0
}

function validate (state: Object): ?Validation {
  //ifall man är på första sidan så ska inget valideringsfel köraas ut
  if(state.fastigheter?.firstStepFinished === false) {
    return undefined;
  }
  const atgarderWithErrors = []
  if (hasValidationErrors(validateAvstyckningForm(state?.form?.avstyckning))) {
    atgarderWithErrors.push('avstyckning')
  }
  if (hasValidationErrors(validateAvstyckning3dForm(state?.form?.avstyckning))) {
    atgarderWithErrors.push('3d-avstyckning')
  }
  if (hasValidationErrors(validateFastighetsregleringForm(state?.form?.fastighetsreglering))) {
    atgarderWithErrors.push('fastighetsreglering')
  }
  if (hasValidationErrors(validateKlyvningForm(state?.form?.klyvning))) {
    atgarderWithErrors.push('klyvning')
  }
  if (hasValidationErrors(validateSammanlaggningForm(state?.form?.sammanlaggning))) {
    atgarderWithErrors.push('sammanläggning')
  }
  if (hasValidationErrors(validateGransUtmarkningForm(state?.form?.gransutmarkning))) {
    atgarderWithErrors.push('gränsutmärkning')
  }
  if (hasValidationErrors(validateFatighetsBestamningForm(state?.form?.fastighetsbestamning))) {
    atgarderWithErrors.push('fastighetsbestämning')
  }

  if (atgarderWithErrors.length > 0 && !window.location.pathname.includes("/start")) {
    const message = createErrorMessage(atgarderWithErrors)
          return new Validation(INGA_VALIDERINGSFEL_FINNS_FOR_ATGARDER, message, 'atgard', BLOCKING, undefined)
  }
  return undefined
}

export default validate
