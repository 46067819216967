import Log from 'utils/log';
import {Collection} from "ol";

export default class FeatureHandler {
  constructor() {
    this.log = new Log(this.constructor.name);
  }

  getFastighetFeatures() {
    return this.fastighetFeatures;
  }

  getFastighetFeaturesRaw() {
    return this.fastighetFeaturesRaw;
  }

  isDrawingFeature (feature) {
    return this.getDrawingFeatures().getArray().includes(feature)
  }

  getDrawingFeatures() {
    return this.drawingFeatures;
  }

  init() {
    this.fastighetFeatures = new Collection();
    this.fastighetFeaturesRaw = new Collection();
    this.drawingFeatures = new Collection();

  }
}
