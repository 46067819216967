import React, { useState } from 'react'
import PropTypes from 'prop-types'
//@ts-ignore
import { ButtonToolbar, CircleLoading, LinkButtonTop, TileWizardDialog, } from 'redet-react-components'

type BestyrkanProps = {
  closeModalCallback: Function,
  bestyrkanUrl: string,
  attachToDomElementWithId?: string
}
function BestyrkanWizardModal (props: BestyrkanProps) {
  
  const [activeIndex, setActiveIndex] = useState(0)

  const getBestyrkanTiles = () => {
    return [
      {
        title: 'Steg 1',
        pendingDescription: (
          <>
            För att bestyrka handligen klickar du på{' '}
            <strong>Gå till bestyrkan</strong>, en ny flik öppnas där du
            bestyrker att handlingen överensstämmer med originalet.
          </>
        ),
        children: getLinkButton(1, 'Gå till bestyrkan'),
      },
      {
        title: 'Steg 2',
        pendingDescription:
          'Bestyrk kopian, kom ihåg att granska de bifogade handlingarna. När du väljer att bestyrka kopian gör du det genom ytterligare en legitimering med hjälp av din e-legitimation. När bestyrkan är färdig kan du stänga den nya fliken och fortsätta här.',
        children: (
          <CircleLoading text="Bestyrkan pågår..." isFocusTrapped={false} id="bestyrkanModalLoader"/>
        ),
      },
    ]
  }

  const getLinkButton = (nextIndex: number, buttonText: string) => {
    return (
      <ButtonToolbar>
        <LinkButtonTop
          text={buttonText}
          link={props.bestyrkanUrl}
          isExternalLink={true}
          isOpenedInNewTab={true}
          icon="open_in_new"
          id="goToBestyrkanBtn"
          onClick={() => setActiveIndex(nextIndex)}
        ></LinkButtonTop>
      </ButtonToolbar>
    )
  }

  return (
    <TileWizardDialog
      id="tileWizardBestyrkan"
      title="Bestyrk handling"
      description={
        <>
          Du behöver nu bestyrka att kopian överensstämmer med originalet. Om du
          inte har tillgång till BankID eller om du av någon anledning behöver
          avbryta klickar du på <strong>Avbryt bestyrkan</strong> längst ned i
          den här rutan.
        </>
      }
      activeIndex={activeIndex}
      tiles={getBestyrkanTiles()}
      closeModalCallback={props.closeModalCallback}
      cancelButtonText="Avbryt bestyrkan"
      firstFocus="goToBestyrkanBtn"
      attachToDomElementWithId={props.attachToDomElementWithId}
    ></TileWizardDialog>
  )
}

export default BestyrkanWizardModal
