export type SigningStatus = {
    status: SIGNINGINSTANCE_STATUS,
    dokumentNamn: string,
    signerStatus: SignerStatus[]
  }
  
  export type SignerStatus = {
    status: SIGNER_STATUS,
    signerEmail: string,
    signingUrl: string
  }

  // Must match se.lantmateriet.enak.komplettering.model.responses.SigningStatusResponse (samma som ansokan signing status)
  
  export enum SIGNINGINSTANCE_STATUS {
    NOT_STARTED = 'NOT_STARTED',
    PENDING = 'PENDING',
    SUCCEEDED = 'SUCCEEDED',
    TIMED_OUT = 'TIMED_OUT',
    DECLINED = 'DECLINED'
  }

  export enum SIGNER_STATUS {
    APPROVED = "APPROVED",
    NONE = "NONE",
    DECLINED = "DECLINED"
  }