import React from 'react'

type OmbudForDescriptionType = {
  ombudNamn: string,
  ombudEpost?: string,
  isSokande: boolean,
  /**
   * En lista med namn på kontakter som företräds av ombudet
   */
  namnList: string[],
  id: string
}

export function OmbudForDescription(props:OmbudForDescriptionType) {

  return (
      <>
      {props.namnList && props.namnList.length > 0 &&
      <span className="attention" {...(props.id && {id: props.id})}>
        {`
        ${props.ombudNamn} ${(props.ombudEpost && !props.isSokande) ? '(' + props.ombudEpost + ')' : ''}
        skriver under ${props.isSokande ? 'för egen räkning samt ' : ''}som företrädare för
        `}
        {props.namnList.map((namn, index) =>
            <React.Fragment key={`ombudForKey${index}`}>
              <strong>{namn}</strong>
              {(index + 2 < props.namnList.length ? ', ' : '') + (index + 2 === props.namnList.length ? ' och ' : '')}
            </React.Fragment>
        )}
    </span>
      }
        </>
  )
}

export default OmbudForDescription

