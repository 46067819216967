import {ChangeEvent, useState} from 'react'
// @ts-ignore
import {ARENDE_STATUS, ARENDE_STATUS_TYPES} from 'utils/globalConstants'
import {Form} from 'react-final-form'
import {TextInputFinalForm} from 'components/General/forms/finalForm'
// @ts-ignore
import {ButtonSub, ButtonToolbar, Column, FormFieldset, PageSubContent, Row, Select} from 'redet-react-components'

let resetFormFunction: Function;

type ArendeListFilterProps = {
    filter: ArendeFilterType | undefined,
    onFilterChange: (filter: ArendeFilterType) => void
}

export type ArendeFilterType = {
    ankomstDatum: Date|null,
    arendenummer: string,
    handlaggare: string,
    fastighetsbeteckning?: string,
    status: string|null
}

export const DefaultArendeFilter: ArendeFilterType = {
    ankomstDatum: null,
    arendenummer: "",
    handlaggare: "",
    fastighetsbeteckning: "",
    status: null
}

export const ArendeListFilter = (props: ArendeListFilterProps) => {

    const arendeStatusOptions = ARENDE_STATUS_TYPES.map((type) => ({
        key: type.value,
        value: type.value,
        description: type.description
    }))

    const [arendeFilter, setArendeFilter] = useState<ArendeFilterType>(props.filter || DefaultArendeFilter)

    const clearInput = () => {
        setArendeFilter(DefaultArendeFilter)
        resetFormFunction({})
        props.onFilterChange(DefaultArendeFilter)
    }

    /**
     * Omvandlar ett Date till en datumsträng i ISO-format
     * @param ankomstDatum ett ankomstdatum representerat som ett Date
     * @return en sträng på formatet yyyy-MM-dd
     */
    const getISODateStringFromDate = (ankomstDatum: Date | null): string => {
        return ankomstDatum == null ? "" : ankomstDatum.toISOString().slice(0, 10);
    }

    /**
     * Returnerar ett nytt {@link ArendeFilterType}-objekt med anskomstdatum
     * @param dateString Ett datum på formatet yyyy-MM-dd
     * @return ett nytt {@link ArendeFilterType}-objekt
     */
    const arendeFilterWithAnkomstdatum = (dateString: string): ArendeFilterType => {
        if (dateString === null || dateString === "") {
            return {...arendeFilter, ankomstDatum: null}
        } else {
            return {...arendeFilter, ankomstDatum: new Date(dateString)}
        }
    }

    return (
        <div>
            <PageSubContent title={'Filtrering'} headerLevel={3} headerId={'filterHeaderId'}>
                <FormFieldset id="arende-filter">
                    <Row>
                        <Column columns="col-xs-12 col-sm-6">
                            <div className="form-group">
                                <label className="label" htmlFor="AnkomstdatumFran">Ankomstdatum från</label>
                                <input type="date" className="form-input-text" id="AnkomstdatumFran" aria-label="Ankomstdatum från" max="9999-12-31"
                                       value={getISODateStringFromDate(arendeFilter.ankomstDatum)}
                                       onChange={(event: ChangeEvent<HTMLInputElement>) => setArendeFilter(arendeFilterWithAnkomstdatum(event.target.value))}
                                />
                            </div>
                        </Column>
                        <Column columns="col-xs-12 col-sm-6">
                            <Select
                                id="FilterStatusId" label="Status"
                                value={arendeFilter.status || ""}
                                options={arendeStatusOptions}
                                onChange={(evt: any) => {
                                    setArendeFilter({
                                        ...arendeFilter,
                                        status: evt.target.value === ARENDE_STATUS.ALLA ? DefaultArendeFilter.status : evt.target.value
                                    })
                                }} />
                        </Column>
                    </Row>
                    <Form onSubmit={() => {}} initialValues={arendeFilter} subscription={{submitting: true, pristine: true}} >
                        {(handleSubmit) => {
                            resetFormFunction = handleSubmit.form.reset
                            return (
                                <form onSubmit={() => {}}>
                                    <Row>
                                        <Column columns="col-xs-12 col-sm-6">
                                            <TextInputFinalForm field="arendenummer" label="Ärendenummer"
                                                                placeholder="börjar med" maxLength={20}
                                                                onChange={(evt: any) => setArendeFilter({
                                                                    ...arendeFilter,
                                                                    arendenummer: evt.target.value
                                                                })}
                                                                term={undefined}
                                                                termPostfixText={undefined}
                                                                required={undefined} />
                                        </Column>
                                        <Column columns="col-xs-12 col-sm-6">
                                            <TextInputFinalForm field="handlaggare" label="Handläggare"
                                                                placeholder="börjar med" maxLength={100}
                                                                onChange={(evt: any) => setArendeFilter({
                                                                    ...arendeFilter,
                                                                    handlaggare: evt.target.value
                                                                })}
                                                                term={undefined}
                                                                termPostfixText={undefined}
                                                                required={undefined} />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column columns="col-xs-12 col-sm-6">
                                            <TextInputFinalForm field="fastighetsbeteckning"
                                                                label="Fastighetsbeteckning" placeholder="börjar med"
                                                                maxLength={150}
                                                                onChange={(evt: any) => setArendeFilter({
                                                                    ...arendeFilter,
                                                                    fastighetsbeteckning: evt.target.value
                                                                })}
                                                                term={undefined}
                                                                termPostfixText={undefined}
                                                                required={undefined} />
                                        </Column>
                                    </Row>
                                </form>
                            )
                        }}
                    </Form>
                    </FormFieldset>
                <ButtonToolbar>
                    <ButtonSub text="Filtrera" icon="search" id="arendeListFilterSubmitButton" onClick={() => props.onFilterChange(arendeFilter)} />
                    <ButtonSub text="Återställ" icon="clear" id="arendeListFilterClearButton" onClick={() => clearInput()} /> </ButtonToolbar>
            </PageSubContent>
        </div>
    )
}

export default ArendeListFilter

