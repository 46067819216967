export const bygglov_forhandsbesked = <>
  <p>Tillstånd eller besked från den kommunala byggnadsnämnden som visar att du får
    uppföra viss byggnad eller anläggning.
    <br/>Exempel på handlingar är:</p>

  <ul>
    <li>Bygglov</li>
    <li>Förhandsbesked om bygglov</li>
  </ul>

  <p><strong>Tänk på att det behöver bestyrkas att kopian på bygglovet eller förhandsbeskedet överensstämmer med
    originalet.</strong></p>
</>
