import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { scrollToElement } from 'utils/scrollToElement'
import { displayContactForm } from 'redux/modules/contacts'
import { connect } from 'react-redux'
//@ts-ignore
import { ButtonToolbar, ButtonTop } from 'redet-react-components'

type ContactProps = {
  isEditing: boolean,
  displayContactForm: Function
}

function ContactFormActionButtons(props: ContactProps) {
    return (
      <ButtonToolbar>
        <ButtonTop
          text={props.isEditing ? 'Spara' : 'Lägg till'}
          id="saveContactChangesButton"
          type="submit"
          data-selenium-id="spara-kontaktuppgift"
          icon={props.isEditing ? 'check' : 'add'}
        />
        <ButtonTop
          id="discardContactChangesButton"
          text="Avbryt"
          data-selenium-id="avbryt-kontaktuppgift"
          icon="clear"
          onClick={() => scrollToElement('#section-kontaktuppgifter', () => props.displayContactForm(false))}
        />
      </ButtonToolbar>
    )
}

export default connect(undefined, { displayContactForm })(ContactFormActionButtons)

