import React, {PropsWithChildren} from "react";
// @ts-ignore
import {ExternalLink} from "redet-react-components";
import {Term} from "../views";

/**
 * hjälp för att parsa en tag i locale .json filen till en extern-länk
 * ex <external_link href='www.url.nu'>Klicka här</external_link>
 * @param href länkens url
 * @param children texten inom taggen
 * @param rest
 * @constructor
 */
export const EXTERNAL_LINK = ({href = '', id, children, ...rest}: PropsWithChildren<{ href?: string, id?: string }>) => {
    return <ExternalLink text={children?.toString() || ''} url={href} id={id} {...rest}/>
}

/**
 * hjälp för att parsa en tag i locale .json filen till en fil-öppnar-länk (public-foldern)
 * ex <file_link file='hundvalp.jpg'>Öppna mig</file_link>
 * @param file filens namn i /public
 * @param children texten inom taggen
 * @param rest
 * @constructor
 */
export const FILE_LINK = ({file = '', id, children, ...rest}: PropsWithChildren<{ file?: string, id?: string }>) => {
    const getFileHref = (fileName: string) => `${process.env.PUBLIC_URL}/${fileName}`
    return <ExternalLink text={children?.toString() || ''} url={getFileHref(file)} id={id} {...rest}/>
}

/**
 * hjälp för att parsa en tag i locale .json filen till en fil-nedladdnings-länk
 * ex <download_link file='hundvalp.jpg'>Ladda ner mig</download_link>
 * @param file filens namn i /public
 * @param children texten inom taggen
 * @param rest
 * @constructor
 */
export const DOWNLOAD_LINK = ({file = '', id, children, ...rest}: PropsWithChildren<{ file?: string, id?: string }>) => {
    const getDownloadLinkHref = (fileName: string) => `${process.env.PUBLIC_URL}/${fileName}`
    return <a href={getDownloadLinkHref(file)} id={id} className="download-link" download={file} {...rest}>{children}</a>
}

/**
 * hjälp för att parsa en tag i locale .json filen till en länk för hjälp-sektionen
 * ex. <help_term>hjälp mig</help_term>
 * @param moment se <Term />
 * @param term se <Term />
 * @param children texten inom taggen
 * @constructor
 */
export const HELP_TERM_LINK = ({moment, term, children}: PropsWithChildren<{ moment: string, term?: string}>) => {
    return <Term label={children?.toString() || ''} moment={moment} term={term}/>
}

