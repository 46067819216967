import {Aterkallande, AterkallandeKvittensResponse, AterkallandeRender, AterkallandeRequest} from "./model/aterkallande";
import {Aktbilagebeteckning, Epost, Telefonnummer} from "../../domain/Common";
import {submitAterkallande} from "./aterkallandeReducer";


function createAterkallandeInternal(AterkallandeRender: AterkallandeRender) : Aterkallande {
    return new Aterkallande(
        AterkallandeRender.arendenummer,
        AterkallandeRender.meddelande,
        AterkallandeRender.avsandare,
        new Epost(AterkallandeRender.epostKvittens),
        new Epost(AterkallandeRender.epostKontakt),
        new Telefonnummer(AterkallandeRender.telefon),
        AterkallandeRender.aktbilagebeteckning ? new Aktbilagebeteckning(AterkallandeRender.aktbilagebeteckning) : undefined,
    );
}

function createAterkallandeRequest(Aterkallande: Aterkallande) : AterkallandeRequest {
    return new AterkallandeRequest(Aterkallande);
}

export function submitAterkallandeToMyndighet(AterkallandeRender: AterkallandeRender, dispatch: any) : any {
    const aterkallande = createAterkallandeInternal(AterkallandeRender);
    const aterkallandeRequest = createAterkallandeRequest(aterkallande);
    return dispatch(submitAterkallande(aterkallandeRequest)).then((AterkallandeKvittens : AterkallandeKvittensResponse) => AterkallandeKvittens).catch((err: any) => {
        throw err;
    });
}





