import {Epost, Telefonnummer} from "../../../domain/Common";


export class Skrivelse {
    arendenummer: string
    meddelande: string
    avsandare: string
    epostKvittens: Epost
    epostKontakt?: Epost
    telefon?: Telefonnummer

    constructor(arendenummer: string, meddelande: string, avsandare: string, epostKvittens: Epost, epostKontakt?: Epost, telefon?: Telefonnummer) {
        this.arendenummer = arendenummer;
        this.meddelande = meddelande;
        this.avsandare = avsandare;
        this.epostKvittens = epostKvittens;
        this.epostKontakt = epostKontakt;
        this.telefon = telefon;
    }
}

export class SkrivelseRequest {
    arendenummer: string;
    meddelande: string;
    avsandare: string;
    epostKvittens: string;
    epostKontakt?: string;
    telefon?: string;

    constructor(skrivelse: Skrivelse) {
        this.arendenummer = skrivelse.arendenummer;
        this.meddelande = skrivelse.meddelande;
        this.avsandare = skrivelse.avsandare;
        this.epostKvittens = skrivelse.epostKvittens.epost;
        this.epostKontakt = skrivelse.epostKontakt?.epost;
        this.telefon = skrivelse.telefon?.nummer;
    }
}

export type SkrivelseRender = {
        arendenummer: string;
        meddelande: string;
        avsandare: string;
        epostKvittens: string;
        epostKontakt?: string;
        telefon?: string;
}

export type SkrivelseKvittensResponse = {
    inskickadDatum: string;
    kvittensEmailSuccessfullySent: boolean
}
