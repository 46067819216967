import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { IconLink, ListGroup, ListItem, ListItemActionButton, withPopover } from 'redet-react-components'

const mapStateToProps = (store, own) => {
  return {
    items: store.bifogadHandling.list,
  }
}

let IconLinkWithPopover

class BifogadeHandlingarList extends Component {

  constructor (props) {
    super(props)
    this.state = {
      bifogadHandlingNamn: '',
      bifogadHandlingId: ''
    }
    IconLinkWithPopover = withPopover(IconLink)
  }

  static propTypes = {
    ansokanId: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    deleteHandlingCallback: PropTypes.func.isRequired,
  }

  render () {
    return (
      <>
        <ListGroup id="bifogadeHandlingarList" title="Uppladdade handlingar">
          {this.props.items.map(item => {
            // Bör ersättas med replaceAll(' ',''). Detta smäller för närvarande i Jest.
            const itemId = (item.name + item.index.toString()).replace(/\s/g, '')
            const actionButtons = []

            actionButtons.push(
              <IconLinkWithPopover
                key={`${itemId}DownKey`}
                link={item.href}
                target={'_blank'}
                download={item.name}
                id={`downloadHandling${item.index}`}
                data-selenium-id={`downloadHandling${itemId}Id`}
                icon={'file_download'}
                ariaLabel={'Ladda ner handling'}
                popoverText={'Ladda ner den här handlingen'}/>)
            actionButtons.push(
              <ListItemActionButton
                key={`${itemId}DeleteKey`}
                id={`deleteHandling${item.index}`}
                data-selenium-id={`deleteHandling${itemId}Id`}
                icon={'delete'}
                ariaLabel={'Ta bort handling'}
                popover={{ text: 'Ta bort den här handlingen' }}
                onClick={() => this.props.deleteHandlingCallback(item.id)}
                name="deleteAttachmentButton"
              />)
            return (
              <ListItem
                key={`${itemId}Key`}
                id={itemId}
                header={item.name}
                description={item.size + ', ' + item.pages}
                actionButtons={actionButtons}>
              </ListItem>
            )
          })}
        </ListGroup>
      </>
    )
  }
}

export default connect(mapStateToProps, undefined)(BifogadeHandlingarList)
