import { Factory, hasMany, Model, Response } from 'miragejs'
import { v4 as uuid } from 'uuid'
import { SVARSTJANSAT_SVAR_ETJANST } from '../utils/globalConstants'

export let svarslistaInflections = function (inflect) {
  inflect.irregular('handling', 'handlingar')
  inflect.irregular('svarslista', 'svarslistor')
}
export let svarslistaModels = function () {
  return {
    handling: Model,
    svarslista: Model.extend({
      handling: hasMany(),
    })
  }
}
export let svarslistaDataFactories = function () {
  return {
    svarslista: Factory.extend({
      arendenummer (i) {
        return `Ä${i * 100000}`
      },
      id: uuid()
    }),
    handling: Factory.extend({
      namn (i) {
        return `Handling${i}`
      },
      aktbilagebeteckning (i) {
        const betckningar = ['A', 'B', 'U', 'K']
        return `${betckningar[i % betckningar.length]}${i}`
      }
    }),
  }
}
export let svarslistaRoutes = function () {
  const authHeader = { 'svarstjanst.passedauthentication': true }
  this.get('api/svarstjanst-backend/user/svarslistor/delgivning', (schema, request) => {
    const arendenummer = request.queryParams.arendenummer
    let svarslistor = schema.svarslistor.all()
    if (arendenummer)
      svarslistor = schema.svarslistor.where({ arendenummer: arendenummer })
    const pnr = request.requestHeaders['session.saml.last.attr.name.cn']
    switch (pnr) {
      case '191010102299': {
        return new Response(200, authHeader, svarslistor)
      }
      case 'status500pnr':
        return new Response(500, authHeader, [])
      case 'enakPassedauthenticationFalse':
        return new Response(200, authHeader, [])
      case 'personSomInteÄrMediEnLista':
        return new Response(200, authHeader, [])
      default: {
        return new Response(200, authHeader, svarslistor)
      }
    }
  })

  this.post('api/svarstjanst-backend/svarslista/:svarslistaId/svara', (schema, request) => {
    let svarslistaId = request.params.svarslistaId
    switch (svarslistaId) {
      case 'svarslistaId500':
        return new Response(500, authHeader, { 'felmeddelande': 'lorem impsum...' })
      case 'svarslistaId403':
        return new Response(403, authHeader, { 'felmeddelande': 'svaret kunde inte registreras då användaren redan svarat på denna svarslista' })
      default: {
        let svarslista = schema.svarslistor.findBy({ id: svarslistaId })
        if (svarslista === null)
          return new Response(404, authHeader)
        svarslista.destroy()
        return new Response(204, authHeader)
      }
    }
  })

  this.post('api/svarstjanst-backend/svarslista/delgivning/:svarslistaId/svara', (schema, request) => {
    let svarslistaId = request.params.svarslistaId
    switch (svarslistaId) {
      case 'svarslistaId500':
        return new Response(500, authHeader, { 'felmeddelande': 'lorem impsum...' })
      case 'svarslistaId403':
        return new Response(403, authHeader, { 'felmeddelande': 'Svaret kunde inte resgistreras då användaren redan svarat på denna svarslista' })
      default: {
        let svarslista = schema.svarslistor.findBy({ id: svarslistaId })
        if (svarslista === null)
          return new Response(404, authHeader)
        svarslista.destroy()
        return new Response(204, authHeader)
      }
    }
  })
}
export let svarslistaSeed = function (server) {
  server.create('svarslista',
    {
      'arendenummer': 'Ä123456',
      'id': 'eb9b91f3-b8e4-422f-a184-282c5e3f6720',
      'skapad': '2020-11-17T16:59:40.820009',
      'handlingar': [
        {
          'namn': 'Handling1',
          'aktbilagebeteckning': 'A1'
        },
        {
          'namn': 'Handling2',
          'aktbilagebeteckning': 'A2'
        }
      ]
    })
  server.create('svarslista', {
    'arendenummer': 'Ä234567',
    'id': '40205b74-085b-4e8b-ba66-9619eaed6380',
    'skapad': '2020-10-17T16:59:40.820009',
    'handlingar': [
      {
        'namn': 'Handling3',
        'aktbilagebeteckning': 'A3'
      },
      {
        'namn': 'Handling4',
        'aktbilagebeteckning': 'A4'
      }
    ]
  })
  server.create('svarslista', {
    'arendenummer': 'Ä345678',
    'id': '40205b74-085b-4e8b-ba66-9619eaed6381',
    'skapad': '2020-08-17T16:59:40.820009',
    'svar': {
      'svartyp': SVARSTJANSAT_SVAR_ETJANST,
      'svarstid': '2020-03-19T13:12:19.619172'
    },
    'handlingar': [
      {
        'namn': 'Handling6',
        'aktbilagebeteckning': 'A3'
      },
      {
        'namn': 'Handling7',
        'aktbilagebeteckning': 'A4'
      }
    ]
  })
  server.create('svarslista', {
    'arendenummer': 'TS0004',
    'id': '40205b74-085b-4e8b-ba66-9619eaed6382',
    'skapad': '2020-03-17T16:59:40.820009',
    'handlingar': [
      {
        'namn': 'Handling5',
        'aktbilagebeteckning': 'A3'
      },
      {
        'namn': 'Handling6',
        'aktbilagebeteckning': 'A4'
      }
    ]
  })
  server.create('svarslista', {
    'arendenummer': 'Ä678901',
    'id': '40205b74-085b-4e8b-ba66-9619eaed6383',
    'skapad': '2020-02-10T16:59:40.820009',
    'handlingar': [
      {
        'namn': 'Handling7',
        'aktbilagebeteckning': 'A3'
      },
      {
        'namn': 'Handling8',
        'aktbilagebeteckning': 'A4'
      }
    ]
  })
  server.create('svarslista', {
    'arendenummer': 'Ä678901',
    'id': '40205b74-085b-4e8b-ba66-9619eaed6384',
    'skapad': '2020-07-09T16:59:40.820009',
    'svar': {
      'svartyp': SVARSTJANSAT_SVAR_ETJANST,
      'svarstid': '2020-03-19T13:12:19.619172'
    },
    'handlingar': [
      {
        'namn': 'Handling8',
        'aktbilagebeteckning': 'A3'
      },
      {
        'namn': 'Handling9',
        'aktbilagebeteckning': 'A4'
      }
    ]
  })
}
