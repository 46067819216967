import {ListItemFlexGroup} from "../../../../General/list/ListItemFlexGroup";
import {ListItemFlex} from "../../../../General/list/ListItemFlex";
import React from "react";
// @ts-ignore
import {ListItemActionButton} from "redet-react-components";
import {ListItemStatus} from "../../../../../domain/ListItemStatus";
import {getLocalizedDateString} from "../../../../../helpers/dates";
import {ISO8601} from "../../../../../utils/globalConstants";

interface Props {
    id?: string,
    header: string,
    createdDate?: string
}

export const KompletteringProcessingListItem = ({id, header, createdDate, ...rest}: Props) => {

    const statusitem: ListItemStatus = {
        icon: 'schedule',
        text: 'Under bearbetning, kommer skickas in inom kort'
    }

    const formattedCreatedDateString = createdDate ? `Skapad ${getLocalizedDateString(createdDate, ISO8601)}` : undefined

    return (<ListItemFlex id={id} {...rest}>
        {{
            actions: <ListItemActionButton id={`pendingButton_${id}`}
                                           icon={'hourglass_full'}
                                           name={'pendingKompletteringButton'}
                                           isDisabled={true}
                                           ariaLabel={'Komplettering bearbetas'}
            />,
            items: <ListItemFlexGroup title={header} status={statusitem} description={formattedCreatedDateString}></ListItemFlexGroup>
        }}
    </ListItemFlex>)
}
