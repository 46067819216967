export class Telefonnummer {
    nummer: string
    constructor(nummer: string) {
        this.nummer = nummer;
    }
}

export class Epost {
    epost: string
    constructor(epost: string) {
       this.epost = epost;
    }
}
export class Aktbilagebeteckning {
    aktbilagebeteckning: string[]
    constructor(aktbilagebeteckning: string[]) {
        this.aktbilagebeteckning = aktbilagebeteckning;
    }
}