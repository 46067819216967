import Log from 'utils/log'
import { saveAndLoadAnsokan, saveAnsokan, setSaveCooldown, } from 'redux/modules/ansokanCRUD'
import { formProperties } from 'redux/formProperties'
import { UPDATE_FIELD_STATE, UPDATE_FORM_STATE } from '../finalForm'
import {
  ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS,
  ACTION_REMOVE_BIFOGAD_HANDLING_SUCCESS,
  ACTION_UPLOAD_BIFOGAD_HANLDING_SUCCESS,
  USER_CHOOSE_NOT_TO_UPLOAD_BIFOGAD_HANDLIG,
  USER_CHOOSE_TO_UPLOAD_BIFOGAD_HANDLIG
} from '../modules/bifogadHandling'
import {
  ACTION_REMOVE_KARTSKISS_SUCCESS,
  ACTION_UPLOAD_KARTSKISS_SUCCESS,
  USER_CHOOSE_NOT_TO_ADD_A_KARTSKISS,
  USER_CHOOSE_TO_ADD_A_KARTSKISS
} from '../modules/kartskiss'
import {
  ACTION_REMOVE_BIFOGAD_KARTSKISS_SUCCESS,
  ACTION_UPLOAD_BIFOGAD_KARTSKISS_SUCCESS
} from '../modules/bifogadKartskiss'
import { ANSOKAN_STATUS_DRAFT } from '../../utils/globalConstants'
import {logError} from "../../utils/errorHandler";

const ansokanRepositoryMiddleware = ({ dispatch, getState }) => next => action => {
  const result = next(action)

  // If data in any form is changing or we've manually initiated the save or load action
  if ((action.type === UPDATE_FORM_STATE || action.type === UPDATE_FIELD_STATE) && formProperties[action.form].save) {
    rebounceSave(dispatch, getState, 2000)
    return result
  } else if (!action.promise && action.shouldTriggerSaveWhenProcessed) {
    try {
      dispatch(saveAnsokan())
    } catch (err) {
      logError(err, 'Save error when saving triggered by: not promise and action.shouldTriggerSaveWhenProcessed')
    }
    return result
  } else if (!action.promise && action.shouldTriggerSaveWithLoadAnsokanWhenProcessed) {
    try{
      dispatch(saveAndLoadAnsokan())
    } catch (err) {
      logError(err, 'Save error when saving triggered by: not promise and action.shouldTriggerSaveWithLoadAnsokanWhenProcessed')
    }
    return result
  } else if (action.shouldTriggerSaveWithLoadAnsokanWhenProcessed) {
    try{
      dispatch(saveAndLoadAnsokan())
    } catch (err) {
      logError(err, 'Save error when saving triggered by ction.shouldTriggerSaveWhenProcessed')
    }
    return result
  }

  const actionsThatShouldTriggerSave = [USER_CHOOSE_NOT_TO_UPLOAD_BIFOGAD_HANDLIG,
    USER_CHOOSE_TO_UPLOAD_BIFOGAD_HANDLIG,
    USER_CHOOSE_NOT_TO_ADD_A_KARTSKISS,
    USER_CHOOSE_TO_ADD_A_KARTSKISS,
    ACTION_REMOVE_BIFOGAD_KARTSKISS_SUCCESS,
    ACTION_UPLOAD_BIFOGAD_KARTSKISS_SUCCESS,
    ACTION_REMOVE_KARTSKISS_SUCCESS,
    ACTION_UPLOAD_KARTSKISS_SUCCESS,
    ACTION_REMOVE_BIFOGAD_HANDLING_SUCCESS,
    ACTION_UPLOAD_BIFOGAD_HANLDING_SUCCESS,
    ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS,
  ]
  if (getState().ansokanRepository.ansokanStatus === ANSOKAN_STATUS_DRAFT && actionsThatShouldTriggerSave.includes(action.type)) {
    try{
      dispatch(saveAnsokan())
    } catch(err) {
      logError(err, 'Save error when saving for action: ', action.type)
    }
  }

  return result
}

/*
  Schedule a save operation, will automatically replace any
  existing operations that has been queued but not yet executed.
*/
function rebounceSave (dispatch, getState, delay) {
  const log = new Log('ansokanRepository')

  // Clear previously queued operation if exists
  if (window.saveTimeoutId !== null && getState().ansokanRepository.saveCooldown) {
    log.debug('Clear previously scheduled save operation that has not yet executed')
    clearTimeout(window.saveTimeoutId)
  }

  // Set cooldown lock
  dispatch(setSaveCooldown(true))

  // Set timeout and save timer id in window
  window.saveTimeoutId = setTimeout(() => {
    // Clear cooldown lock
    dispatch(setSaveCooldown(false))
    // Check if save operation was cancelled while this function were in timeout wait
    if (getState().ansokanRepository.remoteDataKey !== null) {
      // Store data
      try {
        dispatch(saveAnsokan())
      } catch (err) {
        logError(err, 'rebounceSave error')
      }
    } else {
      log.debug('Discarding scheduled save operation as the ansokan is no longer open')
    }
  }, delay)

  log.debug('Scheduled save operation')
}

export default ansokanRepositoryMiddleware
