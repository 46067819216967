export const DISPLAY_VALIDATION_PANEL = 'help/DISPLAY_VALIDATION_PANEL';
export const DISPLAY = 'help/DISPLAY';
const TOGGLE = 'help/TOGGLE';
const MOMENT = 'help/MOMENT';
const TERM = 'help/TERM';
const SET_COMPACT = 'help/SET_COMPACT';
export const defaultHelpMoment = 'Välj moment';

const initialState = {
    initialized: true,
    visible: false,
    isValidationpanelOpen: false,
    moment: defaultHelpMoment,
    term: '',
    compact: false
};

type Action = {
    type: string
    moment: string
    visible: boolean
    title: string
    term: string
    compact: boolean
    visibleValidationpanel: boolean
}

export default function reducer(state = initialState, action: Action =
    {
        type: "",
        moment: "",
        visible: false,
        title: "",
        term: "",
        compact: false,
        visibleValidationpanel: false
    }
) {
    switch (action.type) {
        case MOMENT:
            return {
                ...state,
                moment: action.moment,
                term: '',
                visible: action.visible === undefined ? state.visible : action.visible,
                isValidationpanelOpen: action.visible === true ? false : state.isValidationpanelOpen,
                title: action.title
            };

        case TERM:
            return {
                ...state,
                visible: true,
                isValidationpanelOpen: false,
                moment: action.moment,
                term: action.term,
            };

        case SET_COMPACT:
            return {
                ...state,
                compact: action.compact
            };

        case DISPLAY:
            return {
                ...state,
                visible: action.visible,
                isValidationpanelOpen: action.visible === true ? false : state.isValidationpanelOpen,
            };

        case TOGGLE:
            return {
                ...state,
                visible: !state.visible,
                isValidationpanelOpen: !state.visible === true ? false : state.isValidationpanelOpen,
            };

        case DISPLAY_VALIDATION_PANEL:
            const validationpanelOpen = action.visibleValidationpanel ? action.visibleValidationpanel : !state.isValidationpanelOpen;
            return {
                ...state,
                isValidationpanelOpen: validationpanelOpen,
                visible: validationpanelOpen === true ? false : state.visible
            };

        default:
            return state;
    }
}

export function toggle() {
    return {
        type: TOGGLE
    };
}

export function show() {
    return {
        type: DISPLAY,
        visible: true
    };
}

/**
 * Togglar valideringspanelen, dvs fäller ut valideringspanelen om den är infälld och fäller in den om den är utfälld.
 *
 * @param disableToggleShowHide true avaktiverar toggle-beteendet och visar den även om den redan är utfälld
 */
export function showValidationpanel(disableToggleShowHide: boolean = false) {
    return {
        type: DISPLAY_VALIDATION_PANEL,
        visibleValidationpanel: disableToggleShowHide
    };
}

export function setCompact() {
    return {
        type: SET_COMPACT,
        compact: true
    };
}

export function setFullHeight() {
    return {
        type: SET_COMPACT,
        compact: false
    };
}

export function hide() {
    return {
        type: DISPLAY,
        visible: false
    };
}

export function visaMoment(moment_: string, title_?: string, keepOpen?: boolean) {
    return {
        type: MOMENT,
        moment: moment_,
        title: title_,
        visible: keepOpen
    };
}

export function visaTerm(moment_: string, term_: string) {
    return {
        type: TERM,
        visible: true,
        moment: moment_,
        term: term_
    };
}
