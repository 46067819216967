import { EXPORT_MAP_RATIO } from './mapConstants'

export default class CropManager {
  constructor (mapController, isCropModeActiveFunc) {
    this.mapController = mapController
    this.isCropModeActiveFunc = isCropModeActiveFunc
  }

  init () {
    document.getElementById('toolbarSpara').addEventListener('click', () => {
      this.calculateAndApplyCropCutoff()
    })

    window.addEventListener('resize', () => {
      if (this.isCropModeActiveFunc()) {
        if (this.cropCalculationTimeoutId === undefined) {
          this.cropCalculationTimeoutId = setTimeout(() => {
            this.calculateAndApplyCropCutoff()
            this.cropCalculationTimeoutId = undefined
          }, 200)
        }
      }
    })
  }

  applyCropCutoff (cropValues) {
    const overlays = document.getElementsByClassName('cutout')[0]

    if (overlays) {
      overlays.getElementsByClassName('left')[0].style.width = cropValues.xCutOff + 'px'
      overlays.getElementsByClassName('right')[0].style.width = cropValues.xCutOff + 'px'
      overlays.getElementsByClassName('top')[0].style.height = cropValues.yCutOff + 'px'
      overlays.getElementsByClassName('bottom')[0].style.height = cropValues.yCutOff + 'px'
    }
  }

  calculateCropCutoff () {
    const MIN_CROP_DIV_SIZE = 20

    const cropValues = { xCutOff: MIN_CROP_DIV_SIZE, yCutOff: MIN_CROP_DIV_SIZE }

    const map = this.mapController.getMap()
    if (map) {
      const mapSizeX = map.getSize()[0]
      const mapSizeY = map.getSize()[1]
      const mapRatio = mapSizeX / mapSizeY
      if (mapRatio > EXPORT_MAP_RATIO.ratioValue) {
        const onePartOfRatio = mapSizeY / EXPORT_MAP_RATIO.xRatio
        const widthToDisplay = onePartOfRatio * EXPORT_MAP_RATIO.yRatio
        cropValues.xCutOff = Math.round((mapSizeX - widthToDisplay) / 2) + MIN_CROP_DIV_SIZE
      } else {
        const onePartOfRatio = mapSizeX / EXPORT_MAP_RATIO.yRatio
        const heightToDisplay = onePartOfRatio * EXPORT_MAP_RATIO.xRatio
        cropValues.yCutOff = Math.round((mapSizeY - heightToDisplay) / 2) + MIN_CROP_DIV_SIZE
      }
    }

    return cropValues
  }

  calculateAndApplyCropCutoff () {
    const cropValues = this.calculateCropCutoff()
    this.applyCropCutoff(cropValues)
  }
}
