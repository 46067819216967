import React, { useState } from 'react';
import { ListGroup } from "redet-react-components";
import { FastighetsInnehav } from 'redux/modules/FastighetsInnehav';
import { Fastighet } from "../../domain/Ansokan";
//@ts-ignore
import { Checkbox, CheckboxFormGroup } from "redet-react-components";

type props = {
    valdaFastigheter: Fastighet[],
    fastigheter: FastighetsInnehav[],
    onClick: (regbet: string, checked: boolean) => void
}

export function Fastighetsvalslista(props: props) {

    const [lokalLista, setLokalLista] = useState<FastighetsInnehav[]>([])

    function isChosen(fastighet: FastighetsInnehav) {
        return !!(props.valdaFastigheter.find((item: Fastighet) => item.registerbeteckning === fastighet.beteckning))
    }

    function onClickParentCallback(fastighet: FastighetsInnehav) {
        return (event: React.MouseEvent<HTMLInputElement>) => {
            // @ts-ignore
            props.onClick(fastighet.beteckning, event.target.checked)
        }
    }

    return (
        <CheckboxFormGroup id="fastighetsinnehav-lista">
            <ListGroup
                listOptions={{
                    list: props.fastigheter,
                    listSetter: setLokalLista,
                    paginationConfig: {initPage: 0, maxNoPageButtons: 5, pageSize: 5, onPageChangeScrollToTopOfList: false}
                }}>
                {lokalLista
                    .sort((current, next) => (current.beteckning < next.beteckning) ? -1 : (current.beteckning > next.beteckning) ? 1 : 0)
                    .map(fastighet =>
                        <div role={"listitem"}>
                        <Checkbox key={fastighet.beteckning}
                                  id={`fastighetsvalcheckbox_${fastighet.beteckning}`}
                                  label={fastighet.beteckning}
                                  isChecked={isChosen(fastighet)}
                                  onChange={onClickParentCallback(fastighet)}
                                  description={
                                      <a href={`https://minfastighet.lantmateriet.se/webapi/fastighet/v1.0/${fastighet.id}`}
                                         target="_blank"
                                         rel="noreferrer"
                                         className="external-link">Visa i Min fastighet</a>
                                  }
                        />
                        </div>
                    )
                }
            </ListGroup>
        </CheckboxFormGroup>
    )
}

Fastighetsvalslista.defaultProps = {
    valdaFastigheter: [],
    fastigheter: []
}

export default Fastighetsvalslista