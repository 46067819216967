import classNames from 'classnames'

type FullscreenLoadingProps = {
  hideContent: boolean,
  text?: string,
  fullscreen?: boolean
}

export default function FullscreenLoading (props: FullscreenLoadingProps) {
  return (
    <div id={'circleLoadingUniqueId'} data-testid={'circleLoadingTestId'}
         className={classNames({ 'fullscreen-animation': props.fullscreen }, { 'hide-content': props.hideContent })}
         role="status">
      <div className="circle-animation">
        <span className="block"/>
        <span className="block"/>
        <span className="block"/>
        <span className="block"/>
        <span className="block"/>
        <span className="block"/>
        <span className="block"/>
        <span className="block"/>
        {props.text !== '' &&
          <span className="text">{props.text}</span>
        }
      </div>
    </div>
  )
}

FullscreenLoading.defaultProps = {
  hideContent: true,
  fullscreen: true
}

