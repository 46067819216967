import axios from "axios";
import {
    FaultyPDFFileError,
    ForbiddenBifogadHandlingFiletypeException, ForbiddenKompletteringException,
    InvalidEmailException,
    TooManyFilesInBifogadHandlingException,
    UserAbortException
} from "./BifogadHandlingException";
import {logError} from "../utils/errorHandler";
import Log from "../utils/log";

const repositoryUrl = '/api/komplettering-service/komplettering'
const log = new Log('kompletteringYtterligareHandlingRepository')

export type YtterligareHandlingRequest = {
    type: string
    description: string
    files: Array<any>
    inbjudanEmail: string
    notifieringEmail: string
}

export function uploadBifogadHandlingKomplettering (arendenummer:string, bifogadHandling:YtterligareHandlingRequest, onProgressCallback: (percent?: number) => void, abortController: AbortController = new AbortController()) {
    //create form data
    const formData = new FormData()
    formData.append('arendeId', arendenummer)
    formData.append('type', bifogadHandling.type)
    formData.append('typeDescription', bifogadHandling.description)
    formData.append('notifieringEmail', bifogadHandling.notifieringEmail)
    formData.append('inbjudanEmail', bifogadHandling.inbjudanEmail)
    for (const file of bifogadHandling.files) {
        formData.append('files', file)
    }
    const {signal} = abortController;
    return axios.post(
        `${repositoryUrl}/ytterligarehandling`,
        formData,
        {
            onUploadProgress: ({progress = 1}) => onProgressCallback(Math.ceil(progress * 100)),
            signal
        })
        .then(response => {
            if (response.status === 201) {
                const kompletteringId = response.data as string
                log.info('Komplettering successfully uploaded to server')
                return kompletteringId
            } else {
                throw new Error(`Misslyckades med att ladda upp kompletterig ytterligare handling, status ${response.status}`)
            }
        })
        .catch(error => {
            if (signal.aborted) {
                throw new UserAbortException('User has aborted the upload!')
            }
            logError(error, 'Error encountered while uploading kompletterig ytterligare handling')
            const errorCode = error?.response?.status
            const errorData = error?.response?.data
            if (errorCode === 403 && errorData?.includes('ForbiddenKompletteringException')) {
                throw new ForbiddenKompletteringException('Något blev fel vid uppladdning av komplettering')
            } else if (errorCode === 400 && errorData?.includes('ForbiddenBifogadHandlingException')) {
                throw new ForbiddenBifogadHandlingFiletypeException('Uploaded file was rejected, one cause for this is that the file contained a MIME-type which is not allowed')
            } else if (errorCode === 404 && errorData?.includes('ArendeNotFoundException')) {
                throw new ForbiddenKompletteringException('Ärendet med ärendenummer: ' + arendenummer + ' kunde inte hittas')
            } else if (errorCode === 400 && errorData?.includes('TooManyFilesInBifogadHandlingException')) {
                throw new TooManyFilesInBifogadHandlingException('Exceeded limit for number of files/pages in PDF for a bifogad handling')
            } else if (errorCode === 400 && errorData?.includes('InvalidEmailException')) {
                throw new InvalidEmailException('Invalid email submitted for notifiering or inbjudan')
            } else if (errorCode === 500 && errorData?.includes('FaultyPDFFileError')) {
                throw new FaultyPDFFileError('Faulty PDF-file uploaded')
            } else {
                throw new Error(`Misslyckades med att ladda upp komplettering ytterligare handling, status: ${errorCode}`)
            }
        })
}