import Log from 'utils/log'
import { Pointer } from 'ol/interaction'

export default class MoveInteraction {
  constructor (mapController, featureHandler, setCursorFunc, saveCurrentFeaturesFunc, ol, interactionDestructors) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.featureHandler = featureHandler
    this.setCursorFunc = setCursorFunc
    this.saveCurrentFeaturesFunc = saveCurrentFeaturesFunc
    this.ol = ol
    this.interactionDestructors = interactionDestructors
  }

  init () {
    let movingFeature = undefined
    let coordinate = undefined
    const cursor = 'pointer'
    let previousCursor = undefined

    const interaction = new Pointer({
      'handleDownEvent': (mapBrowserEvent) => {
        const clickedOnFeature = mapBrowserEvent.map.forEachFeatureAtPixel(mapBrowserEvent.pixel, (feature, layer) => {
          return (layer ? feature : null)
        })

        if (clickedOnFeature && this.featureHandler.isDrawingFeature(clickedOnFeature)) {
          movingFeature = clickedOnFeature
          coordinate = mapBrowserEvent.coordinate
          this.log.trace('Start feature move')
        }

        return movingFeature !== undefined
      },

      'handleDragEvent': (mapBrowserEvent) => {
        const deltaX = mapBrowserEvent.coordinate[0] - coordinate[0]
        const deltaY = mapBrowserEvent.coordinate[1] - coordinate[1]

        movingFeature.getGeometry().translate(deltaX, deltaY)

        coordinate[0] = mapBrowserEvent.coordinate[0]
        coordinate[1] = mapBrowserEvent.coordinate[1]
      },

      'handleMoveEvent': (mapBrowserEvent) => {
        const element = mapBrowserEvent.map.getTargetElement()
        if (movingFeature) {
          if (element.style.cursor !== cursor) {
            previousCursor = element.style.cursor
            element.style.cursor = cursor
          }
        } else if (previousCursor !== undefined) {
          element.style.cursor = previousCursor
          previousCursor = undefined
        }
      },

      'handleUpEvent': () => {
        this.log.trace('Stop feature move')
        movingFeature = undefined
        coordinate = undefined
        this.saveCurrentFeaturesFunc()
        return false
      }
    })

    this.interactionDestructors.push(() => {
      this.log.debug('Unregister MOVE interaction')
      this.mapController.getMap().removeInteraction(interaction)

      this.setCursorFunc('auto')
    })

    this.log.debug('Register MOVE interaction')
    this.mapController.getMap().addInteraction(interaction)

    this.setCursorFunc('pointer')
  }
}
