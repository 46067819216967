import { loadList } from 'repository/fastighetsinnehavRepository'
import { Fetcher } from '../../repository/fetcher'

export const LOAD = 'fastighetsinnehav/LOAD'
export const LOAD_SUCCESS = 'fastighetsinnehav/LOAD_SUCCESS'
export const LOAD_FAIL = 'fastighetsinnehav/LOAD_FAIL'

const initialState = {
  list: [],
  isLoading: true,
  error: false
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        list: action.result,
        isLoading: false,
        error: false
      }
    case LOAD:
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    default:
      return state
  }
}

export function loadingFastighetsinnehav () {
  return {
    type: LOAD,
  }
}

export function loadFastighetsinnehavSuccess (data) {
  return {
    type: LOAD_SUCCESS,
    result: data,
  }
}

export function loadFastighetsinnehavFailed () {
  return {
    type: LOAD_FAIL,
  }
}

export function loadFastighetsinnehav () {
  return function action (dispatch, getState) {
    dispatch(loadingFastighetsinnehav())
    const fetcher = new Fetcher(dispatch, getState, false)
    return loadList(fetcher, getState())
      .then(result => {
        dispatch(loadFastighetsinnehavSuccess(result))
        return result
      }).catch(() => {
        dispatch(loadFastighetsinnehavFailed())
      })
  }
}

export function setLoadingFlag () {
  return {
    type: LOAD,
  }
}
