import React from 'react'
import { ButtonGroup, ButtonToolbarV2, ButtonTop } from 'redet-react-components'

export const WizardIntorduction = ({ title, ingress, description, children }) => (
  <>
    {title && <legend>{title}</legend>}
    {ingress && <p className="introduction">{ingress}</p>}
    {description && <p>{description}</p>}
    {children}
  </>)

export const WizardNavigationButtonToolbar = ({
  prevButtonText,
  nextButtonText,
  onClickNextButton,
  onClickPrevButton,
  disableNextButton,
  disablePrevButton,
  children
}) => (
  <ButtonToolbarV2>
    <ButtonGroup>
      {onClickPrevButton && prevButtonText && <ButtonTop text={prevButtonText}
                                                         id="prevButtonId"
                                                         onClick={onClickPrevButton}
                                                         icon={'arrow_back'}
                                                         isDisabled={disablePrevButton}/>}
    </ButtonGroup>
    <ButtonGroup>
      {children}
      {onClickNextButton && <ButtonTop text={nextButtonText}
                                       id="nextButtonId"
                                       onClick={onClickNextButton}
                                       icon={'arrow_forward'}
                                       isDisabled={disableNextButton}/>}
    </ButtonGroup>
  </ButtonToolbarV2>)
