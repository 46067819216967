// @ts-ignore
import {FormFieldset, RadioButton} from "redet-react-components";
import {DeltarPaSammantrade} from "../../../../domain/Svarslistor";
import React, {ChangeEvent, useState} from "react";
import {enumToArray} from "../../../../helpers/enums";

type Props = {
    value: DeltarPaSammantrade,
    onValueChange?: (value: DeltarPaSammantrade) => void
}

function DelgivningSelectNarvaroSammantrade({value, onValueChange}: Props) {

    const [selected, setSelected] = useState(value)

    const onAttendanceChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const convert = (value: string) => enumToArray(DeltarPaSammantrade).find(enm => enm === value) ?? DeltarPaSammantrade.EJANGIVET
        const value = convert(evt.target.value)
        setSelected(value)
        if (onValueChange)
            onValueChange(value)
    }

    return (<FormFieldset>
        <div className='form-group'>
            <label className='label' htmlFor={'rdo-delta-sammantrade'}>Jag planerar att delta på sammanträde</label>
            <div className={'flex-line'}>
                <RadioButton label={'Ja'} value={DeltarPaSammantrade.JA}
                             name={'rdo-delta-sammantrade'}
                             id={'rdoNarvaroSammantradeJa'}
                             checked={selected === DeltarPaSammantrade.JA}
                             onChange={onAttendanceChange}/>
                <RadioButton label={'Nej'} value={DeltarPaSammantrade.NEJ}
                             name={'rdo-delta-sammantrade'}
                             id={'rdoNarvaroSammantradeNej'}
                             checked={selected === DeltarPaSammantrade.NEJ}
                             onChange={onAttendanceChange}/>
                <RadioButton label={'Ej angivet'} value={DeltarPaSammantrade.EJANGIVET}
                             name={'rdo-delta-sammantrade'}
                             id={'rdoNarvaroSammantradeEjAngivet'}
                             checked={selected === DeltarPaSammantrade.EJANGIVET}
                             onChange={onAttendanceChange}/>
            </div>
        </div>
    </FormFieldset>)
}

export default DelgivningSelectNarvaroSammantrade