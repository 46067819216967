import { SigningFormValues } from "domain/signing/SigningModels";
import {useEffect, useState} from "react";
// @ts-ignore
import {ButtonGroup, ButtonToolbarV2} from "redet-react-components";
import {useAppSelector} from "../../hooks";

const {
    ButtonSub,
    Checkbox,
    CircleLoading,
    ModalDialogSub,
} = require("redet-react-components");

type Props = {
    closeModalCallback: () => void;
    initiateHandlingarSigningCallback: () => void;
    formData?: SigningFormValues;
    isSigneringPending: boolean;
    attachCircleLoadingToDomElementWithId: string;
};

export function HederOchSamveteModalV2(props: Props) {
    const [hasConfirmedHandlingarPreview, setHasConfirmedHandlingarPreview] =
        useState(false);
    const [hasClickedFortsatt, setHasClickedFortsatt] = useState(false);

    const isPhosMockEnabled = useAppSelector(store => store?.featureToggle?.PHOS_MOCK_SIGNING_ENABLED)
    const showStart = !hasClickedFortsatt;
    const showLoading = hasClickedFortsatt && !props.isSigneringPending && !isPhosMockEnabled;
    const showWaitForBestyrka = props.isSigneringPending;

    useEffect(() => {
        if (props.formData !== undefined && !isPhosMockEnabled) {
            const form = document?.getElementById("bestyrkanform") as HTMLFormElement;
            setTimeout(() => form?.submit(), 5000)
        }
    }, [props.formData, isPhosMockEnabled]);

    function onClickFortsatt() {
        setHasClickedFortsatt(true);
        props.initiateHandlingarSigningCallback();
    }

    function onChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
        if (!hasClickedFortsatt) {
            setHasConfirmedHandlingarPreview(event.target.checked);
        }
    }

    return (
        <>
            {showStart && (
                <ModalDialogSub
                    id={"phosmodal"}
                    modalTitle={"Bestyrk handlingar"}
                    closeModalCallback={props.closeModalCallback}
                    useDefaultToolbar={false}
                    ingress={"Du kommer nu försäkra på heder och samvete att samtliga handlingar\n" +
                        "                            överensstämmer med originalet. Obs! Att försäkra något på heder\n" +
                        "                            och samvete som inte är sant kan leda till straffrättsligt ansvar."}
                >
                    <>
                        <Checkbox
                            label={"Jag har förhandsgranskat mina handlingar."}
                            name={"confirmHandlingarPreviewCheckbox"}
                            id={"confirmHandlingarPreviewCheckboxId"}
                            isChecked={hasConfirmedHandlingarPreview}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                onChangeCheckbox(event)
                            }
                        />
                        <ButtonToolbarV2>
                            <ButtonGroup>
                                <ButtonSub
                                    text={"Stäng"}
                                    icon={"clear"}
                                    onClick={props.closeModalCallback}
                                />
                            </ButtonGroup>
                            <ButtonGroup>
                                <ButtonSub
                                    text={"Fortsätt"}
                                    id={"phosModalToStepTwoButton"}
                                    isLoading={hasClickedFortsatt}
                                    isDisabled={
                                        hasClickedFortsatt || !hasConfirmedHandlingarPreview
                                    }
                                    onClick={
                                        hasConfirmedHandlingarPreview ? onClickFortsatt : undefined
                                    }
                                    icon={"arrow_forward"}
                                />
                            </ButtonGroup>
                        </ButtonToolbarV2>
                    </>
                </ModalDialogSub>
            )}
            {showLoading && (
                <CircleLoading
                    isFullscreen={true}
                    isFocusTrapped={false}
                    text={"Förbereder ..."}
                    attachToDomElementWithId={props.attachCircleLoadingToDomElementWithId}
                />
            )}
            {showWaitForBestyrka && props.formData &&  (
                <>
                    <CircleLoading text={"Du kommer nu skickas iväg till e-legitimering..."}
                                   isFocusTrapped={false}
                                   attachToDomElementWithId={props.attachCircleLoadingToDomElementWithId}
                                   isFullscreen={true}/>
                    <form
                        action={`${props.formData.action}`}
                        method='post'
                        id={"bestyrkanform"}>
                        <div>
                            <input type='hidden' name='Binding' value={`${props.formData.binding}`}/> <input
                            type='hidden' name='RelayState'
                            value={`${props.formData.relayState}`}/> <input type='hidden'
                                                                            name='EidSignRequest'
                                                                            value={`${props.formData.eidSignRequest}`}/>
                            <noscript>
                                <p>
                                    <strong>Notera:</strong> Din webbläsare stödjer inte Javascript så du måste
                                    klicka
                                    på Bestyrk-knappen.
                                </p>
                                <div>
                                    <input type='submit' value='Bestyrk'/>
                                </div>
                            </noscript>
                        </div>
                    </form>
                </>
            )}
        </>
    );
}
