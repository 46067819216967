import { createValidator } from '../../../utils/validation'
import { sokandeOrganizationMustHaveOmbud } from '../../../utils/contactsValidationsHelper'

export function organizationContactFormWarningValidation (formValues, contactList, contactId) {
  const organizationContactFieldWarningValidation = {
    rollSokande: [],
  }

  sokandeOrganizationMustHaveOmbud(formValues, contactList, contactId, organizationContactFieldWarningValidation)
  return createValidator(formValues, organizationContactFieldWarningValidation)

}