import {Model, Response, Server} from "miragejs";
import {BadRequest} from "./skrivelseRepository";

export let skrivelseModels = function () {
    return {
        skrivelse: Model,
    }
}

export function skrivelseRoutes (server: Server) {
    const authHeader = {'enak.passedauthentication': 'true', 'svarstjanst.passedauthentication': 'true'}
    server.put('/api/komplettering-service/komplettering/skrivelse', (schema, request) => {
        if(!request) {
            return new Response(500, authHeader, new BadRequest('AAAAAH!! ALLT ÄR ÄNNU VÄRRRRRRRREEEE!'))
        } else if (request.requestBody === undefined || request.requestBody === null) {
            return new Response(401, authHeader, new BadRequest('AAAAAH!! ALLT ÄR SKIT'))
        } else if (request.requestBody === 'null') {
            return new Response(200, authHeader, {badresponse: null})
        } else {
            return new Response(200, authHeader, {inskickadDatum: '2022-10-10 13:37', kvittensEmailSuccessfullySent: true})
        }
    })
}
