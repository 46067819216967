import {Fetcher} from "../../repository/fetcher";
import {AterkallandeKvittensResponse, AterkallandeRequest} from "./model/aterkallande";
import {formatToDateAndTime} from "../../helpers/dates";
import Log from "../../utils/log";

const apiUrl : string = '/api/komplettering-service/komplettering/aterkallande'
const log = new Log('aterkallandeRepository');
const APPLICATION_JSON : string = 'application/json'

export function submit(fetcher: Fetcher, aterkallande: AterkallandeRequest) : Promise<AterkallandeKvittensResponse> {
    return fetcher.fetching(apiUrl,
        {
            cache: 'no-store',
            method: 'PUT',
            body: JSON.stringify(aterkallande),
        }, { 'Content-Type': APPLICATION_JSON})
        .then((response) => {
            return response.json();
        }).then((response) => {
            if(response.inskickadDatum) {
                return {inskickadDatum: formatToDateAndTime(response.inskickadDatum), kvittensEmailSuccessfullySent: response.kvittensEmailSuccessfullySent}
            } throw new TypeError('kvittens.inskickadDatum är null eller undefined')
        }).catch((err) => {
            if(err instanceof TypeError) {
                log.error('An error occured while trying to create kvittens from submit-response', err)
                throw new JsonMappingFailure(`,An error occured while trying to map JSON with message: ${err}`);
            } else if(err.status === 401) {
                log.error('An error occured while trying to submit aterkallande to MM/komplettering-servce with status: ', err.status);
                throw new BadRequest(`An error occured while trying to submit aterkallande, reason: ${err.message}`);
            } else {
                log.error(`An error occured while trying to submit aterkallande to MM/komplettering-service with status: ${err.status} and message: ${err.message}`);
                throw new SubmitFailed(err.error);
            }
        })
}

export class JsonMappingFailure extends Error {
    message: string;
    constructor(message: string) {
        super(message);
        this.message = message;
    }
}

export class BadRequest extends Error {
    message: string;
    constructor(message: string) {
        super(message);
        this.message = message;
    }
}

export class SubmitFailed extends Error {
    message: string;
    constructor(message: string) {
        super(message);
        this.message = message;
    }
}