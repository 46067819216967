// @flow
// 1.1 En ansökan ska innehålla minst 1 fastighet
import { BLOCKING, MINST_EN_KARTSKISS } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  if ((state.kartskiss.numberOfRitadeKartor === 0 || state.kartskiss.numberOfRitadeKartor === undefined) &&
    (state.bifogadKartskiss.numberOfUppladdadeKartor === 0 || state.bifogadKartskiss.numberOfUppladdadeKartor === undefined) &&
    (state.kartskiss.hasUserChosenNotToAddAkartskiss === false || state.kartskiss.hasUserChosenNotToAddAkartskiss === undefined)) {
    return new Validation(MINST_EN_KARTSKISS, 'Du har inte bifogat eller skapat någon karta.', 'kartskiss', BLOCKING, undefined)
  }
  return undefined
}

export default validate
