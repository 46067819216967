import { useAppSelector } from 'hooks'
//@ts-ignore
import { CircleLoading } from 'redet-react-components'

export function FullscreenProgress() {

  const visible = useAppSelector((store) => store.fullscreenProgress.visible)
  const procentComplete = useAppSelector((store) => store.fullscreenProgress.procentComplete)
  const hidePercentage = useAppSelector((store) => store.fullscreenProgress.hidePercentage)
  const abortFunction = useAppSelector((store) => store.fullscreenProgress.abortFunction)
  const percentageComplete = !procentComplete ? 1 : procentComplete;

  return (
    <>
      {visible && <CircleLoading isFullscreen={true} ariaLabel={"Laddindikator"}
                                            id={'fullscreenProgressAbortButton'}
                                            button={{ onClick: abortFunction, text: 'Avbryt' }}
                                            percentageComplete={hidePercentage ? undefined : percentageComplete}
      />}
    </>
  )
}

export default FullscreenProgress;
