import Log from 'utils/log'
import { logError } from 'utils/errorHandler'
import MeasureTooltip from './MeasureTooltip'
import DeleteInteraction from './interactions/DeleteInteraction'
import LineStringInteraction from './interactions/LineStringInteraction'
import MeasureLineInteraction from './interactions/MeasureLineInteraction'
import MeasurePolygonInteraction from './interactions/MeasurePolygonInteraction'
import ModifyInteraction from './interactions/ModifyInteraction'
import MoveInteraction from './interactions/MoveInteraction'
import PointInteraction from './interactions/PointInteraction'
import PolygonInteraction from './interactions/PolygonInteraction'
import SnapInteraction from './interactions/SnapInteraction'
import TextInteraction from './interactions/TextInteraction'
import {
  DoubleClickZoom,
  DragBox,
  DragPan,
  DragZoom,
  KeyboardPan,
  KeyboardZoom,
  MouseWheelZoom,
  PinchZoom
} from 'ol/interaction'
import { platformModifierKeyOnly } from 'ol/events/condition'
import { unByKey } from 'ol/Observable'
import { Kinetic } from 'ol'

export const LINE_STRING = 'LineString'
export const POLYGON = 'Polygon'
export const POINT = 'Point'
export const MODIFY = 'Modify'
export const SNAP = 'Snap'
export const DELETE = 'Delete'
export const MOVE = 'Move'
export const TEXT = 'Text'
export const PAN = 'Pan'
export const MEASURE_LINE = 'MeasureLine'
export const MEASURE_POLYGON = 'MeasurePolygon'

export class MapInteractions {
  constructor (mapController, featureHandler, toolCoordinator, destructors, setCursorFunc, saveCurrentFeaturesFunc) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.featureHandler = featureHandler
    this.toolCoordinator = toolCoordinator
    this.destructors = destructors
    this.setCursorFunc = setCursorFunc
    this.saveCurrentFeaturesFunc = saveCurrentFeaturesFunc

    this.interactionDestructors = []
    this.ol = require('ol')
    this.isModifying = false
    this.measureTooltip = new MeasureTooltip(this.mapController)
    this.measureTooltip.rebuild()

    this.log.debug('Creating interactions')
    this.deleteInteraction = new DeleteInteraction(mapController, featureHandler, setCursorFunc, this.ol, this.interactionDestructors)
    this.lineStringInteraction = new LineStringInteraction(
      mapController,
      setCursorFunc,
      featureHandler,
      (...params) => this.registerCurrentMultipointInteraction(...params),
      () => this.unregisterCurrentMultipointInteraction(),
      this.measureTooltip,
      this.ol,
      this.interactionDestructors,
    )
    this.measureLineInteraction = new MeasureLineInteraction(
      mapController,
      this.measureTooltip,
      setCursorFunc,
      this.ol,
      featureHandler,
      this.interactionDestructors,
      (...params) => this.registerCurrentMultipointInteraction(...params),
      () => this.unregisterCurrentMultipointInteraction(),
    )
    this.measurePolygonInteraction = new MeasurePolygonInteraction(
      mapController,
      this.measureTooltip,
      setCursorFunc,
      this.ol,
      this.interactionDestructors,
      (...params) => this.registerCurrentMultipointInteraction(...params),
      () => this.unregisterCurrentMultipointInteraction(),
      featureHandler,
    )
    this.modifyInteraction = new ModifyInteraction(this.ol, mapController, featureHandler, setCursorFunc, this.interactionDestructors)
    this.moveInteraction = new MoveInteraction(mapController, featureHandler, setCursorFunc, saveCurrentFeaturesFunc, this.ol, this.interactionDestructors)
    this.pointInteraction = new PointInteraction(mapController, setCursorFunc, this.interactionDestructors, featureHandler, this.ol)
    this.polygonInteraction = new PolygonInteraction(
      mapController,
      this.measureTooltip,
      setCursorFunc,
      this.ol,
      this.interactionDestructors,
      (...params) => this.registerCurrentMultipointInteraction(...params),
      () => this.unregisterCurrentMultipointInteraction(),
      featureHandler,
    )
    this.snapInteraction = new SnapInteraction(mapController, this.interactionDestructors, featureHandler, this.ol)
    this.textInteraction = new TextInteraction(mapController, setCursorFunc, this.interactionDestructors, featureHandler, this.ol)
  }

  getMeasureTooltip () {
    return this.measureTooltip
  }

  setIsModifying (isModifying) {
    this.isModifying = isModifying
  }

  getDefaultInteractions () {
    const dragPan = new DragPan({ kinetic: new Kinetic(-0.01, 0.1, 200) })
    const dragBox = new DragBox({ condition: platformModifierKeyOnly })
    const doubleClickZoom = new DoubleClickZoom()
    const pinchZoom = new PinchZoom()
    const keyboardPan = new KeyboardPan()
    const keyboardZoom = new KeyboardZoom()
    const mouseWheelZoom = new MouseWheelZoom()
    const dragZoom = new DragZoom()
    return [doubleClickZoom, dragPan, pinchZoom, keyboardPan, keyboardZoom, mouseWheelZoom, dragZoom, dragBox]
  }

  addDefaultInteractionsToMap () {
    this.defaultInteractions = this.getDefaultInteractions()
    this.defaultInteractions.forEach((interaction) => this.mapController.getMap().addInteraction(interaction))
    this.addCustomCursorEventListerners()
  }

  addCustomCursorEventListerners () {
    const observableKey = this.mapController.getMap().on('pointermove', (event) => {
      // Show the hand-cursor when mouse is over a drawing feature
      if (this.toolCoordinator.getActiveTool() === MODIFY || this.toolCoordinator.getActiveTool() === MOVE || this.toolCoordinator.getActiveTool() === DELETE) {
        this.setCursorFunc('auto')
        this.mapController.getMap().forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          // Prevent hand-symbol for fastighetsgeometri
          if (layer && feature && this.featureHandler.isDrawingFeature(feature)) {
            this.setCursorFunc('pointer')
          }
        })
      }

      this.destructors.push(() => {
        unByKey(observableKey)
      })

      if (this.isDragging && !event.dragging && !this.isModifying) {
        this.log.debug('Stop dragging')
        // Reset cursor if it was changed when draggning started
      }

      if (!this.isDragging && event.dragging) {
        this.log.debug('Start dragging')
        // Would be nice if we could use the "grabbing" cursor here, but browser support is still experimental
      }

      this.isDragging = event.dragging
    })

  }

  lockInteractions () {
    this.log.debug('Lock map for editing')
    this.defaultInteractions.forEach((interaction) => {
      interaction.setActive(false)
    })
  }

  unlockInteractions () {
    this.log.debug('Unlock map')
    this.defaultInteractions.forEach((interaction) => {
      interaction.setActive(true)
    })
  }

  getCurrentMultiPointFeature () {
    return this.currentMultiPointFeature
  }

  unregisterCurrentMultipointInteraction () {
    this.currentMultiPointInteraction = null
    this.currentMultiPointFeature = null
  }

  registerCurrentMultipointInteraction (interaction, feature) {
    this.currentMultiPointInteraction = interaction
    this.currentMultiPointFeature = feature
  }

  activatePanInteraction () {
    // This is the default, only need to change pointer
    this.setCursorFunc('move')
  }

  activateModifyInteraction () {
    const modifyInteraction = this.modifyInteraction.init()
    this.snapInteraction.init()
    return modifyInteraction
  }

  activateDeleteInteraction () {
    this.deleteInteraction.init()
    this.snapInteraction.init()
  }

  activateMoveInteraction () {
    this.moveInteraction.init()
  }

  activateLineStringInteraction (drawMeasurements) {
    this.lineStringInteraction.init(drawMeasurements)
    this.snapInteraction.init()
  }

  activateMeasureLineInteraction () {
    this.measureLineInteraction.init()
    this.snapInteraction.init()
  }

  activatePolygonInteraction (drawMeasurements) {
    this.polygonInteraction.init(drawMeasurements)
    this.snapInteraction.init()
  }

  activateMeasurePolygonInteraction () {
    this.measurePolygonInteraction.init()
    this.snapInteraction.init()
  }

  activateTextInteraction () {
    this.textInteraction.init()
    this.snapInteraction.init()
  }

  activatePointInteraction () {
    this.pointInteraction.init()
    this.snapInteraction.init()
  }

  finishDrawing (mobile) {
    if (this.currentMultiPointFeature === undefined || this.currentMultiPointFeature === null) {
      logError(new Error('currentMultiPointFeature is null or undefined. Cancel execution of finishDrawing().'))
      return
    }
    if (this.currentMultiPointFeature.getGeometry() === undefined || this.currentMultiPointFeature.getGeometry() === null) {
      logError(new Error('currentMultiPointFeature.getGeometry() is null or undefined. Cancel execution of finishDrawing().'))
      return
    }
    switch (this.currentMultiPointFeature.getGeometry().getType()) {
      case LINE_STRING:
        this.finishDrawingLine()
        break
      case POLYGON:
        this.finishDrawingPolygon(mobile)
        break
      default:
        break
    }
  }

  finishDrawingLine () {
    const coordinates = this.currentMultiPointFeature.getGeometry().getCoordinates()
    const numberOfCoordinates = coordinates.length
    const numberOfDuplicateCoordinates = MapInteractions.getNumberOfDuplicateCoordinates(coordinates)
    this.log.debug('Number of duplicate coordinates in LineString: ', numberOfDuplicateCoordinates)
    if ((numberOfCoordinates - numberOfDuplicateCoordinates) < 2) {
      this.log.debug('Removing all points as shape is not finished')
      for (let i = 0; i < numberOfCoordinates; i++) {
        this.currentMultiPointInteraction.removeLastPoint()
      }
    } else {
      this.log.debug('Treating previous point as the last and finish drawing')
      this.currentMultiPointInteraction.finishDrawing()
    }
  }

  finishDrawingPolygon (mobile) {
    const coordinates = this.currentMultiPointFeature.getGeometry().getCoordinates()[0]
    const numberOfCoordinates = coordinates.length
    let numberOfDuplicateCoordinates = MapInteractions.getNumberOfDuplicateCoordinates(coordinates)
    if (mobile) {
      numberOfDuplicateCoordinates = numberOfDuplicateCoordinates - 1
    }
    this.log.debug('Number of duplicate coordinates in Polygon: ', numberOfDuplicateCoordinates)
    if (numberOfCoordinates - numberOfDuplicateCoordinates < 4) {
      this.log.debug('Removing all points as shape is not finished')
      for (let i = 0; i < numberOfCoordinates; i++) {
        this.currentMultiPointInteraction.removeLastPoint()
      }
    } else {
      this.log.debug('Treating previous point as the last and finish drawing')
      for (let i = 0; i < numberOfDuplicateCoordinates; i++) {
        this.currentMultiPointInteraction.removeLastPoint()
      }
      this.currentMultiPointInteraction.finishDrawing()
    }
  }

  static getNumberOfDuplicateCoordinates (coordinates) {
    if (coordinates === undefined || coordinates.length <= 1) {
      return 0
    }

    let numberOfDups = 0
    let previousCoordinate = coordinates[0]
    coordinates.shift()

    coordinates.forEach(coordinate => {
      if (coordinate.toString() === previousCoordinate.toString()) {
        numberOfDups++
      }
      previousCoordinate = coordinate
    })

    return numberOfDups
  }

  removeAllInteractions () {
    this.interactionDestructors.forEach((destructor) => {
      destructor()
    })
    // Clear array
    this.interactionDestructors.splice(0)
  }
}

export default MapInteractions
