import type {ValidationCategory, ValidationLink, ValidationSeverity} from '../constants'
import React, {ReactNode} from "react";

export class Validation {
    id: string
    message: ReactNode | string
    subMessage?: ReactNode | string
    child?: React.ReactNode
    category: ValidationCategory
    severity: ValidationSeverity
    link?: ValidationLink

    constructor(id: string, message: ReactNode | string, category: ValidationCategory, severity: ValidationSeverity, subMessage?: ReactNode | string, child?: React.ReactNode) {
        this.id = id
        this.category = category
        this.severity = severity
        this.link = this.getLinkDependingOnCategory()
        this.message = message
        this.subMessage = subMessage
        this.child = child
    }

    cloneWithNewMessage(msg: string | ReactNode, subMessage?: string | ReactNode): Validation {
        return new Validation(this.id, msg, this.category, this.severity, subMessage, this.child)
    }

    getLinkDependingOnCategory(): ValidationLink | undefined {
        switch (this.category) {
            case 'contacts':
                return 'kontakter'
            case 'atgard':
                return 'forandringar'
            case 'handlingar':
                return 'handlingar'
            case 'kartskiss':
                return 'kartskiss'
            case 'fastighet':
                return 'fastighet'
            default:
                return undefined
        }
    }
}
