// @ts-ignore
import {inflections} from 'inflected'
import {RestSerializer, Server} from 'miragejs'
import {svarslistaDataFactories, svarslistaInflections, svarslistaModels, svarslistaRoutes, svarslistaSeed} from './repository/svarslistaRepositoryMock'
import {ansokanRoutes} from './repository/ansokanApiMock'
import {
  kompletteringDataFactories,
  kompletteringGeneralRoutes,
  kompletteringInflections,
  kompletteringModels,
  kompletteringSeed,
  kompletteringYrkandeRoutes
} from './repository/kompletteringRepositoryMock'
import {togglyRoutes} from './repository/togglyApiMock'
import {validationErrorRoutes} from './repository/validationErrorRepositoryMock'
import {skrivelseModels, skrivelseRoutes} from "./components/skrivelse/skrivelseRepositoryMock";
import {signingRoutes} from "./repository/signingApiMock";
import {aterkallandeModels, aterkallandeRoutes} from "./components/aterkallande/aterkallandeRepositoryMock";

export function startApiMockServer (disableConsoleLogs = false, extraRoutes: Array<Function> = []) {

  let ApplicationSerializer = RestSerializer.extend({
    root: false, // Ta bort root-objektet. Ex: {svarslistor:[{lista...}]} -> {[{lista...}]} https://miragejs.com/api/classes/serializer/#root
    embed: true, // Baka ihop modeller.  https://miragejs.com/api/classes/serializer/#embed
    serializeIds: 'never',
  })

  // I vanliga fall så översätter mirage till engelsk pluralform. Så svarslista -> svarslistas, handling -> handlings.
  inflections('en', function (inflect: any) {
    svarslistaInflections(inflect)
    kompletteringInflections(inflect)
  })

  return new Server({
    serializers: {
      application: ApplicationSerializer,
    },
    // Json-modellerna
    models: { ...svarslistaModels(), ...kompletteringModels(), ...skrivelseModels(), ...aterkallandeModels() },
    // Mockdataskapare
    factories: { ...svarslistaDataFactories(), ...kompletteringDataFactories()},
    // Initialdata att mata databasen med.
    seeds (server) {
      svarslistaSeed(server)
      kompletteringSeed(server)
    },
    // Routes... precis. Dina endpoints.
    routes () {
      this.passthrough()
      this.logging = !disableConsoleLogs
      svarslistaRoutes.call(this)
      ansokanRoutes(this)
      togglyRoutes(this)
      // signingRoutes(this)
      kompletteringYrkandeRoutes.call(this)
      kompletteringGeneralRoutes.call(this)
      validationErrorRoutes(this)
      skrivelseRoutes(this)
      aterkallandeRoutes(this)
      extraRoutes.forEach(extraRoute => extraRoute.call(this))
    }
  })
}
