import jquery from 'jquery'
import { handleSessionTimeoutError } from './errorHandler'
import { v4 as uuidv4 } from 'uuid'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export class SessionTimeoutError extends Error {}

export class LockedAnsokanManipulationError extends Error {}

export function handleSessionTimeout (dispatch, callbacks) {
  handleSessionTimeoutError()
  // Run the session timeout callback
  if (callbacks && callbacks.sessionTimeout) {
    callbacks.sessionTimeout()
  }
  // Prevent other done/fail handlers to run
  throw new SessionTimeoutError()
}

const checkIfSessionTimeout = (jqXHR, dispatch, callbacks) => {
  if (!(jqXHR.getResponseHeader('enak.passedauthentication') === 'true')) {
    handleSessionTimeout(dispatch, callbacks)
  }
}

/*
  Wrapper function for jquery.ajax that applies some of our own error and log handling
*/
export function createJqueryAjaxDriver (getState, dispatch, callbacks) {
  return (configuration) => {
    // Check if session has changed since we last stored the user information, this can happen if
    // the user start a new session in another browser tab, in that case this tab is no longer valid
    if (getState().auth.user.currentSessionId !== cookies.get('MRHSession')) {
      handleSessionTimeout(dispatch, callbacks)
    }

    const newConfig = { ...configuration, headers: { ...configuration.headers, 'enak.transactionid': uuidv4() } }
    return jquery.ajax(newConfig)
      .done((data, textStatus, jqXHR) => {
        checkIfSessionTimeout(jqXHR, dispatch, callbacks)
      })
      .fail((jqXHR) => {
        if (!jqXHR.userForcedAbort) {
          checkIfSessionTimeout(jqXHR, dispatch, callbacks)
        }
      })
  }
}
