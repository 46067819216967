import { useState } from 'react'
import { logFeedback } from '../../utils/feedbackHandler'
import { useAppDispatch } from 'hooks'
//@ts-ignore
import {Column, ExternalLink, ModalDialogAlert, ModalDialogTop, TextArea} from 'redet-react-components'
import { closeModalDialog } from '../../redux/modules/modalDialog'


export function GenericErrorFormDialog() {
  
  const dispatch = useAppDispatch();
  const [errorMessageFeedbackText, setErrorMessageFeedbackText] = useState(null)

  const sendFeedback = () => {
    if (errorMessageFeedbackText) {
      logFeedback({
        rating: 0,
        comment: 'Feedback från felmeddelanderuta: ' + errorMessageFeedbackText
      })
    }
    dispatch(closeModalDialog())
  }

  const title = 'Ett tekniskt fel har tyvärr inträffat'

  return (
      <ModalDialogAlert modalTitle={title}
                      acceptButton={{ text: 'OK', onClick: sendFeedback }}>
        <p><strong>Felet har rapporterats till oss på Lantmäteriet!</strong><br/>Tänk på att du alltid kan
          kontakta <ExternalLink url={'https://www.lantmateriet.se/kontakt'} text={'Kundcenter'}/> för snabb hjälp med
          din ansökan.</p>
        <div className="row md-equal-heights">
          <Column columns="col-xs-12 col-md-6 right">
            <h4>Hjälp oss att rätta felet</h4>
            <p>Beskriv gärna vad som hände innan felet dök upp och vilken typ av enhet du använder tjänsten på.</p>
            <TextArea rows={4} id={'genericErrorId'} label={'Det här hände när felet dök upp'}
                      onChange={(error: any) => setErrorMessageFeedbackText(error.target.value)} characterLimit={2000}/>
          </Column>
          <Column columns="col-xs-12 col-md-6 left">
            <div className="message bottom-left">
              <h3>Tips!</h3>
              <p><strong>Problemet kan ibland avhjälpas genom att du startar om din webbläsare eller provar med en nyare
                version.</strong></p>
              <p>Beskriv gärna vilket operativsystem och webbläsare du använder, det underlättar för oss i vår
                felsökning!</p>
            </div>
          </Column>
        </div>
      </ModalDialogAlert>
    )
}

export default GenericErrorFormDialog;