import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    logs: [] as string[]
}

const logSlice = createSlice({
    name: 'log',
    initialState: initialState,
    reducers: {
        registerLog(state, action: PayloadAction<string[]>) {
            state.logs = [...state.logs, ...action.payload];
        }
    }
})

export const {registerLog} = logSlice.actions;

export default logSlice.reducer;
