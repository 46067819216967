import memoize from 'lru-memoize'
import {
  createValidator,
  email,
  integer,
  maxLength,
  minLength,
  required,
  swedishOrganizationNumber
} from 'utils/validation'
import invoiceFormValidations, { invoiceAlternativeFieldValidations } from '../invoicingFormValidations'
import commonContactFieldsValidations from '../Common/commonContactFieldsValidations'
import { validatePhonenumber } from '../../../utils/telephoneNumberValidation'

export const organizationContactFieldValidations = {
  orgNummer: [integer, swedishOrganizationNumber],
  orgNamn: [required, minLength(2), maxLength(80)],
  orgKontaktperson: [maxLength(100)],
  orgEmail: [email, maxLength(100)],
  orgKontaktpersonEmail: [email, maxLength(100)],
  orgTelefon: [validatePhonenumber],
  orgTelefonKontaktperson: [validatePhonenumber],
  orgTelefonKontaktpersonMobil: [validatePhonenumber]
}

export function organizationContactFormValidations (formValues, contactList, contactId, ansokanValidations) {
  if (formValues.rollSokande) {
    organizationContactFieldValidations.orgNummer = [required, minLength(2), maxLength(80)]
  } else {
    organizationContactFieldValidations.orgNummer = [minLength(2), maxLength(80)]
  }

  let validations = { ...organizationContactFieldValidations, ...commonContactFieldsValidations(formValues, contactList, contactId, ansokanValidations) }
  validations = formValues && formValues.fakturaAdressAngiven ? { ...validations, ...invoiceFormValidations } : validations
  validations = formValues && formValues.alternativAdressAngiven ? { ...validations, ...invoiceAlternativeFieldValidations } : validations
  return createValidator(formValues, validations)
}

export default memoize(10)(organizationContactFormValidations)
