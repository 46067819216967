import React from 'react'
import ReactDOM from 'react-dom/server'
import Log from './log'
// eslint-disable-next-line import/no-deprecated
import { displayGenericErrorFormDialog } from '../redux/modules/modalDialog'
import jquery from 'jquery'
import { SessionTimeoutError } from './repositoryHelpers'
import { serializeError } from 'serialize-error'
import UAParser from "ua-parser-js";

export class BaseClientError {
  constructor (errorMessage = '') {
    // I wanted something dynamic here but babel or something else seems to destroy the name,
    // both '<class>.name' and 'this.constructor.name' evaluates to 'e' in production mode...
    this.errorType = 'BaseClientError'
    this.errorMessage = errorMessage
    this.currentBrowsingUrl = window.location.href
  }
}

export class NetworkError {
  constructor (type = '', errorMessage = '', attemptedUrl = '') {
    // I wanted something dynamic here but babel or something else seems to destroy the name,
    // both '<class>.name' and 'this.constructor.name' evaluates to 'e' in production mode...
    this.errorType = type
    this.errorMessage = errorMessage
    this.currentBrowsingUrl = attemptedUrl
  }
}

export class AjaxClientError extends BaseClientError {
  constructor (attemptedUrl = '', httpStatusCode = '', httpError = '', reponseText = '', httpMethod = '', transactionId = '') {
    super('An AJAX error occured')
    // I wanted something dynamic here but babel or something else seems to destroy the name,
    // both '<class>.name' and 'this.constructor.name' evaluates to 'e' in production mode...
    this.errorType = 'AjaxClientError'
    this.attemptedUrl = attemptedUrl
    this.httpStatusCode = httpStatusCode
    this.httpError = httpError
    this.reponseText = reponseText
    this.httpMethod = httpMethod
    this.transactionId = transactionId
  }
}

export class FetchClientError extends BaseClientError {
  constructor (errorObject, httpMethod = '', transactionId = '', msg = '') {
    super('An Fetch error occured')
    // I wanted something dynamic here but babel or something else seems to destroy the name,
    // both '<class>.name' and 'this.constructor.name' evaluates to 'e' in production mode...
    this.errorType = 'FetchClientError'
    this.attemptedUrl = errorObject.url
    this.httpStatusCode = errorObject.status
    this.message = msg
    this.httpError = errorObject.statusText
    this.httpMethod = httpMethod
    this.transactionId = transactionId
  }
}

export class LocalClientError extends BaseClientError {
  constructor (errorMessage = '', fileUrl = '', lineNumber = '', column = '', fullErrorObject = {}, logs = {}) {
    super(errorMessage)
    // I wanted something dynamic here but babel or something else seems to destroy the name,
    // both '<class>.name' and 'this.constructor.name' evaluates to 'e' in production mode...
    this.errorType = 'LocalClientError'
    this.fileUrl = fileUrl
    this.lineNumber = lineNumber
    this.column = column
    this.fullErrorObject = serializeError(fullErrorObject)
    this.logs = logs
  }
}

export function displayGenericErrorPopup (dispatch) {
  logError(new BaseClientError('Unhandled error (generic error popup displayed)'), 'Generic Error popup')
  try {
    // eslint-disable-next-line import/no-deprecated
    dispatch(displayGenericErrorFormDialog())
  } catch (er) {
    const message = (
      <div>
        <p className="introduction">Ett tekniskt fel har uppstått i tjänsten.</p>
        <p className="mobile-hide">Problemet kan kanske avhjälpas genom att du startar om din webbläsare eller provar
          att använda en nyare version av
          webbläsarna <strong>Google Chrome</strong>, <strong>Mozilla Firefox</strong> eller <strong>Microsoft Internet
            Explorer</strong>.
          Du kan få hjälp från vårt <a href="https://www.lantmateriet.se/sv/Om-Lantmateriet/Kontakta-oss/"
                                       target="_blank" rel="noopener noreferrer">Kundcenter</a>.</p>
      </div>)

    // If displaying the error message with a redux action fails we fallback to an cruder alternative
    window.jQuery('body').html(ReactDOM.renderToString(message)).css({ 'margin-left': '20px', 'background': 'red' })
  }
}

export function handleSessionTimeoutError () {
  switch (window.location.hostname) {
    case 'localhost':
      window.location.href = window.location.protocol + '//' + window.location.host + '/utloggad'
      break
    case 'enak.lantmateriet.se':
      window.location.href = window.location.protocol + '//enak.lantmateriet.se/utloggad'
      break
    case 'enak-tst.lantmateriet.se':
      window.location.href = window.location.protocol + '//enak-tst.lantmateriet.se/utloggad'
      break
    case 'enak-ver.lantmateriet.se':
      window.location.href = window.location.protocol + '//enak-ver.lantmateriet.se/utloggad'
      break
    default:
      window.location.href = window.location.protocol + '//enak-tst.lantmateriet.se/utloggad'
  }
}

export function checkIfResponseIsTooManyRequests(res) {
  let isError = false;
  res.forEach((element, index) => {
        if (res[index].status === 429) {
          isError = true;
        }
      }
  )
  return isError;
}

export function logError (error, logTitle = '') {
  const log = new Log(logTitle)
  if (process.env.NODE_ENV !== 'production') {
    log.error(error)
  }

  // Special treatment for pure JavaScript errors,  they cannot be stringified because they lack enumerable properties
  if (error.name === TypeError.name || error.name === Error.name) {
    error = new BaseClientError('Logtitle: ' + logTitle + 'Error message: ' + error.message + ' Error type: ' + error.name)
  }

  fetch('/api/logerror', {
    method: 'POST',
    body: JSON.stringify(error),
    headers: { 'Content-Type': 'application/json' }
  }).then(() => {
    log.info('Sent error log message to server')
  }).catch((err) => {
    if (process.env.NODE_ENV !== 'production') {
      log.error('Failed to send error info to server, this error info is only visible in dev-mode: ', err)
    } else {
      log.error('Failed to send error info to server')
    }
  })
}

export function handleError (error, dispatch) {
  if(error?.errorMessage === 'Script error.') {
    logError(error, 'Script error (error originates from outside of our application');
  } else {
    logError(error, 'Uncaught error')
    displayGenericErrorPopup(dispatch)
  }
}

export function applyErrorHandlers (dispatch, logs) {
  // General ajax fallback that will run in addition to potential local error handlers. But if a query wants to handle errors
  // itself (only in the local error handler) it can set the query option 'global: false' which will cause this global function to not be executed.
  jquery(document).ajaxError((event, jqxhr, settings, thrownError) => {
    handleError(new AjaxClientError(settings.url, jqxhr.status, thrownError, jqxhr.responseText, settings.type, settings.headers['enak.transactionid']), dispatch)
  })

  // Add generic error handler
  window.onerror = (errorMsg, fileUrl, lineNumber, column, errorObj) => {
    // Ignore session timeout errors as they are handled by the repository helper
    // Also ignore script errors that is triggered outside of application. Could be anything and tells us nothing
    if (!(errorObj instanceof SessionTimeoutError) && !(errorMsg === "Script error." && !errorObj)) {
      handleError(new LocalClientError(errorMsg, fileUrl, lineNumber, column, errorObj, logs), dispatch)
    }
  }
}

function extractHeaderInfo (req) {
  return {
    auth_user: req.get('session.saml.last.attr.name.cn'),
    auth_personnummer: req.get('session.saml.last.attr.name.workforceid'),
    auth_orgnummer: req.get('session.saml.last.attr.name.lmscborgnummer'),
    auth_role: req.get('session.saml.last.attr.name.role'),
    ...userAgentInfo(req),
    // As the requests are routed through the approuter the req.ip will always be 127.0.0.1, so instead we look at the header
    clientip: req.get('X-Forwarded-For'),
    currentBrowsingUrl: req.body.currentBrowsingUrl,
    tag: 'lennart-web'
  }
}

function userAgentInfo (req) {
  const userAgent = req.get('User-Agent')
  const uaResult = UAParser(userAgent)
  delete uaResult.ua
  return {
    userAgent: userAgent,
    userAgent_analyzed: uaResult,
    browser: uaResult?.browser?.name,
    browser_version: `${uaResult?.browser?.name} ${uaResult?.browser?.version}`,
    system: uaResult?.os?.name,
    system_version: `${uaResult?.os?.name} ${uaResult?.os?.version}`
  }
}


export function mapFromClientLogToGraylog (req) {
  const logInfo = extractHeaderInfo(req)
  return { logInfo, longMessage: req.body.logMessage }
}

export function mapFromClientErrorToGraylog (req) {
  let logInfo = extractHeaderInfo(req)
  let longMessage = ''
  if (req.body.errorType === AjaxClientError.name) {
    longMessage = `${req.body.errorMessage}: ${req.body.httpStatusCode} - ${req.body.httpError}: ${req.body.reponseText}`
    logInfo = {
      ...logInfo,
      url: req.body.attemptedUrl,
      httpMethod: req.body.httpMethod,
      TRANSACTION_ID: req.body.transactionId,
      exception: req.body
    }
  } else if (req.body.errorType === LocalClientError.name || req.body.errorType === BaseClientError.name || req.body.errorType === FetchClientError.name) {
    longMessage = `${req.body.errorMessage}`
    logInfo = {
      ...logInfo,
      exception: req.body
    }
  } else {
    let reqBody;
    try {
      reqBody = JSON.stringify(req.body);
    } catch (err) {
      // swallow error and procced to throwing error with bad context information
    }
    throw Error(`Unhandled error type: ${req?.body?.errorType}. Complete request body: ${reqBody}`)
  }

  return { logInfo, longMessage }
}
