//@ts-ignore
import { Column, ExternalLink, ModalDialogTop, Row } from 'redet-react-components'
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

type ToU_Props = {
  okClick?: Function,
  okButtonText?: string,
  okButtonIcon?: string
}

TermsOfUsageModalForSDG.defaultProps = {
  okButtonText: t('termsOfUsage.accept'),
  okButtonIcon: 'check',
  okClick: undefined
}

export function TermsOfUsageModalForSDG(props: ToU_Props) {

    const { t } = useTranslation();
    
    return (
      <ModalDialogTop modalTitle={t('termsOfUsage.title')}
                      description={t('termsOfUsage.introText')}
                      acceptButton={{
                        text: props.okButtonText,
                        icon: props.okButtonIcon,
                        onClick: props.okClick
                      }}
                      elementsToAllowFocus={["helpSidePanelId"]}
                      id={'termsOfUsageModalId'}
                      isBig={true}>
        <Row>
          <Column columns="col-sm-6">
            <small>
              <h3>{t('termsOfUsage.aboutTerms')}</h3>
              <p>{t('termsOfUsage.aboutTermsText')} <strong>{t('termsOfUsage.cadastral')}</strong> {t('termsOfUsage.aboutTermsText2')} 
               {t('termsOfUsage.aboutTermsText3')}
               </p>
              <h3>{t('termsOfUsage.purpose')}</h3>
              <p>{t('termsOfUsage.purposeText')}</p>
              <h3>{t('termsOfUsage.LMresponsibility')}</h3>
              <p>{t('termsOfUsage.LMresponsibilityText')}</p>
              <h3>{t('termsOfUsage.responsibility')}</h3>
              <p>{t('termsOfUsage.responsibilityText')}</p>
            </small>
          </Column>
          <Column columns="col-sm-6">
            <small>
              <h3>{t('termsOfUsage.security')}</h3>
              <p>{t('termsOfUsage.securityCookies')} <ExternalLink text={t('termsOfUsage.website')} 
              url={'https://www.lantmateriet.se/sdg-cookies'}/>.
                <br/>
                <br/>
                {t('termsOfUsage.securityText')}
                </p>
              <h3>{t('termsOfUsage.drift')}</h3>
              <p>{t('termsOfUsage.driftText')}</p>
              <h3>{t('termsOfUsage.validity')}</h3>
              <p>{t('termsOfUsage.validityText')}</p>
              <h3>{t('termsOfUsage.law')}</h3>
              <p>{t('termsOfUsage.lawText')}</p>
            </small>
          </Column>
        </Row>
      </ModalDialogTop>
    )
}

