import {FullscreenContentDialog} from "../../components/General/FullscreenContentDialog"
// @ts-ignore
import {ButtonToolbarV2, ButtonGroup, ButtonTop, ModalDialogAlert} from "redet-react-components"
import {Trans, useTranslation} from "react-i18next"
import React, {useContext, useEffect, useState} from "react"
import {AnsokanWizardSDGContext, AnsokanWizardSDGContextType} from "./AnsokanWizardSDGContext"
import {LogOutModal} from "../../components/Navigation/Navigation"
import {useNavigate} from "react-router-dom";
import {EXTERNAL_LINK} from "../../helpers/LocaleComponentHelper";
import {scrollToMainTag} from "./util";

export function SubmitResult() {

    const SLM_AUTHORITY = "Lantmäteriet";

    const [showLogOutModal, setShowLogOutModal] = useState(false)

    const {result} = useContext(AnsokanWizardSDGContext) as AnsokanWizardSDGContextType

    const navigate = useNavigate();
    const {t} = useTranslation()

    const isKlm = () => result?.mottagandemyndighet?.toLowerCase() !== SLM_AUTHORITY.toLowerCase()

    useEffect(() => {
        scrollToMainTag()
    }, []);

    function toggleLogOutModal(show: boolean) {
        setShowLogOutModal(show)
    }

    return <>
        {result ? <>
                <FullscreenContentDialog>
                    <h1>{t('submit.result.title')}</h1>
                    <p className={'introduction'}>
                        {t('submit.result.introduction')}
                    </p>
                    <p className={'description'}>
                        <Trans i18nKey={isKlm() ? 'submit.result.description_klm' : 'submit.result.description_slm'}
                               values={{authority: result.mottagandemyndighet, referenceId: result.kvittens}}
                               components={{bold: <strong/>}}/>
                    </p>
                    {result.fastighetsbeteckningar && result.fastighetsbeteckningar.length > 0 && <p>
                        <ul>
                            {result.fastighetsbeteckningar.map(fastighetsbeteckning => (
                                <li key={fastighetsbeteckning}>{fastighetsbeteckning}</li>
                            ))}
                        </ul>
                    </p>}
                    <div className="layout-group-sub left-aligned" data-testid={'reference-information-container'}>
                        <p className={'introduction'}>
                            {t('submit.result.info.introduction')}
                        </p>
                        <p className={'description'}>
                            <Trans i18nKey="submit.result.info.contact"
                                   components={{external_link: <EXTERNAL_LINK aria-label={t('submit.result.info.contact_link_aria_label')}/>}}/>
                        </p>
                        <p>
                            <strong>
                                {t('submit.result.info.regards')}
                            </strong>
                            {<Trans i18nKey={isKlm() ? 'submit.result.info.authority_title_klm' : 'submit.result.info.authority_title_slm'}
                                    values={{authority: result.mottagandemyndighet}}/>}
                        </p>
                    </div>
                    <ButtonToolbarV2 className={'toolbar left-aligned'}>
                        <ButtonGroup>
                            <ButtonTop text={t('navigation.logout')}
                                       onClick={() => toggleLogOutModal(true)}
                                       icon={'logout'}
                                       ariaLabel={t('navigation.logout')}
                                       id="logOutButton"/>
                        </ButtonGroup>
                    </ButtonToolbarV2>
                </FullscreenContentDialog>
                {showLogOutModal && <LogOutModal declineOnClick={() => toggleLogOutModal(false)}/>}
            </> :
            //om result saknas, har något gått på tok
            <ModalDialogAlert
                modalTitle={t('submit.result.error.technicalErrorTitle')}
                ingress={t('submit.result.error.technicalErrorDescription')}
                description={
                    <Trans i18nKey="submit.result.error.technicalErrorInfo"
                           components={{external_link: <EXTERNAL_LINK aria-label={t('submit.result.info.contact_link_aria_label')}/>}}/>
                }
                acceptButton={{
                    text: 'Ok', icon: 'check',
                    //@ts-ignore
                    onClick: (): void => {
                        navigate('/')
                    }
                }}
            />
        }
    </>
}