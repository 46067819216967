// @flow
// 1.10 En ansökan ska innehålla minst 1 fakturamottagare

import { MINST_EN_FAKTURAMOTTAGARE, WARNING } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  const contacts = state.contacts.contactList
  const fakturamottagare = contacts.filter(contact => contact.data.rollFakturamottagare)
  if (fakturamottagare.length < 1) {
    return new Validation(MINST_EN_FAKTURAMOTTAGARE, 'Du har inte angett någon betalare.', 'contacts', WARNING, undefined)
  }
  return undefined
}

export default validate
