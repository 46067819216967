import { useState } from 'react'


type KartskissLightboxProps = {
  fullBifogadKartskiss: {
    kartskissImages: [],
    namn: string
  }
}
export default function BifogadKartskissLightbox(props: KartskissLightboxProps) {
  
  const [currentIndex, setCurrentIndex] = useState(0)
  
  const onScrollImageClick = (next: boolean) => {
    setCurrentIndex(next ? (currentIndex + 1) : (currentIndex - 1))
  }

  const onCloseBifogadKartskissClick = () => {
    setCurrentIndex(0)
  }

    return (
      <div className="lightbox" id={`kartskiss_lightbox_${currentIndex}`}>
        <div className="lightbox-content">
          {currentIndex > 0 &&
            <a href={`#kartskiss_lightbox_${currentIndex - 1}`} className="lightbox-button previous"
               onClick={() => onScrollImageClick(false)}><i className="material-icons">arrow_back_ios</i></a>
          }
          <img src={props.fullBifogadKartskiss.kartskissImages[currentIndex]}
               alt={'Kartskiss ' + currentIndex}/>
          {currentIndex < props.fullBifogadKartskiss.kartskissImages.length - 1 &&
            <a href={`#kartskiss_lightbox_${currentIndex + 1}`} className="lightbox-button next"
               onClick={() => onScrollImageClick(true)}><i className="material-icons">arrow_forward_ios</i></a>

          }
        </div>
        <label className="lightbox-caption">{props.fullBifogadKartskiss.namn}</label>
        <a id="close_lightbox_link" href="#section-kartskiss" className="button-top"
           onClick={() => onCloseBifogadKartskissClick()}><i className="material-icons">clear</i>Stäng</a>
      </div>
    )
}


