import {Arende} from "../../../domain/Arende";
// @ts-ignore
import {Column, Row} from "redet-react-components";
import React from "react";
import {MYNDIGHET_LANTMATERIET} from "../../../utils/globalConstants";

type Props = {
    arendeOversikt: Arende
}

export function ArendeHandlaggareInfoView({arendeOversikt}: Props) {

    const getMottagandeMyndighet = () => arendeOversikt.mottagandeMyndighet === MYNDIGHET_LANTMATERIET ?
        MYNDIGHET_LANTMATERIET :
        `Kommunala lantmäterimyndigheten i ${arendeOversikt.mottagandeMyndighet}`

    return (<>
        <Row>
            <Column columns="col-6 col-sm-4 col-md-2">
                <div className="form-group">
                    <label htmlFor={'handlaggareinfo-namn'} className="label">Namn</label>
                    <p id={'handlaggareinfo-namn'}>{arendeOversikt.handlaggareNamn}</p>
                </div>
            </Column>
            <Column columns="col-6 col-sm-4 col-md-2">
                <div className="form-group">
                    <label htmlFor={'handlaggareinfo-telefon'} className="label">Telefon</label>
                    <p id={'handlaggareinfo-telefon'}>{arendeOversikt.handlaggareTelefon}</p>
                </div>
            </Column>
            <Column columns="col-6 col-sm-4 col-md-2">
                <div className="form-group">
                    <label htmlFor={'handlaggareinfo-epost'} className="label">E-post</label>
                    <p id={'handlaggareinfo-epost'} className="preview-message"><a
                        href={`mailto:${arendeOversikt.handlaggareEpost}?Subject=Angående%20lantmäteriförrättning%20med%20ärendenummer%20${arendeOversikt.nummer}`}
                        target="_top">{arendeOversikt.handlaggareEpost}</a></p>
                </div>
            </Column>
            {arendeOversikt.mottagandeMyndighet && <Column columns="col-6 col-sm-12 col-md-4">
                <div className="form-group">
                    <label htmlFor={'handlaggareinfo-myndighet'} className="label">Myndighet</label>
                    <p id={'handlaggareinfo-myndighet'}  className="preview-message">{getMottagandeMyndighet()}</p>
                </div>
            </Column>}
        </Row>
    </>)
}
