import Log from 'utils/log'

const log = new Log('ansokanRepository')

export function mockMarkAsSigned (fetcher, key) {
  if (!key) {
    throw new Error('The key argument is missing')
  }

  return fetcher.fetching('/api/ansokan-service/dev/ansokan/' + key + '/markAsSigned',
    {
      cache: 'no-store',
      method: 'POST',
    }).then(() => {
    log.info('Ansokan successfully updated on server')
  }).catch(() => {
    log.error('Error encountered while updating ansokan on server')
  })
}
