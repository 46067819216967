import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {LoadFastighetException} from 'repository/fastighetRepository'
import {Fastighet, Term} from 'views'
// @ts-ignore
import Autosuggest from 'react-autosuggest'
// @ts-ignore
import {ButtonSub, FormFieldset, ModalDialogAlert} from 'redet-react-components'
import {logError} from '../../utils/errorHandler'
import {useTranslation, withTranslation} from 'react-i18next';
import {useAppDispatch} from "../../hooks";
import {RootState} from "../../store";
import {FastighetsInnehav} from "../../redux/modules/FastighetsInnehav";
import {Fastighet as FastighetType} from "../../domain/Ansokan";
import {
  failAnnanFastighet,
  ickeReggadFastighet,
  loadRegisterbeteckningFunc,
  removeRegisterenhet,
  setFastighetSearchString,
  successAnnanFastighet,
  updateAutoCompleteOptions
} from "../../redux/modules/fastigheter";
import { JSX } from 'react/jsx-runtime'

type Props = {
  ickeAgdaFastigheter: FastighetType[]
  egnaFastigheter: FastighetsInnehav[]
  addedPropertys: FastighetType[]
}
let controller: AbortController | undefined;
let signal: AbortSignal;

interface Language {
  name: string;
  year: number;
}
type loadOutSuggestionType = {
  value: string,
  reason: string,
}
export function FastighetsvalSearchBox(props: Props) {
  const dispatch = useAppDispatch()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSweLetters, setShowSweLetters] = useState(false)
  const {t} = useTranslation();
  const searchFeedback = useSelector((store: RootState) => store.fastigheter.fastighetSearchFeedback)
  const autocompleteOptions = useSelector((store: RootState) => store.fastigheter.fastighetAutoCompleteOptions)
  const searchString = useSelector((store: RootState) => store.fastigheter.fastighetSearchString)
  const fastighetsinnehav = useSelector((store: RootState) => store.fastighetsinnehav.list)

  function onSearchClick() {
    dispatch(loadRegisterbeteckningFunc(searchString.trim(), fastighetsinnehav))
        .then(response => {
          dispatch(successAnnanFastighet(response, searchString.trim()))
          dispatch(ickeReggadFastighet(response, searchString.trim()))
        })
        .catch((error) => {
          if (error instanceof LoadFastighetException) {
            dispatch(failAnnanFastighet(error.errorMessage))
          } else {
            error.component = 'FastighetsvalSearchBox.js'
            logError(error, 'Fastighetsvalsearchbox-component')
            setShowErrorModal(true)
          }
        })
  }

  function loadAutoCompleteSuggestions({ value, reason }: loadOutSuggestionType): void {
    if (controller !== undefined) {
      // Cancel the previous request
      try {
        controller.abort()
      } catch (err) {
        // Swallow error that may occur when no previous request exist
      }
    }

    // Feature detect
    if ('AbortController' in window) {
      controller = new AbortController()
      signal = controller.signal
    }
    if (reason === 'input-changed' || reason === 'input-focused')
      dispatch(updateAutoCompleteOptions(value, signal))
  }
  function onOptionSelected(event: React.FormEvent<any>, {suggestion}: Autosuggest.SuggestionSelectedEventData<Language>): void {
    if (controller !== undefined) {
      // Cancel the previous request
      try {
        controller.abort()
      } catch (err) {
        // Swallow error that may occur when no previous request exist
      }
    }

    // Feature detect
    if ('AbortController' in window) {
      // @ts-ignore
      controller = window.AbortController
      // @ts-ignore
      signal = controller.signal
    }
    dispatch(updateAutoCompleteOptions(suggestion + ' ', signal))
  }

  function updateSearchText(event: React.FormEvent<any>, {newValue, method}: Autosuggest.ChangeEvent): void {
    dispatch(setFastighetSearchString(newValue))
  }

  function hideErrorModal() {
    setShowErrorModal(false)
  }

  function showSwedishLetters() {
    setShowSweLetters(!showSweLetters)
  }

  return (
      <>
        <div className="layout-group-sub annan-fastighet">
          <FormFieldset title={'Andra fastigheter som du inte äger'}
                        description={<>Lägg till fastighet, samfällighet och gemensamhetsanläggning med hjälp av
                          en <Term
                              label="beteckning"
                              moment="help-selection"
                              term="term-fastighetsbeteckning"/>.<br/>Fastighetsbeteckningen börjar alltid med kommun,
                          t.ex. <strong>GÄVLE BERGA
                            1:2</strong></>}
                        id="fastighetSearchFieldId">
            <div className="form-group">
              <label className="label" htmlFor={'fastighetsbeteckning'}>Fastighetsbeteckning</label>
              <div className="flex-line">
                <div className="flex-group">
                  <div className="enak-autocomplete">
                    <Autosuggest
                        suggestions={autocompleteOptions ? autocompleteOptions : ""}
                        onSuggestionsFetchRequested={loadAutoCompleteSuggestions}
                        onSuggestionSelected={onOptionSelected}
                        alwaysRenderSuggestions={true}  /*Slå på ifall du vill inpektera listan som renderas.*/
                        getSuggestionValue={(suggestion: string) => suggestion}
                        renderSuggestion={(suggestion: string) => (<>{suggestion}</>)}
                        renderInputComponent={(inputProps: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) => (
                          <input {...inputProps} aria-multiline={false} aria-label="Fastighetssökresultat"/>
                      )}
                      containerProps={{...{'aria-controls': 'fastighetsbeteckning'}}}
                      inputProps={
                        {
                          value: searchString,          // usually comes from the application state
                          onChange: updateSearchText,       // called every time the input value changes
                          // called when the input loses focus, e.g. when user presses Tab
                          type: 'search',
                          className: 'form-input-text',
                          id: 'fastighetsbeteckning',
                          title: 'fastighetsbeteckningInput',
                          placeholder: 'KOMMUN TRAKTNAMN BLOCK:ENHET',
                          autoComplete: 'off',
                        }
                      }
                      renderSuggestionsContainer={({containerProps, children}: Autosuggest.RenderSuggestionsContainerParams): React.JSX.Element =>
                          //WCAG kräver att role:listbox ska bort
                      {
                        delete containerProps.role
                        return <div {...containerProps} aria-label={'Fastighetsresultat'} role="listbox">
                          {children}
                        </div>
                      }}
                      theme={{
                        suggestionsList: 'form-autocomplete',
                        suggestionHighlighted: 'form-autocomplete-suggestion-active',
                      }}
                  />
                </div>
                {searchFeedback && searchFeedback.length > 0 &&
                    <span className="validation"
                          data-selenium-id="fastighetssok-felmeddelande">{t(searchFeedback)}</span>
                }
                {showSweLetters &&
                    <div className="flex-group separated-buttons" id="umlaut">
                      <ButtonSub class="button-sub button-umlaut">Å</ButtonSub>
                      <ButtonSub class="button-sub button-umlaut">Ä</ButtonSub>
                      <ButtonSub class="button-sub button-umlaut">Ö</ButtonSub>
                    </div>
                }
                {showSweLetters &&
                    <ButtonSub id="toggle-umlaut" onClick={showSwedishLetters}>ÅÄÖ</ButtonSub>
                }

              </div>
              <ButtonSub text="Lägg till"
                         icon="add"
                         onClick={onSearchClick}
                         id="fastighetssok-lagg-till"/>
            </div>
          </div>
        </FormFieldset>
          <Fastighet andraFastigheter={props.ickeAgdaFastigheter}
                     deleteMethod={(beteckning) => dispatch(removeRegisterenhet(beteckning))}
                     egnaFastigheter={props.egnaFastigheter}/>

        {showErrorModal &&
          <ModalDialogAlert
            modalTitle={'Tekniskt fel'}
            ingress={'Ett tekniskt fel uppstod vid sökning av fastighetsbeteckning.'}
            description={<span>Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
              href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>}
            acceptButton={{ text: 'Ok', icon: 'check', onClick: hideErrorModal }}
          />
        }
        </div>
      </>
    )
}

export default FastighetsvalSearchBox