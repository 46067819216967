//@ts-ignore
import { IconLink, ListGroup, ListItem, ListItemActionButton, withPopover } from 'redet-react-components'
import { formatFileSize } from './HandlingUtils'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

type Props = {
    items: File[]
    deleteHandlingCallback: (selected: File) => void,
    listSetter?: any
}

function ApplicationSDGList(props: Props) {

  const { t } = useTranslation();
  const [listan, setListan] = useState(props.items)

  const IconLinkWithPopover = withPopover(IconLink)
    return (
      <>
        <ListGroup id="bifogadeHandlingarList" title={t('application.filesUploaded')} listOptions=
        {{list: props.items, listSetter: setListan, paginationConfig: {initPage:0, maxNoPageButtons:5, pageSize:5, onPageChangeScrollToTopOfList: false}}}>
          {listan.map((item: File, index: number) => {
            // Bör ersättas med replaceAll(' ',''). Detta smäller för närvarande i Jest.
            const itemId = (item.name + index).replace(/\s/g, '')
            const actionButtons = []
            const itemSize = formatFileSize(item.size)

            actionButtons.push(
              <IconLinkWithPopover
                key={`${itemId}DownKey`}
                link={URL.createObjectURL(item)}
                target={'_blank'}
                download={item.name}
                id={`downloadHandling${index}`}
                data-selenium-id={`downloadHandling${itemId}Id`}
                icon={'file_download'}
                ariaLabel={t('application.fileAria')}
                popoverText={t('application.file')}
                name="downloadAttachmentButton"
                />)
            actionButtons.push(
              <ListItemActionButton
                key={`${itemId}DeleteKey`}
                id={`deleteHandling${index}`}
                data-selenium-id={`deleteHandling${itemId}Id`}
                icon={'delete'}
                ariaLabel={t('application.removeAria')}
                popover={{ text: t('application.remove')}}
                onClick={() => props.deleteHandlingCallback(item)}
                name="deleteAttachmentButton"
              />)
            return (
              <ListItem
                key={`${itemId}Key`}
                id={itemId}
                header={item.name}
                description={itemSize}
                actionButtons={actionButtons}>
              </ListItem>
            )
          })}
        </ListGroup>
      </>
    )
  }

export default (ApplicationSDGList)
