import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {SmallLoading} from '../index'

const mapStateToProps = (store) => {
  return {
    saveIsPending: store.ansokanRepository.saveIsPending,
    lastSaved: store.ansokanRepository.lastSaved
  }
}

class SavingIndicator extends Component {

  static propTypes = {
    lastSaved: PropTypes.number.isRequired,
    saveIsPending: PropTypes.bool,
  }

  static defaultProps = {
    lastSaved: 0
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      showSpinner: false,
      spinnerTimeoutIsSet: false
    }
    this.getHourMinuteSecondFromEpoch = this.getHourMinuteSecondFromEpoch.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.activateSaveSpinner()
    }
  }

  activateSaveSpinner () {
    if (this.props.saveIsPending) {
      this.setState({
        showSpinner: true
      })

      if (!this.state.spinnerTimeoutIsSet) {
        this.setState({ spinnerTimeoutIsSet: true })
        const spinnerTimeoutId = setTimeout(() => {
          this.setState({
            showSpinner: false,
            spinnerTimeoutIsSet: false,
            spinnerTimeoutId: spinnerTimeoutId
          })
        }, 3000)
      }
    }
  }

  componentWillUnmount () {
    if (this.state.spinnerTimeoutIsSet) {
      clearTimeout(this.state.spinnerTimeoutId)
    }
  }


  getHourMinuteSecondFromEpoch (epoch) {
    const date = new Date(epoch)
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return `${hours}:${minutes}:${seconds}`
  }

  render () {
    return (
      <>
        {this.state.showSpinner ?
          <div aria-label={'Sparningsindikator'} role="status" className="message-panel in-progress" id="sparningsindikatorId"><SmallLoading
            text={'Sparar...'}/></div> :
            <div className="message-panel" aria-label={'Tid då ansökan sparades senast'} role="status" aria-live="off" id="senastSparadMeddelandeId">
              <p>Sparad<br/><strong>{this.props.lastSaved === 0 ? '-' : this.getHourMinuteSecondFromEpoch(this.props.lastSaved)}</strong></p>
            </div>
        }
      </>
    )
  }
}

export default connect(mapStateToProps, undefined)(SavingIndicator)
