import {ListItemFlexGroup} from "../../../../General/list/ListItemFlexGroup";
import {ListItemFlex} from "../../../../General/list/ListItemFlex";
import React from "react";
// @ts-ignore
import {ListItemActionButton} from "redet-react-components"
import {getLocalizedDateString} from "../../../../../helpers/dates";
import {ISO8601} from "../../../../../utils/globalConstants";
import {ListItemStatus} from "../../../../../domain/ListItemStatus";
import KompletteringHelper from "../../../../../helpers/KompletteringHelper";
import {KOMPLETTERINGSTATUS} from "../../../../../domain/Komplettering";

interface Props {
    id?: string
    header: string
    createdDate?: string
    onDeleteClick?: (event: any) => void
}

export const KompletteringTimedOutListItem = ({id, header, createdDate, onDeleteClick, ...rest}: Props) => {

    const statusitem: ListItemStatus = KompletteringHelper.getStatusObject(KOMPLETTERINGSTATUS.UTGATT)

    const formattedCreatedDateString = createdDate ? `Skapad ${getLocalizedDateString(createdDate, ISO8601)}` : undefined

    return (<ListItemFlex data-testid={'komplettering-item'} id={id} {...rest}>
        {{
            actions: onDeleteClick ? <ListItemActionButton
                id={`deleteButton_${id}`}
                icon={'delete'}
                name={'deleteKompletteringButton'}
                ariaLabel={'Ta bort komplettering'}
                popover={{text: 'Ta bort'}}
                onClick={(event: any) => onDeleteClick(event)}/> : undefined,
            items: <ListItemFlexGroup title={header} status={statusitem} description={formattedCreatedDateString}></ListItemFlexGroup>
        }}
    </ListItemFlex>)
}
