import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'react-final-form'
import React, { useEffect } from 'react'
import { OnChange } from 'react-final-form-listeners'
import { blockeradLmAdressOtillatenMottagare, errorMessages, warningMessages } from '../../../utils/validation'
import {
  Checkbox,
  Felruta,
  NumberInput,
  RadioButton,
  SelectSearch,
  TextInput,
  VarningsRuta,
  TextArea
} from 'redet-react-components'
import { PERSONAL_NUMBER_INPUT_TYPING_VERIFICATION_REGEX } from '../../../utils/globalConstants'

export const CheckboxFinalForm = ({
  id,
  field,
  label,
  term,
  termPostfixText,
  warningMessage,
  disabled,
  children,
  header,
  labelChildren,
  ...rest
}) =>
  <Field name={field} type="checkbox" >
    {({ input }) => {
      const description = term !== undefined ? <>{termPostfixText} {term}</> : undefined
      return (
        <>
          <Checkbox id={id}
                    label={label}
                    isDisabled={disabled}
                    labelChildren={labelChildren}
                    children={children}
                    checked={input.value}
                    {...rest}
                    {...input}
          />
          {warningMessage ? warningMessage : null}
          {description && <p>{description}</p>}
        </>
      )
    }}
  </Field>

CheckboxFinalForm.propTypes = {
  id: PropTypes.string,
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  term: PropTypes.element,
  termPostfixText: PropTypes.string,
  disabled: PropTypes.bool,
  header: PropTypes.string,
}

CheckboxFinalForm.defaultProps = {
  termPostfixText: 'Läs mer om',
  disabled: false
}

/**
 * Textarea anpassad till FinalForm.
 */
export const TextAreaFinalForm = ({ field, label, id, placeholder, rows, maxLength, characterLimit, mandatory }) =>
  <Field name={field}>
    {({ input, meta }) => (
      <div className="form-group">
         <TextArea
          {...input}
          id={id}
          className="form-textarea"
          maxLength={maxLength}
          placeholder={placeholder}
          rows={rows}
          characterLimit={characterLimit}
          label={label}
          isRequired={mandatory}
        />
        {meta.error && <span className="validation mandatory block">{meta.error}</span>}
      </div>
    )}
  </Field>

TextAreaFinalForm.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  characterLimit: PropTypes.number,
  mandatory: PropTypes.bool
}
TextAreaFinalForm.defaultProps = {
  maxLength: 500,
  placeholder: '',
  rows: 3,
  mandatory: false
}

/**
 * Textarea anpassad till FinalForm. Borde göras om till en tagg istället för en funktion.
 */
export const SelectSearchFinalForm = ({
  field,
  label,
  options,
  maxLength,
  isAcceptingCustomInput = false,
  isRequired,
  ...rest
}) =>
  <Field name={field}>
    {({ input, meta }) => {
      return <div className="form-group">
        <SelectSearch label={label}
                      name={field}
                      id={field + 'Id'}
                      isRequired={isRequired}
                      initialValue={input?.value}
                      options={options}
                      isAcceptingCustomInput={isAcceptingCustomInput}
                      maxLength={maxLength}
                      validationErrorMsg={meta.error}
                      onBlur={(event, value) => input.onChange(value)}
                      {...rest}
        />
      </div>
    }}
  </Field>
SelectSearchFinalForm.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  maxLength: PropTypes.number,
  isAcceptingCustomInput: PropTypes.bool,
  isRequired: PropTypes.bool
}

SelectSearchFinalForm.defaultProps = {
  maxLength: 500,
  isAcceptingCustomInput: false,
  isRequired: false
}

export function TextInputFinalForm ({
  field,
  label,
  term,
  termPostfixText,
  maxLength,
  placeholder,
  required,
  ...rest
}) {
  const description = term !== undefined ? <>{termPostfixText} {term}</> : undefined
  return (
    <>
      <div className="form-group">
        <Field name={field}>
          {({ input, meta }) => (
            <TextInput
              {...input}
              id={field + 'Id'}
              maxLength={maxLength}
              placeholder={placeholder}
              {...rest}
              isRequired={required}
              label={label}
              validationErrorMsg={meta.touched ? meta.error : undefined}
            />
          )}
        </Field>
      </div>
      {description && <p>{description}</p>}
    </>
  )
}

TextInputFinalForm.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  term: PropTypes.element,
  termPostfixText: PropTypes.string,
}

TextInputFinalForm.defaultProps = {
  termPostfixText: 'Läs mer om'
}

/**
 * Denna kommer att tas bort när inputfälten i invitetosign försvinner, därav Old
 */
export function EmailInputFinalFormOld ({
  field,
  label,
  initValue,
  id,
  name,
  placeholder,
  required,
  errorShowWithoutTouch = false,
  ...rest
}) {
  return (
    <div className="form-group">
      <Field name={field} validate={(value) => (errorMessages(value))} initialValue={initValue}>
        {({ input, meta }) => {
          const warningMessage = warningMessages(input.value)
          return (
            <>
              {label &&
                <label className={classNames('label', { 'mandatory': required })} htmlFor={id}>{label}
                  <em>{name}</em></label>}
              <input
                {...input}
                id={id}
                required={required}
                className="form-input-text"
                minLength={1}
                maxLength={80}
                placeholder={placeholder}
                type="email"
                {...rest}
              />

              {id === 'vidimerare' && warningMessage !== undefined && !meta.error && meta.touched &&
                <span className="validation optional">{warningMessage}</span>
              }
              {meta.touched && meta.error &&
                <span className="has-error validation mandatory">{meta.error}</span>
              }

            </>
          )
        }}
      </Field>
    </div>
  )
}

export function EmailInputFinalForm ({
  field,
  label,
  id,
  maxLength,
  placeholder,
  required,
  validationErrorMsg,
  ...rest
}) {
  return (
    <div className="form-group">
      <Field name={field} validate={(value) => (blockeradLmAdressOtillatenMottagare(value))}>
        {({ input, meta }) => (
          <TextInput
            {...input}
            id={field + 'Id'}
            maxLength={maxLength}
            placeholder={placeholder}
            {...rest}
            isRequired={required}
            label={label}
            type={'text'} // Om type sätts till 'email' kommer HTML5-validering köras på elementet vilket förhindrar validering av ReactFinalForm att ske på rätt sätt. Därför måste type vara 'text'.
            validationErrorMsg={meta.touched ? meta.error : undefined}
            {...rest}
          />
        )}
      </Field>
    </div>
  )
}

EmailInputFinalFormOld.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool
}

export function NumberInputFinalForm ({
  field,
  label,
  maxNumberValue,
  minNumberValue,
  placeholder,
  required,
  term,
  ...rest
}) {
  return (
    <>
      <div className="form-group">
        <Field name={field}>
          {({ input, meta }) => <>
            {label &&
              <label className={classNames('label', { 'mandatory': required })} htmlFor={field + 'Id'}>{label}</label>}
            <input
              {...input}
              className="form-input-number"
              id={field + 'Id'}
              placeholder={placeholder}
              type="number"
              max={maxNumberValue || 999999999999}
              min={minNumberValue || 0}
              {...rest}
            />
            {meta.error && meta.touched && <span className="validation mandatory">{meta.error}</span>}
          </>
          }
        </Field>
      </div>
      Läs mer om {term}
    </>
  )
}

NumberInputFinalForm.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxNumberValue: PropTypes.number,
  minNumberValue: PropTypes.number,

}

export function Personnummer ({
  label, mandatory, fieldId, placeholder, maxNumberValue, minNumberValue, errorMsg, showErrorMsg, children, ...rest
}) {
  let checkInputDependingOnType = (event) => {
    if (PERSONAL_NUMBER_INPUT_TYPING_VERIFICATION_REGEX.test(event.target.value)) {
      if (rest?.onChange)
        rest.onChange(event)
    }
  }
  // Telefonnummer regex -  ^[\+]?[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{2}[-\s]?[0-9]{2,10}$
  return <NumberInput
    label={label}
    id={fieldId}
    isRequired={mandatory}
    placeholder={placeholder}
    showValidationMsg={showErrorMsg}
    validationErrorMsg={errorMsg}
    maxNumberValue={maxNumberValue}
    minNumberValue={minNumberValue}
    {...rest}
    type={'tel'}
    onChange={checkInputDependingOnType}
  >{children}</NumberInput>
}

export function PersonnummerFinalForm ({
  field,
  label,
  maxNumberValue,
  minNumberValue,
  placeholder,
  required,
  term,
  ...rest
}) {
  return (
    <>
      <div className="form-group">
        <Field name={field}>
          {({ input, meta }) =>
            <Personnummer label={label} mandatory={required} fieldId={field}
                          placeholder={placeholder} maxNumberValue={maxNumberValue}
                          minNumberValue={minNumberValue}
                          errorMsg={meta.error} showErrorMsg={meta.touched}
                          {...rest} {...input}
            >Läs mer om {term}</Personnummer>
          }
        </Field>
      </div>
    </>
  )
}

PersonnummerFinalForm.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxNumberValue: PropTypes.number,
  minNumberValue: PropTypes.number,

}

export const HiddenInputFinalForm = ({ field }) =>
  <Field name={field} tabindex="-1">
    {({ input }) => (<input {...input} hidden/>)}
  </Field>
/**
 * Condition för final-form-komponenter.
 *  @ Params borttagna då komponenten inte fungerade tillsammans med typescript.
 */
export const Condition = ( {when, is, isNot, children} ) => {
  let funcCondition
  if (is) {
    is = convertToArray(is)
    funcCondition = (value) => is.indexOf(value) > -1
  } else {
    isNot = convertToArray(isNot)
    funcCondition = (value) => isNot.indexOf(value) === -1
  }
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (funcCondition(value) ? children : null)}
    </Field>
  )
}

export const Subscribe = ({ fieldName, children }) => (
  <Field name={fieldName}
         subscription={{ value: true }}>
    {children}
  </Field>)

/**
 * När ett fält ändras, ändra också detta fält.
 * @ Params borttagna då komponenten inte fungerade tillsammans med typescript.
 */
export const WhenFieldChanges = ({ field, becomes, set, to }) => {
  set = convertToArray(set)
  return set.map((setField, index) => {
    return <Field key={index} name={setField} subscription={{}}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <OnChange name={field}>
          {value => {
            if (value === becomes) {
              if (typeof to === 'function')
                to()
              else
                onChange(to)
            }
          }}
        </OnChange>
      )}
    </Field>
  })
}
export const WhenRadioButtonChanges = ({ field, becomes, set, to }) => {
  set = convertToArray(set)
  return set.map((setField, index) => {
    return <Field key={index} name={setField} subscription={{}}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <OnChange name={field}>
          {value => {
            if (value === becomes) {
              if (typeof to === 'function')
                to()
              else
                onChange(to)
            }
          }}
        </OnChange>
      )}
    </Field>
  })
}
/**
 * När ett fält ändras, ändra också detta fält.
 *
 * @param field {string} namn på fält
 * @param becomes {type} när field blir detta
 * @param set {string} namn på fält som skall ändras
 * @returns {*}
 * @constructor
 */
export const WhenFieldChangesRun = ({ field, becomes, run }) => {
  return <Field name={field} subscription={{}}>
    {(// No subscription. We only use Field to get to the change function
    ) => (
      <OnChange name={field}>
        {value => {
          if (value === becomes) {
            run()
          }
        }}
      </OnChange>
    )}
  </Field>
}

function convertToArray (possibleArray) {
  return Array.isArray(possibleArray) ? possibleArray : Array.of(possibleArray)

}

export const RadioFinalForm = ({ field, value, tabIndex, defaultChecked, label, ...rest }) =>
  <Field name={field} type="radio" value={value}>
    {({ input }) => {
      return <RadioButton label={label}
                          tabIndex={tabIndex}
                          {...rest}
                          {...input}/>
    }}
  </Field>

export function ValideringssamanfattningFinalForm ({ validationErrors, faltnamnDescription, children, id, ...rest }) {
  useEffect(() => {
    if (document.getElementById(id)) {
      document.getElementById(id).focus()
    }
  }, [id])
  if (Object.keys(validationErrors).length === 0)
    return <></>
  let listobjekt = {}
  const faltNamn = Object.keys(validationErrors)
  faltNamn.forEach(function (namn) {
    const beskrivandeFaltnamn = faltnamnDescription[namn] ? faltnamnDescription[namn] : namn
    listobjekt[beskrivandeFaltnamn] = validationErrors[namn]
  }, [])
  const valideringsfelmeddelandelista = Object.entries(listobjekt).map(([key, value]) => `${key} - ${value}`)
  return (
    <Felruta errorMessages={valideringsfelmeddelandelista}
             id={id}
             intro={`Det finns ${valideringsfelmeddelandelista.length} problem i formuläret. Vänligen ändra dessa delar.\n`}
             aria-label={"Valideringssammanfattning - Problem"}
             {...rest}
    >{children}</Felruta>
  )
}

ValideringssamanfattningFinalForm.propTypes = {
  validationErrors: PropTypes.object.isRequired,
  faltnamnDescription: PropTypes.object.isRequired,
  children: PropTypes.node,
  id: PropTypes.string
}

export function Varningssamanfattning ({ validationWarnings, faltnamnDescription, children, id, ...rest }) {
  useEffect(() => {
    if (document.getElementById(id)) {
      document.getElementById(id).focus()
    }
  }, [id])
  if (Object.keys(validationWarnings).length === 0)
    return <></>
  let listobjekt = {}
  const faltNamn = Object.keys(validationWarnings)
  faltNamn.forEach(function (namn) {
    const beskrivandeFaltnamn = faltnamnDescription[namn] ? faltnamnDescription[namn] : namn
    listobjekt[beskrivandeFaltnamn] = validationWarnings[namn]
  }, [])
  const valideringsvarningslista = Object.entries(listobjekt).map(([key, value]) => `${key} - ${value}`)
  const varningsstring = valideringsvarningslista.length > 1 ? 'varningar' : 'varning'
  return (
    <VarningsRuta
      warningMessages={valideringsvarningslista}
      description={`Det finns ${valideringsvarningslista.length} ${varningsstring} i formuläret. Vänligen se över dessa delar.\n`}
      aria-label={"Valideringssammanfattning - Varningar"}
      role={"status"}
      {...rest}
    >{children}</VarningsRuta>
  )
}

Varningssamanfattning.propTypes = {
  validationWarnings: PropTypes.object.isRequired,
  faltnamnDescription: PropTypes.object.isRequired,
  children: PropTypes.node,
  id: PropTypes.string
}