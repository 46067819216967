import {ServerValidationError} from "./rules/model/ServerValidationError";
import {Validation} from "./rules/model/Validation";
// @ts-ignore
import {ExternalLink} from "redet-react-components";

export function mapToRules(serverValidationErrors: ServerValidationError[] = []): Validation[] {
    return serverValidationErrors.map(validationError => {
        let serverValidationError = new ServerValidationError();
        Object.assign(serverValidationError, validationError)
        return serverValidationError.toValidation()
    })
}

export function decorateWithSubmessages(validationErrors: Validation[]): Validation[] {
    const newValidationList = []
    for (const validationError of validationErrors) {
        switch (validationError.id) {
            case "MINST_EN_BEHORIGHETSHANDLING_VID_OMBUD":
                newValidationList.push(validationError.cloneWithNewMessage(validationError.message, <>Är du osäker på
                    hur en fullmakt ska se ut eller formuleras? Använd gärna vår <ExternalLink
                        text={"Fullmaktsblankett"}
                        url={"https://www.lantmateriet.se/globalassets/blanketter/fullmakt-for-ombud.pdf"}
                        openInNewTab={true}/>.</>))
                break;
            default:
                newValidationList.push(validationError)
        }
    }
    return newValidationList
}
