import {ARENDE_STATUS, ARENDE_STATUS_TYPES} from "utils/globalConstants";
import {ArendeStatusTile} from "components";

type Props = {
    currentStatus: ARENDE_STATUS;
};

function ArendeStatus(props: Props) {
        return (
            <>
                <h2 className="visually-hidden">Status för ärende</h2>
                <ul className="status-visualization" data-selenium-id="arendeStatus" data-testid={'arende-status-tiles'}>
                    <ArendeStatusTile
                        arendeStatusTypes={ARENDE_STATUS_TYPES}
                        status={ARENDE_STATUS.GRANSKNING}
                        currentStatus={props.currentStatus}
                        description={"Ansökan har kommit in och diarieförts hos Lantmäterimyndigheten. Utredning och prövning av ärendet påbörjas när en handläggare finns tillgänglig."}
                    />
                    <ArendeStatusTile
                        arendeStatusTypes={ARENDE_STATUS_TYPES}
                        status={ARENDE_STATUS.UTREDNING_PROVNING}
                        currentStatus={props.currentStatus}
                        description={<span>Ärendet utreds och prövas av Lantmäterimyndigheten. De viktigaste händelserna i ärendet finns att se under <strong>Dagbok</strong> nedan.</span>}
                    />
                    <ArendeStatusTile
                        arendeStatusTypes={ARENDE_STATUS_TYPES}
                        status={ARENDE_STATUS.BESLUT_TAGET}
                        currentStatus={props.currentStatus}
                        description={"Lantmäterimyndigheten tar beslut i ärendet. Alla beslut protokollförs, och sakägare och myndigheter med rätt att överklaga får en underrättelse om besluten."}
                    />
                    <ArendeStatusTile
                        arendeStatusTypes={ARENDE_STATUS_TYPES}
                        status={ARENDE_STATUS.REGISTRERING}
                        currentStatus={props.currentStatus}
                        description={"Beslut börjar gälla och registrering av förrättningen sker i bland annat registerkartan och fastighetsregistret."}
                    />
                    <ArendeStatusTile
                        arendeStatusTypes={ARENDE_STATUS_TYPES}
                        status={ARENDE_STATUS.SLUTFORT}
                        currentStatus={props.currentStatus}
                        description={"Ärendet är slutfört och förändringarna är registrerade. Ärendet syns i denna tjänst i 30 dagar."}
                    />
                </ul>
            </>
        );
}

export default ArendeStatus;
