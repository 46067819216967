import {ListItemFlexGroup} from "../../../../General/list/ListItemFlexGroup";
import {ListItemFlex} from "../../../../General/list/ListItemFlex";
import React from "react";
// @ts-ignore
import {IconLinkWithPopover, ListItem, ListItemActionButton} from "redet-react-components";
import {KOMPLETTERINGSTATUS, KOMPLETTERINGTYP} from "../../../../../domain/Komplettering";
import {SIGNER_STATUS, SignerStatus, SIGNINGINSTANCE_STATUS, SigningStatus} from "../../../../../domain/signing/SigningStatus";
import {getLocalizedDateString} from "../../../../../helpers/dates";
import {ISO8601} from "../../../../../utils/globalConstants";
import SigningHelper from "../../../../../helpers/SigningHelper";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store";
import {ListItemStatus} from "../../../../../domain/ListItemStatus";
import KompletteringHelper, {DUMMY_EMAIL_FOR_PHOS, DUMMY_EMAIL_FOR_PHOS_OLD} from "../../../../../helpers/KompletteringHelper";
import {signeringLeifWebYtterligareHandlingToggle, useFeatureToggle} from "../../../../../hooks/useFeatureToggle";

interface Props {
    id?: string
    header: string
    kompletteringId: string
    kompletteringTyp: KOMPLETTERINGTYP
    kompletteringStatus: KOMPLETTERINGSTATUS
    signingStatus: SigningStatus
    kvittensId?: string
    createdDate?: string
    submittedDate?: string
    onDeleteClick?: (event: any) => void
    onInviteClick?: (event: any, url: string) => void
}

export const KompletteringDefaultListItem = ({id, header,kompletteringId, kompletteringTyp, kompletteringStatus, signingStatus, kvittensId, createdDate, submittedDate, onDeleteClick, onInviteClick, ...rest}: Props) => {

    const user = useSelector((store: RootState) => store.auth.user)

    const isSigningYtterligareHandlingLeifWeb: boolean = useFeatureToggle(signeringLeifWebYtterligareHandlingToggle)

    const status: ListItemStatus = KompletteringHelper.getStatusObject(kompletteringStatus, kvittensId, signingStatus.status)

    const isSigningKompletteringInLeifWeb = () => {
        switch (kompletteringTyp) {
            case KOMPLETTERINGTYP.YTTERLIGARE_HANDLING:
                return isSigningYtterligareHandlingLeifWeb
            default:
                return false
        }
    }

    const getPopoverText = () => {
        switch (kompletteringTyp) {
            case KOMPLETTERINGTYP.YRKANDE:
                return 'Skriv under'
            default:
                return 'Bestyrk'
        }
    }
    const getInviteButton = (signerStatus: SignerStatus) => (
        <ListItemActionButton id={`inviteButton_${id}`}
                              key={`inviteButton_key_${id}`}
                              icon={'link'}
                              data-selenium-id={`inbjudanLankForEmail${signerStatus.signerEmail}Id`}
                              name={'inviteKompletteringButton'}
                              ariaLabel={'Visa inbjudningslänk'}
                              popover={{text: 'Visa inbjudningslänken'}}
                              onClick={(event: any) => {
                                  if (onInviteClick)
                                      onInviteClick(event, signerStatus.signingUrl)
                              }}/>)

    const getPhosSigner = () => {
        const signerStatus = signingStatus.signerStatus
        if (!signerStatus)
            return undefined
        return signerStatus.length > 0 && isPhosSigner(signerStatus[0]) ? signerStatus[0] : undefined
    }

    const isPhosSigner = (status: SignerStatus) => {
        return status.signerEmail === DUMMY_EMAIL_FOR_PHOS || status.signerEmail === DUMMY_EMAIL_FOR_PHOS_OLD
    }

    const showBestyrkButton = () => getPhosSigner()?.status === SIGNER_STATUS.NONE && signingStatus.status !== SIGNINGINSTANCE_STATUS.TIMED_OUT
    const showSignerInviteButton = (signerStatus: SignerStatus) => signerStatus.status === SIGNER_STATUS.NONE && !isPhosSigner(signerStatus)

    const formattedSubmittedDateString = submittedDate ? `Inskickad ${getLocalizedDateString(submittedDate, ISO8601)}` : undefined
    const formattedCreatedDateString = createdDate ? `Skapad ${getLocalizedDateString(createdDate, ISO8601)}` : undefined
    const formattedPhosSigningUrl = () => {
        let url = getPhosSigner()?.signingUrl.replace('?id=', 'sign/')
        if (url && isSigningKompletteringInLeifWeb()) {
            url = `${window.location.href}/komplettering/${kompletteringId}/preview`
        }
        return url
    }

    const bestyrkButton = (<IconLinkWithPopover
        id={`bestyrkButton_${id}`}
        key={`bestyrkButton_${id}`}
        icon={'edit_note'}
        ariaLabel={'Bestyrk komplettering'}
        popoverText={getPopoverText()}
        link={formattedPhosSigningUrl() ?? ''}
        isOpenedInNewTab={!isSigningKompletteringInLeifWeb()}/>)

    const deleteButton = (<ListItemActionButton
        id={`deleteButton_${id}`}
        key={`deleteButton_${id}`}
        icon={'delete'}
        name={'deleteKompletteringButton'}
        ariaLabel={'Ta bort komplettering'}
        popover={{text: 'Ta bort'}}
        onClick={(event: any) => {
            if (onDeleteClick)
                onDeleteClick(event)
        }}/>)

    return (<ListItemFlex data-testid={'komplettering-item'} key={`komplettering-item-key-${id}`} id={id} {...rest}>
        {{
            actions: showBestyrkButton() ? [bestyrkButton, deleteButton] : deleteButton,
            items: [
                <ListItemFlexGroup key={`komplettering-signing-item-${id}`} title={header} status={status} description={formattedSubmittedDateString ?? formattedCreatedDateString}></ListItemFlexGroup>
            ],
            sublist: signingStatus.signerStatus?.map((signerStatus, index) => <ListItem
                key={`komplettering-signer-status-${id}-${index}`}
                id={`komplettering-signer-status-${id}-${index}`}
                header={''}
                description={isPhosSigner(signerStatus) && user ? user.fullName : signerStatus.signerEmail}
                status={SigningHelper.getSignerListItemStatus(signerStatus.status, signingStatus.status)}
                actionButtons={showSignerInviteButton(signerStatus) ? [getInviteButton(signerStatus)] : []}
                hasTableActionButtons={signingStatus.signerStatus.some(status => showSignerInviteButton(status))}
            />) ?? [<></>]
        }}
    </ListItemFlex>)
}
