// @ts-ignore
import {ButtonToolbar, IconButtonWithPopover, ListGroup} from 'redet-react-components'
import {
    Fastighet as FastighetType,
    Registerbeteckning_Fastighetinfo,
    TidigareRegisterbeteckning_Fastighetinfo
} from 'domain/Ansokan'
import {FastighetsInnehav} from "../../redux/modules/FastighetsInnehav";
import {useTranslation} from 'react-i18next';

type Props = {
    deleteMethod: (beteckning: string) => void,
    egnaFastigheter: FastighetsInnehav[]
    andraFastigheter: FastighetType[]
}

export function Fastighet(props: Props) {
    const {t} = useTranslation();
    const onDeleteClick = (beteckning: string) => {
        if (props.deleteMethod) {
            props.deleteMethod(beteckning)
        }
    }
    const formatFullRegisterbeteckning = (regbet: Registerbeteckning_Fastighetinfo | TidigareRegisterbeteckning_Fastighetinfo): string => {
        if (!regbet.block || regbet.block === '*') {
            return `${regbet.registeromrade} ${regbet.traktnamn} ${regbet.enhet}`
        } else {
            return `${regbet.registeromrade} ${regbet.traktnamn} ${regbet.block}:${regbet.enhet}`
        }
    }

    // Det är alltid fastighet.data.registerbeteckning.tidigareRegisterbeteckningar[0] som är den senaste omregistreringen och det är bara den vi vill visa
    const getTidigareRegisterbeteckning = (fastighet: FastighetType) => {
        const tidigareRegisterbeteckning = fastighet?.data?.registerbeteckning?.tidigareRegisterbeteckningar?.[0]
        if (!tidigareRegisterbeteckning)
            return null
        const text = t('propertyList.reregistrated') + `${formatFullRegisterbeteckning(tidigareRegisterbeteckning)}`
        return <span className="additional_information">&nbsp;{text}</span>
    }


    const isOmregistrerad = (fastighet: FastighetType) => {
        return fastighet?.data?.registerbeteckning?.tidigareRegisterbeteckningar?.length ? fastighet?.data?.registerbeteckning?.tidigareRegisterbeteckningar?.length > 0 : false;
    }

    const getBeteckningar = () => {
        return props.andraFastigheter?.slice()
            .sort((current, next) => (current.registerbeteckning < next.registerbeteckning) ? -1 : (current.registerbeteckning > next.registerbeteckning) ? 1 : 0)
            .map(fastighet => {
                let fastighetsBeteckningId = fastighet.registerbeteckning
                return (
                    <div className="passthrough-row" key={fastighetsBeteckningId}>
                        <ButtonToolbar>
                            <IconButtonWithPopover id={fastighetsBeteckningId} icon="delete"
                                                   data-selenium-id={'fastigheter-lista-radera'}
                                                   ariaLabelledBy={fastighetsBeteckningId}
                                                   ariaLabel={t('propertyList.ariaRemove')}
                                                   popoverText={t('propertyList.popOverRemove')}
                                                   popoverTitle={t('propertyList.popOverRemove')}
                                                   onClick={() => onDeleteClick(fastighet.registerbeteckning)}></IconButtonWithPopover>
                        </ButtonToolbar>
                        <section className="content single-line">
                            <strong><span
                                id={fastighetsBeteckningId}>{formatFullRegisterbeteckning(fastighet.data.registerbeteckning)}</span></strong>
                            {isOmregistrerad(fastighet) && getTidigareRegisterbeteckning(fastighet)}
                        </section>
                    </div>
                )
            })
    }

    return (
        <>
            {getBeteckningar()?.length > 0 &&
                <div data-selenium-id="fastigheter-lista">
                    <ListGroup hasZebraBackground={true}>
                    {getBeteckningar()}
                    </ListGroup>
                </div>
            }
        </>
    )

}

export default Fastighet
