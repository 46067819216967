import * as React from 'react'
import {useEffect, useState} from 'react'
// @ts-ignore
import { ListSidePanel } from 'redet-react-components'
import {useSelector} from 'react-redux'
import {
  BLOCKING,
  ruleCategorysList,
  validationpanelGroupWhitelist,
  WARNING
} from '../../redux/modules/validation/rules/constants'
import {showValidationpanel} from '../../redux/modules/help'
import { Validation } from '../../redux/modules/validation/rules/model/Validation'
import {useAppDispatch} from "../../hooks";
import {RootState} from "../../store";

type Props = {
  id: string
}

function sortBySeverity (rule1: Validation) {
  if (rule1.severity === BLOCKING) {
    return -1
  }
  if (rule1.severity === WARNING) {
    return 1
  }
  return 0
}

function sortRulesByOrderOfFLikar (rules: Array<Validation>) {
  const itemOrder = ['fastighet', 'atgard', 'kartskiss', 'contacts', 'handlingar']
  return rules.sort((rule1, rule2) => {
    if (itemOrder.indexOf(rule1.category) === -1) {
      return 1
    }
    if (itemOrder.indexOf(rule2.category) === -1) {
      return -1
    }
    return itemOrder.indexOf(rule1.category) - itemOrder.indexOf(rule2.category)
  })
}

export function groupErrorsByCategory (errors: Array<Validation>): Array<Validation> {
  // return errors;
  const rulesNotToGroup = errors.filter(rule => validationpanelGroupWhitelist.some(ruleId => rule.id === ruleId))
  const rulesToGroup = errors.filter(rule => !validationpanelGroupWhitelist.some(ruleId => rule.id === ruleId)).sort(sortBySeverity)
  const groupedRules = ruleCategorysList.flatMap(category => {
    const rule = rulesToGroup.find(error => error.category === category)
    if (rule !== undefined) {
      switch (category) {
        case 'contacts': {
          return rule.severity === BLOCKING ? rule.cloneWithNewMessage('Kontakter innehåller fel.', undefined)
            : rule.cloneWithNewMessage('Kontakter innehåller varningar.', undefined)
        }
        case 'atgard': {
          return rule.severity === BLOCKING ? rule.cloneWithNewMessage('Åtgärder innehåller fel.', undefined)
            : rule.cloneWithNewMessage('Åtgärder innehåller varningar.', undefined)
        }
        case 'handlingar': {
          return rule.severity === BLOCKING ? rule.cloneWithNewMessage('Handlingar innehåller fel.', undefined)
            : rule.cloneWithNewMessage('Handlingar innehåller varningar.', undefined)
        }
        case 'kartskiss': {
          return rule.severity === BLOCKING ? rule.cloneWithNewMessage('Kartskiss innehåller fel.', undefined)
            : rule.cloneWithNewMessage('Kartskiss innehåller varningar.', undefined)
        }
        case 'fastighet': {
          return rule.severity === BLOCKING ? rule.cloneWithNewMessage('Fastighet innehåller fel.', undefined)
            : rule.cloneWithNewMessage('Fastighet innehåller varningar.', undefined)
        }
        default:
          return rule
      }
    }
    return []
  })
  let rules = [...groupedRules, ...rulesNotToGroup]
  return sortRulesByOrderOfFLikar(rules)
}

export default function ValidationChecklistSidePanel (props: Props) {

  const dispatch = useAppDispatch();
  const errors = useSelector((store: RootState) => store.validation.errors);
  const isOpen = useSelector((store: RootState) => store.help.isValidationpanelOpen);
  const [hasUserLearnedToOpenPanel, setHasUserLearnedToOpenPanel] = useState(false)
  const [noErrors, setNoErros] = useState(0)
  const [errorsToShow, setErrorsToShow] = useState<Array<Validation>>([])

  useEffect(() => {
    if (errors) {
      let errorsByCategory = groupErrorsByCategory(errors)
      setErrorsToShow(errorsByCategory)
      setNoErros(errorsByCategory.filter((error) => error.severity === BLOCKING).length)
    }
  }, [errors])

  useEffect(() => {
    const hasUserHasAlreadyLearnedToOpenPanel = localStorage.getItem('lm_learnedOpeningPanel') !== null
    setHasUserLearnedToOpenPanel(hasUserHasAlreadyLearnedToOpenPanel)
  }, [])

  const userOpenedPanelClick = () => {
    if (!hasUserLearnedToOpenPanel) {
      setHasUserLearnedToOpenPanel(true)
      localStorage.setItem('lm_learnedOpeningPanel', '1')
    }
  }

  const introduction = 'Se över alla varningar innan du går vidare till underskrift. Genom att klicka på respektive varning navigerar du till den aktuella sektionen.'
  return (
    <ListSidePanel errors={errorsToShow}
                   introduction={introduction}
                   ariaLabel={'Valideringssidopanel'}
                   id={props.id}
                   isOpen={isOpen}
                   badgeNumber={noErrors}
                   hasTooltip={!hasUserLearnedToOpenPanel}
                   useReactRouterLink={true}
                   panelButton={{ icon: 'warning', onClick: userOpenedPanelClick, ariaLabel: "Öppna/stäng valideringspanel" }}
                   closePanelOnClickWidth={990}
                   openCloseCallback={() => {
                      dispatch(showValidationpanel(false))
                   }}/>
  )
}
