import Log from '../../utils/log'

const log = new Log('featureToggleRepository')
const repositoryUrl = '/toggly-backend/AK000577'

const headers = new Headers();
headers.append('pragma', 'no-cache');
headers.append('cache-control', 'no-cache');

// Hämta alla feature toggles
export function fetchToggles (): Promise<any> {
  return fetch(repositoryUrl + '/toggles', {
    method: 'GET',
    headers
  }).then((response) => {
    if (!response.ok) {
      throw response
    }
    return response.json()
  }).then((data) => {
    // log.debug('Feature toggles successfully fetched from server')
    return data
  }).catch((err) => {
    log.error('An error occurd while fetching feature toggles from server', err)
    throw err
  })
}
