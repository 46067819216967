import {deleteKomplettering, loadKompletteringar} from 'repository/kompletteringRepository'
import {fakeLoadKompletteringar, fakeRemoveKomplettering} from 'repository/testdata/fakeKompletteringRepository'
import {Fetcher} from 'repository/fetcher'
import {KOMPLETTERING_STATUS_INSKICKAD} from '../../utils/globalConstants'
import {UNLOAD as ARENDE_UNLOAD} from './arendecommon'
import {kompletteringSubmit} from '../../repository/kompletteringRepository'
import {Komplettering} from "../../domain/Komplettering";
import {Dispatch} from "redux";
import {RootState} from "../../store";

const ACTION_FETCH_KOMPLETTERINGAR_PENDING = 'ArendeAtgarder/ACTION_FETCH_KOMPLETTERINGAR_PENDING'
export const ACTION_FETCH_KOMPLETTERINGAR_SUCCESS = 'ArendeAtgarder/ACTION_FETCH_KOMPLETTERINGAR_SUCCESS'
const ACTION_FETCH_KOMPLETTERINGAR_FAILED = 'ArendeAtgarder/ACTION_FETCH_KOMPLETTERINGAR_FAILED'

const ACTION_DELETE_KOMPLETTERING_PENDING = 'ArendeAtgarder/ACTION_DELETE_KOMPLETTERING_PENDING'
export const ACTION_DELETE_KOMPLETTERING_SUCCESS = 'ArendeAtgarder/ACTION_DELETE_KOMPLETTERING_SUCCESS'
const ACTION_DELETE_KOMPLETTERING_FAILED = 'ArendeAtgarder/ACTION_DELETE_KOMPLETTERING_FAILED'

const ACTION_SUBMIT_KOMPLETTERING_PENDING = 'ArendeAtgarder/ACTION_SUBMIT_KOMPLETTERING_PENDING'
export const ACTION_SUBMIT_KOMPLETTERING_SUCCESS = 'ArendeAtgarder/ACTION_SUBMIT_KOMPLETTERING_SUCCESS'
const ACTION_SUBMIT_KOMPLETTERING_FAILED = 'ArendeAtgarder/ACTION_SUBMIT_KOMPLETTERING_FAILED'

const ACTION_RELOAD_LIST_DONE = 'ArendeAtgarder/ACTION_RELOAD_LIST_DONE'
const ACTION_RELOAD_LIST = 'ArendeAtgarder/ACTION_RELOAD_LIST'
const ACTION_SELECT_DOCUMENT = 'ArendeAtgarder/ACTION_SELECT_DOCUMENT'
const ACTION_DESELECT_DOCUMENT = 'ArendeAtgarder/ACTION_DESELECT_DOCUMENT'
const ACTION_CLEAR_SELECTED_DOCUMENTS = 'ArendeAtgarder/ACTION_CLEAR_SELECTED_DOCUMENTS'

const initialState: ArendeKompletteringarState = {
    list: {pagaende: [], klara: []},
    isLoadingList: false,
    isDeleting: false,
    isCreating: false,
    isSubmitting: false,
    failedToSubmit: false,
    error: '',
    shouldReloadKompletteringar: false,
    selectedAktbilagebeteckningar: []
}

type ArendeKompletteringarState = {
    list: { pagaende: Komplettering[], klara: Komplettering[] },
    isLoadingList: boolean,
    isDeleting: boolean,
    isCreating: boolean,
    isSubmitting: boolean,
    failedToSubmit: boolean,
    error: string,
    shouldReloadKompletteringar: boolean,
    selectedAktbilagebeteckningar: string[]
}

const splitIntoPagaendeAndKlara = (kompletteringar: Komplettering[]) => {
    let pagaendeKompletteringar = []
    let klaraKompletteringar = []
    for (const komplettering of kompletteringar) {
        if (komplettering.status !== KOMPLETTERING_STATUS_INSKICKAD)
            pagaendeKompletteringar.push(komplettering)
        else
            klaraKompletteringar.push(komplettering)
    }
    return {'pagaende': pagaendeKompletteringar, 'klara': klaraKompletteringar}
}

export default function reducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case ACTION_FETCH_KOMPLETTERINGAR_PENDING:
            return {
                ...state,
                isLoadingList: true,
                error: ''
            }

        case ACTION_SUBMIT_KOMPLETTERING_PENDING:
            return {
                ...state,
                isSubmitting: true
            }

        case ACTION_SUBMIT_KOMPLETTERING_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                failedToSubmit: false
            }
        case ACTION_SUBMIT_KOMPLETTERING_FAILED:
            return {
                ...state,
                isSubmitting: false,
                failedToSubmit: true
            }

        case ACTION_FETCH_KOMPLETTERINGAR_SUCCESS:
            return {
                ...state,
                list: splitIntoPagaendeAndKlara(action.result),
                isLoadingList: false,
                error: ''
            }

        case ACTION_FETCH_KOMPLETTERINGAR_FAILED:
            return {
                ...state,
                isLoadingList: false,
                error: 'failed to load kompletteringar'
            }

        case ACTION_DELETE_KOMPLETTERING_PENDING:
            return {
                ...state,
                isDeleting: true,
            }

        case ACTION_DELETE_KOMPLETTERING_FAILED: // Ska falla igenom till nästa case.
        case ACTION_DELETE_KOMPLETTERING_SUCCESS:
            return {
                ...state,
                isDeleting: false,
            }

        case ACTION_RELOAD_LIST:
            return {
                ...state,
                shouldReloadKompletteringar: true,
            }

        case ACTION_RELOAD_LIST_DONE:
            return {
                ...state,
                shouldReloadKompletteringar: false,
            }

        case ACTION_SELECT_DOCUMENT:
            return {
                ...state,
                selectedAktbilagebeteckningar: [...state.selectedAktbilagebeteckningar, action.documentAkt],
            }

        case ACTION_DESELECT_DOCUMENT:
            return {
                ...state,
                selectedAktbilagebeteckningar: state.selectedAktbilagebeteckningar.filter(akt => akt !== action.documentAkt),
            }

        case ACTION_CLEAR_SELECTED_DOCUMENTS:
            return {
                ...state,
                selectedAktbilagebeteckningar: [],
            }
        case ARENDE_UNLOAD:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export function setAktbilagebeteckning(documentAkt: string) {
    return {
        type: ACTION_SELECT_DOCUMENT,
        documentAkt
    }
}

export function removeAktbilagebeteckning(documentAkt: string) {
    return {
        type: ACTION_DESELECT_DOCUMENT,
        documentAkt
    }
}

export function clearAktbilagebeteckningar() {
    return {
        type: ACTION_CLEAR_SELECTED_DOCUMENTS
    }
}

export function fetchKompletteringar(arendenummer: string) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        dispatch({type: ACTION_FETCH_KOMPLETTERINGAR_PENDING})
        const fetcher = new Fetcher(dispatch, getState, false)
        return (getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeLoadKompletteringar() : loadKompletteringar(fetcher, arendenummer))
            .then((kompletteringar: Komplettering[]) => {
                dispatch({
                    type: ACTION_FETCH_KOMPLETTERINGAR_SUCCESS,
                    result: kompletteringar
                })
            }).catch((err: any) => {
                dispatch({type: ACTION_FETCH_KOMPLETTERINGAR_FAILED})
                throw err
            })
    }
}

export function removeKomplettering(kompletteringId: string) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        dispatch({type: ACTION_DELETE_KOMPLETTERING_PENDING})
        const fetcher = new Fetcher(dispatch, getState)
        return (getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeRemoveKomplettering(kompletteringId) : deleteKomplettering(fetcher, kompletteringId))
            .then((result: Komplettering[]) => {
                dispatch({type: ACTION_DELETE_KOMPLETTERING_SUCCESS})
                return result
            })
            .catch((error: any) => {
                dispatch({type: ACTION_DELETE_KOMPLETTERING_FAILED})
                throw error
            })
    }
}

export function submitKomplettering(kompletteringId: string) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        dispatch({type: ACTION_SUBMIT_KOMPLETTERING_PENDING})
        const fetcher = new Fetcher(dispatch, getState, false)
        return (getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeRemoveKomplettering(kompletteringId) : kompletteringSubmit(fetcher, kompletteringId))
            .then((result: any) => {
                dispatch({type: ACTION_SUBMIT_KOMPLETTERING_SUCCESS})
                return result
            })
            .catch((error: any) => {
                dispatch({type: ACTION_SUBMIT_KOMPLETTERING_FAILED})
                throw error
            })
    }
}

export function reloadKompletteringar(value: boolean) {
    if (value === true) {
        return {
            type: ACTION_RELOAD_LIST,
        }
    }
    return {
        type: ACTION_RELOAD_LIST_DONE,
    }
}

