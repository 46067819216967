import React, {Component, useId} from 'react'
//@ts-ignore
import { ButtonGroup, ButtonSub, ButtonToolbarV2, Column } from 'redet-react-components'

type BifogadKartskissProps = {
  namn: string,
  thumbnail: string,
  deleteBifogadKartskiss: Function,
  getBifogadKartskiss: Function,
  kartskissId: string
}

export default function BifogadKartskiss(props: BifogadKartskissProps) {
    const headerId = useId()
    return (
      <Column columns="col-xs-12 col-sm-6 col-lg-4 col-xxl-3">
        <div className="image-panel" role="listitem" aria-labelledby={headerId}>
          {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
          <header className="map-heading" id={headerId}>{props.namn}</header>
          <figure style={{ height: '270px' }} aria-labelledby={headerId}><img src={props.thumbnail} alt={`Bild av ${props.namn}`}/></figure>
          <ButtonToolbarV2>
            <ButtonGroup>
              <ButtonSub id="visaBifogadKartskissButton"
                         text={'Visa'}
                         icon={'zoom_in'}
                         aria-labelledby={`visaBifogadKartskissButton ${headerId}`}
                         onClick={() => props.getBifogadKartskiss(props.kartskissId)}/>
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a className="hidden" href="#kartskiss_lightbox_0" id="trigger_kartskiss_lightbox_0"/>
              <ButtonSub id="deleteBifogadKartskissButton"
                         text={'Ta bort'}
                         icon={'delete'}
                         aria-labelledby={`deleteBifogadKartskissButton ${headerId}`}
                         onClick={() => props.deleteBifogadKartskiss(props.kartskissId)}/>
            </ButtonGroup>
          </ButtonToolbarV2>
        </div>
      </Column>

    )
}


