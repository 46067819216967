

type SmallLoadingProps = {
  text: string
}

export default function SmallLoading(props: SmallLoadingProps){
    return (
      <div className="linear-animation">
        <span className="block"/><span className="block"/><span className="block"/><span className="block"/><span
        className="block"/>
        {props.text !== '' &&
          <span className="text">{props.text}</span>
        }
      </div>
    )
}


