export default class Medsokande {
    name: string
    email: string
    personnummer: string
    mobiltelefon?: string
    telefonarbete?: string
    telefonbostad?: string
    adress?: string
    postnummer?: string
    ort?: string
    land?: string

    constructor(name: string, email: string, personnummer: string) {
        this.name = name
        this.email = email
        this.personnummer = personnummer
    }
}