import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Checkbox, FormFieldset } from 'redet-react-components'
import { CheckboxFinalForm, Subscribe, WhenFieldChanges } from '../../General/forms/finalForm'
import { FieldArray } from 'react-final-form-arrays'
import { Term } from '../../../views'

const mapStateToProps = (store) => {
  return {
    fastigheterAddedToAnsokan: store.fastigheter.fastigheterList,
    // WARNING - These props are not used in the component but still important so don't remove!
    // For some reason(bug?) redux-form won't trigger re-render (and componentWillReceiveProps) when the
    // selectedFastigheter array prop is modified so in order to detect when it has been changed we instead
    // monitor it using normal redux.
    // selectedFastigheterIndividualTrigger: store.form[formProperties.individualContactBeingEdited.name].representerarFastigheter,
    // selectedFastigheterOrganizationTrigger: store.form[formProperties.organizationContactBeingEdited.name].representerarFastigheter
  }
}

export const optOutFromRepresentingFastighetLabel = 'Personen företräder ingen fastighet i förrättningen'

class ContactFastighetSelection extends Component {
  static propTypes = {
    fastigheterAddedToAnsokan: PropTypes.array.isRequired,
    agerValdaFastigheter: PropTypes.array.isRequired,
    optOutFromRepresentingFastighet: PropTypes.bool.isRequired,
    importedContact: PropTypes.bool.isRequired,
  }
  static defaultProps = {
    agerValdaFastigheter: []
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      selectedList: [],
      text: 'Företräder ingen fastighet'
    }
  }

  render () {
    const possibleFastigheterToSelect = this.props.fastigheterAddedToAnsokan.map((fastighet) => {
      return {
        label: fastighet.registerbeteckning,
        value: { registerbeteckning: fastighet.registerbeteckning, objektidentitet: fastighet.data.objektidentitet },
      }
    })
    return (
      <FormFieldset
        title={<>Följande fastigheter <Term label="företräds" moment="help-contactinformation"
                                            term="term-foretradaFastighet"/> av personen</>}
        id={'foretraderFastighetTitleId'}>
        <div id="annanFastighet">
          <WhenFieldChanges field={'optOutFromRepresentingFastighet'}
                            becomes={true}
                            set={'representerarFastigheter'}
                            to={[]}/>

          <WhenFieldChanges field={'optOutFromRepresentingFastighet'}
                            becomes={false}
                            set={'representerarFastigheter'}
                            to={this.props.agerValdaFastigheter}/>

          {possibleFastigheterToSelect.length > 0 &&
            <>
              <FieldArray name={'representerarFastigheter'} options={possibleFastigheterToSelect}>
                {({ fields, options, meta }) => (
                  <>
                    <CheckboxFinalForm label={optOutFromRepresentingFastighetLabel}
                                       key={'optOutFromRepresentingFastighet'}
                                       field={'optOutFromRepresentingFastighet'}
                                       disabled={fields.length > 0 && this.props.importedContact !== true}/>
                    <div id={'representerarFastigheterListId'}>
                      {options.map((option, index) =>
                        <Subscribe key={'subscriber_keY_' + option.value.objektidentitet} fieldName={'optOutFromRepresentingFastighet'}>
                          {({ input }) =>
                            <Checkbox field={option.label}
                                      name={option.label}
                                      key={option.value.objektidentitet}
                                      isDisabled={input.value === true}
                                      isChecked={fields.value?.some((item) => item.objektidentitet === option.value.objektidentitet) === true}
                                      onChange={() => {/* Kommentar för att förhindra varningar då isChecked används */}}
                                      label={option.label}
                                      onClick={(evt) => evt.target.checked ? fields.push(option.value) :
                                        fields.remove(fields.value.findIndex((listItem) => option.value.objektidentitet === listItem.objektidentitet))
                                      }
                            />
                          }
                        </Subscribe>
                      )}
                      {meta.error &&
                        <span className="validation mandatory">{meta.error}</span>
                      }
                    </div>
                  </>
                )}
              </FieldArray>
            </>
          }
          {possibleFastigheterToSelect.length === 0 &&
            <span className="lowercase">{'Ansökan saknar valda fastigheter'}</span>}
        </div>
      </FormFieldset>
    )
  }
}

export default connect(mapStateToProps, undefined)(ContactFastighetSelection)
