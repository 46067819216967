import { t } from "i18next"
import '../../../i18n';
// @ts-ignore
import { SidePanelChapterHeading } from "redet-react-components"

export function FeedbackHelpContent() {
    return(
    <SidePanelChapterHeading header={t("navigation.feedback_modal.terms.title")} 
    introduction={<>{t("navigation.feedback_modal.terms.intro")} </>}/> 
)}

