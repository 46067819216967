import { handleSessionTimeoutError } from '../utils/errorHandler'
import { LockedAnsokanManipulationError, SessionTimeoutError } from '../utils/repositoryHelpers'
import { AjaxClientError, handleError } from 'utils/errorHandler'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'universal-cookie'

export class ResponseWasNotOkError {
  constructor (response) {
    this.response = response
  }
}

export class Fetcher {

  constructor (dispatch, getState, globalErrorHandling = true, cookies = new Cookies(), fetchheaders = {}) {
    this.dispatch = dispatch
    this.getState = getState
    this.fetchheaders = fetchheaders
    this.globalErrorHandling = globalErrorHandling
    this.mockHeaders = this.setMockHeaders(getState())

    // Check if session has changed since we last stored the user information, this can happen if
    // the user start a new session in another browser tab, in that case this tab is no longer valid
    if (getState().auth.user.currentSessionId !== cookies.get('MRHSession')) {
      this._handleSessionTimeout()
    }

  }

  fetching (url, options, headers) {
    const transactionid = uuidv4()
    return fetch(url, {
      ...options,
      headers: { ...this.fetchheaders, ...this.mockHeaders, ...headers, 'enak.transactionid': transactionid }
    })
      .then(response => {
        this._handleErrors(response)
        return response
      })
      .catch(error => {
        if (this.globalErrorHandling && !(error instanceof SessionTimeoutError) && !(error instanceof LockedAnsokanManipulationError)) {
          this._handleGlobalError(error, options, url, transactionid)
        }
        throw error
      })
  }

  setMockHeaders (store) {
    let headers = {}
    if (store?.auth?.user?.environment !== 'prd') {
      if (store?.featureToggle?.PHOS_MOCK_SIGNING_ENABLED)
        headers['phos-mock-signing-service'] = 'true'
    }
    return headers
  }

  _handleGlobalError (error, options, url, transactionid) {
    if (error instanceof ResponseWasNotOkError) {
      const method = options != null && options.method != null ? options.method : 'GET'
      error.response.text().then(body =>
        handleError(new AjaxClientError(error.response.url, error.response.status, error.response.statusText, body, method, transactionid), this.dispatch)
      )
    } else {
      // Should be either AbortError or TypeError which are instances of DOMException
      handleError(new AjaxClientError(url, 'N/A', 'Fetch error - ' + error.name + ': ' + error.message, 'N/A', 'N/A', transactionid), this.dispatch)
    }

  }

  setGlobalErrorHandling (bool) {
    this.globalErrorHandling = bool
  }

  _handleSessionTimeout () {
    handleSessionTimeoutError()
    throw new SessionTimeoutError()
  }

  _checkIfSessionTimeout (response) {
    if (response.headers.get('enak.passedauthentication') !== 'true' && response.headers.get('svarstjanst.passedauthentication') !== 'true') {
      this._handleSessionTimeout()
    }
  }

  _checkIfUserTryToManipulateLockedAnsokan (response) {
    if (response._bodyText) {
      const errorMessage = JSON.parse(response._bodyText).message
      if (errorMessage === 'Endast ansökningar i status UTKAST får förändras') {
        throw new LockedAnsokanManipulationError()
      }
    }
  }

  _handleErrors (response) {
    this._checkIfSessionTimeout(response)
    this._checkIfUserTryToManipulateLockedAnsokan(response)
    if (!response.ok) {
      if (this.globalErrorHandling) {
        throw new ResponseWasNotOkError(response)
      }
      throw response
    }
  }
}
