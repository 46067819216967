import Log from 'utils/log'
import { v4 as uuidv4 } from 'uuid'
import { Draw } from 'ol/interaction'
import { unByKey } from 'ol/Observable'

export default class TextInteraction {
  constructor (mapController, setCursorFunc, interactionDestructors, featureHandler, ol) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.setCursorFunc = setCursorFunc
    this.interactionDestructors = interactionDestructors
    this.featureHandler = featureHandler
    this.ol = ol
  }

  init () {
    const interaction = new Draw({
      features: this.featureHandler.getDrawingFeatures(),
      type: 'Point',
      condition: (event) => {
        let existingTextFeature = false
        this.mapController.getMap().forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          if (layer && feature.getGeometry().getType() === 'Point') {
            if (feature.getId() !== undefined) {
              existingTextFeature = true
            }
          }
        })

        return !existingTextFeature
      }
    })

    const drawEnd = interaction.on('drawend', (event) => {
      event.feature.setId(uuidv4())
      event.feature.set('isTextPoint', true)
      event.feature.set('hasTextFeature', true)
      interaction.setActive(true)
    })

    this.interactionDestructors.push(() => {
      this.log.debug('Unregister TEXT interaction')
      unByKey(drawEnd)
      this.mapController.getMap().removeInteraction(interaction)
      this.setCursorFunc('auto')
    })

    this.log.debug('Register TEXT interaction')
    this.mapController.getMap().addInteraction(interaction)

    this.setCursorFunc('crosshair')
  }
}
