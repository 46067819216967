import React from 'react'
import PropTypes from 'prop-types'
import { IconLink, ListGroup, ListItem, withPopover } from 'redet-react-components'

function BifogadeHandlingarTable ({ handlingar, isBestyrkt }) {

  const IconLinkWithPopover = withPopover(IconLink)
  return (
    <ListGroup id="bifogadeHandlingarTable">
      {handlingar?.map(handling => {
        const itemId = handling.name + handling.index.toString()
        const downloadButton = [<IconLinkWithPopover
          key={`${itemId}DownKey`}
          link={handling.href}
          target={'_blank'}
          download={handling.name}
          id={`downloadHandling${handling.index}`}
          data-selenium-id={`downloadHandling${itemId}Id`}
          icon={'file_download'}
          ariaLabel={'Ladda ner handling'}
          popoverText={'Ladda ner den här handlingen'}/>]
        return (
          <ListItem
            actionButtons={downloadButton}
            key={`${itemId}Key`}
            id={itemId}
            header={handling.name}
            description={handling.size + ', ' + handling.pages}
            status={isBestyrkt ? { text: 'Bestyrkt', icon: 'check' } : undefined}>
          </ListItem>
        )
      })}
    </ListGroup>
  )
}

BifogadeHandlingarTable.propTypes = {
  handlingar: PropTypes.array.isRequired,
}

export default BifogadeHandlingarTable
