import {ANSOKAN_FORM_DESTROY} from 'redux/modules/sharedActions'
import {fetchStatus, lock, status, submit, unlock} from 'repository/ansokanRepository'
import {addProgress, endProgress} from './fullscreenLoading'
import {Fetcher} from '../../repository/fetcher'
import {RootState} from "../../store";
import {Dispatch} from "redux";
import {AnsokanStatus} from "../../domain/Ansokan";
import {initialState as crudinitial} from "././ansokanCRUD"

export const LOCK_PENDING = 'ansokanRepository/LOCK_PENDING'
export const LOCK_SUCCESSFUL = 'ansokanRepository/LOCK_SUCCESSFUL'
export const LOCK_FAILED = 'ansokanRepository/LOCK_FAILED'

export const UNLOCK_PENDING = 'ansokanRepository/UNLOCK_PENDING'
export const UNLOCK_SUCCESSFUL = 'ansokanRepository/UNLOCK_SUCCESSFUL'
export const UNLOCK_FAILED = 'ansokanRepository/UNLOCK_FAILED'

export const SUBMIT_PENDING = 'ansokanRepository/SUBMIT_PENDING'
export const SUBMIT_SUCCESSFUL = 'ansokanRepository/SUBMIT_SUCCESSFUL'
export const SUBMIT_FAILED = 'ansokanRepository/SUBMIT_FAILED'

export const STATUS_PENDING = 'ansokanRepository/STATUS_PENDING'
export const STATUS_SUCCESSFUL = 'ansokanRepository/STATUS_SUCCESSFUL'
export const STATUS_FAILED = 'ansokanRepository/STATUS_FAILED'

const initialState = {
    lockIsPending: false,
    lockSuccessful: false,
    lockFailed: false,
    ansokanStatus: AnsokanStatus.OKAND,
    ...crudinitial
}

export default function reducer(state = initialState, action:any  = {}) {
    switch (action.type) {
        case LOCK_PENDING:
            return {
                ...state,
                lockIsPending: true,
                lockSuccessful: false,
                lockFailed: false,
                error: ''
            }
        case LOCK_SUCCESSFUL:
            return {
                ...state,
                lockIsPending: false,
                lockSuccessful: true,
                lockFailed: false
            }
        case LOCK_FAILED:
            return {
                ...state,
                lockIsPending: false,
                lockSuccessful: false,
                lockFailed: true,
                error: action.value
            }
        case STATUS_PENDING:
            return {
                ...state,
                ansokanStatus: null,
            }
        case STATUS_SUCCESSFUL:
            return {
                ...state,
                ansokanStatus: action.result,
            }
        case STATUS_FAILED:
            return {
                ...state,
                ansokanStatus: null
            }
        case ANSOKAN_FORM_DESTROY:
            return initialState
        default:
            return state
    }
}

export function lockingAnsokan() {
    return {
        type: LOCK_PENDING
    }
}

export function lockAnsokanSuccess() {
    return {
        type: LOCK_SUCCESSFUL,
    }
}

export function lockAnsokanFailed(error: string) {
    return {
        type: LOCK_FAILED,
        value: error,
    }
}

export function lockAnsokan(key: string) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        dispatch(lockingAnsokan())
        dispatch(addProgress(true))
        const fetcher = new Fetcher(dispatch, getState)
        return lock(fetcher, key)
            .then(() => {
                dispatch(lockAnsokanSuccess())
            }).catch(error => {
                dispatch(lockAnsokanFailed(error))
            }).finally(() => {
                dispatch(endProgress())
            })
    }
}

export function unlockingAnsokan() {
    return {
        type: UNLOCK_PENDING
    }
}

export function unlockAnsokanSuccess() {
    return {
        type: UNLOCK_SUCCESSFUL,
    }
}

export function unlockAnsokanFailed(error: string) {
    return {
        type: UNLOCK_FAILED,
        value: error
    }
}

export function unlockAnsokan(key: string) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        dispatch(unlockingAnsokan())
        dispatch(addProgress(true))
        const fetcher = new Fetcher(dispatch, getState)
        return unlock(fetcher, key)
            .then(() => {
                dispatch(unlockAnsokanSuccess())
            }).catch(error => {
                dispatch(unlockAnsokanFailed(error))
            }).finally(() => {
                dispatch(endProgress())
            })
    }
}

export function changeAnsokanStatus(key: string, newStatus: any) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState)
        return status(fetcher, key, newStatus)
            .then((result) => {
                return result
            }).catch(error => {
                return error
            })
    }
}

export function submittingAnsokan() {
    return {
        type: SUBMIT_PENDING
    }
}

export function submitAnsokanSuccess() {
    return {
        type: SUBMIT_SUCCESSFUL,
    }
}

export function submitAnsokanFailed(error: string) {
    return {
        type: SUBMIT_FAILED,
        value: error
    }
}

export function submitAnsokan(key: string) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        dispatch(submittingAnsokan())
        dispatch(addProgress(false))
        const fetcher = new Fetcher(dispatch, getState, false) // globalErrorHandling ska vara false då vi tar hand om fel som kastas högre upp i stacken
        return submit(fetcher, key, getState().featureToggle.SIGNING_SERVICE_MOCK_ENABLED)
            .then((response) => {
                dispatch(submitAnsokanSuccess())
                return response
            }).catch(error => {
                dispatch(submitAnsokanFailed(error))
                throw error;
            }).finally(() => {
                dispatch(endProgress())
            })
    }
}

export function fetchingAnsokanStatus() {
    return {
        type: STATUS_PENDING
    }
}

export function fetchAnsokanStatusSuccess(_status: AnsokanStatus) {
    return {
        type: STATUS_SUCCESSFUL,
        result: _status
    }
}

export function fetchAnsokanStatusFailed(error: string) {
    return {
        type: STATUS_FAILED,
        value: error
    }
}

export function fetchAnsokanStatus(key: string) {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {
        dispatch(fetchingAnsokanStatus())
        dispatch(addProgress(true))
        const fetcher = new Fetcher(dispatch, getState, false)
        return fetchStatus(fetcher, key)
            .then(_status => {
                dispatch(fetchAnsokanStatusSuccess(_status))
            }).catch(error => {
                dispatch(fetchAnsokanStatusFailed(error))
                throw error
            }).finally(() => {
                dispatch(endProgress())
            })
    }
}

