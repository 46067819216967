import {useState } from 'react'
//@ts-ignore
import { FormFieldset, WizardNavigationButtonToolbar } from 'redet-react-components'
import { EpostInputFieldWithValidation } from '../../../components/General/EpostInputFieldWithValidation'

type Props = {
  setNotifieringEmail: (value: string) => void,
  notifieringEmail: string,
  prevButtonText: string,
  //Injectas med clone element från WizardDialogStep :sick!
  nextStep?: () => void
  previousStep?: () => void
}

export function EmailBifogadeHandlingarKompletteringWizard(props: Props){
  
  const [notifieringEmail, setEmail] = useState(props.notifieringEmail);
  const [emailError, setEmailError] = useState(false);

  function setNotifieringemailEmailUppdateringarValue(value: string) {
    setEmail(value);
    props.setNotifieringEmail(value);
  }

  function isFormValid () {
    return notifieringEmail?.length > 2 && !emailError
  }

    const defaultEpostValue = props.notifieringEmail
    return <>

      <FormFieldset title={'E-post för notifiering'}
                    description={'Ange din e-postadress för att ta emot kvittens vid inskickande.'}>
        <EpostInputFieldWithValidation inputFieldHasWarningValidation={false}
        id={'stepKvittensEmailInput'}
        defaultEpost={defaultEpostValue}
        isRequired={true}
        callbackValidEmail={setNotifieringemailEmailUppdateringarValue}
        disabledButton={setEmailError}/>
      </FormFieldset>

      <WizardNavigationButtonToolbar prevButtonText={props.prevButtonText}
                                     onClickPrevButton={props.previousStep}
                                     nextButtonText={'Bestyrk handling'}
                                     nextButtonId={'bestyrkHandlingButton'}
                                     isNextButtonDisabled={!isFormValid()}
                                     onClickNextButton={() => {
                                      if(props.nextStep){
                                        props.nextStep()
                                      }            
                                     }}/>
    </>
  }


