import React, { useState } from 'react'
//@ts-ignore
import { FormFieldset, TextArea, WizardNavigationButtonToolbar } from 'redet-react-components'

type Props = {
  setYrkandeText: (yrkandeText: string) => void,
  initText: string,
  //Injectas med clone element från WizardDialogStep :sick!
  nextStep?: () => void
  previousStep?: () => void
  nextButtonText: string
}

export function YrkandeWizardStepSkriv (props: Props) {
  const [yrkandetext, setYrkandetext] = useState(props.initText ?? '')
  return (
    <>
      <FormFieldset title={'Beskrivning av yrkandet'} id={'skrivYrkandeWizardStepId'}>
        <TextArea isRequired={true}
                  id={'yrkandeTextInputId'}
                  maxLength={4000}
                  characterLimit={4000}
                  value={yrkandetext}
                  label="Härmed yrkas på"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setYrkandetext(event.target.value)}/>
      </FormFieldset>
      <WizardNavigationButtonToolbar nextButtonText={props.nextButtonText}
                                     isNextButtonDisabled={yrkandetext.length === 0}
                                     onClickNextButton={() => {
                                      props.setYrkandeText(yrkandetext);
                                      if(props.nextStep){props.nextStep()}
                                     }}
                                     prevButtonText={'Ändra information'}
                                     onClickPrevButton={() => {
                                      props.setYrkandeText(yrkandetext);
                                      if(props.previousStep){props.previousStep()}
                                     }}/>
    </>)
}