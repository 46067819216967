import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom"
import {SigningDocumentPreview} from "../../components/pdf/SigningDocumentPreview"
import React, {useEffect, useState} from "react"
import {useAppDispatch} from "../../hooks"
import {JumpLinkToMain} from "../../components/General/JumpLinkToMain"
import {Helmet} from "react-helmet"
import {Navigation} from "../../components"
import {fetchSigningStatusKomplettering} from "../../redux/modules/arendeKomplettering"
import {Komplettering, KOMPLETTERINGSTATUS} from "../../domain/Komplettering"
import SignDocumentTextHelper, {
    ErrorData,
    PreviewData,
    SuccessData
} from "../../components/pdf/helper/SignDocumentTextHelper"
// @ts-ignore
import {CircleLoading, ModalDialogAlert, ModalDialogSub} from "redet-react-components"
import {SignAutoPostForm} from "../../components/pdf/SignAutoPostForm"
import {declineSigningRequest, fetchSigningFormValues} from "../../redux/modules/signing"
import {SigningFormValues} from "../../domain/signing/SigningModels";
import HjalpForSignering from "../euCitizenApplication/HjalpForSignering";
import {submitKomplettering} from "../../redux/modules/arendeKompletteringar";

type Props = {
    signResponse?: boolean
    attachToDomElementWithId?: string
}

export function SignKompletteringPreviewPage({signResponse = false, attachToDomElementWithId = 'rootBody'}: Props) {
    //värden från route
    const {id: arendenummer, kompletteringId} = useParams()
    const location = useLocation()
    const [searchParams] = useSearchParams();

    const [signingResponseError] = useState(!!searchParams.get('error'))
    const [komplettering, setKomplettering] = useState<Komplettering>()
    const [previewViewModel, setPreviewViewModel] = useState<PreviewData>()
    const [successViewModel, setSuccessViewModel] = useState<SuccessData>()
    const [errorViewModel, setErrorViewModel] = useState<ErrorData>()

    const [loadingMessage, setLoadingMessage] = useState<string | null>(null)
    const [signingForm, setSigningForm] = useState<SigningFormValues>()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (kompletteringId) {
            toggleLoadingSpinner(true, 'Förbereder ...')
            pageInit(kompletteringId)
                .catch((error: any) => {
                    setErrorViewModel({
                        heading: 'Ett oväntat fel har inträffat',
                        description: 'Klicka på knappen nedan för att ta dig tillbaka till ärendet och starta om signeringen'
                    })
                })
                .finally(() => toggleLoadingSpinner())
        }
    }, [kompletteringId])

    const pageInit = async (kompletteringId:string) => {
        // hämta status
        const komplettering:Komplettering = await fetch(kompletteringId)
        setKomplettering(komplettering)

        if(signResponse) {
            // detta betyder att vi landat här igen efter signering är avklarat ->
            // skulle kanske kunna tänkas vara en fristående kompononent med egen url för samtliga signeringar ...
            // Todo: Utöka med kontroll av signingStatus? flerpartssigenring lite lurigt, inkludera party id för att hitta rätt signatör ?
            // kontrollera ev error
            if(signingResponseError) {
                const viewModel = SignDocumentTextHelper.getSigningResponseError()
                setErrorViewModel(viewModel)
            } else {
                //skicka in komplettering
                toggleLoadingSpinner(true, 'Skickar in ...')
                await submit(kompletteringId)
                    .then(submittedDate => {
                        const viewModel = SignDocumentTextHelper.getSignedAndSubmittedSuccessViewModel(komplettering.typ, submittedDate)
                        setSuccessViewModel(viewModel)
                    })
                    .catch(error => {
                        // om vi misslyckas med inskick låter vi batchjobbet ta hand om det
                        // visa meddelande
                        const viewModel = SignDocumentTextHelper.getSignedNotSubmittedSuccessViewModel(komplettering.typ)
                        setSuccessViewModel(viewModel)
                    })
            }
        } else {
            // kontrollerar status
            if (komplettering.status === KOMPLETTERINGSTATUS.SIGNERAS) {
                //hämtar vy-modell för kompletteringstypen
                const viewModel = SignDocumentTextHelper.getDocumentPreviewViewModel(komplettering.typ)
                setPreviewViewModel(viewModel)
            } else {
                //om vi har en annan status än SIGNERAS så är något tokigt.
                const viewModel = SignDocumentTextHelper.getFaultyStatusError(komplettering.status)
                setErrorViewModel(viewModel)
            }
        }
        toggleLoadingSpinner()
    }

    const fetch = (kompletteringId: string): Promise<Komplettering> => {
        return new Promise((resolve, reject) => {
            dispatch(fetchSigningStatusKomplettering(kompletteringId))
                .then((response: Komplettering) => resolve(response))
                .catch((error: any) => reject(error))
        })
    }

    const submit = (kompletteringId: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            dispatch(submitKomplettering(kompletteringId))
                .then((response: Komplettering) =>  {
                    resolve(response.inskickadDatum)
                })
                .catch((error: any) =>  reject(error))
        })
    }

    const toggleLoadingSpinner = (show: boolean = false, message: string = '') => setLoadingMessage(show ? message : null)
    const isLoading = () => loadingMessage != null
    const navigateToArendeView = (anchor = '') => navigate(`/privat/arende/${arendenummer}${anchor}`)

    // ** button events
    async function onApproveButtonClick() {
        if (komplettering?.signingRequestId) {
            // användaren har valt att signera handligen
            // detta är url:en som användare landar på efter bankId har slutförts
            const redirectUrl = decodeURIComponent(window.origin + location.pathname + '/signresponse')
            const formValues = await getApprovalPostForm(komplettering.signingRequestId, redirectUrl)
            if (formValues) {
                // detta kommer automatiskt att trigga en post och redirect till bank-id signering som i sin tur
                // redirectar tillbaka till redirect urlen
                setSigningForm(formValues)
            }
        }
    }

    async function onDeclineButtonClick() {
        if (komplettering?.signingRequestId) {
            const success = await declineRequest(komplettering.signingRequestId)
            if (success) {
                const viewModel = SignDocumentTextHelper.getDeclineViewModel(komplettering.typ)
                setSuccessViewModel(viewModel)
            }
        }
    }

    function onBackButtonClick() {
        navigateToArendeView('#komplettera-arende')
    }

    function onSuccessModalButtonClick() {
        navigateToArendeView('#komplettera-arende')
    }

    function onErrorModalButtonClick() {
        navigateToArendeView()
    }

    // ** server events
    async function getApprovalPostForm(requestId: string, redirectUrl: string) {
        toggleLoadingSpinner(true, 'Du skickas nu vidare till e-legitimering ...')
        return dispatch(fetchSigningFormValues(requestId, redirectUrl))
            .catch(error => {
                setErrorViewModel({
                    heading: 'Ett oväntat fel har inträffat',
                    description: 'Klicka på knappen nedan för att starta om signeringen'
                })
                console.log(error)
            })
            .finally(() => setTimeout(() => toggleLoadingSpinner(), 3000))
    }

    async function declineRequest(requestId: string) {
        toggleLoadingSpinner(true, 'Avböjer signering ...')
        return dispatch(declineSigningRequest(requestId))
            .catch(error => {
                setErrorViewModel({
                    heading: 'Ett oväntat fel har inträffat',
                    description: 'Klicka på knappen nedan för att starta om signeringen'
                })
            })
            .finally(() => setTimeout(() => toggleLoadingSpinner(), 500))
    }

    // ** gui
    return <>
        <JumpLinkToMain/>
        <header>
            <Navigation/>
            <Helmet>
                <title>Bestyrk kopia</title>
            </Helmet>
        </header>
        {signingForm && <div>
            <SignAutoPostForm form={signingForm}/>
        </div>}
        <main className="container" id="sign-komplettering-preview-container">
            {isLoading() && <>
                <CircleLoading text={loadingMessage}
                               isFocusTrapped={false}
                               attachToDomElementWithId={attachToDomElementWithId}
                               isFullscreen={true}/>
            </>}
            {/*preview*/}
            {!signResponse && komplettering?.signingRequestId && previewViewModel &&
                <SigningDocumentPreview requestId={komplettering?.signingRequestId}
                                        viewModel={previewViewModel}
                                        onApproveButtonClick={() => onApproveButtonClick()}
                                        onDeclineButtonClick={() => onDeclineButtonClick()}
                                        onBackButtonClick={() => onBackButtonClick()}/>}
            {/*sign response message*/}
            {successViewModel && <>
                <ModalDialogSub modalTitle={successViewModel.heading}
                                ingress={successViewModel.introduction}
                                description={successViewModel.description}
                                acceptButton={{
                                    text: successViewModel.confirmButtonText,
                                    icon: 'arrow_back',
                                    onClick: () => onSuccessModalButtonClick()
                                }}>
                </ModalDialogSub>
            </>}
            {/*error*/}
            {errorViewModel && <>
                <ModalDialogAlert modalTitle={errorViewModel.heading}
                                  description={errorViewModel.description}
                                  acceptButton={{
                                      text: 'Tillbaka till ärendet',
                                      icon: 'arrow_back',
                                      onClick: () => onErrorModalButtonClick()
                                  }}/>
            </>}
        </main>
        <HjalpForSignering/>
    </>
}
