// @ts-ignore
import {SidePanelChapterHeading, SidePanelSection} from "redet-react-components";


export const ArendestatusHelpContent =
    <>
        <SidePanelChapterHeading header={'Ärendestatus'}
            sectionTitle={'Förrättningsärende'}
            introduction={'För att beskriva var i handläggningsprocessen ett ärende befinner sig finns fem olika statusar. Mer information om repspektive status finns nedan.'}
        />
            <SidePanelSection heading={'Inledande granskning'} id={'term-inledande_granskning'}>
                <p>
                    Ansökan har kommit in och diarieförts hos Lantmäterimyndigheten.
                    Vi kontrollerar och granskar din ansökan, och hör av oss om vi behöver ytterligare information från dig.
                    Ärendet kommer att börja handläggas så snart en handläggare finns tillgänglig.
                    Information om kö- och handläggningstider finns&nbsp;
                    <a href={'https://www.lantmateriet.se/sv/fastigheter/Andra-fastighet/hur-lang-tid-tar-en-lantmateriforrattning/'}
                       rel='noopener noreferrer'
                       target="_blank">här</a>.
                </p>
            </SidePanelSection>
            <SidePanelSection heading={'Utredning och prövning'} id={'term-utredning_provning'}>
                <p>
                    Ärendet utreds och prövas av Lantmäterimyndigheten.
                    Vi utreder ditt ärende mot gällande lagstiftning, gör eventuellt fältarbete och för diskussion med dig och andra sakägare.
                    De viktigaste händelserna i ärendet finns att se under <strong>Dagbok</strong>.
                </p>
            </SidePanelSection>
            <SidePanelSection heading={'Beslut'} id={'term-beslut'}>
                <p>
                    Lantmäterimyndigheten tar beslut i ärendet.
                    Alla beslut protokollförs, och sakägare och myndigheter med rätt att överklaga får en underrättelse om besluten.
                </p>
            </SidePanelSection>
            <SidePanelSection heading={'Registrering'} id={'term-registrering'}>
                <p>
                    När ingen överklagat besluten inom rätt tid börjar besluten att gälla (Så kallat vinna laga kraft).
                    Registrering av förrättningen sker i bland annat registerkartan och fastighetsregistret.
                </p>
            </SidePanelSection>
            <SidePanelSection heading={'Slutfört'} id={'term-slutfort'}>
                <p>
                    Ärendet är slutfört och förändringarna är registrerade.
                    Ärendet syns i denna tjänst i ca 30 dagar efter att förrättningen slutförts av Lantmäteriet,
                    därefter finns informationen om förrättningen tillgänglig i tjänsten&nbsp;
                    <a href={'https://www.lantmateriet.se/sv/fastighet-och-mark/information-om-fastigheter/Min-fastighet/'}
                       rel='noopener noreferrer'
                       target="_blank">Min Fastighet</a>.
                </p>
            </SidePanelSection>
    </>;

export const ArendestatusHelpContentOld =
    <>
        <SidePanelChapterHeading header={'Ärendestatus'}
                                 sectionTitle={'Förrättningsärende'}
                                 introduction={'För att beskriva var i handläggningsprocessen ett ärende befinner sig finns fem olika statusar. Mer information om repspektive status finns nedan.'}
        />
        <SidePanelSection heading={'Ansökan'} id={'term-ansokan'}>
            <p>
                Ansökan har kommit in och diarieförts hos Lantmäterimyndigheten.
                Vi kontrollerar och granskar din ansökan, och hör av oss om vi behöver ytterligare information från dig.
                Ärendet kommer att börja handläggas så snart en handläggare finns tillgänglig.
                Information om kö- och handläggningstider finns&nbsp;
                <a href={'https://www.lantmateriet.se/sv/fastigheter/Andra-fastighet/hur-lang-tid-tar-en-lantmateriforrattning/'}
                   rel='noopener noreferrer'
                   target="_blank">här</a>.
            </p>
        </SidePanelSection>
        <SidePanelSection heading={'Handläggning'} id={'term-handlaggning'}>
            <p>
                Ärendet handläggs av utsedd handläggare. Normalt förs löpande dagboksanteckningar av de viktigaste händelserna i ärendet, läs mer under <strong>Dagbok</strong>.
            </p>
        </SidePanelSection>
        <SidePanelSection heading={'Beslut'} id={'term-beslut-old'}>
            <p>
                Beslut ska tas i ärendet. Sakägare och myndigheter med rätt att överklaga får en underrättelse om besluten.
            </p>
        </SidePanelSection>
        <SidePanelSection heading={'Laga Kraft'} id={'term-laga-kraft'}>
            <p>
                Beslut i ärendet vinner laga kraft och är därefter inte överklagningsbara. Uppgifter ska inom kort registreras i fastighetsregistret och registerkartan.
            </p>
        </SidePanelSection>
        <SidePanelSection heading={'Registrerad'} id={'term-registrerad'}>
            <p>
                När beslut i ärendet vunnit laga kraft ska uppgifterna registreras i fastighetsregistret.
            </p>
        </SidePanelSection>
    </>;