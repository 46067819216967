import React, { useEffect, useState } from 'react'
import { removeInvalidCharsFromText } from '../../../helpers/textHelpers'
import { WizardIntorduction, WizardNavigationButtonToolbar } from '../../../components/wizard/WizardFormElements'
//@ts-ignore
import { ButtonTop, FormFieldset, TextInput } from 'redet-react-components'
import { betweenLength, checkForOnlySpaces } from '../../../utils/validation'

type Props = {
  setKartskissNamnCallback: (namn: string) => {},
  startUploadCallback: () => {},
  kartskissNamn: string,
  previousStep?: () => {}
}

export function NamgeKartskissBifogadKartskissWizardStep(props: Props) {
 
  const [kartskissNamnValidationError, setKartskissNamnValidationError] = useState<string | undefined>();
  
  useEffect(() => {
    if (props.kartskissNamn?.length > 0) {
      validateKartskissNamn(props.kartskissNamn)
    }
  }, [props.kartskissNamn])

  function validateKartskissNamn (name: string) {
    let isErrorMessage
    isErrorMessage = betweenLength(2, 40, name)
    if (!isErrorMessage) {
      isErrorMessage = checkForOnlySpaces(name)
    }

    if (!isErrorMessage) {
        setKartskissNamnValidationError(undefined)
      } else {
        setKartskissNamnValidationError(isErrorMessage)
      }
  }

    const laddaUppButtonActive = kartskissNamnValidationError === undefined && props.kartskissNamn !== undefined && props.kartskissNamn.length > 0
    return (
      <>
       {/* @ts-ignore */}
        <WizardIntorduction title={'Namnge kartskiss'}/>
        <div className="modal-form">
          <FormFieldset id="kartskissNamnForm">
            <TextInput isRequired={true}
                       label="Ange ett namn till din kartskiss"
                       maxLength={40}
                       validationErrorMsg={kartskissNamnValidationError}
                       id="kartskissNamn"
                       defaultValue={props.kartskissNamn}
                       placeholder={''}
                       onDragStart={() => false}
                       onDrop={() => false}
                       onClick={(evt: React.ChangeEvent<HTMLInputElement>) => {
                         validateKartskissNamn(evt?.target?.value)
                       }}
                       onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                         validateKartskissNamn(evt?.target?.value)
                         props.setKartskissNamnCallback(removeInvalidCharsFromText(evt?.target?.value))
                       }}
            />
          </FormFieldset>
        </div>
        {/* @ts-ignore */}
        <WizardNavigationButtonToolbar prevButtonText={'Välj fil'} onClickPrevButton={props.previousStep}>
          <ButtonTop text="Ladda upp befintlig kartskiss"
                     id="uploadKartskissButton"
                     isDisabled={!laddaUppButtonActive}
                     isActive={laddaUppButtonActive}
                     onClick={props.startUploadCallback}
                     icon={'check'}
          />
        </WizardNavigationButtonToolbar>
      </>
    )
}
