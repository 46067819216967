import BestyrkanWizardModal from 'components/General/BestyrkanWizardModal'
import { SIGNER_STATUS, SIGNINGINSTANCE_STATUS } from 'domain/signing/SigningStatus'
import PropTypes from 'prop-types'
import { Component } from 'react'
import {
  ButtonTop,
  CircleLoading,
  FormFieldset,
  ListGroup,
  ListItem,
  ModalDialogAlert
} from 'redet-react-components'
import { WizardNavigationButtonToolbar } from '../../../components/wizard/WizardFormElements'

export const AVBRYT_INBJUDAN_BUTTON_TEXT = 'Ändra epost'
export const PHOS_KOMPLETTERING_SKRIV_UNDER_BUTTON_TEXT = 'Bestyrk handling'

export class SigningHederOchSamveteKompletteringWizard extends Component {
  static propTypes = {
    // Props
    bifogadehandlingar: PropTypes.array.isRequired,
    startUpload: PropTypes.func.isRequired,
    abortSigning: PropTypes.func.isRequired,
    signingUrl: PropTypes.string,
    kompletteringId: PropTypes.string,
    fetchSigningStatusKomplettering: PropTypes.func.isRequired,
    submitKomplettering: PropTypes.func.isRequired,
    inskickadDatumCallback: PropTypes.func.isRequired,
    uploadFailed: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.state = {
      showLoading: false,
      isFetchKompletteringStatusPending: false,
      isSubmittingKompletteringPending: false,
      hasSigningDeclined: false,
      showSigningModal: false,
      handlingarSigneringStatus: undefined,
      handlingarStatusTimerId: undefined,
    }
    this.fetchingKomplettering = this.fetchingKomplettering.bind(this)
    this.startCheckHandlingarSigningStatusTimer = this.startCheckHandlingarSigningStatusTimer.bind(this)
    this.stopCheckHandlingarSigningStatusTimer = this.stopCheckHandlingarSigningStatusTimer.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.signingUrl !== undefined && this.props.signingUrl !== prevProps.signingUrl) {
    // flags to ensure that fetch of kompletteringStatus and submit of komplettering does not queue up when requests take a long time
    if (!this.state.isFetchKompletteringStatusPending || !this.state.isSubmittingKompletteringPending) {
      this.fetchingKomplettering()
    }
    }
  }

  componentWillUnmount () {
    this.stopCheckHandlingarSigningStatusTimer()
  }

  stopCheckHandlingarSigningStatusTimer () {
    if (this.state.handlingarStatusTimerId !== undefined) {
      clearTimeout(this.state.handlingarStatusTimerId)
      this.setState({ handlingarStatusTimerId: undefined })
    }
  }

  startCheckHandlingarSigningStatusTimer () {
    if (this.state.handlingarStatusTimerId === undefined) {
      const timerId = setInterval(() => {
        if (!this.state.isFetchKompletteringStatusPending && !this.state.isSubmittingKompletteringPending) {
          this.fetchingKomplettering()
        }
      }, 5000)
      this.setState({ handlingarStatusTimerId: timerId })
    }
  }

  fetchingKomplettering () {
    this.setState({ isFetchKompletteringStatusPending: true })
    this.props.fetchSigningStatusKomplettering(this.props.kompletteringId)
      .then((result) => {
        const signerStatus = result.signingStatus.status
        const signeringsStatus = result.signingStatus?.signerStatus ? result.signingStatus.signerStatus[0].status : undefined;
        this.setState({
          status: result.signingStatus.status,
          signerStatus: signerStatus, // array of SignerStatus (objects): status, signerEmail, signingUrl
          errorMsg: undefined,
          isFetchKompletteringStatusPending: false,
          showLoading: false
        })
        if (signerStatus === SIGNINGINSTANCE_STATUS.SUCCEEDED) {
          this.stopCheckHandlingarSigningStatusTimer()
          this.setState({ isSubmittingKompletteringPending: true })
          this.props.submitKomplettering(this.props.kompletteringId)
            .then(response => {
              this.hideSigneringModal();
              this.props.inskickadDatumCallback(response.inskickadDatum)
              this.props.nextStep()
              this.setState({ isSubmittingKompletteringPending: false })
            }).catch(() => {
            this.setState({ isSubmittingKompletteringPending: false })
            // Swallow error, this can happen if batch-job runs at the same time as submitKomplettering-request.
          })
        }
        if (signeringsStatus === SIGNER_STATUS.DECLINED || 
          signerStatus === SIGNINGINSTANCE_STATUS.TIMED_OUT || 
          signerStatus === SIGNINGINSTANCE_STATUS.NOT_STARTED) {
          this.stopCheckHandlingarSigningStatusTimer()
          this.hideSigneringModal();
          this.showDeclinedSigneringModal();
        }
        if (signerStatus === SIGNINGINSTANCE_STATUS.PENDING) {
          this.showSigneringModal();
          this.startCheckHandlingarSigningStatusTimer()
        } else {
          this.stopCheckHandlingarSigningStatusTimer()
        }
      }).catch((error) => {
      this.setState({
        errorMsg: error,
        isFetchKompletteringStatusPending: false
      })
    })
  }

  startSigning = () => {
    if (this.props.bifogadehandlingar.length !== 0) {
      this.props.startUpload().then(() => {
        this.setState({
          showLoading: true,
        })
      })
    }
  }

  abortSigning = () => {
    this.setState({
      showLoading: true,
    });
    this.props.abortSigning(this.props.kompletteringId);
  }

  hideSigneringModal = () => {
    this.setState({
      showSigningModal: false
    })
  }

  showSigneringModal = () => {
    this.setState({
      showSigningModal: true
    })
  }

  hideDeclinedSigneringModal = () => {
    this.setState({
      hasSigningDeclined: false
    })
  }

  showDeclinedSigneringModal = () => {
    this.setState({
      hasSigningDeclined: true
    })
  }

  render () {
    return (
      <>
        <FormFieldset
          title={'Bestyrk handling'}
          description={'Genom att skriva under med e-legitimation försäkrar du på heder och samvete att handlingen som du bifogat överensstämmer med originalet. När handlingen är bestyrkt kommer den att skickas in automatiskt.'}
        >
          <ListGroup id="bifogadeHandlingarTable" title="Följande filer kommer ingå i handlingen" headerLevel={4}>
            {this.props.bifogadehandlingar?.map(handling => {
              const itemId = handling.name
              return (
                <ListItem
                  key={`${itemId}Key`}
                  id={itemId}
                  header={handling.name}
                  hasTableActionButtons={false}>
                </ListItem>
              )
            })}
          </ListGroup>
        </FormFieldset>
        <WizardNavigationButtonToolbar prevButtonText={AVBRYT_INBJUDAN_BUTTON_TEXT}
                                       onClickPrevButton={this.props.previousStep}>
          <ButtonTop text={PHOS_KOMPLETTERING_SKRIV_UNDER_BUTTON_TEXT}
                     isActive={true}
                     icon="create"
                     id="intygaHederOchSamveteButton"
                     onClick={this.startSigning}/>
        </WizardNavigationButtonToolbar>

        {this.state.showLoading && !this.props.uploadFailed &&
          <CircleLoading isFullscreen={true}
                         text={'Förbereder ...'}
                         attachToDomElementWithId={'elementAttachId' in this.props ? this.props.elementAttachId : 'rootBody'}
                         isFocusTrapped={false}/>
        }
        {this.state.showSigningModal &&
          <BestyrkanWizardModal
            closeModalCallback={this.abortSigning}
            bestyrkanUrl={this.props.signingUrl}
            attachToDomElementWithId={'elementAttachId' in this.props ? this.props.elementAttachId : 'rootBody'}
          ></BestyrkanWizardModal>
        }
        {this.state.hasSigningDeclined && <ModalDialogAlert
          modalTitle={'Bestyrkandet har avbrutits'}
          ingress={'Du har avböjt signeringen.'}
          description={<span>Klicka på "Bestyrk handling" för att försöka signera igen eller stäng för att avsluta inskickande av ytterligare handling.</span>}
          acceptButton={{ text: 'Ok', icon: 'check', onClick: this.hideDeclinedSigneringModal }}
        />
        }
      </>
    )
  }
}

export default SigningHederOchSamveteKompletteringWizard