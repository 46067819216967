import {Arende, Arendetyp} from "../../../domain/Arende";
// @ts-ignore
import {Column, Row} from 'redet-react-components'
import {useEffect, useState} from "react";
import {getLocalizedDateString} from "../../../helpers/dates";
import {ARENDE_STATUS, ARENDE_STATUS_TYPES, ArendestatusType} from "../../../utils/globalConstants";

type Props = {
    arendeOversikt: Arende
}

export function ArendeBasInfoView({arendeOversikt}: Props) {

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        setStartDate(getDateString(arendeOversikt.startDate))
        setEndDate(getDateString(arendeOversikt.endDate))

        let statusType = ARENDE_STATUS_TYPES.find(type => type.value === arendeOversikt.status)
        if(isSERSAM(arendeOversikt) && statusType){
            statusType = convertToSERSAMStatus(statusType)
        }
        setStatus(statusType ? statusType.description : "")
    }, [])

    const isSERSAM = (arende: Arende) => arende.arendetyp === Arendetyp.SERSAM

    function convertToSERSAMStatus(status: ArendestatusType){
        if (status.value === ARENDE_STATUS.SLUTFORT) {
            return ARENDE_STATUS_TYPES.find(type => type.value === ARENDE_STATUS.SLUTFORT)
        }
        return ARENDE_STATUS_TYPES.find(type => type.value === ARENDE_STATUS.PAGAENDE)
    }

    function getDateString(date?: string): string {
        if (date)
            return getLocalizedDateString(date, 'yyyy-MM-dd') || 'Saknas'
        return 'Saknas'
    }

    return (<Row>
        <Column columns="col-6 col-sm-4 col-md-2">
            <div className="form-group">
                <label htmlFor={'basinfo-beskrivning'} className="label">Beskrivning</label>
                <p id={'basinfo-beskrivning'} className="preview-message">{arendeOversikt.beskrivning}</p>
            </div>
        </Column>
        <Column columns="col-6 col-sm-4 col-md-2">
            <div className="form-group">
                <label htmlFor={'basinfo-ankomstdatum'} className="label preview-message">Ankomstdatum</label>
                <p id={'basinfo-ankomstdatum'} data-testid={'arende-bas-info-startdate'}>{startDate}</p>
            </div>
        </Column>
        <Column columns="col-6 col-sm-4 col-md-2">
            <div className="form-group">
                <label htmlFor={'basinfo-slutdatum'} className="label">Ber. slutdatum</label>
                <p id={'basinfo-slutdatum'} data-testid={'arende-bas-info-enddate'}>{endDate}</p>
            </div>
        </Column>
        <Column columns="col-6 col-sm-4 col-md-2">
            <div className="form-group">
                <label htmlFor={'basinfo-status'} className="label">Status</label>
                <p id={'basinfo-status'} data-testid={'arende-bas-info-status'}>{status}</p>
            </div>
        </Column>
        <Column columns="col-6 col-sm-4 col-md-2">
            <div className="form-group">
                <label htmlFor={'basinfo-lan'} className="label">Län</label>
                <p id={'basinfo-lan'}>{arendeOversikt.lan}</p>
            </div>
        </Column>
        <Column columns="col-6 col-sm-4 col-md-2">
            <div className="form-group">
                <label htmlFor={'basinfo-arendeTyp'} className="label">Ärendetyp</label>
                <p id={'basinfo-arendeTyp'} data-testid={'arende-bas-info-arendeTyp'}>{arendeOversikt.arendetyp === "F" && "Lantmäteriförättning"}{arendeOversikt.arendetyp === "SERSAM" && "Bildande av samfällighetsförening"}</p>
            </div>
        </Column>
    </Row>)
}