export const formProperties = {
  avstyckning: { name: 'avstyckning', save: true, displayName: 'Avstyckning' },
  avstyckning3d: { name: 'avstyckning3d', save: true, displayName: 'Avstyckning 3D' },
  bifogadehandlingar: { name: 'bifogadehandlingar', save: true },
  bifogadHandlingUploadForm: { name: 'bifogadHandlingUploadForm', save: true },
  editEmail: { name: 'editEmail', save: false },
  fastighetsforandring: { name: 'fastighetsforandring', save: true },
  fastighetsreglering: { name: 'fastighetsreglering', save: true, displayName: 'Fastighetsreglering' },
  fastighetsregleringSamfalligheter: {
    name: 'fastighetsregleringSamfalligheter',
    save: true,
    displayName: 'Fastighetsreglering samfälligheter'
  },
  individualContactBeingEdited: { name: 'individualContactBeingEdited', save: false },
  inviteToSign: { name: 'inviteToSign', save: false },
  klyvning: { name: 'klyvning', save: true, displayName: 'Klyvning' },
  organizationContactBeingEdited: { name: 'organizationContactBeingEdited', save: false },
  ovrigBeskrivning: { name: 'ovrigBeskrivning', save: true },
  sammanlaggning: { name: 'sammanlaggning', save: true, displayName: 'Sammanläggning' },
  arendeListFilter: { name: 'arendeListFilter', save: false },
  kompletteringBifogadHandlingUploadForm: { name: 'kompletteringBifogadHandlingUploadForm', save: true },
  data: { name: 'data', save: false },
  gransutmarkning: {name: 'gransutmarkning', save: true, displayName:'Särskild Gränsutmärkning'},
  fastighetsbestamning: {name: 'fastighetsbestamning', save: true, displayName: 'Fastighetsbestämning'}
}

// TODO! data är tillagt tillsvidare, utvärdera om den måste ligga kvar här då det kan vara förvirrande
