import Log from './log'

const log = new Log('LocalStorageUtils')


/**
 * Function that determines if localStorage or sessionStorage is available
 *
 * - localStorage - The localStorage read-only property of the window interface allows you to access a Storage object for the Document's origin;
 * the stored data is saved across browser sessions.
 *
 * - sessionStorage - The read-only sessionStorage property accesses a session Storage object for the current origin. sessionStorage is similar to localStorage;
 * the difference is that while data in localStorage doesn't expire, data in sessionStorage is cleared when the page session ends.
 *
 * Use of this function: storageAvailable('localStorage ? interactWithLocalStorage(funTimes) : doNotInteractWithLocalStorage(capish!?)
 *
 * source: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 *
 * @param type of storage e.g. localStorage or sessionStorage
 * @returns {boolean}
 */
export function storageAvailable (type: string): any {
  let storage
  try {
    // @ts-ignore
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (err) {
    log.error('Browser storage is not available', type, err)
    return err instanceof DOMException && (
        // everything except Firefox
        err.code === 22 ||
        // Firefox
        err.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        err.name === 'QuotaExceededError' ||
        // Firefox
        err.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0)
  }
}

/**
 *
 * @param key
 * @returns {string|null}
 */
export function getValueFromLocalStorage (key: string): string | null {
  if (storageAvailable('localStorage')) {
    return window.localStorage.getItem(key)
  }
  return null
}

/**
 *
 * @param key
 * @param value
 * @returns {true|false}
 */
export function setValueInLocalStorage (key: string, value: string): boolean {
  if (storageAvailable('localStorage')) {
    window.localStorage.setItem(key, value)
    return true
  }
  return false
}

export function removeValueFromLocalStorage (key: string): void {
  if (storageAvailable('localStorage')) {
    return window.localStorage.removeItem(key)
  }
}