import { TextInputFinalForm } from '../../General/forms/finalForm'
//@ts-ignore
import { Column, FormFieldset, Row } from 'redet-react-components'
import { NameForm } from './NameForm'
import { ContactOmbudSelection } from './ContactOmbudSelection'
import { Contact } from 'domain/Contact'

export default function OmbudForm (props:{ contact: Contact, contactList: Contact[], formAutocomplete: string, warnings: [] }) {
  const columnSetting = 'col-xs-12 col-sm-6'
  return (
    <FormFieldset title="Namn och Personnummer">
      <Row>
        <NameForm formAutocomplete={props.formAutocomplete}/>
      </Row>
      <Row>
        <Column columns={columnSetting}>
          {/* @ts-ignore */}
          <TextInputFinalForm field={'personnummer'} label={'Personnummer'} placeholder={'ÅÅÅÅMMDDXXXX'}
                              required={true}
                              maxNumberValue={999999999999}
                              minNumberValue={0}/>
        </Column>
      </Row>
      <br/>
      <ContactOmbudSelection contactList={props.contactList}
                             contact={props.contact} warnings={props.warnings}/>
    </FormFieldset>
  )
}