// @flow
import Log from '../utils/log'
import {AjaxClientError} from '../utils/errorHandler'
import {formProperties} from '../redux/formProperties'
import {fromUnixTime} from 'date-fns'
import {ServerValidationError} from '../redux/modules/validation/rules/model/ServerValidationError'
import {AnsokanListItemType} from 'domain/AnsokanListItemType'
import { Fetcher } from './fetcher'
import { Ansokan, AnsokanStatus } from 'domain/Ansokan'
import { RootState } from 'store'
import { validate as uuidValidate } from 'uuid';
import {isNonEmptyString} from "../utils/stringUtil";

const repositoryUrl = '/api/ansokan-service/ansokan'
const log = new Log('ansokanRepository')
export const currentSchemaVersion = 4
const KEY_ARGUMENT_MISSING_MSG = 'The key argument is missing'
const ANSOKAN_STORED_SUCCESS_MSG = 'Ansokan successfully updated on server'
const ANSOKAN_STORED_ERROR_MSG = 'Error encountered while storing ansokan to server'

const APPLICATION_JSON = 'application/json'

export class AnsokanNotFoundException {
    errorMessage: string

    constructor(errorMessage: string = '') {
        this.errorMessage = errorMessage
    }
}

export class AnsokanCouldNotBeOpenedException {
    errorMessage: string
    error: Object
    errorType: string = 'BaseClientError'
    currentBrowsingUrl: string = window.location.href

    constructor(errorMessage: string = '', error: Object = {}) {
        this.errorMessage = errorMessage
        this.error = error
    }
}

export type AnsokanSubmitResponse = {
    kvittensId: number
    mottagandeMyndighet: string
    fastighetsbeteckningar: string[]
}

export type GetAnsokanResponse = {
    data: Ansokan
    validationMessages: ServerValidationError[]
}

type SaveAnsokanResponse = {
    remoteDataKey: string
    validationMessages: ServerValidationError[]
    ansokan: Ansokan
}

// Denna funktion skall tas bort då vi inte längre använder Redux form
export function cleanReduxForm(forms: Object) {
    const newFormsObject = {}

    for (const formName of Object.keys(forms)) {
        const newFormObject = {}
        // @ts-ignore
        if (forms[formName] === null || forms[formName] === undefined) {
            continue
        }
        // @ts-ignore
        for (const formPropName of Object.keys(forms[formName])) {
            // Dont add redux form internal props
            if (!formPropName.startsWith('_')) {
                // Only take the value prop
                // @ts-ignore
                newFormObject[formPropName] = forms[formName][formPropName]
            }
        }
        // @ts-ignore
        newFormsObject[formName] = newFormObject
    }
    return newFormsObject
}

// Filters the redux forms so that only those configured to be automatically saved are present
export function filterForms(forms: Object) {
    const filteredForms = {}
    const keys = Object.keys(forms)
    // @ts-ignore
    const filteredKeys = keys.filter(key => formProperties[key].save === true)
    // @ts-ignore
    filteredKeys.forEach(key => filteredForms[key] = forms[key])
    return filteredForms
}

function isAnsokanId(ansokanTabPath: string) {
    return uuidValidate(ansokanTabPath);
}

function extractPathname(url: string) {
    const urlSplitted = url.split('/').filter(value => isNonEmptyString(value))
    if (urlSplitted.length > 3 && urlSplitted[0] === "privat" && urlSplitted[1] === "ansokan" && isAnsokanId(urlSplitted[2]))
        return  urlSplitted[3]
}

function mapAndSerializeState(state: RootState) {
    let kartskissUtanFastighetFeatures = {
        ...state.map,
    };
    kartskissUtanFastighetFeatures.fastighetFeatures = {};
    kartskissUtanFastighetFeatures.fastighetFeaturesRaw = {};
    const dataToSave = {
        data: {
            currentSchemaVersion,
            // Add all redux forms that are flagged to be saved
            ...cleanReduxForm(filterForms(state.form)),
            // Add contacts list
            contactList: state.contacts.contactList,
            // Add current working state for the map
            map: kartskissUtanFastighetFeatures,
            // Add fastigheter selection
            fastigheterList: state.fastigheter.fastigheterList,
            ickeAgdaFastigheter: state.fastigheter.ickeAgdaFastigheter,
            firstStepFinished: state.fastigheter.firstStepFinished,
            wizardState: extractPathname(document.location.pathname),
            hasUserChosenNotToUploadBifogadHandlig: state.bifogadHandling.hasUserChosenNotToUploadBifogadHandlig,
            userHasConfirmedAmountOfSokandeIsNotCorrect: state.contacts.userHasConfirmedAmountOfSokandeIsNotCorrect,
            numberOfBifogadeHandlingar: state.bifogadHandling.numberOfBifogadeHandlingar,
            //@ts-ignore //kräver typning av kartskiss reducer
            hasUserChosenNotToAddAkartskiss: state.kartskiss.hasUserChosenNotToAddAkartskiss,
            //@ts-ignore //kräver typning av kartskiss reducer
            numberOfRitadeKartor: state.kartskiss.numberOfRitadeKartor,
            numberOfUppladdadeKartor: state.bifogadKartskiss.numberOfUppladdadeKartor
        }
    }
    return JSON.stringify(dataToSave)
}

export function save(fetcher: Fetcher, state: RootState) {
    function assignToClass(validationMessages: Array<Object>): Array<ServerValidationError> {
        if (!validationMessages) {
            return []
        }
        return validationMessages.map(message => Object.assign(new ServerValidationError(), message))
    }

    // Check if this is an initial save or if we are updating an existing record
    if (state.ansokanRepository?.remoteDataKey) {
        //Fix för att ansokanReducers inte är typade än
        const remoteDataKey = state.ansokanRepository?.remoteDataKey as string;
        return fetcher.fetching(repositoryUrl + '/' + remoteDataKey,
            {
                cache: 'no-store',
                method: 'PUT',
                body: mapAndSerializeState(state),
            },
            {'Content-Type': APPLICATION_JSON})
            .then(response => {
                log.debug(ANSOKAN_STORED_SUCCESS_MSG)
                return response.json()
            }).then((response: GetAnsokanResponse) => {
                return {
                    remoteDataKey: remoteDataKey,
                    validationMessages: assignToClass(response.validationMessages)
                } as SaveAnsokanResponse
            })
            .catch((error: Error) => {
                log.error(ANSOKAN_STORED_ERROR_MSG)
                throw error
            })
    } else {
        log.error(ANSOKAN_STORED_ERROR_MSG, 'no id exists')
        return Promise.reject();
    }
}

export function createNewAnsokan(fetcher: Fetcher, state: RootState) {
    return fetcher.fetching(repositoryUrl,
        {
            cache: 'no-store',
            method: 'POST',
            body: mapAndSerializeState(state),
        },
        {'Content-Type': APPLICATION_JSON}
    ).then(response => {
        return response.json()
    }).then((ansokan: Ansokan) => {
        log.debug(ANSOKAN_STORED_SUCCESS_MSG)
        return ansokan._id
    }).catch((error: Error) => {
        log.error(ANSOKAN_STORED_ERROR_MSG)
        throw error
    })
}

export function load(fetcher: Fetcher, remoteDataKey: string) {
    if (!remoteDataKey) {
        log.error('Load operation aborted: ' + KEY_ARGUMENT_MISSING_MSG)
        throw new Error(KEY_ARGUMENT_MISSING_MSG)
    }
    const url = repositoryUrl + '/' + remoteDataKey

    return fetcher.fetching(url, {cache: 'no-store'})
        .then(response => {
            return response.json()
        })
        .then((ansokan: GetAnsokanResponse) => {
            log.debug(ANSOKAN_STORED_SUCCESS_MSG)
            return ansokan
        }).catch((err: any) => {
            // As we want custom handling of errors in this query we're disabling the automatic global
            // ajax error handling with the query option 'global: false' and need to handle it all ourselves.
            const error = new AjaxClientError(url, err.status, err, err.statusText)
            if (err.status === 404) {
                throw new AnsokanNotFoundException('Ansökan not found or access denied')
            } else {
                throw (error)
            }
        })
}

export function loadList(fetcher: Fetcher) {
    return fetcher.fetching(repositoryUrl, {cache: 'no-store'})
        .then(response => {
            return response.json()
        })
        .then((storedRecords: Ansokan[]) => {
            log.info('Ansokan list successfully loaded from server')
            return storedRecords.map((record: any) => {
                return {
                    ansokanId: record._id,
                    createdDate: fromUnixTime(record._skapad),
                    modifiedDate: record._andrad ? fromUnixTime(record._andrad) : null,
                    ansokanStatus: record._status,
                    ansokanStatusModified: record._statusAndrad ? fromUnixTime(record._statusAndrad) : null,
                } as AnsokanListItemType
            })

        }).catch((err: any) => {
            log.error('An error has occurred while loading ansokan list from server with error code: ', err.status)
            throw err
        })
}

export function remove(fetcher: Fetcher, key: string) {
    // Old args ajaxDriver, key
    if (!key) {
        throw new Error(KEY_ARGUMENT_MISSING_MSG)
    }

    return fetcher.fetching(repositoryUrl + '/' + key, {cache: 'no-store', method: 'DELETE'})
        .then(response => {
            response.text()
        }).then(() => {
            log.info('Ansokan successfully deleted on server')
            return true
        }).catch((error: Error) => {
            log.error('Error encountered while deleting ansokan on server')
            throw error
        })
}

export function lock(fetcher: Fetcher, key: string) {
    // Old args ajaxDriver, key
    if (!key) {
        throw new Error(KEY_ARGUMENT_MISSING_MSG)
    }

    const url = `${repositoryUrl}/lock/${key}`

    return fetcher.fetching(url, {cache: 'no-store', method: 'POST'})
        .then(response=> {
            response.text()
        }).then(() => {
            log.debug(ANSOKAN_STORED_SUCCESS_MSG)
            return true
        }).catch((error: Error) => {
            log.error('Error encountered while updating ansokan on server')
            throw error
        })
}

export function unlock(fetcher: Fetcher, key: string) {
    // Old args ajaxDriver, key
    if (!key) {
        throw new Error(KEY_ARGUMENT_MISSING_MSG)
    }

    const url = `${repositoryUrl}/unlock/${key}`

    return fetcher.fetching(url, {cache: 'no-store', method: 'POST'})
        .then(response=> {
            response.text()
        }).then(() => {
            log.debug(ANSOKAN_STORED_SUCCESS_MSG)
            return true
        }).catch((error: Error) => {
            log.error('Error encountered while updating ansokan on server')
            throw error
        })
}

export function status(fetcher: Fetcher, key: string, newStatus: Object) {
    // Old args ajaxDriver, key
    if (!key) {
        throw new Error(KEY_ARGUMENT_MISSING_MSG)
    }

    const url = `${repositoryUrl}/${key}/status`

    return fetcher.fetching(url, {
        cache: 'no-store',
        method: 'POST',
        body: JSON.stringify(newStatus)
    }, {'Content-Type': APPLICATION_JSON})
        .then(response => {
            return response.text()
        }).then((text) => {
            log.debug(ANSOKAN_STORED_SUCCESS_MSG)
            return text as AnsokanStatus
        }).catch((error: Error) => {
            log.error('Error encountered while updating ansokan on server')
            throw error
        })
}



export function submit(fetcher: Fetcher, key: string, mockIntegrations: boolean) {
    // Old args ajaxDriver, key, mockIntegration
    if (!key) {
        throw new Error(KEY_ARGUMENT_MISSING_MSG)
    }

    const url = `${repositoryUrl}/skickain/${key}`
    const mockIntegrationsHeader = mockIntegrations ? {'enak-mock-integrations': 'true'} : null
    return fetcher.fetching(url, {
        cache: 'no-store',
        method: 'POST',
    }, mockIntegrationsHeader)
        .then((response) => {
            return response.json()
        }).then((response: AnsokanSubmitResponse) => {
            log.debug(ANSOKAN_STORED_SUCCESS_MSG)
            return response;
        }).catch((error: Error) => {
            log.error('Error encountered while updating ansokan on server')
            throw error
        })
}

export function fetchStatus(fetcher: Fetcher, key: string) {
    // Old args ajaxDriver and key
    const url = `${repositoryUrl}/${key}/status`

    return fetcher.fetching(url, {cache: 'no-store'})
        .then((response: any) => {
            return response.json()
        }).then((ansokanStatus: AnsokanStatus) => {
            log.debug('Status for ansokan successfully loaded from server')
            return ansokanStatus
        }).catch((err: any) => {
            const error = new AjaxClientError(url, err.status, err, err.statusText)
            if (err.status === 404 || err.status === 400) {
                throw new AnsokanNotFoundException('Ansökan not found or access denied')
            } else {
                throw (new AnsokanCouldNotBeOpenedException('Ansökan kunde inte öppnas', error))
            }
        })
}
