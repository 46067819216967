import { useEffect, useState } from 'react'
import { Navigation } from '../components'
import { getLoginUrlOrganisation } from '../helpers/loginUrl'
import { setCompact, setFullHeight, visaMoment } from '../redux/modules/help'
//@ts-ignore
import { Helmet } from 'react-helmet'
import { JumpLinkToMain } from '../components/General/JumpLinkToMain'
//@ts-ignore
import { ButtonToolbar, Column, ExternalLink, LinkButtonTop, PageTopContent, Row, SidePanelChapter } from 'redet-react-components'
import { PageHeader } from '../components/General/forms/layoutElements'
import Hjalp from '../views/Hjalp/Hjalp'
import { LoginHelpContentOrganisation } from '../views/Hjalp/texter/login/LoginHelpContentOrganisation'
import { KontaktaOssHelpContent } from '../views/Hjalp/texter/KontaktaOssHelpContent'
import { PersonuppgifterHelpContent } from '../views/Hjalp/texter/PersonuppgifterHelpContent'
import { IE11modalDialogWarning } from './Ansokan'
import i18next from 'i18next'
import { useAppDispatch } from 'hooks'

export default function StartInloggningOrganisation() {
  
  const dispatch = useAppDispatch()
  const [urlOrgPerson] = useState(getLoginUrlOrganisation());

  useEffect(() => {
    dispatch(setCompact())
    dispatch(visaMoment('help-login-organisation', undefined, false))
    return () => {
      dispatch(setFullHeight())
    }
  }, [])

  function getTjanstebeskrivning() {
    return <>
      <p className={'introduction'}>
        I den här tjänsten får du hjälp med att följa ett pågående lantmäteriförrättningsärende.
      </p>
    </>
  }

    i18next.changeLanguage('sv')
    const contentList = [{ key: 'login', moment: 'help-login-organisation', text: 'Hur loggar jag in?' },]
    
    return (
      <div>
        <JumpLinkToMain/>
        <Helmet>
          <title>Inloggning lantmäteriförrättning</title>
        </Helmet>
        <IE11modalDialogWarning/>
        <header>
          <Navigation contentList={contentList}/>
          <PageHeader introduction={getTjanstebeskrivning()}>
            <ButtonToolbar>
              <a className="button-banner" href={urlOrgPerson}><i
                className="material-icons">login</i>Logga in som organisation</a>
            </ButtonToolbar>
          </PageHeader>
        </header>
        <main id="startPageMainContentId">
          <div className="container">
            <Row>
              <Column columns="col-xs-12 col-md-12 col-lg-6 offset-lg-3">
                <PageTopContent headerId={'innanStartHeaderId'} headerLevel={2} title={'Logga in'}
                                description={<>Följ ett inskickat ärende eller se ärenden som du i övrigt är berörd
                                  av.</>}>
                  <p><ExternalLink text={'Läs mer om att logga in med lösenord'}
                                   url={'https://www.lantmateriet.se/sv/Fastigheter/Andra-fastighet/ansok-folj-eller-komplettera-ett-arende/ansok-om-inloggning-och-administratorsrattigheter/'}/>
                  </p>
                  <ButtonToolbar>
                    <div className="button-group">
                      <LinkButtonTop text={'Logga in'}
                                     link={urlOrgPerson}
                                     icon={'login'}
                                     isActive={true}
                                     id="loginButtonOrg"/>
                    </div>
                  </ButtonToolbar>
                </PageTopContent>
              </Column>
            </Row>
          </div>
        </main>
        <Hjalp>
          <SidePanelChapter name={'help-login-organisation'}>
            <LoginHelpContentOrganisation/>
          </SidePanelChapter>
          <SidePanelChapter name={'help-support'}>
            <KontaktaOssHelpContent/>
          </SidePanelChapter>
          <SidePanelChapter name={'help-personuppgift'}>
            <PersonuppgifterHelpContent/>
          </SidePanelChapter>
        </Hjalp>
      </div>
    )
  }
