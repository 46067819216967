import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  ButtonGroup,
  ButtonSub,
  ButtonToolbarV2,
  IconButton,
  IconButtonWithPopover,
  TextInput
} from 'redet-react-components'
import { betweenLength, checkForOnlySpaces } from '../../utils/validation'

export const slutförKartaKnapptext = 'Slutför'
export const slutförKartaKnappikon = 'check'
export const avbrytBeskärningAvKartskissKnapptext = 'Avbryt'
export const avbrytBeskärningAvKartskissKnappikon = 'clear'

export default class MapPanel extends Component {
  static propTypes = {
    saveAndExit: PropTypes.func.isRequired,
    exitCropMode: PropTypes.func.isRequired,
    mapTitle: PropTypes.string.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
      minimize: false,
      kartskissNamnValidationError: undefined,
      filename: this.props.mapTitle,
    }
    this.validateKartskissNamn = this.validateKartskissNamn.bind(this)
  }

  componentDidMount () {
    if (this.props.mapTitle?.length > 0) {
      this.validateKartskissNamn(this.props.mapTitle)
    }
  }

  validateKartskissNamn (name) {
    let isErrorMessage
    isErrorMessage = betweenLength(2, 40, name)
    if (!isErrorMessage) {
      isErrorMessage = checkForOnlySpaces(name)
    }

    if (!isErrorMessage) {
      this.setState({
        kartskissNamnValidationError: undefined,
        filename: name
      })
    } else {
      this.setState({
        kartskissNamnValidationError: isErrorMessage,
        filename: name
      })
    }
  }

  toggleMinimize () {
    this.setState((prevState) => ({ minimize: !prevState.minimize }))
  }

  render () {
    return (
      <div className="map-windows left-aligned">
        <div className="map-window">
          <div className="toolbar map-window-heading-toolbar">
            <div className="button-group">
              <IconButton
                ariaLabel={'Minimera panel'}
                icon={this.state.minimize ? 'unfold_less' : 'unfold_more'}
                isActive={this.state.minimize}
                onClick={() => this.toggleMinimize()}/>
              <IconButton
                ariaLabel={'Stäng panel'}
                icon={'clear'}
                onClick={this.props.exitCropMode}/>
            </div>
          </div>
          <h2 className="map-window-heading">Slutför kartskiss</h2>
          {!this.state.minimize && <>
            <ol>
              <li><p><strong>Zooma</strong>
                <IconButtonWithPopover
                  id="textMedPopover"
                  ariaLabel={'Så fungerar zoom'}
                  icon="info"
                  className="button-inline attention"
                  popoverText={'Det går att zooma i kartan på tre olika sätt: Du kan zooma in och ut genom att använda funktionen i kartan ' +
                    'uppe till vänster i bild. + zoomar in och – zoomar ut. Du kan även använda zoomreglaget under +/- knapparna. Du kan zooma ' +
                    'in i kartan genom att dubbelklicka direkt i kartan. Scrollhjulet på musen går att använda för att zooma in och ut i kartan.'}
                  popoverPlacement="bottom"
                /> och <strong>panorera</strong>
                <IconButtonWithPopover
                  id="merTextMedPopover"
                  ariaLabel={'Så fungerar panorering'}
                  icon="info"
                  className="button-inline attention"
                  popoverText={'Du kan flytta dig runt i kartan genom att använda Panorera-handen.'}
                  popoverPlacement="right"
                /> så kartskissen blir så tydlig som möjligt. Det mörka området kommer att tas bort i ansökan.</p>
              </li>
              <li>
                <TextInput
                  id="kartskissName"
                  label="Ange ett namn eller en beskrivning av kartskissen"
                  maxLength={100}
                  value={this.state.filename} data-selenium-id="mapTitle"
                  validationErrorMsg={this.state.kartskissNamnValidationError}
                  onClick={(evt) => {
                    this.validateKartskissNamn(evt.target.value)
                  }}
                  onChange={(evt) => {
                    this.validateKartskissNamn(evt.target.value)
                  }}/>
              </li>
            </ol>
          </>}
          <ButtonToolbarV2>
            <ButtonGroup>
              <ButtonSub
                text={slutförKartaKnapptext}
                isActive={this.state.kartskissNamnValidationError === undefined}
                isDisabled={this.state.kartskissNamnValidationError !== undefined}
                onClick={() => this.props.saveAndExit(this.state.filename)}
                id="doneButton"
                data-selenium-id="mapPanelAccept"
                icon={slutförKartaKnappikon}/>
              <ButtonSub
                text={avbrytBeskärningAvKartskissKnapptext}
                onClick={this.props.exitCropMode}
                id="cancelButton"
                icon={avbrytBeskärningAvKartskissKnappikon}/>
            </ButtonGroup>
          </ButtonToolbarV2>
        </div>
      </div>
    )
  }
}
