import {useEffect} from 'react'
import {applyErrorHandlers} from 'utils/errorHandler'
import OverlayComponents from 'pages/OverlayComponents'
import TopLevelErrorBoundary from 'pages/errorBoundaries/TopLevelErrorBoundary'
import {Footer} from '../components/General/Footer'
import {useAppDispatch, useAppSelector} from 'hooks'
import {RootState} from 'store'
import {Outlet, useLocation} from "react-router-dom";

const setSeasonalBackground = () => {
  document.body.classList.add(getSeasonsBackgroundClass())
}

const getSeasonsBackgroundClass = () =>  {
  const date = new Date()
  const monthNumber = date.getMonth()
  if (monthNumber >= 2 && monthNumber <= 4) {
    return 'spring'
  } else if (monthNumber >= 5 && monthNumber <= 7) {
    return 'summer'
  } else if (monthNumber >= 8 && monthNumber <= 10) {
    return 'autumn'
  } else if (monthNumber >= 11 || monthNumber <= 1) {
    return 'winter'
  }
  return "";
}


export function Enak() {

  const logs = useAppSelector((store: RootState) => store.log.logs);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    setSeasonalBackground()
    applyErrorHandlers(dispatch, logs)
  }, [logs, dispatch])

  const isEditingMap = () => {
    return location.pathname.includes("skapakartskiss")
  }


  return (
      <TopLevelErrorBoundary>
        <OverlayComponents/>
          <Outlet/>
      {!isEditingMap()  && <Footer/>}
      </TopLevelErrorBoundary>
  )
}

// Behövs troligen inte då dispatch bli inskickad automagiskt om det inte är några actions.
// https://react-redux.js.org/using-react-redux/connect-mapdispatch#default-dispatch-as-a-prop
// const mapDispatchToProps = {dispatch, ...bindActionCreators({}, dispatch)}
// withRouter slänger in history som en prop. https://reacttraining.com/react-router/web/api/withRouter
export default Enak;