import Log from 'utils/log';

const repositoryBaseUrl = '/api/ansokan-service';
const log = new Log('kartskissRepository');

export function loadList(fetcher, ansokanId) {
    const url = repositoryBaseUrl + '/ansokan/' + ansokanId + '/kartskiss';
    return fetcher.fetching(url, {cache: "no-store"})
        .then(function (response) {
            return response.json();
        })
        .then(kartskisser => {
            log.debug('Kartskisser successfully loaded from server');
            return kartskisser.map(karta => {
                return {id: karta._id, ...karta.data};
            });
        }).catch(err => {
            log.error('Error encountered while loading list of kartskisser from server');
            throw err;
        });
}


export function upload (ajaxDriver, ansokanId, kartskissId, kartskiss) {
  return new Promise((resolve, reject) => {
    ajaxDriver({
      method: 'POST',
      url: repositoryBaseUrl + '/ansokan/' + ansokanId + '/kartskiss',
      contentType: 'application/json',
      cache: false,
      data: JSON.stringify({
        _id: kartskissId,
        data: kartskiss
      }),
    }).done(() => {
      log.debug('Kartskiss successfully uploaded to server')
      resolve()
    }).fail((response) => {
      // Global ajax error handler will run automatically and display an error message
      log.error('Error encountered while uploading kartskiss to server')
      reject(response)
    })
  })
}

export function remove(ajaxDriver, ansokanId, id) {
    if (!id) {
        throw new Error('The id argument is missing');
    }
    return new Promise((resolve, reject) => {
        ajaxDriver({
            url: repositoryBaseUrl + '/ansokan/' + ansokanId + '/kartskiss/' + id,
            cache: false,
            method: 'DELETE'
        }).done(() => {
            log.info('Kartskiss successfully deleted on server');
            resolve();
        }).fail((response) => {
            // Global ajax errro handler will run automatically and display an error message
            log.error('Error encountered while deleting kartskiss on server');
            reject(response);
        });
    });
}
