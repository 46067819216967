import {Skrivelse, SkrivelseKvittensResponse, SkrivelseRender, SkrivelseRequest} from "./model/skrivelse";
import {Epost, Telefonnummer} from "../../domain/Common";
import {submitSkrivelse} from "./skrivelseReducer";


function createSkrivelseInternal(skrivelseRender: SkrivelseRender) : Skrivelse {
        return new Skrivelse(
            skrivelseRender.arendenummer,
            skrivelseRender.meddelande,
            skrivelseRender.avsandare,
            new Epost(skrivelseRender.epostKvittens),
            skrivelseRender.epostKontakt ? new Epost(skrivelseRender.epostKontakt) : undefined,
            skrivelseRender.telefon ? new Telefonnummer(skrivelseRender.telefon) : undefined,
        );
}

function createSkrivelseRequest(skrivelse: Skrivelse) : SkrivelseRequest {
        return new SkrivelseRequest(skrivelse);
}

export function submitSkrivelseToMyndighet(skrivelseRender: SkrivelseRender, dispatch: any) : any {
        const skrivelse = createSkrivelseInternal(skrivelseRender);
        const skrivelseRequest = createSkrivelseRequest(skrivelse);
        return dispatch(submitSkrivelse(skrivelseRequest)).then((skrivelseKvittens : SkrivelseKvittensResponse) => skrivelseKvittens).catch((err: any) => {
                throw err;
        });
}





