import { useState } from 'react'
//@ts-ignore
import { FormFieldset, RadioButton, WizardNavigationButtonToolbar } from 'redet-react-components'

enum Yrkare {
  FLERA = "flera",
  JAG = "jag"
}

enum BifogaKartskiss {
  JA = "ja",
  NEJ = "nej"
}
type Choices = {
  isMultiplePeopleYrkare: boolean,
  isUploadingAkartskiss: boolean
}
type Props = {
  setChoices: (choices: Choices) => void,
  choices: Choices,
  //Injectas med clone element från WizardDialogStep :sick!
  nextStep?: () => void
  previousStep?: () => void
}

export function YrkandeWizardStepIntroFragor (props: Props) {
  const [inputValues, setInputValues] = useState({
    vilka: props.choices?.isMultiplePeopleYrkare ? Yrkare.FLERA : Yrkare.JAG,
    bifogaKartskiss: props.choices?.isUploadingAkartskiss ? BifogaKartskiss.JA : BifogaKartskiss.NEJ
  })

  const setInputValue = (value: {vilka?: Yrkare, bifogaKartskiss?: BifogaKartskiss}) => {
    return () => {setInputValues({ ...inputValues, ...value })}
  }

  return (
    <>
      <FormFieldset title={'Information om yrkandet'}>
        <div className="form-group">
          <label className="label">Vem ska skriva under detta yrkande?</label>

          <RadioButton label={'Endast jag'} value={'jag'}
                       checked={inputValues.vilka === Yrkare.JAG}
                       onChange={setInputValue({ vilka: Yrkare.JAG })}
                       id={'skrivaUnderFragaJagId'}/>
          <RadioButton label={'Jag tillsammans med andra'} value={'flera'}
                       checked={inputValues.vilka === Yrkare.FLERA}
                       onChange={setInputValue({ vilka: Yrkare.FLERA })}
                       id={'skrivaUnderFragaFleraId'}/>
        </div>
        <div className="form-group">
          <label className="label">Behöver du bifoga en kartskiss med detta yrkande?</label>
          <RadioButton label={'Nej'}
                       value={'nej'}
                       checked={inputValues.bifogaKartskiss === BifogaKartskiss.NEJ}
                       onChange={setInputValue({ bifogaKartskiss: BifogaKartskiss.NEJ })}
                       id={'bifogaKartskissNejFragaId'}/>
          <RadioButton label={'Ja'}
                       value={'ja'}
                       checked={inputValues.bifogaKartskiss === BifogaKartskiss.JA}
                       onChange={setInputValue({ bifogaKartskiss: BifogaKartskiss.JA })}
                       id={'bifogaKartskissJaFragaId'}/>
        </div>
      </FormFieldset>
      <WizardNavigationButtonToolbar nextButtonText={'Skriv yrkande'}
                                     onClickNextButton={() => {
                                      props.setChoices({
                                         isMultiplePeopleYrkare: inputValues.vilka === Yrkare.FLERA,
                                         isUploadingAkartskiss: inputValues.bifogaKartskiss === BifogaKartskiss.JA
                                       })
                                       if(props.nextStep) {props.nextStep()}
                                     }}/>
    </>)
}
