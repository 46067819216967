import React, {ChangeEvent, useEffect, useState} from 'react'
//@ts-ignore
import {WizardNavigationButtonToolbar} from '../../../components/wizard/WizardFormElements'
//@ts-ignore
import {Personnummer} from '../../../components/General/forms/finalForm'
import {Term} from 'views'
import {
    ListGroup,
    //@ts-ignore
    ButtonSub, ButtonToolbar, ButtonTop, Column, ListItem, ListItemActionButton, NumberInput, Row, TextInput, VarningsRuta
} from 'redet-react-components'
import {email, swedishSocialSecurityNumber} from '../../../utils/validation'
import {validatePhonenumber} from '../../../utils/telephoneNumberValidation'
import Medsokande from "./Medsokande";

type Props = {
    selectedAktbilagebeteckningar: string[],
    startUpload: (notifieringEmail: string, inbjudna: Medsokande[]) => void,
    notifieringEmail: string,
    previousStep: () => void // Denna prop kommer in via WixzardDialog på något sätt
}

const emailBlacklist = ['registrator@lm.se']

interface FieldValidationResult {
    name: string,
    valid: boolean,
    errMsg: string | null
}

interface ValueAndError {
    value: string,
    error: any
}

interface CurrentEditing {
    name: ValueAndError,
    email: ValueAndError,
    personnummer: ValueAndError,
    mobiltelefon: ValueAndError,
    telefonarbete: ValueAndError,
    telefonbostad: ValueAndError,
    adress: ValueAndError,
    postnummer: ValueAndError,
    ort: ValueAndError,
    land: ValueAndError,
}

function LaggTillMedsokandeStepWizard(props: Props) {

    const initialStateCurrentEditing: CurrentEditing = {
        name: {value: '', error: null},
        email: {value: '', error: null},
        personnummer: {value: '', error: null},
        mobiltelefon: {value: '', error: null},
        telefonarbete: {value: '', error: null},
        telefonbostad: {value: '', error: null},
        adress: {value: '', error: null},
        postnummer: {value: '', error: null},
        ort: {value: '', error: null},
        land: {value: '', error: null},
    }

    const initialStateInbjudna: Medsokande[] = []

    const [inbjudna, setInbjudna] = useState(initialStateInbjudna);
    const [addButtonDisabled, setAddButtonDisabled] = useState(true);
    const [currentEditing, setCurrentEditing] = useState(initialStateCurrentEditing);

    const resetForm = () => {
        setCurrentEditing(Object.assign({}, initialStateCurrentEditing))
    }

    useEffect(() => {
        const fieldIsNotEmpty = (fieldname: keyof CurrentEditing): boolean => {
            return currentEditing[fieldname] != null && currentEditing[fieldname].value !== undefined && currentEditing[fieldname].value !== null && currentEditing[fieldname].value !== ''
        }

        const isFieldValid = (fieldname: keyof CurrentEditing): boolean => {
            return currentEditing[fieldname].error === null
        }

        const allFieldsValid = (): boolean => {
            return [
                fieldIsNotEmpty('name'),
                isFieldValid('name'),
                fieldIsNotEmpty('personnummer'),
                isFieldValid('personnummer'),
                fieldIsNotEmpty('email'),
                isFieldValid('email'),
                isFieldValid('telefonbostad'),
                isFieldValid('telefonarbete'),
                isFieldValid('mobiltelefon'),
            ].every(value => value)
        }

        setAddButtonDisabled(!allFieldsValid())
    }, [currentEditing]);

    const validField = (validatorname: string): FieldValidationResult => {
        return {name: validatorname, valid: true, errMsg: null}
    }

    const invalidField = (validatorname: string, value: string, errMsg: string): FieldValidationResult => {
        return {name: validatorname, valid: false, errMsg: errMsg}
    }

    const errorMessage = (statename: keyof CurrentEditing): string|null => {
        const state = currentEditing[statename]
        if (state.error === null || state.error === undefined) {
            return null
        }

        return state.error.errMsg
    }

    const isRequiredValidator = (val: string): FieldValidationResult => {
        if (val == null || val.length === 0 || val === '') {
            return invalidField('obligatorisk', val, 'Obligatoriskt fält')
        } else {
            return validField('obligatorisk')
        }
    }

    const minRequiredNamesValidator = (value: string): FieldValidationResult => {
        const validatorname = 'minRequiredNamesValidator'
        const minNumberOfNames = 2
        const errMsg = 'Du måste ange för- och efternamn'

        if (value == null || value.length === 0 || value === '') {
            return invalidField(validatorname, value, errMsg)
        }
        if (value.split(' ').length >= minNumberOfNames) {
            return validField(validatorname)
        }

        return invalidField(validatorname, value, errMsg)
    }

    const isRequiredAmountOfCharactersValidator = (value: string): FieldValidationResult => {
        const validatorname = 'minCharacterRequiredNamesValidator'
        const minNumberOfCharacters = 4
        const amountOfCharacters = 'Vänligen ange minst 4 tecken'

        if (value.length < minNumberOfCharacters) {
            return invalidField(validatorname, value, amountOfCharacters)
        }

        if (value.length >= minNumberOfCharacters) {
            return validField(validatorname)
        }

        return invalidField(validatorname, value, amountOfCharacters)

    }

    const emailBlackListValidator = (value: string): FieldValidationResult => {
        const validatorname = 'emailBlackListValidator'
        if (value == null || value.length === 0) {
            return validField(validatorname)
        }

        const isBlacklisted = emailBlacklist.filter(vv => vv === value).length

        if (isBlacklisted) {
            return invalidField(validatorname, value, 'Otillåten mottagare')
        }

        return validField(validatorname)
    }

    const epostDubletterValidator = (value: string) => {
        const validatorname = 'epostDubletterValidator'
        if (value == null || value.length === 0) {
            return validField(validatorname)
        }

        const emailExists = inbjudna.filter(inb => inb.email === value).length > 0

        if (emailExists) {
            return invalidField(validatorname, value, 'Epost redan tillagd')
        }

        return validField(validatorname)
    }

    const emailValidator = (value: string): FieldValidationResult => {
        const validatorname = 'emailvalidator'
        const errorMsg = email(value)

        if (errorMsg == null) {
            return validField(validatorname)
        }

        return invalidField(validatorname, value, errorMsg)
    }

    const personnummerDubletterValidator = (personnummer: string): FieldValidationResult|null => {
        const validatorname = 'personnummerDubletterValidator'

        if (personnummer == null || personnummer.length === 0) {
            return null
        }

        let persnrAlreadyExists
        const persNrWithHyphenExists = inbjudna.filter(inb => inb.personnummer.includes('-'))

        if (persNrWithHyphenExists.length > 0) {
            persnrAlreadyExists = (inbjudna.filter(inb => inb.personnummer === personnummer).length > 0 || inbjudna.filter(inb => inb.personnummer === personnummer.replace('-', '')).length > 0 || inbjudna.filter(inb => inb.personnummer.replace('-', '') === personnummer).length > 0)
        } else {
            persnrAlreadyExists = (inbjudna.filter(inb => inb.personnummer === personnummer.replace('-', '')).length > 0 || inbjudna.filter(inb => inb.personnummer === personnummer).length > 0)
        }

        if (persnrAlreadyExists) {
            return invalidField(validatorname, personnummer, 'Sökande redan tillagd')
        }

        return validField(validatorname)
    }

    const personnummerValidator = (value: string): FieldValidationResult => {
        const validatorname = 'personnummerValidator'
        let valueWithoutHyphen
        if (value.includes('-')) {
            valueWithoutHyphen = value.replace('-', '')
        }
        const errorMsg = swedishSocialSecurityNumber(valueWithoutHyphen ? valueWithoutHyphen : value)

        if (errorMsg == null) {
            return validField(validatorname)
        }

        return invalidField(validatorname, valueWithoutHyphen ? valueWithoutHyphen : value, errorMsg)
    }

    const telfonnummerValidator = (value: string): FieldValidationResult => {
        const validatorname = 'telfonnummerValidator'
        const errorMsg = validatePhonenumber(value === '' ? undefined : value)

        if (errorMsg === undefined) {
            return validField(validatorname)
        }

        return invalidField(validatorname, value, errorMsg)
    }

    const onChangeValidateInput = (evt: ChangeEvent<HTMLInputElement>, validators: Function[], stateTarget: keyof CurrentEditing) => {
        const current: CurrentEditing = Object.assign({}, currentEditing)
        const value = evt.target.value
        let error = validators.map(validatorFn => validatorFn(value)).find(validated => validated.valid === false)

        if (error === undefined) { // SKITSPRÅK
            error = null
        }

        current[stateTarget] = {
            value: value,
            error: error,
        }

        setCurrentEditing(current);
    }

    const addMedsokande = () => {
        setInbjudna([...inbjudna, skapaMedsokande()])
        resetForm()
    }

    const skapaMedsokande = (): Medsokande => {
        const medsokande = new Medsokande(valueOf('name'), valueOf('email'), cleanPnr(valueOf('personnummer')))

        medsokande.adress = valueOf('adress')
        medsokande.postnummer = valueOf('postnummer')
        medsokande.land = valueOf('land')
        medsokande.ort = valueOf('ort')
        medsokande.mobiltelefon = valueOf('mobiltelefon')
        medsokande.telefonarbete = valueOf('telefonarbete')
        medsokande.telefonbostad = valueOf('telefonbostad')

        return medsokande
    }

    const valueOf = (field: keyof CurrentEditing): string => {
        return currentEditing[field].value
    }

    /**
     * Kontrollera om något inputfält innehåller ett värde
     * @returns {boolean} true om ett eller fler inputfält inte är tomt, false om samtliga inputfält är tomma
     */
    const anyInputFieldHasText = (): boolean => {
        return Object.values(currentEditing).filter(entry => entry.value !== "").length > 0;
    }

    const deleteEmailAndNameFromList = (index: number) => {
        const newInbjudnaArray = Object.assign([], inbjudna)
        newInbjudnaArray.splice(index, 1)
        setInbjudna(newInbjudnaArray)
    }

    const joinAktbilagebeteckningar = (aktbilagebeteckningar: string[]): string => {
        let joined = ''

        if (aktbilagebeteckningar.length === 0) {
            return joined
        } else if (aktbilagebeteckningar.length === 1) {
            joined += aktbilagebeteckningar[0]
        } else if (aktbilagebeteckningar.length === 2) {
            aktbilagebeteckningar.forEach((aktbilagebeteckning, index) => {
                if (index === 0) {
                    joined += aktbilagebeteckning + ' och '
                } else {
                    joined += aktbilagebeteckning
                }
            })
        } else {
            aktbilagebeteckningar.forEach((aktbilagebeteckning, index) => {
                if (index === aktbilagebeteckningar.length - 1) {
                    joined += 'och ' + aktbilagebeteckning
                } else {
                    joined += aktbilagebeteckning + ', '
                }
            })
        }

        return joined
    }

    const cleanPnr = (pnr: string): string => {
        if (!pnr) {
            return pnr
        }
        return pnr.replaceAll(' ', '').replaceAll('-', '')
    }

    return (
        <>
            <fieldset className={'form-fieldset'}>
                <legend>Medsökande
                    <span
                        className="description">Ange namn och e&#x2011;postadresser till samtliga som ska <Term
                        label="bjudas in att bli medsökande" moment="help-komplettering"
                        term="term-bitradande"/>.</span>
                </legend>
                <form id={'medsokandeForm'}>
                    <Row>
                        <Column columns="col-md-6 col-xxl-4">
                            <TextInput id={'signatoryNameInput'}
                                       type={'text'}
                                       label={'Namn'}
                                       name={'Namn'}
                                       autocomplete={"name"}
                                       isRequired={true}
                                       validationErrorMsg={errorMessage('name')}
                                       maxLength={80}
                                       minLength={4}
                                       value={currentEditing.name.value}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [isRequiredValidator, isRequiredAmountOfCharactersValidator, minRequiredNamesValidator], 'name')}/>
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <Personnummer
                                id={'signatoryPersonnummerInput'}
                                field={'personnummer'}
                                label={'Personnummer'}
                                name={"Personnummer"}
                                mandatory={true}
                                placeholder={'ÅÅÅÅMMDDXXXX'}
                                maxNumberValue={999999999999}
                                minNumberValue={0}
                                value={currentEditing.personnummer.value}
                                validationErrorMsg={errorMessage('personnummer')}
                                onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [isRequiredValidator, personnummerValidator, personnummerDubletterValidator], 'personnummer')}
                                fieldId={undefined} errorMsg={undefined} showErrorMsg={undefined} children={undefined} // Dessa finns här eftersom Personnummer fortfarande är JS och då tycker TS att allt är obligatoriskt
                            />
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <TextInput id={'signatoryEmailInput'}
                                       isRequired={true}
                                       validationErrorMsg={errorMessage('email')}
                                       maxLength={100}
                                       type={'email'}
                                       name={"Epostadress"}
                                       autocomplete={"email"}
                                       label={'E-postadress'}
                                       value={currentEditing.email.value}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [isRequiredValidator, epostDubletterValidator, emailValidator, emailBlackListValidator], 'email')}/>
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <TextInput id={'signatoryMobiltelefon'}
                                       type='tel'
                                       isRequired={false}
                                       label={'Mobiltelefon'}
                                       name={"Mobiltelefon"}
                                       autocomplete={"tel"}
                                       value={currentEditing.mobiltelefon.value}
                                       validationErrorMsg={errorMessage('mobiltelefon')}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [telfonnummerValidator], 'mobiltelefon')}/>
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <TextInput id={'signatoryTelefonBostad'}
                                       type='tel'
                                       isRequired={false}
                                       label={'Hemtelefon'}
                                       name={"Hemtelefon"}
                                       autocomplete={"tel"}
                                       value={currentEditing.telefonbostad.value}
                                       validationErrorMsg={errorMessage('telefonbostad')}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [telfonnummerValidator], 'telefonbostad')}/>
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <TextInput id={'signatoryTelefonArbete'}
                                       type='tel'
                                       isRequired={false}
                                       label={'Telefon dagtid'}
                                       name={"Telefondagtid"}
                                       autocomplete={"tel"}
                                       value={currentEditing.telefonarbete.value}
                                       validationErrorMsg={errorMessage('telefonarbete')}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [telfonnummerValidator], 'telefonarbete')}/>
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <TextInput id={'signatoryAdress'}
                                       isRequired={false}
                                       validationErrorMsg={errorMessage('adress')}
                                       maxLength={100}
                                       type={'text'}
                                       label={'Adress'}
                                       name={"Adress"}
                                       autocomplete={"street-address"}
                                       value={currentEditing.adress.value}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [], 'adress')}/>
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <div className="flex-line full-width">
                                <NumberInput id={'signatoryPostnummer'}
                                             isRequired={false}
                                             maxLength={20}
                                             type={"number"}
                                             label={'Postnummer'}
                                             name={"Postnummer"}
                                             autocomplete={"postal-code"}
                                             validationErrorMsg={errorMessage('postnummer')}
                                             value={currentEditing.postnummer.value}
                                             onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [], 'postnummer')}/>
                                <TextInput id={'signatoryOrt'}
                                           isRequired={false}
                                           validationErrorMsg={errorMessage('ort')}
                                           maxLength={100}
                                           name={"ort"}
                                           autocomplete={"address-level2"}
                                           type={'text'}
                                           label={'Ort'}
                                           value={currentEditing.ort.value}
                                           onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [], 'ort')}/>
                            </div>
                        </Column>
                        <Column columns="col-md-6 col-xxl-4">
                            <TextInput id={'signatoryLand'}
                                       isRequired={false}
                                       validationErrorMsg={errorMessage('land')}
                                       maxLength={100}
                                       type={'text'}
                                       name={"land"}
                                       autocomplete={"country-name"}
                                       label={'Land (annat än Sverige)'}
                                       value={currentEditing.land.value}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeValidateInput(evt, [], 'land')}/>
                        </Column>
                    </Row>
                    <ButtonToolbar>
                        <ButtonSub text={'Lägg till medsökande'}
                                   icon={'add'}
                                   id="addMedsokandeToListButton"
                                   isDisabled={addButtonDisabled}
                                   onClick={addMedsokande}/>
                    </ButtonToolbar>
                </form>
            </fieldset>
            {inbjudna.length > 0 &&
                <ListGroup title={'Medsökande'} headerLevel={3}>
                    {inbjudna.map((inbjuden, index) => {
                        const actions = [<ListItemActionButton ariaLabel={'Ta bort'}
                                                               icon={'delete'}
                                                               key={`deleteButton${index}`}
                                                               id={`deleteButton${index}`}
                                                               data-testid={`deleteButton${index}`}
                                                               onClick={() => deleteEmailAndNameFromList(index)}
                                                               popover={{text: 'Ta bort'}}/>]
                        const description = [inbjuden.adress, inbjuden.postnummer, inbjuden.ort, inbjuden.land,
                            (inbjuden.mobiltelefon ? `Mobil: ${inbjuden.mobiltelefon}` : undefined),
                            (inbjuden.telefonbostad && `Hem: ${inbjuden.telefonbostad}`),
                            (inbjuden.telefonarbete && `Dagtid: ${inbjuden.telefonarbete}`),
                            (inbjuden.email && `E-postadress:${inbjuden.email}`)

                        ].filter(value => value)
                        return <ListItem header={`${inbjuden.name} - ${inbjuden.personnummer}`}
                                         actionButtons={actions}
                                         key={inbjuden.personnummer}
                                         id={`medsokande${index}`}
                                         headerLevel={4}
                                         description={description.join(', ')}
                                         status={{text: `Ska biträda ansökan ${joinAktbilagebeteckningar(props.selectedAktbilagebeteckningar)}`}}
                        />
                    })}
                </ListGroup>
            }
            <VarningsRuta
                description={'Tänk på att du måste fylla i namn, personummer och e-postadress för att kunna lägga till medsökanden.'}
                ariaTitle={'Tänk på'}/>
            <div id="Spara">
                <WizardNavigationButtonToolbar prevButtonText={'Ange kvittensmottagare'}
                                               onClickPrevButton={props.previousStep}
                                               nextButtonText={undefined} onClickNextButton={undefined} disableNextButton={undefined} disablePrevButton={undefined} // Dessa finns här eftersom WizardNavigationButtonToolbar fortfarande är JS och då tycker TS att allt är obligatoriskt
                                               >
                    <ButtonTop text={'Bjud in medsökande'} icon={'check'}
                               id={'finishUploadHandlingButtonHandlingar'}
                               isDisabled={(inbjudna.length === 0 || anyInputFieldHasText())}
                               onClick={() => props.startUpload(props.notifieringEmail, inbjudna)}/>
                </WizardNavigationButtonToolbar>
            </div>
        </>
    )

}

export default LaggTillMedsokandeStepWizard
