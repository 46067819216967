import React, { useState } from 'react'
import { errorMessages, warningMessages } from '../../utils/validation'
//@ts-ignore
import { TextInput } from 'redet-react-components'

type emailRecipient = {
  name: string,
  email: string
}

interface Props {
  inputFieldHasWarningValidation: boolean,
  disabledButton: (input: boolean) => void,
  callbackValidEmail: (email: string) => void,
  id: string,
  emailList?: emailRecipient[],
  defaultEpost?: string,
  value?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  isRequired: boolean
}

export const EpostInputFieldWithValidation = ({
  inputFieldHasWarningValidation,
  disabledButton,
  callbackValidEmail,
  id,
  emailList,
  defaultEpost,
  value,
  onChange,
  isRequired
  }: Props) => {

  const [warningMessage, setWarningMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const ANGE_GILTIG_EPOSTADRESS = 'Ange en giltig e-postadress'
  const OTILLATEN_MOTTAGARE = 'Otillåten mottagare'
  const OBLIGATORISKT_FALT = 'Obligatoriskt fält'
  const EPOST_FINNS_REDAN_I_LISTAN = 'E-postadressen finns redan med i listan'
  const HANDLAGGARE_OFTA_INTE_BESTYRKA = 'Handläggare på Lantmäteriet kan oftast inte bestyrka'

  const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    disabledButton(true)
    setWarningMessage(undefined)
    setErrorMessage(undefined)
    if (inputFieldHasWarningValidation === true) {
      validationWithWarning(event.target.value)
    } else {
      noWarningValidation(event.target.value)
    }
    if (onChange)
      onChange(event)
  }

  const validationWithWarning = (email: string) => {
    let isErrorMessage = errorMessages(email, emailList)
    let isWarningMessage = warningMessages(email)
    let validationMessage = isErrorMessage || isWarningMessage
    switch (validationMessage) {
      case ANGE_GILTIG_EPOSTADRESS:
        setErrorMail(validationMessage)
        break
      case OTILLATEN_MOTTAGARE:
        setErrorMail(validationMessage)
        break
      case OBLIGATORISKT_FALT:
        setErrorMail(validationMessage)
        break
      case HANDLAGGARE_OFTA_INTE_BESTYRKA:
        setWarningEmail(validationMessage, email)
        break
      default:
        if (email !== defaultEpost) {
          callbackValidEmail(email)
          disabledButton(false)
        } else {
          disabledButton(false)
          callbackValidEmail(email)
        }
        break
    }
  }

  const setErrorMail = (validationMessage: string) => {
    if (id === 'signatoryEmailInput' && validationMessage === OBLIGATORISKT_FALT && emailList && emailList.length > 0) {
      disabledButton(true)
    } else {
      disabledButton(true)
      setErrorMessage(validationMessage)
    }
  }

  const setWarningEmail = (validationMessage: string, email: string) => {
    disabledButton(false)
    setWarningMessage(validationMessage)
    callbackValidEmail(email)
  }

  const noWarningValidation = (email: string) => {
    let isErrorMessage = errorMessages(email, emailList)
    switch (isErrorMessage) {
      case ANGE_GILTIG_EPOSTADRESS:
        setErrorMail(isErrorMessage)
        break
      case OTILLATEN_MOTTAGARE:
        setErrorMail(isErrorMessage)
        break
      case OBLIGATORISKT_FALT:
        setErrorMail(isErrorMessage)
        break
      case EPOST_FINNS_REDAN_I_LISTAN:
        setErrorMail(isErrorMessage)
        break
      default:
        if (email !== defaultEpost) {
          callbackValidEmail(email)
          disabledButton(false)
        } else {
          disabledButton(false)
          callbackValidEmail(email)
        }
        break
    }
  }

  return (
    <TextInput
      className="form-input-text"
      label={'E-post'}
      maxLength={100}
      name={'epost'}
      autocomplete={'email'}
      type={'email'}
      id={id}
      isRequired={isRequired}
      validationWarningMsg={warningMessage}
      validationErrorMsg={errorMessage}
      defaultValue={defaultEpost}
      value={value}
      onChange={(email: React.ChangeEvent<HTMLInputElement>) => validateEmail(email)}
    />
  )
}
