import React from 'react'
import PropTypes from 'prop-types'
import { SelectSearchFinalForm, TextInputFinalForm } from '../../General/forms/finalForm'
import { Column, Row } from 'redet-react-components'
import { countrys } from '../../../utils/globalConstants'

export default function AddressForm ({
  fieldsAreNotRequired,
  formAutocomplete,
  formAutocompletePrefix,
  fields: { adress, postnummer, postort, land },
  children
}) {
  const autoPrefix = formAutocompletePrefix ? formAutocompletePrefix : ''
  return (
    <>
      <TextInputFinalForm field={adress}
                          label={'Adress'}
                          required={!fieldsAreNotRequired}
                          maxLength={100}
                          autoComplete={formAutocomplete ? autoPrefix + 'street-address' : undefined}/>
      <Row>
        <Column columns={'col-xs-12 col-sm-6'}>
          <div className="flex-line">
            <TextInputFinalForm field={postnummer}
                                label={'Postnr'}
                                required={!fieldsAreNotRequired}
                                maxLength={5}
                                autoComplete={formAutocomplete ? autoPrefix + 'postal-code' : undefined}/>
            <TextInputFinalForm field={postort}
                                label={'Postort'}
                                required={!fieldsAreNotRequired}
                                maxLength={100}
                                autoComplete={formAutocomplete ? autoPrefix + 'address-level1' : undefined}/>
          </div>
        </Column>
        <Column columns={'col-xs-12 col-sm-6'}>
          <SelectSearchFinalForm field={land}
                                 label={'Land (annat än Sverige)'}
                                 isAcceptingCustomInput={false}
                                 options={countrys}
                                 autoComplete={formAutocomplete ? autoPrefix + 'country-name' : undefined}/>
        </Column>
      </Row>
      {children}
    </>
  )
}

AddressForm.propTypes = {
  fields: PropTypes.object.isRequired,
  fieldsAreNotRequired: PropTypes.bool,
  formAutocomplete: PropTypes.bool,
  formAutocompletePrefix: PropTypes.string,

}
