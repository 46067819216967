
export enum ContactTypes {
    CONTACT_TYPE_INDIVIDUAL = "contacts/CONTACT_TYPE_INDIVIDUAL",
    CONTACT_TYPE_ORGANIZATION = "contacts/CONTACT_TYPE_ORGANIZATION"
}

export type Contact = {
    id: string
    type: ContactTypes
    identifier: string
    data: ContactData
}

export type ContactData = {
    foretrader: string;
    rollSokande: boolean;
    namn: string;
    epost?: string;
    imported: boolean;
    representerarFastigheter: KontaktFastighet[];
    agerValdaFastigheter: KontaktFastighet[];
    adress: string;
    postnummer: string;
    postort: string;
    alternativAdressAngiven: boolean;
    rollFakturamottagare: boolean;
    rollAktmottagare: boolean;
    adressAlternativ: string;
    postnummerAlternativ: string;
    postortAlternativ: string;
    kontaktKommentar: string;
    andelAvForattningskostnaden: string;
    fakturaAdressAngiven: boolean;
    adressFaktura: string;
    postnummerFaktura: string;
    postortFaktura: string;
    samordningsnummer: string;
    ovrigtIdNummer: string;
    fakturaMarkning: string;
    telefonMobil: string;
    telefonHem: string;
    telefonDagtid: string;
    rollOmbud: boolean;
    ombudForPersoner?: string[];
    IDChoice: string;
    personnummer: string;
    orgKontaktperson: string;
    orgNamn: string;
    orgNummer: string;
    optOutFromRepresentingFastighet: boolean;
    rollUpprattare: boolean;
    rollAnnanBerord: boolean;
    land: string;
    landAlternativ: string;
    landFaktura: string;
    orgTelefon: string;
    orgTelefonKontaktperson: string;
    orgTelefonKontaktpersonMobil: string;
    orgEmail: string;
    orgKontaktpersonEmail?: string;
    saknarBankId: boolean;
}

type KontaktFastighet = {
    registerbeteckning: string;
    objektidentitet: string;
}