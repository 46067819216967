// @ts-ignore
import {ButtonToolbar, IconButtonWithPopover, LinkButtonText} from "redet-react-components";
import {formatOrgnr, formatPnr} from "../../utils/personnummer";
import {individualContactFormValidations} from "./Individual/individualContactFormValidations";
import {organizationContactFormValidations} from "./Organization/organizationContactFormValidations";
import {getFortradareNamnForContact} from "../../utils/contactsHelper";
import {individualContactFormWarningValidation} from "./Individual/individualContactFormWarningValidation";
import {organizationContactFormWarningValidation} from "./Organization/organizationContactFormWarningValidation";
import {Validation} from "redux/modules/validation/rules/model/Validation";
import {Contact, ContactTypes} from "domain/Contact";

const kompletteringString = "Måste kompletteras"

type Props = {
    contact: Contact
    onClickListItemCallback: (contact: Contact) => void
    onClickRemoveContactButton: (show: boolean, contactId: string) => void
    contactList: Contact[]
    ansokanValidations: Validation[]
}

export const ContactListItem = (props: Props) => {

    const handleContactClick = () => {
        return () => props.onClickListItemCallback(props.contact);
    }

    const checkIfPersonHasOmbud = () => {
        let name = getFortradareNamnForContact(props.contactList, props.contact.id);
        if (name !== '') {
            return "Företräds av " + name;
        }
    }

    const getRoleText = () => {
        let privatpersonString = [];
        if (props.contact.data.rollUpprattare) {
            privatpersonString.push("Upprättare av ansökan");
        }
        if (props.contact.data.rollSokande) {
            privatpersonString.push("Sökande");
        }
        if (props.contact.data.rollFakturamottagare) {
            privatpersonString.push("Betalare");
        }
        if (props.contact.data.rollAktmottagare) {
            privatpersonString.push("Aktmottagare");
        }
        if (props.contact.data.rollAnnanBerord) {
            privatpersonString.push("Annan berörd");
        }
        if (props.contact.data.rollOmbud) {
            privatpersonString.push("Företrädare");
        }
        return privatpersonString.join(', ')
    }

    const contactIsPerson = () => {
        return props.contact.type === ContactTypes.CONTACT_TYPE_INDIVIDUAL;
    }

    const contactIsOrganization = () => {
        return props.contact.type === ContactTypes.CONTACT_TYPE_ORGANIZATION;
    }

    const getIdentificationNr = () => {
        if (contactIsPerson()) {
            if (props.contact.data.personnummer) {
                return <p>Personnummer {formatPnr(props.contact.data.personnummer)}</p>
            } else if (props.contact.data.samordningsnummer) {
                return <p>Samordningsnummer {formatPnr(props.contact.data.samordningsnummer)}</p>
            } else if (props.contact.data.ovrigtIdNummer) {
                return <p>Övrigt id nummer {props.contact.data.ovrigtIdNummer}</p>
            }
        } else if (contactIsOrganization()) {
            if (props.contact.data.orgNummer) {
                return <p>Organisationsnummer {formatOrgnr(props.contact.data.orgNummer)}</p>
            }
        }
    }

    const getRepresentedProperties = () => {
        return (
            <div className="sub-list">
                <div className="list alternating">
                    <section className="content">
                        {
                            props.contact.data.representerarFastigheter?.length > 0 &&
                            <>
                                <h4>Företräder följande fastigheter</h4>
                                {props.contact.data.representerarFastigheter.map((fastighet, index) => <p
                                    key={index}>{fastighet.registerbeteckning}</p>)}
                            </>
                        }
                    </section>
                </div>
            </div>
        );
    }

    const getEmptyRecommendedFieldsWarning = () => {
        return (
            <p>
                <span className="description error">
                    <span className="material-icons">error_outline</span>
                    {kompletteringString}
                </span>
            </p>
        );
    }

    const getContactName = () => {
        if (contactIsPerson()) {
            return `${props.contact.data.namn}`;
        } else if (contactIsOrganization()) {
            return props.contact.data.orgNamn;
        }
    }


    const contactContainsEmptyRecommendedFields = (contact: Contact) => {
        switch (contact.type) {
            case ContactTypes.CONTACT_TYPE_INDIVIDUAL:
                const validationErrors = Object.entries(individualContactFormValidations(contact.data, props.contactList, contact.id, props.ansokanValidations));
                const validationWarnings = Object.entries(individualContactFormWarningValidation(contact.data, props.contactList, contact.id));
                const validations = validationErrors.concat(validationWarnings);
                return validations.length > 0;
            case ContactTypes.CONTACT_TYPE_ORGANIZATION:
                const orgValidationErrors = Object.entries(organizationContactFormValidations(contact.data, props.contactList, contact.id, props.ansokanValidations))
                const orgValidationWarnings = Object.entries(organizationContactFormWarningValidation(contact.data, props.contactList, contact.id))
                const orgValidations = orgValidationErrors.concat(orgValidationWarnings);
                return orgValidations.length > 0;
            default:
                return false;
        }
    };
    return (
        <div className="passthrough-row">
            <ButtonToolbar>
                <IconButtonWithPopover icon={'create'}
                                       popoverText={'Ändra kontaktuppgift'}
                                       ariaLabel={'Ändra kontaktuppgift'}
                                       onClick={handleContactClick()}
                                       name="contactAndraKnapp"
                                       id={`editContactButtonId${props.contact.id}`}/>
                <IconButtonWithPopover popoverText={'Ta bort den här kontaktuppgiften'}
                                       icon={'delete'}
                                       ariaLabel={'Ta bort'}
                                       onClick={() => props.onClickRemoveContactButton(true, props.contact.id)}
                                       name="contactDeleteKnapp"
                                       id={`deleteContactButtonId${props.contact.id}`}
                                       isDisabled={props.contact.data.rollUpprattare}/>
            </ButtonToolbar>
            <section className="content">
                <h3>
                    <LinkButtonText
                        text={getContactName()}
                        data-selenium-name="contactname"
                        onClick={handleContactClick()}/>
                </h3>
                {getIdentificationNr()}
                <p data-selenium-name="contactRoles"
                   id={"contactRoles-" + props.contact.id}>{getRoleText()} </p>
                <strong><p data-selenium-name="contactHasOmbud">{checkIfPersonHasOmbud()}</p></strong>
                {contactContainsEmptyRecommendedFields(props.contact) && getEmptyRecommendedFieldsWarning()}
            </section>
            {getRepresentedProperties()}
        </div>);
}
