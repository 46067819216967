export type FeatureToggle = FeatureToggleOnOff | FeatureToggleNotis

export type FeatureToggleOnOff = {
  name: string,
  enabled: boolean,
  metadata: {
    type: 'onoffMetadata'
  }
}

export type FeatureToggleNotis = {
  name: string
  enabled: boolean,
  unread: boolean,
  metadata: {
    header?: string
    severity?: FeatureToggleNotisSeverity,
    message?: string,
    type: 'notificationMetadata'
  }
}

export enum FeatureToggleNotisSeverity {
  INFORMATION = "Information",
  WARNING = "Warning",
  ERROR = "Error"
}

export const ONOFF_METADATA = 'onoffMetadata'
export const NOTIFICATION_METADATA = 'notificationMetadata'
