import React, {ReactElement, ReactNode} from 'react'
import {ListItemFlexGroupProps} from "./ListItemFlexGroup";
import {ListItemActionButton, ButtonToolbar, ButtonGroup, ListItemSubList} from "redet-react-components"

interface ListRowProps {
    id?: string
    children: {
        actions?: typeof ListItemActionButton | typeof ListItemActionButton[] | ReactNode
        items?: ReactElement<ListItemFlexGroupProps> | ReactElement<ListItemFlexGroupProps>[] | ReactNode
        sublist?: ReactNode | ReactNode[]
    }
}

export function ListItemFlex({id, children, ...rest}: ListRowProps) {

    const {actions, items, sublist} = children

    return (<div role={'listitem'} className={'passthrough-row'} id={id} {...rest}>
        {/*action-knappar*/}
        {actions && <ButtonToolbar>
            <ButtonGroup>
                {/*@ts-ignore*/}
                {actions}
            </ButtonGroup>
        </ButtonToolbar>}
        <section className={'content'}>
            <div className={'flex-line'}>
                {items}
            </div>
        </section>
        {sublist && <ListItemSubList>
            {sublist}
        </ListItemSubList>}
    </div>)
}
