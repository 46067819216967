const SET_USER_RATING = 'feedback/SET_USER_RATING'
const USER_COMMENT_CHANGE = 'feedback/USER_COMMENT_CHANGE'

const initialState = {
  rating: 0,
  comment: ''
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_RATING:
      return {
        ...state,
        rating: action.rating
      }
    case USER_COMMENT_CHANGE:
      return {
        ...state,
        comment: action.comment
      }

    default:
      return state
  }
}

export function setRating (rating) {
  return {
    type: SET_USER_RATING,
    rating: rating
  }
}

export function userCommentChange (comment) {
  return {
    type: USER_COMMENT_CHANGE,
    comment: comment
  }
}
