import { useEffect, useState } from 'react'
import { Term } from 'views'
import { createValidator, required } from 'utils/validation'
import { formProperties } from 'redux/formProperties'
import { connect } from 'react-redux'
import { initFormData } from 'redux/finalForm'
import FormStateToRedux from 'redux/FormStateToRedux'
import { Form } from 'react-final-form'
import { CheckboxFinalForm, Condition, TextAreaFinalForm, WhenFieldChanges } from '../../General/forms/finalForm'
//@ts-ignore
import { Column, FormFieldset, Row, PageSubContent } from 'redet-react-components'


type AvstyckProps = {
  initFormData: (atgard: string) => {}
}

export let validateAvstyckning3dForm = function (values: any) {
  return createValidator(values,
    {
      nyttServitutBeskrivning: values?.nyttServitut ? [required] : [],
      nyGemensamhetsanlaggningBeskrivning: values?.nyGemensamhetsanlaggning ? [required] : [],
    })
}

export function Avstyckning3dForm(props: AvstyckProps) {

  const [_formDataLoaded, setFormDataLoaded] = useState(false);
  const [_initData, setInitData] = useState({});

  useEffect(() => {
    const res = props.initFormData(formProperties.avstyckning3d.name)
    setFormDataLoaded(true);
    setInitData(res)
  },[])

    return (
      <PageSubContent title={<>Bilda ny tredimensionell fastighet <em>(avstyckning för att bilda en ny 3D-fastighet)</em></>} headerLevel={4} id="fastighetsforandring-avstyckning3dForm" headerId="fastighetsforandring-avstyckning3dHeader">
        <br/>
        <Form
          onSubmit={() => {}}
          initialValues={_initData}
          subscription={{ submitting: true, pristine: true }}
          validate={values => validateAvstyckning3dForm(values)}>
          {() => {
            return (
              <form onSubmit={() => {}}>
                {_formDataLoaded && <FormStateToRedux form={formProperties.avstyckning3d.name}/>}

                <fieldset>
                  <Row>
                    <Column columns={'col-xs-12 col-md-6'}>
                      <FormFieldset title={'Vad ska den eller de nya fastigheterna användas till?'}
                                    description={'Ange användning, t.ex. permanentboende, parkering, handel eller liknande'}>
                        <TextAreaFinalForm id={'anvandningId'} field={'anvandning'} label={'Beskrivning'}
                                           mandatory={false}/>
                      </FormFieldset>
                    </Column>
                    <Column>
                      <FormFieldset title={'Övriga uppgifter'}>
                        {/* @ts-ignore */}
                        <CheckboxFinalForm field={'inteckningsfri'} id={'avstyckning3dInteckningsfriId'}
                                           label={'Nybildad fastighet ska vara fri från inteckningar'}
                                           term={<Term label="inteckningsfri avstyckning" moment="help-description"
                                                       term="term-avstyckning"/>}/>
                        {/* Nytt Servitut */}
                        {/* @ts-ignore */}
                        <CheckboxFinalForm field={'nyttServitut'} label={'Ny rättighet ska bildas'}
                                           id={'avstyckning3dNyttServitutId'}
                                           term={<Term label="servitut" moment="help-description"
                                                       term="term-servitut"/>}/>
                        {/* @ts-ignore */}
                        <Condition when={'nyttServitut'} is={true}>
                          <TextAreaFinalForm
                            id={'nyttServitutBeskrivningId'}
                            field={'nyttServitutBeskrivning'}
                            label={'Beskriv vad servituten avser.'}
                            mandatory={true}/>
                        </Condition>
                        <WhenFieldChanges field={'nyttServitut'} becomes={false} set={'nyttServitutBeskrivning'}
                                          to={undefined}/>
                        {/* Ny Gemensamhetsanlaggning */}
                        {/* @ts-ignore */}
                        <CheckboxFinalForm field={'nyGemensamhetsanlaggning'}
                                           label={'Ny gemensamhetsanläggning ska bildas'}
                                           id={'avstyckning3dNyGemensamhetsanlaggningId'}
                                           term={<Term label="gemensamhetsanläggning" moment="help-description"
                                                       term="term-gemensamhetsanlaggning"/>}/>
                        {/* @ts-ignore */}
                        <Condition when={'nyGemensamhetsanlaggning'} is={true}>
                          <TextAreaFinalForm
                            id={'nyGemensamhetsanlaggningBeskrivningId'}
                            field={'nyGemensamhetsanlaggningBeskrivning'}
                            label={'Beskriv syfte och ändamål med gemensamhetsanläggningen'}
                            mandatory={true}/>
                        </Condition>

                        <WhenFieldChanges field={'nyGemensamhetsanlaggning'} becomes={false}
                                          set={'nyGemensamhetsanlaggningBeskrivning'} to={undefined}/>
                      </FormFieldset>
                    </Column>
                  </Row>
                </fieldset>
              </form>
            )
          }}
        </Form>
      </PageSubContent>
    )
}


export default connect(undefined, { initFormData })(Avstyckning3dForm)