//@ts-ignore
import {SidePanelChapterHeading, SidePanelSection} from "redet-react-components";

export const SammanfattningHelpContent = <>
    <SidePanelChapterHeading header={'Sammanfattning'} sectionTitle={'Sammanfattning'}/>
    <SidePanelSection heading="Information" id={'term-ovrigt'}>
        <p>
            I fliken "Sammanfattning" kan valfria anteckningar som inte passar in någon annanstans i ansökan skrivas ner.
            Dessa blir en del utav ansökan.
            Exempelvis specifikation av kontaktuppgifter ("Kontakta mig gärna i huvudsak per e-post"),
            ett förtydligande av information du lämnat ("Jag har bifogad bygglovshandlingen, om ni önskar se originalet kan jag skicka detta till er")
            eller helt enkelt något annat som du tycker att vi på Lantmäteriet bör känna till för att kunna handlägga ditt ärende.
        </p>
    </SidePanelSection>
</>