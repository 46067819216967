// @flow
// 1.1 En ansökan ska innehålla minst 1 fastighet

import { BLOCKING, MINST_EN_ATGARD } from './constants'
import { Validation } from './model/Validation'

const ALLA_ATGARDER = ['avstyckning', 'sammanlaggning', 'klyvning', 'avstyckning3d', 'fastighetsreglering', 'gransutmarkning', 'fastighetsbestamning']

const ansokanHarAtgard = state => atgard => {
  const atgarder = state.form.fastighetsforandring
  return atgarder[atgard] !== undefined && atgarder[atgard] === true
}

function validate (state: Object): ?Validation {

  const atgarder = ALLA_ATGARDER.filter(ansokanHarAtgard(state))

  if (atgarder.length === 0) {
    return new Validation(MINST_EN_ATGARD, 'Du har inte valt vad som ska hända med fastigheten. Du måste ange minst en åtgärd.', 'atgard', BLOCKING, undefined)
  }
  return undefined
}

export default validate
