import { ANSOKAN_LOAD_SUCCESSFUL } from '../ReduxDataLoaderReducer'
import { resetFormState } from '../finalForm'

const cleanUpAtgarderMiddleware = ({ getState, dispatch }) => next => action => {
  let result = next(action)

  function isEmptyObject (objectToCheck) {
    return Object.keys(objectToCheck).length === 0 && objectToCheck.constructor === Object
  }

  function atgardContainsData (atgard) {
    return atgard !== undefined && !isEmptyObject(atgard)
  }

  if (action.type === ANSOKAN_LOAD_SUCCESSFUL) {
    let state = getState()
    for (const [atgard, isChosen] of Object.entries(state.form.fastighetsforandring)) {
      if (isChosen === false) {
        if (atgardContainsData(state.form[atgard])) {
          dispatch(resetFormState(atgard))
        }
      }
    }
  }
  return result
}

export default cleanUpAtgarderMiddleware