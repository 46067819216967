import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayNextStep, displayPreviousStep } from 'redux/modules/wizardDialog'
import {
    displayFullscreenProgress,
    hideFullscreenProgress,
    updateFullscreenProgress
} from 'redux/modules/fullscreenProgress'
import {
    pendingFiles,
    signKompletteringStart
} from 'redux/modules/kompletteringBifogaHandling'
import { reloadKompletteringar, removeKomplettering } from 'redux/modules/arendeKompletteringar'
import { BIFOGADHANDLINGTYPE_NOT_SELECTED, BIFOGADHANDLINGTYPES, } from 'utils/globalConstants'

import { addProgress, endProgress } from 'redux/modules/fullscreenLoading'
import UploadFilesStep2Wizard from './uploadWizard/UploadFilesStep2Wizard'
import { ValjTypStep1Wizard } from './uploadWizard/ValjTypStep1Wizard'
import { Term } from '../index'
import { fetchKompletteringar, submitKomplettering } from '../../redux/modules/arendeKompletteringar'
import WizardDialog, { WizardDialogStep } from '../../components/wizard/WizardDialog'
import ModalDialogError from '../../components/General/ModalDialogError'
import { ANTAL_SIDOR_FELMEDDELANDE, EPOST_FELMEDDELANDE, FELAKTIG_PDF, FILTYP_FELMEDDELANDE } from './handlingConstants'
import SigningHederOchSamveteKompletteringWizard from './kompletteringWizard/SigningHederOchSamveteKompletteringWizard'
import {
    abortSigningKomplettering,
    signKompletteringStartPhos,
    uploadKompletteringFromBifogadFilePhos
} from '../../redux/modules/kompletteringBifogaHandling'
import { fetchSigningStatusKomplettering } from '../../redux/modules/arendeKomplettering'
import { KompletteringInskickadStep } from './kompletteringWizard/KompletteringInskickadStep'
import { logError } from '../../utils/errorHandler'
import {
    EmailBifogadeHandlingarKompletteringWizard
} from './kompletteringWizard/EmailBifogadeHandlingarKompletteringWizard'
import {FaultyPDFFileError, ForbiddenBifogadHandlingFiletypeException, TooManyFilesInBifogadHandlingException, UserAbortException,InvalidEmailException} from "../../repository/BifogadHandlingException";

const mapStateToProps = (store, own) => {
  return {
    pendingFiles: store.bifogadHandlingKomplettering.pendingFiles,
    isUploading: store.bifogadHandlingKomplettering.isUploading,
    failedToSubmit: store.arendeKompletteringar.failedToSubmit || false,
    featureToggleKompletteringService: store.featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED,
    bifogadeHandlingar: store.bifogadHandling.list,
  }
}

const mapDispatchToProps = {
  signKompletteringStart,
  signKompletteringStartPhos,
  abortSigningKomplettering,
  uploadKompletteringFromBifogadFilePhos,
  displayFullscreenProgress,
  updateFullscreenProgress,
  hideFullscreenProgress,
  displayNextStep,
  displayPreviousStep,
  pendingFiles,
  addProgress,
  endProgress,
  reloadKompletteringar,
  fetchKompletteringar,
  removeKomplettering,
  fetchSigningStatusKomplettering,
  submitKomplettering
}

export class BifogadeHandlingarKompletteringWizardUploadForm extends Component {
  static propTypes = {
    // constructor
    arendenummer: PropTypes.string.isRequired,

    // bifogadHandlingKomplettering
    failedToSubmit: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    pendingFiles: PropTypes.func.isRequired,
    signKompletteringStart: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    bifogadeHandlingar: PropTypes.array.isRequired,

    // Fullscreen progress
    displayFullscreenProgress: PropTypes.func.isRequired,
    updateFullscreenProgress: PropTypes.func.isRequired,
    hideFullscreenProgress: PropTypes.func.isRequired,

    // WizardDialog
    displayNextStep: PropTypes.func.isRequired,
    displayPreviousStep: PropTypes.func.isRequired,

    // arendeKompletteringar
    reloadKompletteringar: PropTypes.func.isRequired,
    fetchKompletteringar: PropTypes.func.isRequired,
    fetchSigningStatusKomplettering: PropTypes.func.isRequired,

    // övrigt
    featureToggleKompletteringService: PropTypes.bool.isRequired,
    addProgress: PropTypes.func.isRequired,
    endProgress: PropTypes.func.isRequired,

    closeWizardFunction: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)

    this.state = {
      failedToSubmit: false,
      selectedFiles: [],
      attachmentType: BIFOGADHANDLINGTYPE_NOT_SELECTED,
      errorMessages: [],
      ovrigtBeskrivningKomplettering: '',
      notifieringEmail: undefined,
      signingUrl: undefined,
      errorMessage: undefined,
      closeWizardOnSignStartError: false,
      uploadFailed: false,
      inskickadDatum: undefined,
      showCloseModal: false,
      isInskickad: false,
      ...props.initialState
    }

    // Bind this to class methods instead of older ::
    this.onUploadComplete = this.onUploadComplete.bind(this)
    this.onUploadFailed = this.onUploadFailed.bind(this)
    this.onSigningFailed = this.onSigningFailed.bind(this)
    this.startUpload = this.startUpload.bind(this)
    this.startSigning = this.startSigning.bind(this)
    this.abortSigning = this.abortSigning.bind(this)
    this.removeSelected = this.removeSelected.bind(this)
    this.clearErrorMessage = this.clearErrorMessage.bind(this)
    this.setInskickadDatum = this.setInskickadDatum.bind(this)
    this.fetchKompletteringAndHandleError = this.fetchKompletteringAndHandleError.bind(this)
    this.setNotifieringEmail = this.setNotifieringEmail.bind(this)
  }

  onUploadComplete (kompletteringId) {
    this.setState({ kompletteringId: kompletteringId })
    this.props.onUpload()
    if (kompletteringId === undefined || kompletteringId === null) {
      this.setState(prevState => ({
        errorMessages: [...prevState.errorMessages, 'Något gick fel, vänligen försök igen.']
      }))
    } else {
      this.props.pendingFiles(false)
      this.startSigning(kompletteringId)
    }
  }

  clearErrorMessage () {
    this.setState({ errorMessage: undefined })
  }

  onUploadFailed (error) {
    this.setState({ errorMessages: [], uploadFailed: true })
    const title = 'Fel vid uppladdning'
    if (error instanceof TooManyFilesInBifogadHandlingException) {
      this.setState({
        errorMessage: {
          title: title,
          message: ANTAL_SIDOR_FELMEDDELANDE,
          button: { text: 'OK', icon: 'check', onClickFunction: this.clearErrorMessage }
        }
      })
    } else if (error instanceof ForbiddenBifogadHandlingFiletypeException) {
      this.setState({
        errorMessage: {
          title: title,
          message: FILTYP_FELMEDDELANDE,
          button: { text: 'OK', icon: 'check', onClickFunction: this.clearErrorMessage }
        }
      })
    } else if (error instanceof InvalidEmailException) {
      this.setState({
        errorMessage: {
          title: title,
          message: EPOST_FELMEDDELANDE,
          button: { text: 'OK', icon: 'check', onClickFunction: this.clearErrorMessage }
        }
      })
    } else if (error instanceof FaultyPDFFileError) {
      this.setState({
        errorMessage: {
          title: title,
          message: FELAKTIG_PDF,
          button: { text: 'OK', icon: 'check', onClickFunction: this.clearErrorMessage }
        }
      })
    } else {
      error.component = 'BifogadeHandlingarKompletteringWizardUploadForm.js'
      logError(error, 'BifogadeHandlingarKompletteringWizardUploadForm-component')
      this.setState({
        errorMessage: {
          title: title,
          message: <span>Ett tekniskt fel uppstod vid uppladdning av bifogad handling. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
            href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>,
          button: { text: 'OK', icon: 'check', onClickFunction: this.clearErrorMessage }
        }
      })
    }
  }

  onSigningFailed () {
    this.props.endProgress()

    this.props.removeKomplettering(this.state.kompletteringId)

    this.setState({
      errorMessage: {
        title: 'Fel vid uppladdning',
        message:
          <span>Ett fel inträffade när e-post skulle skickas. Försök igen senare genom att klicka på <strong>Lägg till handling</strong> och börja om.</span>,
        button: {
          text: 'OK', icon: 'check', onClickFunction: () => {
            this.props.closeWizardFunction(this.state.isInskickad);
            this.props.reloadKompletteringar(true)
            this.fetchKompletteringAndHandleError(this.props.arendenummer)
          }
        }
      },
      closeWizardOnSignStartError: true
    })
  }

  onAbortSigningFailed () {
    this.props.endProgress()

    this.setState({
      errorMessage: {
        title: 'Fel vid avbrytande av signering',
        message:
          <span>Ett fel inträffade när signeringen skulle avbrytas. Försök igen senare genom att klicka på <strong>Skicka in ytterligare handling</strong> och börja om.</span>,
        button: {
          text: 'OK', icon: 'check', onClickFunction: () => {
            this.props.closeWizardFunction(this.state.isInskickad);
            this.props.reloadKompletteringar(true)
            this.fetchKompletteringAndHandleError(this.props.arendenummer)
          }
        }
      },
      closeWizardOnSignStartError: true
    })
  }

  fetchKompletteringAndHandleError (arendenummer) {
    this.props.fetchKompletteringar(arendenummer)
      .catch(() => {
        this.setState({
          errorMessage: {
            title: 'Fel vid hämtning av komplettering',
            message: <span>Ett tekniskt fel uppstod vid hämtning av kompletteringar. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
              href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>,
            button: { text: 'OK', icon: 'check', onClickFunction: this.clearErrorMessage }
          }
        })
      })
  }

  startUpload () {
    const handling = {
      type: this.state.attachmentType,
      description: this.state.attachmentType === 'ANNAT' ? this.state.ovrigtBeskrivningKomplettering : '',
      files: this.state.selectedFiles,
      inbjudanEmail: 'noreply@lm.se', //PHOS DUMMY EMAIL
      notifieringEmail: this.state.notifieringEmail
    }

    return new Promise(resolve => {
      const abortController = new AbortController()
      //visa progress med avbryt/abort
      this.props.displayFullscreenProgress(() => abortController.abort())
      return this.props.uploadKompletteringFromBifogadFilePhos(
          this.props.arendenummer,
          handling,
          (percentComplete = 100) => {
            this.props.updateFullscreenProgress(percentComplete)
            if (percentComplete >= 95) {
              //göm avbryt-knapp när uppladdning klar
              this.props.displayFullscreenProgress(undefined, true)
            }
          },
          abortController)
          .then(result => {
            this.onUploadComplete(result)
            resolve()
          })
          .catch(error => {
            if (!(error instanceof UserAbortException)) {
              this.onUploadFailed(error)
            }
          })
          .finally(() => setTimeout(this.props.hideFullscreenProgress, 500))
    })
  }

  setInskickadDatum (inskickadDatum) {
    if (inskickadDatum !== undefined) {
      this.setState({ inskickadDatum: inskickadDatum })
    }
    this.setState({isInskickad: true})
  }

  startSigning (kompletteringId) {
    this.props.displayFullscreenProgress()
    this.props.addProgress(false)
    this.props.signKompletteringStartPhos(
      kompletteringId)
      .then((result) => {
        this.setState({
          signingUrl: result.signingUrl
        })
        this.props.endProgress()
      }).catch((err) => {
      this.onSigningFailed(err)
    })
  }

  abortSigning(kompletteringId) {
    this.props.abortSigningKomplettering(kompletteringId)
    .then((result) => {
      this.setState({
        signingUrl: result
      })
      this.props.endProgress()
    }).catch((error) => {
      this.onAbortSigningFailed()
    }).finally(() => this.fetchKompletteringAndHandleError(this.props.arendenummer))
  }

  removeSelected (file) {
    this.setState({ selectedFiles: [...this.state.selectedFiles.filter(selectedFile => selectedFile !== file)] }, () => {
      if (this.state.selectedFiles.length === 0) {
        this.props.pendingFiles()
      }
    })
  }

  selectType = (type) => {
    this.setState({ attachmentType: type })
  }

  setOvrigBeskrivning = (text) => {
    this.setState({ ovrigtBeskrivningKomplettering: text })
  }

  setSelectedFiles = (filesToAdd) => {
    this.setState({ selectedFiles: [...this.state.selectedFiles, ...filesToAdd] })
  }

  setNotifieringEmail = (email) => {
    this.setState({ notifieringEmail: email })
  }

  onCloseWizard = () => {
    this.fetchKompletteringAndHandleError(this.props.arendenummer)
    this.props.closeWizardFunction(this.state.isInskickad);
  }

  render () {

    const typeOptions = BIFOGADHANDLINGTYPES
      .filter(handling => handling.isValbarIKomplettering && !handling.isDeprecated)
      .sort((first, second) => first.visningsnamn.localeCompare(second.visningsnamn))
      .map(handling => ({ key: handling.value, value: handling.value, description: handling.visningsnamn })
      )

    const instructionText = (<>Välj <Term label="fil/filer" moment="help-upload" term=""/> (JPG, GIF, PNG eller PDF).
      Om du lägger till flera filer kommer de att slås ihop till en handling. <br/>
      Handlingar ska skannas och laddas upp separat - Exempelvis ett köp/bygglov/avtal etc per fil.</>)

    return (
      <>
        {!this.state.closeWizardOnSignStartError && <WizardDialog
          title={'Skicka in handling'}
          cancelFunction={() => this.onCloseWizard()}
          defaultNav={false}
          id={'BifogadeHandligarKompletteringWizardDialogId'}
          elementsToAllowFocus={['helpSidePanelId']}
          {...this.props.restWizardProps}>
          <WizardDialogStep stepTitle={'Typ av handling'}>
            <ValjTypStep1Wizard
              typeOptions={typeOptions}
              showAttachmentTypeDescription={false}
              selectAttachmentTypeCallback={this.selectType}
              setOvrigtBeskrivningCallback={this.setOvrigBeskrivning}
              ovrigtBeskrivning={this.state.ovrigtBeskrivningKomplettering}
              attachmentType={this.state.attachmentType}
              nextStepButtonText={'Välj filer'}
              nextStepButtonAriaLabel={'Gå vidare för att välja filer'}/>
          </WizardDialogStep>
          <WizardDialogStep stepTitle={'Filer'}>
            <UploadFilesStep2Wizard
              nextStepButtonText={'Ange epost'}
              instructionText={instructionText}
              selectedFiles={this.state.selectedFiles}
              selectFile={this.setSelectedFiles}
              removeSelectedFile={this.removeSelected}
              isLastStep={false}
              attachmentType={this.state.attachmentType}
              komplettering={true}
              />
          </WizardDialogStep>
          <WizardDialogStep stepTitle={'Ange epost'}>
            <EmailBifogadeHandlingarKompletteringWizard
              setNotifieringEmail={this.setNotifieringEmail}
              notifieringEmail={this.state.notifieringEmail}
              prevButtonText={'Ändra filer'}
              nextButtonText={{ text: 'Bestyrk handling' }}/>
          </WizardDialogStep>
          <WizardDialogStep stepTitle={'Bestyrk handling'}>
            <SigningHederOchSamveteKompletteringWizard
              bifogadehandlingar={this.state.selectedFiles}
              startUpload={this.startUpload}
              abortSigning={this.abortSigning}
              signingUrl={this.state.signingUrl}
              kompletteringId={this.state.kompletteringId}
              inskickadDatumCallback={this.setInskickadDatum}
              fetchSigningStatusKomplettering={this.props.fetchSigningStatusKomplettering}
              submitKomplettering={this.props.submitKomplettering}
              uploadFailed={this.state.uploadFailed}/>
          </WizardDialogStep>
          <WizardDialogStep stepTitle={'Inskickad'}>
            <KompletteringInskickadStep
                failedToSubmit={this.props.failedToSubmit}
                inskickadDatum={this.state.inskickadDatum}
                closeModal={() => {
                  this.fetchKompletteringAndHandleError(this.props.arendenummer)
                  this.props.closeWizardFunction(this.state.isInskickad);
                }}/>
          </WizardDialogStep>

        </WizardDialog>}
        {this.state.errorMessage &&
          <ModalDialogError modalTitle={this.state.errorMessage.title}
                            description={this.state.errorMessage.message}
                            button={this.state.errorMessage.button}/>}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BifogadeHandlingarKompletteringWizardUploadForm)

