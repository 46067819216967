import { initialState as arendeInitialState } from './arende'
import { initialState as arendesvarslistorInitialState } from './arendesvarslistor'

/**
 * Denna reducer behövs eftersom vi använder paketet reduce-reducers (se reducer.js) och kombinerar två
 * reducers (arende + arendesvarslistor).
 *
 * Första och viktigaste anledningen till att den behövs är för att en action som kastas (t ex arende/UNLOAD i detta fall)
 * fångas av båda reducers som är kombinerade av reduceReducers, men redux får bara state
 * från en av dem. Om man fångar UNLOAD i arende och arendesvarslistor kommer redux-statet att sakna variabler från
 * den ena av dem, trots att båda kastar rätt initialState. (Begränsning/bug i reduce-reducers?)
 *
 * Andra anledningen är för att det är bekvämt att slå ihop initialState från de kombinerade reducers så man kan
 * ange detta i reduceReducers i reducer.js. Detta kunde man lösa utan denna reducer, men det är en bonus.
 */

export const UNLOAD = 'arende/UNLOAD'

export const initialState = {
  ...arendeInitialState,
  ...arendesvarslistorInitialState
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case UNLOAD:
      return initialState
    default:
      return state
  }
}

export function unloadArende () {
  return {
    type: UNLOAD,
  }
}