import PropTypes from 'prop-types'
import {
  ANSOKAN_SIGNING_STATUS_NOT_STARTED,
  ANSOKAN_STATUS_REVIEW_EMAILS,
  ANSOKAN_STATUS_SIGNED,
  ANSOKAN_STATUS_SIGNING
} from 'utils/globalConstants'
//@ts-ignore
import { Column, VarningsRuta } from 'redet-react-components'

type Props = {
  ansokanStatus: string
}

export default function SigningText (props: Props) {
  let introduction
  let description = undefined
  switch (props.ansokanStatus) {
    case ANSOKAN_SIGNING_STATUS_NOT_STARTED:
      introduction = 'När du bestyrkt dina handlingar kan du gå vidare och skriva under ansökan och bjuda in eventuella medsökande.'
      description = 'När samtliga inbjudna har skrivit under kan du skicka in ansökan. Om underskrift ' +
        'och inskick av ansökan inte sker inom 30 dagar blir samtliga underskrifter ogiltiga och du måste börja om med ny inbjudan.'
      break
    case ANSOKAN_STATUS_REVIEW_EMAILS:
      introduction = 'När inbjudan har skickats får de inbjudna möjlighet att skriva under ansökan.'
      description = 'När samtliga inbjudna har skrivit under kan du skicka in ansökan. Om underskrift och inskick av ' +
        'ansökan inte sker inom 30 dagar blir samtliga underskrifter ogiltiga och du måste börja om med ny inbjudan.'
      break
    case ANSOKAN_STATUS_SIGNING:
      introduction = 'När ansökan är underskriven kan du skicka in den. Inskickandet av ansökan måste göras av dig och ' +
        'sker inte automatiskt.'
      break
    case ANSOKAN_STATUS_SIGNED:
      introduction = 'När du skickat in din ansökan kommer du få en kvittens/bekräftelse på skärmen och via e-post. ' +
        'Tänk på att kvittensnumret endast gäller som referens tills dess att du har fått ett ärendenummer.'
      break
    default: {
      introduction = undefined
      description = undefined
    }
  }
  return (
    <div className="row md-equal-heights">
      <Column columns="col-xs-12 col-md-12">
        <VarningsRuta header={{ text: 'Vad är nästa steg?', headerLevel: 2, lookLike: 4 }}
                      intro={introduction}
                      description={description} ariaTitle={"Information om nästa steg"}/>
      </Column>
    </div>
  )
}

