import {  Dispatch, useEffect, useState } from "react";
import { TextAreaFinalForm } from '../../General/forms/finalForm'
//@ts-ignore
import { Column, FormFieldset, Row, PageSubContent } from 'redet-react-components'
import { Form } from 'react-final-form'
import { initFormData } from 'redux/finalForm'
import FormStateToRedux from 'redux/FormStateToRedux'
import { connect } from 'react-redux'
import { formProperties } from 'redux/formProperties'
import { createValidator, required } from 'utils/validation'

type Props = {
  initFormData: (atgard: string) => {}
}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: any) => {
    return { initFormData: (formField: string) => ownProps.initFormData || dispatch(initFormData(formField)) }
}

export let validateGransUtmarkningForm = function (values: Record<string, any>) {
    return createValidator(values,
      {
          gransBeskrivning: [required]
      })
}

function GransUtmarkningForm(props: Props) {

  const [_formDataLoaded, setFormDataLoaded] = useState(false);
  const [_initData, setInitData] = useState({});

  useEffect(() => {
    const res = props.initFormData(formProperties.gransutmarkning.name)
    setFormDataLoaded(true);
    setInitData(res);
  }, [])

    return (
          <PageSubContent title={"Särskild gränsutmärkning"} headerLevel={4} id="fastighetsforandring-gransutmarkning" headerId="fastighetsforandring-gransutmarkningHeader">
            <br/>
            <Form
              onSubmit={() => {}}
              initialValues={_initData}
              subscription={{ submitting: true, pristine: true }}
              validate={values => validateGransUtmarkningForm(values)}>
              {() => {
                return (
                  <form onSubmit={() => {}}>
                      {_formDataLoaded && <FormStateToRedux form={formProperties.gransutmarkning.name}/>}
                    <Row>
                      <Column columns={'col-xs-12 col-md-6'}>
                        <FormFieldset
                          description={"Ange vilken gräns som ska märkas ut tydligare eller vilka gränsmarkeringar som ska sättas ut (du kan även rita i kartskissen)."}>
                          <TextAreaFinalForm id={'gransBeskrivningId'} field={'gransBeskrivning'} label={'Beskrivning'}
                                             mandatory={true} characterLimit={4000}/>
                        </FormFieldset>
                      </Column>
                    </Row>
                  </form>
                )
              }}
            </Form>
          </PageSubContent>
  )
}

export default connect(undefined, mapDispatchToProps)(GransUtmarkningForm)
