import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addMap } from 'redux/modules/map'
import { getKartskisserList, setUserChooseNotToAddaKartskiss } from 'redux/modules/kartskiss'
import { addProgress, endProgress } from 'redux/modules/fullscreenLoading'
import { PageWizardNavigationToolbar } from 'components/General/PageWizardNavigationToolbar'
import {
  closeUploadForm,
  displayUploadForm,
  fetchBifogadKartskiss,
  fetchBifogadKartskissList
} from 'redux/modules/bifogadKartskiss'
import { getBifogadHandlingQuotaForAnsokan } from 'redux/modules/bifogadHandling'
import BifogadKartskissWizardUploadForm from './BifogadKartskissWizardUploadForm'
import { visaMoment } from '../../redux/modules/help'
import { scrollToMainTag } from '../../utils/scrollToElement'
import { MAX_NUMBER_OF_MAPS, NO_SCROLLING_CSS_CLASS } from '../../utils/globalConstants'
import {
  ButtonToolbar,
  ButtonTop,
  Checkbox,
  ExternalLink,
  ModalDialogSub,
  PageContentHeader,
  Row,
  VarningsRuta
} from 'redet-react-components'
import Term from '../Hjalp/TermLank'
import { removeBifogadKartskiss } from '../../redux/modules/bifogadKartskiss'
import { removeKartskiss } from '../../redux/modules/kartskiss'
import { SmallLoading } from 'components'
import {ValidationError} from '../index'
import KartskissLista from "./KartskissLista";

const mapStateToProps = (store) => {
  return {
    isUploadFormVisible: store.bifogadKartskiss.isUploadFormVisible,
    ritadeKartskisser: store.kartskiss.list,
    bifogadeKartskisser: store.bifogadKartskiss.list,
    hasUserChosenNotToAddAkartskiss: store.kartskiss.hasUserChosenNotToAddAkartskiss,
    isLoadingRitadeKartskisserList: store.kartskiss.isLoadingList,
    isLoadingBifogadKartskisserList: store.bifogadKartskiss.isLoadingList
  }
}

const mapDispatchToProps = {
  fetchBifogadKartskissList,
  displayUploadForm,
  addProgress,
  endProgress,
  addMap,
  getKartskisserList,
  fetchBifogadKartskiss,
  getBifogadHandlingQuotaForAnsokan,
  closeUploadForm,
  visaHjalpAvsnitt: visaMoment,
  setUserChooseNotToAddaKartskiss,
  removeBifogadKartskiss,
  removeKartskiss,
}

export class Kartskiss extends Component {
  static propTypes = {
    // Props
    ansokanId: PropTypes.string.isRequired,
    isUploadFormVisible: PropTypes.bool.isRequired,
    ritadeKartskisser: PropTypes.array.isRequired,
    bifogadeKartskisser: PropTypes.array.isRequired,
    hasUserChosenNotToAddAkartskiss: PropTypes.bool.isRequired,
    isLoadingRitadeKartskisserList: PropTypes.bool.isRequired,
    isLoadingBifogadKartskisserList: PropTypes.bool.isRequired,

    // Action creators
    visaHjalpAvsnitt: PropTypes.func.isRequired,
    fetchBifogadKartskissList: PropTypes.func.isRequired,
    displayUploadForm: PropTypes.func.isRequired,
    addProgress: PropTypes.func.isRequired,
    endProgress: PropTypes.func.isRequired,
    getKartskisserList: PropTypes.func.isRequired,
    addMap: PropTypes.func.isRequired,
    closeUploadForm: PropTypes.func.isRequired,
    removeBifogadKartskiss: PropTypes.func.isRequired,
    removeKartskiss: PropTypes.func.isRequired,

    // Check quota for bifogade handlingar
    getBifogadHandlingQuotaForAnsokan: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    hasUserChosenNotToAddAkartskiss: false
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      showNoKartskissWarningModal: false,
      showDeleteKartskissModal: false,
      showTooManyKartskisserModal: false,
      showTotalSizeOfKartskisserExceededModal: false,
      allowedSizeOfKartskisser: undefined
    }
    this.createNewMap = this.createNewMap.bind(this)
    this.fetchBifogadeKartskisser = this.fetchBifogadeKartskisser.bind(this)
    this.createNewBifogadKartskiss = this.createNewBifogadKartskiss.bind(this)
    this.onClickNoKartskissButton = this.onClickNoKartskissButton.bind(this)
  }

  componentDidMount () {
    this.props?.visaHjalpAvsnitt('help-map', 'Kartskisser')
    this.fetchKartskisser().then(() => this.fetchBifogadeKartskisser())
    scrollToMainTag()
  }

  fetchKartskisser () {
    return this.props.getKartskisserList(this.props.ansokanId)
  }

  fetchBifogadeKartskisser () {
    return this.props.fetchBifogadKartskissList(this.props.ansokanId)
  }

  createNewMap () {
    if (this.numberOfKartskisserCreated() < this.maxNumberOfMaps) {
      this.props.addMap()
      this.props.navigate("/privat/ansokan/" + this.props.ansokanId + "/skapakartskiss");
    } else {
      this.displayTooManyKartskisserModalDialog()
    }
  }

  displayTooManyKartskisserModalDialog () {
    this.showTooManyKartskisserModal()
  }

  createNewBifogadKartskiss () {
    if (this.numberOfKartskisserCreated() < this.maxNumberOfMaps) {
      document.body.classList.add(NO_SCROLLING_CSS_CLASS)

      const successCallback = (quotaReport) => {
        this.props.endProgress()

        if (quotaReport.remainingSize <= 0) {
          this.showTotalSizeOfKartskisserExceededModal(true, quotaReport.allowedSize)
          return
        }
        this.props.displayUploadForm()
      }
      this.props.addProgress(false)
      this.props.getBifogadHandlingQuotaForAnsokan(this.props.ansokanId)
        .then((quotaReport) => {
          setTimeout(() => successCallback(quotaReport), 500) // Success
        }).catch(() => {
        this.props.endProgress() // Fail
      })
    } else {
      this.displayTooManyKartskisserModalDialog()
    }
  }

  numberOfKartskisserCreated () {
    return this.props.ritadeKartskisser.length + this.props.bifogadeKartskisser.length
  }

  onClickNoKartskissButton () {
    if (this.props.hasUserChosenNotToAddAkartskiss) {
      this.props.setUserChooseNotToAddaKartskiss(false)
    } else {
      this.setState({ showNoKartskissWarningModal: true })
    }
  }

  noBifogadHandligWarningModalChoice (choice) {
    this.setState({ showNoKartskissWarningModal: false })
    if (choice) {
      this.props.setUserChooseNotToAddaKartskiss(true)
    }
  }

  showDeleteKartskissModal = ({ show = true, ritadKartskissId = undefined, bifogadKartskissId = undefined }) => {
    this.setState({
      showDeleteKartskissModal: show,
      ritadKartskissIdToBeDeleted: ritadKartskissId,
      bifogadKartskissIdToBeDeleted: bifogadKartskissId
    })
  }

  deleteRitadKartskiss = (id) => {
    this.showDeleteKartskissModal({ ritadKartskissId: id })
  }

  deleteBifogadKartskiss = (id) => {
    this.showDeleteKartskissModal({ bifogadKartskissId: id })
  }

  deleteKartskiss = () => {
    if (this.state.ritadKartskissIdToBeDeleted !== undefined) {
      this.props.removeKartskiss(this.props.ansokanId, this.state.ritadKartskissIdToBeDeleted)
    } else if (this.state.bifogadKartskissIdToBeDeleted) {
      this.props.removeBifogadKartskiss(this.props.ansokanId, this.state.bifogadKartskissIdToBeDeleted)
        .then(() => {
          this.props.fetchBifogadKartskissList(this.props.ansokanId)
        })
    }
  }

  showTooManyKartskisserModal = (show = true) => {
    this.setState({ showTooManyKartskisserModal: show })
  }

  showTotalSizeOfKartskisserExceededModal = (show = true, allowedSize = undefined) => {
    this.setState({
      showTotalSizeOfKartskisserExceededModal: show,
      allowedSizeOfKartskisser: allowedSize
    })
  }

  maxNumberOfMaps = 10

  render () {
    const hasRitadKartskiss = this.props.ritadeKartskisser?.length > 0
    const hasBifogadKartskiss = this.props.bifogadeKartskisser?.length > 0
    const isLoading = this.props.isLoadingRitadeKartskisserList || this.props.isLoadingBifogadKartskisserList

    const ritaEgenKartskissKnapptext = 'Rita en egen kartskiss'
    const laddaUppEgenKartskissKnapptext = 'Ladda upp en befintlig kartskiss'
    return (
      <>
        <div id="section-kartskiss">
          {this.props.isUploadFormVisible && <BifogadKartskissWizardUploadForm ansokanId={this.props.ansokanId}
                                                                               reloadBifogadKartskissList={this.fetchBifogadeKartskisser}
                                                                               closeUploadForm={this.props.closeUploadForm}/>}
          <PageContentHeader title={'Lägg till en kartskiss'}
                             headerLevel={2}
                             headerId={'nyKartskissHeaderId'}
                             description={<>Bifoga en karta som visar de fastigheter som berörs.
                               <br/>
                               <br/>
                               Klicka på <strong>{ritaEgenKartskissKnapptext}</strong> för att rita en karta.
                               Om det skapas nya gränser så rita in dessa.
                               Rita också in eventuella servitut.<br/> Du kan också vid behov skriva in förklarande
                               text.
                               Tänk på att fastighetsgränserna inte alltid visar rätt och de är inte juridiskt gällande,<br/>
                               se ytterligare information om korrekta gränser på&nbsp;
                               <ExternalLink text={'Lantmäteriets hemsida'} openInNewTab={true}
                                             url={'https://www.lantmateriet.se/korrektagranser'}/>
                               <br/>
                               <br/>
                               Klicka på <strong>{laddaUppEgenKartskissKnapptext}</strong> om du redan har en färdig
                               karta som du vill
                               bifoga ansökan.</>}>
            <ButtonToolbar>
              <ButtonTop text={ritaEgenKartskissKnapptext}
                         icon={'add'}
                         onClick={this.createNewMap}
                         data-selenium-id="laggTillKartskiss"
                         id={'createNewKartskissButton'}
                         isDisabled={this.props.hasUserChosenNotToAddAkartskiss}/>
              <ButtonTop text={laddaUppEgenKartskissKnapptext}
                         id="open_bifogad_kartskiss_wizard_button"
                         icon={'attachment'}
                         onClick={this.createNewBifogadKartskiss}
                         data-selenium-id="laggTillKartskiss"
                         isDisabled={this.props.hasUserChosenNotToAddAkartskiss}/>
            </ButtonToolbar>
            <Checkbox label={'Jag behöver inte lägga till någon kartskiss'}
                      checked={this.props.hasUserChosenNotToAddAkartskiss}
                      onChange={this.onClickNoKartskissButton}
                      isDisabled={hasRitadKartskiss || hasBifogadKartskiss}
                      id={'noKartskissCheckButtonId'}/>
          </PageContentHeader>
          <br/>
          {(hasRitadKartskiss || hasBifogadKartskiss) && !isLoading &&
            <Row role="list" aria-label={"Kartskisser"}>
              <KartskissLista ansokanId={this.props.ansokanId}
                              deleteRitadKartskissCallback={this.deleteRitadKartskiss}
                              deleteBifogadKartskissCallback={this.deleteBifogadKartskiss}
                              displayTooManyKartskisserModalDialogCallback={this.showTooManyKartskisserModal}/>
            </Row>
          }
          {isLoading &&
            <div className="with-top-margin with-bottom-margin"><SmallLoading text="Laddar kartskisser..."/></div>}
          <ValidationError tag={'kartskiss'}/>
          {!hasRitadKartskiss && !hasBifogadKartskiss && !isLoading &&
            <VarningsRuta intro={
              <>
                Du har inte lagt till någon kartskiss än, du kan antingen rita en kartskiss själv med
                hjälp av funktionen <em>{ritaEgenKartskissKnapptext}</em> eller använda
                funktionen <em>{laddaUppEgenKartskissKnapptext}</em>.<br/><br/>
                Ifall du inte lägger till någon kartskiss kan du bli tvungen att komplettera din ansökan i ett senare
                skede,
                vilket kan medföra längre handläggningstider med ev. ökad kostnad som följd.<br/><br/>
                Du behöver inte ladda upp en kartskiss som redan finns i t.ex. köpehandling eller överenskommelse
                som bifogas till ansökan.
              </>
            }
            ariaTitle='Information om att användaren inte lagt till någon kartskiss'
            />
          }
          {(hasRitadKartskiss || hasBifogadKartskiss) &&
            <VarningsRuta intro={'Observera att det endast är vad som visas på kartskisserna ovan som ingår i ' +
              'ansökan och som kommer att skickas in. Se till att dessa tydligt visar vad du vill genomföra.'}
              ariaTitle='Information om kartskisser i ansökan'
              />
          }
          <PageWizardNavigationToolbar buttonLeftText={'Beskriv vad du vill göra'}
                                       buttonLeftLink={'../forandringar'}
                                       buttonRightText={'Kontaktuppgifter och kostnader'}
                                       buttonRightLink={'../kontakter'}/>
        </div>
        {this.state.showNoKartskissWarningModal &&
          <NoKartskissWarningModal closeModalCallback={() => this.noBifogadHandligWarningModalChoice(false)}
                                   onClickJaKnapp={() => this.noBifogadHandligWarningModalChoice(true)}/>}
        {this.state.showDeleteKartskissModal &&
          <RemoveKartskissModal closeModalCallback={() => this.showDeleteKartskissModal({ show: false })}
                                onClickJaKnapp={this.deleteKartskiss}/>
        }
        {this.state.showTooManyKartskisserModal &&
          <TooManyKartskisserModal closeModalCallback={() => this.showTooManyKartskisserModal(false)}/>}
        {this.state.showTotalSizeOfKartskisserExceededModal &&
          <TotalSizeOfKartskisserExceededModal allowedSizeOfKartskisser={this.state.allowedSizeOfKartskisser}
                                               closeModalCallback={() => this.showTotalSizeOfKartskisserExceededModal(false)}/>}
      </>
    )
  }
}

export function TotalSizeOfKartskisserExceededModal ({ allowedSizeOfKartskisser, closeModalCallback }) {
  return <ModalDialogSub modalTitle={'Du får inte lägga till fler kartskisser'}
                         description={`Du har överskridit gränsen för den sammanlagda storleken av bifogade handlingar/kartskisser i en ansökan 
                           som är ${allowedSizeOfKartskisser / 1024 / 1024}MB`}
                         closeModalCallback={closeModalCallback}/>
}

export function TooManyKartskisserModal ({ closeModalCallback }) {
  return <ModalDialogSub modalTitle="För många kartskisser"
                         closeModalCallback={closeModalCallback}
                         description={`Du kan endast ha ${MAX_NUMBER_OF_MAPS} kartskisser. För att skapa en ny måste du 
                                först ta bort en befintlig.`}/>
}

export function RemoveKartskissModal ({ closeModalCallback, onClickJaKnapp }) {
  return <ModalDialogSub modalTitle={'Ta bort kartskiss'}
                         description={<>Tänk på att du inte kan ångra att ta bort kartskissen.
                           <br/><br/>
                           Vill du ta bort kartskissen?</>}
                         closeModalCallback={closeModalCallback}
                         acceptButton={{ text: 'Ja', onClick: onClickJaKnapp }}
                         declineButton={{ text: 'Nej' }}/>
}

export function NoKartskissWarningModal ({ closeModalCallback, onClickJaKnapp }) {
  return <ModalDialogSub modalTitle={'Är du helt säker på att du inte ska ladda upp/skapa en kartskiss?'}
                         ingress={'För att din ansökan ska vara så komplett som möjlig kan du lägga till en kartskiss.'}
                         description={<>Du behöver inte ladda upp en kartskiss som redan finns i t.ex. köpehandling
                           eller överenskommelse
                           som bifogas till ansökan.
                           <br/>
                           <br/>
                           Ifall du inte lägger till någon kartskiss kan du bli tvungen att komplettera din ansökan i
                           ett senare skede,
                           vilket
                           kan medföra längre handläggningstider med ev. ökad kostnad som följd. Är du osäker på vad du
                           ska
                           lägga till kan du kontakta <Term label={'kundcenter'} moment={'help-support'}/>.</>}
                         closeModalCallback={closeModalCallback}
                         acceptButton={{ text: 'Ja, jag är säker', onClick: onClickJaKnapp }}
                         declineButton={{ text: 'Nej' }}
                         id={'showNoKartskissWarningModalId'}
                         elementsToAllowFocus={['helpSidePanelId', 'validationSidePanelId']}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(Kartskiss)
