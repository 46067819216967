import BestyrkanWizardModal from "components/General/BestyrkanWizardModal";
import {useState} from "react";
// @ts-ignore
import {ButtonGroup, ButtonToolbarV2} from "redet-react-components";

const {
    ButtonSub,
    Checkbox,
    CircleLoading,
    ModalDialogSub,
} = require("redet-react-components");

type Props = {
    closeModalCallback: () => void;
    initiateHandlingarSigningCallback: () => void;
    handlingarSigneringWebUrl: string;
    isSigneringPending: boolean;
    attachCircleLoadingToDomElementWithId: string;
};

export function HederOchSamveteModal(props: Props) {
    const [hasConfirmedHandlingarPreview, setHasConfirmedHandlingarPreview] =
        useState(false);
    const [hasClickedFortsatt, setHasClickedFortsatt] = useState(false);

    const showStart = !hasClickedFortsatt && !props.isSigneringPending;
    const showLoading = hasClickedFortsatt && !props.isSigneringPending;
    const showWaitForBestyrka = props.isSigneringPending;

    function onClickFortsatt() {
        setHasClickedFortsatt(true);
        props.initiateHandlingarSigningCallback();
    }

    function onChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
        if (!hasClickedFortsatt) {
            setHasConfirmedHandlingarPreview(event.target.checked);
        }
    }

    return (
        <>
            {showStart && (
                <ModalDialogSub
                    id={"phosmodal"}
                    modalTitle={"Bestyrk handlingar"}
                    closeModalCallback={props.closeModalCallback}
                    useDefaultToolbar={false}
                >
                    <>
                        <p className="introduction">
                            Du kommer nu försäkra på heder och samvete att samtliga handlingar
                            överensstämmer med originalet. Obs! Att försäkra något på heder
                            och samvete som inte är sant kan leda till straffrättsligt ansvar.
                        </p>
                        <Checkbox
                            label={"Jag har förhandsgranskat mina handlingar."}
                            name={"confirmHandlingarPreviewCheckbox"}
                            id={"confirmHandlingarPreviewCheckboxId"}
                            isChecked={hasConfirmedHandlingarPreview}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                onChangeCheckbox(event)
                            }
                        />
                        <ButtonToolbarV2>
                            <ButtonGroup>
                                <ButtonSub
                                    text={"Stäng"}
                                    icon={"clear"}
                                    onClick={props.closeModalCallback}
                                />
                            </ButtonGroup>
                            <ButtonGroup>
                                <ButtonSub
                                    text={"Fortsätt"}
                                    id={"phosModalToStepTwoButton"}
                                    isLoading={hasClickedFortsatt}
                                    isDisabled={
                                        hasClickedFortsatt || !hasConfirmedHandlingarPreview
                                    }
                                    onClick={
                                        hasConfirmedHandlingarPreview ? onClickFortsatt : undefined
                                    }
                                    icon={"arrow_forward"}
                                />
                            </ButtonGroup>
                        </ButtonToolbarV2>
                    </>
                </ModalDialogSub>
            )}
            {showLoading && (
                <CircleLoading
                    isFullscreen={true}
                    isFocusTrapped={false}
                    text={"Förbereder ..."}
                    attachToDomElementWithId={props.attachCircleLoadingToDomElementWithId}
                />
            )}
            {showWaitForBestyrka && (
                <BestyrkanWizardModal
                    closeModalCallback={props.closeModalCallback}
                    bestyrkanUrl={props.handlingarSigneringWebUrl}
                    attachToDomElementWithId={props.attachCircleLoadingToDomElementWithId}
                ></BestyrkanWizardModal>
            )}
        </>
    );
}
