import React from 'react'
import { Term } from 'views'
import AddressForm from '../Common/AddressForm'
import { CheckboxFinalForm, Condition, Subscribe, TextInputFinalForm } from '../../General/forms/finalForm'
import { FormFieldset } from 'redet-react-components'

export default function OrganizationAddressForm () {
  return (
    <>
      <FormFieldset title="Adress">
        <Subscribe fieldName={'rollFakturamottagare'}>
          {(props) => {
            const fakturaMottagareChecked = props.input.value
            return (
              <>
                <AddressForm fields={{ adress: 'adress', postnummer: 'postnummer', postort: 'postort', land: 'land' }}
                             fieldsAreNotRequired={!fakturaMottagareChecked}/>
                <CheckboxFinalForm label={'Ange en alternativ adress'} field={'alternativAdressAngiven'}
                                   term={<Term label="alternativa adresser" moment="help-contactinformation"
                                               term="term-alternativAdress"/>}
                                   data-selenium-id={'alternativAdressAngiven'}/>
              </>
            )
          }}
        </Subscribe>
      </FormFieldset>

      <Condition when={'alternativAdressAngiven'} is={true}>
        <Subscribe fieldName={'alternativAdressAngiven'}>
          {(props) => {
            const alternativAdressAngiven = props.input.value
            return (
              <FormFieldset title="Alternativ adress" description="T.ex. arbete, för delgivning eller liknande"
                            titleId="alternativAdressSection">
                <AddressForm fields={{
                  adress: 'adressAlternativ',
                  postnummer: 'postnummerAlternativ',
                  postort: 'postortAlternativ',
                  land: 'landAlternativ'
                }} fieldsAreNotRequired={!alternativAdressAngiven}/>
                <TextInputFinalForm field={'kontaktKommentar'} label={'Kommentar'} maxLength={100}/>
              </FormFieldset>
            )
          }}
        </Subscribe>
      </Condition>
    </>
  )
}
