import { maxLength, nonEmptyArray, oneMustBeTrue, required } from 'utils/validation'
import { SokandeCheckboxMustBeTrueIfContactHasOmbud } from '../../../utils/contactsValidationsHelper'

export const noRoleSelectedErrorMessage = 'Du måste ange minst en roll'
export const noSokandeSelectedForOmbudErrorMessage = 'En företrädare måste ha rollen sökande'
export const noSokandeSelectedForForetraddErrorMessage = 'Personer som företräds av någon annan måste ha rollen sökande'
export const noSokandeSelectedForForetraddOrganizationErrorMessage = 'Organisationer som företräds av någon annan måste ha rollen sökande'

const rollRule = oneMustBeTrue(['rollUpprattare', 'rollSokande', 'rollAnnanBerord', 'rollFakturamottagare', 'rollAktmottagare', 'rollOmbud'], noRoleSelectedErrorMessage)
export default function commonContactFieldsValidations (formValues, contactList, contactId, ansokanValidations) {
  const rules = {
    adress: [maxLength(100)],
    postnummer: [maxLength(10)],
    postort: [maxLength(100)],
    land: [maxLength(35)],
    adressAlternativ: [maxLength(100)],
    postnummerAlternativ: [maxLength(10)],
    postortAlternativ: [maxLength(100)],
    landAlternativ: [maxLength(100)],
    kontaktKommentar: [maxLength(100)],
    rollSokande: [rollRule],
    rollAnnanBerord: [rollRule],
    rollFakturamottagare: [rollRule],
    rollAktmottagare: [rollRule],
    rollUpprattare: [rollRule],
    rollOmbud: [rollRule],
    representerarFastigheter: []
  }

  const validations = { ...rules }
  if (formValues !== undefined && formValues.rollFakturamottagare) {
    validations.adress.push(required)
    validations.postnummer.push(required)
    validations.postort.push(required)
  }
  if (formValues.rollSokande && formValues.optOutFromRepresentingFastighet !== true && (formValues.representerarFastigheter === undefined || formValues.representerarFastigheter.length < 1)) {
    // Kolla om ansökan innehåller ett övergripande valideringsfel "MINST EN FASTIGHET" så att valideringen nedan blir användarvänlig
    const MINST_EN_FASTIGHET_ERROR = Array.isArray(ansokanValidations) ? ansokanValidations.filter(error => error.id === 'MINST_EN_FASTIGHET') : []
    if (MINST_EN_FASTIGHET_ERROR.length === 0) {
      validations.representerarFastigheter.push(nonEmptyArray('Ett val måste göras för kontaktuppgifter med rollen sökande'))
    }
  }

  //Sätter roll sökande som obligatoriskt när kontakten är företrädd av ett ombud (gäller både privatperson och org)
  SokandeCheckboxMustBeTrueIfContactHasOmbud(formValues, contactList, contactId, validations)

  return validations
}