import {ANSOKAN_LOAD_SUCCESSFUL} from "../ReduxDataLoaderReducer";

const initialState = {list: []}

export default function reducer(state: any = initialState, action: { type: string, result?: any, }) {
    if (action.type === ANSOKAN_LOAD_SUCCESSFUL) {
        return {
            ...state,
            list: action.result.data.handlingar,
        }
    } else {
        return state
    }
}