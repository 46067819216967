import * as interactions from '../interactions'
import PropTypes from 'prop-types'
import React from 'react'
import { MapButton } from 'redet-react-components'

export function DrawToolsMenu (props) {
  return (
    <>
      <MapButton
        text={'Punkt'}
        icon={'fiber_manual_record'}
        isActive={props.activeTool === interactions.POINT}
        id="mapToolbarToolPoint"
        onClick={() => props.onClickToolCallback(interactions.POINT)}/>
      <MapButton
        text={'Linje'}
        onClick={() => props.onClickToolCallback(interactions.LINE_STRING)}
        id="mapToolbarToolLineString"
        isActive={props.activeTool === interactions.LINE_STRING}
        icon={'show_chart'}/>
      <MapButton
        onClick={() => props.onClickToolCallback(interactions.POLYGON)}
        text={'Yta'}
        icon={'network_cell'}
        type="button"
        id="mapToolbarToolPolygon"
        isActive={props.activeTool === interactions.POLYGON}/>
      <MapButton
        onClick={() => props.onClickToolCallback(interactions.TEXT)}
        type="button"
        id="mapToolbarToolText"
        text={'Text'}
        icon={'font_download'}/>
    </>
  )
}

DrawToolsMenu.propTypes = {
  activeTool: PropTypes.string,
  onClickToolCallback: PropTypes.func
}