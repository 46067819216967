import Log from 'utils/log'
import { shiftKeyOnly, singleClick } from 'ol/events/condition'
import { Modify } from 'ol/interaction'
import { unByKey } from 'ol/Observable'

export default class ModifyInteraction {
  constructor (ol, mapController, featureHandler, setCursorFunc, interactionDestructors) {
    this.log = new Log(this.constructor.name)
    this.ol = ol
    this.mapController = mapController
    this.featureHandler = featureHandler
    this.setCursorFunc = setCursorFunc
    this.interactionDestructors = interactionDestructors
  }

  init () {
    const interaction = new Modify({
      features: this.featureHandler.getDrawingFeatures(),
      // the SHIFT key must be pressed to delete vertices, so
      // that new vertices can be drawn at the same position
      // of existing vertices
      deleteCondition: (event) => {
        return shiftKeyOnly(event) && singleClick(event)
      }
    })

    const start = interaction.on('modifystart', () => {
    })

    const end = interaction.on('modifyend', () => {
    })

    this.interactionDestructors.push(() => {
      this.log.debug('Unregister MODIFY interaction')
      unByKey(start)
      unByKey(end)
      this.mapController.getMap().removeInteraction(interaction)
      this.setCursorFunc('auto')
    })

    this.log.debug('Register MODIFY interaction')
    this.mapController.getMap().addInteraction(interaction)

    this.setCursorFunc('pointer')

    return interaction
  }
}
