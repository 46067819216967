import {v4 as uuidv4} from 'uuid'
//@ts-ignore
import {ButtonTop, Column, Row} from 'redet-react-components'
import {useParams} from "react-router-dom";

function LocalLogin() {
    const params = useParams()
    const dedikeradForKomplettering = 'Dedikerad för komplettering'
    const testUsersIndividuals = [
      {
        personId: '191111111116',
        namn: 'Tian Elvansson',
        fornamn: 'Tian',
        efternamn: 'Elvansson',
        beskrivning: 'Äger 24 fastigheter'
      },
      {
        personId: '191010101010',
        namn: 'lantt016',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: 'Äger ingen fastighet'
      },
      {
        personId: '191010108874',
        namn: 'lantt027',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: 'Äger 25 extrema fastigheter'
      },
      {
        personId: '191010107439',
        namn: 'lantt028',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: 'Äger 9 fastigheter'
      },
      {
        personId: '191010103479',
        namn: 'lantt029',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: 'Äger 3 fastigheter'
      },
      {
        personId: '191010102299',
        namn: 'lantt031',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: 'Äger 2 fastigheter'
      },
      {
        personId: '191010102323',
        namn: 'lantt041',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: dedikeradForKomplettering
      },
      {
        personId: '191010104287',
        namn: 'lantt043',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: dedikeradForKomplettering
      },
      {
        personId: '191010104899',
        namn: 'lantt052',
        fornamn: 'lantt052', // Vi har assert på detta i enak-komplettering-systemtest för Skrivelse, så förnamn måste vara detta på denna användare.
        efternamn: 'Användare',
        beskrivning: 'Dedikerad för skrivelse'
      },
      {
        personId: '193802278485',
        namn: 'lantt054',
        fornamn: 'lantt052', // Vi har assert på detta i enak-komplettering-systemtest för Skrivelse, så förnamn måste vara detta på denna användare.
        efternamn: 'Användare',
        beskrivning: 'Dedikerad för skrivelse'
      },
      {
        personId: '199012142387',
        namn: 'Kickan Cavallin',
        fornamn: 'Kickan',
        efternamn: 'Cavallin',
        beskrivning: 'Har 108 pågående ärenden i Trossen'
      },
      {
        personId: '199206052384',
        namn: 'Margareta Nytröen',
        fornamn: 'Margareta',
        efternamn: 'Nytröen',
        beskrivning: 'Har 41 pågående ärenden i Trossen'
      },
      {
        personId: '199204052394',
        namn: 'Okänd testdataanvändare',
        fornamn: 'Test',
        efternamn: 'Användare',
        beskrivning: 'Har ett pågående ärenden i Trossen'
      },
    ]
    const testUsersOrganization = [
        {
            personId: '199803279950',
            namn: 'LantmaterietOrgUser',
            beskrivning: 'Konto bundet mot Lantmateriets organisationsnummer',
            org: '2021004888'
        },
        {
            personId: '193905224188',
            namn: 'lantt998',
            beskrivning: 'Konto bundet mot ett icke existerande organisationsnummer',
            org: '9999999999'
        },
        {personId: '191010105201', namn: 'lantt051', beskrivning: dedikeradForKomplettering, org: '2021004888'},
    ]
    const testUsersSDG = [
      {
        personId: 'XA/SE/193911137077',
        namn: 'Brüno Gehard',
        fornamn: 'Brüno',
        efternamn: 'Gehard',
        dateOfBirth: "1939-11-13",
        beskrivning: 'Österrikare med eidasPersonIdentifier',
      }
    ]
    const loginUrl = 'http://localhost:9080/actuator/iammock/login'
    const typAvInloggning = params.type

    return (
      <div>
        <h1>Logga in</h1>
        {typAvInloggning === 'privat' && <div>
          <h3>Logga in som privatperson</h3>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
              <tr>
                <th>Namn</th>
                <th>Personnummer</th>
                <th>Beskrivning</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {testUsersIndividuals.map((user) =>
                <tr key={user.personId}>
                  <td>{user.namn}</td>
                  <td>{user.personId}</td>
                  <td>{user.beskrivning}</td>
                  <td>
                    <form id={user.personId} action={loginUrl}>
                      <input type="hidden" name="workforceid" value={user.personId}/>
                      <input type="hidden" name="fullName" value={user.namn}/>
                      <input type="hidden" name="fornamn" value={user.fornamn}/>
                      <input type="hidden" name="efternamn" value={user.efternamn}/>
                      <input type="hidden" name="randomNumberToAvoidCacheForPriv" value={uuidv4()}/>
                      <input type="hidden" name="cn" value={user.personId}/>
                      <input type="hidden" name="role" value="privatperson"/>
                      <input type="hidden" name="redirect" value="privat"/>
                      <ButtonTop text={'Logga in'} type="submit" form={user.personId}/>
                    </form>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
          <h3>Logga in manuellt som privatperson</h3>
          <form id="loginForm" action="http://localhost:9080/actuator/iammock/login" autoComplete="on">
            <input type="hidden" name="role" value="privatperson"/>
            <div className="container">
              <Row>
                <Column columns="col-md-4">
                  <div className="form-group">
                    <input className="form-control" type="text" data-selenium-id="personnummer"
                           placeholder={'Personnummer *'} name="workforceid" autoComplete="on"/>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column columns="col-md-4">
                  <div className="form-group">
                    <input className="form-control" type="text" data-selenium-id="name"
                           placeholder={'Fullständigt namn *'} name="fullName" autoComplete="on"/>
                  </div>
                </Column>
              </Row>
                <Row>
                    <Column columns="col-md-4">
                        <div className="form-group">
                            <input className="form-control" type="text" data-selenium-id="fornamn"
                                   placeholder={'Förnamn'} name="fornamn" autoComplete="on"/>
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column columns="col-md-4">
                        <div className="form-group">
                            <input className="form-control" type="text" data-selenium-id="efternamn"
                                   placeholder={'Efternamn'} name="efternamn" autoComplete="on"/>
                        </div>
                    </Column>
                </Row>
              <Row>
                <Column columns="col-md-4">
                  <div className="form-group">
                    <input className="form-control" type="text" data-selenium-id="username"
                           placeholder={'Användarnamn *'} name="cn" autoComplete="on"/>
                      <input type="hidden" name="redirect" value="privat"/>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column columns="col-md-4">
                  <div className="form-group">
                    <ButtonTop text={'Logga in'} type="submit" value="Logga in" data-selenium-id="submitLoginForm"
                               form="loginForm">Logga in</ButtonTop>
                  </div>
                </Column>
              </Row>
            </div>
          </form>
        </div>}

            {typAvInloggning === 'org' && <div>
                <h3>Logga in som organisation</h3>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Namn</th>
                            <th>Personnummer</th>
                            <th>OrgNo</th>
                            <th>Beskrivning</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {testUsersOrganization.map((user) =>
                            <tr key={user.personId}>
                                <td>{user.namn}</td>
                                <td>{user.personId}</td>
                                <td>{user.org}</td>
                                <td>{user.beskrivning}</td>
                                <td>
                                    <form id={user.personId} action="http://localhost:9080/actuator/iammock/login">
                                        <input type="hidden" name="workforceid" value={user.personId}/>
                                        <input type="hidden" name="fullName" value={user.namn}/>
                                        <input type="hidden" name="cn" value={user.namn}/>
                                        <input type="hidden" name="randomNumberToAvoidCacheForOrg" value={uuidv4()}/>
                                        <input type="hidden" name="role" value="organisation"/>
                                        <input type="hidden" name="orgNo" value={user.org}/>
                                        <input type="hidden" name="redirect" value={"org"}/>
                                        <ButtonTop text={'Logga in'} type="submit" form={user.personId}/>
                                    </form>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>}

            {typAvInloggning === 'sdg' && <div>
                <h3>Log in as an EU citizen</h3>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Identification (eidasPersonIdentifier)</th>
                            <th>Description</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {testUsersSDG.map((user) =>
                            <tr key={user.personId}>
                                <td>{user.namn}</td>
                                <td>{user.personId}</td>
                                <td>{user.beskrivning}</td>
                                <td>
                                    <form id={user.personId} action="http://localhost:9080/actuator/iammock/loginSDG">
                                        <input type="hidden" name="eidasPersonIdentifier" value={user.personId}/>
                                        <input type="hidden" name="fornamn" value={user.fornamn}/>
                                        <input type="hidden" name="efternamn" value={user.efternamn}/>
                                        <input type="hidden" name="dateOfBirth" value={user.dateOfBirth}/>
                                        <input type="hidden" name="randomNumberToAvoidCacheForPriv" value={uuidv4()}/>
                                        <input type="hidden" name="redirect" value={"euCitizenApplication"}/>
                                        <ButtonTop text={'Log in'} type="submit" form={user.personId}/>
                                    </form>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>}
        </div>
    )
}

export default LocalLogin
