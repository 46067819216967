import { extent, resolutions } from './mapConstants'
import { TileWMS } from 'ol/source'
import TileGrid from 'ol/tilegrid/TileGrid'
import { Tile } from 'ol/layer'

export function topowebbLayer () {
  return new Tile({
    extent: extent,
    source: new TileWMS({
      url: '/api/map/topowebb/wms/v1.1',
      params: {
        'LAYERS': 'topowebbkartan',
        'TILED': true,
        'VERSION': '1.1.1',
        'FORMAT': 'image/png',
        'TRANSPARENT': false
      },
      serverType: 'mapserver',
      tileGrid: new TileGrid({
        tileSize: [256, 256],
        extent: extent,
        resolutions: resolutions
      })
    })
  })
}

export function ortofotoLayer () {
  return new Tile({
    extent: extent,
    source: new TileWMS({
      url: '/api/map/ortofoto/wms/v1.3',
      params: {
        'LAYERS': 'Ortofoto_0.5,Ortofoto_0.4,Ortofoto_0.25,Ortofoto_0.16',
        'TILED': true,
        'VERSION': '1.1.1',
        'FORMAT': 'image/png',
        'TRANSPARENT': false
      },
      serverType: 'mapserver',
      tileGrid: new TileGrid({
        tileSize: [512, 512],
        extent: extent,
        resolutions: resolutions
      })
    })
  })
}

export function fastighetsgranserLayer (morkBakgrund = false) {
  return new Tile({
    extent: extent,
    source: new TileWMS({
      url: '/api/map/fastighet/wms/v1.1',
      params: {
        'LAYERS': 'granser,text',
        'STYLES': morkBakgrund ? 'morkbakgrund,registerkarta' : 'ljusbakgrund,registerkarta',
        // 'STYLES': 'morkbakgrund,registerkarta',
        'TILED': true,
        'VERSION': '1.1.1',
        'FORMAT': 'image/png',
        'TRANSPARENT': true
      },
      serverType: 'mapserver',
      tileGrid: new TileGrid({
        tileSize: [2048, 2048],
        extent: extent,
        resolutions: resolutions
      })
    })
  })
}