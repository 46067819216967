//@ts-ignore
import { ModalDialogSub } from 'redet-react-components'

export function AbortSigningModal (props:{ closeModalCallback: Function, onClickJaKnapp: Function }) {
  return <ModalDialogSub modalTitle="Avbryt underskrifter"
                         id={'avbrytUnderskrifterModalId'}
                         description={<>
                           Alla underskrifter och bestyrkningar som gjorts kommer att försvinna och alla inbjudningar
                           som skickats kommer att
                           bli ogiltiga.
                           <br/><br/>
                           Vill du avbryta underskrifterna?
                         </>}
                         acceptButton={{ text: 'Ja', onClick: props.onClickJaKnapp }}
                         declineButton={{ text: 'Nej' }}
                         closeModalCallback={props.closeModalCallback}/>
}
