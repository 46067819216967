import { blockeradAtLm, EMAIL_REGEX, lmAdressVarning } from './globalConstants'

const isEmpty = (value: string) => value === undefined || value === null || value === '';

const join = (rules: RuleMethod[]) => (value: string | boolean, props: DynamicStringObject | DynamicBooleanObject) => 
  rules.map((rule: any) => rule(value, props)).filter((error: string) => !!error)[0 /* first error */]

export function email (value: string) {
  if (!isEmpty(value) && !EMAIL_REGEX.test(value)) {
    return 'En giltig e-postadress måste anges'
  }
}

export function warningMessages (value: string) {
  if (!isEmpty(value) && lmAdressVarning.test(value)) {
    return 'Handläggare på Lantmäteriet kan oftast inte bestyrka'
  }
  return undefined
}

type emailRecipient = {
  name: string,
  email: string
}

export function errorMessages (value: string, list?: emailRecipient[]) {
  if (isEmpty(value)) {
    return 'Obligatoriskt fält'
  }
  if (!isEmpty(value) && !EMAIL_REGEX.test(value)) {
    return 'Ange en giltig e-postadress'
  }

  if (!isEmpty(value) && blockeradAtLm.test(value)) {
    return 'Otillåten mottagare'
  }

  if (list !== undefined) {
    const result = list.filter(inbjudanLista => inbjudanLista.email === value)
    if (result.length > 0) {
      return 'E-postadressen finns redan med i listan'
    }
  }

  return undefined
}

export function blockeradLmAdressOtillatenMottagare (value: string) {
  if (!isEmpty(value) && blockeradAtLm.test(value)) {
    return 'Otillåten mottagare'
  }
}

export function required (value: string) {
  if (isEmpty(value)) {
    return 'Obligatoriskt fält'
  }
}

export function requiredArray<T>(value: T[]) {
  if (value.length === 0) {
    return 'Obligatoriskt fält'
  }
}

export function nonEmptyArray (message: string) {
  return (value: any[]) => {
    if (Array.isArray(value)) {
      if (value.length < 1) {
        return message
      }
    } else {return message}
  }
}

export function emailRequired (message: string) {
  return (value: string) => { if (isEmpty(value)) { return message }}
}

export function namnValidation (value: string) {
  if (isEmpty(value)) {
    return 'Obligatoriskt fält'
  }
  if (value.length < 4 && value.length > 0) {
    return 'Vänligen ange minst 4 tecken'
  }
  if (!isEmpty(value) && !value.includes(' ')) {
    return 'Du måste ange för- och efternamn'
  }
}

export function minLength (min: number) {
  return (value: string) => {
    if (!isEmpty(value) && value.length < min) {
      return `Ange minst ${min} tecken`
    }
  }
}

export function betweenLength (min: number, max: number, val: string) {
  if (isEmpty(val)) {
    return 'Obligatoriskt fält'
  } else if (val.length < min) {
    return `Ange minst ${min} tecken`
  } else if (val.length > max) {
    return `Ange maximalt ${max} tecken`
  }
}

export function checkForOnlySpaces (value: string) {
  if (/^\s+$/.test(value)) {
    return 'Filnamnet måste även innehålla bokstäver eller siffror'
  }
}

export function exactLength (length: number) {
  return (value: string) => {
    if (!isEmpty(value) && value.length !== length) {
      return `Ange exakt ${length} tecken`
    }
  }
}

export function maxLength (max: number) {
  return (value: string) => {
    if (!isEmpty(value) && value.length > max) {
      return `Ange max ${max} tecken`
    }
  }
}

export function integer (value: string) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Använd enbart siffror'
  }
}

export function integerAndHypen (value: string) {
  if (!isEmpty(value)) {
    const valueWithoutHypen = value.replace("-", "")
    if (!Number.isInteger(Number(valueWithoutHypen)))
    return 'Använd siffror och bindestreck'
  }
}

export function oneInteger (value: string) {
  if (!isEmpty(value) && !/\d/.test(value)) {
    return 'Id bör innehålla minst en siffra'
  }
}

export function luhnAlgorithm (input: string) {
  if (!isEmpty(input)) {
    let sum = 0

    for (let index = 0; index < input.length; index++) {
      let digit = parseInt(input.charAt(index), 10)
      if (index % 2 === (input.length % 2)) { digit *= 2 }
      if (digit > 9) { digit -= 9 }
      sum += digit
    }
    if ((sum % 10) !== 0) {
      return 'Ogiltigt nummer'
    }
  }
}

export function swedishSocialSecurityNumber (value: string) {
  if (!isEmpty(value)) {
    const valueOnlyNumbers = value.toString().replace("-", "")
    if (valueOnlyNumbers.length !== 12) {
      return `Ange personnr med 12 siffror`
    }

    if (valueOnlyNumbers.substring(0, 2) !== '18' && valueOnlyNumbers.substring(0, 2) !== '19' && valueOnlyNumbers.substring(0, 2) !== '20') {
      return 'Felaktigt århundrade'
    }

    return luhnAlgorithm(valueOnlyNumbers.substring(2))
  }
}

// https://www.skatteverket.se/privat/internationellt/bosattutomlands/samordningsnummer
export function samordningsNumber (value: string) {
  if (!isEmpty(value)) {
    if (value.length !== 10) {
      return `Ange samordningsnummer med 10 siffror`
    }
    const dayNumber = parseInt(value.substr(4, 2))
    if (dayNumber - 60 < 1) {
      return `Ange giltligt samordningsnummer.`
    }
  }
}

export function swedishOrganizationNumber (value: string) {
  if (!isEmpty(value)) {
    if (value.length !== 10) {
      return `Ange organisationsnr med 10 siffror`
    }

    return luhnAlgorithm(value)
  }
}

export function checkIfRadioButtonIsNotChosen (value: string, message: string) {
  return (fieldValue: string) => {
    if (fieldValue !== value) {
      return message
    }
  }
}

export function checkIfCheckboxIsChosen (message: string) {
  return (fieldValue: boolean) => {
    if (fieldValue === true) {
      return message
    }
  }
}

interface DynamicStringObject {
  [name: string]: string;
}

export function oneMustBeFilled (oneOfFields: string[], message: string) {
  return (value: any, props: DynamicStringObject) => {
    let oneExist = false
    if (!oneOfFields || !Array.isArray(oneOfFields)) {
      return undefined
    }
    oneOfFields.forEach((field) => {
      if ((props[field] && props[field].length > 0)) {
        oneExist = true
      }
    })
    return oneExist ? undefined : message
  }
}

interface DynamicBooleanObject {
  [name: string]: boolean;
}

export function oneMustBeTrue (oneOfFields: string[], message: string) {
  return (value: any, props: DynamicBooleanObject) => {
    let oneExist = false
    if (!oneOfFields || !Array.isArray(oneOfFields)) {
      return undefined
    }
    oneOfFields.forEach((field) => {
      if ((props[field] !== undefined && props[field] === true)) {
        oneExist = true
      }
    })
    return oneExist ? undefined : message
  }
}

interface DynamicArrayObject {
  [name: string]: string[];
}

type RuleMethod = ((value: any) => string | undefined)[]

interface RulesObject {
  [name: string]: RuleMethod;
}

export function createValidator (data: Record<string, any>, rules: RulesObject) {
  if (data === undefined)
    return {} as DynamicArrayObject
  if (rules === undefined || Object.keys(rules).length === 0)
    return {} as DynamicArrayObject
  const errors: DynamicArrayObject = {}
  Object.keys(rules).forEach((field) => {
    const rule = join(([] as RuleMethod[]).concat(rules[field])) // concat enables both functions and arrays of functions
    const valueToValidate = data[field]

    if (Array.isArray(valueToValidate) && valueToValidate.length > 0) {
      valueToValidate.forEach((value, index) => {
        const error = rule(value, data)
        if (error) {
          if (errors[field] === undefined) {
            errors[field] = [] as string[]
          }
          errors[field][index] = error
        }
      })
    } else {
      const error = rule(valueToValidate, data)
      if (error) {
        errors[field] = error
      }
    }
  })
  return errors
}
