import { Fragment } from "react";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { Column } from "redet-react-components";

type Props = {
  items: File[];
  desc: string;
};

export default function MapAnsokanAndHandlingar(props: Props) {
  const { t } = useTranslation();

  return (
    <Column columns="col-xs-12 col-sm-6 col-md-2">
      {props.items.map((item: File, index: number) => {
        const itemId = (item.name + index).replace(/\s/g, "");
        return (
          <Fragment key={index}>
            <p >
              <a
                href={URL.createObjectURL(item)}
                className="download-link"
                download={item.name}
                id={`Download${itemId}`}
                aria-label={t("sumbit.aria")}
              >
                {props.desc + (index + 1)}
              </a>
            </p>
          </Fragment>
        );
      })}
    </Column>
  );
}
