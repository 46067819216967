import {
  signAbort,
  signStart,
  signStartPhos,
  uploadYrkandeAndStartSigning
} from 'repository/kompletteringRepository'
import {
  fakeSignAbort,
  fakeSignStart,
  fakeUploadKompletteringYtterligareHandling
} from 'repository/testdata/fakeKompletteringRepository'
import { Fetcher } from 'repository/fetcher'
import { createJqueryAjaxDriver } from '../../utils/repositoryHelpers'
import {Dispatch} from "redux";
import {RootState} from "../../store";
import {uploadBifogadHandlingKomplettering, YtterligareHandlingRequest} from "../../repository/kompletteringBifogaHandlingRepository";

const ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_PENDING = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_PENDING'
export const ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_SUCCESS = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_SUCCESS'
const ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_FAILED = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_FAILED'

const ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_PENDING = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_PENDING'
const ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_SUCCESS = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_SUCCESS'
const ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_FAILED = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_FAILED'

const PENDING_FILES = 'BifogadHandlingKomplettering/PENDING_FILES'
const NO_PENDING_FILES = 'BifogadHandlingKomplettering/NO_PENDING_FILES'

const initialState = {
  isUploading: false,
  isInitiatingSigning: false,
  isSigning: false,
  pendingFiles: false,
}

export default function reducer (state: any = initialState, action: {type:string}) {
  switch (action.type) {
    case PENDING_FILES:
      return {
        ...state,
        pendingFiles: true,
      }

    case NO_PENDING_FILES:
      return {
        ...state,
        pendingFiles: false,
      }
    case ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_PENDING:
    case ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_PENDING:
      return {
        ...state,
        isUploading: true,
      }
    case ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_SUCCESS:
    case ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_FAILED:
    case ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_SUCCESS:
    case ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_FAILED:
      return {
        ...state,
        isUploading: false,
      }

    default:
      return state
  }
}

export function pendingFiles (isPending: boolean) {
  if (isPending) {
    return {
      type: PENDING_FILES
    }
  }
  return {
    type: NO_PENDING_FILES
  }
}

export function uploadKompletteringFromBifogadFilePhos (arendenummer: string, bifogadHandling: YtterligareHandlingRequest, onProgressCallback: (percent?: number) => void, abortController: AbortController = new AbortController()) {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({type: ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_PENDING})
    if(getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED) {
      //mock!
      return fakeUploadKompletteringYtterligareHandling()
          .then((kompletteringId: string) => {
            dispatch({type: ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_SUCCESS})
            return kompletteringId
          })
    }
    return uploadBifogadHandlingKomplettering(arendenummer, bifogadHandling, onProgressCallback, abortController)
        .then((kompletteringId: string) => {
          dispatch({type: ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_SUCCESS})
          return kompletteringId
        }).catch((error: Error) => {
          dispatch({type: ACTION_UPLOAD_NEW_BIFOGAD_HANDLING_KOMPLETTERING_FAILED})
          throw error
        })
  }
}

export function signKompletteringStart (kompletteringId:string, signingInvitationRequest:any) {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    const fetcher = new Fetcher(dispatch, getState)
    return getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeSignStart() : signStart(fetcher, kompletteringId, signingInvitationRequest)
  }
}

export function signKompletteringStartPhos (kompletteringId:string) {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    const fetcher = new Fetcher(dispatch, getState)
    return getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeSignStart() : signStartPhos(fetcher, kompletteringId)
  }
}

export function abortSigningKomplettering (kompletteringId:string) {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    const fetcher = new Fetcher(dispatch, getState)
    return getState().featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED ? fakeSignAbort() : signAbort(fetcher, kompletteringId)
  }
}

// Använd denna vid utveckling av yrkande
export function uploadKompletteringYrkande (data:any, progressCallback: (evt:any) => {}, xhrProxy: any) {
  const callbacks = [() => {}, () => {}, () => {}]
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    return uploadYrkandeAndStartSigning(
      createJqueryAjaxDriver(getState, dispatch, callbacks),
      data,
      progressCallback,
      xhrProxy
    ).catch((error) => { throw error})
  }
}
