import { Factory, Model, Response } from 'miragejs'
import { v4 as uuid } from 'uuid'
import {BITRADANDETYP, YTTERLIGARE_HANDLING_TYP} from "../utils/globalConstants";

const authHeader = { 'enak.passedauthentication': 'true', 'svarstjanst.passedauthentication': 'true' }

export let kompletteringInflections = function (inflect) {
  inflect.irregular('komplettering', 'kompletteringar')
  inflect.irregular('yrkande', 'yrkanden')
  inflect.irregular('kompletteringsLista', 'kompletteringsListor')
}

export let kompletteringModels = function () {
  return {
    komplettering: Model.extend({
      komplettering: Model,
      yrkande: Model,
    })
  }
}

export let kompletteringDataFactories = function () {
  return {
    yrkande: Factory.extend({
      yrkandeId () {
        return uuid()
      },
      createdByUserPersonnummer () {
        return '198712067530'
      },
      createdByUserFullName () {
        return 'Oskar Sandström'
      },
      arendenummer () {
        return 'Ä123456'
      },
      skapad () {
        return new Date().toTimeString()
      },
      yrkandeText () {
        return 'BLA BLA BLA YRKANDETEXT YADAYDAYDAYDAYDAYDAY!?!1234546'
      },
      status () {
        return 'SIGNERAS'
      },
    }),
  }
}

export let kompletteringYrkandeRoutes = function () {
  this.post('/api/komplettering-service/komplettering/yrkande', (schema, request) => {
    const id = request.queryParams.id
    switch (id) {
      case '1': {
        return new Response(500, authHeader, { 'felmeddelande': 'INTERNAL SERVER ERROR' })

      }
      case '2': {
        return new Response(400, authHeader, { 'felmeddelande': 'BAD REQUEST' })

      }
      case '3': {
        return new Response(401, authHeader, { 'felmeddelande': 'UNAUTHORIZED' })

      }
      case '4': {
        return new Response(404, authHeader, { 'felmeddelande': 'NOT FOUND' })

      }
      default: {
        return new Response(201, authHeader, { 'id': uuid() })

      }
    }
  }, { timing: 2000 })
}

export let kompletteringGeneralRoutes = function () {
  this.delete('/api/komplettering-service/komplettering/', (schema, request) => {
    const kompletteringId = request.queryParams.kompletteringId
    switch (kompletteringId) {
      case '1': {
        return new Response(500, authHeader, { 'felmeddelande': 'INTERNAL SERVER ERROR' })

      }
      case '2': {
        return new Response(400, authHeader, { 'felmeddelande': 'BAD REQUEST' })

      }
      case '3': {
        return new Response(401, authHeader, { 'felmeddelande': 'UNAUTHORIZED' })

      }
      case '4': {
        return new Response(404, authHeader, { 'felmeddelande': 'NOT FOUND' })

      }
      default: {
        let komplettering = schema.komplettering.findBy({ kompletteringId: kompletteringId })
        if (komplettering === null)
          return new Response(404, authHeader)
        komplettering.destroy()
        return new Response(204, authHeader)
      }
    }
  })

  this.get('/api/komplettering-service/komplettering/', (schema, request) => {
    const arendenummer = request.queryParams.arendenummer
    let kompletteringar = schema.kompletteringar.all()
    switch (arendenummer) {
      case '1': {
        return new Response(500, authHeader, [{ 'felmeddelande': 'INTERNAL SERVER ERROR' }])

      }
      case '2': {
        return new Response(400, authHeader, [{ 'felmeddelande': 'BAD REQUEST' }])

      }
      case '3': {
        return new Response(401, authHeader, [{ 'felmeddelande': 'UNAUTHORIZED' }])

      }
      case '4': {
        return new Response(404, authHeader, [{ 'felmeddelande': 'NOT FOUND' }])

      }
      default: {
        return new Response(200, authHeader, kompletteringar)

      }
    }
  })

  this.get('/api/komplettering-service/komplettering/:kompletteringId', (schema, request) => {
    const kompletteringId = request.params.kompletteringId
    switch (kompletteringId) {
      case '1': {
        return new Response(500, authHeader, [{ 'felmeddelande': 'INTERNAL SERVER ERROR' }])

      }
      case '2': {
        return new Response(400, authHeader, [{ 'felmeddelande': 'BAD REQUEST' }])

      }
      case '3': {
        return new Response(401, authHeader, [{ 'felmeddelande': 'UNAUTHORIZED' }])

      }
      case '4': {
        return new Response(404, authHeader, [{ 'felmeddelande': 'NOT FOUND' }])
      }
      case '6087f110650cfb0001bff917': {
        return new Response(200, authHeader, signingStatusResponsePending)
      }
      case '6087df3e650cfb0001bff8ba': {
        return new Response(200, authHeader, signingStatusResponseFinished1)
      }
      case '6087df17650cfb0001bff89c': {
        return new Response(200, authHeader, signingStatusResponseFinished2)
      }
      default: {
        return new Response(200, authHeader, dynamicSigningResponse(kompletteringId))

      }
    }
  })

  this.post('/api/komplettering-service/komplettering/:kompletteringId/submit', (schema, request) => {
    const kompletteringId = request.params.kompletteringId
    switch (kompletteringId) {
      case '1': {
        return new Response(500, authHeader, [{ 'felmeddelande': 'INTERNAL SERVER ERROR' }])

      }
      case '2': {
        return new Response(400, authHeader, [{ 'felmeddelande': 'BAD REQUEST' }])

      }
      case '3': {
        return new Response(401, authHeader, [{ 'felmeddelande': 'UNAUTHORIZED' }])

      }
      case '4': {
        return new Response(404, authHeader, [{ 'felmeddelande': 'NOT FOUND' }])
      }
      default: {
        return new Response(200, authHeader, uuid())

      }
    }
  })

  this.post('/api/komplettering-service/komplettering/:kompletteringId/signing/abort', (schema, request) => {
    const kompletteringId = request.params.kompletteringId
    switch (kompletteringId) {
      case '1': {
        return new Response(500, authHeader, [{ 'felmeddelande': 'INTERNAL SERVER ERROR' }])

      }
      case '2': {
        return new Response(400, authHeader, [{ 'felmeddelande': 'BAD REQUEST' }])

      }
      case '3': {
        return new Response(401, authHeader, [{ 'felmeddelande': 'UNAUTHORIZED' }])

      }
      case '4': {
        return new Response(404, authHeader, [{ 'felmeddelande': 'NOT FOUND' }])
      }
      default: {
        return new Response(200, authHeader, kompletteringId)
      }
    }
  })
}

export let kompletteringSeed = function (server) {
  server.create('komplettering',
    {
      'typ': YTTERLIGARE_HANDLING_TYP,
      'status': 'INSKICKAD',
      'kompletteringId': '6087df17650cfb0001bff89c',
      'createdByUserPersonnummer': '198712067530',
      'createdByUserFullName': 'Sandström Oskar',
      'handlingTyp': 'JORDFORVARVSTILLSTAND',
      'arendenummer': 'Ä123456',
      'skapad': '2021-04-27T09:53:27Z',
      'notifikationsmail': 'noreply@lm.se',
      'signingInstanceId': '026ee962-2770-4855-8319-34b05c5b8733',
      'kvittensId': '105416',
      'dokumentId': '6087df17650cfb0001bff89d'
    })
  server.create('komplettering',
    {
      'typ': BITRADANDETYP,
      'status': 'INSKICKAD',
      'kompletteringId': '6087df3e650cfb0001bff8ba',
      'createdByUserPersonnummer': '198712067530',
      'createdByUserFullName': 'Sandström Oskar',
      'handlingTyp': 'BITRADANDEHANDLING',
      'arendenummer': 'Ä123456',
      'skapad': '2021-04-27T09:54:06Z',
      'notifikationsmail': 'osidos@dsjksd.jdskjdks',
      'signingInstanceId': '5369939d-73ab-4021-802b-83d3d625e107',
      'kvittensId': '105417',
      'aktbilagebeteckning': [
        'A1'
      ],
      'dokumentId': 'e210305f-6e60-434c-b425-de8cf3ca6560'
    })
  server.create('komplettering',
    {
      'typ': BITRADANDETYP,
      'status': 'UTKAST',
      'kompletteringId': '6087f110650cfb0001bff917',
      'createdByUserPersonnummer': '198712067530',
      'createdByUserFullName': 'Sandström Oskar',
      'handlingTyp': 'BITRADANDEHANDLING',
      'arendenummer': 'Ä123456',
      'skapad': '2021-04-27T11:10:08Z',
      'notifikationsmail': '',
      'signingInstanceId': '',
      'kvittensId': null,
      'aktbilagebeteckning': [
        'A1'
      ],
      'dokumentId': '1128e6a7-c8d3-4745-b7d4-c2c239872039'
    })
}

const signingStatusResponsePending = {
  'signingStatus': {
    'status': 'PENDING',
    'dokumentNamn': 'Biträdandehandling',
    'signerStatus': [
      {
        'status': 'NONE',
        'signerEmail': 'djskds@jkdss.jkds',
        'signingUrl': 'https://signingweb-tst.lantmateriet.se/?id=YjM1OTQ5MTRiZGZmNGQ3NDk3ZWY1NzQxNjFjNGUwNTcmZjQzNGEyNzEtMmE0Zi00YTkxLTgzYmMtNmRiMDZkYmE0NGMxn'
      }
    ]
  },
  'typ': BITRADANDETYP,
  'status': 'SIGNERAS',
  'kvittensId': null,
  'kompletteringId': '6087f110650cfb0001bff917',
  'createdByUserPersonnummer': '198712067530',
  'createdByUserFullName': 'Sandström Oskar',
  'handlingTyp': 'BITRADANDEHANDLING'
}

const signingStatusResponseFinished1 =
  {
    'signingStatus': {
      'status': 'NOT_STARTED',
      'dokumentNamn': null,
      'signerStatus': null
    },
    'typ': BITRADANDETYP,
    'status': 'INSKICKAD',
    'kvittensId': '105417',
    'kompletteringId': '6087df3e650cfb0001bff8ba',
    'createdByUserPersonnummer': '198712067530',
    'createdByUserFullName': 'Sandström Oskar',
    'handlingTyp': 'BITRADANDEHANDLING'
  }

const signingStatusResponseFinished2 =
  {
    'signingStatus': {
      'status': 'NOT_STARTED',
      'dokumentNamn': null,
      'signerStatus': null
    },
    'typ': YTTERLIGARE_HANDLING_TYP,
    'status': 'INSKICKAD',
    'kvittensId': '105416',
    'kompletteringId': 'v',
    'createdByUserPersonnummer': '198712067530',
    'createdByUserFullName': 'Sandström Oskar',
    'handlingTyp': 'JORDFORVARVSTILLSTAND'
  }

const dynamicSigningResponse = (id) => {
  return {
    'signingStatus': {
      'status': 'SUCCEEDED',
      'dokumentNamn': 'Köpebrev',
      'signerStatus': [
        {
          'status': 'APPROVED',
          'signerEmail': 'noreply@lm.se',
          'signingUrl': 'https://signingweb-tst.lantmateriet.se/?id=NGY4MmZkMGEyMTI0NDczM2FjMDM2YmNhMzBhYzJlZGUmNDNhNjM0YTgtMmZjNy00NmRlLTg5ZTgtYTM3NDYwMzc5MzY2q'
        }
      ]
    },
    'typ': YTTERLIGARE_HANDLING_TYP,
    'status': 'SIGNERAS',
    'kvittensId': null,
    'kompletteringId': id,
    'createdByUserPersonnummer': '198712067530',
    'createdByUserFullName': 'Sandström Oskar',
    'handlingTyp': 'KOPEBREV'
  }
}
