import { uploadKompletteringBitradandeAndStartSigning } from 'repository/kompletteringRepository'
import { Fetcher } from 'repository/fetcher'

const ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_PENDING = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_PENDING'
const ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_SUCCESS = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_SUCCESS'
const ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_FAILED = 'BifogadHandlingKomplettering/ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_FAILED'

const initialState = {
  isUploading: false,
  isInitiatingSigning: false,
  isSigning: false,
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_PENDING:
      return {
        ...state,
        isUploading: true,
      }
    case ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_SUCCESS:
      return {
        ...state,
        isUploading: false,
      }
    case ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_FAILED:
      return {
        ...state,
        isUploading: false,
      }
    default:
      return state
  }
}

export function uploadingKompletteringForBitradande () {
  return {
    type: ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_PENDING,
  }
}

export function uploadKompletteringForBitradandeSuccess () {
  return {
    type: ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_SUCCESS,
  }
}

export function uploadKompletteringForBitradandeFailed () {
  return {
    type: ACTION_UPLOAD_NEW_ANSOKAN_SIGNATURES_KOMPLETTERING_FAILED,
  }
}

export function uploadKompletteringForBitradande (arendenummer, aktbilagebeteckningar, notifieringsmail, signatories) {
  return function action (dispatch, getState) {
    dispatch(uploadingKompletteringForBitradande())
    const fetcher = new Fetcher(dispatch, getState, false)
    return uploadKompletteringBitradandeAndStartSigning(fetcher, arendenummer, aktbilagebeteckningar, notifieringsmail, signatories)
      .then((kompletteringId) => {
        dispatch(uploadKompletteringForBitradandeSuccess())
        return kompletteringId
      }).catch((err) => {
        dispatch(uploadKompletteringForBitradandeFailed())
        throw err
      })
  }
}