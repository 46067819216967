import { Dispatch, SetStateAction, useState } from "react";
import {
    // @ts-ignore
    Column, Row, TextArea, TextInput, WizardIntorduction, WizardNavigationButtonToolbar
} from "redet-react-components";
import { validatePhonenumber } from "utils/telephoneNumberValidation";
import { betweenLength, email } from "utils/validation";
import { Term } from "views";


type Props = {
    meddelande: string
    setMeddelande: Dispatch<SetStateAction<string>>
    epostKontakt: string
    setEpostKontakt: Dispatch<SetStateAction<string>>
    telefonKontakt: string
    setTelefonKontakt: Dispatch<SetStateAction<string>>
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
}

export function UpprattaSkrivelseStep(props: Props) {
    const MAX_LENGTH_TEXT_AREA = 2000;
    const [meddelandeValidationError, setMeddelandeValidationError] = useState<string>('');
    const [epostKontaktValidationError, setEpostKontaktValidationError] = useState<string>('');
    const [telefonValidationError, setTelefonValidationError] = useState<string>('');
    return <>
        <WizardIntorduction
            title={"Meddelande till handläggare"}
            ingress={(<>
                Ett <Term label="meddelande"
                         moment="help-komplettering"
                         term="term-meddelande"/> som inkommer till myndigheten blir en allmän handling och kan komma att publiceras i denna E-tjänst för samtliga berörda i ärendet.
                Observera att ditt namn kommer att synas på det inskickade dokumentet.
                </>)}>
            <Row>
                <Column columns={"form-group col-xs-12 col-sm-12 col-xl-8"}>
                    <TextArea label={"Skriv meddelande"}
                              maxLength={MAX_LENGTH_TEXT_AREA}
                              characterLimit={MAX_LENGTH_TEXT_AREA}
                              isRequired={true}
                              id={"textAreaId"}
                              value={props.meddelande}
                              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                  const error = betweenLength(2, MAX_LENGTH_TEXT_AREA, evt.target.value);
                                  if (error) {
                                      setMeddelandeValidationError(error);
                                      props.setMeddelande(evt.target.value)
                                  } else {
                                      setMeddelandeValidationError('')
                                      props.setMeddelande(evt.target.value)
                                  }
                              }}
                              placeholder={"Max antal tecken 2000"}
                              validationErrorMsg={meddelandeValidationError}
                    />
                </Column>
            </Row>
            <Row>
                <Column columns={"form-group col-xs-12 col-sm-6 col-xl-5"}>
                    <TextInput
                        label={"E-post"}
                        isRequired={false}
                        type={"email"}
                        autoComplete={'email'}
                        id={"mailId"}
                        maxLength={100}
                        validationErrorMsg={epostKontaktValidationError}
                        value={props.epostKontakt}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const error = email(evt.target.value)
                            if (error) {
                                setEpostKontaktValidationError(error)
                                props.setEpostKontakt(evt.target.value)
                            } else {
                                setEpostKontaktValidationError('')
                                props.setEpostKontakt(evt.target.value)
                            }
                        }}
                    />
                    <TextInput
                        label={"Telefonnummer"}
                        isRequired={false}
                        id={"phoneId"}
                        autoComplete={'tel'}
                        validationErrorMsg={telefonValidationError}
                        value={props.telefonKontakt ? props.telefonKontakt : ''}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const error = validatePhonenumber(evt.target.value)
                            if (error) {
                                setTelefonValidationError(error);
                                props.setTelefonKontakt(evt.target.value)
                            } else {
                                setTelefonValidationError('')
                                props.setTelefonKontakt(evt.target.value)
                            }
                        }}
                    />
                </Column>
            </Row>
        </WizardIntorduction>
        <WizardNavigationButtonToolbar
            isPrevButtonDisabled={true}
            nextButtonText={"Ange e-post för kvittens"}
            onClickNextButton={props.nextStep}
            isNextButtonDisabled={(!props.meddelande || meddelandeValidationError || telefonValidationError || epostKontaktValidationError)}/>
    </>;
}
export default UpprattaSkrivelseStep;