import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react'
import {Fastighet} from 'domain/Ansokan'
import {SdgSubmitResult} from "./model/SdgSubmitResult";

export type AnsokanWizardSDGContextType = {
    fastigheter: Fastighet[],
    setFastigheter: Dispatch<SetStateAction<Fastighet[]>>,
    handlingar: File[],
    setHandlingar: Dispatch<SetStateAction<File[]>>,
    ansokan?: File,
    setAnsokan: Dispatch<SetStateAction<File | undefined>>
    result?: SdgSubmitResult,
    setResult: Dispatch<SetStateAction<SdgSubmitResult | undefined>>
    clearAnsokan: () => void
}

const initalSDGContext = {
    fastigheter: [] as Fastighet[],
    setFastigheter: () => {},
    handlingar: [] as File[],
    setHandlingar: () => {},
    setAnsokan: () => {},
    setResult: () => {},
    clearAnsokan: () => {}
}

export const AnsokanWizardSDGContext = createContext<AnsokanWizardSDGContextType>(initalSDGContext)

export function AnsokanWizardSDGProvider(props: {children: ReactNode}){
    const [fastigheter, setFastigheter] = useState<Fastighet[]>([])
    const [handlingar, setHandlingar] = useState<File[]>([])
    const [ansokan, setAnsokan] = useState<File | undefined>()
    const [submitResult, setSubmitResult] = useState<SdgSubmitResult>()

    const clearAnsokan = () => {
        setFastigheter([]);
        setHandlingar([]);
        setAnsokan(undefined);
    }

    return(
        <AnsokanWizardSDGContext.Provider value={ {fastigheter, setFastigheter, handlingar, setHandlingar, ansokan, setAnsokan, result: submitResult, setResult: setSubmitResult, clearAnsokan}}>
            {props.children}
        </AnsokanWizardSDGContext.Provider>
    )
}