export const avtal = <>
  <p>Avtal som kan vara av betydelse för ärendet och som eventuellt kan läggas till grund för
    beslut. Avser ej köpeavtal.
    <br/>Exempel på handlingar är:</p>
  <ul>
    <li>Nyttjanderättsavtal</li>
    <li> Servitutsavtal</li>
    <li> Avtal mellan makar (sammanläggning)</li>
  </ul>
</>
