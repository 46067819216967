import {ARENDE_STATUS, ARENDE_STATUS_TYPES} from "utils/globalConstants";
import {ArendeStatusTile} from "components";

type Props = {
    currentStatus: ARENDE_STATUS;
};

export function SersamArendeStatus({currentStatus}: Props) {
    const convertedStatus = convertStatus(currentStatus)

    function convertStatus(status: ARENDE_STATUS): ARENDE_STATUS.PAGAENDE | ARENDE_STATUS.SLUTFORT {
        if (status === ARENDE_STATUS.SLUTFORT) {
            return ARENDE_STATUS.SLUTFORT
        }
        return ARENDE_STATUS.PAGAENDE
    }

    return (<>
        <h2 className="visually-hidden">Status för ärende</h2>
        <ul className="status-visualization" data-selenium-id="arendeStatus" data-testid={'sersam-arende-status-tiles'}>
            <ArendeStatusTile
                arendeStatusTypes={ARENDE_STATUS_TYPES}
                status={ARENDE_STATUS.PAGAENDE}
                currentStatus={convertedStatus}
                description={"Ärendet utreds och prövas av Lantmäterimyndigheten."}
            />
            <ArendeStatusTile
                arendeStatusTypes={ARENDE_STATUS_TYPES}
                status={ARENDE_STATUS.SLUTFORT}
                currentStatus={convertedStatus}
                description={"Ärendet är slutfört och förändringarna är registrerade. Ärendet syns i denna tjänst i 30 dagar."}
            />
        </ul>
    </>)
}
