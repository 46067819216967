import Log from 'utils/log'
import { AjaxClientError, logError } from 'utils/errorHandler'
import jQuery from 'jquery'
import {
  ForbiddenBifogadHandlingFiletypeException,
  ForbiddenKompletteringException, InvalidEmailException, KompletteringNotFoundException,
  TooLongTextException,
  TooManyFilesInBifogadHandlingException,
  UserAbortException
} from "./BifogadHandlingException";

const repositoryUrl = '/api/komplettering-service/komplettering'
const log = new Log('kompletteringRepository')

export function loadKompletteringar (fetcher, arendenummer) {
  const url = repositoryUrl + '/?arendenummer=' + encodeURIComponent(arendenummer)
  return fetcher.fetching(url, {
    method: 'GET',
    cache: 'no-store',
  }).then((response) => {
    return response.json()
  }).then((kompletteringar) => {
    log.info('Pågående kompletteringar succesfully loaded from server')
    return kompletteringar
  }).catch((err) => {
    log.error('Error encountered while loading list of pågående kompletteringar from server')
    throw err
  })
}

export function uploadKompletteringBitradandeAndStartSigning (fetcher, arendenummer, aktbilagebeteckningar, notifieringsmail, signatories) {
  fetcher.setGlobalErrorHandling(false)
  const url = repositoryUrl + '/bitradande'
  return fetcher.fetching(url, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      'arendeId': arendenummer,
      'aktbilagebeteckning': aktbilagebeteckningar,
      'notifieringsmail': notifieringsmail,
      'signatories': signatories
    }),
  }, { 'Content-Type': 'application/json' })
    .then((response) => {
      return response.text()
    }).then((kompletteringId) => {
      log.info('Komplettering successfully uploaded to server')
      return kompletteringId
    }).catch((err) => {
      // As we want custom handling of errors in this query we're disabling the automatic global
      // ajax error handling with the query option 'global: false' and need to handle it all ourselves.
      if (err.name === 'AbortError') {
        throw new UserAbortException('User has aborted the upload!')
      } else {
        log.error('Error encountered while uploading komplettering to server')
        const error = new AjaxClientError(repositoryUrl, err.status, err, err.statusText)
        logError(error)

        if (err.status === 403 && err.body && err.body.exception.includes('ForbiddenKompletteringException')) {
          throw new ForbiddenKompletteringException('Något blev fel vid uppladdning av komplettering')
        } else if (err.status === 404 && err.body && err.body.exception.includes('ArendeNotFoundException')) {
          throw new ForbiddenKompletteringException('Ärendet med ärendenummer: ' + arendenummer + ' kunde inte hittas')
        } else if (err.status === 400 && err.body && err.body.exception.includes('InvalidEmailException')) {
          throw new InvalidEmailException('Fel format på notifieringsmail: ' + notifieringsmail + ' eller inbjudanmail ' + signatories + '.')
        } else {
          throw error
        }
      }
    })
}

export function constructYrkandeFormData ({
  choices,
  arendenummer,
  yrkandeText,
  bifogadeKartor = [],
  notifieringemail,
  medyrkare
}) {

  let request = {
    arendeId: arendenummer,
    yrkandeText: yrkandeText,
    notifieringEmail: notifieringemail,
  }

  if (choices.isMultiplePeopleYrkare) {
    request = {
      ...request,
      inbjudningar: medyrkare
    }
  }
  const formData = new FormData()

  if (choices.isUploadingAkartskiss) {
    for (const karta of bifogadeKartor) {
      formData.append('files', karta)
    }
  }
  formData.append('request', new Blob([JSON.stringify(request)], {
    type: 'application/json'
  }))
  return formData
}

/**
 * TODO: Denna metod är ett utkast ännu så länge, den behöver testas och verifieras!
 */
export function uploadYrkandeAndStartSigning (ajaxDriver, data, progressCallback, xhrProxy) {
  const formData = constructYrkandeFormData(data)
  /*
  Exempel på request:

  const request = {
      arendeId: 'Ä123456',
      yrkandeText: 'Jag yrkar på att få en massa pengar av LM',
      notifieringEmail: 'notifieringEmail@foo.xyz',
      inbjudningar: [{name: 'inbjuden1 testsson', email: 'inbjuden1@foo.xyz'}, {name: 'inbjuden2 testsson', email: 'inbjuden2@foo.xyz'}]
  }
  */

  return new Promise((resolve, reject) => {
    xhrProxy.xhr = ajaxDriver({
      method: 'POST',
      url: repositoryUrl + '/yrkande',
      contentType: false,
      cache: false,
      processData: false,
      data: formData,
      global: false,
      xhr: () => {
        const xhr = jQuery.ajaxSettings.xhr()
        xhr.upload.onprogress = progressCallback
        return xhr
      }
    }).done((kompletteringId) => {
      log.info('Yrkande successfully uploaded to server')
      resolve(kompletteringId)
    }).fail((jqXHR, textStatus, errorThrown) => {
      // As we want custom handling of errors in this query we're disabling the automatic global
      // ajax error handling with the query option 'global: false' and need to handle it all ourselves.
      if (jqXHR.userForcedAbort) {
        reject(new UserAbortException('User has aborted the upload!'))
      } else {
        log.error('Error encountered while uploading komplettering to server', errorThrown)
        const error = new AjaxClientError(repositoryUrl, jqXHR.status, errorThrown, jqXHR.responseText)
        logError(error)
        if (jqXHR.status === 403 && jqXHR.responseText && jqXHR.responseText.includes('ForbiddenKompletteringException')) {
          reject(new ForbiddenKompletteringException('Något blev fel vid uppladdning av komplettering'))
        } else if (jqXHR.status === 400 && jqXHR.responseText && jqXHR.responseText.includes('ForbiddenBifogadHandlingException')) {
          reject(new ForbiddenBifogadHandlingFiletypeException('Uploaded file was rejected, one cause for this is that the file contained a MIME-type which is not allowed'))
        } else if (jqXHR.status === 404 && jqXHR.responseText && jqXHR.responseText.includes('ArendeNotFoundException')) {
          reject(new ForbiddenKompletteringException('Ärendet med ärendenummer: ' + data.arendenummer + ' kunde inte hittas'))
        } else if (jqXHR.status === 400 && jqXHR.responseText && jqXHR.responseText.includes('TooManyFilesInBifogadHandlingException')) {
          reject(new TooManyFilesInBifogadHandlingException('Exceeded limit for number of files/pages in PDF for a bifogad kartskiss'))
        } else if (jqXHR.status === 400 && jqXHR.responseText && jqXHR.responseText.includes('TooLongTextException')) {
          reject(new TooLongTextException('Exceeded limit for number of characters in Yrkande-text'))
        } else if (jqXHR.status === 400 && jqXHR.responseText && jqXHR.responseText.includes('InvalidEmailException')) {
          reject(new InvalidEmailException('Invalid email submitted for notifiering or inbjudan'))
        } else {
          reject(error)
        }
      }
    })
  }).catch((error) => { throw error})
}

function startKompletteringSigning (fetcher, kompletteringId, theData, url) {
  fetcher.setGlobalErrorHandling(false)

  return fetcher.fetching(url, {
    method: 'POST',
    cache: 'no-store',
    body: theData ? JSON.stringify(theData) : undefined,
  }, { 'Content-Type': 'application/json' })
    .then((signingWebUrl) => {
      log.info('Signing instance for komplettering successfully created')
      return signingWebUrl.json()
    }).then((signingWebUrl) => {
      return signingWebUrl;
    }).catch((err) => {
      // Custom felhantering
      log.error('Error encountered while initiating signering request to server')
      const error = new AjaxClientError(repositoryUrl, err.status, err, err.statusText)
      logError(error)
      if (err.status === 404 && err.body && err.body.exception.includes('KompletteringNotFoundException')) {
        throw (new KompletteringNotFoundException('Komplettering med kompletteringId: ' + kompletteringId + ' kunde inte hittas'))
      } else {
        throw error
      }
    })
}

export function signStart (fetcher, kompletteringId, signingInvitationRequest) {
  const url = repositoryUrl + '/bitradande/' + kompletteringId + '/signstart'
  const medsokandeInformation = {
    'notifieringEmail': signingInvitationRequest.notifieringEmail,
    'inbjudan': signingInvitationRequest.inbjudna
  }
  return startKompletteringSigning(fetcher, kompletteringId, medsokandeInformation, url)
}

export function signStartPhos (fetcher, kompletteringId) {
  const url = repositoryUrl + '/ytterligarehandling/' + kompletteringId + '/signstart'
  return startKompletteringSigning(fetcher, kompletteringId, undefined, url)
}

export function signAbort (fetcher, kompletteringId) {
  const formData = new FormData()
  formData.append('kompletteringId', kompletteringId)
  const url = repositoryUrl + '/' + kompletteringId + '/signing/abort'
  fetcher.setGlobalErrorHandling(false)

  return fetcher.fetching(url, {
    method: 'POST',
    cache: 'no-store',
    body: formData,
  }).then(() => {
    log.info('Komplettering signering successfully aborted')
    return (kompletteringId)
  }).catch((err) => {
    // Custom felhantering
    log.error('Error encountered while aborting komplettering signering')
    const error = new AjaxClientError(repositoryUrl, err.status, err, err.statusText)
    logError(error)

    if (err.status === 404 && err.body && err.body.exception.includes('KompletteringNotFoundException')) {
      return (new KompletteringNotFoundException('Komplettering med kompletteringId: ' + kompletteringId + ' kunde inte hittas'))
    } else {
      throw error
    }
  })
}

export function loadSigningStatus (fetcher, kompletteringId) {
  const url = repositoryUrl + '/' + kompletteringId
  return fetcher.fetching(url, { cache: 'no-store' })
    .then((response) => {
      return response.json()
    }).then((signeringStatus) => {
      log.info('Kompletteringstatus succesfully loaded from server', kompletteringId)
      return signeringStatus
    }).catch((error) => {
      log.error('Error encountered while loading kompletteringstatus', kompletteringId)
      throw error
    })
}

export function deleteKomplettering (fetcher, kompletteringId) {
  if (!kompletteringId) {
    throw new Error('The id argument is missing')
  }
  const url = repositoryUrl + '/' + kompletteringId

  return fetcher.fetching(url, { cache: 'no-store', method: 'DELETE' })
    .then(() => {
      log.info('Komplettering successfully deleted on server')
      return true
    })
    .catch((error) => {
      // Global hantering av fel
      log.error('Error encountered while deleting komplettering on server')
      throw error
    })
}

export function kompletteringSubmit (fetcher, kompletteringId) {
  if (!kompletteringId) {
    throw new Error('The id argument is missing')
  }
  const url = repositoryUrl + '/' + kompletteringId + '/submit'

  return fetcher.fetching(url, { cache: 'no-store', method: 'POST' })
    .then((response) => {
      log.info('Komplettering is successfully submitted on server')
      return response.json()
    }).then((response) => {
      return response
    })
    .catch((error) => {
      // Global hantering av fel
      log.error('Error encountered while submitting komplettering on server')
      throw error
    })
}