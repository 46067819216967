import {useEffect, useState } from 'react'
//@ts-ignore
import { ButtonTop, FormFieldset, TextInput } from 'redet-react-components'
import { email } from '../../../utils/validation'

type Props = {
  handleNotifigeringsMail: (email?: string) => {},
  email: string,
  //Injectas med clone element från WizardDialogStep :sick!
  nextStep?: () => void
}
export default function AngeKvittensmottagareStepWizard(props: Props) {

  const [kvittensEmail, setKvittensEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    validateEmail(props.email);
  }, [])

  function validateEmail (value: string) {
    let validationMessage = email(value)
    setKvittensEmail(value);
    setEmailError(validationMessage);
    setNextButtonDisabled(validationMessage !== undefined || value === undefined || value === '');
  }

  function gotoNextStep () {
    props.handleNotifigeringsMail(kvittensEmail);

    if(props.nextStep){
      props.nextStep()
    }
  }

    return (
      <>
        <FormFieldset title={'Uppdateringar och kvittenser'}
          description={<>Här anger du <b>din egen</b> e-postadress. När biträdandet är signerat av
            samtliga inbjudna och
            inskickat till Lantmäteriet kommer en kvittens att skickas till denna e-postadress.</>}>
          <div className="form-group">
            <TextInput id={'signatoryEmailInput'}
                       isRequired={true}
                       validationErrorMsg={emailError}
                       maxLength={100}
                       name={'Kvittensepost'}
                       type={'email'}
                       autoComplete={"email"}
                       label={'Epost'}
                       defaultValue={props.email}
                       onChange={(evt: React.ChangeEvent<HTMLInputElement>) => validateEmail(evt.target.value)}/>
          </div>
        </FormFieldset>
        {/* eslint-disable-next-line rrc-recommendations/replace-with-rrc */}
        <div className="toolbar">
          <div className="button-group"/>
          <div className="button-group">
            <ButtonTop text={'Ange medsökande'}
                       id="nextStepAddMedsokande"
                       onClick={gotoNextStep}
                       isDisabled={nextButtonDisabled}
                       icon={'arrow_forward'}/>
          </div>
        </div>
      </>
    )
  }


