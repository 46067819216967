import { ARENDE_STATUS } from '../../utils/globalConstants'

export function combineArendenWithSvarslistor (arenden, svarslistor) {
  let svarslistorWithArendenummerAsKey = setArendenummerAsKey(svarslistor)
  for (const arende of arenden) {
    if (svarslistorWithArendenummerAsKey.length === 0) {
      break
    }
    if (svarslistorWithArendenummerAsKey[arende.nummer] !== undefined) {
      const svarsListaHasBeenAnswerd = svarslistorWithArendenummerAsKey[arende.nummer].every(svarslistaEntry => svarslistaEntry.svar !== undefined)
      const arendeIsDone = arende.status === ARENDE_STATUS.REGISTRERING || arende.status === ARENDE_STATUS.SLUTFORT

      if (!svarsListaHasBeenAnswerd && !arendeIsDone) {
        arende.svarslistor = svarslistorWithArendenummerAsKey[arende.nummer].filter(svarslistaEntry => svarslistaEntry.svar === undefined)
      }

      delete svarslistorWithArendenummerAsKey[arende.nummer]
    }
  }
  return arenden
}

export function setArendenummerAsKey (svarslistor) {
  let result = {}
  svarslistor.forEach(svarslista => {
    if (result[svarslista.arendenummer] !== undefined)
      result[svarslista.arendenummer].push(svarslista)
    else
      result[svarslista.arendenummer] = [svarslista]
  })
  return result
}
