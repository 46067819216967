import ChoiceOfRealEstate from "./euCitizenApplication/ChoiceOfRealEstate";
import {AnsokanWizardSDGContext} from "./euCitizenApplication/AnsokanWizardSDGContext";
// @ts-ignore
import {Helmet} from 'react-helmet'
import {Route, Routes, useNavigate} from "react-router-dom";
import UploadApplicationForm from "./euCitizenApplication/UploadApplicationForm";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AdditionalDocuments from "./euCitizenApplication/AdditionalDocuments";
import {useTranslation} from "react-i18next";
import {Navigation} from "components";
import CheckAndSubmit from "./euCitizenApplication/CheckAndSubmit";
import {WizardLinks} from "./euCitizenApplication/LinksForSDG";
import HjalpForSDG from "./euCitizenApplication/HjalpForSDG";
import {useCallback, useContext, useEffect} from "react";
import {SubmitResult} from "./euCitizenApplication/SubmitResult";


function AnsokanWizardSDG() {

    const {ansokan, result} = useContext(AnsokanWizardSDGContext);

    const navigate = useNavigate();

    const navigateToFirstStepInWizard = useCallback(() => {
        navigate("pdfApplication")
    }, [navigate]);

    useEffect(() => {
        if (ansokan === undefined && result === undefined) {
            navigateToFirstStepInWizard()
        }
    }, [ansokan, result, navigateToFirstStepInWizard])

    const queryClient = new QueryClient()
    const {t} = useTranslation();

    return (
        <>
            <header>
                <Navigation showNotiserBtn={false} showMinSidaBtn={false} showLanguageSelector={true}
                            showNormalToUModal={false} showSDGToUModal={true}/>
                <Helmet>
                    <title>{t('sdg_wizard.title')}</title>
                </Helmet>
            </header>
            <div className="container">
                <main>
                    <h1>{t('sdg_wizard.title')}</h1>
                    <br/>
                    <QueryClientProvider client={queryClient}>
                        <WizardLinks/>
                        <Routes>
                            <Route path={`pdfApplication`} element={
                                <UploadApplicationForm/>
                            }/>
                            <Route path={`additionalDocuments`} element={
                                <AdditionalDocuments/>
                            }/>
                            <Route path={`realEstates`} element={
                                <ChoiceOfRealEstate/>
                            }/>
                            <Route path={`reviewAndSubmit`} element={
                                <CheckAndSubmit/>
                            }/>
                            <Route path={`kvittens`} element={
                                <SubmitResult/>
                            }/>
                        </Routes>
                        {/* {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools initialIsOpen={false} />} */}
                    </QueryClientProvider>
                </main>
            </div>
            <br/>
            <HjalpForSDG/>
        </>
    );
}

export default (AnsokanWizardSDG);
