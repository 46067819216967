//@ts-ignore
import { ButtonSub, ButtonToolbar, ModalDialogSub, } from 'redet-react-components'

type AddContactsProps = {
    closeModalCallback: Function,
    onAddPrivatperson: Function,
    onAddOrganisation: Function
}

export default function AddManualContactsModal (props: AddContactsProps) {
 
  const addPrivatPerson = () => {
    props.closeModalCallback()
    props.onAddPrivatperson()
  }

  const addOrg = () => {
    props.closeModalCallback()
    props.onAddOrganisation()
  }

  return (
    <ModalDialogSub
      modalTitle="Lägg till part manuellt"
      id="addContactManuallyModal"
      useDefaultToolbar={false}
      description="Saknas någon part i förrättningen? Om någon part saknas kan du lägga till en privatperson eller organisation manuellt."
      closeModalCallback={props.closeModalCallback}
    >
      <>
        <ButtonToolbar>
          <div className="button-group">
            <ButtonSub
              text={'Lägg till privatperson'}
              onClick={addPrivatPerson}
              data-selenium-id="kontakt-ny-privatperson"
              id="addIndividualContactButtonId"
              icon={'person_add'}
            />
            <ButtonSub
              text={'Lägg till organisation'}
              onClick={addOrg}
              data-selenium-id="kontakt-ny-organisation"
              id="addOrganizationContactButtonId"
              icon={'group_add'}
            />
            <ButtonSub
              text={'Avbryt'}
              onClick={props.closeModalCallback}
              data-selenium-id="modalDialogDecline"
              icon={'clear'}
            />
          </div>
        </ButtonToolbar>
      </>
    </ModalDialogSub>
  )
}

