import {createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState} from "react";

export type ArendeViewContextType = {
    showCloseModalConfirmationDialog: boolean
    setShowCloseModalConfirmationDialog: Dispatch<SetStateAction<boolean>>
    onConfirmClose: Function
    setOnConfirmClose: Dispatch<SetStateAction<Function>>
}

export const ArendeViewContext = createContext<ArendeViewContextType>({
    showCloseModalConfirmationDialog: false,
    setShowCloseModalConfirmationDialog: () => {},
    onConfirmClose: () => {},
    setOnConfirmClose: () => {},
})

export function ArendeViewProvider(props: { children: ReactNode }) {
    const [showCloseModalConfirmationDialog, setShowCloseModalConfirmationDialog] = useState<boolean>(false)
    const [onConfirmClose, setOnConfirmClose] = useState<Function>(() => {})

    return (
        <ArendeViewContext.Provider value={{
            showCloseModalConfirmationDialog,
            setShowCloseModalConfirmationDialog,
            onConfirmClose,
            setOnConfirmClose,
        }}>
            {props.children}
        </ArendeViewContext.Provider>
    )
}