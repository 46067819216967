import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    // @ts-ignore
    Column, FormFieldset, Row, TextArea, TextInput, VarningsRuta, WizardIntorduction, WizardNavigationButtonToolbar
} from "redet-react-components";
import { validatePhonenumber } from "utils/telephoneNumberValidation";
import { betweenLength, email } from "utils/validation";
import { Term } from "views";
import {getLocalizedDateString} from "../../../helpers/dates";
import {filterAnsokningar, getUniqueListOfDocuments} from "../../../utils/helperMethods";
import {Forrattningsarende} from "../../../domain/Forratningsarende/Forratningsarende";
import {removeAktbilagebeteckning, setAktbilagebeteckning} from "../../../redux/modules/arendeKompletteringar";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useAppDispatch} from "../../../hooks";


type Props = {
    meddelande: string
    setMeddelande: Dispatch<SetStateAction<string>>
    epostKontakt: string
    setEpostKontakt: Dispatch<SetStateAction<string>>
    telefonKontakt: string
    setTelefonKontakt: Dispatch<SetStateAction<string>>
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
    arende: Forrattningsarende
}

export function UpprattaAterkallandeStep(props: Props) {
    const MAX_LENGTH_TEXT_AREA = 2000;
    const [meddelandeValidationError, setMeddelandeValidationError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    //userHasClickedOnNextButton är för att visa felmeddelande först efter första klicket
    const [userHasClickedOnNextButton, setUserHasClickedOnNextButton] = useState<boolean>(false);
    const [epostKontaktValidationError, setEpostKontaktValidationError] = useState<string>('');
    const [telefonValidationError, setTelefonValidationError] = useState<string>('');
    const [listOfDocuments] = useState (getUniqueListOfDocuments(filterAnsokningar(props.arende?.documentDetails)))
    const selectedAktbilagebeteckningar = useSelector((store: RootState) =>  store.arendeKompletteringar.selectedAktbilagebeteckningar);
    const dispatch = useAppDispatch();
    let staticText = "Härmed bekräftar jag att ansökan bör återkallas. Anledning: ";

    useEffect(() => {
        checkIfErrorExistsBeforeLeavingPage()
    });

    function handleSelectChange (evt: any) {
        if (evt.target.checked) {
            dispatch(setAktbilagebeteckning(evt.target.value))
        } else {
            dispatch(removeAktbilagebeteckning(evt.target.value))
        }
    }

    function checkIfErrorExistsBeforeLeavingPage () {
        if(meddelandeValidationError || !props.telefonKontakt || !props.epostKontakt || telefonValidationError || epostKontaktValidationError) {
            setErrorMessage(true)
            return true
        } else {
            setErrorMessage(false)
            return false
        }
    }

    function nextButton () {
        setUserHasClickedOnNextButton(true)
        if(!errorMessage) {
            if(!props.meddelande.includes('Härmed bekräftar jag att ansökan bör återkallas.')) {
                props.setMeddelande(staticText + props.meddelande)
            }
            dispatch(props.nextStep)
        }
    }

    return <>
        <WizardIntorduction
            title={"Återkallande till handläggare"}
            ingress={(<>
                Ett <Term label="återkallande"
                          moment="help-komplettering"
                          term="term-aterkallande"/> som inkommer till myndigheten blir en allmän handling och kan komma att publiceras i denna E-tjänst för samtliga berörda i ärendet.
                Observera att ditt namn kommer att synas på det inskickade dokumentet.
            </>)}>
            <Row>
                {listOfDocuments?.length > 0 &&
                <Column>
                    <FormFieldset title={'Välj ansökan'} description={<>
                        Välj den eller de ansökningar som du vill <Term label="återkalla" moment="help-komplettering"
                                                                        term="term-aterkallande"/>.
                    </>}>
                        <div id="ansokan-select-list" className="with-top-margin">
                            {listOfDocuments.map((document, index) =>
                                <div className="form-checkbox" key={'key_' + document.akt + document.documentName}>
                                    <label htmlFor={'ansokanCheckbox' + index}>
                                        <input id={'ansokanCheckbox' + index} type="checkbox"
                                            //@ts-ignore
                                               checked={selectedAktbilagebeteckningar.includes(document.akt)} value={document.akt}
                                               onChange={(evt) => handleSelectChange(evt)}/>
                                        <span className="text">{document.documentName},<span
                                            className="text"> publicerad {getLocalizedDateString(document.publishedDate, 'd LLLL y')}, aktbilaga <span
                                            className="item">{document.akt}</span></span></span>
                                    </label>
                                </div>
                            )}
                        </div>
                    </FormFieldset>
                </Column>
                }
                {listOfDocuments?.length > 0 &&
                <>
                    <Column className="col-12 col-lg-6">
                        <FormFieldset title={"Anledning"} description={<>
                            Förklara varför du vill återkalla  <Term label="återkalla"
                                                                     moment="help-komplettering"
                                                                     term="term-aterkallande"/> ansökan.
                        </>}>
                            <TextArea label={"Ange motivering (valfritt)"}
                                      maxLength={MAX_LENGTH_TEXT_AREA}
                                      characterLimit={MAX_LENGTH_TEXT_AREA}
                                      isRequired={false}
                                      id={"textAreaId"}
                                      value={props.meddelande}
                                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                          props.setMeddelande(evt.target.value)
                                      }}
                                      placeholder={"Max antal tecken 2000"}
                                      validationErrorMsg={meddelandeValidationError}
                            />
                        </FormFieldset>
                        <TextInput
                            label={"E-post"}
                            isRequired={true}
                            type={"email"}
                            autoComplete={'email'}
                            id={"mailId"}
                            maxLength={100}
                            validationErrorMsg={epostKontaktValidationError}
                            value={props.epostKontakt}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                const error = email(evt.target.value)
                                if (error) {
                                    setEpostKontaktValidationError(error)
                                    props.setEpostKontakt(evt.target.value)
                                } else {
                                    setEpostKontaktValidationError('')
                                    props.setEpostKontakt(evt.target.value)
                                }
                            }}
                        />
                        <TextInput
                            label={"Telefonnummer"}
                            isRequired={true}
                            id={"phoneId"}
                            autoComplete={'tel'}
                            validationErrorMsg={telefonValidationError}
                            value={props.telefonKontakt ? props.telefonKontakt : ''}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                const error = validatePhonenumber(evt.target.value)
                                if (error) {
                                    setTelefonValidationError(error);
                                    props.setTelefonKontakt(evt.target.value)
                                }
                                else {
                                    setTelefonValidationError('')
                                    props.setTelefonKontakt(evt.target.value)
                                }
                            }}
                        />
                    </Column>

                </>
                }
                {listOfDocuments?.length < 1 &&
                    <>
                    <Row>
                        <Column columns={"form-group col-xs-12 col-sm-12 col-xl-8"}>
                        <FormFieldset title={"Anledning"} description={<>
                            Förklara varför du vill återkalla  <Term label="återkalla"
                                                                            moment="help-komplettering"
                                                                            term="term-aterkallande"/> ansökan.
                        </>}>
                            <TextArea label={"Ange motivering (valfritt)"}
                                      maxLength={MAX_LENGTH_TEXT_AREA}
                                      characterLimit={MAX_LENGTH_TEXT_AREA}
                                      isRequired={false}
                                      id={"textAreaId"}
                                      value={props.meddelande}
                                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                          props.setMeddelande(evt.target.value)
                                      }}
                                      placeholder={"Max antal tecken 2000"}
                                      validationErrorMsg={meddelandeValidationError}
                            />
                        </FormFieldset>
                        </Column>
                    </Row>
                        <Row>
                        <Column columns={"form-group col-xs-12 col-sm-6 col-xl-5"}>
                    <TextInput
                        label={"E-post"}
                        isRequired={true}
                        type={"email"}
                        autoComplete={'email'}
                        id={"mailId"}
                        maxLength={100}
                        validationErrorMsg={epostKontaktValidationError}
                        value={props.epostKontakt}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const error = email(evt.target.value)
                            if (error) {
                                setEpostKontaktValidationError(error)
                                props.setEpostKontakt(evt.target.value)
                            } else {
                                setEpostKontaktValidationError('')
                                props.setEpostKontakt(evt.target.value)
                            }
                        }}
                    />
                    <TextInput
                        label={"Telefonnummer"}
                        isRequired={true}
                        id={"phoneId"}
                        autoComplete={'tel'}
                        validationErrorMsg={telefonValidationError}
                        value={props.telefonKontakt ? props.telefonKontakt : ''}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const error = validatePhonenumber(evt.target.value)
                            if (error) {
                                setTelefonValidationError(error);
                                props.setTelefonKontakt(evt.target.value)
                            }
                            else {
                                setTelefonValidationError('')
                                props.setTelefonKontakt(evt.target.value)
                            }
                        }}
                    />
                        </Column>
                        </Row>
                    </>
                }
            </Row>
        </WizardIntorduction>
        <WizardNavigationButtonToolbar
            isPrevButtonDisabled={true}
            nextButtonText={"Ange e-post för kvittens"}
            onClickNextButton={() => nextButton()}/>
        {errorMessage && userHasClickedOnNextButton &&
        <VarningsRuta errorMessages={["Du måste fylla i både e-post och telefonnummer."]} ariaTitle={"Felmeddelande"}/>}
    </>;
}
export default UpprattaAterkallandeStep;