import {
    ANSOKAN_STATUS_DRAFT,
    ANSOKAN_STATUS_LOCKED,
    ANSOKAN_STATUS_REVIEW_EMAILS,
    ANSOKAN_STATUS_SIGNED,
    ANSOKAN_STATUS_SIGNING,
    ANSOKAN_STATUS_SUBMITTED,
} from "utils/globalConstants";
import {ISO8601} from "utils/globalConstants";
import {
    // @ts-ignore
    ListItem, ListItemActionButton
} from "redet-react-components";
import {undefinedAsString} from "helpers/textHelpers";
import {getLocalizedDateString} from "helpers/dates";
import {AnsokanListItemType} from "domain/AnsokanListItemType";
import {Link, useNavigate} from "react-router-dom";

type AnsokanListItemProps = {
    ansokan: AnsokanListItemType;
    deleteAnsokanCallback: (ansokanId: string) => void;
};

export const AnsokanListItem = (props: AnsokanListItemProps) => {
    const navigate = useNavigate();
    const statusToText = new Map<string, string>();
    statusToText.set(ANSOKAN_STATUS_DRAFT, "Utkast");
    statusToText.set(ANSOKAN_STATUS_LOCKED, "Låst för underskrift");
    statusToText.set(ANSOKAN_STATUS_REVIEW_EMAILS, "Låst för underskrift");
    statusToText.set(ANSOKAN_STATUS_SIGNING, "Underskrift påbörjad");
    statusToText.set(ANSOKAN_STATUS_SIGNED, "Redo att skickas in");
    statusToText.set(ANSOKAN_STATUS_SUBMITTED, "Inskickad");

    const statusToIcon = new Map<string, string>();
    statusToIcon.set(ANSOKAN_STATUS_DRAFT, "edit");

    const getModifiedDateAsText = (ansokan: AnsokanListItemType): string => {
        if (ansokan.ansokanStatus === ANSOKAN_STATUS_DRAFT) {
            return ansokan.modifiedDate
                ? undefinedAsString(
                    getLocalizedDateString(ansokan.modifiedDate.toISOString(), ISO8601)
                )
                : "";
        } else {
            return undefinedAsString(
                getLocalizedDateString(
                    ansokan.ansokanStatusModified.toISOString(),
                    ISO8601
                )
            );
        }
    };

    const ansokanUrl = `ansokan/${props.ansokan.ansokanId}`;
    const hasDraftStatus = props.ansokan.ansokanStatus === ANSOKAN_STATUS_DRAFT;

    const editAnsokanBtn = (
        <ListItemActionButton
            id={`visa${props.ansokan.ansokanId}`}
            key={`visa${props.ansokan.ansokanId}`}
            ariaLabel={"Editera"}
            icon={"edit"}
            popover={{text: "Ändra den här ansökan"}}
            onClick={() => navigate(ansokanUrl)}
        />
    );

    const removeAnsokanBtn = (
        <ListItemActionButton
            id={`taBort${props.ansokan.ansokanId}`}
            key={`taBort${props.ansokan.ansokanId}`}
            icon={"delete"}
            ariaLabel={"Ta bort"}
            isDisabled={!hasDraftStatus}
            onClick={
                !hasDraftStatus
                    ? undefined
                    : () => props.deleteAnsokanCallback(props.ansokan.ansokanId)
            }
            popover={{
                text: !hasDraftStatus
                    ? "Ansökningar som är låsta för underskrift måste låsas upp innan de kan tas bort"
                    : "Ta bort den här ansökan",
            }}
        />
    );

    const buttons = [editAnsokanBtn, removeAnsokanBtn];

    return (
        <ListItem
            id={`ansokan_${props.ansokan.ansokanId}`}
            key={`ansokan_key_${props.ansokan.ansokanId}`}
            title={`Ansökan ${getLocalizedDateString(
                props.ansokan.createdDate.toISOString(),
                ISO8601
            )}
          `}
            header={
                <Link to={ansokanUrl}>
                    Ansökan&nbsp;
                    {getLocalizedDateString(
                        props.ansokan.createdDate.toISOString(),
                        ISO8601
                    )}
                </Link>
            }
            description={`Aktualitetsdatum: ${getModifiedDateAsText(props.ansokan)}`}
            status={{
                icon: statusToIcon.get(props.ansokan.ansokanStatus) || "schedule",
                text: `Status: ${statusToText.get(props.ansokan.ansokanStatus) ||
                    "Felaktig status, kontakt kundtjänst"
                    }`,
            }}
            actionButtons={buttons}
        />
    );
};
