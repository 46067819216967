//@ts-ignore
import { ExternalLink, Ikon, SidePanelChapterHeading, SidePanelSection } from 'redet-react-components'

export const KartHjalpDrawing = (
  <>
    <SidePanelChapterHeading header={'Karta'}
                             sectionTitle={'Kartskisser'}
                             introduction={<>Här laddar du upp eller ritar en karta som förklarar vad du vill göra i förrättningen. Du kan antingen ladda upp en kartskiss själv, eller använda vår tjänst för att rita en karta. Nedan beskrivs hur karttjänsten fungerar.</>}/>
    <SidePanelSection id={'map-accuracy-id'}
                      heading={'Noggrannhet i kartan'}
                      text={'Koordinaterna och de knivskarpa linjerna i kartan ger ett exakt intryck, \n ' +
                        'men fastighetsgränserna visar inte alltid rätt och de är inte juridiskt gällande.\n ' +
                        'Det innebär att du inte med självklarhet bör utgå från kartans gränser om du vill bygga, \n' +
                        ' avverka skog eller göra andra åtgärder på en fastighet. Se mer information under länken nedan.'}>
      <ExternalLink text={'Korrekta gränser'}
                    url={'https://www.lantmateriet.se/korrektagranser'}
                    openInNewTab={true}
                    isStandAloneLink={true}/>
    </SidePanelSection>
    <SidePanelSection id={'map-zoom-id'}
                      heading={'Zooma i kartan'}
                      text={'Det går att zooma i kartan på tre olika sätt:\n' +
                        '            Du kan zooma in och ut genom att använda funktionen i kartan uppe till vänster i bild.\n' +
                        '            \\+ zoomar in och – zoomar ut. Du kan även använda zoomreglaget under +/- knapparna.\n' +
                        '            Du kan zooma in i kartan genom att dubbelklicka direkt i kartan.\n' +
                        '            Scrollhjulet på musen går att använda för att zooma in och ut i kartan.'}/>
    <SidePanelSection id={'map-change-map-id'}
                      heading={'Byt bakgrundskarta'}
                      text={'För att växla bakgrundskarta mellan den topografiska webbkartan och flygfoto klickar du på fönstret i det nedre vänstra\n' +
                        '                hörnet av kartan där den andra bakgrundskartan visas i miniatyr.'}/>

    <SidePanelSection id={'map-pan-tool-id'}
                      heading={<><i className="material-icons">pan_tool</i>Panorera</>}
                      text={'Du kan flytta dig runt i kartan genom att använda Panorera-handen.'}/>

    <SidePanelSection id={'map-dot-tool-id'}
                      heading={<><i className="material-icons">fiber_manual_record</i>Punkt</>}
                      text={'Välj punkt. En punkt skapas genom ett vänsterklick där du vill att punkten ska vara placerad. Du ges även möjlighet att i\n' +
                        '                text förklara vad den aktuella punkten avser, t ex vattenbrunn. För att färdigställa texten trycker du Enter. Vill du\n' +
                        '                skapa en punkt utan text klickar du bort textrutan uppe till höger.'}
    />
    <SidePanelSection id={'map-chart-tool-id'}
                      heading={<><i className="material-icons">show_chart</i>Linje</>}
                      text={'Välj linje. En linje skapas genom ett vänsterklick för varje brytpunkt på linjen. Dubbelklicka vid linjens slutpunkt när\n' +
                        '                du är klar. Högerklick kan även användas för att färdigställa linjen vid den senast placerade brytpunkten.'}/>

    <SidePanelSection id={'map-area-tool-id'}
                      heading={<><i className="material-icons">network_cell</i>Yta</>}
                      text={'Välj yta. En yta skapas genom ett vänsterklick för varje brytpunkt på ytans begränsningslinje. Dubbelklicka där du vill ha\n' +
                        '                din sista brytpunkt eller enkelklicka på den första brytpunkten för att färdigställa ytan. Högerklick kan även användas\n' +
                        '                för att färdigställa ytan vid den senast placerade brytpunkten.'}
    />
    <SidePanelSection id={'map-text-tool-id'}
                      heading={<><i className="material-icons">font_download</i>Text</>}
                      text={'Välj text för att skapa förklarande texter i kartan.\n' +
                        '                Vänsterklicka med musen i kartan där texten ska placeras, fyll sedan i text.\n' +
                        '                Tryck Enter för att färdigställa texten.'}/>


    <SidePanelSection id={'map-edit-tool-id'}
                      heading={<><i className="material-icons">create</i>Redigera</>}
                      text={'Välj redigera om du vill justera något du själv gjort i kartan.'}>
      <h5>Flytta brytpunkt</h5>
      <p>Vänsterklicka på en orange brytpunkt och dra punkten för att flytta den till rätt läge.</p>
      <h5>Lägg till brytpunkt</h5>
      <p>Vänsterklicka på en linje för att lägga till en brytpunkt mellan två befintliga brytpunkter.</p>
      <h5>Text</h5>
      <p>Vänsterklicka med musen på den orange brytpunkten för att ändra den text som du skrivit.</p>
    </SidePanelSection>

    <SidePanelSection id={'map-move-tool-id'}
                      heading={<><Ikon name={'open_with'}/>Flytta</>}
                      text={'Välj flytta om du vill flytta en punkt, linje, yta eller text. Ta tag i en orange brytpunkt på objektet genom att trycka\n' +
                        '                in musens vänsterknapp och dra sedan objektet till rätt läge. Hela objektet flyttas.'}/>

    <SidePanelSection id={'map-remove-tool-id'}
                      heading={<><Ikon name={'clear'}/>Ta bort</>}
                      text={'Välj ta bort om du vill du ta bort en punkt, linje, yta eller text genom att klicka på en av de orange brytpunkterna för\n' +
                        '                att ta bort objektet.'}/>

    <SidePanelSection id={'map-measure-line-tool-id'}
                      heading={<><Ikon name={'straighten'}/>Mät med linje</>}>
      <p>Fungerar likadant som <strong>Linje med mått</strong> men figuren försvinner när du avmarkerar mätverktygen
        eller stänger kartan.
      </p>
    </SidePanelSection>

    <SidePanelSection id={'map-measure-line-area-tool-id'}
                      heading={<><Ikon name={'straighten'}/>Mät med yta</>}>
      <p>Fungerar likadant som <strong>Yta med mått</strong> men figuren försvinner när du avmarkerar mätverktygen eller
        stänger kartan.</p>
    </SidePanelSection>

    <SidePanelSection id={'map-done-tool-id'}
                      heading={<><Ikon name={'check_circle'}/>Slutför kartskiss</>}
                      text={'Används när du är klar med din kartskiss och vill spara.'}/>

    <SidePanelSection id={'map-cancel-tool-id'}
                      heading={<><Ikon name={'cancel'}/>Avbryt</>}>
      <p>Används för att stänga kartskissen och komma tillbaka till ansökningssidan utan att kartskissen sparas. Om du
        inte vill
        spara dina ändringar klickar du <strong>Ja</strong> när du får frågan om du vill stänga utan att spara. Klickar
        du <strong>Nej</strong> återvänder du till
        kartan.</p>
    </SidePanelSection>

    <SidePanelSection id={'map-teckenforklaring-id'}
                      heading={'Teckenförklaring'}>
      <ExternalLink text={'Fastighetsgränser'}
                    url={'https://www.lantmateriet.se/sv/Kartor-och-geografisk-information/geodataprodukter/produktlista/fastighetsindelning-visning/#steg=3'}
                    openInNewTab={true}
                    isStandAloneLink={true}/>
      <ExternalLink text={'Topografisk Webbkarta'}
                    url={'https://www.lantmateriet.se/sv/Kartor-och-geografisk-information/geodataprodukter/produktlista/topografisk-webbkarta-visning/#steg=3'}
                    openInNewTab={true}
                    isStandAloneLink={true}/>
    </SidePanelSection>

    <SidePanelSection id={'map-map-layer-id'}
                      heading={'Kartskiktsförklaring'}
                      text={'Länkar till Lantmäteriets kartskiktsförklaringar:'}>
      <ExternalLink text={'Ortofoto'}
                    url={'https://www.lantmateriet.se/sv/geodata/vara-produkter/produktlista/ortofoto-visning/'}
                    openInNewTab={true}
                    isStandAloneLink={true}/>
      <ExternalLink text={'Topografisk Webbkarta'}
                    url={'https://www.lantmateriet.se/sv/Kartor-och-geografisk-information/geodataprodukter/produktlista/topografisk-webbkarta-visning-skiktindelad/#steg=3'}
                    openInNewTab={true}
                    isStandAloneLink={true}/>
    </SidePanelSection>
  </>
)