import {saveAnsokan} from 'redux/modules/ansokanCRUD'
import {fetchAnsokanStatus, lockAnsokan} from 'redux/modules/ansokanSPECIAL'
import {addProgress, endProgress} from 'redux/modules/fullscreenLoading'
import {useAppDispatch, useAppSelector} from 'hooks'
import {useNavigate} from "react-router-dom"
// @ts-ignore
import {ButtonToolbar, ButtonTop, ButtonTopWithPopover} from 'redet-react-components'
import {logError} from "../../utils/errorHandler"
import {NO_SCROLLING_CSS_CLASS} from '../../utils/globalConstants'
import React from "react";
import {showValidationpanel} from "../../redux/modules/help";

type Props = {
    hasValidationErrors: boolean
}

export const FormSubmit = () => {

    //ansokanCrud och ansokanSpecial är ännu ej korrekt typade så sätter därför typen manuellt.
    const remoteDataKey = useAppSelector((store) => store.ansokanRepository?.remoteDataKey) as string | null;
    const validationErrors = useAppSelector((store) => store.validation.errors)
    const dispatch = useAppDispatch();
    const navigate =  useNavigate()

    const validationErrorsExists = () => {
        return validationErrors.filter(validationError => validationError.severity === 'error').length > 0
    }

    const hasValidationErrors = validationErrorsExists()

    const triggerSaveAnsokan = () => {
        dispatch(addProgress(true))
        dispatch(saveAnsokan())
            .then(() => {
                setAnsokanAsLocked()
            }).catch((err: Error) => {
                logError(err, 'SaveAnsokan on submit failed')
            })
    }

    const setAnsokanAsLocked = () => {
        dispatch(lockAnsokan(remoteDataKey!))
            .then(() => {
                setTimeout(refreshStatus, 500)
            })
    }

    const fortsattTillUnderskriftIfNoErrorsExists = () => {
        if (hasValidationErrors) {
            dispatch(showValidationpanel(true))
        } else {
            document.body.classList.remove(NO_SCROLLING_CSS_CLASS)
            triggerSaveAnsokan()
        }
    }

    const refreshStatus = () => {
        dispatch(addProgress(true))
        dispatch(fetchAnsokanStatus(remoteDataKey!))
            .then(() => {
                navigate('../')
                setTimeout(() => dispatch(endProgress()), 500)
            })
    }

    return <>
        <ButtonTop
            text="Skriv under"
            icon="arrow_forward"
            id="lockAnsokanAndContinueToSigningButton"
            isActive={remoteDataKey}
            isDisabled={!remoteDataKey}
            onClick={fortsattTillUnderskriftIfNoErrorsExists}
            data-selenium-id="ansokan-lock"/>

        {hasValidationErrors &&
            <span className="button-description">Du måste åtgärda de stoppande varningar som finns innan du kan skriva under</span>
        }
    </>;
}

export default FormSubmit;