import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayNextStep, displayPreviousStep } from 'redux/modules/wizardDialog'
import {
  displayFullscreenProgress,
  hideFullscreenProgress,
  updateFullscreenProgress
} from 'redux/modules/fullscreenProgress'
import { pendingFiles, signKompletteringStart } from 'redux/modules/kompletteringBifogaHandling'
import { uploadKompletteringForBitradande } from 'redux/modules/ansokanKomplettering'
import {
  clearAktbilagebeteckningar,
  reloadKompletteringar,
  removeAktbilagebeteckning,
  setAktbilagebeteckning
} from 'redux/modules/arendeKompletteringar'
import { addProgress, endProgress } from 'redux/modules/fullscreenLoading'
import { filterAnsokningar, getUniqueListOfDocuments } from 'utils/helperMethods'
import ValjAnsokanStepWizard from './kompletteringWizard/ValjAnsokanStepWizard'
import LaggTillMedsokandeStepWizard from './kompletteringWizard/LaggTillMedsokandeStepWizard'
import { fetchKompletteringar, removeKomplettering } from '../../redux/modules/arendeKompletteringar'
import WizardDialog, { WizardDialogStep } from '../../components/wizard/WizardDialog'
import ModalDialogError from '../../components/General/ModalDialogError'
import { logError } from '../../utils/errorHandler'
import AngeKvittensmottagareStepWizard from './kompletteringWizard/AngeKvittensmottagareStepWizard'

const mapStateToProps = (store) => {
  return {
    arende: store.arende.arende,
    pendingFiles: store.bifogadHandlingKomplettering.pendingFiles,
    isUploading: store.bifogadHandlingKomplettering.isUploading,
    featureToggleKompletteringService: store.featureToggle.KOMPLETTERING_SERVICE_MOCK_ENABLED,
    selectedAktbilagebeteckningar: store.arendeKompletteringar.selectedAktbilagebeteckningar,
  }
}

const mapDispatchToProps = {
  uploadKompletteringForBitradande,
  signKompletteringStart,
  displayFullscreenProgress,
  updateFullscreenProgress,
  hideFullscreenProgress,
  displayNextStep,
  displayPreviousStep,
  pendingFiles,
  addProgress,
  endProgress,
  reloadKompletteringar,
  setAktbilagebeteckning,
  removeAktbilagebeteckning,
  clearAktbilagebeteckningar,
  fetchKompletteringar,
  removeKomplettering
}

export class AnsokanKompletteringWizardAddSignatoriesForm extends Component {

  static propTypes = {

    arende: PropTypes.object.isRequired,
    // constructor
    arendenummer: PropTypes.string.isRequired,

    // bifogadHandlingKomplettering
    isUploading: PropTypes.bool.isRequired,
    pendingFiles: PropTypes.func.isRequired,
    uploadKompletteringForBitradande: PropTypes.func.isRequired,
    signKompletteringStart: PropTypes.func.isRequired,

    // Fullscreen progress
    displayFullscreenProgress: PropTypes.func.isRequired,
    updateFullscreenProgress: PropTypes.func.isRequired,
    hideFullscreenProgress: PropTypes.func.isRequired,

    // WizardDialog
    displayNextStep: PropTypes.func.isRequired,
    displayPreviousStep: PropTypes.func.isRequired,

    // arendeKompletteringar
    reloadKompletteringar: PropTypes.func.isRequired,
    setAktbilagebeteckning: PropTypes.func.isRequired,
    removeAktbilagebeteckning: PropTypes.func.isRequired,
    clearAktbilagebeteckningar: PropTypes.func.isRequired,
    fetchKompletteringar: PropTypes.func.isRequired,
    selectedAktbilagebeteckningar: PropTypes.array.isRequired,

    // övrigt
    featureToggleKompletteringService: PropTypes.bool.isRequired,
    addProgress: PropTypes.func.isRequired,
    endProgress: PropTypes.func.isRequired,
    closeWizardFunction: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.state = {
      errorMessage: undefined,
      notifieringEmail: '',
      inbjudna: '',
      listOfDocuments: getUniqueListOfDocuments(filterAnsokningar(this.props.arende.documentDetails))
    }

    // Bind this to class methods instead of older ::
    this.onUploadComplete = this.onUploadComplete.bind(this)
    this.onUploadFailed = this.onUploadFailed.bind(this)
    this.onSigningCompleted = this.onSigningCompleted.bind(this)
    this.onSigningFailed = this.onSigningFailed.bind(this)
    this.startSigningBitradande = this.startSigningBitradande.bind(this)
    this.startUpload = this.startUpload.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.fetchKompletteringAndHandleError = this.fetchKompletteringAndHandleError.bind(this)
    this.handleNotifieringsEmail = this.handleNotifieringsEmail.bind(this)
  }

  componentWillUnmount () {
    this.props.clearAktbilagebeteckningar()
  }

  async onUploadComplete (id, notifieringEmail, inbjudna) {
    setTimeout(this.props.hideFullscreenProgress, 500)

    if (id === undefined || id === null) {
      this.setState({
        errorMessage: {
          title: 'Tekniskt fel',
          message:
            <span>Ett tekniskt fel inträffade vid skapande av biträdande komplettering. Försök igen senare genom att klicka på <strong>Bjud in medsökande</strong> och börja om.</span>,
          button: {
            text: 'OK', icon: 'check', onClickFunction: () => {
              this.props.closeWizardFunction(false)
              this.props.reloadKompletteringar(true)
              this.props.fetchKompletteringar(this.props.arendenummer)
            }
          }
        },
        closeWizardOnSignStartError: true
      })
    } else {
      await this.startSigningBitradande(id, notifieringEmail, inbjudna)
    }
    this.fetchKompletteringAndHandleError(this.props.arendenummer)
  }

  onUploadFailed (err) {
    this.setState({ errorMessages: [] })
    err.component = 'AnsokanKompletteringWizardAddSignatoriesForm.js'
    logError(err, 'AnsokanKompletteringWizardAddSignatoriesForm-component')
    this.props.hideFullscreenProgress()
    this.setState({
      errorMessage: {
        title: 'Tekniskt fel',
        message:
          <span>Ett tekniskt fel inträffade vid skapande av biträdande komplettering. Försök igen senare genom att klicka på <strong>Bjud in medsökande</strong> och börja om.</span>,
        button: {
          text: 'OK', icon: 'check', onClickFunction: () => {
            this.props.closeWizardFunction(false)
            this.props.reloadKompletteringar(true)
            this.props.fetchKompletteringar(this.props.arendenummer)
          }
        }
      },
      closeWizardOnSignStartError: true
    })
  }

  onSigningCompleted () {
    this.props.endProgress()
    this.props.closeWizardFunction(true)
    this.props.reloadKompletteringar(true)
  }

  onSigningFailed (kompletteringsId, error) {
    this.props.endProgress()

    this.props.removeKomplettering(kompletteringsId)

    this.setState({
      errorMessage: {
        title: 'Fel vid inbjudan',
        message:
          <span>Ett fel inträffade när e-post skulle skickas. Försök igen senare genom att klicka på <strong>Bjud in medsökande</strong> och börja om.</span>,
        button: {
          text: 'OK', icon: 'check', onClickFunction: () => {
            this.props.closeWizardFunction(false)
            this.props.reloadKompletteringar(true)
            this.fetchKompletteringAndHandleError(this.props.arendenummer)
          }
        }
      },
      closeWizardOnSignStartError: true
    })
  }

  fetchKompletteringAndHandleError (arendenummer) {
    this.props.fetchKompletteringar(arendenummer)
      .catch(() => {
        this.setState({
          errorMessage: {
            title: 'Fel vid hämtning',
            message:
              <span>Ett fel inträffade när komplettering skulle hämtas. Försök igen senare genom att ladda om sidan.</span>,
            button: {
              text: 'OK', icon: 'check', onClickFunction: () => {
                this.props.closeWizardFunction(false)
                this.props.reloadKompletteringar(true)
              }
            }
          }
        })
      })
  }

  async startSigningBitradande (kompletteringsId, notifieringEmail, inbjudna) {
    await this.props.signKompletteringStart(kompletteringsId,
      { notifieringEmail: notifieringEmail, inbjudna: inbjudna })
      .then(() => {
        this.onSigningCompleted()
      }).catch((err) => {
      this.onSigningFailed(kompletteringsId, err)
    })
  }

  startUpload (notifieringEmail, inbjudna) {
      this.props.addProgress(false)
    this.props.uploadKompletteringForBitradande(
      this.props.arendenummer,
      this.props.selectedAktbilagebeteckningar,
      notifieringEmail,
      inbjudna
    ).then(async (kompletteringId) => {
      await this.onUploadComplete(kompletteringId, notifieringEmail, inbjudna)
          .finally(() => this.props.endProgress())
    }).catch((err) => {
      this.onUploadFailed(err)
        this.props.endProgress()
    })
  }

  handleSelectChange (evt) {
    if (evt.target.checked) {
      this.props.setAktbilagebeteckning(evt.target.value)
    } else {
      this.props.removeAktbilagebeteckning(evt.target.value)
    }
  }

  handleNotifieringsEmail (email) {
    this.setState({
      notifieringEmail: email
    })
  }

  checkIfUserWantsToExit = () => {
    this.props.closeWizardFunction(false);
  }

  render () {
    return (
      <>
        {!this.state.closeWizardOnSignStartError && <WizardDialog
          title={'Bjud in medsökande'}
          cancelFunction={() => this.checkIfUserWantsToExit()}
          defaultNav={false}
          elementsToAllowFocus={['helpSidePanelId']}
          id="AnsokanKompletteringWizardModalId"
          {...this.props.restWizardProps}>
          <WizardDialogStep id={'fansomhelst'} stepTitle={'Välj ansökan'}>
            <ValjAnsokanStepWizard
              selectedAktbilagebeteckningar={this.props.selectedAktbilagebeteckningar}
              handleSelectChange={this.handleSelectChange}
              listOfDocuments={this.state.listOfDocuments}/>
          </WizardDialogStep>
          <WizardDialogStep stepTitle={'Ange kvittensmottagare'}>
            <AngeKvittensmottagareStepWizard handleNotifigeringsMail={this.handleNotifieringsEmail}
                                             email={this.state.notifieringEmail}/>
          </WizardDialogStep>
          <WizardDialogStep stepTitle={'Ange medsökande'}>
            <LaggTillMedsokandeStepWizard
              selectedAktbilagebeteckningar={this.props.selectedAktbilagebeteckningar}
              startUpload={this.startUpload}
              notifieringEmail={this.state.notifieringEmail}
            />
          </WizardDialogStep>
        </WizardDialog>}
        {this.state.errorMessage &&
          <ModalDialogError modalTitle={this.state.errorMessage.title}
                            description={this.state.errorMessage.message}
                            button={this.state.errorMessage.button}/>}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnsokanKompletteringWizardAddSignatoriesForm)
