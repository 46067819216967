import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { abortSigningInstance, markAnsokanAsSigned } from 'redux/modules/signing'
import { addProgress, endProgress } from 'redux/modules/fullscreenLoading'
import { DocumentPullupList, OmbudForDescription, SigningText, UserActivityMonitor } from 'components'
import { ANSOKAN_STATUS_SIGNING } from 'utils/globalConstants'
import {
  ButtonGroup,
  ButtonToolbarV2,
  ButtonTop,
  IconButton,
  ListGroup,
  ModalDialogAlert,
  ModalDialogSub,
  PageTopContent,
  TextInput
} from 'redet-react-components'
import { KompletteringSignerStatus } from '../../components/Signing/KompletteringSignerStatus'
import { NO_SCROLLING_CSS_CLASS } from '../../utils/globalConstants'
import { getBifogadHandlingList } from '../../redux/modules/bifogadHandling'
import { AbortSigningModal } from './AbortSigningModal'
import { StepVisualisationFish } from 'pages/Signing'
import * as contactsHelper from 'utils/contactsHelper'

const mapStateToProps = (store) => {
  return {
    signingServiceMockEnabled: store.featureToggle.SIGNING_SERVICE_MOCK_ENABLED,
    bifogadHandlingList: store.bifogadHandling.list,
  }
}

const mapDispatchToProps = {
  markAnsokanAsSigned,
  addProgress,
  endProgress,
  getBifogadHandlingList,
  abortSigningInstance
}

export class SigningInProgress extends Component {
  static propTypes = {
    // Constructor
    ansokanId: PropTypes.string.isRequired,
    signingStatus: PropTypes.object.isRequired,
    onRefresh: PropTypes.func.isRequired,
    upprattare: PropTypes.object.isRequired,
    contactList: PropTypes.array.isRequired,

    // Dispatch
    markAnsokanAsSigned: PropTypes.func.isRequired,
    addProgress: PropTypes.func.isRequired,
    endProgress: PropTypes.func.isRequired,

    // Store
    signingServiceMockEnabled: PropTypes.bool.isRequired,
    bifogadHandlingList: PropTypes.array.isRequired,
    getBifogadHandlingList: PropTypes.func.isRequired, // TODO: Den här ska tas bort härifrån nu när vi har PHOS
    abortSigningInstance: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      editVisible: false,
      signerare: this.sortSignerare(this.props.signingStatus.signerare),
      showUrlModal: false,
      showAbortSigningModal: false,
      abortSigningError: false,
    }
    this.closeUrlModal = this.closeUrlModal.bind(this)
    this.showUrlModal = this.showUrlModal.bind(this)
  }

  componentDidMount () {
    this.userActivityMonitor = new UserActivityMonitor(this.props.onRefresh, [])
    this.register = () => this.userActivityMonitor.registerActivity()
    window.addEventListener('mousemove', this.register)
    document.body.classList.add('no-menu')
    document.body.classList.remove(NO_SCROLLING_CSS_CLASS)
    this.props.getBifogadHandlingList(this.props.ansokanId) // TODO: Den här ska tas bort härifrån nu när vi har PHOS

  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.signingStatus !== this.props.signingStatus) {
      this.setState({
        signerare: this.sortSignerare(this.props.signingStatus.signerare),
      })
    }
  }

  componentWillUnmount () {
    clearInterval(this.userActivityMonitor.timeoutId)
    window.removeEventListener('mousemove', this.register)
  }

  sortSignerare (signerare) {
    return signerare.sort((current, next) => {
      if (current.email === this.props.upprattare?.data?.epost) {
        return -1
      } else {
        return next.email === this.props.upprattare?.data?.epost ? 1 : 0
      }
    })
  }

  closeUrlModal () {
    this.setState({
      showUrlModal: false,
    })
  }

  showUrlModal (url) {
    this.setState({
      showUrlModal: true,
      signUrl: url,
    })
  }

  showAbortSigningModal = (show = true) => {
    this.setState({ showAbortSigningModal: show })
  }

  abortSigning = () => {
    this.props.addProgress(false, 'Avbryter underskrifter...')
    this.props.abortSigningInstance(this.props.ansokanId)
      .then(() => {
        this.props.endProgress()
        this.props.onRefresh()
      }).catch(() => {
      this.props.endProgress()
      this.setState({ abortSigningError: true })
    })
  }

  render () {
    return (
      <>
        <div className="wizard" id="step-3">
          <StepVisualisationFish activeStep={3}/>
          <PageTopContent title={'Underskrifter'} headerLevel={2} headerId={'underskriftHeaderId'}
                          introduction={'Underskrift av ansökan pågår.'}
                          description={'E-post med inbjudan att skriva under ansökan har skickats till de e-postadresser du angett. Du kommer att få ett e-postmeddelande när alla underskrifter är klara; tills dess kan du se status på den här sidan. De inbjudna kommer att få en påminnelse via e-post varannan dag så länge inbjudan gäller. Inbjudan gäller i 30 dagar. Om inte alla underskrifter kommit in innan dess måste du bjuda in samtliga som ska skriva under på nytt.'}
          >

          </PageTopContent>
          <ListGroup headerLevel={3} title={'Underskrifter av ansökan'} id={'signerareStatusTable'}>
            {this.state.signerare.map((signerare, index) =>
              <React.Fragment key={index}>
                <KompletteringSignerStatus
                  name={signerare.contactName}
                  email={signerare.email}
                  status={signerare.status}
                  signingUrl={signerare.signingUrl}
                  showModalCallback={this.showUrlModal}
                  key={signerare.contactId}
                  signedByUpprattare={signerare.contactId === this.props.upprattare?.id}
                  additionalSignerInfo={
                    contactsHelper.isForetradare(this.props.contactList, signerare.contactId) ?
                      <OmbudForDescription ombudNamn={signerare.contactName}
                                           isSokande={contactsHelper.isSokande(this.props.contactList, signerare.contactId)}
                                           namnList={contactsHelper.getForetraddaNamnForContactList(this.props.contactList, signerare.contactId)}
                                           id={`sokandeOmbudForDescription${index}`}/> :
                      undefined
                  }
                />
              </React.Fragment>)}
            <br/>
          </ListGroup>
          <SigningText ansokanStatus={ANSOKAN_STATUS_SIGNING}/>
          <ButtonToolbarV2>
            <ButtonGroup>
              <ButtonTop name="abort-signing-button"
                         icon="clear"
                         text={'Avbryt underskrifter'}
                         onClick={this.showAbortSigningModal}
              />
              <DocumentPullupList>
                <ul className="dropdown-menu">
                  <li>
                    <a className="button-top" title="Ansökan" target="_blank"
                       rel="noopener noreferrer"
                       href={'/api/ansokan-service/ansokan/' + this.props.ansokanId + '/pdf'} download><i
                      className="material-icons">save_alt</i>Ansökan</a>
                  </li>
                  {this.props.bifogadHandlingList.map((handling, index) => {
                      return (
                        <li key={index}>
                          <a className="button-top"
                             href={`/api/ansokan-service/ansokan/${this.props.ansokanId}/bifogadHandling/${handling.id}`}
                             title={handling.name} target="_blank" rel="noopener noreferrer"
                             download={handling.name}><i
                            className="material-icons">save_alt</i>{handling.name}</a>
                        </li>
                      )
                    }
                  )}
                </ul>
              </DocumentPullupList>
            </ButtonGroup>
            <ButtonGroup>
              {this.props.signingServiceMockEnabled &&
                <ButtonTop
                  text={'Simulera att alla signerar'}
                  data-selenium-id="ansokan-mark-as-signed"
                  onClick={() => {
                    this.props.markAnsokanAsSigned(this.props.ansokanId)
                      .then(() => {
                        this.props.onRefresh()
                      })
                  }}
                  icon={'fingerprint'}/>
              }
            </ButtonGroup>
          </ButtonToolbarV2>
          <br/>
        </div>
        {this.state.showUrlModal &&
          <SigningCopyUrlModal closeModalCallback={this.closeUrlModal} url={this.state.signUrl}/>}
        {this.state.showAbortSigningModal &&
          <AbortSigningModal onClickJaKnapp={this.abortSigning}
                             closeModalCallback={() => this.showAbortSigningModal(false)}/>
        }
        {this.state.abortSigningError &&
          <ModalDialogAlert
            modalTitle={'Ett fel uppstod'}
            id={'modalAbortSigningErrorDialog'}
            closeModalCallback={() => this.setState({ abortSigningError: false })}
            description={
              <span>Ett fel uppstod när signeringen skulle avbrytas. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta&nbsp;
                <a href="https://www.lantmateriet.se/kontakt">kundcenter</a>.
                        </span>
            }
          />
        }
      </>
    )
  }
}

export function SigningCopyUrlModal ({ closeModalCallback, url }) {
  let copyUrl = () => {
    const urlInput = document.getElementById('urlTextInputId')
    urlInput.select()
    urlInput.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(urlInput.value).then(() => true);
  }
  return <ModalDialogSub
    name={'Signeringsurl-modaldialog'}
    modalTitle={'Inbjudningslänk'}
    description={'Alla som ska skriva under kommer få inbjudningslänken skickad till den e-postadress du angivit men om något skulle gå snett ' +
      'så kan du kopiera inbjudningslänken nedan och själv skicka till dem på valfritt sätt.'}
    closeModalCallback={closeModalCallback}>
    <div className="flex-line">
      <TextInput
        id={'urlTextInputId'}
        aria-label={'Inbjudningslänk'}
        defaultValue={url}
        onFocus={(event) => event.target.select()}
        readonly=""/>
      <IconButton
        ariaLabel={'Kopiera länk'}
        icon={'content_copy'}
        onClick={copyUrl}/>
    </div>
  </ModalDialogSub>
}

export default connect(mapStateToProps, mapDispatchToProps)(SigningInProgress)
