const ENABLE = 'featureToggle/ENABLE'
const DISABLE = 'featureToggle/DISABLE'
const DISABLE_ALL = 'featureToggle/DISABLE_ALL'
export const SIGNING_SERVICE_MOCK_ENABLED = 'SIGNING_SERVICE_MOCK_ENABLED'
export const FRISSE_SERVICE_MOCK_ENABLED = 'FRISSE_SERVICE_MOCK_ENABLED'
export const FEATURE_TOOGLE_COOKIE_NAME = 'enak_feature_toggles'
export const KOMPLETTERING_SERVICE_MOCK_ENABLED = 'KOMPLETTERING_SERVICE_MOCK_ENABLED'
export const DEV_FEATURES_ENABLED = 'DEV_FEATURES_ENABLED'
export const PHOS_MOCK_SIGNING_ENABLED = 'PHOS_MOCK_SIGNING_ENABLED'

const initialState = {
  SIGNING_SERVICE_MOCK_ENABLED: false,
  FRISSE_SERVICE_MOCK_ENABLED: false,
  KOMPLETTERING_SERVICE_MOCK_ENABLED: false,
  PHOS_MOCK_SIGNING_ENABLED: process.env.REACT_APP_FEATURE_TOGGLE_PHOS_MOCK_SIGNING_ENABLED === 'true',
  DEV_FEATURES_ENABLED: false,
}

const blockedFeaturesInPrd = []
const isProd = window.location.host === 'enak.lantmateriet.se'

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ENABLE:
      const newState = { ...state }
      if (isProd && blockedFeaturesInPrd.includes(action.feature)) {
        return newState
      }
      newState[action.feature] = true
      return newState

    case DISABLE:
      const newState2 = { ...state }
      newState2[action.feature] = false
      return newState2

    case DISABLE_ALL:
      return initialState

    default:
      return state
  }
}

export function disableFeature (feature) {
  return {
    type: DISABLE,
    feature: feature
  }
}

export function disableAllFeatures () {
  return {
    type: DISABLE_ALL
  }
}

export function enableFeature (feature) {
  return {
    type: ENABLE,
    feature: feature
  }
}

// Helper för att enkelt kunna hantera feature toggles från consolen
export function createFeatureToggleAPI (store) {
  const api = {
    signeringServiceMock: {
      enable: () => store.dispatch(enableFeature(SIGNING_SERVICE_MOCK_ENABLED)),
      disable: () => store.dispatch(disableFeature(SIGNING_SERVICE_MOCK_ENABLED))
    },
    frisseMock: {
      enable: () => store.dispatch(enableFeature(FRISSE_SERVICE_MOCK_ENABLED)),
      disable: () => store.dispatch(disableFeature(FRISSE_SERVICE_MOCK_ENABLED))
    },
    kompletteringMock: {
      enable: () => store.dispatch(enableFeature(KOMPLETTERING_SERVICE_MOCK_ENABLED)),
      disable: () => store.dispatch(disableFeature(KOMPLETTERING_SERVICE_MOCK_ENABLED))
    },
    phosMockSigningFeature: {
      enable: () => store.dispatch(enableFeature(PHOS_MOCK_SIGNING_ENABLED)),
      disable: () => store.dispatch(disableFeature(PHOS_MOCK_SIGNING_ENABLED))
    },
    DevFeatures: {
      enable: () => store.dispatch(enableFeature(DEV_FEATURES_ENABLED)),
      disable: () => store.dispatch(disableFeature(DEV_FEATURES_ENABLED))
    },
    getStatus: () => store.getState().featureToggle,
    clearAll: () => store.dispatch(disableAllFeatures())
  }

  return api
}