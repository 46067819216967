import {
  //@ts-ignore
  ButtonTop, CircleLoading, ListGroup, ListItem, WizardIntorduction, WizardNavigationButtonToolbar,
  ModalDialogAlert
} from 'redet-react-components'
import { useState } from 'react'
import {
  SIGNER_SIGNINGSTATUS_APPROVED,
  SIGNER_SIGNINGSTATUS_DECLINED,
  SIGNER_SIGNINGSTATUS_NONE
} from '../../../../utils/globalConstants'
import { useQuery } from '@tanstack/react-query'
import {YrkandeUnderskriftWizardModal} from "./YrkandeUnderskriftWizardModal";
import { Komplettering } from 'domain/Komplettering';
import { SIGNINGINSTANCE_STATUS, SignerStatus, SIGNER_STATUS } from 'domain/signing/SigningStatus'


type Medyrkande = {
  name: string,
  email: string
}

type Signresponse = {
  kompletteringId: string,
  upprattareSigneringUrl: string
}

type spinnerType = {
  percentageComplete?: number,
  text: string,
  isFocusTrapped?: boolean,
  button?: { text: string, icon: string, onClick: () => void }
}

type Props = {
  medyrkare: Medyrkande[],
  createKompletteringAndStartSigning: (onProgressCallback: (progress: ProgressEvent) => void, abortFunction: () => void ) => Promise<Signresponse>,
  fetchSigningStatusKomplettering: (kompletteringId: string) => void,
  abortKompletteringSigning: (kompletteringId?: string) => Promise<void>,
  nextStep?: () => void
  previousStep?: () => void
  prevButtonText: string
  elementAttachId?: string
  onSubmitted: Function
}

export function YrkandeWizardStepSigneringMedyrkare (props: Props) {

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [signingUrl, setSigningUrl] = useState<string>('')
  const [showSigningModal, setShowSigningModal] = useState(false)
  const [kompletteringId, setKompletteringId] = useState('')
  const [isPollingError, setIsPollingError] = useState(false)
  const [isDeclinedSigneringWarningModal, setDeclinedSigneringWarningModal] = useState(false)
  const [isPolling, setIsPolling] = useState(false)
  const [signeringKompletteringData, setSigneringKompletteringData] = useState<{
    status: SIGNINGINSTANCE_STATUS,
    signerStatus: SignerStatus[],
    uprattareStatus: SIGNER_STATUS | undefined}
    >({
    status: SIGNINGINSTANCE_STATUS.NOT_STARTED,
    signerStatus: [] as SignerStatus[],
    uprattareStatus: SIGNER_STATUS.NONE,
  })


  const loadingSpinnerInitProps = {
    percentageComplete: 0,
    text: 'Laddar upp yrkande och startar signering...',
    isFocusTrapped: false,
    button: { text: 'Avbryt', icon: 'clear', onClick: () => {setShowLoadingSpinner(false)} }
  }
  const [loadingSpinnerProps, setLoadingSpinnerProps] = useState<spinnerType>({ ...loadingSpinnerInitProps })

  const buttonText = 'Bjud in & skriv under'

  const isPollingForSigningStatus = isPolling && !!kompletteringId && !isPollingError && (signeringKompletteringData.uprattareStatus === undefined || signeringKompletteringData.uprattareStatus === SIGNER_SIGNINGSTATUS_NONE || signeringKompletteringData.uprattareStatus === SIGNER_SIGNINGSTATUS_DECLINED)

  const fetchingKomplettering = (query: any) => {
    const _kompletteringId = query.queryKey[0]
    return props.fetchSigningStatusKomplettering(_kompletteringId)
  }

  const onProgressCallback = (progress: ProgressEvent) => {
    setLoadingSpinnerProps({
      ...loadingSpinnerInitProps,
      percentageComplete: Math.trunc((progress.loaded / progress.total) * 100)
    })
  }

  const onFetchKompletteringError = () => {
    setShowLoadingSpinner(false)
    setIsPollingError(true)
    setIsPolling(false)
  }

  const hideDeclinedSigneringModal = () => {
    setDeclinedSigneringWarningModal(false)
  }

  const onFetchKompletteringSuccess = (result: Komplettering) => {
    const upprattareStatus = result?.signingStatus?.signerStatus?.find((signer: any) => (signer.status === SIGNER_SIGNINGSTATUS_APPROVED || signer.status === SIGNER_SIGNINGSTATUS_DECLINED))?.status

    setSigneringKompletteringData({
      status: result?.signingStatus?.status,
      signerStatus: result?.signingStatus?.signerStatus,
      uprattareStatus: upprattareStatus
    })
    if (upprattareStatus === SIGNER_SIGNINGSTATUS_APPROVED) {
      setIsPolling(false)
      if(props.nextStep) {props.nextStep()}
    }
    if (upprattareStatus === SIGNER_SIGNINGSTATUS_DECLINED) {
      setDeclinedSigneringWarningModal(true)
      setIsPolling(false)
      setShowLoadingSpinner(false)
      setShowSigningModal(false)
      setLoadingSpinnerProps(loadingSpinnerInitProps)
    }
  }

  const { isError: hasPollingError } = useQuery({
    queryKey: [kompletteringId],
    queryFn: fetchingKomplettering,
    onSuccess: onFetchKompletteringSuccess,
    onError: onFetchKompletteringError,
    enabled: isPollingForSigningStatus,
    refetchInterval: 5000
  })

  const abortSigningModal = () => {
    setLoadingSpinnerProps({
      text: 'Förbereder...',
      isFocusTrapped:false
    })

    setShowLoadingSpinner(true)

    props.abortKompletteringSigning(kompletteringId)
        .then(result => setDeclinedSigneringWarningModal(true))
        .finally(() => {
          setShowLoadingSpinner(false)
          setShowSigningModal(false)
        })
  }

  const onClickSkrivUnderOchSkickaIn = () => {
    const xhrProxy = {
      xhr: {
        userForcedAbort: false,
        abort(){}
      }
    }
    const abortFunction = () => {
      xhrProxy.xhr.userForcedAbort = true
      xhrProxy.xhr.abort()
    }
    setShowLoadingSpinner(true)
    setLoadingSpinnerProps({
      percentageComplete: 0,
      text: 'Laddar upp yrkande och startar signering...',
      button: { text: 'Avbryt', icon: 'clear', onClick: abortFunction }
    })
    props.createKompletteringAndStartSigning(onProgressCallback, abortFunction)
      .then((response: Signresponse) => {
        setKompletteringId(response.kompletteringId)
        setIsPolling(true)

        setSigningUrl(response?.upprattareSigneringUrl)
        setShowSigningModal(true)

        setShowLoadingSpinner(false)
        props.onSubmitted();
      })
      .catch((error: Error) => {
        console.error(error)
      })
  }

  return <>
    <WizardIntorduction title={'Skriv under'}
                        description={`Nu är det dags att skriva under. När du klickar på knappen "${buttonText}" skickas e-post med signeringsinbjudan till dina medyrkande och du får möjlighet att skiva under yrkandet direkt.`}>
    </WizardIntorduction>

    {props.medyrkare?.length > 0 &&
      <>
        <ListGroup id={'medyrkandeSigneringListaId'} title={'Medyrkare'} headerLevel={4}>
          {props.medyrkare.map(({ email, name }, index) =>
            <ListItem header={name}
                      key={`medyrkareKey${index}`}
                      description={email} hasTableActionButtons={false}/>
          )}

        </ListGroup>
      </>}


    <WizardNavigationButtonToolbar prevButtonText={props.prevButtonText}
                                   onClickPrevButton={props.previousStep}>
      <ButtonTop text={buttonText}
                 isActive={true}
                 id={'skrivUnderOchBjudInKnappId'}
                 icon={'create'}
                 onClick={onClickSkrivUnderOchSkickaIn}/>
    </WizardNavigationButtonToolbar>
    {showLoadingSpinner && !hasPollingError && <CircleLoading isFullscreen={true}
                                                             attachToDomElementWithId={props.elementAttachId ? props.elementAttachId : 'rootBody'} {...loadingSpinnerProps}/>}

    {showSigningModal && signingUrl && (
        <YrkandeUnderskriftWizardModal
            closeModalCallback={abortSigningModal}
            signingUrl={signingUrl}
            attachToDomElementWithId={props.elementAttachId ? props.elementAttachId : 'rootBody'}
        ></YrkandeUnderskriftWizardModal>
    )}

    {hasPollingError && <ModalDialogAlert modalTitle={'Ett fel uppstod'}
                                          description={'Någoting gick fel. Vänligen försök igen senare.'}
                                          attachToDomElementWithId={props.elementAttachId ? props.elementAttachId : 'rootBody'}/>}
    {isDeclinedSigneringWarningModal && <ModalDialogAlert
      modalTitle={'Yrkandet har avbrutits'}
      ingress={'Du har avböjt signeringen.'}
      description={<span>Klicka på "bjud in och skriv under" för att signera och bjuda in medyrkanden igen, alternativt ändra medyrkare.</span>}
      acceptButton={{ text: 'Ok', icon: 'check',
        //@ts-ignore
        onClick: hideDeclinedSigneringModal }}
    />
    }
  </>
}