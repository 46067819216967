import React, {useEffect, useState} from "react";
import {KompletteringAtgard} from "../../../../domain/Forratningsarende/KompletteringAtgard";
import {Severity} from "../../../../redux/modules/model/helpText";
import {ARENDE_STATUS} from "../../../../utils/globalConstants";
// @ts-ignore
import {Felruta, VarningsRuta} from "redet-react-components";

type Props = {
    kompletteringAtgarder: KompletteringAtgard[]
    arendestatus: ARENDE_STATUS
}

export default function InactiveKompletteringReasons({kompletteringAtgarder, arendestatus}: Props) {

    const [inactiveAtgarder, setInactiveAtgarder] = useState<KompletteringAtgard[]>([])

    useEffect(() => {
        const getActiveAtgarder = (atgarder: KompletteringAtgard[]) => atgarder
            .filter(status => status && !status.active && status.inactiveReasons.length > 0)

        setInactiveAtgarder(getActiveAtgarder(kompletteringAtgarder))

    }, [kompletteringAtgarder]);

    const isArendeClosed = (arendestatus: ARENDE_STATUS) => arendestatus === ARENDE_STATUS.REGISTRERING || arendestatus === ARENDE_STATUS.SLUTFORT
    const containsOnlyErrorMessages = (atgarder: KompletteringAtgard[]) => atgarder.every(atgard => atgard.inactiveReasons.every(reason => reason.severity === Severity.ERROR))
    const showSingleArendeClosedMessage = (atgarder: KompletteringAtgard[]) => isArendeClosed(arendestatus) && //stängt ärende
        atgarder.length > 1 && //mer än 1 åtgärd som det gäller
        atgarder.every(atgard => (atgard.inactiveReasons.length === 1 && //endast ett meddelande för varje åtgärd
            atgard.inactiveReasons.every(reason => (reason.severity === Severity.INFORMATION)) //meddelande av typen info
        ))

    function getAttentionGroupContent(atgarder: KompletteringAtgard[]) {
        return (<>
            {atgarder.length === 1 && atgarder[0].inactiveReasons.map(reason => (
                    <p key={`inactive-reason-${Math.random()}`} className={reason.severity === Severity.ERROR ? 'mandatory' : 'optional'}>
                        {reason.message}
                    </p>
                )
            )}
            {atgarder.length > 1 && atgarder.map(atgard => (
                    <div key={`inactive-atgard-${Math.random()}`} className={'panel-group'}>
                        {atgard?.inactiveReasons.map(reason => (
                            <p key={`inactive-reason-${Math.random()}`} className={reason.severity === Severity.ERROR ? 'mandatory' : 'optional'}>
                                {reason.message}
                            </p>
                        ))}
                    </div>
                )
            )}
        </>)
    }

    //om inga meddelanden finns att visa
    if (inactiveAtgarder.length === 0) {
        return <></>
    }

    //om ärendet är stängt och endast ett varningsmeddelande visas per åtgärd, skriver vi ut ett gemensamt meddelande
    if (showSingleArendeClosedMessage(inactiveAtgarder)) {
        return <VarningsRuta ariaTitle={'Information'}>
            <p className={'optional'}>Du kan inte komplettera ärendet eftersom ärendet är avslutat</p>
        </VarningsRuta>
    }

    //om samtliga meddelanden är av typen ERROR, visa felruta
    if (containsOnlyErrorMessages(inactiveAtgarder)) {
        //om samtliga meddelanden är av typen ERROR, visa felruta
        return <><Felruta aria-label={'Felmeddelande'} errorMessages={[]}>
            {getAttentionGroupContent(inactiveAtgarder)}
        </Felruta><br/>
        </>
    }

    //skapa en samlad varningsruta med samtliga åtgärders meddelanden grupperade
    return <VarningsRuta ariaTitle={'Information'}>
        {getAttentionGroupContent(inactiveAtgarder)}
    </VarningsRuta>
}