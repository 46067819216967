import * as interactions from '../interactions'
import { Overlay } from 'ol'
import { unByKey } from 'ol/Observable'

export default class FinishButtonOverlay {
  constructor (mapController, mapInteractions, destructors, toolCoordinator) {
    this.mapController = mapController
    this.mapInteractions = mapInteractions
    this.destructors = destructors
    this.toolCoordinator = toolCoordinator
  }

  init () {
    // Overlay
    this.finishButtonOverlay = new Overlay({
      id: 'finishButtonOverlay',
      element: document.getElementById('finishMapButton')
    })

    this.mapController.getMap().addOverlay(this.finishButtonOverlay)

    // OnClick button
    this.finishButtonOverlay.getElement().onclick = () => {
      this.mapInteractions.finishDrawing()
      this.hide()
    }

    // onTouchStart button
    this.finishButtonOverlay.getElement().ontouchstart = () => {
      const mobile = true
      this.mapInteractions.finishDrawing(mobile)
      this.hide()
    }

    // Remove button of doubleClick
    const observeKey = this.mapController.getMap().on('dblclick', () => {
      this.finishButtonOverlay.setPosition(undefined)
    })
    this.destructors.push(() => {
      unByKey(observeKey)
    })

    // SingleClick
    let observableKey = this.mapController.getMap().on('singleclick', () => {
      const isLineString = [interactions.LINE_STRING, interactions.MEASURE_LINE].includes(this.toolCoordinator.getActiveTool())
      const isPolygon = [interactions.POLYGON, interactions.MEASURE_POLYGON].includes(this.toolCoordinator.getActiveTool())

      if ((isLineString || isPolygon) && (this.mapInteractions.getCurrentMultiPointFeature() !== null && this.mapInteractions.getCurrentMultiPointFeature().getGeometry() !== undefined)) {
        const coordinates = this.mapInteractions.getCurrentMultiPointFeature().getGeometry().getCoordinates()
        let finishButtonShouldBeShown = false
        let lastCoordinate = undefined
        let secondLastCoordinate = undefined

        if (isLineString && coordinates.length > 2) {
          finishButtonShouldBeShown = true
          lastCoordinate = coordinates[coordinates.length - 2]
          secondLastCoordinate = coordinates[coordinates.length - 3]
        } else if (isPolygon && coordinates[0].length > 3) {
          finishButtonShouldBeShown = true
          lastCoordinate = coordinates[0][coordinates[0].length - 2]
          secondLastCoordinate = coordinates[0][coordinates[0].length - 3]
        }
        // Button position caluclation
        if (finishButtonShouldBeShown) {
          const secondLastCoordinatePixelPostition = this.mapController.getMap().getPixelFromCoordinate(secondLastCoordinate)
          const lastCoordinatePixelPostition = this.mapController.getMap().getPixelFromCoordinate(lastCoordinate)

          // Source: http://math.stackexchange.com/a/1630886
          // Extract the two last points
          const lx = lastCoordinatePixelPostition[0]
          const slx = secondLastCoordinatePixelPostition[0]
          const ly = lastCoordinatePixelPostition[1]
          const sly = secondLastCoordinatePixelPostition[1]

          // Calculate distance between points
          // abs() turn negative value to positive.
          const xCatheti = Math.abs(lx - slx)
          const yCatheti = Math.abs(ly - sly)
          const distance = Math.sqrt(Math.pow(xCatheti + yCatheti, 2)) // Hypotenuse
          // Do the magic
          const buttonDistanceFromLastPoint = 55 // px
          const ratio = buttonDistanceFromLastPoint / distance // ratioDistance
          const buttonXpos = (1 - ratio) * lx + ratio * slx
          const buttonYpos = (1 - ratio) * ly + ratio * sly
          let pos = [buttonXpos, buttonYpos]

          // if this condition is met then the button will overlay
          // the last coordinate, this is a quick and dirty hack to prevent that

          if (sly < ly && slx < lx) {
            pos = [buttonXpos - 50, buttonYpos - 50]
          }

          this.finishButtonOverlay.setPosition(this.mapController.getMap().getCoordinateFromPixel(pos))

        }
      } else {
        this.finishButtonOverlay.setPosition(undefined)
      }
    })

    this.destructors.push(() => {
      unByKey(observableKey)
    })

    observableKey = this.mapController.getMap().getViewport().addEventListener('contextmenu', () => {
      this.hide()
    })

    this.destructors.push(() => {
      unByKey(observableKey)
    })
  }

  hide () {
    this.finishButtonOverlay.setPosition(undefined)
  }
}
