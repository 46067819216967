import {useQuery} from '@tanstack/react-query'
import {useEffect, useState} from 'react'
import {ModalDialogNotifications, Notis} from 'redet-react-components'
import {convertEnabledNotiserToFlatStructure} from './notisService'
import {getToggles} from '../featureToggle/featureToggleReducer'
import {useSelector} from 'react-redux'
import {RootState} from 'store'
import {useAppDispatch} from 'hooks'
import {doljNotisModal, markNotisAsRead, visaNotisModal} from "./notisReducer";

export function NotiserComponent() {

    const [error, setError] = useState(false)
    const [notiser, setNotiser] = useState<Notis[]>([])
    const dispatch = useAppDispatch();
    const showNotificationModal = useSelector((store: RootState) => store.notiser.showNotificationModal)
    const featureToggleNotiser = useSelector((store: RootState) => store.notiser.notiser)

    const {isError: hasPollingError, isSuccess} = useQuery({
        queryKey: ["fetchNotiser"],
        initialData: new Map(),
        queryFn: fetchingNotices,
        enabled: true,
        refetchInterval: 60000
    })

    useEffect(() => {
        if (isSuccess)
            setError(false)
        if (hasPollingError)
            setError(true)
    }, [hasPollingError, isSuccess]);

    useEffect(() => {
        if (featureToggleNotiser.length > 0) {
            setNotiser(convertEnabledNotiserToFlatStructure(featureToggleNotiser))
            if (featureToggleNotiser.some(notis => notis.enabled && notis.unread && ["error", "warning"].includes(notis.metadata.severity?.toLowerCase() ?? "")))
                dispatch(visaNotisModal())
        } else {
            setNotiser([])
        }
    }, [dispatch, featureToggleNotiser, featureToggleNotiser.length])

    async function fetchingNotices() {
        try {
            return await dispatch(getToggles())
        } catch {
            setError(true)
        }
    }

    function closeNotisModal() {
        document.body?.classList?.remove('no-scrolling')
        dispatch(doljNotisModal())
    }

    function visitedNotisCallback(notis: Notis): void {
        dispatch(markNotisAsRead(notis))
    }

    function show(): boolean {
        return (showNotificationModal && notiser.length > 0)
    }

    return (
        <>
            {(!hasPollingError && !error && show()) &&
                <ModalDialogNotifications notiser={notiser}
                                          closeModalCallback={closeNotisModal}
                                          visistedNotisCallback={visitedNotisCallback}
                                          id={'modalNotifications'}/>
            }
        </>)
}