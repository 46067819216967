import { Component, ErrorInfo, PropsWithChildren } from 'react'
import GenericErrorBoundaryPage from 'pages/GenericErrorBoundaryPage'
import { SessionTimeoutError } from '../../utils/repositoryHelpers'
import { handleSessionTimeoutError, logError } from '../../utils/errorHandler'


type State = {
  error?: Error;
  hasError: boolean;
  errorInfo?: ErrorInfo;
};

class TopLevelErrorBoundary extends Component<PropsWithChildren, State> {
  constructor (props: PropsWithChildren) {
    super(props)
    this.state = {
      error: undefined,
      hasError: false
    }
  }

  static getDerivedStateFromError (error: Error) {
    return {
      error: error,
      hasError: true,
    }
  }

  componentDidCatch (error: Error, info: ErrorInfo) {
    if (error instanceof SessionTimeoutError) {
      handleSessionTimeoutError()
    }
    logError(error, 'TopLevelErrorBoundary')
    this.setState({
      hasError: true,
      error: error,
      errorInfo: info,
    })
  }

  render () {
    if (this.state.hasError) {
      return (
        <>
          {!(this.state.error instanceof SessionTimeoutError) &&
            <GenericErrorBoundaryPage modalVisible={true}/>}
        </>
      )
    }
    return this.props.children
  }
}

export default (TopLevelErrorBoundary)