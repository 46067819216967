import React from 'react'
import Log from 'utils/log'
import * as interactions from '../interactions'
import { Overlay } from 'ol'
import { unByKey } from 'ol/Observable'

export default class TextOverlay {
  constructor (map, toolCoordinator, displayModalConfirmDialog, selectToolFunc, destructors, saveCurrentFeaturesFunc, featureHandler) {
    this.log = new Log(this.constructor.name)
    this.map = map
    this.toolCoordinator = toolCoordinator
    this.displayModalConfirmDialog = displayModalConfirmDialog
    this.selectToolFunc = selectToolFunc
    this.destructors = destructors
    this.saveCurrentFeaturesFunc = saveCurrentFeaturesFunc
    this.featureHandler = featureHandler
  }

  init () {
    if (this.map.getOverlayById('text') === null) {
      this.log.debug('Add "text" overlay to map')

      const overlay = new Overlay(({
        id: 'text',
        element: document.getElementById('popup'),
        autoPan: true,
        autoPanAnimation: {
          duration: 250
        }
      }))

      this.map.addOverlay(overlay)

      const observableKey = this.map.on('singleclick', (event) => {
        if ((this.toolCoordinator.getActiveTool() === interactions.TEXT) || (this.toolCoordinator.getActiveTool() === interactions.POINT) || (this.toolCoordinator.getActiveTool() === interactions.MODIFY)) {
          const textFeatures = []
          this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
            if (feature.get('hasTextFeature')) {
              textFeatures.push(feature)
            }
          })

          if (textFeatures.length > 0) {
            textFeatures.forEach((feature) => {
              const initialValue = feature.get('text') !== undefined ? feature.get('text') : ''

              // Text input modal dialog
              this.displayModalConfirmDialog(
                (<div>
                  <div className="form-group">
                    <label>Din text:</label>
                    <input id="mapTextModalInput" className="form-control" type="text" maxLength="50"
                           placeholder="Fritext..." defaultValue={initialValue}/>
                  </div>
                </div>),
                'Skriv in en valfri text'
                ,
                (userInput) => {
                  if (userInput === true) {
                    feature.set('text', document.getElementById('mapTextModalInput').value)
                    this.selectToolFunc(interactions.PAN)
                  }
                  if (this.isDotWithText(feature)) {
                    this.featureHandler.getDrawingFeatures().remove(feature)
                  }
                },
                'Ok', 'Avbryt')
            })
          }
        }
      })

      this.destructors.push(() => {
        unByKey(observableKey)
      })
    }
  }

  isDotWithText (feature) {
    return (feature.get('text') === undefined || feature.get('text').length === 0) && feature.get('isTextPoint') !== undefined
  }

  hide () {
    const overlay = this.map.getOverlayById('text')
    if (overlay !== null) {
      overlay.setPosition(undefined)
      this.saveCurrentFeaturesFunc()
    }
  }
}
