export class BifogadHandlingException {
    errorMessage: string
    constructor(errorMessage: string = '') {
        this.errorMessage = errorMessage
    }
}

export class UserAbortException extends BifogadHandlingException {}

export class ForbiddenKompletteringException extends BifogadHandlingException {}

export class ArendeNotFoundException extends BifogadHandlingException {}

export class HandlingNotFoundException extends BifogadHandlingException {}

export class KompletteringNotFoundException extends BifogadHandlingException {}

export class TooManyFilesInBifogadHandlingException extends BifogadHandlingException {}

export class TooLongTextException extends BifogadHandlingException {}

export class ForbiddenBifogadHandlingFiletypeException extends BifogadHandlingException {}

export class InvalidEmailException extends BifogadHandlingException {}

export class FaultyPDFFileError extends  BifogadHandlingException {}