import React from 'react'
import { NumberInputFinalForm, Subscribe, TextInputFinalForm } from '../../General/forms/finalForm'
import { Column, Row } from 'redet-react-components'

export default function OrganizationIdentityForm () {
  const columnSettings = 'col-xs-12 col-sm-6'
  return (
    <Row>
      <Column columns={columnSettings}>
        <Subscribe fieldName={'rollSokande'}>
          {(props) => {
            const sokandeChecked = props.input.value === '' ? false : props.input.value
            return (<>
              <NumberInputFinalForm field={'orgNummer'}
                                    label={'Organisationsnummer (10 siffror)'}
                                    minNumberValue={0}
                                    required={sokandeChecked}
                                    maxNumberValue={9999999999}/>
            </>)
          }}</Subscribe>
      </Column>
      <Column columns={columnSettings}>
        <TextInputFinalForm field={'orgNamn'}
                            label={'Namn på organisation'}
                            maxLength={80}
                            required={true}/>
      </Column>
      <Column columns={columnSettings}>
        <TextInputFinalForm field={'orgKontaktperson'}
                            label={'Kontaktperson'}
                            maxLength={100}/>
      </Column>
    </Row>
  )
}
