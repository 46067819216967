import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { WizardNavigationButtonToolbar } from './WizardFormElements'
import { NO_SCROLLING_CSS_CLASS } from '../../utils/globalConstants'
import { ModalDialogTop } from 'redet-react-components'
import { deprecated } from '../../helpers/customProptypes'

export function WizardDialogStep ({ stepTitle, children, ...rest }) {
  return React.Children.map(children, (child, i) => {
      return (
        React.cloneElement(child, rest)
      )
    }
  )
}

class WizardDialog extends Component {
  static propTypes = {
    steps: deprecated('Wrappa varje steg i en WizardDialogStep med propen \'stepTitle\' istället.'),
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string,
    cancelFunction: PropTypes.func,
    initialStep: PropTypes.number,
    defaultNav: PropTypes.bool,
    onStepChange: PropTypes.func,
    elementsToAllowFocus: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    initialStep: 1,
    defaultNav: null,
    onStepChange: () => {
    },
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      activeStep: this.props.initialStep - 1,
      children: props.children.filter(child => child)
    }
    this.escFunction = this.escFunction.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.children !== this.props.children)
      this.setState({ children: this.props.children.filter(child => child) })
  }

  componentWillUnmount () {
    document.body.classList.remove(NO_SCROLLING_CSS_CLASS)
    if (this.props.cancelFunction) {
      document.removeEventListener('keydown', this.escFunction, false)
    }
  }

  componentDidMount () {
    if (this.props.cancelFunction) {
      document.addEventListener('keydown', this.escFunction, false)
    }
  }

  escFunction (event) {
    if (event.keyCode === 27)
      this.closeModal()
  }

  closeModal () {
    if (this.props.cancelFunction !== undefined)
      this.props.cancelFunction()
  }

  isInvalidStep = next => (next < 0 || next >= this.state.children.length)

  setActiveStep = (next) => {
    const active = this.state.activeStep
    if (active === next) return
    if (this.isInvalidStep(next)) {
      console.error(`${next + 1} is an invalid step`)
      return
    }

    this.setState({
      activeStep: next,
    }, () => {
      // Step change callback
      this.onStepChange({
        previousStep: active + 1,
        activeStep: next + 1,
      })
    })
  }

  onStepChange = (stats) => {
    // User callback
    this.props.onStepChange(stats)
  }

  /** Go to first step */
  firstStep = () => this.goToStep(1)

  /** Go to last step */
  lastStep = () => this.goToStep(this.state.children.length)

  /** Next Step */
  nextStep = () => this.setActiveStep(this.state.activeStep + 1)

  /** Previous Step */
  previousStep = () => this.setActiveStep(this.state.activeStep - 1)

  /** Go to step index */
  goToStep = step => this.setActiveStep(step - 1)

  // Allows for using HTML elements as a step
  isReactComponent = ({ type }) => (
    typeof type === 'function' || typeof type === 'object'
  )

  render () {
    const props = {
      currentStep: this.state.activeStep + 1,
      totalSteps: this.state.children.length,
      isLastStep: this.state.children.length === this.state.activeStep + 1,
      isFirstStep: this.state.activeStep === 0,
      /** Functions */
      nextStep: this.nextStep,
      previousStep: this.previousStep,
      goToStep: this.goToStep,
      firstStep: this.firstStep,
      lastStep: this.lastStep,
    }
    const childrenWithProps = React.Children.map(this.state.children, (child, i) => {
      props.isActive = (i === this.state.activeStep)
      if (props.isActive) {
        return (
          <div id={`wizardStep${i + 1}`}>{
            this.isReactComponent(child)
              ? React.cloneElement(child, props)
              : child
          }</div>
        )
      }
    })
    return (
      <ModalDialogTop modalTitle={this.props.title}
                      closeModalCallback={this.closeModal}
                      showHeaderCloseButton={true}
                      useDefaultToolbar={this.props.defaultNav}
                      elementsToAllowFocus={this.props.elementsToAllowFocus}
                      id={this.props.id}>
        {(this.props.steps || this.state.children.some(child => child !== null && child.props.stepTitle !== undefined)) &&
          <ul className="step-visualization full-width" id={'wizardStepIndicatorId'}>
            {this.props.steps && this.props.steps.map((stepTitle, index) =>
              <li key={index} className={classNames({ 'active': index === this.state.activeStep })}>{stepTitle}</li>
            )}
            {!this.props.steps && this.state.children.map((child, index) =>
              child.props !== undefined ?
                <li key={index}
                    className={classNames({ 'active': index === this.state.activeStep })}>{child.props.stepTitle}</li> : null
            )}
          </ul>}
        {childrenWithProps}
        {this.props.defaultNav === false ? null :
          <WizardNavigationButtonToolbar nextButtonText={'Nästa'}
                                         prevButtonText={'Bakåt'}
                                         onClickNextButton={this.state.activeStep !== this.state.children.length - 1 ? this.nextStep : undefined}
                                         onClickPrevButton={this.state.activeStep === 1 ? this.previousStep : undefined}/>}
      </ModalDialogTop>
    )
  }
}

export default (WizardDialog)