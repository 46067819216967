import React, { Dispatch, useEffect, useState } from 'react'
import { Term } from 'views'
import { createValidator, required } from 'utils/validation'
import { formProperties } from 'redux/formProperties'
import FormStateToRedux from 'redux/FormStateToRedux'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { initFormData } from '../../../redux/finalForm'
import {
  CheckboxFinalForm,
  Condition,
  Subscribe,
  TextAreaFinalForm,
  WhenFieldChanges
} from '../../General/forms/finalForm'
//@ts-ignore
import { Column, FormFieldset, Row, PageSubContent } from 'redet-react-components'


type AvstyckProps = {
  initFormData: (atgard: string) => {}
}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: any) => {
  return { initFormData: (formField: string) => ownProps.initFormData || dispatch(initFormData(formField)) }
}

export let validateAvstyckningForm = function (values: any) {
  return createValidator(values,
    {
      annatBeskrivning: values?.annat ? [required] : [],
      bildaServitutBeskrivning: values?.bildaServitut ? [required] : [],
      overforFastighetstillbehorBeskrivning: values?.overforFastighetstillbehor ? [required] : [],
      berorsRattighetsinnehavareBeskrivning: values?.berorsRattighetsinnehavare ? [required] : [],
    })
}

export function AvstyckningForm(props: AvstyckProps) {

  const [_formDataLoaded, setFormDataLoaded] = useState(false);
  const [_initData, setInitData] = useState({});
  
  useEffect(() => {
    const res = props.initFormData(formProperties.avstyckning.name)
    setFormDataLoaded(true);
    setInitData(res)
  }, [])
  
  return (
    <PageSubContent title={"Avstyckning"} headerLevel={4} id="fastighetsforandring-avstyckning" headerId="fastighetsforandring-avstyckningHeader">
        <Form
          onSubmit={() => {
          }}
          initialValues={_initData}
          subscription={{ submitting: true, pristine: true }}
          validate={values => validateAvstyckningForm(values)}>
          {() => {
            return (
              <form onSubmit={() => {
              }}>
                {_formDataLoaded && <FormStateToRedux form={formProperties.avstyckning.name}/>}
                <Row>
                  <Column columns={'col-xs-12 col-md-6'}>
                    <FormFieldset title={'Vad ska den nybildade fastigheten användas till?'}
                                  id={'avstyckningNybildadFastighetsLabelId'}>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="helarsbostad" label="Helårsbostad" id={'avstyckningHelarsbostadId'}/>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="fritidsbostad" label="Fritidsbostad" id={'avstyckningFritidsbostadId'}/>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="mindreOdling"
                                         label="Bostad med mark för mindre odling/djurhållning/husbehovsved"
                                         id={'avstyckningMindreOdlingId'}/>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="jordSkogsbruk" label="Jord/skogsbruk"
                                         id={'avstyckningJordSkogsbrukId'}/>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="industriverksamhet" label="Industriverksamhet"
                                         id={'avstyckningIndustriverksamhetId'}/>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="tredimensionellFastighet"
                                         label="Bilda ny tredimensionell (3-D) fastighet"
                                         id={'avstyckningTredimensionellFastighetId'}/>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="annat" label="Annat" id={'avstyckningAnnatId'}/>
                      {/* @ts-ignore */}
                      <Condition when={'annat'} is={true}>
                        <TextAreaFinalForm
                          id={'annatBeskrivningId'}
                          field={'annatBeskrivning'}
                          label={'Beskriv vad ny fastighet ska användas till.'}
                          mandatory={true}
                          characterLimit={4000}
                        />
                      </Condition>
                      <WhenFieldChanges field={'annat'} becomes={false} set={'annatBeskrivning'} to={undefined}/>
                    </FormFieldset>
                  </Column>
                  <Column columns={'col-xs-12 col-md-6'}>
                    <FormFieldset title={'Ska den nybildade fastigheten bebyggas?'}>
                      <Subscribe fieldName={'ingenByggnad'}>
                        {(prop: any) => {
                          const ingenByggnadChecked = prop.input.value
                          return (
                            <>
                              <WhenFieldChanges field={'ingenByggnad'} becomes={true} set={['befintlig', 'ny']}
                                                to={false}/>
                              {/* @ts-ignore */}
                              <CheckboxFinalForm field="ingenByggnad" id={'avstyckningIngenByggnadId'}
                                                 label="Nej, ny fastighet är inte avsedd att bebyggas (t ex enbart skogsmark)."/>
                              {/* @ts-ignore */}
                              <CheckboxFinalForm field="befintlig" label="Nej, enbart befintliga byggnader berörs."
                                                 id={'avstyckningBefintligId'} disabled={!!ingenByggnadChecked}/>
                              {/* @ts-ignore */}
                              <CheckboxFinalForm field="ny" label="Ja, avstyckningen sker för nybyggnation."
                                                 id={'avstyckningNyId'}
                                                 disabled={!!ingenByggnadChecked}/>
                            </>
                          )
                        }}
                      </Subscribe>
                    </FormFieldset>
                  </Column>

                </Row>
                <FormFieldset title={'Övriga uppgifter'}>
                  <Row>
                    <Column columns={'col-xs-12 col-md-6'}>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm
                        field="inteckningsfri"
                        label="Nybildad fastighet ska vara fri från inteckningar"
                        id={'avstyckningInteckningsfriId'}
                        termPostfixText={'Läs mer om inteckningsfri avstyckning under'}
                        term={
                          <Term
                            label="avstyckning"
                            moment="help-description"
                            term="term-avstyckning"
                          />
                        }
                      />
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="bildaServitut" label="Ny rättighet ska bildas"
                                         id={'avstyckningBildaServitutId'}
                                         term={<Term label="servitut" moment="help-description"
                                                     term="term-servitut"/>}/>
                      {/* @ts-ignore */}
                      <Condition when={'bildaServitut'} is={true}>
                        <TextAreaFinalForm
                          field={'bildaServitutBeskrivning'}
                          label={'Beskriv vad det nya servitutet avser. Du kan även rita in det i kartskissen.'}
                          id={'bildaServitutBeskrivningId'}
                          mandatory={true}
                          characterLimit={4000}/>
                      </Condition>
                      <WhenFieldChanges field={'bildaServitut'} becomes={false} set={'bildaServitutBeskrivning'}
                                        to={undefined}/>

                    </Column>
                    <Column columns={'col-xs-12 col-md-6'}>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="overforFastighetstillbehor"
                                         id={'avstyckningOverforFastighetstillbehorId'}
                                         label="Fastighetstillbehör ska överföras från en fastighet till en annan fastighet."
                                         term={<Term label="fastighetstillbehör" moment="help-description"
                                                     term="term-fastighetstillbehor"/>}/>
                      {/* @ts-ignore */}
                      <Condition when={'overforFastighetstillbehor'} is={true}>
                        <TextAreaFinalForm
                          id={'overforFastighetstillbehorBeskrivningId'}
                          field={'overforFastighetstillbehorBeskrivning'}
                          label={'Beskriv vilket fastighetstillbehör som ska överföras.'}
                          mandatory={true}
                          characterLimit={4000}/>
                      </Condition>
                      <WhenFieldChanges field={'overforFastighetstillbehor'} becomes={false}
                                        set={'overforFastighetstillbehorBeskrivning'} to={undefined}/>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field="berorsRattighetsinnehavare"
                                         id={'avstyckningBerorsRattighetsinnehavareId'}
                                         label="Nybildad fastighet berörs av rättighet som inte är inskriven i fastighetsregistret."
                                         term={<Term label="oinskriven rättighet" moment="help-description"
                                                     term="term-oinskrivna-rattighetsinnehavare"/>}/>
                      {/* @ts-ignore */}
                      <Condition when={'berorsRattighetsinnehavare'} is={true}>
                        <TextAreaFinalForm
                          id={'berorsRattighetsinnehavareBeskrivningId'}
                          field={'berorsRattighetsinnehavareBeskrivning'}
                          characterLimit={4000}
                          label={'Om du vet, ange vem som har rättigheten och vad den avser, t ex arrende. ' +
                          'Du kan även markera eventuella rättighetsområde i kartskissen. ' +
                          'Om det finns flera oinskrivna rättigheter kan du ange samtliga här.'}
                          mandatory={true}
                        />
                      </Condition>
                      <WhenFieldChanges field={'berorsRattighetsinnehavare'} becomes={false}
                                        set={'berorsRattighetsinnehavareBeskrivning'} to={undefined}/>
                    </Column>
                  </Row>
                </FormFieldset>
                <FormFieldset title={'Behöver du förtydliga något?'}
                              description={'Gäller ansökan fler avstyckningar så kan du förtydliga vad som gäller (ändamål, byggnad mm.)' +
                                ' för respektive nybildad fastighet'}
                >
                  <TextAreaFinalForm
                    id={'fleraAvstyckningarFortydligandeId'}
                    field={'fleraAvstyckningarFortydligande'}
                    label={'Förtydligande'}
                    mandatory={false}
                    characterLimit={4000}
                  />
                </FormFieldset>
              </form>
            )
          }}
        </Form>
        </PageSubContent>
    )
}


export default connect(undefined, mapDispatchToProps)(AvstyckningForm)
