import {
    avbrytBeskärningAvKartskissKnappikon,
    avbrytBeskärningAvKartskissKnapptext,
    slutförKartaKnappikon,
    slutförKartaKnapptext
  } from '../../../../components/Map/MapPanel'
  //@ts-ignore
  import { Ikon, SidePanelChapterHeading, SidePanelSection, VarningsRuta } from 'redet-react-components'

  export const KartaHjalpCropping = (
    <>
      <SidePanelChapterHeading header={'Bildformat'}
                               sectionTitle={'Kartskisser'}
                               introduction={'Kartskisser sparas i 4:3-format och kommer därför beskäras. De mörka kanterna runt kartan kommer\n' +
                                 '                    att beskäras bort. Placera det du vill spara innanför de mörka kanterna genom att panorera och zooma och\n' +
                                 '                    klicka sedan\n' +
                                 '                    på {slutförKartaKnapptext} Panoreringsverktyget har valts åt dig automatiskt.'}>
        <VarningsRuta
            ariaTitle={"Info"}
          intro={'OBS! Kartskisser sparas alltid som karta, dvs inte som flygfoto. Detta görs för att det ska bli så tydligt\n' +
            '                    som möjligt i ansökan.'}/>
      </SidePanelChapterHeading>
      <SidePanelSection
        heading={<><Ikon name={avbrytBeskärningAvKartskissKnappikon}/>{avbrytBeskärningAvKartskissKnapptext}</>}
        id={'map-format-cancel-id'}
        text={'Används för att gå tillbaka till ritläget och fortsätta arbeta i kartskissen.'}/>

      <SidePanelSection heading={<><Ikon name={slutförKartaKnappikon}/>{slutförKartaKnapptext}</>}
                        id={'map-format-done-id'}
                        text={'Används när du vill stänga och spara din kartskiss för att komma tillbaka till ansökningssidan.\n' +
                          '                    Namn på skissen sätts automatiskt till Kartskiss men det går att ändra till det du själv vill genom att du ändrar texten.'}/>
    </>
  )