import { get, list, remove, upload } from 'repository/bifogadKartskissRepository'
import { Fetcher } from '../../repository/fetcher'
import { LOAD_SUCCESSFUL as ANSOKAN_LOAD_SUCCESSFUL } from './ansokanCRUD'

const ACTION_DISPLAY_UPLOAD_FORM = 'BifogadKartskiss/ACTION_DISPLAY_UPLOAD_FORM'
const ACTION_CLOSE_UPLOAD_FORM = 'BifogadKartskiss/ACTION_CLOSE_UPLOAD_FORM'

const ACTION_FETCH_BIFOGAD_KARTSKISS_PENDING = 'BifogadKartskiss/ACTION_FETCH_BIFOGAD_KARTSKISS_PENDING'
const ACTION_FETCH_BIFOGAD_KARTSKISS_SUCCESS = 'BifogadKartskiss/ACTION_FETCH_BIFOGAD_KARTSKISS_SUCCESS'
const ACTION_FETCH_BIFOGAD_KARTSKISS_FAILED = 'BifogadKartskiss/ACTION_FETCH_BIFOGAD_KARTSKISS_FAILED'

const ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_PENDING = 'BifogadKartskiss/ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_PENDING'
export const ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_SUCCESS = 'BifogadKartskiss/ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_SUCCESS'
const ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_FAILED = 'BifogadKartskiss/ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_FAILED'

const ACTION_UPLOAD_BIFOGAD_KARTSKISS_PENDING = 'BifogadKartskiss/ACTION_UPLOAD_BIFOGAD_KARTSKISS_PENDING'
export const ACTION_UPLOAD_BIFOGAD_KARTSKISS_SUCCESS = 'BifogadKartskiss/ACTION_UPLOAD_BIFOGAD_KARTSKISS_SUCCESS'
const ACTION_UPLOAD_BIFOGAD_KARTSKISS_FAILED = 'BifogadKartskiss/ACTION_UPLOAD_BIFOGAD_KARTSKISS_FAILED'

const ACTION_REMOVE_BIFOGAD_KARTSKISS_PENDING = 'BifogadKartskiss/ACTION_REMOVE_BIFOGAD_KARTSKISS_PENDING'
export const ACTION_REMOVE_BIFOGAD_KARTSKISS_SUCCESS = 'BifogadKartskiss/ACTION_REMOVE_BIFOGAD_KARTSKISS_SUCCESS'
const ACTION_REMOVE_BIFOGAD_KARTSKISS_FAILED = 'BifogadKartskiss/ACTION_REMOVE_BIFOGAD_KARTSKISS_FAILED'

const PENDING_FILES = 'BifogadKartskiss/PENDING_FILES'
const NO_PENDING_FILES = 'BifogadKartskiss/NO_PENDING_FILES'

const initialState = {
  fetchedBifogadKartskiss: {},
  shouldDisplayLightbox: false,
  list: [],
  isLoadingList: false,
  isLoading: false,
  isUploadFormVisible: false,
  isUploading: false,
  isDeleting: false,
  pendingFiles: false,
  currentIndex: 0,
  numberOfUppladdadeKartor: 0
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case NO_PENDING_FILES:
      return {
        ...state,
        pendingFiles: false,
      }

    case PENDING_FILES:
      return {
        ...state,
        pendingFiles: true,
      }

    case ACTION_DISPLAY_UPLOAD_FORM:
      return {
        ...state,
        isUploadFormVisible: true,
      }

    case ACTION_CLOSE_UPLOAD_FORM:
      return {
        ...state,
        isUploadFormVisible: false,
        pendingFiles: false,
      }

    case ACTION_FETCH_BIFOGAD_KARTSKISS_PENDING:
      return {
        ...state,
        isLoading: true,
      }

    case ACTION_FETCH_BIFOGAD_KARTSKISS_SUCCESS:
      return {
        ...state,
        fetchedBifogadKartskiss: action.result,
        isLoading: false,
        shouldDisplayLightbox: true
      }

    case ACTION_FETCH_BIFOGAD_KARTSKISS_FAILED:
      return {
        ...state,
        fetchedBifogadKartskiss: {},
        isLoading: false,
      }

    case ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_PENDING:
      return {
        ...state,
        isLoadingList: true,
      }

    case ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_SUCCESS:
      return {
        ...state,
        list: action.result,
        isLoadingList: false,
        numberOfUppladdadeKartor: action.result.length
      }

    case ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_FAILED:
      return {
        ...state,
        list: [],
        isLoadingList: false,
      }

    case ACTION_UPLOAD_BIFOGAD_KARTSKISS_PENDING:
      return {
        ...state,
        isUploading: true,
      }

    case ACTION_UPLOAD_BIFOGAD_KARTSKISS_SUCCESS:
      return {
        ...state,
        isUploading: false,
        numberOfUppladdadeKartor: state.numberOfUppladdadeKartor + 1
      }

    case ACTION_UPLOAD_BIFOGAD_KARTSKISS_FAILED:
      return {
        ...state,
        isUploading: false,
      }

    case ACTION_REMOVE_BIFOGAD_KARTSKISS_PENDING:
      return {
        ...state,
        isDeleting: true,
      }

    case ACTION_REMOVE_BIFOGAD_KARTSKISS_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        numberOfUppladdadeKartor: state.numberOfUppladdadeKartor - 1
      }

    case ACTION_REMOVE_BIFOGAD_KARTSKISS_FAILED:
      return {
        ...state,
        isDeleting: false,
      }

    case ANSOKAN_LOAD_SUCCESSFUL:
      return {
        ...state,
        numberOfUppladdadeKartor: action.result.data.numberOfUppladdadeKartor === undefined ? 0 : action.result.data.numberOfUppladdadeKartor,
      }

    default:
      return state
  }
}

export function displayUploadForm () {
  return {
    type: ACTION_DISPLAY_UPLOAD_FORM,
  }
}

export function closeUploadForm () {
  return {
    type: ACTION_CLOSE_UPLOAD_FORM,
  }
}

export function pendingFiles (bool) {
  if (bool) {
    return {
      type: PENDING_FILES
    }
  }
  return {
    type: NO_PENDING_FILES
  }
}

export function fetchingBifogadKartskiss () {
  return {
    type: ACTION_FETCH_BIFOGAD_KARTSKISS_PENDING,
  }
}

export function fetchBifogadKartskissSuccess (result) {
  return {
    type: ACTION_FETCH_BIFOGAD_KARTSKISS_SUCCESS,
    result: result,
  }
}

export function fetchBifogadKartskissFailed () {
  return {
    type: ACTION_FETCH_BIFOGAD_KARTSKISS_FAILED,
  }
}

export function fetchBifogadKartskiss (ansokanId, bifogadKartskissId) {
  return function action (dispatch, getState) {
    dispatch(fetchingBifogadKartskiss())
    const fetcher = new Fetcher(dispatch, getState)
    return get(fetcher, ansokanId, bifogadKartskissId)
      .then((bifogadKartskiss) => {
        dispatch(fetchBifogadKartskissSuccess(bifogadKartskiss))
      }).catch(() => {
        dispatch(fetchBifogadKartskissFailed())
      })
  }
}

/*export function fetchBifogadKartskissList(ansokanId, pendingCallback, successCallback, failCallback) {
  return {
    types: [ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_PENDING, ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_SUCCESS, ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_FAILED],
    backendPromise: (ajaxDriver) => list(ajaxDriver, ansokanId),
    callbacks: {pending: pendingCallback, success: successCallback, fail: failCallback}
  };
}*/

export function fetchingBifogadKartskissList () {
  return {
    type: ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_PENDING,
  }
}

export function fetchBifogadKartskissListSuccess (result) {
  return {
    type: ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_SUCCESS,
    result: result,
  }
}

export function fetchBifogadKartskissListFailed () {
  return {
    type: ACTION_FETCH_BIFOGAD_KARTSKISS_LIST_FAILED
  }
}

export function fetchBifogadKartskissList (ansokanId) {
  return function action (dispatch, getState) {
    dispatch(fetchingBifogadKartskissList())
    const fetcher = new Fetcher(dispatch, getState)
    return list(fetcher, ansokanId)
      .then((bifogadeKartskisser) => {
        dispatch(fetchBifogadKartskissListSuccess(bifogadeKartskisser))
      }).catch(() => {
        dispatch(fetchBifogadKartskissListFailed())
      })
  }
}

export function uploadBifogadKartskiss (namn, ansokanId, bifogadKartskiss, xhrProxy, pendingCallback, progressCallback, successCallback, failCallback) {
  return {
    types: [ACTION_UPLOAD_BIFOGAD_KARTSKISS_PENDING, ACTION_UPLOAD_BIFOGAD_KARTSKISS_SUCCESS, ACTION_UPLOAD_BIFOGAD_KARTSKISS_FAILED],
    backendPromise: (ajaxDriver) => upload(ajaxDriver, namn, ansokanId, bifogadKartskiss, progressCallback, xhrProxy),
    callbacks: { pending: pendingCallback, success: successCallback, fail: failCallback }
  }
}

export function removingBifogadKartskiss () {
  return {
    type: ACTION_REMOVE_BIFOGAD_KARTSKISS_PENDING,
  }
}

export function removeBifogadKartskissSuccess () {
  return {
    type: ACTION_REMOVE_BIFOGAD_KARTSKISS_SUCCESS,
  }
}

export function removeBifogadKartskissFailed () {
  return {
    type: ACTION_REMOVE_BIFOGAD_KARTSKISS_FAILED
  }
}

export function removeBifogadKartskiss (ansokanId, kartskissId) {
  return function action (dispatch, getState) {
    dispatch(removingBifogadKartskiss())
    const fetcher = new Fetcher(dispatch, getState)
    return remove(fetcher, ansokanId, kartskissId)
      .then(() => {
        dispatch(removeBifogadKartskissSuccess())
      }).catch(() => {
        dispatch(removeBifogadKartskissFailed())
      })
  }
}

