import React, {useEffect, useRef, useState} from "react";
import {Komplettering} from "../../../../domain/Komplettering";
import {KompletteringItemViewMemo} from "./KompletteringItemView";
// @ts-ignore
import {ListGroup, VarningsRuta} from "redet-react-components";
import {sortBy} from "../../../../helpers/arrays";
import {useAppDispatch} from "../../../../hooks";
import {fetchSigningStatusKomplettering} from "../../../../redux/modules/arendeKomplettering";
import SmallLoading from "../../../General/SmallLoading";
import {ARENDE_LIST_PAGE_SIZE} from "../../../../helpers/KompletteringHelper";

interface Props {
    title?: string,
    introduction?: string,
    kompletteringar: Komplettering[],
    isReloadingList?: boolean,
    onRefetch: () => void,
    id?: string
}

/**
 * Skapar en paginerad lista med ETT URVAL aktiva kompletteringar och dess status
 * @param title
 * @param introduction
 * @param kompletteringar
 * @param isReloadingList
 * @param onRefetch
 * @param id
 * @param rest
 * @constructor
 */
export function AktivaKompletteringarList({ title, introduction, kompletteringar, isReloadingList = false, onRefetch, id, ...rest }: Props) {

    const _firstRender = useRef(false);
    const [initList, setInitList] = useState<Komplettering[]>([])
    const [kompletteringarList, setKompletteringarList] = useState<Komplettering[]>([])
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)

    const listGroupOptions = {
        list: initList,
        listSetter: setKompletteringarList,
        paginationConfig: {
            initPage: 0,
            maxNoPageButtons: 5,
            pageSize: ARENDE_LIST_PAGE_SIZE,
            onPageChangeScrollToTopOfList: true
        }
    }

    const dispatch = useAppDispatch()

    useEffect(() => {
        setIsLoadingStatus(true)
    }, [])

    useEffect(() => {

        // TODO endpoint som hämtar samtliga kompletteringar MED signeringsstatus ?
        // initialt saknas signeringsstatus,
        // vi behöver hämta signeringsstatus för samtliga pågående kompletteringar
        // detta för att filtrera bort ev.felaktiga signeringar som inte kan visas och inte kan tas bort,
        // felaktiva signeringar som inte visas gör så att listans paginering blir tokig (fel antal visas)

        if (!_firstRender.current) //first load
            setIsLoadingStatus(true)
        _firstRender.current = true

        fetchValidKompletteringar()
            .then(validList => {
                const sortedList = sortBy(validList.map(item => item.value), 'skapad').reverse()
                setInitList(sortedList)
            })
            .finally(() => setIsLoadingStatus(false))

    }, [kompletteringar])

    function fetchValidKompletteringar() {
        const promises = kompletteringar.map(komplettering => dispatch(fetchSigningStatusKomplettering(komplettering.kompletteringId)))
        return Promise.allSettled<Komplettering[]>(promises)
            .then(result => {
                //const faultyList = (result.filter(item => item.status === 'rejected') as PromiseRejectedResult[]).map(item => item.reason)
                const validList = (result.filter(item => item.status !== 'rejected') as PromiseFulfilledResult<Komplettering>[])
                return validList;
            })
    }

    function handleError(kompletteringId: string, error: Error) {
        //todo ska vi göra något om en komplettering kastar ett fel?
        console.log(`Oväntat fel vid hantering av komplettering med id: '${kompletteringId}', ${error}`)
    }

    return (<>
        <ListGroup title={title ?? ''}
                   description={<p>{introduction}</p>}
                   listOptions={listGroupOptions}
                   hasSubList={true}
                   id={id}
                   {...rest}>
            {!isLoadingStatus ? <>
                    {kompletteringarList.map(komplettering =>
                        // renderar en memoized komplettering-item
                        // för att undvika omrendering av varje item vid refetch
                        <KompletteringItemViewMemo
                            key={`komplettering-item-view-${komplettering.kompletteringId}`}
                            komplettering={komplettering}
                            isReloading={isReloadingList}
                            onDeleted={() => onRefetch()}
                            onSigningCompleted={() => onRefetch()}
                            onError={(id: string, error: Error) => handleError(id, error)}/>
                    )}
                    {initList.length === 0 &&
                        <section className='content'>
                            <p>
                                <span className='additional_information'>Det finns inga pågående aktiviteter för det här ärendet.</span>
                            </p>
                        </section>
                    }
                </> :
                <section className='content'>
                    <SmallLoading text={"Laddar pågående aktiviteter ..."}></SmallLoading>
                </section>
            }
        </ListGroup>
        {initList.length > 0 && <VarningsRuta ariaTitle={'Observera'}
                                               description={'När en komplettering är signerad/underskriven av alla inbjudna kommer den att skickas in automatiskt. Det kan ta upp till 5 minuter innan detta sker.'}/>
        }
    </>)
}

export default AktivaKompletteringarList