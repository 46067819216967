import { Term } from '../../index'
import { getLocalizedDateString } from '../../../helpers/dates'
//@ts-ignore
import { ButtonTop, FormFieldset } from 'redet-react-components'
import { DocumentDetails } from 'domain/Forratningsarende/DocumentDetails'

type Props = {
  selectedAktbilagebeteckningar: any[],
  handleSelectChange: (evt: React.FormEvent<HTMLInputElement>) => {},
  listOfDocuments: DocumentDetails[],
  nextStep?: () => void
}
function ValjAnsokanStepWizard(props: Props){

    return (
      <>
        <FormFieldset title={'Välj ansökan'} description={<>
          Välj den eller de ansökningar som du vill <Term label="bjuda in medsökanden" moment="help-komplettering"
                                                          term="term-bitradande"/> till.
        </>}>
          <div id="ansokan-select-list" className="with-top-margin">
            {props.listOfDocuments.map((document, index) =>
              <div className="form-checkbox" key={'key_' + document.akt + document.documentName}>
                <label htmlFor={'ansokanCheckbox' + index}>
                  <input id={'ansokanCheckbox' + index} type="checkbox"
                        //@ts-ignore
                         checked={props.selectedAktbilagebeteckningar.includes(document.akt)} value={document.akt}
                         onChange={(evt) => {props.handleSelectChange(evt)}}/>
                  <span className="text">{document.documentName},<span
                    className="text"> publicerad {getLocalizedDateString(document.publishedDate, 'd LLLL y')}, aktbilaga <span
                    className="item">{document.akt}</span></span></span>
                </label>
              </div>
            )}
          </div>
        </FormFieldset>
        {/* eslint-disable-next-line rrc-recommendations/replace-with-rrc */}
        <div className="toolbar">
          <div className="button-group"/>
          <div className="button-group">
            <ButtonTop text={'Ange kvittensmottagare'}
                       icon={'arrow_forward'}
                       id="finishSelectAktbilaga"
                       isDisabled={(props.selectedAktbilagebeteckningar === undefined || props.selectedAktbilagebeteckningar.length === 0)}
                       onClick={props.selectedAktbilagebeteckningar !== undefined &&
                       props.selectedAktbilagebeteckningar.length !== 0 ? () => {if(props.nextStep){props.nextStep()}} : undefined}/>
          </div>
        </div>
      </>
    )
  }


export default (ValjAnsokanStepWizard)
