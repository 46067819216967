//@ts-ignore
import { ExternalLink, SidePanelChapterHeading } from 'redet-react-components'
import '../../../i18n';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export function KontaktaOssHelpContent() {

  const user = useSelector((store: RootState) => store.auth.user)
  const isSdg = user?.isSdg || window.location.href.includes('/sdg')
  
  return(
  <SidePanelChapterHeading header={t('contactUs.title')}
                           introduction={<>{t('contactUs.introText')}
                            {isSdg ? 
                             <ExternalLink
                               text={t('contactUs.here')}
                               url= {'https://www.lantmateriet.se/sdg-contact'}
                               openInNewTab={true}/> : 
                               <ExternalLink text={t('contactUs.here')}
                                url={'https://lantmateriet.se/kontakt	'}/>}
                             <br/>
                             <br/>
                             {t('contactUs.contactInfo')}
                           </>
                           }/>)}