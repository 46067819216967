import {containsOnlyValidCharsForAFastighetsbeteckning, convertToArrayIfNot} from 'utils/helperMethods'
import {AjaxClientError, FetchClientError, logError, NetworkError} from 'utils/errorHandler'
import Log from '../utils/log'
import type {Fetcher} from "./fetcher";
import {FastighetsInnehav} from "../redux/modules/FastighetsInnehav";

const log = new Log('fastighetRepository');
const genericError = 'propertyErrMsg.techIssue';

export class LoadFastighetException {
    public errorMessage: string;
    constructor(errorMessage = '') {
        this.errorMessage = errorMessage;
    }
}

let validateRegisterbeteckning = function (beteckning: string, reject: (reason?: any) => void, fastighetsInnehav?: Array<FastighetsInnehav>) {
    if (!beteckning || beteckning.trim().length === 0) {
        reject(new LoadFastighetException('propertyErrMsg.stateProperty'));
    }
    if (beteckning.indexOf(' ') === -1 || !containsOnlyValidCharsForAFastighetsbeteckning(beteckning) || beteckning.startsWith(' ')) {
        reject(new LoadFastighetException('propertyErrMsg.invalidFormat'));
    }

    if (fastighetsInnehav?.some((item: FastighetsInnehav) => item?.beteckning === beteckning)) {
        reject(new LoadFastighetException('propertyErrMsg.propertyAddedInWrongList'));
    }
};

export function loadFaststighetsinformation (fetcher: Fetcher, beteckning: string) {
  return new Promise((resolve, reject: (reason?: any) => void) => {
    validateRegisterbeteckning(beteckning, reject)
    const registerbeteckningUrl = '/api/fastighetsinformation/?registerbeteckning=' + encodeURIComponent(beteckning)
    return fetcher.fetching(registerbeteckningUrl)
      .then(response => {
        return response.json()
      }).then((result => {
        resolve({ beteckning: result })
      }))
      .catch(err => {
        if (err.status === 404) {
          reject(new LoadFastighetException('propertyErrMsg.propertyNotFound'))
        } else {
          const error = new AjaxClientError(registerbeteckningUrl, err.status, err, err.statusText)
          log.error(error)
          reject(error)
        }
      })

    }).then(response => {
        return response
    }).catch(err => {
        throw err;
    });
}

export function loadRegisterbeteckning (fetcher: Fetcher, beteckning: string, fastighetsInnehav: Array<any>): Promise<any> {
  return new Promise((resolve, reject) => {
    validateRegisterbeteckning(beteckning, reject, fastighetsInnehav)
    const registerbeteckningUrl = '/api/registerbeteckning/?beteckning=' + encodeURIComponent(beteckning)
    return fetcher.fetching(registerbeteckningUrl)
      .then(response => {
        return response.json()
      }).then((result => {
        resolve({ beteckning: result })
      }))
      .catch(err => {
        if (err.status === 404) {
          reject(new LoadFastighetException('propertyErrMsg.propertyNotFound'))
        } else {
          const error = new AjaxClientError(registerbeteckningUrl, err.status, err, err.statusText)
          log.error(error)
          reject(error)
        }
      })

    }).then(response => {
        return response
    }).catch(err => {
        throw err;
    });
}

export type AutoCompleteOptionsType = {
    searchString: string,
    options: string[]
}

export function getAutoCompleteOptions (fetcher: Fetcher, searchString: string, signal: any): Promise<AutoCompleteOptionsType> {
  return new Promise((resolve, reject) => {
    if (!searchString || searchString.trim().length === 0 || !containsOnlyValidCharsForAFastighetsbeteckning(searchString)) {
      resolve({
        searchString: '',
        options: []
      })
      return
    }
    const url = '/api/fastighetsbeteckning/autocomplete/?beteckning=' + encodeURIComponent(searchString.toUpperCase())
    return fetcher.fetching(url, { signal })
      .then(response => {
        return response.json()
      })
      .then(result => {
        let autocompleteOptions
        autocompleteOptions = convertToArrayIfNot(result)
        resolve({
          searchString: searchString,
          options: autocompleteOptions,
        })
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          // We dont want fastigheter reducer to add the error to the app state this should be a quite error
          return null
        } else {
          log.error(error)
          // Custom error handling of error
          reject(genericError)
        }
      })
  })
}

export function findOwners(fetcher: Fetcher, objektidentiteter: any) {
    const url = '/api/agarinformation/byobjektidentiteter';
    return fetcher.fetching(url,
        {
            cache: "no-store",
            method: "POST",
            body: JSON.stringify(objektidentiteter)
        },
        {
            'Content-Type': 'application/json'
        }).then(response => {
        return response.json();
    }).then(data => {
        return {agare: data};
    }).catch((err) => {
        log.error(err);

        let error = err;
        // Custom error handling
        if (err instanceof TypeError) {
            error = new NetworkError('TypeError', err.message, url);
            logError(error);
        }
        if (err instanceof Response) {
            err.json().then((json) =>
                logError(new FetchClientError(err, 'POST', '', json.felmeddelande))
            )
        }

        // Rethrow error for other catch-promises down the execution chain
        throw err;
    });
}

export function importeraFastighetsagare(fetcher: Fetcher, ansokanId: string) {
    const url = '/api/ansokan-service/ansokan/' + ansokanId + '/fastighetsinformation/agare';
    return fetcher.fetching(url,
        {
            cache: "no-store",
            method: "POST",
        },
        {}).catch((err) => {
        log.error(err);

        let error = err;
        // Custom error handling
        if (err instanceof TypeError) {
            error = new NetworkError('TypeError', err.message, url);
            logError(error);
        }
        if (err instanceof Response) {
            err.json().then((json) =>
                logError(new FetchClientError(err, 'POST', '', json.felmeddelande))
            )
        }
        // Rethrow error for other catch-promises down the execution chain
        throw err;
    });
}

export type Enhetsomrade = {
    "yta": Array<any>
}

export type RegisterOmradeResponse = {
    "objektidentitet": string,
    "registerbeteckning": {
        "tidigareRegisterbeteckningar": [],
        "text": string,
        "shortText": string,
        "registeromrade": string,
        "traktnamn": string,
        "block": string,
        "enhet": number
},
    "enhetsomrade": Array<Enhetsomrade>,
    "fiskeomrade": [],
    "fastighetsnyckel"?: string,
    "outrettOmrade"?: any,
    "enhetsutrymme"?: any
}
//borde detta gå mot en egen endpoint som enbart returnerar områden från FastighetsinformationController?
export function getFastighetsOmraden(fetcher: Fetcher, valdFastighet: string): Promise<RegisterOmradeResponse> {
        const registerbeteckningUrl = `/api/fastighetsinformation/${valdFastighet}`;
        return fetcher.fetching(registerbeteckningUrl)
            .then(response => {
                return response.json();
            }).then((result => {
                return result;
            }))
            .catch(err => {
                if (err.status === 404) {
                    logError(new LoadFastighetException('propertyErrMsg.propertyNotFound'));
                    return err // Vi måste returnera error-objektet här eftersom det finns kontroller senare i flödet som är beroende av detta.
                } else {
                    const error = new AjaxClientError(registerbeteckningUrl, err.status, err, err.statusText);
                    logError(error);
                    return err
                }
            });
}

