import { SmallLoading } from 'components'
import { useAppDispatch } from 'hooks'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
//@ts-ignore
import { ButtonGroup, ButtonSub, ButtonToolbarV2, Felruta, FormFieldset, VarningsRuta } from 'redet-react-components'
import { loadFastighetsinnehav } from 'redux/modules/fastighetsinnehav-reducer'
import { RootState } from 'store'
import { Fastighetsvalslista } from 'views'


type Props = {
  onFastighetSelected: (beteckning: string) => void
  onFastighetDeselected: (beteckning: string) => void
}

function Fastighetsinnehav(props: Props) {

  const dispatch = useAppDispatch();

  const fastighetsinnehavResultRef = useRef();

  const fastighetsinnehav = useSelector((store: RootState) => store.fastighetsinnehav);
  const valdaFastigheter = useSelector((store: RootState) => store.fastigheter);
  const fullscreenLoadingIsActive = useSelector((store: RootState) => store.fullscreenLoading.visible);

  useEffect(() => {
    dispatch(loadFastighetsinnehav())
  }, [])

  const handleFastighetsvalslistaClick = (beteckning: string, isCheckboxChecked: boolean) => {
    if (isCheckboxChecked && props.onFastighetSelected) {
      props.onFastighetSelected(beteckning)
    } else if (!isCheckboxChecked && props.onFastighetDeselected) {
      props.onFastighetDeselected(beteckning)
    }
  }

  const { isLoading, list, error } = fastighetsinnehav
  const harFastigheter = list.length > 0

  return (
    <FormFieldset>
      {error &&
        <div>
          <br/>
          <Felruta errorMessages={[]} intro={'Ett fel vid laddning av fastighetsinnehav uppstod.'} ref={fastighetsinnehavResultRef}>
            <br/><br/>
            <ButtonToolbarV2>
              <ButtonGroup>
                <ButtonSub
                  text="Försök igen"
                  onClick={() => dispatch(loadFastighetsinnehav())}
                  icon="refresh"/>
              </ButtonGroup>
            </ButtonToolbarV2>
          </Felruta>
        </div>
      }
      {!harFastigheter && !isLoading && !error &&
        <div className="ansokanAgerIngenFastighet" aria-label={"Information om din fastighet"} role="status">
          <p>Du är inte registrerad som ägare på någon fastighet.</p>
        </div>
      }
      {harFastigheter && !isLoading && !error &&
        <Fastighetsvalslista onClick={handleFastighetsvalslistaClick} fastigheter={list} valdaFastigheter={valdaFastigheter.fastigheterList}/>
      }
      {(isLoading && !fullscreenLoadingIsActive) &&
        <SmallLoading text="Laddar fastigheter..."/>
      }
    </FormFieldset>
  )
}

export default Fastighetsinnehav;
