import {
  CheckboxFinalForm,
  Condition,
  NumberInputFinalForm, PersonnummerFinalForm,
  RadioFinalForm,
  TextInputFinalForm,
  WhenRadioButtonChanges
} from '../../General/forms/finalForm'
import { Column, FormFieldset, Row, ValideringsFelmeddelandeRuta } from 'redet-react-components'
import { NameForm } from './NameForm'
import { FormSpy } from 'react-final-form'
import {
  individualContactFormWarningValidation
} from '../../../views/Contacts/Individual/individualContactFormWarningValidation'
import { harOmbud } from '../../../utils/contactsHelper'
import { Term } from '../../../views'

export default function GeneralIdForm ({ formAutocomplete, contactList, contactId }) {
  const columnSetting = 'col-xs-12 col-lg-6'
  const ombudFinns = harOmbud(contactList, contactId)
  return (
    <>
      <FormSpy subscription={{ submitFailed: true, hasValidationErrors: true, errors: true, values: true }}>
        {({ submitFailed, hasValidationErrors, errors, values }) => {
          const warnings = individualContactFormWarningValidation(values, contactList, contactId)
          const warningsExists = (warnings !== undefined && Object.keys(warnings).length > 0)
          return (<FormFieldset title="Namn och ID-nummer">
              <Row>
                <NameForm formAutocomplete={formAutocomplete}/>
                <Column columns={'col-xs-12 col-lg-6'}>
                  <div className="form-group">
                    <label className="label">ID-nummer</label>
                    <div className="flex-line block-sm block-xs">
                      <RadioFinalForm label={'Personnummer'} field={'IDChoice'} value={'personnummer'}/>
                      <RadioFinalForm label={'Samordningsnummer'} field={'IDChoice'} value={'samordningsnummer'}/>
                      <RadioFinalForm label={'Övrigt'} field={'IDChoice'} value={'ovrigtIdNummer'}/>
                    </div>
                    {submitFailed && hasValidationErrors && errors.IDChoice &&
                      <ValideringsFelmeddelandeRuta text={errors.IDChoice} id="IDErrorFalt"/>
                    }
                    {warningsExists && warnings.IDChoice &&
                      <ValideringsFelmeddelandeRuta text={warnings.IDChoice} id="IDChoiceWarningFalt" isOptional={true}
                                                    isBlock={true}/>
                    }
                  </div>
                </Column>
              </Row>
              <Row>
                <Column columns={columnSetting}>
                  <Condition when={'IDChoice'} is={'personnummer'}>
                    <PersonnummerFinalForm field={'personnummer'} label={'Personnummer'} placeholder={'ÅÅÅÅMMDDXXXX'}
                                          maxNumberValue={999999999999}
                                          required={values.rollSokande === true}
                                          minNumberValue={0}
                                          term={<Term label="personnummer" moment="help-contactinformation"
                                                      term="term-personnummer"/>}/>
                  </Condition>
                  <Condition when={'IDChoice'} is={'samordningsnummer'}>
                    <NumberInputFinalForm field={'samordningsnummer'} label={'Samordningsnummer'}
                                          maxNumberValue={999999999999}
                                          required={values.rollSokande === true}
                                          minNumberValue={0}
                                          term={<Term label="samordningsnummer" moment="help-contactinformation"
                                                      term="term-samordningsnummer"/>}/>
                  </Condition>
                  <Condition when={'IDChoice'} is={'ovrigtIdNummer'}>
                    <TextInputFinalForm field={'ovrigtIdNummer'} label={'Övrigt ID-nummer'}
                                        maxLength={500}
                                        required={values.rollSokande === true}
                                        term={<Term label="övrigt id-nummer" moment="help-contactinformation"
                                                    term="term-ovrigtIdnummer"/>}
                                        data-selenium-id={'ovrigtIdNummerId'}
                                        id={'ovrigtIdNummerId'}/>
                  </Condition>
                </Column>
                <Column columns={columnSetting}>
                  {values.rollSokande === true &&
                    <Condition when={'IDChoice'} is={'personnummer'}><br/><CheckboxFinalForm field={'saknarBankId'}
                                                                                             id={'saknarBankId'}
                                                                                             label={'Personen har inget BankID'}/></Condition>
                  }
                  {!ombudFinns && warningsExists && warnings.saknarBankId &&
                    <ValideringsFelmeddelandeRuta text={warnings.saknarBankId} id="bankIdWarningFalt"
                                                  isOptional={true} aria-label={"BankId-varning"}/>
                  }
                </Column>
                <WhenRadioButtonChanges field={'IDChoice'} becomes={'ovrigtIdNummer'}
                                        set={['personnummer', 'samordningsnummer', 'saknarBankId']} to={undefined}/>
                <WhenRadioButtonChanges field={'IDChoice'} becomes={'personnummer'}
                                        set={['samordningsnummer', 'ovrigtIdNummer', 'saknarBankId']} to={undefined}/>
                <WhenRadioButtonChanges field={'IDChoice'} becomes={'samordningsnummer'}
                                        set={['personnummer', 'ovrigtIdNummer', 'saknarBankId']} to={undefined}/>
              </Row>
            </FormFieldset>
          )
        }}
      </FormSpy>
    </>
  )
}
