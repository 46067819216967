import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, FormSpy } from 'react-final-form'
import ContactFormActionButtons from 'components/contactsRelated/Common/ContactFormActionButtons'
import ContactRolesSelection from 'components/contactsRelated/Common/ContactRolesSelection'
import OrganizationIdentityForm from 'components/contactsRelated/Organization/OrganizationIdentityForm'
import ContactFastighetSelection from 'components/contactsRelated/Common/ContactFastighetSelection'
import OrganizationPhoneEmailForm from 'components/contactsRelated/Organization/OrganizationPhoneEmailForm'
import OrganizationAddressForm from 'components/contactsRelated/Organization/OrganizationAddressForm'
import InvoicingForm from 'components/contactsRelated/Common/InvoicingForm'
import { CONTACT_TYPE_ORGANIZATION, displayContactForm, saveContact } from 'redux/modules/contacts'
import organizationContactFormValidations from './organizationContactFormValidations'
import arrayMutators from 'final-form-arrays'
import {
  Condition,
  HiddenInputFinalForm,
  ValideringssamanfattningFinalForm,
  Varningssamanfattning
} from '../../../components/General/forms/finalForm'
import { NO_SCROLLING_CSS_CLASS } from '../../../utils/globalConstants'
import { Column, FormFieldset, ModalDialogTop, Row } from 'redet-react-components'
import { scrollToElementNative } from '../../../utils/scrollToElement'
import { organizationContactFormWarningValidation } from './organizationContactFormWarningValidation'

const faltNamn =
  {
    efternamn: 'Efternamn',
    orgNamn: 'Organisationsnamn',
    orgTelefon: 'Telefon, organisation',
    orgTelefonKontaktperson: 'Telefon kontaktperson direkt',
    orgTelefonKontaktpersonMobil: 'Telefon kontaktperson mobil',
    orgEmail: 'E-post, organisation',
    orgNummer: 'Organisationsnummer',
    orgKontaktpersonEmail: 'E-post, kontaktperson',
    rollAktmottagare: 'Roll',
    rollAnnanBerord: 'Roll',
    rollFakturamottagare: 'Roll',
    rollSokande: 'Roll',
    rollUpprattare: 'Roll',
    rollOmbud: 'Roll',
    postnummer: 'Postnummer',
    adress: 'Adress',
    adressFaktura: 'Fakturaadress',
    postnummerFaktura: 'Faktura-postnummer',
    postort: 'Postort',
    postortFaktura: 'Faktura-postort',
    alternativAdressAngiven: 'Ange en alternativ adress',
    adressAlternativ: 'Alternativ adress',
    postnummerAlternativ: 'Alternativ postnummer',
    postortAlternativ: 'Alternativ postort',
    landAlternativ: 'Alternativ land',
    kontaktKommentar: 'Kommentar',
    representerarFastigheter: 'Fastighet',
    optOutFromRepresentingFastighet: 'Företräder ingen fastighet'
  }

const mapStateToProps = (store) => {
  return {
    contactList: store.contacts.contactList,
    ansokanValidations: store.validation.errors,
  }
}

const mapDispatchToProps = { saveContact, displayContactForm }

export class OrganizationContactWizardForm extends Component {
  static propTypes = {
    contact: PropTypes.object,
    saveContact: PropTypes.func.isRequired,
    displayContactForm: PropTypes.func,
    ansokanValidations: PropTypes.array.isRequired,
  }

  isEditingContact = this.props.contact !== undefined

  componentDidMount () {
    document.body.classList.add(NO_SCROLLING_CSS_CLASS)
  }

  componentWillUnmount () {
    document.body.classList.remove(NO_SCROLLING_CSS_CLASS)
  }

  getFormInitValues () {
    let foretraderFastighet = false
    if (this.isEditingContact) {
      foretraderFastighet = this.props.contact.data !== undefined && this.props.contact.data.representerarFastigheter !== undefined && this.props.contact.data.representerarFastigheter.length > 0
      return {
        ...this.props.contact.data,
        id: this.props.contact.id,
        type: this.props.contact.type,
        identifier: this.props.contact.identifier,
        foretrader: foretraderFastighet ? 'ja' : 'nej',
        optOutFromRepresentingFastighet: this.props.contact.data.optOutFromRepresentingFastighet,
      }
    } else {
      return {
        type: CONTACT_TYPE_ORGANIZATION,
        foretrader: 'nej',
        isCreatedManually: true
      }
    }
  }

  sparaKontakt = (values) => {
    this.props.saveContact(values)
  }

  closeContactModal = () => {
    this.props.displayContactForm(false)
  }

  render () {
    const initialValues = this.getFormInitValues()

    return (
      <ModalDialogTop id={'contact-information'}
                      modalTitle={this.isEditingContact ? 'Komplettera uppgifter om organisationen' : 'Lägg till organisation'}
                      useDefaultToolbar={false}
                      closeModalCallback={this.closeContactModal}
                      elementsToAllowFocus={['helpSidePanelId', 'validationSidePanelId']}
                      isBig={true}>
        <Form
          onSubmit={this.sparaKontakt}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          subscription={{ submitting: true, pristine: true }}
          validate={values => {
            return organizationContactFormValidations(values, this.props.contactList, this.props.contact?.id, this.props.ansokanValidations)
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} data-selenium-id="kontaktuppgift-form" className="bottom-spacing">
              <FormSpy subscription={{ submitFailed: true, errors: true, values: true }}>
                {({ submitFailed, errors, values }) => {
                  const warnings = organizationContactFormWarningValidation(values, this.props.contactList, this.props.contact?.id)
                  if (submitFailed) {
                    scrollToElementNative('contact-informationModalContentId')
                  }
                  return (<>
                    {(submitFailed || this.props.contact !== undefined) &&
                      <ValideringssamanfattningFinalForm validationErrors={errors}
                                                         faltnamnDescription={faltNamn}
                                                         id="kontaktOrganisationValideringssamanfattningsId"/>
                    }
                    {warnings !== undefined &&
                      <Varningssamanfattning
                        validationWarnings={warnings}
                        faltnamnDescription={faltNamn}
                        id="kontaktOrganizationVarningssamanfattningsId"/>
                    }
                  </> )
                }}
              </FormSpy>
              <HiddenInputFinalForm field={'id'}/>
              <HiddenInputFinalForm field={'type'}/>
              <HiddenInputFinalForm field={'identifier'}/>
              <ContactRolesSelection editingId={this.isEditingContact ? this.props.contact.id : undefined}
                                     privatPerson={false} contact={this.props.contact}/>
              <Row>
                <Column columns="col-xs-12 col-sm-6">
                  <FormFieldset title="Organisationsuppgifter">
                    <OrganizationIdentityForm/>
                  </FormFieldset>
                </Column>
                <Column columns="col-xs-12 col-sm-6">
                  <FormFieldset title="Telefon och e-post">
                    <OrganizationPhoneEmailForm/>
                  </FormFieldset>
                </Column>
              </Row>
              <Row>
                <Column columns="col-xs-12 col-sm-6">
                  <OrganizationAddressForm/>
                </Column>
                <Column columns="col-xs-12 col-sm-6">
                  <ContactFastighetSelection
                    optOutFromRepresentingFastighet={this.props.contact?.data?.optOutFromRepresentingFastighet === true}
                    agerValdaFastigheter={this.props.contact?.data?.agerValdaFastigheter}
                    importedContact={this.props.contact?.data?.imported}/>
                </Column>
              </Row>
              <Condition when={'rollFakturamottagare'} is={true}>
                <InvoicingForm contactTypeIsOrganization={true}/>
              </Condition>
              <ContactFormActionButtons isEditing={this.isEditingContact}/>
            </form>
          )}
        </Form>
      </ModalDialogTop>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationContactWizardForm)
