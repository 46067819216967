import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {addProgress, endProgress} from 'redux/modules/fullscreenLoading'
import {ActionBar, InviteToSign, SigningFinished, SigningInProgress} from 'views'
import {
    ANSOKAN_SIGNING_STATUS_NOT_STARTED,
    ANSOKAN_SIGNING_STATUS_PENDING,
    ANSOKAN_SIGNING_STATUS_SUCCEEDED,
    ANSOKAN_SIGNING_STATUS_TIMED_OUT,
    ANSOKAN_STATUS_LOCKED,
    ANSOKAN_STATUS_REVIEW_EMAILS
} from 'utils/globalConstants'
import {Navigation, SmallLoading} from 'components'
// @ts-ignore
import {Helmet} from 'react-helmet'
// @ts-ignore
import {ModalDialogSub, SidePanelChapter} from 'redet-react-components'
import {JumpLinkToMain} from 'components/General/JumpLinkToMain'
import {fetchAnsokan} from 'redux/modules/ansokanCRUD'
import {KontaktaOssHelpContent} from "../views/Hjalp/texter/KontaktaOssHelpContent";
import Hjalp from '../views/Hjalp/Hjalp'
import {PersonuppgifterHelpContent} from "../views/Hjalp/texter/PersonuppgifterHelpContent";
import {RootState} from "../store";
import {useAppDispatch} from "../hooks";
import {abortSigningInstance, fetchSigningStatus} from "../redux/modules/signing";
import {SigningStatus} from "../domain/signing/SigningStatus";
import {Ansokan, AnsokanStatus} from "../domain/Ansokan";
import {useNavigate} from "react-router-dom";
import {Contact} from "../domain/Contact";
import SigningHederOchSamvete from "../views/Signing/SigningHederOchSamvete";
import SigningHederOchSamveteV2 from "../views/Signing/SigningHederOchSamveteV2";
import {useFeatureToggle} from "../hooks/useFeatureToggle";

const LOADING_ANIMATION_TYPE = { FULL_SCREEN_HIDE_CONTENT: 0, FULL_SCREEN_FADE_CONTENT: 1, POPOVER: 2 }

type SigningProps = {
  ansokanId: string;
};

export function Signing (props: SigningProps) {

  const [signingStatus, setSigningStatus] = useState<SigningStatus>()
  const [refreshPopupVisible, setRefreshPopupVisible] = useState(false)
  const [contactList, setContactList] = useState<Contact[]>([])
  const [upprattare, setUpprattare] = useState<Contact>()
  const [showTimedOutSigningModal, setShowTimedOutSigningModal] = useState(false)
  const [showSigningNotFoundModal, setShowSigningNotFoundModal] = useState(false)
  const [ansokanStatus, setAnsokanStatus] = useState<AnsokanStatus>()
  const [signingStatusCheckOngoing, setSigningStatusCheckOngoing] = useState(false)

  const hjalpPanelVisible = useSelector((store: RootState) => store.help.visible)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

    //** 2024-05-14
    // Signering i samma flik har en tillfällig toggle
    // vid aktiverad går signeringen inte via ny flik i signering-web
    const isSigningInLeif: boolean = useFeatureToggle('signering-leif-web')
    //**

  useEffect(() => {
    // @ts-ignore
      checkStatus(LOADING_ANIMATION_TYPE.FULL_SCREEN_HIDE_CONTENT).then(() => {
        loadContacts()
    })
  }, [])

  function loadContacts () {
      dispatch(addProgress())
      dispatch(fetchAnsokan(props.ansokanId))
          .then((ansokan: Ansokan) => {
            let upprattare = ansokan.data.contactList.find(kontakt => kontakt.data.rollUpprattare)
            setContactList(ansokan.data.contactList)
            setUpprattare(upprattare)
            setAnsokanStatus(ansokan._status)
          })
  }

  function handleTimedOutSigning () {
    dispatch(addProgress(true))
    showTimedOutSigningModalMethod();
  }

  function checkStatus (loadingAnimationType: Object) {
    const loadingAnimationStart = () => {
      switch (loadingAnimationType) {
        case LOADING_ANIMATION_TYPE.FULL_SCREEN_HIDE_CONTENT:
          dispatch(addProgress(true))
          break
        case LOADING_ANIMATION_TYPE.FULL_SCREEN_FADE_CONTENT:
          dispatch(addProgress(false))
          break
        case LOADING_ANIMATION_TYPE.POPOVER:
          setRefreshPopupVisible(true)
          break
        default:
         setSigningStatusCheckOngoing(false)
          throw new Error('Unhandled LOADING_ANIMATION_TYPE')
      }
    }

    const successCallback = (signingStatus: SigningStatus) => {
      if (signingStatus.status === ANSOKAN_SIGNING_STATUS_TIMED_OUT) {
       handleTimedOutSigning()
      }
      if (signingStatus.status === ANSOKAN_SIGNING_STATUS_SUCCEEDED || signingStatus.status === ANSOKAN_SIGNING_STATUS_NOT_STARTED) {
       loadContacts()
      }
      setSigningStatus(signingStatus)
      if (loadingAnimationType !== LOADING_ANIMATION_TYPE.POPOVER) {
        dispatch(endProgress())
      } else {
       setRefreshPopupVisible(false)
      }
    }

    if(!signingStatusCheckOngoing){
      setSigningStatusCheckOngoing(true)
      loadingAnimationStart()
      return dispatch(fetchSigningStatus(props.ansokanId))
            .then((signingStatus: SigningStatus) => {
              successCallback(signingStatus)
              setSigningStatusCheckOngoing(false)
            }).catch(() => {
          showSigningNotFoundModalMethod()
          setSigningStatusCheckOngoing(false)
      })
    }
  }

  const showTimedOutSigningModalMethod = (show = true) => {
    setShowTimedOutSigningModal(show)
  }

  const showSigningNotFoundModalMethod = (show = true) => {
    setShowSigningNotFoundModal(show)
  }


  return (
      <>
        <JumpLinkToMain/>
        <Helmet>
          <title>Underskrifter av lantmäteriförrättning</title>
        </Helmet>
        <header>
          <Navigation showHelp={false}/>
        </header>
        <main id="signeringMainContentId">
          <div className="container">
            <h1>Skriv under och skicka in ansökan</h1>
              {(ansokanStatus === ANSOKAN_STATUS_LOCKED && (signingStatus?.status === ANSOKAN_SIGNING_STATUS_NOT_STARTED || signingStatus?.status === undefined)) && <>
                  {/* toggle för signering i leif-web eller signering-web */}
                  {isSigningInLeif ?
                      <SigningHederOchSamveteV2 ansokanId={props.ansokanId}/>
                      :
                      <SigningHederOchSamvete ansokanId={props.ansokanId}/>
                  }
              </>}

              {(ansokanStatus === ANSOKAN_STATUS_REVIEW_EMAILS && (signingStatus?.status === ANSOKAN_SIGNING_STATUS_NOT_STARTED || signingStatus?.status === undefined)) &&
              <InviteToSign ansokanId={props.ansokanId}
                            onFormSubmit={() => {
                              checkStatus(LOADING_ANIMATION_TYPE.FULL_SCREEN_FADE_CONTENT)
                              loadContacts()
                            }}
                            contactList={contactList}/>
            }
            {(signingStatus?.status === ANSOKAN_SIGNING_STATUS_PENDING) &&
              <SigningInProgress ansokanId={props.ansokanId}
                                 signingStatus={signingStatus}
                                 onRefresh={() => checkStatus(LOADING_ANIMATION_TYPE.POPOVER)}
                                 upprattare={upprattare ? upprattare : {}} contactList={contactList}/>
            }
            {(signingStatus?.status === ANSOKAN_SIGNING_STATUS_SUCCEEDED) &&
              <SigningFinished ansokanId={props.ansokanId} navigate={navigate}
                               signingStatus={signingStatus}
                               onFormSubmit={() => checkStatus(LOADING_ANIMATION_TYPE.FULL_SCREEN_FADE_CONTENT)}
                               contactList={contactList}/>
            }
          </div>
          <ActionBar saveAnsokanOnClose={false}/>
        </main>
        {refreshPopupVisible &&
          <aside aria-label={'Hämtar signeringsstatus'} className="message-panel in-progress"><SmallLoading
            text={'Hämtar signeringsstatus...'}/></aside>}
        {showTimedOutSigningModal &&
          <TimedOutSigningModal closeModalCallback={() => setShowTimedOutSigningModal(false)}
                                onClickOkKnapp={() => dispatch(abortSigningInstance(props.ansokanId))
                                  .then(() => {
                                    dispatch(endProgress())
                                    checkStatus(LOADING_ANIMATION_TYPE.FULL_SCREEN_HIDE_CONTENT)
                                  }).catch(() => {
                                      dispatch(endProgress())
                                  })}/>}
        {showSigningNotFoundModal &&
          <SigningNotFoundModal closeModalCallback={() => navigate('/')}/>}
        {hjalpPanelVisible &&
            <Hjalp>
              <SidePanelChapter name={'help-support'}>
                <KontaktaOssHelpContent/>
              </SidePanelChapter>
              <SidePanelChapter name={'help-personuppgift'}>
                <PersonuppgifterHelpContent/>
              </SidePanelChapter>
            </Hjalp>
        }
    </>
    )
  }

export function SigningNotFoundModal (props: {closeModalCallback: () => void}) {
  return <ModalDialogSub modalTitle={'Signeringsärendet kan inte hittas'}
                         description={'Signeringsärendet kan inte hittas, detta kan bero på att 30 dagar har gått sedan det initierades. ' +
                           'Vänligen kontakta kundservice.'}
                         closeModalCallback={props.closeModalCallback}/>
}

export function TimedOutSigningModal (props: {closeModalCallback: () => void, onClickOkKnapp: () => void}) {
  return <ModalDialogSub modalTitle={'Ditt ärende har avbrutits'}
                         description="Ditt ärende har varit aktivt för länge utan att slutföras och har därför avbrutits, vänligen gör ett nytt försök."
                         closeModalCallback={props.closeModalCallback}
                         acceptButton={{
                           onClick: props.onClickOkKnapp
                         }}/>
}
type StepVisualisationFishProps = {
  activeStep: number
}
export function StepVisualisationFish (props: StepVisualisationFishProps) {

  return <>
    <ul className="step-visualization full-width" data-selenium-id="signingStepVisualizationList">
      <li className={props.activeStep === 1 ? 'active' : ''} key="step-1" data-selenium-id="signingStepBestyrkHandlingar">Bestyrk handlingar</li>
      <li className={props.activeStep === 2 ? 'active' : ''} key="step-2" data-selenium-id="signingStepGranskaEpostadresser">Granska e-postadresser</li>
      <li className={props.activeStep === 3 ? 'active' : ''} key="step-3" data-selenium-id="signingStepUnderskrifter">Underskrifter</li>
      <li className={props.activeStep === 4 ? 'active' : ''} key="step-4" data-selenium-id="signingStepSkickaIn">Skicka in ansökan</li>
    </ul>
  </>
}
export default (Signing);