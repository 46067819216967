import React, {useState} from 'react'
import {ButtonTop, ModalDialogAlert, WizardDialog, WizardDialogStep, WizardIntorduction} from 'redet-react-components'
import {ValjFilerWizardStep} from '../../../views/BifogadeHandlingar/kompletteringWizard/ValjFilerWizardStep'
import {YrkandeWizardStepIntroFragor} from './yrkandeWizardStep/YrkandeWizardStepIntroFragor'
import {YrkandeWizardStepSkriv} from './yrkandeWizardStep/YrkandeWizardStepSkriv'
import {YrkandeWizardStepMedyrkande} from './yrkandeWizardStep/YrkandeWizardStepMedyrkande'
import {YrkandeWizardStepSignering} from './yrkandeWizardStep/YrkandeWizardStepSignering'
import {YrkandeWizardStepSigneringMedyrkare} from './yrkandeWizardStep/YrkandeWizardStepSigneringMedyrkare'
import {YrkandeWizardStepEmail} from './yrkandeWizardStep/YrkandeWizardStepEmail'

import {connect} from 'react-redux'
import {abortSigningKomplettering, uploadKompletteringYrkande} from '../../../redux/modules/kompletteringBifogaHandling'
import {fetchSigningStatusKomplettering} from '../../../redux/modules/arendeKomplettering'
import {submitKomplettering} from '../../../redux/modules/arendeKompletteringar'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {formatToDateAndTime} from '../../../helpers/dates'
import {removeIllegalUnicodeChars} from 'utils/stringUtil';

const mapDispatchToProps = {
    uploadKompletteringYrkande,
    fetchSigningStatusKomplettering,
    submitKomplettering,
    abortSigningKomplettering
}

const queryClient = new QueryClient()

export function YrkandeWizard({
    closeWizardFunction,
    uploadKompletteringYrkande,
    fetchSigningStatusKomplettering,
    submitKomplettering,
    abortSigningKomplettering,
    refetchKompletteringarList,
    arendenummer,
    ...rest
}) {
    const [choices, setChoices] = useState({...rest})
    const [yrkandeText, setYrkandeText] = useState(rest.yrkandetext)
    const [bifogadeKartor, setBifogadeKartor] = useState(rest.bifogadeKartor)
    const [medyrkare, setMedyrkare] = useState(rest.medyrkare)
    const [notifieringemail, setNotifieringemail] = useState(rest.notifieringemail)
    const [inskickadDatum, setInskickadDatum] = useState(rest.inskickadDatum)
    const [isInskickad, setIsInskickad] = useState(false)
    const [errorMessage, setErrorMessage] = useState(undefined)

    function createKompletteringAndStartSigning(onProgressCallback, abortFunction) {
        return uploadKompletteringYrkande({
            choices: choices,
            arendenummer: arendenummer,
            yrkandeText: yrkandeText,
            bifogadeKartor: bifogadeKartor,
            notifieringemail: notifieringemail,
            medyrkare: medyrkare
        }, onProgressCallback, abortFunction)
    }

    let skrivYrkandeNextButtonText = 'Ange e-post'
    if (choices?.isUploadingAkartskiss) {
        skrivYrkandeNextButtonText = 'Ladda upp kartskiss'
    }
    else if (choices?.isMultiplePeopleYrkare) {
        skrivYrkandeNextButtonText = 'Ange medyrkare'
    }

    let signeringPrevButtonText = 'Skriv yrkande'
    if (choices?.isMultiplePeopleYrkare) {
        signeringPrevButtonText = 'Ändra medyrkare'
    }
    else if (choices?.isUploadingAkartskiss) {
        signeringPrevButtonText = 'Ändra kartskiss(er)'
    }

    function onAbortSigning(kompletteringsId) {
        return new Promise((resolve, reject) => {
            abortSigningKomplettering(kompletteringsId)
                .then(result => resolve(result))
                .catch(error => {
                    setErrorMessage({
                        title: 'Fel vid avbrytande av signering',
                        message:
                            <span>Ett fel inträffade när signeringen skulle avbrytas.
                                Försök igen senare genom att klicka på <strong>Skicka in yrkande</strong> och börja om.</span>,
                        button: {
                            text: 'OK', icon: 'check', onClick: () => {
                                closeWizardFunction(isInskickad)
                                refetchKompletteringarList()
                                setErrorMessage(undefined)
                            }
                        }
                    })
                    reject(error)
                })
                .finally(() => refetchKompletteringarList())
        })
    }

    /*
    Man skulle nog kunna skriva om denna wizard med ett gemensamt Context istället för att skicka ner setter-funktioner överallt.
     */
    return (
        <QueryClientProvider client={queryClient}>
            <WizardDialog title={'Skicka in yrkande'}
                cancelFunction={() => closeWizardFunction(isInskickad)}
                hasDefaultNav={false}
                elementsToAllowFocus={['helpSidePanelId']}
                {...rest}>
                <WizardDialogStep isDisplayingSteps={true} stepTitle={'Information'}>
                    <YrkandeWizardStepIntroFragor choices={choices} setChoices={setChoices} />
                </WizardDialogStep>
                <WizardDialogStep stepTitle={'Yrkande'}>
                    <YrkandeWizardStepSkriv setYrkandeText={(text) => setYrkandeText(removeIllegalUnicodeChars(text))}
                        initText={yrkandeText}
                        nextButtonText={skrivYrkandeNextButtonText} />
                </WizardDialogStep>
                {choices?.isUploadingAkartskiss &&
                    <WizardDialogStep stepTitle={'Kartskiss'}>
                        <ValjFilerWizardStep setFilesCallback={setBifogadeKartor}
                            files={bifogadeKartor}
                            nextButton={{text: choices?.isMultiplePeopleYrkare ? 'Ange medyrkare' : 'Ange e-post'}}
                            prevButton={{text: 'Skriv yrkande'}} />
                    </WizardDialogStep>}
                {choices?.isMultiplePeopleYrkare &&
                    <WizardDialogStep stepTitle={'Vem yrkar'}>
                        <YrkandeWizardStepMedyrkande setMedyrkande={setMedyrkare} initMedyrkandeList={medyrkare}
                            setNotifieringsemail={setNotifieringemail}
                            initNotifieringsemail={notifieringemail}
                            prevButtonText={choices?.isUploadingAkartskiss ? 'Ändra kartskiss(er)' : 'Skriv yrkande'} />
                    </WizardDialogStep>}
                {!choices?.isMultiplePeopleYrkare &&
                    <WizardDialogStep stepTitle={'Vem yrkar'}>
                        <YrkandeWizardStepEmail setNotifieringsemail={setNotifieringemail}
                            initNotifieringsemail={notifieringemail}
                            prevButtonText={choices?.isUploadingAkartskiss ? 'Ändra kartskiss(er)' : 'Skriv yrkande'}
                            nextButtonText={{text: 'Underskrift'}} />
                    </WizardDialogStep>}
                {!choices?.isMultiplePeopleYrkare &&
                    <WizardDialogStep stepTitle={'Underskrift'}>
                        <YrkandeWizardStepSignering setInskickadDatum={setInskickadDatum}
                            createKompletteringAndStartSigning={createKompletteringAndStartSigning}
                            fetchSigningStatusKomplettering={fetchSigningStatusKomplettering}
                            refetchKompletteringarList={refetchKompletteringarList}
                            prevButtonText={signeringPrevButtonText}
                            nextButtonText={{text: 'Underskrift'}}
                            abortKompletteringSigning={onAbortSigning}
                            submitKomplettering={submitKomplettering} />
                    </WizardDialogStep>}
                {choices?.isMultiplePeopleYrkare &&
                    <WizardDialogStep stepTitle={'Underskrift'}>
                        <YrkandeWizardStepSigneringMedyrkare medyrkare={medyrkare}
                            fetchSigningStatusKomplettering={fetchSigningStatusKomplettering}
                            createKompletteringAndStartSigning={createKompletteringAndStartSigning}
                            abortKompletteringSigning={onAbortSigning} onSubmitted={() => setIsInskickad(true)}
                            prevButtonText={signeringPrevButtonText} />
                    </WizardDialogStep>}
                {!choices?.isMultiplePeopleYrkare &&
                    <WizardDialogStep stepTitle={'Inskickad'}>
                        <YrkandeWizardStepInskickad inskickadDatum={inskickadDatum} closeWizardFunction={() => closeWizardFunction(isInskickad)}
                            refetchKompletteringarList={refetchKompletteringarList} onSubmitted={() => setIsInskickad(true)} />
                    </WizardDialogStep>}
                {choices?.isMultiplePeopleYrkare &&
                    <WizardDialogStep stepTitle={'Skrivs under'}>
                        <YrkandeWizardVantarPaMedyrkanden closeWizardFunction={() => closeWizardFunction(isInskickad)}
                            refetchKompletteringarList={refetchKompletteringarList} />
                    </WizardDialogStep>}
            </WizardDialog>

            {errorMessage &&
                <ModalDialogAlert modalTitle={errorMessage.title}
                                  description={errorMessage.message}
                                  acceptButton={errorMessage.button}/>}
        </QueryClientProvider>
    )
}

export default connect(undefined, mapDispatchToProps)(YrkandeWizard)

export function YrkandeWizardStepInskickad({inskickadDatum, closeWizardFunction, refetchKompletteringarList, onSubmitted}) {
    function onCloseButtonClick() {
        refetchKompletteringarList()
        closeWizardFunction()
    }

    function addInskickadDatumToInfo(value) {
        onSubmitted();
        const formattedDateString = formatToDateAndTime(value)
        return (formattedDateString !== '') ? ' och har fått datum ' + formattedDateString : ''
    }

    return <>
        <WizardIntorduction ingress={`Ditt yrkande är nu inskickat${addInskickadDatumToInfo(inskickadDatum)}.`} />
        <ButtonTop text={'OK'} icon={'check'} onClick={onCloseButtonClick} id="nextButtonId" />
    </>
}

export function YrkandeWizardVantarPaMedyrkanden({closeWizardFunction, refetchKompletteringarList}) {
    function onCloseButtonClick() {
        refetchKompletteringarList()
        closeWizardFunction()
    }

    return <>
        <WizardIntorduction ingress={'Du har nu skrivit under yrkandet.'}
            description={'När det är signerat av alla inbjudna kommer yrkandet automatiskt att skickas in och kvittensnummer kommer finnas i listan med slutförda aktiviter. Du kan nu stänga denna ruta.'} />
        <ButtonTop text={'OK'} icon={'check'} onClick={onCloseButtonClick} id="nextButtonId" />
    </>

}
