import {Fastighet} from "domain/Ansokan";
import {useAppDispatch} from "hooks";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {
    // @ts-ignore
    ButtonSub, Checkbox, ExternalLink, ModalDialogSub, PageSubContent, PageTopContent, Row, VarningsRuta,
    ButtonGroup, ButtonToolbar, ModalDialogAlert,
} from 'redet-react-components';
import {addProgress, endProgress} from "redux/modules/fullscreenLoading";
import {initiateSDGSigningInstance, submitSDGInstance, withdrawSDGSigningInstance} from "redux/modules/sdg";
import {SDG_SIGNING_STATUS} from "repository/sgdRepository";
import Log from '../../utils/log';
import {AnsokanWizardSDGContext} from "./AnsokanWizardSDGContext";
import MapAnsokanAndHandlingar from "./MapAnsokanAndHandlingar";
import {usePollSigningStatus} from "./hooks/SigningStatusHook";
import {scrollToMainTag} from "./util";

const log = new Log('FeatureToggleMapper')

export default function CheckAndSubmit() {

    const [signingCheckBoxChecked, setSigningCheckBoxCheckedled] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [showSigningStatusLoader, setShowSigningStatusLoader] = useState(false);
    const [errorModalIngress, setErrorModalIngress] = useState('');
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [ansokanId, setAnsokanId] = useState('');
    const [signingInstanceUrl, setSigningInstanceUrl] = useState('');
    const {t, i18n} = useTranslation();
    const {fastigheter, handlingar, ansokan, setResult, clearAnsokan} = useContext(AnsokanWizardSDGContext);
    const [signStatusError, signStatus, disableSigningStatusPoll] = usePollSigningStatus(ansokanId);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (signStatusError) {
            disableSigningPoll();
            showErrorModal(t("submit.errorModal.signingStatusPollError"));
        }
        if (signStatus?.status === SDG_SIGNING_STATUS.TIMED_OUT) {
            disableSigningPoll();
            setSigningInstanceUrl("");
            setInfoModalVisible(true);
        } else if (signStatus?.status === SDG_SIGNING_STATUS.SUCCEEDED && ansokanId) {
            disableSigningPoll();
            submitSDG();
        }
    }, [signStatusError, signStatus])

    useEffect(() => {
        scrollToMainTag()
    }, []);

    const openSigningInNewTab = () => {
        window.open(signingInstanceUrl, '_blank')?.focus();
        setShowSigningStatusLoader(true);
    };

    const submitSDG = () => {
        dispatch(addProgress(false, t("submit.submitApplication")))
        dispatch(submitSDGInstance(ansokanId)).then(response => {
            setResult({
                kvittens: response.kvittensId,
                mottagandemyndighet: response.mottagandemyndighet,
                fastighetsbeteckningar: fastigheter?.map(fastighet => fastighet.registerbeteckning) ?? []
            })
            clearAnsokan();
            navigate("/euCitizenApplication/kvittens");
        }).catch(async error => {
            showErrorModal(t("submit.errorModal.submitApplicationError"), await getErrorMessage(error));
            setSigningInstanceUrl("");
            disableSigningPoll();
        }).finally(() => dispatch(endProgress()))
    }

    const withdrawSigning = () => {
        disableSigningPoll();
        dispatch(addProgress(false, t("submit.withdrawingSigning")))
        return dispatch(withdrawSDGSigningInstance(ansokanId))
            .then(() => {
                setAnsokanId("")
            })
            .catch(async error => {
                showErrorModal(t("submit.errorModal.withdrawingSigningError"), await getErrorMessage(error))
            })
            .finally(() => dispatch(endProgress()))
    }

    const initiateSigning = () => {
        if (!!ansokan && fastigheter.length > 0) {
            dispatch(addProgress(false, t("submit.loaderInitiateSigning")))
            dispatch(initiateSDGSigningInstance(fastigheter, handlingar, ansokan))
                .then((initiateSigningResponse) => {
                    setAnsokanId(initiateSigningResponse.ansokanId.value);
                    setSigningInstanceUrl(addLanguageToSigningUrl(initiateSigningResponse.signingWebUrl));
                })
                .catch(async error => {
                    showErrorModal(t("submit.errorModal.initiateSigningError"), await getErrorMessage(error))
                })
                .finally(() => dispatch(endProgress()))
        } else {
            log.error("En användare har lyckats trigga submit av SDG-ansökan utan ansökan eller fastighet tillagd");
        }
    }

    const disableSigningPoll = () => {
        disableSigningStatusPoll();
        setShowSigningStatusLoader(false);
    }

    const showErrorModal = (errorModalIngress: string, errorModalMessage: string = '') => {
        setErrorModalIngress(errorModalIngress);
        setErrorModalMessage(errorModalMessage)
        setErrorModalVisible(true)
    }

    const hideErrorModal = () => {
        setErrorModalVisible(false);
        setErrorModalIngress('');
    }

    const addLanguageToSigningUrl = (url: string) => {
        return i18n.language !== "sv" ? url + "?lang=en" : url;
    }

    const getErrorMessage = async (error: any) : Promise<string|undefined> => {
        try {
            //try parse json
            const errorJson = error?.json ? await error.json() : undefined
            //create translation key
            const type = `server.error.${errorJson?.key}`
            return t(type, errorJson?.detail ?? '', errorJson?.params ?? {}) as string
        } catch (error: any) {
            //oväntat fel, inget meddelande
            return undefined
        }
    }

    return (
        <>
            <PageTopContent headerLevel={2} title={t("submit.title")} headerId={t("submit.titleHeaderId")}/>
            <br/>
            <PageSubContent title={t("submit.step1")} headerLevel={3} headerId={"Steg1HeaderId"}>
                <h4>{t("submit.propertiesAdded")}</h4>
                {fastigheter.map((fastighet: Fastighet, index: number) => (
                    <p key={index} id={index.toString()}>
                        {fastighet.registerbeteckning}
                    </p>
                ))}
                <h4>{t("submit.applicationAndDocuments")}</h4>
                <Row>
                    {ansokan &&
                        <>
                            <MapAnsokanAndHandlingar
                                items={[ansokan]}
                                desc={t('submit.applicationText')}
                            />
                            <br/>
                        </>
                    }
                    <MapAnsokanAndHandlingar
                        items={handlingar}
                        desc={t('submit.documentText')}
                    />
                </Row>
            </PageSubContent>
            <PageSubContent title={t("submit.step2")} headerLevel={3} headerId={"Steg2HeaderId"}>
                <p>{t("submit.correspondToOriginals")}</p>
                <Checkbox
                    id="confirm"
                    label={t("submit.phos")}
                    checked={signingCheckBoxChecked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSigningCheckBoxCheckedled(event.target.checked)}
                    ariaLabel={t("submit.phosCheckbox")}
                />
                <div>
                    <p>
                        {t("submit.signApplication")}
                        <strong>{t("submit.initiateSigningButton")}</strong>.
                        <br/>
                        {t("submit.submitButtonText")}<strong>{t("submit.submit")}</strong>,
                        {t("submit.newTab")}
                    </p>
                    {!signingInstanceUrl &&
                        <ButtonToolbar>
                            <ButtonGroup>
                                <ButtonSub
                                    className="button-top wizard-step"
                                    text={t("submit.initiateSigningButton")}
                                    isDisabled={!signingCheckBoxChecked}
                                    onClick={() => {
                                        if (ansokanId) {
                                            withdrawSigning().then(() => initiateSigning())
                                        } else {
                                            initiateSigning()
                                        }
                                    }}
                                />
                            </ButtonGroup>
                        </ButtonToolbar>
                    }
                </div>
            </PageSubContent>
            <VarningsRuta ariaTitle={t("submit.costInfo_aria")}>
                <p className="mandatory">
                    {t("submit.costInfo")}
                    {t("submit.moreInfo")}
                    <ExternalLink
                        url="https://www.lantmateriet.se/sdg-cadastral-procedure"
                        text={t("submit.hrefText")}
                    >
                    </ExternalLink>
                    {t("submit.and")}
                    <ExternalLink
                        url="https://www.lantmateriet.se/en/single-digital-gateway--residence-in-sweden/applying-for-a-cadastral-procedure/fees-and-examples-of-costs/"
                        text={t("submit.costLink")}
                    >
                    </ExternalLink>
                </p>
                <p className="mandatory">
                    {t("submit.signaturePersonalDataInfo.text")}
                    <ExternalLink
                        url="https://www.lantmateriet.se/sdg-personal-data"
                        text={t("submit.signaturePersonalDataInfo.hrefText")}
                    >
                    </ExternalLink>
                </p>
            </VarningsRuta>
            {showSigningStatusLoader &&
                <div className="message-panel in-progress">
                    <div className="circle-animation">
                        <span className="block"></span>
                        <span className="block"></span>
                        <span className="block"></span>
                        <span className="block"></span>
                        <span className="block"></span>
                        <span className="block"></span>
                        <span className="block"></span>
                        <span className="block"></span>
                    </div>
                    <p>{t("submit.signingprocessStarted")}<br/><strong>{t("submit.CheckingStatus")}</strong></p>
                </div>
            }
            <div className="toolbar mobile-toolbar">
                <ButtonGroup>
                    <Link className="button-top wizard-step" to={"/euCitizenApplication/realEstates"}>
                        <i className="material-icons">{'arrow_back'}</i>
                        {t("submit.backBtn")}
                    </Link>
                </ButtonGroup>
                {signingInstanceUrl &&
                    <ButtonGroup>
                        <ButtonSub
                            className={"button-top wizard-step" + (signingInstanceUrl ? " active" : "")}
                            text={t("submit.submit")}
                            icon={"launch"}
                            isDisabled={!signingCheckBoxChecked || !signingInstanceUrl}
                            onClick={() => openSigningInNewTab()}
                        />
                    </ButtonGroup>
                }
            </div>
            {errorModalVisible &&
                <ModalDialogAlert
                    modalTitle={t("submit.errorModal.title")}
                    ingress={errorModalIngress}
                    acceptButton={{
                        text: t("submit.errorModal.acceptButton"), icon: 'check', onClick: () => hideErrorModal()
                    }}>
                    {errorModalMessage && <p className="mandatory">{errorModalMessage}</p>}
                    <p>
                        {t("submit.errorModal.description")}<a
                        href="https://www.lantmateriet.se/sdg-contact" target="_blank" rel="external noopener noreferrer">
                        {t("submit.errorModal.customerSupport")}</a>.
                    </p>
                </ModalDialogAlert>
            }
            {infoModalVisible &&
                <ModalDialogSub modalTitle={t("submit.infoModal.title")}
                                description={t("submit.infoModal.timeout")}
                                closeModalCallback={() => setInfoModalVisible(false)}
                                acceptButton={{text: t("submit.infoModal.acceptButton")}}/>
            }
        </>
    );
}

