import {ListItemStatus} from "domain/ListItemStatus";
import React, {ReactNode} from "react";
import {string} from "prop-types";

export interface ListItemFlexGroupProps {
    title?: string
    status?: ListItemStatus
    description?: string | ReactNode,
    children?: ReactNode
}

export function ListItemFlexGroup({title, status, description, children, ...rest}: ListItemFlexGroupProps) {

    const _description = description instanceof string ? <p>{description}</p> : <>{description}</>

    return (<div className={'flex-group'} {...rest}>
        {/*title*/}
        {title && <h4>
            {title}
        </h4>}
        {/*status*/}
        {status && <p>
            {status.icon && <span className={'material-icons'}>{status.icon}</span>}
            {status.text && <>{status.text}</>}
        </p>}
        {/*description*/}
        {description && <>
            {_description}
        </>}
        {children}
    </div>)
}