import showLoader from 'components/General/HOC/showLoader'
import {useAppDispatch, useAppSelector} from 'hooks'
import {useEffect} from 'react'
import {useCookies} from "react-cookie"
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    useLoaderData,
    useLocation
} from 'react-router-dom'
import {AuthState, storeSessionCookie} from 'redux/modules/auth'
import {
    DEV_FEATURES_ENABLED,
    FRISSE_SERVICE_MOCK_ENABLED,
    KOMPLETTERING_SERVICE_MOCK_ENABLED,
    SIGNING_SERVICE_MOCK_ENABLED
} from 'redux/modules/featureToggle'
import {createTermsOfUsageHash} from 'utils/authentication'
import {useCookieFeatureToggleHelper} from 'utils/featureToggleHelperHook'
import {NotFound} from './pages/NotFound'
import SessionTimeout from './pages/SessionTimeout'
import {PHOS_MOCK_SIGNING_ENABLED} from './redux/modules/featureToggle'
import TermsOfUsageSDG from 'pages/TermsOfUsageSDG'
import {setUserLoggedIn} from './redux/modules/auth'
import Start from "./pages/Start";
import StartInloggningPrivatperson from "./pages/StartInloggningPrivatperson";
import StartInloggningOrganisation from "./pages/StartInloggningOrganisation";
import StartInloggningSDG from "./pages/StartInloggningSDG";
import {TermsOfUsage} from "./pages/TermsOfUsage";
import LogoutUsername from "./pages/LogoutUsername";
import {User} from "./domain/User";
import LocalLogin from "./pages/LocalLogin";
import i18next from "i18next";
import {Ansokan} from "./pages/Ansokan";
import Arende from "./pages/Arende";
import Welcome from "./pages/welcome/Welcome";
import Kvittens from "./pages/Kvittens";
import WelcomeOrganization from "./pages/welcome/WelcomeOrganization";
import ContextProviderWrapperSDG from "./pages/ContextProviderWrapperSDG";
import type {AppDispatch} from "./store";
import {Cookie} from "universal-cookie";
import Enak from "./pages/EnakRootComponent";
import {Redirect} from "./helpers/Redirect";
import {SignKompletteringPreviewPage} from "./pages/sign/SignKompletteringPreviewPage";

export const TERMS_OF_USAGE_COOKIE_NAME = 'enak_accepted_terms_of_usage'
export const MRHSESSION_COOKIE_NAME = "MRHSession"

const AnsokanComponent = showLoader(Ansokan)
const ArendeComponent = showLoader(Arende)
const WelcomeComponent = showLoader(Welcome)

/**
 * Funktion som skapar Route till en komponent, eller redirectar användaren ifall den redan är inloggad.
 *
 * @param props , redirectTo relativ url som användaren ska redirectas till om den redan är inloggad.
 */
function RedirectIfInloggadRoute( props : {redirectTo: string}) {
    const auth = useAppSelector((store) => store.auth);
    if (auth?.user) {
        return <Navigate to={props.redirectTo}/>
    }
    return <Outlet/>
}

/*
 * Funktion som returnerar en route baserat på om användaren är inloggad, privatperson, inloggad med användarnamn i prod och huruvida denne har accepterat TOC
 * Tar state auth-object och komponent som parametrar, komponenten som skickas med är den som renderas om samtliga villkor stämmer
 */
/*
 * Funktion som retunerar en route baserat på om användaren är inloggad, organisation och huruvida denne har accepterat TOC
 * Tar state auth-object och komponent som parametrar, komponenten som skickas med är den som renderas om samtliga villkor stämmer
 */

async function checkLogin(auth: AuthState, dispatch: any, cookies: { [p: string]: any }): Promise<User | undefined> {
    if (auth.user !== undefined)
        return auth.user
    return await fetch('/loggedin', {cache: 'no-store'})
        .then(res => {
            return res.json()
        })
        .then(json => {
            dispatch(setUserLoggedIn(json))
            storeCurrentSessionIdExistsInStore(auth, dispatch, cookies)
            return json
        }).catch(error => {
            console.error("Kunde inte hämta inloggning", error)
            return undefined
        })
}


    async function checkUserIsLoggedIn(auth: AuthState, dispatch: AppDispatch, cookies: Cookie): Promise<User | null> {
        if (!auth.user) {
            return await checkLogin(auth, dispatch, cookies)
                .then(user => user ?? null)
                .catch(() => null)
        }
        return auth.user;
    }

function RequireAuthPrivatperson() {
    let location = useLocation();
    const [cookies] = useCookies()
    const user = useLoaderData()  as User | undefined
    if (!user) return <Navigate to="/start/privat" state={{from: location}} replace/>
    if (!user.giltigtPersonnummer && (user.environment === 'prd' || cookies.setRunEnvToPrd))
        return <Navigate to={'/loggautanvandarnamn'} replace/>
    if (user.isOrganization) return <Navigate to="/org" replace/>
    if (user.isSdg) return <Navigate to="/start/sdg" replace/>
    i18next.changeLanguage('sv')
    return <Outlet/>;
}

function RequireAuthOrg() {
    let location = useLocation();
    const user = useLoaderData() as User | undefined
    if (!user) return <Navigate to="/start/org" state={{from: location}} replace/>;
    if (user.isSdg) return <Navigate to="/start/sdg" replace/>
    if (!user.isOrganization) return <Navigate to="/privat" replace/>
    i18next.changeLanguage('sv')
    return <Outlet/>;
}

function RequireAuthSdg() {
    let location = useLocation();
    // @ts-ignore
    const user = useLoaderData()  as User | undefined
    if (!user) return <Navigate to="/start/sdg" state={{from: location}} replace/>;
    if (user.isOrganization) return <Navigate to="/org" replace/>
    if (!user.isSdg) return <Navigate to="/privat" replace/>
    return <Outlet/>;
}

function RequireTermsOfUsageIsAccepted(props: {redirectIfNot: string}) {
    let location = useLocation();
    const auth = useAppSelector((store) => store.auth);
    const [cookies] = useCookies()
    const userHasAgreedToTerms = cookies[TERMS_OF_USAGE_COOKIE_NAME] && auth.user && createTermsOfUsageHash(auth.user) === cookies[TERMS_OF_USAGE_COOKIE_NAME];
    if (!userHasAgreedToTerms) return <Navigate to={props.redirectIfNot} state={{from: location}} replace/>
    return <Outlet/>;
}

const storeCurrentSessionIdExistsInStore = (auth: AuthState, dispatch: any, cookies: { [p: string]: any }) => {
    //Vi sparar currentSessionId i storen för att det ska vara tillgängligt för fetcher
    //som körs på flertalet ställen i våra komponenter
    if (!auth?.user?.currentSessionId) {
        dispatch(storeSessionCookie(cookies[MRHSESSION_COOKIE_NAME]))
    }
}

function ApplyFeatureToggleAndRedirectToStart(props: { featureToggle?: string }) {
    // @ts-ignore
    const user: User | undefined = useLoaderData()
    const {enableFeatureToggle, clearFeatureToggles} = useCookieFeatureToggleHelper();
    useEffect(() => {
        if (props.featureToggle === undefined) {
            clearFeatureToggles()
        }

        if (user && props.featureToggle !== undefined) {
            enableFeatureToggle(props.featureToggle)
        }
    }, [user]);

    if (user?.isSdg) return <Navigate to={"/start/sdg"}/>
    if (user?.isOrganization) return <Navigate to={"/org"}/>
    return <Navigate to={"/privat"}/>
}

export function routesFromElements(auth: AuthState, dispatch: AppDispatch, cookies: Cookie) {
    return createRoutesFromElements(
        <Route element={<Enak/>}>
            {/* ----  PRIVATPERSON ----- */}
            <Route loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)}
                   element={<RequireAuthPrivatperson/>}>
                <Route
                    element={<RequireTermsOfUsageIsAccepted redirectIfNot={"/anvandarvillkor"}/>}>
                    <Route path={"privat"} element={<WelcomeComponent/>}/>
                    <Route path={`privat/ansokan/:id/*`} element={<AnsokanComponent/>}/>
                    <Route path={`privat/arende/:id`} element={<ArendeComponent asHandlaggare={false}/>}/>
                    <Route path={`privat/arende/:id/komplettering/:kompletteringId/preview`} element={<SignKompletteringPreviewPage/>}/>
                    <Route path={`privat/arende/:id/komplettering/:kompletteringId/preview/signresponse`} element={<SignKompletteringPreviewPage signResponse={true}/>}/>
                    <Route path={`privat/arende/:id/*`} element={<Redirect to={`/privat/arende/:id`} />}/>
                </Route>
            </Route>
            <Route element={<RedirectIfInloggadRoute redirectTo={'privat'}/>}>
                <Route path="/start/privat" element={<StartInloggningPrivatperson/>}/>
            </Route>

            {/* ----  ORGANISATION ----- */}
            <Route loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)} element={
                <RequireAuthOrg/>}>
                <Route element={<RequireTermsOfUsageIsAccepted redirectIfNot={"/anvandarvillkor"}/>}>
                    <Route path={"org"} element={<WelcomeOrganization/>}/>
                    <Route path={`org/arende/:id`} element={<ArendeComponent asHandlaggare={false}/>}/>
                    <Route path={`org/arende/:id/*`} element={<Redirect to={`/org/arende/:id`}/>}/>
                    <Route path={`org/fromtrossen/arende/:id`} element={<ArendeComponent asHandlaggare={true}/>}/>
                    <Route path={`org/fromtrossen/arende/:id/*`} element={<Redirect to={`/org/fromtrossen/arende/:id`}/>}/>
                </Route>
            </Route>
            <Route element={<RedirectIfInloggadRoute redirectTo={'org'}/>}>
                <Route path="start/org" element={<StartInloggningOrganisation/>}/>
            </Route>
            <Route path="/fromtrossen/arende/:id" element={<Redirect to={`/org/fromtrossen/arende/:id`}/>}/>
            {/* Denna redirect behövs för bakåtkompatibilitet med leif-web, kan tas bort när vi ändrat inloggningsurl i lastbalanseraren*/}
            <Route path="/orglogin" element={<Navigate to={`/org`} replace={true}/>}/>

            {/* ----  PRIVAT + ORG ----- */}
            <Route path="/anvandarvillkor" element={<TermsOfUsage/>}/>


            {/* ----  EU-MEDLEM ----- */}
            <Route loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)} element={
                <RequireAuthSdg/>}>
                <Route element={<RequireTermsOfUsageIsAccepted redirectIfNot={"/anvandarvillkorSDG"}/>}>
                    <Route path={"euCitizenApplication/*"} element={<ContextProviderWrapperSDG/>}/>
                </Route>
            </Route>
            <Route path="/anvandarvillkorSDG" element={<TermsOfUsageSDG/>}/>
            <Route element={<RedirectIfInloggadRoute redirectTo={'euCitizenApplication'}/>}>
                <Route path="/start/sdg" element={<StartInloggningSDG/>}/>
                <Route path="/sdg" element={<Navigate to="/start/sdg" replace />}
    />
            </Route>

            {/* ----  START ----- */}
            <Route path={"/"} element={<Start/>}/>
            <Route path={"*"} element={<NotFound/>}/>
            <Route path={"/start"} element={<Navigate to={`/`}/>}/>

            {/* ----  GEMENSAMMA ----- */}
            <Route path={'/kvittens'} element={<Kvittens/>}/>
            <Route path="/loggautanvandarnamn" element={<LogoutUsername/>}/>
            <Route path="/utloggad" element={<SessionTimeout/>}/>
            {(process.env.NODE_ENV === 'development' || process.env.REACT_APP_IAMMOCK === 'true') &&
                <Route path="/login/:type" element={<LocalLogin/>}/>
            }

            {/* ----  TOGGLES ----- */}
            <Route path="mockSigningService"
                   loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)}
                   element={<ApplyFeatureToggleAndRedirectToStart featureToggle={SIGNING_SERVICE_MOCK_ENABLED}/>}/>
            <Route path="mockForrattningsinfoEnabled"
                   loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)}
                   element={<ApplyFeatureToggleAndRedirectToStart featureToggle={FRISSE_SERVICE_MOCK_ENABLED}/>}/>
            <Route path="mockKompletteringServiceEnable"
                   loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)}
                   element={<ApplyFeatureToggleAndRedirectToStart
                       featureToggle={KOMPLETTERING_SERVICE_MOCK_ENABLED}/>}/>
            <Route path="devFeaturesEnable"
                   loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)}
                   element={<ApplyFeatureToggleAndRedirectToStart featureToggle={DEV_FEATURES_ENABLED}/>}/>
            <Route path="phosMockSigningFeatureEnable"
                   loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)}
                   element={<ApplyFeatureToggleAndRedirectToStart featureToggle={PHOS_MOCK_SIGNING_ENABLED}/>}/>
            <Route path="clearFeatureToggles"
                   loader={() => checkUserIsLoggedIn(auth, dispatch, cookies)}
                   element={<ApplyFeatureToggleAndRedirectToStart featureToggle={undefined}/>}/>
        </Route>
    )
}

export function Router() {

    const dispatch = useAppDispatch();
    const auth = useAppSelector((store) => store.auth);
    const [cookies] = useCookies([TERMS_OF_USAGE_COOKIE_NAME, MRHSESSION_COOKIE_NAME, "setRunEnvToPrd"]);
    const {applyFeatureTogglesFromCookie} = useCookieFeatureToggleHelper()

    useEffect(() => {
        applyFeatureTogglesFromCookie()
    }, [applyFeatureTogglesFromCookie]);


    return <RouterProvider router={createBrowserRouter(routesFromElements(auth, dispatch, cookies))}/>
}

export default Router;
