import { ANSOKAN_FORM_DESTROY } from 'redux/modules/sharedActions'
import { createNewAnsokan, load, remove, save } from 'repository/ansokanRepository'
import Cookies from 'universal-cookie'
import { handleError } from 'utils/errorHandler'
import { Fetcher } from '../../repository/fetcher'
import { LockedAnsokanManipulationError } from '../../utils/repositoryHelpers'
import { endProgress } from './fullscreenLoading'
import { SERVER_VALIDATION_RESULT } from './validation/validation'
import {USER_CONFIRM_AMOUNT_OF_SOKANDE_IS_INCORRECT} from "./contacts";
import {Dispatch} from "redux";
import {RootState} from "../../store";

export const CREATE_PENDING = 'ansokanRepository/CREATE_PENDING'
export const CREATE_SUCCESSFUL = 'ansokanRepository/CREATE_SUCCESSFUL'
export const CREATE_FAILED = 'ansokanRepository/CREATE_FAILED'

export const LOAD_PENDING = 'ansokanRepository/LOAD_PENDING'
export const LOAD_SUCCESSFUL = 'ansokanRepository/LOAD_SUCCESSFUL'
export const LOAD_FAILED = 'ansokanRepository/LOAD_FAILED'

export const SAVE_PENDING = 'ansokanRepository/SAVE_PENDING'
export const SAVE_SUCCESSFUL = 'ansokanRepository/SAVE_SUCCESSFUL'
export const SAVE_FAILED = 'ansokanRepository/SAVE_FAILED'

export const SAVE_COOLDOWN = 'ansokanRepository/SAVE_COOLDOWN'

export const DELETE_PENDING = 'ansokanRepository/DELETE_PENDING'
export const DELETE_SUCCESSFUL = 'ansokanRepository/DELETE_SUCCESSFUL'
export const DELETE_FAILED = 'ansokanRepository/DELETE_FAILED'

export const initialState = {
  loadIsPending: false,
  loadSuccessful: false,
  loadFailed: false,
  saveIsPending: false,
  lastSaved: 0,
  saveSuccessful: false,
  saveFailed: false,
  saveCooldown: false,
  deleteIsPending: false,
  deleteSuccessful: false,
  deleteFailed: false,
  remoteDataKey: null,
  isAnsokanStatusChangeError: false,
  error: ''
}

export default function reducer (state: Object = initialState, action: any = {}): Object {
  switch (action.type) {
    case CREATE_PENDING:
      // Partly handled in middleware
      return {
        ...state,
        remoteDataKey: null
      }
    case LOAD_PENDING:
      return {
        ...state,
        loadIsPending: true,
        loadSuccessful: false,
        loadFailed: false,
        error: ''
      }
    case LOAD_SUCCESSFUL:
      return {
        ...state,
        loadIsPending: false,
        loadSuccessful: true,
        loadFailed: false,
        remoteDataKey: action.result._id,
        skapad: action.result._skapad,
        andrad: action.result._andrad,
        status: action.result._status,
        statusAndrad: action.result._statusAndrad,

      }
    case LOAD_FAILED:
      return {
        ...state,
        loadIsPending: false,
        loadSuccessful: false,
        loadFailed: true,
        error: action.value
      }
    case SAVE_PENDING:
      return {
        ...state,
        saveIsPending: true,
        saveSuccessful: false,
        saveFailed: false,
        isAnsokanStatusChangeError: false,
        error: ''
      }
    case SAVE_SUCCESSFUL:
      return {
        ...state,
        saveIsPending: false,
        saveSuccessful: true,
        saveFailed: false,
        isAnsokanStatusChangeError: false,
        lastSaved: Date.now(),
        remoteDataKey: action.result
      }
    case SAVE_FAILED:
      return {
        ...state,
        saveIsPending: false,
        saveSuccessful: false,
        saveFailed: true,
        error: action.value,
        isAnsokanStatusChangeError: action.isAnsokanStatusChangeError
      }
    case SAVE_COOLDOWN:
      return {
        ...state,
        saveCooldown: action.value
      }
    case DELETE_PENDING:
      return {
        ...state,
        deleteIsPending: true,
        deleteSuccessful: false,
        deleteFailed: false,
        error: ''
      }
    case DELETE_SUCCESSFUL:
      return {
        ...state,
        deleteIsPending: false,
        deleteSuccessful: true,
        deleteFailed: false
      }
    case DELETE_FAILED:
      return {
        ...state,
        deleteIsPending: false,
        deleteSuccessful: false,
        deleteFailed: true,
        error: action.value
      }
    case ANSOKAN_FORM_DESTROY:
      return initialState
    default:
      return state
  }
}

export function createAnsokan (): any {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({ type: CREATE_PENDING })
    const fetcher = new Fetcher(dispatch, getState, false)
    return createNewAnsokan(fetcher, getState())
      .then(remoteDataKey => {
        dispatch({
          type: CREATE_SUCCESSFUL,
          result: remoteDataKey
        })
        return remoteDataKey
      }).catch(error => {
        dispatch({
          type: CREATE_FAILED,
          value: error
        })
        throw error
      }).finally(() => {
        dispatch(endProgress())
      })
  }
}

export function saveAnsokan (cookies: Cookies = new Cookies()): any {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({ type: SAVE_PENDING })
    const fetcher = new Fetcher(dispatch, getState, false, cookies)
    return save(fetcher, getState())
      .then(({ remoteDataKey, validationMessages }) => {
        dispatch({
          type: SAVE_SUCCESSFUL,
          result: remoteDataKey
        })

        if(validationMessages.find(error => error.id === "FASTIGHET_HAR_RATT_ANTAL_SOKANDE") === undefined){
          dispatch({
            type: USER_CONFIRM_AMOUNT_OF_SOKANDE_IS_INCORRECT,
            choices: false
          })
        }

        dispatch({
          type: SERVER_VALIDATION_RESULT,
          errors: validationMessages
        })

        return remoteDataKey
      }).catch(error => {
        if (error instanceof LockedAnsokanManipulationError) {
          dispatch({
            type: SAVE_FAILED,
            value: error,
            isAnsokanStatusChangeError: true
          })
        } else {
          dispatch({
            type: SAVE_FAILED,
            value: error,
            isAnsokanStatusChangeError: false
          })
        }
      })
  }
}

export function saveAndLoadAnsokan (cookies: Cookies = new Cookies()): any {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({ type: SAVE_PENDING })
    const fetcher = new Fetcher(dispatch, getState, false, cookies)
    return save(fetcher, getState())
      .then(({ remoteDataKey, validationMessages}) => {
        dispatch({
          type: SAVE_SUCCESSFUL,
          result: remoteDataKey
        })
        dispatch(loadAnsokan(remoteDataKey))
        return { remoteDataKey, validationMessages}
      }).catch(error => {
        if (error instanceof LockedAnsokanManipulationError) {
          dispatch({
            type: SAVE_FAILED,
            value: error,
            isAnsokanStatusChangeError: true
          })
          dispatch({
            type: LOAD_FAILED,
            value: error
          })
        } else {
          dispatch({
            type: SAVE_FAILED,
            value: error,
            isAnsokanStatusChangeError: false
          })
          dispatch({
            type: LOAD_FAILED,
            value: error
          })
        }
        handleError(error, dispatch)
      })
  }
}

export function setSaveCooldown (value: boolean): Object {
  return {
    type: SAVE_COOLDOWN,
    value: value
  }
}

export function loadAnsokan (remoteDataKey: string): any {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({ type: LOAD_PENDING })
    const fetcher = new Fetcher(dispatch, getState)
    return load(fetcher, remoteDataKey)
      .then(ansokan => {
        const ansokanResponse = new AnsokanResponse()
        Object.assign(ansokanResponse, ansokan)

        dispatch({
          type: LOAD_SUCCESSFUL,
          result: ansokanResponse.data
        })

        dispatch({
          type: SERVER_VALIDATION_RESULT,
          errors: ansokanResponse.validationMessages
        })

        return ansokanResponse.data
      }).catch(error => {
        dispatch({
          type: LOAD_FAILED,
          value: error
        })
      }).finally(() => {
        dispatch(endProgress())
      })
  }
}

class AnsokanResponse {
  data: Object
  validationMessages: Array<Object>

  constructor (ansokanData: Object = {}, validationMessages: Array<Object> = []) {
    this.data = ansokanData
    this.validationMessages = validationMessages
  }
}

// Only fetch not load Ansokan into the store
export function fetchAnsokan (remoteDataKey: string): any {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    const fetcher = new Fetcher(dispatch, getState)
    return load(fetcher, remoteDataKey)
      .then(ansokan => {
        const ansokanResponse = new AnsokanResponse(ansokan.data, ansokan.validationMessages)
        return ansokanResponse.data
      }).catch(error => {
        return error
      }).finally(() => {
        dispatch(endProgress())
      })
  }
}

export function deleteAnsokan (ansokanId: string): any {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({ type: DELETE_PENDING })
    const fetcher = new Fetcher(dispatch, getState, false)
    return remove(fetcher, ansokanId)
      .then(() => {
        dispatch({ type: DELETE_SUCCESSFUL })
      }).catch(error => {
        dispatch({
          type: DELETE_FAILED,
          value: error
        })
        throw error
      }).finally(() => {
        dispatch(endProgress())
      })
  }
}
