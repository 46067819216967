import React from 'react'
import KartskissThumbnail from './KartskissThumbnail'
import BifogadKartskiss from './BifogadKartskiss'
import {FullscreenLoading} from '../../components'
import BifogadKartskissLightbox from './BifogadKartskissLightbox'
import {useSelector} from 'react-redux'
import {editMap, loadFastighetsomraden} from '../../redux/modules/map'
import {fetchBifogadKartskiss} from '../../redux/modules/bifogadKartskiss'
import {copyKartskiss} from '../../redux/modules/kartskiss'
import {MAX_NUMBER_OF_MAPS} from '../../utils/globalConstants'
import {useAppDispatch} from "../../hooks";
import {RootState} from "../../store";
import {logError} from "../../utils/errorHandler";
import {useNavigate} from 'react-router-dom'


type KartskissListaProps = {
  ansokanId: string
  deleteRitadKartskissCallback: Function
  deleteBifogadKartskissCallback: Function
  displayTooManyKartskisserModalDialogCallback: Function
}

export function KartskissLista(props: KartskissListaProps)  {

  // @ts-ignore
  const ritadeKartskisser = useSelector((store: RootState) => store.kartskiss.list)
  // @ts-ignore
  const isLoadingKartskissList = useSelector((store: RootState) => store.kartskiss.isLoadingList)
  const bifogadeKartskisser = useSelector((store: RootState) => store.bifogadKartskiss.list)
  const isLoadingBifogadKartskissList = useSelector((store: RootState) => store.bifogadKartskiss.isLoadingList)
  const shouldDisplayLightbox = useSelector((store: RootState) => store.bifogadKartskiss.shouldDisplayLightbox)
  const fullBifogadKartskiss = useSelector((store: RootState) => store.bifogadKartskiss.fetchedBifogadKartskiss)
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  function numberOfKartskisserCreated () {
    return ritadeKartskisser.length + bifogadeKartskisser.length
  }

  function onCopyMapClick (id: string) {
    if (numberOfKartskisserCreated() < MAX_NUMBER_OF_MAPS) {
      dispatch(copyKartskiss(props.ansokanId, id))
    } else {
      props.displayTooManyKartskisserModalDialogCallback()
    }
  }
  function onEditMapClick(item: any) {
    dispatch(editMap(item))
    dispatch(loadFastighetsomraden(item.fastigheter))
    navigate("/privat/ansokan/" + props.ansokanId + "/skapakartskiss");

  }

  function onGetBifogadKartskissClick (kartskissId: string) {
   dispatch(fetchBifogadKartskiss(props.ansokanId, kartskissId))
      .then(() => {
        document?.getElementById('trigger_kartskiss_lightbox_0')?.click()
      }).catch((error: Error) => {
     logError(error, 'Kartskisslista-component')
    })
  }

    return (
      <>
        {ritadeKartskisser.length > 0 && ritadeKartskisser.map((item: any) => {
          return (
            <KartskissThumbnail key={item.id}
                                map={item}
                                onEditMapClick={() => onEditMapClick(item)}
                                onDeleteMapClick={() => props.deleteRitadKartskissCallback(item.id)}
                                onCopyMapClick={() => onCopyMapClick(item.id)}/>
          )
        })}
        {bifogadeKartskisser.length > 0 && bifogadeKartskisser.map((bfk: any) => {
          return (
            <BifogadKartskiss namn={bfk.namn} kartskissId={bfk.id} thumbnail={bfk.thumbnail}
                              deleteBifogadKartskiss={() => props.deleteBifogadKartskissCallback(bfk.id)}
                              getBifogadKartskiss={() => onGetBifogadKartskissClick(bfk.id)}
                              key={bfk.id}/>
          )
        })}
        {isLoadingKartskissList || isLoadingBifogadKartskissList ?
          <FullscreenLoading fullscreen={false}
                             hideContent={false}
                             text={'Laddar kartskisser...'}/> : null}
        {shouldDisplayLightbox &&
          <BifogadKartskissLightbox fullBifogadKartskiss={fullBifogadKartskiss}/>}
      </>
    )
  }

export default (KartskissLista)
