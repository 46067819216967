import { connect } from 'react-redux'
//@ts-ignore
import { LinkButtonTop } from 'redet-react-components'

const mapStateToProps = (store: StoreType) => {
  return {
    ansokanId: store.ansokanRepository.remoteDataKey,
    saveIsPending: store.ansokanRepository.saveIsPending
  }
}

type PDFProps = {
  ansokanId?: string,
  saveIsPending: boolean,
  onClick: Function
}

type StoreType = {
  ansokanRepository: {
    remoteDataKey: string,
    saveIsPending: boolean
  }
}

PDFLinkButton.defaultProps = {
  saveIsPending: false
}

function PDFLinkButton(props: PDFProps) {

    const downloadLink = '/api/ansokan-service/ansokan/'+props.ansokanId+'/pdf?withUnderskriftsRader=true'
    return (
      <>
        <LinkButtonTop link={downloadLink} text={'Ladda ner förhandsgranskning'}
                       icon={'save_alt'}
                       isDisabled={(props.saveIsPending)}
                       data-selenium-id="förhandsgranska"
                       aria-label={'Ladda ner kopia av ansökan'}
                       id="ansokanDownloadLink"
                       target="_blank"
                       download="Ansokan"
                       onClick={props.onClick}/>
      </>
    )
}

export default connect(mapStateToProps, undefined)(PDFLinkButton)
