import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    // @ts-ignore
    Checkbox, Column, Row, TextInput, VarningsRuta, WizardIntorduction, WizardNavigationButtonToolbar
} from "redet-react-components";
import {email} from "utils/validation";
import {useAppDispatch} from "../../../hooks";

type Props = {
    epostKvittens: string
    setEpostKvittens: Dispatch<SetStateAction<string>>
    epostKontakt: string
    useSameEpost: boolean
    setUseSameEpost: Dispatch<boolean>
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
    previousStep?: () => void
}

export function KvittensEpostAterkallandeStep(props: Props) {
    const [epostKvittensValidationError, setEpostKvittensValidationError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const [userHasClickedNextButton, setUserHasClickedNextButton] = useState<boolean>(false);
    const dispatch = useAppDispatch()

    useEffect(() => {
        checkIfErrorExistsBeforeLeavingPage()
    });

    function checkIfErrorExistsBeforeLeavingPage() {
        if ((!props.useSameEpost && !props.epostKvittens) || epostKvittensValidationError) {
            setErrorMessage(true)
            return true
        } else {
            setErrorMessage(false)

            return false
        }
    }
    function nexButtonClick() {
        setUserHasClickedNextButton(true)
            if(!errorMessage) {
                dispatch(props.nextStep)
            }
    }

    return <>
        <WizardIntorduction
            title={"Kvittens"}
            ingress={"Vi skickar en kvittens till nedan angiven e-postadress när återkallandet är inskickad till Lantmäterimyndigheten. " +
                "Denna e-postadress kommer inte att visas för ansvariga handläggare om du valt att inte ange denna i föregående steg."}>
            <Row>
                <Column columns={"form-group col-xs-12 col-sm-6 col-xl-6"}>
                    <TextInput
                        label={"E-post"}
                        isRequired={true}
                        autoComplete={'email'}
                        type={"email"}
                        id={"mailId"}
                        maxLength={100}
                        value={props.epostKvittens}
                        validationErrorMsg={epostKvittensValidationError}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const error = email(evt.target.value);
                            if (error) {
                                setEpostKvittensValidationError(error);
                                props.setEpostKvittens(evt.target.value)
                            } else {
                                setEpostKvittensValidationError('');
                                props.setEpostKvittens(evt.target.value)
                            }
                        }}
                    />
                    <Checkbox
                        id={'useCurrentEpostId'}
                        label={'Använd samma epostadress som i föregående steg'}
                        isDisabled={!props.epostKontakt}
                        isChecked={props.useSameEpost}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            props.setUseSameEpost(evt.target.checked);
                            const error = email(props.epostKontakt);
                            setEpostKvittensValidationError(error || '');
                            (evt.target.checked && props.epostKontakt) ? props.setEpostKvittens(props.epostKontakt) : props.setEpostKvittens('');
                        }}/>
                </Column>
            </Row>
        </WizardIntorduction>
        <WizardNavigationButtonToolbar
            prevButtonText={"Återkallande"}
            nextButtonText={"Förhandsgranska"}
            onClickNextButton={() => nexButtonClick()}
            onClickPrevButton={props.previousStep}/>
        {errorMessage && userHasClickedNextButton &&
            <VarningsRuta
                errorMessages={["Du måste antingen fylla i en ny e-post eller välja att använda samma e-post som i föregående steg"]}
                ariaTitle={"Felmeddelande"}/>}
    </>;
}

export default KvittensEpostAterkallandeStep;