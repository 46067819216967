import React, {ChangeEvent, MouseEvent, DragEvent, useRef, useState} from "react";
import {BifogaHandlingContext} from "../BifogaHandlingContext";
// @ts-ignore
import {ButtonGroup, ButtonToolbarV2, ButtonTop, FormFieldset, ListGroup, ListItem, ListItemActionButton, ValideringsFelmeddelandeRuta, VarningsRuta} from "redet-react-components";
import {Term} from "../../../../views";
import BifogadFileFilter, {FilteredFilesResult} from "./helper/BifogadFileFilter";
import {createFileHash} from "../../../../helpers/fileHelpers";
import {formatFileSize} from "../../../../pages/euCitizenApplication/HandlingUtils";

type Props = {
    //Injectas med clone element från WizardDialogStep :sick!
    previousStep?: () => void,
    nextStep?: () => void
}

export function BifogaHandlingLaddaUppFilerStep({previousStep, nextStep}: Props) {

    const {typ, handlingar, setHandlingar} = React.useContext(BifogaHandlingContext)

    const [warnings, setWarnings] = useState<string[]>([])
    const [filesValidationMsg, setFilesValidationMsg] = useState<string>()

    const filesSelectedInputRef = useRef<HTMLInputElement>(null)

    const validate = (files: File[]) => {
        const valid = files.length > 0
        setFilesValidationMsg(!valid ? 'Du måste välja minst en fil' : '')
        return valid
    }

    function onNextStepClick(evt: MouseEvent<HTMLInputElement>) {
        // if valid, go to next step!
        if (nextStep && validate(handlingar))
            nextStep()
    }

    function onFileChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            const files = Array.from(event.target.files)
            appendFiles(files)
            // Clear the input control once we have its data so that it can be reused without any odd behaviour
            event.target.value = ''
            //if validation was already done, reset msg
            if(filesValidationMsg)
                validate(files)
        }
    }

    function onFileDrop(evt: DragEvent<HTMLDivElement>) {
        onPreventDefault(evt)
        const files = Array.from(evt.dataTransfer?.files ?? [])
        appendFiles(files)
    }

    function onDeleteFile(file: File) {
        setHandlingar(handlingar.filter(handling => handling !== file))
        // clear msg
        setWarnings([])
    }

    function onPreventDefault(event: any) {
        event.preventDefault()
        event.stopPropagation()
    }

    const appendFiles = (selectedFiles: File[]) => {
        //clear
        setFilesValidationMsg('')
        //filter by rules
        const warnings = [] as string[]
        let filteredList: File[] = [...selectedFiles]
        const handleResult = (result: FilteredFilesResult) => {
            if (result.message)
                warnings.push(result.message)
            filteredList = [...result.filtered]
        }
        //filter,
        handleResult(
            BifogadFileFilter.filterByDuplicates(handlingar, filteredList))
        handleResult(
            BifogadFileFilter.filterByFileType(filteredList))
        handleResult(
            BifogadFileFilter.filterByDistinctFileFormat(handlingar, filteredList))
        handleResult(
            BifogadFileFilter.filterBySize(filteredList))
        handleResult(
            BifogadFileFilter.filterByAccumulatedSize(handlingar, filteredList))
        handleResult(
            BifogadFileFilter.filterByCount(handlingar, filteredList))
        //all done
        setHandlingar([...handlingar, ...filteredList])
        setWarnings(warnings)
    }

    return (<>
        <FormFieldset title={'Välj filer'}
                      description={<>Välj <Term label="fil/filer" moment="help-upload" term=""/> (JPG, GIF, PNG eller PDF).
                          Om du lägger till flera filer kommer de att slås ihop till en handling. <br/>
                          Handlingar ska skannas och laddas upp separat - Exempelvis ett köp/bygglov/avtal etc per fil.</>}>
            {/*ref input*/}
            <input id="fileSelectionInput" type="file" ref={filesSelectedInputRef}
                   onChange={onFileChange}
                   multiple className="multiple-files hidden" aria-label="Ladda upp filer"
                   accept=".gif, .jpg, .jpeg, .png, .pdf"/>
            <div className="file-upload"
                 onClick={() => filesSelectedInputRef.current?.click()}
                 onDrop={onFileDrop}
                 onDrag={onPreventDefault}
                 onDragStart={onPreventDefault}
                 onDragEnd={onPreventDefault}
                 onDragOver={onPreventDefault}
                 onDragEnter={onPreventDefault}
                 onDragLeave={onPreventDefault}>
                            <span className="area">
                                <span className="active">Klicka för att välja eller dra filer hit</span>
                            </span>
            </div>
            {/*valideringmeddelande*/}
            {filesValidationMsg && <>
                <ValideringsFelmeddelandeRuta style={{position: 'relative', zIndex: 1000}} text={filesValidationMsg} isBlock={true} ariaTitle={'Felmeddelande'}/>
            </>}
        </FormFieldset>
        {handlingar.length > 0 && <>
            <ListGroup title={`Valda filer för ${typ?.visningsnamn}`} id={'selected-files-list'}>
                {handlingar.map((handling, idx) => {
                        const id = createFileHash(handling)
                        return <ListItem id={id}
                                         header={`Fil ${idx + 1}`}
                                         description={`${handling.name}, ${formatFileSize(handling.size)}`}
                                         key={`bifogad-handling-${id}-key`}
                                         actionButtons={[<ListItemActionButton ariaLabel={'Ta bort'}
                                                                               icon={'delete'}
                                                                               onClick={() => onDeleteFile(handling)}
                                                                               id={`delete-btn-${id}`}
                                                                               key={`delete-btn-${id}-key`}/>]}
                        />
                    }
                )}
            </ListGroup>
        </>}
        {warnings.length > 0 && <VarningsRuta ariaTitle={'Information om validering'} warningMessages={warnings}/>}
        {/*navigeringsknappar*/}
        <ButtonToolbarV2>
            <ButtonGroup>
                <ButtonTop text={'Ändra typ'}
                           onClick={previousStep}
                           icon={'arrow_back'}
                           id={'previous-step-button'}
                           ariaLabel={'Tillbaka till typ av handling'}/>
            </ButtonGroup>
            <ButtonGroup>
                <ButtonTop text={'Ange epost'}
                           onClick={onNextStepClick}
                           icon={'arrow_forward'}
                           id={'next-step-button'}
                           ariaLabel={'Gå vidare för att ange e-post'}/>
            </ButtonGroup>
        </ButtonToolbarV2>
    </>)
}