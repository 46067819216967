import React, {MouseEvent, useEffect, useState} from 'react'
import {
//@ts-ignore
    ValideringsFelmeddelandeRuta, FormFieldset, RadioButton, ButtonToolbarV2, ButtonGroup, ButtonTop
} from 'redet-react-components'
import {getLocalizedDateString} from '../../../../helpers/dates'
import {SmallLoading} from 'components'
import {Svarslista} from 'domain/Svarslistor'
import {BekraftaDelgivningContext} from '../BekraftaDelgivningContext';

type Props = {
    obesvaradeSvarslistor: Svarslista[],
    svarslistorIsLoading?: boolean
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
}

export function ValjDelgivningStep({obesvaradeSvarslistor, svarslistorIsLoading = false, nextStep}: Props) {

    const {delgivning, setDelgivning} = React.useContext(BekraftaDelgivningContext);

    const [selectDelgivningValidationMsg, setSelectDelgivningValidationMsg] = useState<string>()

    useEffect(() => {
        if(obesvaradeSvarslistor.length === 1)
            setDelgivning(obesvaradeSvarslistor[0])
    },[])

    const onSelectDelgivningChange = (svarslista: Svarslista) => {
        setDelgivning(svarslista)
        if (svarslista)
            setSelectDelgivningValidationMsg('')
    }

    const onNextStepClick = (evt: MouseEvent<HTMLInputElement>) => {
        //validate
        const valid = delgivning !== undefined
        const message = 'Du måste välja en delgivning av bekräfta'
        setSelectDelgivningValidationMsg(!valid ? message : '')
        //if valid, go to next step!
        if (nextStep && valid)
            nextStep()
    }

    const getRadioButtonLabel = (svarslista: Svarslista) => {
        const handlingLabel = svarslista.handlingar.length <= 1 ? 'handling' : 'handlingar'
        const createdDateLabel = getLocalizedDateString(svarslista.skapad, 'yyyy-MM-dd HH:mm')
        return `Delgivning ${createdDateLabel} (${svarslista.handlingar.length} ${handlingLabel})`
    }

    return (<>
        {!svarslistorIsLoading ? <>
            {/*välj delgivning*/}
            <FormFieldset title={'Välj delgivning'}
                          description={<> Välj den delgivning som du vill bekräfta. </>}>
                <div id='delgivning-select-list' className='form-group'>

                    {obesvaradeSvarslistor.map((svarslista, idx) =>
                        <RadioButton name='select-delgivning-choice'
                                     label={getRadioButtonLabel(svarslista)}
                                     value={svarslista.id}
                                     id={`delgivning-radio-${svarslista.id}`}
                                     data-selenium-id={`delgivning-radio-${idx}`}
                                     key={`delgivning-radio-key-${svarslista.id}-${delgivning?.id === svarslista.id ? 'checked' : 'unchecked'}`}
                                     checked={delgivning?.id === svarslista.id ? 'checked' : undefined}
                                     onChange={() => onSelectDelgivningChange(svarslista)}/>)}
                    {selectDelgivningValidationMsg &&
                        <ValideringsFelmeddelandeRuta text={selectDelgivningValidationMsg}
                                                      isBlock={true} ariaTitle={'Felmeddelande'}/>
                    }
                </div>
            </FormFieldset>
        </> : <SmallLoading text='Laddar delgivningar...'/>}
        {/*navigeringsknappar*/}
        <ButtonToolbarV2>
            <ButtonGroup/>
            <ButtonGroup>
                <ButtonTop text={'Kontaktuppgifter'}
                           onClick={onNextStepClick}
                           icon={'arrow_forward'}
                           isDisabled={svarslistorIsLoading}
                           id={'next-step-button'}
                           ariaLabel={'Välj markerad delgivning'}/>
            </ButtonGroup>
        </ButtonToolbarV2>
    </>)
}

export default ValjDelgivningStep