import * as interactions from './interactions'
import Log from 'utils/log'
import { Tile } from 'ol/layer'
import { unByKey } from 'ol/Observable'
import { boundingExtent } from 'ol/extent'

export default class MapExporter {
  constructor (mapController, cropManager, changeCenterXYFunc, selectToolFunc, zoomToFunc, exportMapImageFunc) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.cropManager = cropManager
    this.changeCenterXYFunc = changeCenterXYFunc
    this.selectToolFunc = selectToolFunc
    this.zoomToFunc = zoomToFunc
    this.exportMapImageFunc = exportMapImageFunc
    this.drawTextInDownRightCornerOfMap = this.drawTextInDownRightCornerOfMap.bind(this)
  }

  waitForTilesToLoad () {

    this.log.debug('Setup tiles loading listeners')
    let hasloadingTiles = false
    let loadingTiles = 0

    const listenerKeys = []

    this.mapController.getMap().getLayers().forEach((layer) => {
      if (layer instanceof Tile) {
        const source = layer.getSource()
        listenerKeys.push(source.on('tileloadstart', () => {
          hasloadingTiles = true
          loadingTiles++
        }))
        listenerKeys.push(source.on('tileloadend', () => {
          loadingTiles--
        }))
        listenerKeys.push(source.on('tileloaderror', () => {
          loadingTiles--
        }))
      }
    })

    const removeEventListeners = () => {
      try {
        listenerKeys.forEach((key) => {
          unByKey(key)
        })
      } catch (error) {
        this.log(error)
      }
    }

    // Crop map to user selection
    this.zoomSaved = this.mapController.getMap().getView().getZoom()
    const mapSizeX = this.mapController.getMap().getSize()[0]
    const mapSizeY = this.mapController.getMap().getSize()[1]
    const cropValues = this.cropManager.calculateCropCutoff()
    const calculatedXcutOff = cropValues.xCutOff
    const calculatedYcutOff = cropValues.yCutOff
    const topLeft = [calculatedXcutOff, calculatedYcutOff]
    const downRight = [mapSizeX - calculatedXcutOff, mapSizeY - calculatedYcutOff]
    const coordinateTopLeft = this.mapController.getMap().getCoordinateFromPixel(topLeft)
    const coordinateDownRight = this.mapController.getMap().getCoordinateFromPixel(downRight)

    // Kartskisser ska alltid ha samma aspect ratio oavsett vilken enhet (mobil, desktop eller hög/låg/hdpi-skärn) och för att det ska funka på olika typer av skärmar och zoomnivåer måste vi göra nedanstående.
    // Hämta browserns zoom-nivå och skalningsfaktor. En lågupplöst skärm med 100% browser-zoom ger 1.0, en lågupplöst skärm med 150% browser-zoom ger 1.5, en HDPI-skärm med 100% browser-zoom och skalning 1.5 ger 1.5, en HDPI-skärm med 150% browser-zoom och skalning 1.5 ger 1.25, osv, osv.
    const scaleFactor = window.devicePixelRatio
    this.log.info('Browser has scale factor ' + scaleFactor)
    // För att den genererade bilden inte ska få en högre/lägre upplösning än önskat när användaren zoomat in/ut eller har anpassad skalgningsfaktor gör vi denna omvandling:
    const heightInPixels = 768 / scaleFactor
    const widthInPixels = 1024 / scaleFactor
    this.log.info('Exporting map to image, setting map height: ' + heightInPixels + 'px and width: ' + widthInPixels + 'px')

    // För att kartskissen ska få en specifik upplösning måste height och width sättas på map-container-elementet
    document.querySelector('#map-container').style.cssText = 'height: ' + heightInPixels + 'px !important; width: ' + widthInPixels + 'px !important'
    // Om klassen 'with-bottom-toolbar' är kvar kommer toolbarens storlek (41) att dras ifrån på map-containerns storlek, så den klassen tas bort
    document.querySelector('#map-container').classList.remove('with-bottom-toolbar')

    // Uppdatera kartan
    this.mapController.getMap().updateSize()
    this.mapController.getMap().renderSync()

    const currentBoundingExtent = boundingExtent([coordinateTopLeft, coordinateDownRight])
    this.mapController.getMap().getView().fit(currentBoundingExtent, { constrainResolution: false })
    this.changeCenterXYFunc(this.mapController.getMap().getView().getCenter()[0], this.mapController.getMap().getView().getCenter()[1])
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (hasloadingTiles) {
          // Retry max 250 times x 100ms = 25s
          let retries = 0

          const isLoaded = () => {
            this.log.debug('Loading tiles', loadingTiles)
            if (loadingTiles > 0) {
              if (retries === 250) {
                this.log.error('Not all tiles have loaded, timeout.')
                removeEventListeners()
                reject({ message: 'Misslyckades med att skapa kartbild.', zoom: this.zoomSaved })
              } else {
                retries++
                setTimeout(isLoaded, 100)
              }
            } else {
              this.log.debug('All tiles have loaded.')
              removeEventListeners()
              resolve()
            }
          }
          isLoaded()
        } else {
          this.log.debug('No tiles requested to load within 1s. Expect loading to be done.')
          removeEventListeners()
          resolve()
        }
      }, 500)
    })
  }

  exportMapImage () {
    return new Promise((resolve, reject) => {

      // Troligen reduntant eftersom att vi nu visar crop mode innan vi sparar!
      this.selectToolFunc(interactions.PAN)

      // Only save with topoweb as background layer
      // Troligen reduntant eftersom att vi nu visar crop mode innan vi sparar!
      if (this.mapController.isOrtofotoActive()) {
        this.mapController.swapBackgroundMap()
      }

      this.waitForTilesToLoad()
        .then(() => {
          let imageData = undefined
          this.mapController.getMap().once('postcompose', (event) => {
            this.log.info('Save map as image')
            const canvas = event.context.canvas
            this.drawTextInDownRightCornerOfMap(canvas, 'Kartredovisningen har inte rättsverkan, jmfr mot beslut i lantmäterihandlingar. ©Lantmäteriet')
            imageData = canvas.toDataURL('image/png')
            this.exportMapImageFunc(imageData)
          })
          this.mapController.getMap().renderSync()
          this.zoomToFunc(this.zoomSaved)
          resolve(imageData)
        })
        .catch((err) => {
          this.log.error(err.message)

          document.querySelector('#map-container').style.cssText = ''
          document.querySelector('#map-container').classList.remove('with-bottom-toolbar')

          this.mapController.getMap().updateSize()
          this.mapController.getMap().renderSync()

          // Återståll cropOff-fältens storlek och zoom-nivån, då denna ändrats vid exporten.
          this.cropManager.calculateAndApplyCropCutoff()
          this.mapController.getMap().getView().setZoom(err.zoom)
          reject('Failed to load tiles')
        })
    })

  }

  drawTextInDownRightCornerOfMap (canvas, text) {
    const ctx = canvas.getContext('2d')
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    ctx.font = '10pt Arial'
    const textWidth = ctx.measureText(text).width
    const textHeight = parseInt(ctx.font, 10)
    const positionX = canvasWidth - textWidth
    const positionY = canvasHeight
    ctx.globalAlpha = 0.8
    ctx.fillStyle = ' #ffffff' // White
    ctx.fillRect(positionX - 4, positionY - textHeight - 2, textWidth + 4, textHeight + 4)
    ctx.fillStyle = ' #000000' // Black
    ctx.fillText(text, positionX - 2, positionY - 2)
    ctx.restore()
  }
}
