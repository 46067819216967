export const behorighetshandling = <>
  <p>Handling som bevisar att du är behörig att företräda t.ex. fastighetsägare eller juridisk
    person att ansöka om
    förrättningen.
    <br/>Exempel på handlingar är:</p>

  <ul>
    <li> Fullmakt (t.ex. vid företrädare för annan sökande)</li>
    <li> Bouppteckning (för dödsbo)</li>
    <li> Stadgar och protokoll (för förening)</li>
    <li> Utdrag från Bolagsregistret</li>
  </ul>
</>
