import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {MapInteractions, PAN} from './interactions';
import {connect} from 'react-redux';
import Log from 'utils/log';
import {changeCenterXY, changeDrawingFeatures, changeFastighetFeatures, exportMapImage, selectTool, zoomTo} from 'redux/modules/map';
import {saveAnsokan} from 'redux/modules/ansokanCRUD';
import {hide} from 'redux/modules/help';
import {displayModalConfirmDialog} from '../../redux/modules/modalDialog';
import classnames from 'classnames';
import FinishButtonOverLay from './overlays/FinishButtonOverlay.js';
import TextOverLay from './overlays/TextOverlay.js';
import CropManager from './CropManager';
import MapExporter from './MapExporter';
import FeatureHandler from './FeatureHandler';
import PropertyToMapSynchronizer from './PropertyToMapSynchronizer';
import UserActivityMonitor from './UserActivityMonitor';
import ToolCoordinator from './ToolCoordinator';
import MapController from './MapController';
import {uploadKartskiss} from '../../redux/modules/kartskiss';
import {ButtonSub} from "redet-react-components";
import {GeoJSON} from "ol/format";
import FullscreenLoading from "../General/FullscreenLoading";

export class UnconnectedOLMap extends Component {
    static propTypes = {
        selectTool: PropTypes.func.isRequired,
        fastigheter: PropTypes.array.isRequired,
        ansokanFastigheter: PropTypes.array.isRequired,
        children: PropTypes.array.isRequired,
        center: PropTypes.object.isRequired,
        drawingFeatures: PropTypes.any.isRequired,
        fastighetFeaturesRaw: PropTypes.any.isRequired,
        zoom: PropTypes.number,
        changeCenterXY: PropTypes.func.isRequired,
        zoomTo: PropTypes.func.isRequired,
        changeDrawingFeatures: PropTypes.func.isRequired,
        changeFastighetFeatures: PropTypes.func.isRequired,
        activeTool: PropTypes.string.isRequired,
        exportMapImage: PropTypes.func.isRequired,
        saveAnsokan: PropTypes.func.isRequired,
        hideHelp: PropTypes.func.isRequired,
        displayModalConfirmDialog: PropTypes.func.isRequired,
        cropMode: PropTypes.bool.isRequired,
        newMap: PropTypes.bool.isRequired,
        uploadKartskiss: PropTypes.func.isRequired,
        isLoadingFastighetsOmraden: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.destructors = [];
        this.state = {
            active: true,
            showMapPanel: false,
            showToolbar: true,
        };
        this.mapRef = React.createRef();
    }

  componentDidMount () {
    document.body.classList.add('map-visible')
    this.log = new Log(this.constructor.name)

        this.log.debug('Running initialization of dependencies');
        this.toolCoordinator = new ToolCoordinator();

        this.userActivityMonitor = new UserActivityMonitor(this.props.saveAnsokan, this.destructors);

        this.featureHandler = new FeatureHandler();
        this.featureHandler.init();

        this.mapController = new MapController(
            this.destructors,
            this.userActivityMonitor,
            this.toolCoordinator,
            (...args) => this.setCursor(...args),
            this.featureHandler,
        );
        this.mapController.createMap('map', this.props.center.x, this.props.center.y, this.props.zoom);
        this.mapController.createMiniMap('miniMap', this.props.center.x, this.props.center.y, this.props.zoom);
        //this.mapController.createFastighetLayer();
        this.mapController.createDrawingLayer();

        this.mapInteractions = new MapInteractions(
            this.mapController,
            this.featureHandler,
            this.toolCoordinator,
            this.destructors,
            (...args) => this.setCursor(...args),
            () => this.saveCurrentFeatures(),
        );
        this.mapInteractions.addDefaultInteractionsToMap();

        this.finishButtonOverLay = new FinishButtonOverLay(
            this.mapController,
            this.mapInteractions,
            this.destructors,
            this.toolCoordinator
        );
        this.finishButtonOverLay.init();

        this.textOverlay = new TextOverLay(
            this.mapController.getMap(),
            this.toolCoordinator,
            this.props.displayModalConfirmDialog,
            this.props.selectTool,
            this.destructors,
            () => this.saveCurrentFeatures(),
            this.featureHandler,
        );
        this.textOverlay.init();

        this.propertyToMapSynchronizer = new PropertyToMapSynchronizer(
            this.mapController,
            () => this.saveCurrentFeatures(),
            this.props.changeCenterXY,
            this.props.zoomTo,
            this.mapInteractions,
            this.finishButtonOverLay,
            this.textOverlay,
            this.destructors,
            this.featureHandler,
            () => this.saveCurrentFeatures(),
        );
        this.propertyToMapSynchronizer.applyMapToPropSync();
        this.propertyToMapSynchronizer.syncPropsWithMap(this.props, this.props, true);
        this.mapController.createFastighetLayer();

        this.cropManager = new CropManager(this.mapController, () => this.props.cropMode);
        this.cropManager.init();

        this.mapExporter = new MapExporter(
            this.mapController,
            this.cropManager,
            this.props.changeCenterXY,
            this.props.selectTool,
            this.props.zoomTo,
            this.props.exportMapImage,
        );

        // Always select the PAN tool as the default
        this.props.selectTool(PAN);

        this.props.hideHelp();
        this.saveCurrentFeatures();

        // Denna forceUpdate behövs för att få events att skickas korrekt (t ex zoom)
        this.forceUpdate();

        // Denna updateSize behövs för att synka kartans storlek med viewporten och för att undvika offset mellan verktyg och muspekare
        setTimeout(() => {
            this.mapController.getMap().updateSize();
        }, 0);

        this.log.debug('Done with initialization of dependencies');
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.propertyToMapSynchronizer.setIsProcessingExternalChange(true);
        this.toolCoordinator.setActiveTool(nextProps.activeTool);
        this.propertyToMapSynchronizer.syncPropsWithMap(this.props, nextProps);
        return true;
    }

    componentDidUpdate() {
        this.propertyToMapSynchronizer.setIsProcessingExternalChange(false);
        if (this.props.cropMode) {
            this.cropManager.calculateAndApplyCropCutoff();
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('map-visible');
        this.log.debug('Close map');
        // First let all interactions remove themselves from the map before it is destroyed
        this.mapInteractions.removeAllInteractions();
        this.destructors.forEach((destructor) => {
            destructor();
        });
        this.props.hideHelp();
        try {
            document.getElementById('toolbarSparaOchStang').removeEventListener('click');
            window.removeEventListener('resize');
        } catch (error) {
            // fef
        }
    }

    // Should probably be moved somewhere
    setCursor(cursor) {
        this.log.debug('Change cursor to', cursor);
        this.mapRef.current.style.cursor = cursor;
    }

    toggleClass(activated) {
        this.setState({active: activated});
    }

  saveCurrentFeatures () {
    this.log.debug('Save current drawing features')
    const format = new GeoJSON()
    let geoJSONString = format.writeFeatures(this.mapController.getDrawingLayer().getSource().getFeatures())
    this.props.changeDrawingFeatures(geoJSONString)
    if (this.mapController.getFastighetLayer()) {
        this.log.debug('Save current fastighet features')
        geoJSONString = format.writeFeatures(this.mapController.getFastighetLayer().getSource().getFeatures())
        this.props.changeFastighetFeatures(geoJSONString)}


    // Save current location and zoom to store in case we need to recover it after a page refresh
    // We can't rely on event handlers as the pan tool throws too many events and the zoom tool might not be used during a drawing session
    this.props.changeCenterXY(this.mapController.getMap().getView().getCenter()[0], this.mapController.getMap().getView().getCenter()[1])
    this.props.zoomTo(this.mapController.getMap().getView().getZoom())
  }

    render() {
        const ortofotoActive = this.mapController && this.mapController.isOrtofotoActive();

        if (this.props.cropMode && ortofotoActive) {
            this.mapController.swapBackgroundMap();
        }

        return (
            <div className={classnames('simple-map-panel fullscreen', {'crop-mode': this.props.cropMode})}
                 id="map-container">
                <div ref={this.mapRef} id="map"/>
                <ButtonSub id="finishMapButton" style={{top: '50%', left: '50%'}} text={"Klar"}/>
                <div className="disclaimer">
                    <p><a href="https://www.lantmateriet.se/korrektagranser" rel='noopener noreferrer' target="_blank">Kartredovisningen har inte
                        rättsverkan, jmfr mot beslut i lantmäterihandlingar.</a> © Lantmäteriet</p>
                </div>
                {/* eslint-disable-next-line react/forbid-elements */}
                <button ref="miniMap" className={classnames('mini-map', {'hidden': this.props.cropMode})}
                     title={`Klicka här för att byta till ${ortofotoActive ? 'karta' : 'flygfoto'} som bakgrund`} onClick={
                    this.mapController ? () => {
                      this.mapController.swapBackgroundMap()
                      document.getElementById('map-container').classList.toggle('dark-content')
                     this.forceUpdate()
                    } : undefined}>
                    <div id="miniMap"/>
                    <p>{ortofotoActive ? 'Visa karta' : 'Visa flygfoto'}</p>
                </button>
                <div className={classnames('cutout', {'hidden': !this.props.cropMode})}>
                    <div className="top"/>
                    <div className="left"/>
                    <div className="right"/>
                    <div className="bottom"/>
                </div>
                {this.props.children}
                {this.props.isLoadingFastighetsOmraden && <FullscreenLoading fullscreen={true}
                                                            hideContent={false}
                                                            text={'Laddar karta...'}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ansokanFastigheter: state.fastigheter.fastigheterList,
        fastigheter: state.map.fastigheter,
        center: state.map.center,
        zoom: state.map.zoom,
        newMap: state.map.newMap,
        drawingFeatures: state.map.drawingFeatures,
        fastighetFeatures: state.map.fastighetFeatures,
        fastighetFeaturesRaw: state.map.fastighetFeaturesRaw,
        activeTool: state.map.activeTool,
        isLoadingFastighetsOmraden: state.map.isLoadingFastighetsOmraden,
    }
};

const mapDispatchToProps = {
    changeCenterXY,
    uploadKartskiss,
    changeDrawingFeatures,
    changeFastighetFeatures,
    zoomTo,
    exportMapImage,
    selectTool,
    saveAnsokan,
    hideHelp: hide,
    displayModalConfirmDialog,
};

export default connect(
    mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true })(UnconnectedOLMap)
;