export function sortBy<T, K extends keyof T>(list: T[], ...keys: K[]): T[] {
    return list.sort((crnt, next): number => {
        for (let key of keys) {
            let result = 0
            if (crnt[key] instanceof Date && next[key] instanceof Date)
                // @ts-ignore
                result = new Date(crnt[key]).getTime() < new Date(next[key]).getTime() ? -1 : new Date(crnt[key]).getTime() > new Date(next[key]).getTime() ? 1 : 0
            else
                result = crnt[key] < next[key] ? -1 : crnt[key] > next[key] ? 1 : 0
            if (result)
                return result
        }
        return 0
    })
}

export function groupBy<T, K extends keyof T>(array: T[], key: K): Map<T[K], T[]> {
    const map = new Map<T[K], T[]>();
    array.forEach(item => {
        let itemKey = item[key];
        if (!map.has(itemKey)) {
            map.set(itemKey, array.filter(i => i[key] === item[key]));
        }
    });
    return map;
}