import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {visaMoment} from 'redux/modules/help'
// eslint-disable-next-line
import logo from '!file-loader!redet-atomic/dist/gfx/logotypes/lantmateriet-rgb.svg'
import {TermsOfUsageModal} from '../General/TermsOfUsageModal'
// eslint-disable-next-line
import selfservice_icon from '!file-loader!redet-atomic/dist/prerequisites/lm-icons/selfservice_black.svg'
import {BrowserUpdate} from '../General/BrowserUpdate'
// @ts-ignore
import {ButtonSub, ButtonTile, ModalDialogSub,
    ButtonToolbar,
    FeedbackModal,
    LinkButtonSub
} from 'redet-react-components'

import {LantmateriforattningfilmIlightbox} from '../ansokan/Lantmateriforattningfilm'
import {sattBetyg} from 'redux/modules/betyg'
import {RootState} from "../../store";
import {useLocation} from "react-router-dom";
import {Term} from 'views';
import {useAppDispatch} from 'hooks'
import LanguageSelector from 'components/General/LanguageSelector'
import {useTranslation} from 'react-i18next'
import {TermsOfUsageModalForSDG} from 'pages/euCitizenApplication/TermsOfUsageModalForSDG'
import {NotisButton} from "../notis/NotisButton";

type ContentList = {
    key: string
    moment: string
    text: string
}[]

interface Props {
    contentList: ContentList
    showHelp: boolean
    showFeedbackBtn: boolean
    showTermsBtn: boolean
    showNotiserBtn: boolean
    showMinSidaBtn: boolean
    showLanguageSelector: boolean
    showNormalToUModal: boolean
    showSDGToUModal: boolean
}

Navigation.defaultProps = {
    showHelp: true,
    showTermsBtn: true,
    showFeedbackBtn: true,
    contentList: undefined,
    showNotiserBtn: true,
    showMinSidaBtn: true,
    showLanguageSelector: false,
    showSDGToUModal: false,
    showNormalToUModal: true
}

export function Navigation(props: Props) {

    const [panelPlayer, setPanelPlayer] = useState(false)
    const [showTermsOfUsage, setShowTermsOfUsage] = useState(false)
    const [showLogOutModal, setShowLogOutModal] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false)

    const user = useSelector((store: RootState) => store.auth.user)
    const browserUpdateVisible = useSelector((store: RootState) => store.browserUpdate.browserUpdateVisible)

    const dispatch = useAppDispatch();
    const location = useLocation();

    const {t} = useTranslation();

    useEffect(() => {
        stickyMenu()
        document.addEventListener("keydown", escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    const stickyMenu = () => {
        const stickyMenu = 'sticky-menu'
        const navbarSelector = '.sticky-menu .navbar-2_0'
        const xWidth = document.getElementsByClassName('navbar-2_0')[0] ? document.getElementsByClassName('navbar-2_0')[0].clientWidth : 1024
        let yOffset = 100
        if (xWidth >= 768 && xWidth <= 1199) {
            yOffset = 132
        }
        if (xWidth >= 1200) {
            yOffset = 100
        }
        if (window.pageYOffset >= yOffset && document.getElementsByClassName(stickyMenu)[0]) {
            document.getElementsByClassName(stickyMenu)[0].classList.add('sticky-active')
            setTimeout(() => {
                if (document.querySelector(navbarSelector)) {
                    document.querySelector(navbarSelector)?.classList.add('hover-active')
                }
            }, 500)
        } else {
            if (document.querySelector(navbarSelector) && document.getElementsByClassName(stickyMenu)[0]) {
                document.getElementsByClassName(stickyMenu)[0].classList.remove('sticky-active')
                document.querySelector(navbarSelector)?.classList.remove('hover-active')
            }
        }
    }

    const isLoggedIn = () => {
        return user !== undefined
    }

    const escFunction = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            hideFeedback();
            hideToU();
        }
    }

    const displayMoment = (moment: string, title: string, event: React.MouseEvent) => {
        dispatch(visaMoment(moment, title, true));
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    const calculateMinSidaUrlForThisEnvironemnt = () => {
        switch (window.location.hostname) {
            case 'enak-tst.lantmateriet.se':
                return 'https://minasidor-tst.lantmateriet.se'
            case 'enak-ver.lantmateriet.se':
                return 'https://minasidor-ver.lantmateriet.se'
            default:
                return 'https://minasidor.lantmateriet.se'
        }
    }

    const userIsHandlaggare = () => {
        return !location.pathname.includes('fromtrossen')
    }

    const showLogOutModalFunction = (show = true) => {
        setShowLogOutModal(show);
    }

    const showPanelPlayer = () => {
        setPanelPlayer(true);
    }

    const hidePanelPlayer = () => {
        setPanelPlayer(false);
    }

    const hideFeedback = () => {
        setShowFeedback(false);
    }

    const skickaBetyg = (grade: number, feedbackText?: string) => {
        dispatch(sattBetyg(grade, feedbackText));
        hideFeedback();
    }

    const showFeedbackFunction = () => {
        setShowFeedback(true);
    }

    const hideToU = () => {
        setShowTermsOfUsage(false);
    }

    const showToU = () => {
        setShowTermsOfUsage(true);
    }

    const showLinkToMinSida = props.showMinSidaBtn && user && !user.isOrganization
    const calculateHrefFromLocation = () => {
        if(location.pathname.includes('/privat') || location.pathname.includes('/kvittens')) {
            return '/privat';
        } else if (location.pathname.includes('/org')) {
            return '/org';
        } else {
            return '/';
        }
    }

    const showHelpPanel = props.showHelp && props.contentList !== undefined
    return (
        <>

            {(browserUpdateVisible && userIsHandlaggare()) && <div id="browser-update-container">
                <BrowserUpdate/>
            </div>}
            <nav className="page-navigation" id="main-navigation">
                <div className="container">
                    <div className="navbar-support">
                        <ButtonToolbar>
                            <div className="button-group">
                                {showHelpPanel &&
                                    <>
                                        <div className="dropdown dropdown-tile">
                                            <ButtonTile
                                                text={t("navigation.help")} icon={'help_outline'} id="menu-help"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="true"/>
                                            <ul className="dropdown-menu">
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <li><a href="#" onClick={() => {
                                                    showPanelPlayer()
                                                }}>{t("navigation.info_clip")}</a></li>
                                                {props.contentList?.map(item =>
                                                    <li key={item.key}>
                                                        <a href={item.text} onClick={(event) => {
                                                            displayMoment(item.moment, item.text, event)
                                                        }}>{item.text}</a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        {panelPlayer &&
                                            <LantmateriforattningfilmIlightbox onButtonCloseClick={() => {
                                                hidePanelPlayer()
                                            }}/>}
                                    </>
                                }
                                <ButtonTile text={t("navigation.contact")}
                                            icon={'headset_mic'}
                                            id={'kontaktNavigationButtonId'}
                                            onClick={(event: React.MouseEvent) => {
                                                displayMoment('help-support', t("navigation.contact"), event)
                                            }}/>
                                <ButtonTile text={t("navigation.personal")}
                                            icon={'accessibility'}
                                            id={'personuppgiftNavigationButtonId'}
                                            onClick={(event: React.MouseEvent) => {
                                                displayMoment('help-personuppgift', t("navigation.personal"), event)
                                            }}/>
                                {props.showTermsBtn && <ButtonTile text={t("navigation.terms")} icon={'important_devices'} id={'anvandarvillkor'}
                                                                   onClick={showToU}/>}
                                {isLoggedIn() && props.showFeedbackBtn &&
                                    <ButtonTile text={t("navigation.feedback")} icon={'mood'} id={'feedbackButton'}
                                                onClick={showFeedbackFunction}/>}
                                <NotisButton/>
                            </div>
                        </ButtonToolbar>
                    </div>
                    <div className="navbar-logo">
                        {location.pathname.includes('/euCitizenApplication') &&
                            <div className="navbar-brand">
                                <img src={logo} alt={t("navigation.lm_logo")}/>
                            </div>
                        }
                        {!location.pathname.includes('/euCitizenApplication') &&
                            <a className="navbar-brand" href={calculateHrefFromLocation()} title={t("navigation.start_link")}>
                                <img src={logo} alt={t("navigation.lm_logo")}/>
                            </a>
                        }
                    </div>
                    <div className="navbar-support">
                        <ButtonToolbar>
                            {user && <div className="form-group logged-in">
                                <label className="label">{t("navigation.logged_in_as")}</label>
                                <p>{user.fullName}</p>
                            </div>}
                            {showLinkToMinSida &&
                                <a href={calculateMinSidaUrlForThisEnvironemnt()} title={t("navigation.min_sida_link")}>
                                    <ButtonTile icon={selfservice_icon} altText={t("navigation.min_sida_alt")} text={t("navigation.min_sida")}/>
                                </a>
                            }
                            {props.showLanguageSelector && <LanguageSelector/>}
                            {user &&
                                <>
                                    <ButtonTile
                                        text={t("navigation.logout")}
                                        icon={'logout'}
                                        ariaLabel={t("navigation.logout")}
                                        id="logoutButton"
                                        className="button-tile logout"
                                        onClick={showLogOutModalFunction}
                                    />
                                </>
                            }
                        </ButtonToolbar>
                    </div>
                </div>
            </nav>
            {showLogOutModal && <LogOutModal declineOnClick={() => setShowLogOutModal(false)}/>}
            {props.showNormalToUModal && showTermsOfUsage && <TermsOfUsageModal okButtonText={t("navigation.terms_accept")} okClick={hideToU}/>}
            {props.showSDGToUModal && showTermsOfUsage && <TermsOfUsageModalForSDG okButtonText={t("navigation.terms_accept")} okClick={hideToU}/>}
            {showFeedback &&
                <FeedbackModal onSubmit={(grade: number, feedbackText?: string) => { skickaBetyg(grade, feedbackText)}}
                               onCancel={() => { hideFeedback() }}
                               texts={{
                                   introduction: t("navigation.feedback_modal.intro"),
                                   paragraph: t("navigation.feedback_modal.paragraph"),
                                   textboxTitle: t("navigation.feedback_modal.textboxTitle"),
                                   gradeButton: t("navigation.feedback_modal.gradeBtn"),
                                   ariaLabelStarButton: t("navigation.feedback_modal.ariaLabelStarBtn"),
                                   grades: [
                                       {gradeDescription: t("navigation.feedback_modal.grades.gradeDescription1"), grade: t("navigation.feedback_modal.grades.grade1")},
                                       {gradeDescription: t("navigation.feedback_modal.grades.gradeDescription2"), grade: t("navigation.feedback_modal.grades.grade2")},
                                       {gradeDescription: t("navigation.feedback_modal.grades.gradeDescription3"), grade: t("navigation.feedback_modal.grades.grade3")},
                                       {gradeDescription: t("navigation.feedback_modal.grades.gradeDescription4"), grade: t("navigation.feedback_modal.grades.grade4")},
                                       {gradeDescription: t("navigation.feedback_modal.grades.gradeDescription5"), grade: t("navigation.feedback_modal.grades.grade5")},
                                   ],
                                   modalTitle: t("navigation.feedback_modal.title"),
                                   cancelButtonText: t("navigation.feedback_modal.cancelBtn"),
                                   description: <p className={'description'}>{t("navigation.feedback_modal.description")}<Term
                                       label={t("navigation.feedback_modal.link")} moment="help-feedback"/></p>
                               }}
                               isFocusTrapped={true} id={"feedback"} elementsToAllowFocus={['helpSidePanelId']}
                               aria-label={t("navigation.feedback_modal.aria")}/>}
        </>
    )
}


export const LogOutModal = (props: { declineOnClick: () => void }) => {

    const {t} = useTranslation();
    const user = useSelector((store: RootState) => store.auth?.user)

    function calculateLogoutUrlForThisEnvironemnt() {
        switch (window.location.hostname) {
            case 'localhost':
                return '/j_spring_security_logout'
            default:
                return '/logout'
        }
    }

    const logOutUrl = calculateLogoutUrlForThisEnvironemnt()
    return (
        <ModalDialogSub modalTitle={t("navigation.logout_modal.title")}
                        ingress={user?.isSdg ?
                            t("navigation.logout_modal.ingress_sdg") :
                            t("navigation.logout_modal.ingress")
                        }
                        useDefaultToolbar={false}
                        firstFocus={'logoutModalYesButtonId'}
                        closeModalCallback={props.declineOnClick}>
            <ButtonToolbar>
                <LinkButtonSub text={t("navigation.logout_modal.accept")} link={logOutUrl} icon={'check'} id={'logoutModalYesButtonId'}/>
                <ButtonSub text={t("navigation.logout_modal.decline")} icon={'clear'} onClick={props.declineOnClick}/>
            </ButtonToolbar>
        </ModalDialogSub>)
}

//withrouter implementera
export default Navigation;