import React from "react";
import {Navigate, RelativeRoutingType} from "react-router-dom";
import { useParams } from "react-router";
import {updateTo} from "./routeHelper";

// Allt kopierat från https://github.com/nirmaoz/react-router6-redirect

export interface RedirectProps {
    to: string;
    state?: any;
    relative?: RelativeRoutingType;
}

/**
 * Wraps the <Navigate> component and replaces "/:<paramName>" with "/<paramValue>"
 */
export const Redirect: React.FC<RedirectProps> = ({ to, ...rest }) => {
    const params = useParams();
    return <Navigate to={updateTo(to, params)} {...rest} replace />;
};