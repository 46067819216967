import Hjalp from "views/Hjalp/Hjalp";
import {FeedbackHelpContent} from "views/Hjalp/texter/FeedbackHelpContent";
import {KontaktaOssHelpContent} from "views/Hjalp/texter/KontaktaOssHelpContent";
import {PersonuppgifterHelpContent} from "views/Hjalp/texter/PersonuppgifterHelpContent";
//@ts-ignore
import {SidePanelChapter} from 'redet-react-components'
import {FullmaktHelpContent} from "../../views/Hjalp/texter/FullmaktHelpContent";

export default function HjalpForSDG() {
    return (
        <Hjalp>
            <SidePanelChapter name={"help-personuppgift"}>
                <PersonuppgifterHelpContent/>
            </SidePanelChapter>
            <SidePanelChapter name={"help-feedback"}>
                <FeedbackHelpContent/>
            </SidePanelChapter>
            <SidePanelChapter name={'help-support'}>
                <KontaktaOssHelpContent/>
            </SidePanelChapter>
            <SidePanelChapter name={'help-power-of-attorney'}>
                <FullmaktHelpContent/>
            </SidePanelChapter>
        </Hjalp>
    )
}