import {SkrivelseKvittensResponse, SkrivelseRequest} from "./model/skrivelse";
import {submit} from "./skrivelseRepository";
import {Fetcher} from "../../repository/fetcher";
import { RootState } from "store";

export function submitSkrivelse(skrivelse: SkrivelseRequest) : (dispatch: any, getState: () => RootState) => Promise<SkrivelseKvittensResponse> {
    return function action(dispatch: any, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState, false);
        return submit(fetcher, skrivelse).then((skrivelse : SkrivelseKvittensResponse) => skrivelse).catch((err) => {throw err});
    }
}
