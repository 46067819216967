import { Link } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { requireId } from '../../../helpers/customProptypes'

const commonLinkProps = {
  link: PropTypes.string.isRequired,
  active: PropTypes.bool,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  replaceUrl: PropTypes.bool,
  /**
   * Skicka state med ruttningen. Hamnar i 'props.location.state'.
   */
  state: PropTypes.object
}

/**
 * @deprecated Skapa en specialiserad komponent för 'button-top', 'button-sub',
 * 'button-top wizard-step'. Denna komponent skall inte tas bort, men ska bara användas i form av "arv"/vid komposition.
 */
export function LinkButtonRouter (props) {
  return (
    <Link className={classNames(props.class, { 'active': props.active }, { 'disabled': props.disable })}
          to={props.disable ? window.location.href : props.link}
          replace={props.replaceUrl}
          onClick={props.onClick}
          id={props.id}
          aria-label={props.ariaLabel}
          state={props.state}
    >
      {props.icon && <i aria-hidden={true} className="material-icons">{props.icon}</i>}
      {props.text}
    </Link>
  )
}

LinkButtonRouter.propTypes = {
  ...commonLinkProps,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  class: PropTypes.oneOf(['button-top', 'button-sub', 'button-top wizard-step']),
  onClick: PropTypes.func,
  disable: PropTypes.bool
}

export function IconLinkRouter ({
  disable,
  classname,
  ariaLabel,
  dataSeleniumId,
  icon,
  link,
  ariaLabelledBy,
  replaceUrl,
  active,
  state,
  ...rest
}) {
  return (
    <Link className={classNames(classname, { 'active': active })}
          to={{
            pathname: link,
            state: state
          }}
          replace={replaceUrl}
          aria-label={ariaLabel}
          data-selenium-id={dataSeleniumId}
          aria-labelledby={ariaLabelledBy}
          {...rest}>
      <i className="material-icons">{icon}</i>
    </Link>
  )
}

IconLinkRouter.propTypes = {
  ...commonLinkProps,
  icon: PropTypes.string.isRequired,
  dataSeleniumId: PropTypes.string,
  replaceUrl: PropTypes.bool,
  ariaLabelledBy: requireId,
}
IconLinkRouter.defaultProps = {
  classname: 'button-icon',
  replaceUrl: false
}
