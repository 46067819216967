import {BifogadFil} from "../domain/BifogadFil";
// @ts-ignore
import md5 from 'md5';

export function filesEqual(file1: File, file2: File) {
    const isEqual = (
        (file1.name === file2.name) &&
        (file1.size === file2.size) &&
        (file1.type === file2.type) &&
        (file1.lastModified === file2.lastModified)
    )
    return isEqual
}

export function sumAndFormatFileSize(fileList: BifogadFil[]) {
    const sizeInBytes = fileList.map(file => file.fileSize).reduce((result, currentValue) => result + currentValue)
    let formatedOutput

    if (sizeInBytes >= (1024 * 1024)) {
        formatedOutput = Math.round((sizeInBytes * 10) / (1024 * 1024)) / 10 + 'MB'
    } else if (sizeInBytes >= 1024) {
        formatedOutput = Math.round(sizeInBytes / 1024) + 'KB'
    } else {
        formatedOutput = sizeInBytes + 'B'
    }

    return formatedOutput
}

export function createFileHash(file:File) {
    return md5(`${file.name}${file.size}${file.type}${file.lastModified}`)
}
