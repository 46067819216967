import { CONTACT_TYPE_INDIVIDUAL, CONTACT_TYPE_ORGANIZATION } from 'redux/modules/contacts'
import { v4 as uuidv4 } from 'uuid'

export const mapFastighetsAgareToContact = (importeradeAgare, fastigheterIAnsokan) => {

  return importeradeAgare.map((agare) => {
    const newAgare = {
      id: uuidv4(),
      identifier: agare.identifier
    }

    switch (agare.agareTyp) {
      case 'privatperson':
        newAgare.type = CONTACT_TYPE_INDIVIDUAL
        newAgare.data = {
          namn: agare.namn,
          personnummer: agare.personnummer,
          imported: true,
        }
        break
      case 'organisation':
        newAgare.type = CONTACT_TYPE_ORGANIZATION
        newAgare.data = {
          orgNamn: agare.organisationsnamn,
          orgNummer: agare.organisationsnummer,
          imported: true,
        }
        break
      default:
        throw new Error('Cannot map agarinformation to contacts: unknown agareTyp')
    }

    switch (agare.agandeTyp) {
      case 'lagfart':
        if (agare.landTyp === 'utlandsk' && !agare.personnummer)
          newAgare.data = { ...newAgare.data, rollAnnanBerord: true }
        else
          newAgare.data = { ...newAgare.data, rollSokande: true }
        break
      case 'tomtratt':
        newAgare.data = { ...newAgare.data, rollAnnanBerord: true }
        break
      default:
        throw new Error('Cannot map agarinformation to contacts: unknown agandeTyp')
    }

    newAgare.data = {
      ...newAgare.data,
      representerarFastigheter: agare.objektidentiteter.map(objekt => {
        const fastighet = fastigheterIAnsokan.filter(fast => fast.data.objektidentitet === objekt)[0]
        return { registerbeteckning: fastighet.registerbeteckning, objektidentitet: fastighet.data.objektidentitet }
      })
    }

    if (agare.adress) {
      if (agare.adress.utdelningsadress) newAgare.data.adress = agare.adress.utdelningsadress
      if (agare.adress.postnummer) newAgare.data.postnummer = agare.adress.postnummer
      if (agare.adress.postort) newAgare.data.postort = agare.adress.postort
      if (agare.adress.co) newAgare.data.adress = `c/o ${agare.adress.co}, ${agare.adress.utdelningsadress}`
      if (agare.adress.land) newAgare.data.land = agare.adress.land
    }

    if(agare.fornamn || agare.efternamn) {
      const fullName = agare.fornamn + " " + agare.efternamn
      newAgare.data.namn = fullName.trim();
    }

    return newAgare
  })
}
