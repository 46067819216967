import {
  email,
  integer,
  integerAndHypen,
  maxLength,
  oneInteger,
  oneMustBeFilled,
  oneMustBeTrue,
  required,
  swedishSocialSecurityNumber,
  samordningsNumber
} from './validation'
import { individualContactFieldValidations } from '../views/Contacts/Individual/individualContactFormValidations'
import { getAllContactsWhoHaveOmbudRegardlessRollSokande, harOmbud } from './contactsHelper'
import {
  noSokandeSelectedForForetraddErrorMessage,
  noSokandeSelectedForForetraddOrganizationErrorMessage
} from '../views/Contacts/Common/commonContactFieldsValidations'

export const individualPersonnummerValidation = (formValues) => {
  if (formValues.rollSokande && (formValues.rollOmbud === undefined || formValues.rollOmbud === false)) {
    individualContactFieldValidations.personnummer = [integerAndHypen, swedishSocialSecurityNumber]
    individualContactFieldValidations.IDChoice = oneMustBeFilled(['personnummer', 'ovrigtIdNummer', 'samordningsnummer'])
  } else if (formValues.rollOmbud === true) {
    individualContactFieldValidations.personnummer = [required, swedishSocialSecurityNumber]
    } else {
    individualContactFieldValidations.IDChoice = []
    individualContactFieldValidations.personnummer = [integerAndHypen, swedishSocialSecurityNumber]
  }
}

export const individualEmailValidation = (formValues) => {
  if (formValues.rollSokande) {
    individualContactFieldValidations.epost = [email, maxLength(100)]
  }
  if (formValues.rollUpprattare || formValues.rollOmbud) {
    individualContactFieldValidations.epost = [required, email, maxLength(100)]
  } else {
    individualContactFieldValidations.epost = [email, maxLength(100)]
  }
}

 export const individualIdNumberValidation = (formValues) => {
  if (formValues.rollSokande && (formValues.rollOmbud === undefined || formValues.rollOmbud === false)) {

    if (formValues.IDChoice === 'ovrigtIdNummer'){

      individualContactFieldValidations.ovrigtIdNummer = [required, oneInteger]
      individualContactFieldValidations.personnummer = []
      individualContactFieldValidations.samordningsnummer = []

    } else if(formValues.IDChoice === 'personnummer'){

      individualContactFieldValidations.personnummer = [required, swedishSocialSecurityNumber]
      individualContactFieldValidations.ovrigtIdNummer = []
      individualContactFieldValidations.samordningsnummer = []

    } else if(formValues.IDChoice === 'samordningsnummer'){

      individualContactFieldValidations.samordningsnummer = [required, samordningsNumber]
      individualContactFieldValidations.ovrigtIdNummer = []
      individualContactFieldValidations.personnummer = []

    }
  } else {
    individualContactFieldValidations.IDChoice = []
    individualContactFieldValidations.ovrigtIdNummer = [oneInteger]
    individualContactFieldValidations.samordningsnummer = [samordningsNumber]    
  }
}

export const SokandeCheckboxMustBeTrueIfContactHasOmbud = (formValues, contactList, contactId, validations) => {
  let listForetradda = getAllContactsWhoHaveOmbudRegardlessRollSokande(contactList)
  if (listForetradda.length > 0) {
    let contact = listForetradda.find(kontakt => kontakt.id === contactId)
    if (contact !== undefined) {
      if (formValues.rollSokande === false || !formValues.rollSokande) {
        validations.rollSokande.push(oneMustBeTrue(['rollSokande'], formValues.orgNamn ? noSokandeSelectedForForetraddOrganizationErrorMessage : noSokandeSelectedForForetraddErrorMessage))
      }
    }
  }
}

export const sokandeOrganizationMustHaveOmbud = (formValues, contactList, contactId, organizationContactFieldWarningValidation) => {
  if (formValues.rollSokande === true) {
    if (!harOmbud(contactList, contactId)) {
      organizationContactFieldWarningValidation.rollSokande = [() => ('Organisationer som är sökande måste företrädas av någon')]
    } else {
      organizationContactFieldWarningValidation.rollSokande = []
    }
  }
}
