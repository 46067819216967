import { SIGNINGINSTANCE_STATUS } from "domain/signing/SigningStatus";
import {KOMPLETTERINGSTATUS, KOMPLETTERINGTYP, Komplettering} from "../../domain/Komplettering";
export const fakedLoadingTime = 500

export const loadTestDataKompletteringList: Komplettering[] = [
  {
    kompletteringId: '1337',
    status: KOMPLETTERINGSTATUS.SIGNERAS,
    typ: KOMPLETTERINGTYP.BITRADANDE,
    handlingTyp: 'BYGGLOV_ELLER_FORHANDSBESKED',
    createdByUserPersonnummer: '198712067530',
    createdByUserFullName: 'Testan Testsson',
    skapad: "12312321",
    inskickadDatum: "12321321",
    signingStatus: {
      status: SIGNINGINSTANCE_STATUS.PENDING,
      dokumentNamn: "123",
      signerStatus: []
    }
  },
  {
    kompletteringId: '4343',
    status: KOMPLETTERINGSTATUS.SIGNERAS,
    typ: KOMPLETTERINGTYP.YRKANDE,
    handlingTyp: 'AVTAL',
    createdByUserPersonnummer: '198712067530',
    createdByUserFullName: 'Testan Testsson',
    skapad: "12312321",
    inskickadDatum: "12321321",
    signingStatus: {
      status: SIGNINGINSTANCE_STATUS.PENDING,
      dokumentNamn: "345",
      signerStatus: []
    }
  },
  {
    kompletteringId: '1557',
    status: KOMPLETTERINGSTATUS.SIGNERAS,
    typ: KOMPLETTERINGTYP.YTTERLIGARE_HANDLING,
    handlingTyp: 'FORVARVSTILLSTAND',
    createdByUserPersonnummer: '198712067530',
    createdByUserFullName: 'Testan Testsson',
    skapad: "12312321",
    inskickadDatum: "12321321",
    signingStatus: {
      status: SIGNINGINSTANCE_STATUS.PENDING,
      dokumentNamn: "654",
      signerStatus: []
    }
  },
]

export function fakeLoadKompletteringar () {
  return new Promise((resolve) => {
    setTimeout(() => resolve(loadTestDataKompletteringList), fakedLoadingTime)
  })
}

export const loadTestDataSigneringStatusSucceeded =
  {
    status: 'SUCCEEDED',
    dokumentNamn: 'bygglov_andra',
    signerStatus: [
      {
        status: 'APPROVED',
        signerEmail: 'testsson@test.se',
        signingUrl: 'http://www.google.se',
      },
    ]
  }

export let loadTestDataSigneringStatusPending =
  {
    status: 'PENDING',
    dokumentNamn: 'ovrigt',
    signerStatus: [
      {
        status: 'NONE',
        signerEmail: 'klk@test.se',
        signingUrl: 'http://www.google.se/dod'
      },
    ]
  }

export const loadTestDataSigneringStatusDeclined =
  {
    status: 'TIMED_OUT',
    dokumentNamn: 'ovrigt',
    signerStatus: [
      {
        status: 'DECLINED',
        signerEmail: 'ton@test.se',
        signingUrl: 'http://www.google.se'
      },
    ]
  }

export const loadTestDataSigneringStatusTimedOut =
  {
    status: 'TIMED_OUT',
    dokumentNamn: 'ovrigt',
    signerStatus: [
      {
        status: 'DECLINED',
        signerEmail: 'rick@test.se',
        signingUrl: 'http://www.google.se'
      },
    ]
  }

export function fakeLoadSigningStatus (kompletteringId: string) {
  if (kompletteringId === '1337') {
    return new Promise(resolve => {
      setTimeout(() => resolve(loadTestDataSigneringStatusDeclined), 500)
    })
  } else if (kompletteringId === '1447') {
    return new Promise(resolve => {
      setTimeout(() => resolve(loadTestDataSigneringStatusPending), 500)
    })
  } else if (kompletteringId === '1557') {
    return new Promise(resolve => {
      setTimeout(() => resolve(loadTestDataSigneringStatusDeclined), 500)
    })
  } else if (kompletteringId === '4343') {
    return new Promise(resolve => {
      setTimeout(() => resolve(loadTestDataSigneringStatusTimedOut), 500)
    })
  }
}

export let count = 100

export function fakeUploadKompletteringYtterligareHandling () {

  loadTestDataKompletteringList.push(
    {
      kompletteringId: '1447',
      status: KOMPLETTERINGSTATUS.SIGNERAS,
      typ: KOMPLETTERINGTYP.YRKANDE,
      handlingTyp: 'FORVARVSHANDLING',
      createdByUserPersonnummer: '198712067530',
      createdByUserFullName: 'Testan Testsson',
      skapad: "12312321",
      inskickadDatum: "12321321",
      signingStatus: {
        status: SIGNINGINSTANCE_STATUS.PENDING,
        dokumentNamn: "987",
        signerStatus: []
      }
    }
  )

  const promise = new Promise<string>(resolve => {
    setTimeout(() => resolve('1447'), fakedLoadingTime)
  })
  count++
  return promise
}

export function fakeRemoveKomplettering (kompletteringId: string) {

  const pos = loadTestDataKompletteringList.map((komplettering: Komplettering) => komplettering.kompletteringId).indexOf(kompletteringId)
  loadTestDataKompletteringList.splice(pos, 1)

  return new Promise(resolve => {
    setTimeout(resolve, fakedLoadingTime)
  })
}

export function fakeSignStart () {
  return new Promise(resolve => {
    setTimeout(resolve, fakedLoadingTime)
  })
}

export function fakeSignAbort () {
  const loadTestDataSigneringStatusPending = [
    {
      status: 'TIMED_OUT',
      dokumentNamn: 'ovrigt',
      signerStatus: [
        {
          status: 'DECLINED',
          signerEmail: 'klk@test.se',
          signingUrl: 'http://www.google.se/dod'
        },
      ]
    }
  ]
  return new Promise(resolve => {
    setTimeout(() => resolve(loadTestDataSigneringStatusPending), fakedLoadingTime)
  })
}

