import { ANSOKAN_FORM_DESTROY } from './modules/sharedActions'

export const ANSOKAN_LOAD_SUCCESSFUL = 'ansokanRepository/LOAD_SUCCESSFUL'

const initialState = {
  fastighetsforandring: {},
  data: undefined,
  bifogadehandlingar: undefined,
  klyvning: undefined,
  avstyckning3d: undefined,
  ovrigBeskrivning: undefined,
  avstyckning: undefined,
  fastighetsreglering: undefined,
  sammanlaggning: undefined,
  gransutmarkning: undefined,
  fastighetsbestamning: undefined
}

export default function reducer (state = initialState, action:any = {}) {
  if (action.type === ANSOKAN_LOAD_SUCCESSFUL) {
    return {
      ...state,
      bifogadehandlingar: action.result.data.bifogadehandlingar,
      klyvning: action.result.data.klyvning,
      avstyckning3d: action.result.data.avstyckning3d,
      ovrigBeskrivning: action.result.data.ovrigBeskrivning,
      fastighetsforandring: action.result.data.fastighetsforandring,
      avstyckning: action.result.data.avstyckning,
      fastighetsreglering: action.result.data.fastighetsreglering,
      sammanlaggning: action.result.data.sammanlaggning,
      gransutmarkning: action.result.data.gransutmarkning,
      fastighetsbestamning: action.result.data.fastighetsbestamning
    }
  } else if (action.type === ANSOKAN_FORM_DESTROY)
    return initialState
  else {
    return state
  }
}

