import { Term } from 'views'
import AddressForm from './AddressForm'
import { CheckboxFinalForm, Condition, TextInputFinalForm } from '../../General/forms/finalForm'
//@ts-ignore
import { Column, FormFieldset, Row } from 'redet-react-components'

type Props = {
  contactTypeIsOrganization: boolean,
  formAutocomplete?: boolean
}

export default function InvoicingForm (props: Props) {
  return (
    <Row>
      <Column columns="col-xs-12 col-sm-6">
        <br/>
        <FormFieldset title="Faktureringsadress">
          {/* @ts-ignore */}
          <CheckboxFinalForm label={'Ange separat faktureringsadress'} field={'fakturaAdressAngiven'}
                             term={<Term
                               label={props.contactTypeIsOrganization ? 'separat faktureringsadress' : 'faktureringsadress'}
                               moment="help-contactinformation" term="term-separatFaktureringsadress"/>}/>
          {/* @ts-ignore */}
          <Condition when={'fakturaAdressAngiven'} is={true}>
            <AddressForm fields={{
              adress: 'adressFaktura',
              postnummer: 'postnummerFaktura',
              postort: 'postortFaktura',
              land: 'landFaktura'
            }}
                         fieldsAreNotRequired={false}
                         formAutocomplete={props.formAutocomplete}
                         formAutocompletePrefix={'section-faktura '}>
              {/* @ts-ignore */}
              <TextInputFinalForm field={'fakturaMarkning'} label={'Märkning'}/>
            </AddressForm>
          </Condition>
        </FormFieldset>
      </Column>
      <Column columns="col-xs-12 col-sm-6">
        <br/>
        <FormFieldset title={'Kostnadsfördelning'}
                      description={<>Läs mer om <Term label="Kostnadsfördelning"
                                                      moment="help-contactinformation"
                                                      term="term-kostnadsfordelning"/></>}>
          {/* @ts-ignore */}
          <TextInputFinalForm field={'andelAvForattningskostnaden'} label={'Andel av förrättningskostnaden'}
                              maxLength={150}/>
        </FormFieldset>
      </Column>
    </Row>
  )
}

