import {useEffect, useState} from 'react'
import {getLocalizedDateString} from '../../helpers/dates'
// @ts-ignore
import {ListGroup, ListItem, VarningsRuta} from 'redet-react-components'
import {LantmatarDagbokAnteckning} from "../../domain/Forratningsarende/LantmatarDagbokAnteckning";
import {ARENDE_LIST_PAGE_SIZE} from "../../helpers/KompletteringHelper";

interface Props {
    title?: string,
    dagboksanteckningar?: LantmatarDagbokAnteckning[]
}

export function ArendeDagboksanteckningarList({title, dagboksanteckningar, ...rest}: Props) {

    const [initList, setInitList] = useState<LantmatarDagbokAnteckning[]>([])
    const [dagboksanteckningarList, setDagboksanteckningarList] = useState<LantmatarDagbokAnteckning[]>([])

    const listGroupOptions = {
        list: initList,
        listSetter: setDagboksanteckningarList,
        sortConfig: initList.length > 1 ? {
            text: 'Sortering',
            options: [{id: 'date', key: 'date', text: 'Datum', initial: true, desc: true}]
        } : undefined,
        paginationConfig: {
            initPage: 0,
            maxNoPageButtons: 5,
            pageSize: ARENDE_LIST_PAGE_SIZE,
            onPageChangeScrollToTopOfList: true
        }
    }

    useEffect(() => {
        setInitList(dagboksanteckningar?.slice() ?? [])
    }, [dagboksanteckningar])

    return (<>
        <ListGroup id={'section-arende-dagboksanteckningar'}
                   title={title ?? ''}
                   description={<p>
                       Här visas handläggarens löpande anteckningar i ärendet. Noteringar om bl.a. arbetsmoment, vilka handlingar som kommit in eller upprättats och beslut som
                       tagits. Korrigering av stavfel och liknande rättelser syns inte i det publicerade dagboksbladet.
                   </p>}
                   listOptions={listGroupOptions}
                   {...rest}>
            {dagboksanteckningarList.map(anteckning =>
                <ListItem header={getLocalizedDateString(anteckning.date, 'd MMMM y')}
                          key={`dagbok_key_${Math.random()}`}
                          description={anteckning.anteckning}
                          hasTableActionButtons={false}
                          descriptionChildren={<>
                              {anteckning.akt &&
                                  <span className={'description'}>
                                          Aktbilaga <span className={'item'}>{anteckning.akt}</span>
                                      </span>
                              }
                          </>}
                />)}

            {initList.length === 0 &&
                <section className='content'>
                    <p>
                        <span className='additional_information'>Det finns inga dagboksanteckningar för det här ärendet.</span>
                    </p>
                </section>
            }
        </ListGroup>
        {initList.length > 0 && <>
            <br/>
            <VarningsRuta ariaTitle={'Meddelande'}
                          warningMessages={['Dagboksanteckningar i ärenden påbörjade 2013 eller tidigare visas inte fullständigt i denna tjänst.']}/>
        </>}
    </>)
}

export default ArendeDagboksanteckningarList