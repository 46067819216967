import {ReactNode} from 'react'
import {LinkButtonSub, ListItem, ListItemActionButton} from 'redet-react-components'
import {SIGNER_STATUS, SIGNINGINSTANCE_STATUS} from 'domain/signing/SigningStatus'
import SigningHelper from "../../helpers/SigningHelper";


type Props = {
    name?: string,
    status: SIGNER_STATUS,
    signingUrl: string,
    email?: string,
    hideSigningUrl?: boolean,
    signedByUpprattare?: boolean,
    showModalCallback: (signingUrl: string) => void,
    signingStatus: SIGNINGINSTANCE_STATUS,
    additionalSignerInfo?: ReactNode
}

KompletteringSignerStatus.defaultProps = {
    hideSigningUrl: false
}
export function KompletteringSignerStatus(props: Props) {
    
    function getIdFromLink() {
        return props.signingUrl.split('=')[1]
    }

    function changeSigningUrlStartPageAndGoToInloggadPage(url: string) {
        let resUrl
        resUrl = url.replace('?id=', 'sign/')
        return resUrl
    }

    function getStatusObject(status: SIGNER_STATUS, signingStatus: SIGNINGINSTANCE_STATUS) {
        return SigningHelper.getSignerListItemStatus(status, signingStatus)
    }

        let actionButtons
        if (props.signedByUpprattare) {
            actionButtons = props.status === SIGNER_STATUS.NONE && props.hideSigningUrl === false ?
                [<LinkButtonSub key={`${getIdFromLink()}_inbjudanlank`}
                                id={`${getIdFromLink()}_skrivunder`}
                                text={'Skriv under'}
                                data-selenium-id={`skrivUnderButtonForEmail${props.email}Id`}
                                icon={'edit_note'}
                                target={'_blank'}
                                link={changeSigningUrlStartPageAndGoToInloggadPage(props.signingUrl)}
                                aria-label={'Skriv under ansökan'}
                />,
                    <ListItemActionButton key={`${getIdFromLink()}_inbjudanlank`}
                                          id={`${getIdFromLink()}_inbjudanlank`}
                                          data-selenium-id={`inbjudanLankForEmail${props.email}Id`}
                                          icon={'link'}
                                          ariaLabel={'Visa inbjudningslänk'}
                                          popover={{text: 'Visa inbjudningslänken'}}
                                          onClick={() => props.showModalCallback(props.signingUrl)}/>] : undefined
        } else {
            actionButtons = props.status === SIGNER_STATUS.NONE && props.hideSigningUrl === false ?
                [<ListItemActionButton key={`${getIdFromLink()}_inbjudanlank`}
                                       id={`${getIdFromLink()}_inbjudanlank`}
                                       data-selenium-id={`inbjudanLankForEmail${props.email}Id`}
                                       icon={'link'}
                                       ariaLabel={'Visa inbjudningslänk'}
                                       popover={{text: 'Visa inbjudningslänken'}}
                                       onClick={() => props.showModalCallback(props.signingUrl)}/>] : undefined
        }
        return (<>
                <ListItem hasTableActionButtons={true}
                          header={props.name ?? ''}
                          description={props.additionalSignerInfo ? <>{props.email}<br/>{props.additionalSignerInfo}</> : props.email}
                          id={getIdFromLink()}
                          actionButtons={actionButtons}
                          status={getStatusObject(props.status, props.signingStatus)}/>
            </>
        )
}


export default (KompletteringSignerStatus)