import classNames from 'classnames'
import {Navigation} from 'components'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {Ansokan, Fastighet} from 'domain/Ansokan'
// @ts-ignore
import {useAppDispatch, useAppSelector} from 'hooks'
//@ts-ignore
import {ModalDialogSub, SidePanelChapter} from 'redet-react-components'
import {loadAnsokan, saveAnsokan} from 'redux/modules/ansokanCRUD'
import {endProgress} from 'redux/modules/fullscreenLoading'
import {discardChanges} from 'redux/modules/map'
import {ActionBar} from 'views'
import {FeedbackHelpContent} from 'views/Hjalp/texter/FeedbackHelpContent'
import {JumpLinkToMain} from '../components/General/JumpLinkToMain'
import SavingIndicator from '../components/General/SavingIndicator'
import MapControl from '../components/Map/MapControl'
import {NO_SCROLLING_CSS_CLASS, ROOT_BODY_CSS_CLASS} from '../utils/globalConstants'
import BifogadeHandlingar from '../views/BifogadeHandlingar/BifogadeHandlingar'
import Contacts from '../views/Contacts/Contacts'
import Fastighetsforandring from '../views/Fastighetsforandring/Fastighetsforandring'
import Hjalp from '../views/Hjalp/Hjalp'
import {KontaktaOssHelpContent} from '../views/Hjalp/texter/KontaktaOssHelpContent'
import {PersonuppgifterHelpContent} from '../views/Hjalp/texter/PersonuppgifterHelpContent'
import {BifogadeHandlingarHelpContent} from '../views/Hjalp/texter/bilagor/BifogadeHandlingarHelpContent'
import {FastighetHelpContent} from '../views/Hjalp/texter/fastighet/FastighetHelpContent'
import {ForandringHelpContent} from '../views/Hjalp/texter/forandringen/ForandringHelpContent'
import {KartaHjalpCropping} from '../views/Hjalp/texter/karta/KartHjalpCropping'
import {KartHjalpDrawing} from '../views/Hjalp/texter/karta/KartHjalpDrawing'
import {ContactsHelpContent} from '../views/Hjalp/texter/kontaktuppgifter/ContactsHelpContent'
import {SammanfattningHelpContent} from "../views/Hjalp/texter/ovrigt/SammanfattningHelpContent"
import {PaymentsHelpContent} from '../views/Hjalp/texter/payment/PaymentsHelpContent'
import Kartskiss from '../views/Karta/Kartskiss'
import ValidationChecklistSidePanel from '../views/validation/ValidationChecklistSidePanel'
import {destroyAnsokanForm} from 'redux/modules/sharedActions'
import {RootState} from "../store";
import FastighetAndAtgardVal from "../views/Fastighetsval/FastighetAndAtgardVal";
import {OmTjansten} from "../views/Hjalp/texter/OmTjansten/omTjansten";
import {Sammanfattning} from "../components/ansokan/sammanfattning/Sammanfattning";
import {Redirect} from "../helpers/Redirect";

type Props = {
  ansokanId: string
  match: {}
}

export function AnsokanForm (props: Props) {

  const [showContinueWithKartskissModal, setShowContinueWithKartskissModal] = useState(false);

  const fastigheterList: Fastighet[] = useAppSelector((store: RootState) => store.fastigheter?.fastigheterList);

  // @ts-ignore
  let avstyckning = useAppSelector((store: RootState) => store.form.fastighetsforandring.avstyckning);
  // @ts-ignore
  let sammanlaggning = useAppSelector((store: RootState) => store.form.fastighetsforandring.sammanlaggning);
  // @ts-ignore
  let fastighetsreglering = useAppSelector((store: RootState) => store.form.fastighetsforandring.fastighetsreglering);
  // @ts-ignore
  let fastighetsbestamning = useAppSelector((store: RootState) => store.form.fastighetsforandring.fastighetsbestamning);
  // @ts-ignore
  let gransutmarkning = useAppSelector((store: RootState) => store.form.fastighetsforandring.gransutmarkning);
  // @ts-ignore
  let klyvning = useAppSelector((store: RootState) => store.form.fastighetsforandring.klyvning);

  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.classList.remove(NO_SCROLLING_CSS_CLASS)

    resize()

    window.onresize = function funcresize () {
      resize()
    }
    reloadAnsokan();
  },[])

  useEffect(() => {
    return () => {
      dispatch(destroyAnsokanForm());
    }
  },[])

  const isRouteSkapakartskiss = () => {
    return location.pathname.includes("skapakartskiss");
  }

  const resize = () => {
    const width = window.innerWidth;
    if ((width > 768) && (width < 1200)) {
      document.getElementById(ROOT_BODY_CSS_CLASS)?.setAttribute('data-offset', '200')
    }
    if (width > 1200) {
      document.getElementById(ROOT_BODY_CSS_CLASS)?.setAttribute('data-offset', '160')
    }
  }

  const reloadAnsokan = () => {
    dispatch(loadAnsokan(props.ansokanId)).then((ansokan: Ansokan) => {
      goToLastSavedWizardState(ansokan)

      if (ansokan.data.map.isEditingMap) {
        setShowContinueWithKartskissModal(true)
      }

      setTimeout(() => dispatch(endProgress()), 500)
    })
  }


  function goToLastSavedWizardState(ansokan: Ansokan) {
    const lastSavedTabPath = ansokan?.data?.wizardState

    if (lastSavedTabPath !== undefined && lastSavedTabPath !== "" && lastSavedTabPath !== null) {
      navigate(lastSavedTabPath, {replace: true})
    } else {
      navigate("start", {replace: true})
    }
  }

  const showProperties = () => {
    let maxNumberOfShownProperties = 3;

    let fastigheter = ""

    fastigheterList.map((fastighet: Fastighet, index) => {
      if(index <= maxNumberOfShownProperties) {
        fastigheter += fastighet.registerbeteckning
        fastigheter += fastigheterList.length-1 !== index ? ", ": ""
      }
    })

    return fastigheter;
  }

  const declineContinueWithKartskiss = () => {
    setShowContinueWithKartskissModal(false);
    dispatch(discardChanges());
    if(location.pathname.includes("skapakartskiss")){
      navigate("/privat/ansokan/" +props.ansokanId + "/kartskiss", {replace: true});
    }
    setTimeout(() => dispatch(endProgress()), 500)
  }

  const continueWithKartskiss = () => {
    setShowContinueWithKartskissModal(false);
    if(!location.pathname.includes("skapakartskiss")){
      navigate("/privat/ansokan/" + props.ansokanId + "/skapakartskiss", {replace: true});
    }
    setTimeout(() => dispatch(endProgress()), 500)
  }

  const contentList = [
    { key: 'start', moment: 'help-selection', text: 'Fastighet' },
    { key: 'forandring', moment: 'help-description', text: 'Åtgärder' },
    { key: 'bifoga handlingar', moment: 'help-upload', text: 'Bifoga handlingar' },
    { key: 'kontaktuppgifter', moment: 'help-contactinformation', text: 'Kontaktuppgifter' },
    { key: 'konstader', moment: 'help-payments', text: 'Kostnader' }
  ]

  const WizardSectionLink = (props: { text: string, sektion: string }) =>
  {
    /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
    return (<li
        className={classNames({'active':location.pathname.endsWith(props.sektion)})}
        onClick={() => setTimeout(() => dispatch(saveAnsokan()), 1000)}>
      <Link to={props.sektion} id={`sektion${props.sektion}`}>{props.text}</Link>
    </li>)
  }
  const atgardAndFastighetPageActive = window.location.pathname.match("/start");
  let moreThanThreeProperties = fastigheterList.length > 3;
  return (
    <>
    <Routes>
      <Route path={`skapakartskiss`} element={
        <MapControl ansokanId={props.ansokanId}
                    discardChanges={() => dispatch(discardChanges())}/>}/>
      <Route path={"*"} element={
        <React.Fragment>
          <JumpLinkToMain/>
          <header>
            {!isRouteSkapakartskiss() && <Navigation showHelp={true} contentList={contentList}/>}

          </header>
          <main id="ansokanFormSectionId">
            <div className="container">
              {!atgardAndFastighetPageActive &&
                  <>
                    <h1 data-selenium-id="atgard">{avstyckning ? "Avstyckning " : null}
                      {fastighetsbestamning ? "Fastighetsbestämning " : null}
                      {fastighetsreglering ? "Fastighetsreglering " : null}
                      {klyvning ? "Klyvning " : null}
                      {sammanlaggning ? "Sammanläggning " : null}
                      {gransutmarkning ? "Gränsutmärkning " : null}</h1>
                          <p>Ansökan gäller: <strong>{showProperties()}</strong>{moreThanThreeProperties && " m.fl"}</p>
                          <p><i>Observera att du inte kan ändra åtgärd eller fastigheter i en pågående ansökan. Vill du ansöka om något annat eller göra en förrättning med andra fastigheter skapar du en ny ansökan.</i></p>
                  </>
              }
            </div>
            <div className="container">
              <div className="wizard" id="ansokanFormWizardId">
                {!atgardAndFastighetPageActive &&
                    <ul className="step-visualization full-width" id="wizardForumlarDelarId">
                      <WizardSectionLink sektion={'forandringar'} text={'Detaljer'}/>
                      <WizardSectionLink sektion={'kartskiss'} text={'Kartskisser'}/>
                      <WizardSectionLink sektion={'kontakter'} text={'Kontaktuppgifter'}/>
                      <WizardSectionLink sektion={'handlingar'} text={'Handlingar'}/>
                      <WizardSectionLink sektion={'sammanfattning'} text={'Sammanfattning'}/>
                    </ul>
                }
                <Routes>
                  <Route path={`start`} element={<FastighetAndAtgardVal ansokanId={props.ansokanId}/>}/>
                  <Route path={`forandringar`}
                         element={<Fastighetsforandring/>}/>
                  <Route path={`kartskiss`} element={
                    <Kartskiss ansokanId={props.ansokanId} navigate={navigate}/>}/>
                  <Route path={`kontakter`} element={<Contacts/>}/>
                  <Route path={`handlingar`} element={<BifogadeHandlingar ansokanId={props.ansokanId}/>}/>
                  <Route path={`fortydligande`} element={<Redirect to={`../sammanfattning`} />}/>
                  <Route path={`sammanfattning`} element={<Sammanfattning/>}/>
                </Routes>
              </div>
              {!isRouteSkapakartskiss() && <ActionBar saveAnsokanOnClose={true}/>}
            </div>
          </main>
        </React.Fragment>}>

      </Route>
      </Routes>
        {!isRouteSkapakartskiss() && <SavingIndicator/>}
        {showContinueWithKartskissModal &&
          <ContinueWithKartskissModal closeModalCallback={() => continueWithKartskiss()}
                                      onClickKastaBortKnapp={() => declineContinueWithKartskiss()}/>
        }
        <Hjalp>
          {OmTjansten}
          <SidePanelChapter name={'help-description'}>
            {ForandringHelpContent}
          </SidePanelChapter>
          <SidePanelChapter name={'help-selection'}>
            {FastighetHelpContent}
          </SidePanelChapter>
          <SidePanelChapter name={'help-upload'}>
            {BifogadeHandlingarHelpContent}
          </SidePanelChapter>
          <SidePanelChapter name={'help-contactinformation'}>
            {ContactsHelpContent}
          </SidePanelChapter>
          <SidePanelChapter name={'help-payments'}>
            {PaymentsHelpContent}
          </SidePanelChapter>
          <SidePanelChapter name={'help-map'}>
            {KartHjalpDrawing}
          </SidePanelChapter>
          <SidePanelChapter name={'help-map-crop'}>
            {KartaHjalpCropping}
          </SidePanelChapter>
          <SidePanelChapter name={'help-support'}>
            <KontaktaOssHelpContent/>
          </SidePanelChapter>
          <SidePanelChapter name={'help-personuppgift'}>
            <PersonuppgifterHelpContent/>
          </SidePanelChapter>
          <SidePanelChapter name={'help-sammanfattning'}>
            {SammanfattningHelpContent}
          </SidePanelChapter>
          <SidePanelChapter name={'help-feedback'}>
            <FeedbackHelpContent/>
          </SidePanelChapter>
        </Hjalp>
        {!isRouteSkapakartskiss() && !location.pathname.includes("start") &&
          <ValidationChecklistSidePanel id="validationSidePanelId"/>
        }
        </>
  )
}

export function ContinueWithKartskissModal (props: { closeModalCallback: () => void, onClickKastaBortKnapp: () => void }) {
  return <ModalDialogSub modalTitle={'Fortsätta arbeta med kartskiss'}
                         description={
                           <>Senast du arbetade med den här ansökan skapades en kartskiss som ännu inte sparats.
                             <br/><br/>
                             Vill du fortsätta med den kartskissen?
                           </>}
                         acceptButton={{ text: 'Fortsätt', onClick: () => props.closeModalCallback() }}
                         declineButton={{ text: 'Kasta bort', onClick: () => props.onClickKastaBortKnapp() }}/>
}

ContinueWithKartskissModal.propTypes = {
  closeModalCallback: PropTypes.func,
  onClickKastaBortKnapp: PropTypes.func
}


export default AnsokanForm;

