import React, {useEffect} from 'react'
import {connect, useSelector} from 'react-redux'
import KlyvningForm from 'components/Fastighetsforandring/Fastigheter/KlyvningForm'
import AvstyckningForm from 'components/Fastighetsforandring/Fastigheter/AvstyckningForm'
import FastighetsregleringForm from '../../components/Fastighetsforandring/Fastigheter/FastighetsregleringForm'
import SammanlaggningForm from '../../components/Fastighetsforandring/Fastigheter/SammanlaggningForm'
import GransUtmarkningForm from '../../components/Fastighetsforandring/Fastigheter/GransUtmarkningForm'
import FastighetsBestamningForm from '../../components/Fastighetsforandring/Fastigheter/FastighetsBestamningForm'
import {PageWizardNavigationToolbar} from '../../components/General/PageWizardNavigationToolbar'
import {visaMoment} from '../../redux/modules/help'
import {scrollToMainTag} from '../../utils/scrollToElement'
//@ts-ignore
import {RootState} from "../../store";

const mapDispatchToProps = {
  visaHjalpAvsnitt: visaMoment
}

type Props = {
  visaHjalpAvsnitt: (chapterName: string) => {},
}

export function Fastighetsforandring(props: Props) {

  // @ts-ignore
  const klyvningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.klyvning)
  // @ts-ignore
  const avstyckningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.avstyckning)
  // @ts-ignore
  const fastighetsregleringIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.fastighetsreglering)
  // @ts-ignore
  const sammanlaggningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.sammanlaggning)
  // @ts-ignore
  const fastighetsbestamningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.fastighetsbestamning)
  // @ts-ignore
  const gransutmarkningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.gransutmarkning)

  useEffect(() => {
    props.visaHjalpAvsnitt('help-description')
    scrollToMainTag()
  }, [])

    return (
      <>
        {klyvningIsSelected &&
            <KlyvningForm/>
        }
        {avstyckningIsSelected &&
            <AvstyckningForm/>
        }
        {fastighetsregleringIsSelected &&
            <FastighetsregleringForm/>
        }
        {sammanlaggningIsSelected &&
            <SammanlaggningForm/>
        }
        {fastighetsbestamningIsSelected &&
          <FastighetsBestamningForm/>
        }
        {gransutmarkningIsSelected &&
            <GransUtmarkningForm/>
        }
        <PageWizardNavigationToolbar
            buttonRightText={'Lägg till en kartskiss'} buttonRightLink={'../kartskiss'}/>
      </>
    )
  }

export default connect(null,mapDispatchToProps)(Fastighetsforandring)
