import React from 'react'
import {FieldArray} from 'react-final-form-arrays'
// @ts-ignore
import {Checkbox, Column, FormFieldset, Row, ValideringsFelmeddelandeRuta} from 'redet-react-components'

export function DynamicCheckboxListOmbud(props: {options: Map<string, string>, warnings: any}) {
    return (
        <>
            <FormFieldset title={'Välj vilka personen ska företräda'} id={'foretraderPersonTitleId'}>
                {props.options.size > 0 &&
                    <div className="form-group without-label" id="ombudForPersonerListId">
                        <Row>
                            <FieldArray name="ombudForPersoner">
                                {({fields}) => <>
                                    {Array.from(props.options, ([id, name]) =>
                                        <Column columns={'col-xs-12 col-sm-6'} key={id}>
                                            <Checkbox name={name} label={name}
                                                      id={id}
                                                      onChange={() => {/* Kommentar för att förhindra varningar då isChecked används */
                                                      }}
                                                      onClick={(evt: any) => {
                                                          if (evt.target.checked) {
                                                              fields.push(id)
                                                          } else {
                                                              fields.remove(fields.value.findIndex((field) => field === id))
                                                          }
                                                      }}
                                                      isChecked={fields.value?.some((item) => item === id)}/>
                                        </Column>)}
                                </>}</FieldArray>
                        </Row>
                        {props.warnings?.ombudPersonval &&
                            <ValideringsFelmeddelandeRuta text={props.warnings.ombudPersonval} isOptional={true}
                                                          isBlock={true} ariaTitle={'Felmeddelande'}/>
                        }
                    </div>}
            </FormFieldset>
            {props.options.size === 0 &&
                <p>Det finns ännu inga kontaktuppgifter att vara ombud för</p>}
        </>
    )
}
