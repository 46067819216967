import Log from 'utils/log';
import proj4 from 'proj4';
import {fastighetsgranserLayer, ortofotoLayer, topowebbLayer} from './backgroundLayers';
import {extent, resolutions, resolutionsMiniMap} from './mapConstants';
import {getFastighetStyles, getViewStyles} from './styles';
import {get as getProjection} from "ol/proj";
import {MousePosition, ScaleLine, Zoom, ZoomSlider} from "ol/control";
import View from "ol/View";
import Map from 'ol/Map';
import {Vector} from "ol/layer";
import {Vector as VectorSource} from "ol/source";
import {register} from "ol/proj/proj4";
import {Collection} from "ol";

export default class MapController {
  constructor (destructors, userActivityMonitor, toolCoordinator, setCursorFunc, featureHandler) {
    this.log = new Log(this.constructor.name)
    this.destructors = destructors
    this.userActivityMonitor = userActivityMonitor
    this.toolCoordinator = toolCoordinator
    this.setCursorFunc = setCursorFunc
    this.featureHandler = featureHandler

    this.ortofotoActive = false;

    this.setProjection();

    this.topowebbLayer = topowebbLayer();
    this.ortofotoLayer = ortofotoLayer();
    this.fastighetsgranserLayer = fastighetsgranserLayer(this.ortofotoActive);
  }

  getMap() {
    return this.map;
  }

  getMiniMap() {
    return this.miniMap;
  }

  isOrtofotoActive() {
    return this.ortofotoActive;
  }

  setProjection() {
    // Se: https://epsg.io/3006 och https://github.com/openlayers/ol3/blob/master/changelog/v3.13.0.md
    proj4.defs('EPSG:3006', '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    register(proj4);
    this.projection = getProjection('EPSG:3006');

    this.log.debug('Using projection', this.projection.getCode());
  }

  createMap(targetDomElement, centerX, centerY, zoom) {

    const zoomControl = new Zoom();

    const zoomSliderControl = new ZoomSlider();
    const mousePositionControl = new MousePosition({
      className: 'ol-mouse-position',
      'coordinateFormat': (coordinate) => {
        this.userActivityMonitor.registerActivity();
        return '<span class="coordinate-panel"><label>SWEREF 99 TM</label><span class="coordinate">N: <strong>' + Math.round(coordinate[1]) + '</span><span class="coordinate"> E: <strong>' + Math.round(coordinate[0]) + '</strong></span></span>';
      },
      target: document.getElementById('map-container')
    });

    this.scaleLineControl = this.createScaleLine();

    const defaultControls = [zoomControl, zoomSliderControl, mousePositionControl, this.scaleLineControl];

    this.map = new Map({
      interactions: new Collection(),
      controls: defaultControls, // ol.control.defaults(),
      layers: [this.topowebbLayer, this.fastighetsgranserLayer],
        view: new View({
        extent: extent,
        projection: this.projection,
        center: [centerX, centerY],
        zoom: zoom,
        resolutions: resolutions,
        constrainOnlyCenter: true,
      })
    });

    this.destructors.push(() => {
      this.map.setTarget(undefined);
      this.map = undefined;
    });

    this.log.debug('Show map');
    this.map.setTarget(targetDomElement);
  }

  createMiniMap(targetDomElement, centerX, centerY, zoom) {

    this.miniMap = new Map({
      interactions: new Collection(),
      controls: new Collection(),
      layers: [this.ortofotoLayer],
      view: new View({
        extent: extent,
        projection: this.projection,
        center: [centerX, centerY],
        zoom: zoom,
        resolutions: resolutionsMiniMap,
        constrainOnlyCenter: true,
      })
    });

    // Make the miniMap follow the big maps movements
    this.map.on('moveend', (event) => {
      this.miniMap.getView().setZoom(event.map.getView().getZoom())
      this.miniMap.getView().setCenter(event.map.getView().getCenter())
    })

    this.destructors.push(()=> {
      this.miniMap.setTarget(undefined);
      this.miniMap = undefined;
    });

    this.log.debug('Show minimap');
    this.miniMap.setTarget(targetDomElement);
  }

  getFastighetLayer () {
    return this.fastighetLayer
  }

  getDrawingLayer() {
    return this.drawingLayer;
  }

  clearFastighetLayer() {
    if (this.fastighetLayer) this.fastighetLayer.getSource().clear();
  }

  clearDrawingLayer() {
    this.drawingLayer.getSource().clear();
  }

  createFastighetLayer() {
    this.fastighetLayer = new Vector({
      source: new VectorSource({features: this.featureHandler.getFastighetFeatures()}),
      style: getFastighetStyles
    });
    // setMap har kommenterats ut till fördel för addLayer pga problem
    // med att sätta z-index på unmanaged layers, någon borde förklara
    // varför man valt att använda setMap?
    // this.fastighetLayer.setMap(this.map);
    this.map.addLayer(this.fastighetLayer);
    this.fastighetLayer.setZIndex(1000);
  }

  createDrawingLayer() {

    this.drawingLayer = new Vector({
      source: new VectorSource({features: this.featureHandler.getDrawingFeatures()}),
      style: (feature) => getViewStyles(feature, this.map)
    });
    this.map.addLayer(this.drawingLayer);
    this.drawingLayer.setZIndex(1001);
  }

  swapBackgroundMap() {
    this.log.debug(`Swapping background map to ${this.ortofotoActive ? 'topoweb' : 'ortofoto'}`);

    this.map.getLayers().clear();
    this.miniMap.getLayers().clear();

    this.miniMap.addLayer(this.ortofotoActive ? this.ortofotoLayer : this.topowebbLayer);
    this.map.addLayer(this.ortofotoActive ? this.topowebbLayer : this.ortofotoLayer);

    this.fastighetsgranserLayer = fastighetsgranserLayer(!this.ortofotoActive);

    this.map.addLayer(this.getDrawingLayer());
    this.map.addLayer(this.fastighetsgranserLayer);
    this.map.addLayer(this.getFastighetLayer());

    this.ortofotoActive = !this.ortofotoActive;

    this.map.removeControl(this.scaleLineControl);
    this.scaleLineControl = this.createScaleLine();
    this.map.addControl(this.scaleLineControl);
  }

  createScaleLine() {
    return new ScaleLine({className: 'ol-scale-line', target: document.getElementById('map-container')});
  }
}
