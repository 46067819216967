import { Response, Server } from 'miragejs'

export let togglyRoutes = function (server: Server) {
  const authHeader = { 'svarstjanst.passedauthentication': 'true' }

  server.get('/toggly-backend/AK000577/toggles', (schema, request) => {
    return new Response(200, authHeader, {
      'toggles': [
        {
          name: 'toggle1',
          metadata: {
            type: 'notificationMetadata',
            header: 'Notis 1',
            message: 'ERROR blbvab albalbla',
            severity: 'ERROR'
          },
          enabled: true
        },
        {
          name: 'toggle2',
          metadata: {
            type: 'notificationMetadata',
            header: 'Notis 2',
            message: 'INFORMATION blbvab albalbla',
            severity: 'INFORMATION'
          },
          enabled: true
        },
        {
          name: 'toggle3',
          metadata: {
            type: 'notificationMetadata',
            header: 'Notis 3',
            message: 'INFORMATION blbvab albalbla',
            severity: 'INFORMATION'
          },
          enabled: true
        },
        {
          name: 'toggle4',
          metadata: {
            type: 'notificationMetadata',
            header: 'Notis 4',
            message: 'WARNING blbvab albalbla',
            severity: 'WARNING'
          },
          enabled: true
        },
        {
          name: 'signering-leif-web',
          metadata: {
            type: "TOGGLE_ONOFF"
          },
          enabled: true
        }
      ]
    })
  })
}
