import Log from '../utils/log'
import {logError} from '../utils/errorHandler'
import {Fetcher} from './fetcher';
import {Fastighet} from 'domain/Ansokan';
import i18n from "i18next";

const log = new Log('sdgRepository')
const APPLICATION_JSON = 'application/json'
const ANSOKAN_SERVICE_PATH = "/api/ansokan-service/sdg/ansokan";

export type SdgSigningResponse = {
    ansokanId: { value: string };
    signingWebUrl: string;
}

export enum SDG_SIGNING_STATUS {
    NOT_STARTED = "NOT_STARTED",
    PENDING = "PENDING",
    SUCCEEDED = "SUCCEEDED",
    TIMED_OUT = "TIMED_OUT"
}

export type SDGSigningStatus = {
    ansokanId: { value: string }
    status: SDG_SIGNING_STATUS
}

export type SubmitSdgAnsokanResponse = {
    kvittensId: number;
    mottagandemyndighet: string;
}

export function initiateSDGSigning(fetcher: Fetcher, fastigheter: Fastighet[], handlingar: File[], ansokan: File) {

    const mappedFastigheter = mapFastighetForSDG(fastigheter);
    const formData = new FormData()

    const json = JSON.stringify({fastigheter: mappedFastigheter});
    const blob = new Blob([json], {
        type: APPLICATION_JSON
    });
    try {
        formData.append('request', blob)
        formData.append('ansokan', ansokan)
        for (const file of handlingar) {
            formData.append('files', file)
        }
    } catch (err) {
        throw err;
    }

    return fetcher.fetching(`${ANSOKAN_SERVICE_PATH}/sign`,
        {
            cache: 'no-store',
            method: 'POST',
            body: formData,
            headers: new Headers({'Content-type': APPLICATION_JSON})
        }, {'Accept-Language': i18n.language}
    ).then((response) => {
        return response.json()
    }).then((response: SdgSigningResponse) => {
        return response
    }).catch((error: Error) => {
        logError(error, 'Error encountered while initiating sdg-signering')
        throw error
    })
  }

  export function getSDGSigningStatus (fetcher: Fetcher, ansokanId: string) {
    return fetcher.fetching(`${ANSOKAN_SERVICE_PATH}/sign/status/${ansokanId}`,
        {
            method: 'GET',
            cache: 'no-store',
        }, {'Accept-Language': i18n.language}
    ).then((response) => {
        return response.json()
    }).then((status: SDGSigningStatus) => {
        log.debug('Signing status for sdg-ansokan successfully loaded from server')
        return status
    }).catch((error: Error) => {
        logError('Error encountered while fetching signing status for sdg-ansokan from server')
        throw error
    })
  }

  export function withdrawSDGSigning (fetcher: Fetcher, ansokanId: string) {
    return fetcher.fetching(`${ANSOKAN_SERVICE_PATH}/sign/withdraw/${ansokanId}`,
        {
            method: 'PUT',
            cache: 'no-store',
        }, {'Accept-Language': i18n.language}
    ).catch((error: Error) => {
        logError('Error encountered while withdrawing signing for sdg-ansokan from server')
        throw error
    })
}

export function submitSDG(fetcher: Fetcher, ansokanId: string) {
    return fetcher.fetching(`${ANSOKAN_SERVICE_PATH}/submit/${ansokanId}`,
        {
            method: 'POST',
            cache: 'no-store',
        }, {'Accept-Language': i18n.language}
    ).then((response) => {
        return response.json()
    }).then((submitResponse: SubmitSdgAnsokanResponse) => {
        log.debug('sdg-ansokan successfully submitted to server')
        return submitResponse
    }).catch((error: Error) => {
        logError('Error encountered while submitting sdg-ansokan to server')
        throw error
    })
}

function mapFastighetForSDG(fastigheterToMap: Fastighet[]) {
    return fastigheterToMap.map((fastighet) => {
            const registerbeteckning = fastighet.data.registerbeteckning;
            return {
                objektidentitet: {value: fastighet.objektidentitet},
                trakt: registerbeteckning.traktnamn,
                block: registerbeteckning.block,
                enhet: registerbeteckning.enhet,
                kommun: registerbeteckning.registeromrade
            }
        }
    );
}