import {format, parseISO} from 'date-fns'
import {sv} from 'date-fns/locale'

// Tar en sträng i ISO8601-format, t ex "2020-01-02T14:33:00.000Z" och returnerar
// en sträng efter format-strängen:
export function getLocalizedDateString(date?: string, formatString: string = 'yyyy-MM-dd HH:mm'): string | undefined {
    return date ? format(parseISO(date), formatString, {locale: sv}) : undefined
}

export function formatToDateAndTime(value: string | number): string {
    if (value === null || value === undefined) {
        return ''
    }
    return new Date(value).toLocaleTimeString('sv-SE', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    })
}
