//@ts-ignore 
import { ExternalLink, SidePanelChapterHeading } from 'redet-react-components'
import '../../../i18n';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export function PersonuppgifterHelpContent() {

  const user = useSelector((store: RootState) => store.auth.user)
  const isSdg = user?.isSdg || window.location.href.includes('/sdg')
  
  return(
  <SidePanelChapterHeading header={t('personalData.title')}
                           introduction={<>
                             {t('personalData.personalDataText')}
                             <br/>
                             <br/>
                             {t('personalData.findInfo')} 
                             {isSdg ? 
                             <ExternalLink
                               text={'www.lantmateriet.se/sdg-personal-data'}
                               url={'https://www.lantmateriet.se/sdg-personal-data'}
                               openInNewTab={true}/> : 
                               <ExternalLink text="www.lantmateriet.se/personuppgifter"
                                url={'https://www.lantmateriet.se/personuppgifter'}/>}
                           </>}/>)}