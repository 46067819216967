// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
export const UPDATE_FORM_STATE = 'finalForm/UPDATE_FORM_STATE'
export const RESET_FORM_STATE = 'finalForm/RESET_FORM_STATE'
export const UPDATE_FIELD_STATE = 'finalForm/UPDATE_FIELD_STATE'

// Reducer
export default function reducer (state = {}, action = {}) {

  switch (action.type) {
    case UPDATE_FORM_STATE:
      return {
        ...state,
        [action.form]: action.payload.values
      }
    case RESET_FORM_STATE:
      return {
        ...state,
        [action.form]: undefined
      }
    case UPDATE_FIELD_STATE:
      const isCurrentFormStateUndefined = state[action.form] === undefined
      return {
        ...state,
        [action.form]: isCurrentFormStateUndefined ? { [action.fieldName]: action.payload } : {
          ...state[action.form],
          [action.fieldName]: action.payload
        }
      }
    default:
      return state
  }
}

// Action Creators
export const updateFormState = (form, state) => ({
  type: UPDATE_FORM_STATE,
  form: form,
  payload: state
})

export const updateFieldState = (form, fieldName, value) => ({
  type: UPDATE_FIELD_STATE,
  form: form,
  fieldName: fieldName,
  payload: value
})

export const resetFormState = (form) => ({
  type: RESET_FORM_STATE,
  form: form,
})

export function initFormData (formField) {
  return function action (dispatch, getState) {
    const formState = getState().form[formField]
    return formState !== undefined ? formState : {}
  }
}