export function decodeDecodeFromHexUtf8(value: string): string {
    let result = value
    if (result && result.slice(0, 2) === '0x') {
        result = decodeURIComponent(value.slice(2).replace(/../g, '%$&'))
    }
    return result
}

export function isNonEmptyString(unknownVariable?: any): boolean {
    if (((typeof unknownVariable !== 'undefined') && unknownVariable !== null && (typeof unknownVariable.valueOf() === 'string')) && (unknownVariable.trim().length > 0)) {
        return true
    }
    return false
}

export function isAllEmptyStrings(arrayOfStrings: any[]): boolean {
    let returnValue = true
    for (const string of arrayOfStrings) {
        if (isNonEmptyString(string)) {
            returnValue = false
        }
    }
    return returnValue
}

export function trimTrailingSlash(string: any): string {
    if (isNonEmptyString(string) && string.endsWith('/')) {
        return string.slice(0, -1)
    } else {
        return string
    }
}

export const removeIllegalUnicodeChars = (text: string): string => {
    return text.replace(/[\uE000-\uF8FF]/g, '');
}
