import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Term } from 'views'
import { CheckboxFinalForm, Condition, Subscribe, WhenFieldChanges } from '../../General/forms/finalForm'
import { Column, FormFieldset, IconButtonInline, Row } from 'redet-react-components'
import { harOmbud } from '../../../utils/contactsHelper'

const mapStateToProps = (state) => {
  return {
    contactList: state.contacts.contactList,
  }
}

export function ContactRolesSelection (props) {
  const shouldrollAktmottagareWarningBeDisplayed = (contactsList, currentContactId, aktmottagareIsSelected) => {
    const contactsWithRole = contactsList.filter(contact => contact.id !== currentContactId && contact.data.rollAktmottagare).length
    return contactsWithRole > 0 && aktmottagareIsSelected
  }

  const roleSelectionColumnSetting = 'col-12 col-sm-6 col-md-4 col-lg-3"'
  return (
    <>
      <FormFieldset title={'Vilken roll har personen i den här ansökan?'}
                    description={harOmbud(props.contactList, props.contact?.id) ? 'Personen företräds av någon annan' : ''}>
        <p/>
        <Row>
          <Condition when={'rollUpprattare'} is={true}>
            <Column columns={roleSelectionColumnSetting}>
              <CheckboxFinalForm
                disabled={true}
                field={'rollUpprattare'}
                label={'Upprättare'}
                term={<Term label="upprättare" moment="help-contactinformation" term="term-rollUpprattare"/>}
              />
            </Column>
          </Condition>
          <Column columns={roleSelectionColumnSetting}>
            <Subscribe fieldName={'rollAnnanBerord'}>{({ input }) => {
              const isRollAnnanBerordChecked = input.value === true
              return <CheckboxFinalForm
                field={'rollSokande'}
                label={'Sökande'}
                data-testid="rollsokandeifbox"
                id={'checkboxRollSokande'}
                disabled={isRollAnnanBerordChecked}
                term={<Term label="sökande" moment="help-contactinformation" term="term-rollSokande"/>}
                warningMessage={isRollAnnanBerordChecked ? <p className={'fw-bold'}>- Annan berörd kan inte ha rollen
                  sökande</p> : false}/>

            }
            }</Subscribe>
          </Column>
          <Column columns={roleSelectionColumnSetting}>
            <Subscribe fieldName={'rollSokande'}>
              {({ input }) => {
                const isRollSokandeChecked = input.value === true
                return <CheckboxFinalForm
                  field={'rollAnnanBerord'}
                  label={'Annan berörd'}
                  disabled={isRollSokandeChecked}
                  id={'rollAnnanBerordCheckboxId'}
                  term={<Term label="annan berörd" moment="help-contactinformation"
                              term="term-rollAnnanBerord"/>}
                  warningMessage={isRollSokandeChecked ? <p className={'fw-bold'}>- Sökande kan inte ha rollen annan
                    berörd</p> : false}/>
              }
              }</Subscribe>
          </Column>
          {props.privatPerson &&
            <Column columns={roleSelectionColumnSetting}>
              <CheckboxFinalForm
                field={'rollOmbud'}
                label={'Företräder sökande'}
                disabled={harOmbud(props.contactList, props.contact?.id)}
                id={'rollOmbudCheckboxId'}
                term={<Term label="företrädare" moment="help-contactinformation"
                            term="term-rollOmbud"/>}
              />
            </Column>
          }
          <Column columns={roleSelectionColumnSetting}>
            <CheckboxFinalForm field={'rollFakturamottagare'} label={'Betalare'} id={'checkboxRollFakturamottagare'}
                               term={<Term
                                 label="betalare"
                                 moment="help-contactinformation"
                                 term="term-rollFakturamottagare"/>}/>
            <WhenFieldChanges field={'rollFakturamottagare'} becomes={false}
                              set={'andelAvForattningskostnaden'} to={undefined}/>
          </Column>
          <Column columns={roleSelectionColumnSetting}>
            <CheckboxFinalForm field={'rollAktmottagare'} label={'Aktmottagare   '} id={'checkboxRollAktmottagare'}
                               term={<Term label="aktmottagare" moment="help-contactinformation"
                                           term="term-rollAktmottagare"/>}
                               labelChildren={<Condition when={'rollAktmottagare'} is={true}>
                                 {shouldrollAktmottagareWarningBeDisplayed(props.contactList, props.editingId, true) &&
                                   <IconButtonInline isImportant={true}
                                                     ariaLabel={'Varning för aktmottagare redan ikryssat på annan kontakt'}
                                                     popover={{ popoverText: 'Vi skickar ut en aktkopia kostnadsfritt, lägger du till fler aktmottagare så tillkommer en extra kostnad/aktkopia.' }}
                                                     id={'rollAktmottagareWarningPopup'}
                                   />}
                               </Condition>}/>
          </Column>
        </Row>
      </FormFieldset>
    </>
  )
}

ContactRolesSelection.propTypes = {
  contactList: PropTypes.array.isRequired,
  editingId: PropTypes.string,
  privatPerson: PropTypes.bool
}

export default connect(mapStateToProps, undefined)(ContactRolesSelection)

