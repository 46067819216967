import React, {ChangeEvent, MouseEvent, useEffect, useState} from "react";
import {BifogaHandlingContext} from "../BifogaHandlingContext";
// @ts-ignore
import {ButtonGroup, ButtonToolbarV2, ButtonTop, FormFieldset, TextInput} from "redet-react-components";
import {email, required} from "../../../../utils/validation"

type Props = {
    defaultEpost?: string
    //Injectas med clone element från WizardDialogStep :sick!
    previousStep?: () => void
    nextStep?: () => void
}

export function BifogaHandlingAngeEpostStep({defaultEpost = '', previousStep, nextStep}: Props) {

    const {epost, setEpost} = React.useContext(BifogaHandlingContext)
    const [emailValidationMsg, setEmailValidationMsg] = useState<string>()

    useEffect(() => {
        if(defaultEpost)
            setEpost(defaultEpost)
    }, [defaultEpost]);

    const validate = (input: string) => {
        const error = required(input) ?? email(input)
        setEmailValidationMsg(error ?? '')
        return error === undefined
    }

    function onNextStepClick(evt: MouseEvent<HTMLInputElement>) {
        //if valid, go to next step!
        if (nextStep && validate(epost))
            nextStep()
    }

    return (<>
        <FormFieldset title={'E-post för notifiering'}
                      description={'Ange din e-postadress för att ta emot kvittens vid inskickande.'}>
            <TextInput label={'E-post'} isRequired={true}
                       data-selenium-id={'notification-email-input'}
                       id={'notification-email-input'}
                       type={'email'}
                       autoComplete={'email'}
                       maxLength={100}
                       validationErrorMsg={emailValidationMsg}
                       value={epost}
                       onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                           if (emailValidationMsg)
                               validate(evt.target.value)
                           setEpost(evt.target.value)
                       }}/>
        </FormFieldset>
        {/*navigeringsknappar*/}
        <ButtonToolbarV2>
            <ButtonGroup>
                <ButtonTop text={'Ändra filer'}
                           onClick={previousStep}
                           icon={'arrow_back'}
                           id={'previous-step-button'}
                           ariaLabel={'Tillbaka till filer'}/>
            </ButtonGroup>
            <ButtonGroup>
                <ButtonTop text={'Bestyrk handling'}
                           onClick={onNextStepClick}
                           icon={'arrow_forward'}
                           id={'next-step-button'}
                           ariaLabel={'Gå vidare för att bestyrka handling'}/>
            </ButtonGroup>
        </ButtonToolbarV2>
    </>)
}