import {connect} from 'react-redux';
import {
    ButtonGroup,
    // @ts-ignore
    ButtonSub, ButtonToolbarV2, LinkButtonSub
} from "redet-react-components";
import {saveAnsokan} from 'redux/modules/ansokanCRUD';
import {logError} from "../../utils/errorHandler";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

type IncomingProps = {
    saveAnsokanOnClose: boolean
}
type DispatchProps = {
    saveAnsokan: typeof saveAnsokan
}

type Props = IncomingProps & DispatchProps;

const mapDispatchToProps = {
    saveAnsokan
};

export function ActionBar(props: Props) {
    const location = useLocation()
    const [url, setUrl] = useState("/")
    const navigate = useNavigate()

    useEffect(() => {
        const firstPartOfPath = location.pathname.split("/").filter(partOfPath => partOfPath)[0]
        switch (firstPartOfPath) {
            case "privat":
            case "kvittens":
                setUrl('/privat');
                break;
            case "org":
                setUrl('/org');
                break;
            case "sdg":
                setUrl('/sdg');
                break;
            default:
                setUrl('/');
        }
    }, [location.pathname]);

    const handleClose = () => {
        props.saveAnsokan()
            .then(() => {
                navigate(url)
            })
            .catch((err: any) => {
                logError(err, 'saveOnCloseFailed')
            });
    }

    return (
        <nav className="control-panel" aria-label="Navigering">
            <ButtonToolbarV2>
                <ButtonGroup>
                    {props.saveAnsokanOnClose ?
                        <ButtonSub text={"Till startsidan"} icon={'home'} onClick={handleClose}
                                   data-selenium-id="stäng-ärendet" className={"button-tile"}/>
                        :
                        <LinkButtonSub link={url} text={"Till startsidan"} icon={"home"}
                                       data-selenium-id="stäng-ärendet" className={"button-tile"}/>
                    }
                </ButtonGroup>
            </ButtonToolbarV2>
        </nav>
    )
}

// @ts-ignore
export default connect(undefined, mapDispatchToProps)(ActionBar);
