import { SigningStatus } from "./signing/SigningStatus"

export type Komplettering = {
    kompletteringId: string
    signingRequestId?: string
    status: KOMPLETTERINGSTATUS
    typ: KOMPLETTERINGTYP
    handlingTyp?: string
    createdByUserPersonnummer: string
    createdByUserFullName: string
    skapad: string
    inskickadDatum: string
    signingStatus: SigningStatus
    kvittensId?: string
}

// Must match se.lantmateriet.enak.komplettering.model.shared.Constants.KompletteringStatus

export enum KOMPLETTERINGSTATUS {
    UNKNOWN = "UNKNOWN",
    UTKAST = "UTKAST",
    AVBOJD = "AVBOJD",
    UTGATT = "UTGATT",
    SIGNERAS = "SIGNERAS", 
    INSKICKAD = "INSKICKAD"
}
export enum KOMPLETTERINGTYP { 
    YTTERLIGARE_HANDLING = "YTTERLIGARE_HANDLING", 
    BITRADANDE = "BITRADANDE", 
    YRKANDE = "YRKANDE", 
    SKRIVELSE = "SKRIVELSE",
}