import {useEffect, useState} from 'react'
import {SmallLoading} from 'components'
import {ArendeListItem} from './ArendeListItem'
import {PaginationToolbar} from 'components/General/PaginationToolbar'
import {useLocation} from 'react-router-dom'
// @ts-ignore
import {ButtonGroup, ButtonToolbarV2} from 'redet-react-components'
import {SortButton} from 'components/General/SortButton'
import {Arende} from 'domain/Arende'

type ArendeListProps = {
    showArendenLoadingSpinner: boolean,
    arenden: Arende[],
    pageSize: number,
    error: boolean,
}

export const ArendeList = (props: ArendeListProps) => {
    const location = useLocation()
    const [sortFalling, setSortFalling] = useState(true)
    const [sliceListStartIndex, setSlicelistStartIndex] = useState(0)
    const [sliceListEndIndex, setSliceListEndIndex] = useState<number | undefined>(undefined)

    const setListSliceIndex = (startIndex: number, endIndex: number) => {
        setSlicelistStartIndex(startIndex)
        setSliceListEndIndex(endIndex)
    }

    const sortedArendeList = () => {
        return (props.arenden.slice().sort((current, next) => sortFalling ?
            (new Date(next.startDate).getTime() - new Date(current.startDate).getTime())
            :
            (new Date(current.startDate).getTime() - new Date(next.startDate).getTime()))
        ).slice(sliceListStartIndex, sliceListEndIndex);
    }

    const url = location.pathname

    return (
        <div>
            <div>
                {props.showArendenLoadingSpinner &&
                    <div className="with-top-margin with-bottom-margin"><SmallLoading text="Laddar ärenden" /></div>
                }
                {props.error &&
                    <div className="dashed with-top-margin">
                        <p><strong>Ett fel uppstod vid laddning av ärenden.</strong></p>
                        <p>Just nu är det prestandaproblem med denna del av tjänsten. Du kan prova igen om en liten stund.</p>
                    </div>
                }
                {!props.showArendenLoadingSpinner && !props.error && props.arenden.length === 0 &&
                    <p className="with-top-margin">Inga upprättade ärenden finns registrerade eller matchar filtreringen.</p>
                }
                {!props.showArendenLoadingSpinner && props.arenden.length > 0 &&
                    <div>
                        <div className="flex-line full-width bottom-aligned block-xs sort">
                            <h3>Ärenden</h3>
                            <ButtonToolbarV2>
                                <ButtonGroup id="sort-arende-list">
                                    <SortButton descending={sortFalling}
                                        onDescendingChange={(desc) => setSortFalling(desc)}
                                        ascendingLabel={"Stigande ansökansdatum"}
                                        descendingLabel={"Fallande ansökansdatum"}></SortButton>
                                </ButtonGroup>
                            </ButtonToolbarV2>
                        </div>
                        <div className="list alternating" data-selenium-id="arende-list">
                            {sortedArendeList()
                                .map((arende) => {
                                    return (
                                        <ArendeListItem arende={arende} url={url} key={arende.nummer} />
                                    )
                                })
                            }
                        </div>
                        <br />
                        <PaginationToolbar
                            list={props.arenden}
                            pageSize={props.pageSize}
                            listSliceIndexsCallback={setListSliceIndex}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

