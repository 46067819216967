import { formProperties } from '../redux/formProperties'
import * as contactsHelper from "./contactsHelper";
import ContactNamesList from "../components/General/ContactNamesList";
import React from "react";

export function mapAtgardListToStringArray (list) {
  if (list) {
    let arr = list.map((str) => {
      if (formProperties[str].displayName !== undefined) {
        return formProperties[str].displayName
      } else {
        return capitalizeFirstLetter(str)
      }
    })
    return arr.sort()
  }
}

function capitalizeFirstLetter (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function parseFastigheterArrayToStringArray (array) {
  let fastighetStrings = []
  if (array) {
    array.forEach((fastighet) => {
      fastighetStrings.push(fastighet.data.registerbeteckning.text)
    })
    return fastighetStrings
  }
}

export function getContactInfo(contactList, contact, index) {
  const namn = contactsHelper.getNamn(contact)
  const epost = contactsHelper.getEpost(contact)

  //hämtar de som kontakten själv företräder
  const foretradda = contactsHelper.getForetraddaForContact(contactList, contact.id)
  //hämtar de som företräder kontaktens
  const foretradare = contactsHelper.getForetradareForContact(contactList, contact.id)

  return (
      <>
          <label key={'label_' + index} className="label">Sökande</label>
          <p key={index}
             id={`sokandeInfo${index}`}>
              {namn}<br/>{epost}
              {contactsHelper.isForetradare(contactList, contact.id) &&
                  foretradda &&
                  foretradda.length > 0 &&
                  <span className="attention" id={`foretraddaDescription${index}`}>
                        Har skrivit under för egen räkning samt som företrädare för <ContactNamesList
                      contacts={foretradda}/>.
                    </span>
              }
              {foretradare.length > 0 &&
                  <span className="attention" id={`foretradareDescription${index}`}>
                        <ContactNamesList contacts={foretradare}/> har skrivit under som företrädare.
                    </span>
              }
          </p>
      </>)
}

export function getContactInfoSammanfattning(contactList, contact, index) {
  const namn = contactsHelper.getNamn(contact)
  const epost = contactsHelper.getEpost(contact)

  //hämtar de som kontakten själv företräder
  const foretradda = contactsHelper.getForetraddaForContact(contactList, contact.id)
  //hämtar de som företräder kontaktens
  const foretradare = contactsHelper.getForetradareForContact(contactList, contact.id)

  return (
      <>
          <h4>Sökande</h4>
          <p id={`sokandeInfo${index}`}>
              {namn}<br/>{epost}
              {contactsHelper.isForetradare(contactList, contact.id) &&
                  foretradda &&
                  foretradda.length > 0 &&
                  <span className="attention" id={`foretraddaDescription${index}`}>
                        Har skrivit under för egen räkning samt som företrädare för <ContactNamesList
                      contacts={foretradda}/>.
                    </span>
              }
              {foretradare.length > 0 &&
                  <span className="attention" id={`foretradareDescription${index}`}>
                        <ContactNamesList contacts={foretradare}/> skriver under som företrädare.
                    </span>
              }
          </p>
      </>)
}

