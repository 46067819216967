import {useEffect, useRef} from "react";
import {SigningFormValues} from "../../domain/signing/SigningModels";

type Props = {
    form?: SigningFormValues,
}

export function SignAutoPostForm({form, ...rest}: Props) {

    const formRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        if (form && formRef.current != null) {
            submitForm()
        }
    }, [form]);

    function submitForm() {
        setTimeout(() => formRef.current?.submit(), 1500)
    }

    return (<>
        {form && <form
            name={'auto-post-form'}
            ref={formRef}
            action={`${form.action}`}
            method='post'
            {...rest}>
            <div>
                <input type='hidden' name='Binding' value={`${form.binding}`} data-testid={'hidden-input-binding'}/>
                <input type='hidden' name='RelayState' value={`${form.relayState}`} data-testid={'hidden-input-relaystate'}/>
                <input type='hidden' name='EidSignRequest' value={`${form.eidSignRequest}`} data-testid={'hidden-input-eidsignrequest'}/>
                <noscript>
                    <p>
                        <strong>Notera:</strong> Din webbläsare stödjer inte Javascript så du måste klicka på Signera-knappen.
                    </p>
                    <div>
                        <input type='button' value='Signera' onClick={() => submitForm()}/>
                    </div>
                </noscript>
            </div>
        </form>}
    </>)
}