import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sumAndFormatFileSize } from '../../../helpers/fileHelpers'
import { MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL } from '../../../utils/globalConstants'
import { WizardIntorduction, WizardNavigationButtonToolbar } from '../../../components/wizard/WizardFormElements'
import { ButtonGroup, ButtonToolbarV2, ButtonTop, FormFieldset, IconButton, VarningsRuta } from 'redet-react-components'

class ValjFilBifogadKartskissWizardStep extends Component {
  static propTypes = {
    // Props
    setFileCallback: PropTypes.func.isRequired,
    file: PropTypes.any,
    // Wizard
    nextStep: PropTypes.func,
    nextButton: PropTypes.shape({ text: PropTypes.string, ariaLabel: PropTypes.string }),
    prevButton: PropTypes.shape({ text: PropTypes.string, ariaLabel: PropTypes.string, onClick: PropTypes.func }),
  }

  static defaultProps = {
    nextButton: { text: 'Namnge kartskiss', ariaLabel: 'Välj vald kartskiss' }
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      selectedFile: props.file || null,
      errorMessages: [],
    }
    this.filterFilesByType = this.filterFilesByType.bind(this)
    this.filterFilesBySize = this.filterFilesBySize.bind(this)
    this.preventDefault = this.preventDefault.bind(this)
    this.fileDropped = this.fileDropped.bind(this)
    this.onFileAdded = this.onFileAdded.bind(this)
    this.goToNextStep = this.goToNextStep.bind(this)
    this.goToPrevStep = this.goToPrevStep.bind(this)
  }

  onFileAdded (event) {
    if (event.target.files.length > 0) {
      this.addFilesToList(event.target.files)
      // Clear the input control once we have its data so that it can be reused without any odd behaviour
      event.target.value = null
    }
  }

  addFilesToList (files) {
    this.setState({ errorMessages: [] })
    let filesToAdd = Array.from(files)

    // Remember that the filter order is important for the behaviour
    const filterMethods = [this.filterFilesByType, this.filterFilesBySize]
    filterMethods.forEach(fn => {
      const filterResult = fn(filesToAdd)
      filesToAdd = filterResult.filteredFiles
      if (filterResult.errorMessage) {
        this.setState(prevState => ({
          errorMessages: [...prevState.errorMessages, filterResult.errorMessage]
        }))
      }
    })
    if (filesToAdd.length > 0) {
      this.setState({ selectedFile: filesToAdd[0] })
    }
  }

  fileDropped (event) {
    this.preventDefault(event)
    this.addFilesToList(event.dataTransfer.files)
  }

  filterFilesByType (files) {
    // Only add files with the right type
    const filteredFiles = files.filter(
      file => file.type && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'application/pdf')
    )

    let errorMessage = null

    if (filteredFiles.length !== files.length) {
      errorMessage = 'Filen som du valt har en otillåten filtyp'
    }

    return { filteredFiles, errorMessage }
  }

  filterFilesBySize (files) {
    let filteredFiles = files
    let errorMessage = null
    const maxFileSizeInBytes =
      MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL * 1024 * 1024

    if (filteredFiles.some(file => file.size > maxFileSizeInBytes)) {
      errorMessage = `En eller fler av de valda filerna har tagits bort eftersom maxstorleken på ${MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL}MB överskreds.`
      filteredFiles = files.filter(file => file.size <= maxFileSizeInBytes)
    }

    return { filteredFiles, errorMessage }
  }

  preventDefault (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  goToNextStep () {
    if (this.state.selectedFile !== null) {
      this.props.setFileCallback(this.state.selectedFile)
      this.props.nextStep()
    }
  }

  goToPrevStep () {
    if (this.props.prevButton?.onClick) {
      this.props.prevButton.onClick()
    }
    this.props.previousStep()
  }

  render () {
    return (
      <>
        <WizardIntorduction title={'Välj fil'}/>
        <FormFieldset title={'Välj fil att ladda upp'}
                      description={'(JPG, GIF, PNG eller PDF)'}>
          <div className="file-upload">

            <span className="area"><span className="active"><label htmlFor="fileSelectionInput">Välj fil</label></span></span>
            <input id="fileSelectionInput" type="file"
                   className="multiple-files"
                   aria-label="Multifilsuppladdning."
                   accept=".gif, .jpg, .jpeg, .png, .pdf"
                   aria-describedby={'selectedBifogadKartskiss'}
                   onChange={this.onFileAdded}/>

          </div>
        </FormFieldset>
        {this.state.errorMessages.length > 0 && (
          <VarningsRuta ariaTitle='Information om fel vid filuppladdning'>
            {this.state.errorMessages.map((errorMessage, index) => (
              <p className="mandatory" key={'error_2_' + index}>{errorMessage}</p>
            ))}
          </VarningsRuta>
        )}
        {this.state.selectedFile !== null && (
          <div id="selectedBifogadKartskiss" className="list alternating headless">
            <div className="passthrough-row">
              <ButtonToolbarV2>
                <ButtonGroup>
                  <IconButton icon={'delete'} ariaLabel={'Ta bort'} onClick={() => {
                    this.setState({ errorMessages: [], selectedFile: null })
                  }}/>
                </ButtonGroup>
              </ButtonToolbarV2>
              <section className="content" key={1}>
                <h3>Kartskiss</h3>
                <p>
                  {' '}
                  {this.state.selectedFile.name},{' '}
                  {sumAndFormatFileSize([
                    { fileSize: this.state.selectedFile.size }
                  ])}
                </p>
              </section>
            </div>
          </div>
        )}
        <WizardNavigationButtonToolbar prevButtonText={this.props.prevButton?.text}
                                       onClickPrevButton={this.goToPrevStep}>
          <ButtonTop text={this.props.nextButton.text}
                     id={'nameKartskissButton'}
                     onClick={this.goToNextStep}
                     isDisabled={this.state.selectedFile === null}
                     isActive={this.state.selectedFile !== null}
                     icon={'arrow_forward'}
                     ariaLabel={this.props.nextButton.ariaLabel}/>
        </WizardNavigationButtonToolbar>
      </>
    )
  }
}

export default ValjFilBifogadKartskissWizardStep
