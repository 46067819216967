import { Response, Server } from 'miragejs'

export let validationErrorRoutes = function (server: Server) {
  const authHeader = { 'svarstjanst.passedauthentication': 'true' }

  server.get('/api/ansokan/:ansokanId/validation-errors', (schema, request) => {
    const ansokanId = request.params.ansokanId
    switch (ansokanId) {
      case 'ansokanid200': {
        return new Response(200, authHeader, [{
          id: 'SOKANDEN_HAR_EN_FASTIGHET',
          category: 'FASTIGHETSAGRE',
          severity: 'ERROR',
          msg: 'Något meddelande'
        }])
      }
      case 'ansokanid400': {
        return new Response(400, authHeader, [{
          type: 'felcategory',
          title: 'Bad Request',
          status: 400,
          detail: 'Detaljer',
          msg: '/api/ansokan/ansokanid400/validation-errors'
        }])
      }
      case 'ansokanid500': {
        return new Response(500, authHeader, [{
          type: 'fel-server',
          title: 'Server Error',
          status: 500,
          detail: 'Detaljer',
          msg: '/api/ansokan/ansokanid500/validation-errors'
        }])
      }
      default:
        return new Response(404, {message: 'Okänd route.'})
    }
  })
}
