import React from 'react'
import {loadSvarslistor} from '../../../redux/modules/arendesvarslistor'
import {Svarslista} from 'domain/Svarslistor'
import {useAppDispatch} from 'hooks'
import {RootState} from 'store'
import {BekraftaDelgivningProvider} from "./BekraftaDelgivningContext";
import BekraftaDelgivningWizardDialog from "./delgivningWizard/BekraftaDelgivningWizardDialog";
import {useSelector} from "react-redux";

type Props = {
    obesvaradeSvarslistor: Svarslista[],
    arendenummer: string,
    onCloseModal: () => void
}

function BekraftaDelgivningWizard({obesvaradeSvarslistor, arendenummer, onCloseModal}: Props) {

    const dispatch = useAppDispatch();

    const isLoadingSvarslistor = useSelector((store: RootState) => store.arende?.svarslistorIsLoading ?? false)

    const reloadSvarslistor = () => {
        dispatch(loadSvarslistor(arendenummer))
    }

    const closeDialogAndReloadSvarslistor = () => {
        onCloseModal()
        reloadSvarslistor()
    }

    return (<>
        <BekraftaDelgivningProvider>
            <BekraftaDelgivningWizardDialog obesvaradeSvarslistor={obesvaradeSvarslistor}
                                            isLoadingSvarslistor={isLoadingSvarslistor}
                                            onSubmitted={() => reloadSvarslistor()}
                                            onCloseDialog={() => closeDialogAndReloadSvarslistor()}/>
        </BekraftaDelgivningProvider>
    </>)
}

export default BekraftaDelgivningWizard
