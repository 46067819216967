import React from 'react'
import PropTypes from 'prop-types'
import { ButtonSub, IconButton } from 'redet-react-components'

export class PaginationToolbar extends React.Component {

  static propTypes = {
    list: PropTypes.array.isRequired,
    listSliceIndexsCallback: PropTypes.func.isRequired,
    initPage: PropTypes.number,
    maxNoPageButtons: PropTypes.number,
    pageSize: PropTypes.number
  }

  static  defaultProps = {
    initPage: 0,
    maxNoPageButtons: 5,
    pageSize: 10
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      currentPage: props.initPage,
      numberOfPages: 0,
    }
  }

  componentDidMount () {
    this.setState({
      numberOfPages: this.getTotalNumberOfPages()
    })
    this.callbackSliceIndexs()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.list !== this.props.list) {
      this.setState({
        numberOfPages: this.getTotalNumberOfPages()
      })
      this.callbackSliceIndexs()
    }
    if (prevState.currentPage !== this.state.currentPage) {
      this.callbackSliceIndexs()
    }
  }

  getTotalNumberOfPages () {
    const numberOfFullPages = Math.trunc(this.props.list.length / this.props.pageSize)
    const numberOfPartialPages = this.props.list.length % this.props.pageSize > 0 ? 1 : 0
    return numberOfFullPages + numberOfPartialPages
  }

  currentPageIsLastPage () {
    return (this.state.currentPage + 1) === this.state.numberOfPages
  }

  currentPageIsFirstPage = () => this.state.currentPage === 0

  currentPageIsAmongTheFirstHalfOfmaxNoPageButtons = () => this.state.currentPage < Math.ceil(this.props.maxNoPageButtons / 2) - 1

  currentPageIsAmongTheLastHalfOfmaxNoPageButtons = () => this.state.currentPage >= this.state.numberOfPages - Math.ceil(this.props.maxNoPageButtons / 2) - 1

  filterBoxes (boxes) {
    if (boxes.length <= this.props.maxNoPageButtons)
      return boxes
    const noOverflow = boxes.length - this.props.maxNoPageButtons
    // WTF is .splice?! --> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
    if (this.currentPageIsFirstPage() || this.currentPageIsAmongTheFirstHalfOfmaxNoPageButtons()) {
      // Ta bort alla element överflödiga element förutom och spara det sista.
      boxes.splice(3, noOverflow + 1)
      // Lägg in överflödesindikator före sista knappen.
      boxes.splice(3, 0, <li key={'overflodesindikatorUniqeKey'}><IconButton disable={true} icon={'more_vert'}
                                                                             ariaLabel={'Överflödesindikator'}/></li>)
    } else if (this.currentPageIsLastPage() || this.currentPageIsAmongTheLastHalfOfmaxNoPageButtons()) {
      // Ta bort alla överflödiga element framifrån.
      boxes.splice(0, noOverflow)
    } else {
      // Ta bort lika mycket på vardera sida om nuvarande position.
      const lastBox = boxes[boxes.length - 1]
      boxes.splice(this.state.currentPage + Math.ceil((this.props.maxNoPageButtons - 2) / 2)) // Efter
      boxes.splice(0, this.state.currentPage - Math.floor((this.props.maxNoPageButtons - 2) / 2)) // Före
      boxes.push(<li key={'overflodesindikatorUniqeKey'}><IconButton disable={true} icon={'more_vert'}
                                                                     ariaLabel={'Överflödesindikator'}/>
      </li>)
      boxes.push(lastBox)
    }
    return boxes
  }

  previousPage = () => {
    // Om första sidan gör inget
    if (this.state.currentPage > 0) {
      this.setState({ currentPage: this.state.currentPage - 1 })
    }
  }

  nextPage = () => {
    // Om sista sidan gör inget
    if ((this.state.currentPage + 1) !== this.state.numberOfPages) {
      this.setState({ currentPage: this.state.currentPage + 1 })
    }
  }

  goToPage = (page) => {
    this.setState({ currentPage: page })
  }

  callbackSliceIndexs () {
    const startIndex = this.state.currentPage * this.props.pageSize
    const endIndex = startIndex + this.props.pageSize
    this.props.listSliceIndexsCallback(startIndex, endIndex)
  }

  renderNumberBoxes () {
    let boxes = []
    for (let i = 0; i < this.state.numberOfPages; i++) {
      const pageNumber = i + 1
      boxes.push(
        <li key={`paginationNumberBoxKey${i}`}>
          <ButtonSub
            id={`pageButtonId${i}`}
            name={'paginationNumberBox'}
            isActive={this.state.currentPage === i}
            onClick={() => this.goToPage(i)}
            ariaLabel={`Gå till sida  ${pageNumber}`}
            text={pageNumber.toString()}
          />
        </li>
      )
    }
    boxes = this.filterBoxes(boxes)
    return boxes
  }

  render () {
    return (
      <>
        {this.state.numberOfPages > 1 &&
          <ul className="pagination-list">
            <li>
              <IconButton
                id="arendeListFirstPageButton"
                icon="first_page"
                isDisabled={this.currentPageIsFirstPage()}
                onClick={() => this.goToPage(0)}
                ariaLabel={'Gå till första sidan.'}
              />
            </li>
            <li>
              <IconButton
                id="arendeListPreviousPageButton"
                icon="chevron_left"
                isDisabled={this.currentPageIsFirstPage()}
                onClick={this.previousPage}
                ariaLabel={'Gå till föregående sidan.'}
              />
            </li>
            {this.renderNumberBoxes()}
            <li>
              <IconButton
                id="arendeListNextPageButton"
                icon="chevron_right"
                isDisabled={this.currentPageIsLastPage()}
                onClick={this.nextPage}
                ariaLabel={'Gå till nästa sidan.'}
              />
            </li>
            <li>
              <IconButton
                id="arendeListLastPageButton"
                icon="last_page"
                isDisabled={this.currentPageIsLastPage()}
                onClick={() => this.goToPage(this.state.numberOfPages - 1)}
                ariaLabel={'Gå till sista sidan.'}
              />
            </li>
          </ul>
        }
      </>
    )
  }
}
