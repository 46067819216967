import {createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState} from "react";
import {DeltarPaSammantrade, Svarslista} from "../../../domain/Svarslistor";

export type BekraftaDelgivningContextType = {
    delgivning?: Svarslista
    setDelgivning: Dispatch<SetStateAction<Svarslista | undefined>>
    epost: string
    setEpost: Dispatch<SetStateAction<string>>
    telefonNr: string
    setTelefonNr: Dispatch<SetStateAction<string>>
    narvaro: DeltarPaSammantrade
    setNarvaro: Dispatch<SetStateAction<DeltarPaSammantrade>>
    clearForm: () => void
};


export const BekraftaDelgivningContext = createContext<BekraftaDelgivningContextType>({
    delgivning: undefined,
    setDelgivning: () => {},
    epost: '',
    setEpost: () => {},
    telefonNr: '',
    setTelefonNr: () => {},
    narvaro: DeltarPaSammantrade.EJANGIVET,
    setNarvaro: () => {},
    clearForm: () => {}
})

export function BekraftaDelgivningProvider(props: { children: ReactNode }) {

    const [delgivning, setDelgivning] = useState<Svarslista>()
    const [epost, setEpost] = useState<string>('')
    const [telefonNr, setTelefonNr] = useState<string>('')
    const [narvaro, setNarvaro] = useState<DeltarPaSammantrade>(DeltarPaSammantrade.EJANGIVET)

    const clearForm = () => {
        setDelgivning(undefined)
        setEpost('')
        setTelefonNr('')
        setNarvaro(DeltarPaSammantrade.EJANGIVET)
    }

    return (
        <BekraftaDelgivningContext.Provider value={useMemo(() => ({
            delgivning, setDelgivning,
            epost, setEpost,
            telefonNr, setTelefonNr,
            narvaro, setNarvaro,
            clearForm
        }), [delgivning, epost, telefonNr, narvaro])}>
            {props.children}
        </BekraftaDelgivningContext.Provider>
    )
}