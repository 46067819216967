import {SIGNER_STATUS, SIGNINGINSTANCE_STATUS} from "../domain/signing/SigningStatus";
import {ListItemStatus} from "../domain/ListItemStatus";

export default class SigningHelper {

    static getSignerListItemStatus = (status: SIGNER_STATUS, signingStatus: SIGNINGINSTANCE_STATUS): ListItemStatus => {
        switch (status) {
            case SIGNER_STATUS.APPROVED:
                return {icon: 'check', text: 'Underskriven'}
            case SIGNER_STATUS.DECLINED:
                return {icon: 'block', text: 'Avböjt att skriva under'}
            default:
                if (signingStatus === SIGNINGINSTANCE_STATUS.TIMED_OUT) {
                    return {icon: 'block', text: 'Bestyrkningstiden har löpt ut'}
                } else {
                    return {icon: 'schedule', text: 'Väntar på underskrift'}
                }
        }
    }
}