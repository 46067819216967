// @flow
// 1.21 En kontaktperson med rollen sökande måste ha en epostadress angiven
import { BLOCKING, FORETRADD_MASTE_VARA_SOKANDE } from './constants'
import { getAllContactsWhoHaveOmbudButIsNotSokande, getNamn } from '../../../../utils/contactsHelper'
import { Validation } from './model/Validation'

function validate (state: Object): ?Array<Validation> {
  const contacts = state.contacts.contactList
  const invalidContacts = getAllContactsWhoHaveOmbudButIsNotSokande(contacts)
  const invalidContactsName = invalidContacts.map(contact => getNamn(contact))
  if (invalidContacts?.length > 0) {
    return invalidContactsName.map((name) => (
      new Validation(FORETRADD_MASTE_VARA_SOKANDE, `${name} som företräds av någon annan måste ha rollen sökande.`, 'contacts', BLOCKING, undefined)))
  }
}

export default validate
