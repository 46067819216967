import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createFileHash, filesEqual, sumAndFormatFileSize } from '../../../helpers/fileHelpers'
import {
  MAX_NUMBER_OF_FILES_IN_YRKANDE_KARTSKISS,
  MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL,
  MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING
} from '../../../utils/globalConstants'
import { WizardNavigationButtonToolbar } from '../../../components/wizard/WizardFormElements'
import {
  ButtonTop,
  FormFieldset,
  ListGroup,
  ListItem,
  ListItemActionButton,
  VarningsRuta
} from 'redet-react-components'
import { logInfo } from '../../../utils/log'

export class ValjFilerWizardStep extends Component {
  static propTypes = {
    // Props
    setFilesCallback: PropTypes.func.isRequired,
    files: PropTypes.array,
    // Wizard
    nextStep: PropTypes.func,
    nextButton: PropTypes.shape({ text: PropTypes.string, ariaLabel: PropTypes.string }),
    prevButton: PropTypes.shape({ text: PropTypes.string, ariaLabel: PropTypes.string, onClick: PropTypes.func }),
  }

  static defaultProps = {
    nextButton: { text: 'Namnge kartskiss', ariaLabel: 'Välj vald kartskiss' },
    files: []
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      errorMessages: [],
    }
    this.filterFilesByType = this.filterFilesByType.bind(this)
    this.filterFilesBySize = this.filterFilesBySize.bind(this)
    this.filterFilesByDuplicates = this.filterFilesByDuplicates.bind(this)
    this.preventDefault = this.preventDefault.bind(this)
    this.fileDropped = this.fileDropped.bind(this)
    this.onFileAdded = this.onFileAdded.bind(this)
    this.goToNextStep = this.goToNextStep.bind(this)
    this.goToPrevStep = this.goToPrevStep.bind(this)
    this.addFingerprintIdToFiles = this.addFingerprintIdToFiles.bind(this)
  }

  onFileAdded (event) {
    if (event.target.files.length > 0) {
      this.addFilesToList(event.target.files)
      // Clear the input control once we have its data so that it can be reused without any odd behaviour
      event.target.value = null
    }
  }

  addFilesToList (files) {
    this.setState({ errorMessages: [] })
    let filesToAdd = Array.from(files)

    // Remember that the filter order is important for the behaviour
    const filterMethods = [
      this.filterFilesByDuplicates.bind(this),
      this.filterFilesByType.bind(this),
      this.filterFilesBySize.bind(this),
      this.filterFilesByAccumulatedSize.bind(this),
      this.filterFilesByCount.bind(this)
    ]
    filterMethods.forEach(fn => {
      const filterResult = fn(filesToAdd)
      filesToAdd = filterResult.filteredFiles
      if (filterResult.errorMessage) {
        this.setState(prevState => ({
          errorMessages: [...prevState.errorMessages, filterResult.errorMessage]
        }))
      }
    })
    filesToAdd = this.addFingerprintIdToFiles(filesToAdd)
    if (filesToAdd.length > 0) {
      if (this.props.files === undefined)
        this.props.setFilesCallback([...filesToAdd])
      else
        this.props.setFilesCallback([...this.props.files, ...filesToAdd])
    }
  }

  filterFilesByDuplicates (files) {
    const filteredFiles = []
    let errorMessage = null
    files.forEach(file => {
      // Only add the files that aren't already added and filter out any duplicated files selected (though this should have been prevented by the OS already)
      if (!(this.props.files.some(selectedFile => filesEqual(selectedFile, file)) || filteredFiles.some(filteredFile => filesEqual(filteredFile, file)))) {
        filteredFiles.push(file)
      } else {
        errorMessage = 'Du har valt samma fil flera gånger, dubbletter har därför filtrerats bort.'
      }
    })
    return { filteredFiles, errorMessage }
  }

  filterFilesByAccumulatedSize (files) {
    const filteredFiles = []
    let errorMessage = null
    const maxTotalFileSizeInBytes = MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING * 1024 * 1024

    files.forEach(file => {
      const alreadyAddedFilesTotalSize = this.props.files.map(selectedFile => selectedFile.size).reduce((x, y) => x + y, 0)
      const filesAlreadyPassedFilterTotalSize = filteredFiles.map(filePassed => filePassed.size).reduce((x, y) => x + y, 0)
      if ((alreadyAddedFilesTotalSize + filesAlreadyPassedFilterTotalSize + file.size) <= maxTotalFileSizeInBytes) {
        filteredFiles.push(file)
      } else {
        errorMessage = `En eller fler av de valda filerna har tagits bort eftersom maxstorleken på ${MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING}MB överskreds.`
      }
    })

    return { filteredFiles, errorMessage }
  }

  filterFilesByCount (files) {
    let filteredFiles = files
    let errorMessage = null

    if ((this.props.files.length + files.length) > MAX_NUMBER_OF_FILES_IN_YRKANDE_KARTSKISS) {
      errorMessage = `En bifogad handling får max innehålla ${MAX_NUMBER_OF_FILES_IN_YRKANDE_KARTSKISS} filer`
      filteredFiles = files.slice(0, MAX_NUMBER_OF_FILES_IN_YRKANDE_KARTSKISS - this.props.files.length)
      logInfo(`En bifogad handling får max innehålla ${MAX_NUMBER_OF_FILES_IN_YRKANDE_KARTSKISS} filer`)
    }

    return { filteredFiles, errorMessage }
  }

  addFingerprintIdToFiles (files) {
    return files.map(file => {
      file['fingerprint'] = createFileHash(file)
      return file
    })
  }

  fileDropped (event) {
    this.preventDefault(event)
    this.addFilesToList(event.dataTransfer.files)
  }

  matchImageContentTypes () {
    return file => file.type && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'application/pdf')
  }

  filterFilesByType (files) {
    // Only add files with the right type
    const filteredFiles = files.filter(
      file => file.type && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'application/pdf')
    )

    let errorMessage = null

    if (filteredFiles.length !== files.length) {
      errorMessage = 'Filen som du valt har en otillåten filtyp'
    }

    return { filteredFiles, errorMessage }
  }

  filterFilesBySize (files) {
    let filteredFiles = files
    let errorMessage = null
    const maxFileSizeInBytes =
      MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL * 1024 * 1024

    if (filteredFiles.some(file => file.size > maxFileSizeInBytes)) {
      errorMessage = `En eller fler av de valda filerna har tagits bort eftersom maxstorleken på ${MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL}MB överskreds.`
      filteredFiles = files.filter(file => file.size <= maxFileSizeInBytes)
    }

    return { filteredFiles, errorMessage }
  }

  preventDefault (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  goToNextStep () {
    if (this.props.files !== undefined) {
      this.props.nextStep()
    }
  }

  goToPrevStep () {
    if (this.props.prevButton?.onClick)
      this.props.prevButton.onClick()
    this.props.previousStep()
  }

  render () {
    return (
      <>
        <FormFieldset title={'Välj fil(er) att ladda upp'}
                      description={'(JPG, GIF, PNG eller PDF)'}
                      id={'valjFilerSomSkaLaddasUppComponentId'}>
          <div className="file-upload">
            <span className="area"><span className="active"><label htmlFor="fileSelectionInput">Välj fil(er)</label></span></span>
            <input type="file"
                   id={'fileSelectionInput'}
                   className="multiple-files"
                   multiple={true}
                   aria-label="Multifilsuppladdning."
                   aria-describedby={'valjFilerSomSkaLaddasUppComponentId'}
                   accept=".gif, .jpg, .jpeg, .png, .pdf"
                   onChange={this.onFileAdded}
                   onDrop={this.fileDropped}
                   onDrag={this.preventDefault}
                   onDragStart={this.preventDefault}
                   onDragEnd={this.preventDefault}
                   onDragOver={this.preventDefault}
                   onDragEnter={this.preventDefault}
                   onDragLeave={this.preventDefault}
            />
          </div>
        </FormFieldset>
        {this.state.errorMessages.length > 0 && (
          <VarningsRuta id={'errorUploadMessageId'}
          ariaTitle='Information angående fel som uppstått'>
            {this.state.errorMessages.map((errorMessage, index) => (
              <p className="mandatory" key={'error_2_' + index}>{errorMessage}</p>
            ))}
          </VarningsRuta>
        )}
        {this.props.files && this.props.files.length > 0 && (
          <>
            <ListGroup title={'Valda filer'} id={'fileListId'}>
              {this.props.files.map(({ name, size, fingerprint }) => (
                <ListItem header={name}
                          key={`${fingerprint}Key`}
                          id={`${fingerprint}Id`}
                          description={sumAndFormatFileSize([{ fileSize: size }])}
                          actionButtons={[<ListItemActionButton ariaLabel={'Ta bort'}
                                                                icon={'delete'}
                                                                id={`delete${fingerprint}Id`}
                                                                key={`delete${fingerprint}Key`}
                                                                onClick={() => this.removeFileFromFiles(fingerprint)}/>]}/>
              ))}
            </ListGroup>
          </>
        )}
        <WizardNavigationButtonToolbar prevButtonText={this.props.prevButton?.text}
                                       onClickPrevButton={this.goToPrevStep}>
          <ButtonTop text={this.props.nextButton.text}
                     id={'nextButtonId'}
                     onClick={this.goToNextStep}
                     isDisabled={this.props.files === undefined || this.props.files.length === 0}
                     icon={'arrow_forward'}
                     ariaLabel={this.props.nextButton.ariaLabel}/>
        </WizardNavigationButtonToolbar>
      </>
    )
  }

  removeFileFromFiles (fileFingerprint) {
    const files = this.props.files.filter(file => file.fingerprint !== fileFingerprint)
    this.props.setFilesCallback(files)
  }
}
