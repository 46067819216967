const DISPLAY = 'fullscreenProgress/DISPLAY'
const UPDATE = 'fullscreenProgress/UPDATE'
const HIDE = 'fullscreenProgress/HIDE'

const initialState = {
  visible: false,
  procentComplete: 0,
  hidePercentage: false,
  abortFunction: null,
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case DISPLAY:
      return {
        ...state,
        visible: true,
        procentComplete: 0,
        abortFunction: action.abortFunction,
        hidePercentage: action.hidePercentage,
      }

    case UPDATE:
      return {
        ...state,
        procentComplete: action.procentComplete,
      }

    case HIDE:
      return {
        ...state,
        visible: false,
        procentComplete: 0,
        abortFunction: action.abortFunction,
      }

    default:
      return state
  }
}

export function displayFullscreenProgress (abortFunction, hidePercentage) {
  return {
    type: DISPLAY,
    abortFunction,
    hidePercentage,
  }
}

export function updateFullscreenProgress (procentComplete) {
  return {
    type: UPDATE,
    procentComplete
  }
}

export function hideFullscreenProgress () {
  return {
    type: HIDE
  }
}
