import React, {useState} from "react";
import {Navigation} from "../components";
import {PageHeader} from "components/General/forms/layoutElements";
//@ts-ignore
import {Helmet} from "react-helmet";
import {JumpLinkToMain} from "../components/General/JumpLinkToMain";
//@ts-ignore
import {ButtonToolbar, Column, LinkButtonTop, PageTopContent, Row, ExternalLink, SidePanelChapter} from 'redet-react-components'
import {IE11modalDialogWarning} from "./Ansokan";
import {getLoginUrlSDG} from "../helpers/loginUrl";
import {Trans, useTranslation} from "react-i18next";
import HjalpForSDG from "./euCitizenApplication/HjalpForSDG";
import {HELP_TERM_LINK} from "../helpers/LocaleComponentHelper";

export default function StartInloggningSDG() {

    const [urlSDG] = useState(getLoginUrlSDG());
    const {t} = useTranslation();

    const getSDGbeskrivning = () => {
        return (
            <>
                <p className={"introduction"}>
                    <Trans i18nKey="login_sdg.info_text_one"
                           components={{help_term: <HELP_TERM_LINK moment={'help-power-of-attorney'}/>}}/>
                    <b>{t("login_sdg.info_text_bold")}</b>
                    {t("login_sdg.info_text_two")}
                    <ExternalLink url={"https://dafi.lantmateriet.se/sv/login?sdg=true"} text={t("login_sdg.info_link")}/>
                </p>
            </>
        );
    };


    return (
        <div>
            <JumpLinkToMain/>
            <Helmet>
                <title>{t("login_sdg.title")}</title>
            </Helmet>
            <IE11modalDialogWarning/>
            <header>
                <Navigation
                    showNotiserBtn={false}
                    showLanguageSelector={true}
                    showSDGToUModal={true}
                    showNormalToUModal={false}
                />
                <PageHeader
                    title={t("login_sdg.title")}
                    introduction={getSDGbeskrivning()}
                />
            </header>
            <main id="startPageMainContentId">
                <div className="container">
                    <Row>
                        <Column columns="col-12 col-md-12 col-lg-4 offset-lg-4">
                            <PageTopContent
                                headerId={"innanStartHeaderId"}
                                headerLevel={2}
                                title={t("login_sdg.login")}
                            >
                                <br/>
                                <p>
                                    {t("login_sdg.login_eid")}
                                </p>
                                <ButtonToolbar>
                                    <div className="button-group">
                                        <LinkButtonTop
                                            text={t("login_sdg.login")}
                                            icon={"login"}
                                            isActive={true}
                                            id="loginButton"
                                            link={urlSDG}
                                        />
                                    </div>
                                </ButtonToolbar>
                            </PageTopContent>
                        </Column>
                    </Row>
                </div>
                <HjalpForSDG/>
            </main>
        </div>
    );
}
