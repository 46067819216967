import { formatArea, formatLength } from './measurement.js'
import { FONT_SIZE } from './mapConstants'
import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import { LineString, MultiPoint, Point } from 'ol/geom'

function formatText (feature, map) {
  let text = undefined
  if (feature.get('drawLineMeasurements')) {
    text = formatLength(feature.getGeometry(), map.getView().getProjection())
  } else if (feature.get('drawPolygonMeasurements')) {
    if (feature.getGeometry().getCoordinates()[0].length > 2) {
      text = formatArea(feature.getGeometry(), map.getView().getProjection(), false)
    }
  } else {
    text = feature.get('text')
  }
  return text
}

function getTextStyle (feature, map) {

  return new Text({
    textAlign: 'center',
    textBaseline: 'bottom',
    // Move texts slightly up for points
    offsetY: feature.getGeometry().getType() === 'Point' ? '-7' : '0',
    font: FONT_SIZE + 'px sans-serif',
    text: formatText(feature, map),
    fill: new Fill({
      color: 'black'
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 1.0)',
      width: 3
    }),
  })
}

function getXPixelCoordinate (coordinate, map) {
  return map.getPixelFromCoordinate(coordinate)[0]
}

function getYPixelCoordinate (coordinate, map) {
  return map.getPixelFromCoordinate(coordinate)[1]
}

function calculateTextOffset (feature, currentCoordinate, nextCoordinate, map) {
  const thisXCoordinate = getXPixelCoordinate(currentCoordinate, map)
  const nextXCoordinate = getXPixelCoordinate(nextCoordinate, map)
  const halfXDistanceToNextCoordinate = (nextXCoordinate - thisXCoordinate) / 2
  const thisYCoordinate = getYPixelCoordinate(currentCoordinate, map)
  const nextYCoordinate = getYPixelCoordinate(nextCoordinate, map)
  const halfYDistanceToNextCoordinate = (nextYCoordinate - thisYCoordinate) / 2
  const midPointCoordinateX = getXPixelCoordinate(feature.getGeometry().getInteriorPoint().getFirstCoordinate(), map)
  const midPointCoordinateY = getYPixelCoordinate(feature.getGeometry().getInteriorPoint().getFirstCoordinate(), map)
  // To find the correct placement of the text (which is in the middle of the line between two polygon points) we take the
  // original position and add half of the distance to the next coordinate. Finally we subtract the original position
  // which is the same as the polygon's interor point so that we get only the offset rather than the absolut coordinate.
  return [thisXCoordinate + halfXDistanceToNextCoordinate - midPointCoordinateX, thisYCoordinate + halfYDistanceToNextCoordinate - midPointCoordinateY]
}

function addPolygonLineLengths (feature, style, map) {
  const textStyles = []

  if (feature.getGeometry().getType() === 'Polygon') {
    const coordinates = feature.getGeometry().getCoordinates()[0]
    // If a node has been created there are more than one coordinate
    if (coordinates.length > 1) {
      for (let i = 0; i < coordinates.length; i++) {
        const isLastCoordinate = i === coordinates.length - 1
        const firstCoordinate = coordinates[0]
        const currentCoordinate = coordinates[i]
        // For the last coordinate the first one is the next
        const nextCoordinate = isLastCoordinate ? firstCoordinate : coordinates[i + 1]

        // For a polygon that is finished the last coordinate is located in the same spot at the first
        // so the length between them would always be 0 and is therefore pointless to display
        if ((currentCoordinate[0] === nextCoordinate[0]) && (currentCoordinate[1] === nextCoordinate[1])) {
          continue
        }

        const offset = calculateTextOffset(feature, currentCoordinate, nextCoordinate, map)

        textStyles.push(new Style({
          text: new Text({
            textAlign: 'center',
            textBaseline: 'bottom',
            // Move texts slightly up for points
            offsetX: offset[0],
            offsetY: offset[1],
            font: FONT_SIZE + 'px sans-serif',
            // For the last coordinate the next coordinate is actually the first coordinate
            text: formatLength(new LineString([currentCoordinate, nextCoordinate]), map.getView().getProjection()),
            fill: new Fill({
              color: 'black'
            }),
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, 1.0)',
              width: 3
            })
          })
        }))
      }
    }
  }

  return textStyles
}

export function getFastighetStyles () {

  const styles = [
    /* We are using two different styles for the polygons:
     *  - The first style is for the polygons themselves.
     *  - The second style is to draw the vertices of the polygons.
     *    In a custom `geometry` function the vertices of a polygon are
     *    returned as `MultiPoint` geometry, which will be used to render
     *    the style.
     */
    new Style({
      stroke: new Stroke({
        color: 'rgba(73, 253, 253, 0.6)',
        width: 5
      }),
      fill: new Fill({
        color: 'rgba(73, 253, 253, 0.2)'
      })
    }),
  ]

  return styles
}

export function getViewStyles (feature, map) {

  const styles = [
    /* We are using two different styles for the polygons:
     *  - The first style is for the polygons themselves.
     *  - The second style is to draw the vertices of the polygons.
     *    In a custom `geometry` function the vertices of a polygon are
     *    returned as `MultiPoint` geometry, which will be used to render
     *    the style.
     */
    new Style({
      stroke: new Stroke({
        color: 'black',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)'
      })
    }),
    new Style({
      text: getTextStyle(feature, map)
    }),
    new Style({
      image: new Circle({
        radius: 8,
        fill: new Fill({
          color: 'black'
        })
      }),
      geometry (featureWithGeometry) {
        let geometry = undefined

        // Hide points for texts
        if (featureWithGeometry.getGeometry().getType() === 'Point' && !featureWithGeometry.get('isTextPoint')) {
          geometry = new Point(featureWithGeometry.getGeometry().getCoordinates())
        }

        return geometry
      }
    })
  ]
  if (feature.get('drawPolygonMeasurements')) {
    styles.push(...addPolygonLineLengths(feature, styles, map))
  }
  return styles
}

export function getEditStyles (feature, map) {

  const styles = [
    /* We are using two different styles for the polygons:
     *  - The first style is for the polygons themselves.
     *  - The second style is to draw the vertices of the polygons.
     *    In a custom `geometry` function the vertices of a polygon are
     *    returned as `MultiPoint` geometry, which will be used to render
     *    the style.
     */
    new Style({
      stroke: new Stroke({
        color: (feature.get('isMeasureLine') || feature.get('isMeasurePolygon')) ? 'orange' : 'black',
        lineDash: (feature.get('isMeasureLine') || feature.get('isMeasurePolygon')) ? [10] : undefined,
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)'
      })
    }),
    // At the moment we actally draw texts for all features but
    // only those that have any text will be visible to the eye
    new Style({
      text: getTextStyle(feature, map)
    }),
    new Style({
      image: new Circle({
        radius: 8,
        fill: new Fill({
          color: 'orange'
        })
      }),
      geometry (featureWithGeometry) {
        let geometry = undefined
        if (featureWithGeometry.getGeometry().getType() === 'LineString') {
          geometry = new MultiPoint(featureWithGeometry.getGeometry().getCoordinates())
        } else if (featureWithGeometry.getGeometry().getType() === 'Polygon') {
          // return the coordinates of the first ring of the polygon
          geometry = new MultiPoint(featureWithGeometry.getGeometry().getCoordinates()[0])
        } else {
          geometry = new Point(featureWithGeometry.getGeometry().getCoordinates())
        }

        return geometry
      }
    })
  ]

  if (feature.get('drawPolygonMeasurements') || feature.get('isMeasurePolygon')) {
    styles.push(...addPolygonLineLengths(feature, styles, map))
  }

  return styles
}

export function getMeasureStyles (feature, map) {

  let style = new Style({
    stroke: new Stroke({
      color: 'orange',
      lineDash: [10],
      width: 3
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)'
    }),
    image: new Circle({
      radius: 5,
      fill: new Fill({
        color: 'orange'
      })
    }),
  })

  if (feature.get('isMeasurePolygon')) {
    style = [style, ...addPolygonLineLengths(feature, style, map)]
  }

  return style
}

function defaultEditingStyle () {
  const white = [255, 255, 255, 1]
  const blue = [0, 153, 255, 1]
  const width = 3
  const styles = [
    new Style({
      fill: new Fill({
        color: [255, 255, 255, 0.5]
      })
    }),
    new Style({
      stroke: new Stroke({
        color: white,
        width: width + 2
      })
    }),
    new Style({
      stroke: new Stroke({
        color: blue,
        width: width
      })
    }),
    new Style({
      image: new Circle({
        radius: width * 2,
        fill: new Fill({
          color: blue
        }),
        stroke: new Stroke({
          color: white,
          width: width / 2
        })
      }),
      zIndex: Infinity
    })
  ]

  return styles
}

export function getDefaultDrawingStylesWithMeasurementLabels (feature, map) {

  const style = [
    ...defaultEditingStyle(),
    new Style({
      text: getTextStyle(feature, map)
    }),
  ]

  style.push(...addPolygonLineLengths(feature, style, map))

  return style
}
