import Log from './log'

export function logFeedback (feedback) {
  const log = new Log('Feedback')
  log.info(feedback)

  fetch('/logfeedback', {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({ comment: feedback.comment, rating: feedback.rating }),
    headers: { 'Content-Type': 'application/json' }
  }).then(() => {
    log.info('Sent feedback to server')
  }).catch((err) => {
    if (process.env.NODE_ENV !== 'production') {
      log.error('Failed to send error info to server, this error info is only visible in dev-mode: ', err)
    } else {
      log.error('Failed to send error info to server')
    }
  })
}
