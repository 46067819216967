const ADD_PROGRESS = 'progressBar/ADD_PROGRESS'
const END_PROGRESS = 'progressBar/END_PROGRESS'

const initialState = {
  visible: false,
  text: '',
  hideContent: false,
  counter: 0,
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ADD_PROGRESS:
      return {
        ...state,
        visible: true,
        counter: state.counter + 1,
        hideContent: action.hideContent === undefined ? state.hideContent : (action.hideContent || state.hideContent),
        text: action.text
      }
    case END_PROGRESS:
      const newCount = state.counter > 0 ? state.counter - 1 : 0
      return {
        ...state,
        counter: newCount,
        visible: newCount > 0,
        hideContent: newCount === 0 ? false : state.hideContent,
      }

    default:
      return state
  }
}

export function addProgress (hideContent = true, text) {
  return {
    type: ADD_PROGRESS,
    hideContent,
    text,
  }
}

export function endProgress () {
  return {
    type: END_PROGRESS
  }
}
