// @flow
// 1.1 En ansökan ska innehålla minst 1 fastighet

import { BLOCKING, MINST_EN_BIFOGAD_HANDLING } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  if (state.bifogadHandling.numberOfBifogadeHandlingar === 0
    && (state.bifogadHandling.hasUserChosenNotToUploadBifogadHandlig === false
      || state.bifogadHandling.hasUserChosenNotToUploadBifogadHandlig === undefined)) {
    return new Validation(MINST_EN_BIFOGAD_HANDLING, 'Du har inte bifogat någon handling.', 'handlingar', BLOCKING, undefined)
  }
  return undefined
}

export default validate
