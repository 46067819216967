import React, { Component } from 'react'
//@ts-ignore
import { ButtonTop } from 'redet-react-components'

function DocumentPullupList(props: {children: []}) {
    return (
      <div className="dropdown">
        <ButtonTop
          className="button-top dropdown-toggle"
          id="download_document_menu"
          aria-haspopup="true"
          aria-expanded="true"
          data-bs-toggle="dropdown"
          icon="menu"
          text="Ladda ned dokument"
        />
        {props.children}
      </div>
    )
}


export default (DocumentPullupList)