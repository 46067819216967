import {getLocalizedDateString} from "../../../../helpers/dates";
import {
// @ts-ignore
    Checkbox, Felruta, FormFieldset, ListGroup, ListItem, VarningsRuta, ButtonGroup, ButtonToolbarV2, ButtonTop, OkRuta
} from 'redet-react-components'
import {Svarslista} from "../../../../domain/Svarslistor";
import React, {ChangeEvent, MouseEvent, useEffect, useState} from "react";
import {Handling} from "../../../../domain/Handling";
import {useAppDispatch} from "../../../../hooks";
import {svaraPaDelgivning} from "../../../../redux/modules/arendesvarslistor";
import {BekraftaDelgivningContext} from "../BekraftaDelgivningContext";
import {SvaraPaDelgivningRequest} from "../../../../repository/svarslistaRepository";

type Props = {
    obesvaradeSvarslistor: Svarslista[]
    svarslistorIsLoading?: boolean
    onSubmitSuccess?: () => void
    onSubmitError?: (err: Error) => void
    onCloseModal?: () => void
    //Injectas med clone element från WizardDialogStep :sick!
    firstStep?: () => void
    previousStep?: () => void
}

export function DelgivningHandlingarConfirmStep({
                                                    obesvaradeSvarslistor,
                                                    svarslistorIsLoading = false,
                                                    onSubmitSuccess,
                                                    onSubmitError,
                                                    onCloseModal,
                                                    firstStep,
                                                    previousStep
                                                }: Props) {
    const LIST_PAGE_SIZE = 3

    const {delgivning, epost, telefonNr, narvaro, clearForm} = React.useContext(BekraftaDelgivningContext);

    const [documentsConfirmed, setDocumentsConfirmed] = useState(false)
    const [documentsConfirmedValidationMsg, setDocumentsConfirmedValidationMsg] = useState<string>('')

    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    const [initHandlingarList, setInitHandlingarList] = useState<Handling[]>([])
    const [handlingarList, setHandlingarList] = useState<Handling[]>([])

    const handlingarListOptions = {
        list: initHandlingarList,
        listSetter: setHandlingarList,
        sortConfig: undefined,
        paginationConfig: {
            initPage: 0,
            maxNoPageButtons: 5,
            pageSize: LIST_PAGE_SIZE,
            onPageChangeScrollToTopOfList: false
        }
    }
    const handlingLabel = delgivning && delgivning.handlingar.length <= 1 ? 'handling' : 'handlingar'
    const createdDateLabel = getLocalizedDateString(delgivning?.skapad, 'yyyy-MM-dd HH:mm')

    const getAktbilageBeteckningLabel = (handling: Handling) => handling.aktbilagebeteckning?.trim() ? handling.aktbilagebeteckning : '--'
    const moreDelgivningarExists = () => obesvaradeSvarslistor.length > 0

    const dispatch = useAppDispatch();

    useEffect(() => {
        setInitHandlingarList(delgivning?.handlingar.slice() ?? [])
    }, [])

    const onConfirmDocumentsValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const checked = evt.target.checked
        setDocumentsConfirmed(checked)
        //reset validation message
        if (checked && documentsConfirmedValidationMsg)
            setDocumentsConfirmedValidationMsg('')
    }

    const onSubmitButtonClick = (evt: MouseEvent<HTMLInputElement>) => {
        //validate
        const valid = documentsConfirmed
        const message = 'Du måste bekräfta att du tagit del av handlingarna'
        setDocumentsConfirmedValidationMsg(!valid ? message : '')
        //if valid, submit!
        if (valid)
            submitForm()
    }

    const createRequest = (delgivning:Svarslista):SvaraPaDelgivningRequest => {
        const kontaktuppgifterSaknas = ():boolean  => !epost && !telefonNr
        return {
            deltarPaSammantrade: delgivning.efterfragaDeltagandePaSammantrade ? narvaro : undefined,
            kontaktuppgift: !kontaktuppgifterSaknas() ?
                {
                    epostadress: epost || undefined,
                    telefonnummer: telefonNr || undefined
                } : undefined
        }
    }

    function submitForm() {
        if (delgivning) {
            const request = createRequest(delgivning)
            setIsSubmitting(true)
            dispatch(svaraPaDelgivning(delgivning.id, request))
                .then((response) => {
                    clearForm()
                    setHasSubmitted(true)
                    if (onSubmitSuccess)
                        onSubmitSuccess()
                })
                .catch((err: any) => {
                    switch (err?.httpStatusCode) {
                        case 403:
                            setErrorMessage('Bekräftelsen kunde inte registreras eftersom att du redan svarat på denna delgivning')
                            break
                        default:
                            setErrorMessage('Det gick inte att skicka in ditt svar just nu. Försök igen lite senare eller kontakta Lantmäteriets kundcenter')
                    }
                    if (onSubmitError)
                        onSubmitError(err)
                })
                .finally(() => setIsSubmitting(false))
        }
    }

    return <>
        {delgivning && !hasSubmitted && <>
            <FormFieldset
                title={`Bekräfta delgivning ${createdDateLabel} (${delgivning.handlingar.length} ${handlingLabel})`}
                description={`Lantmäteriet behöver veta att du tagit del av följande ${handlingLabel} i ärendet ${delgivning.arendenummer}`}>
                {/*documents*/}
                <ListGroup id={'delgivning-handlingar'}
                           data-selenium-id={'delgivning-handlingar-list'}
                           listOptions={handlingarListOptions}>
                    {handlingarList.map((handling, index) =>
                        <ListItem header={handling.namn}
                                  id={`delgivning-handling-no${index}`}
                                  key={`delgiven-handling-${handling.aktbilagebeteckning}-${index}-key`}
                                  hasTableActionButtons={false}
                                  descriptionChildren={<>
                                          <span className={'description'}>
                                              Aktbilaga <span className={'item'}>{getAktbilageBeteckningLabel(handling)}</span>
                                          </span>
                                  </>}
                        />)}
                </ListGroup>
                {initHandlingarList.length > LIST_PAGE_SIZE && <br />}
                <VarningsRuta ariaTitle={'Observera'}
                              description={<>
                                  Om du meddelat detta på något annat sätt behöver du inte bekräfta här.<br/>
                                  Om du inte meddelar att du blivit delgiven kan delgivningen ske genom stämningsman.
                              </>}/>
                {/*confirmation*/}
                <div className='form-group'>
                    <Checkbox label={'Jag bekräftar att jag tagit del av handlingarna ovan.'}
                              id={'confirmDocumentsCheckbox'}
                              errorMsg={documentsConfirmedValidationMsg}
                              onChange={onConfirmDocumentsValueChange}/>
                </div>
            </FormFieldset>
        </>}
        {hasSubmitted && <>
            <OkRuta messages={['Tack! Din bekräftelse har skickats in']}/>
        </>}
        {errorMessage && <Felruta errorMessages={[errorMessage]}/>}
        {/*navigeringsknappar*/}
        <ButtonToolbarV2>
            {/*om inte error och inte inskickad, visa föregående och bekräfta*/}
            {!errorMessage && !hasSubmitted && <>
                <ButtonGroup>
                    <ButtonTop text={'Kontaktuppgifter'}
                               onClick={previousStep}
                               icon={'arrow_back'}
                               id={'previous-step-button'}
                               ariaLabel={'Tillbaka till kontaktuppgifter'}/>
                </ButtonGroup>
                <ButtonGroup>
                    <ButtonTop text={'Bekräfta delgivning'}
                               data-selenium-id='submitDelgivningButton'
                               icon={'check'}
                               onClick={isSubmitting ? undefined: onSubmitButtonClick}
                               isActive={documentsConfirmed}
                               isLoading={isSubmitting}
                               id={'next-step-button'}
                               ariaLabel={'Bekräfta delgivningen'}/>
                </ButtonGroup>
            </>}
            {/*om inte error on inskickad och inte svarlistorna laddar om*/}
            {!errorMessage && hasSubmitted && !svarslistorIsLoading && <>
                <ButtonGroup>
                    {moreDelgivningarExists() ?
                        <>
                            <ButtonTop text={'Bekräfta nästa'}
                                       onClick={firstStep}
                                       icon={'replay'}
                                       id={'confirm-next-button'}
                                       ariaLabel={'Bekräfta nästa delgivning'}
                                       isDisabled={!hasSubmitted}/>
                        </> : <>
                            {onCloseModal && <ButtonTop text={'Ok'}
                                                        onClick={() => onCloseModal()}
                                                        icon={'check'}
                                                        id={'close-wizard-button'}
                                                        ariaLabel={'Stäng dialog'}/>}
                        </>}
                </ButtonGroup>
            </>}
            {/*om error och close-function finns*/}
            {errorMessage && onCloseModal && <>
                <ButtonGroup>
                    <ButtonTop text={'Stäng'}
                               onClick={() => onCloseModal()}
                               icon={'check'}
                               id={'close-wizard-button'}
                               ariaLabel={'Stäng dialog'}/>
                </ButtonGroup>
            </>}
        </ButtonToolbarV2>
    </>
}
