import { CURRENT_USER_TERMS_OF_USAGE_VERSION } from '../pages/TermsOfUsage'
import md5 from 'md5'

export function createTermsOfUsageHash (user) {
  if (user === undefined) return undefined

  if(user.cn){
    return md5(`${CURRENT_USER_TERMS_OF_USAGE_VERSION}:${user.cn.substring(0, 8) + user.fullName}`)
  } else {
    return md5(`${CURRENT_USER_TERMS_OF_USAGE_VERSION}:${user.eidasPersonIdentifier.substring(0, 8) + user.fullName}`)
  }
}
