import {FeatureToggle, FeatureToggleNotis, NOTIFICATION_METADATA, ONOFF_METADATA} from './FeatureToggle'
import Log from '../../utils/log'

const log = new Log('FeatureToggleMapper')

export function mapFeatureToggleJsonToFeatureToggles (featureToggles: any): Map<string, FeatureToggle> {
  let featureToggleMap = new Map<string, FeatureToggle>();
  if (!featureToggles || featureToggles.length < 1) {
    return featureToggleMap
  }
  try {
    featureToggles.toggles.map((toggle: FeatureToggle) => {
      let enabled = toggle.enabled ? toggle.enabled : false;
      return featureToggleMap.set(toggle.name,
          (toggle.metadata?.type === NOTIFICATION_METADATA) ?
              {
                name: toggle.name,
                enabled: enabled,
                unread: false,
              metadata: {message: toggle.metadata?.message, type: NOTIFICATION_METADATA, header: toggle.metadata?.header, severity: toggle.metadata?.severity}
              } :
              {name: toggle.name, enabled: enabled, metadata: {type: ONOFF_METADATA}})
    })
    return featureToggleMap
  } catch (error) {
    log.error('Följande fel uppstod vid mappning av FeatureToggle-array', error)
    return featureToggleMap
  }
}

export function getOnOffToggles (featureToggles: Map<string, FeatureToggle>): Map<string, FeatureToggle> {
  if (featureToggles.size < 1) {
    return new Map<string, FeatureToggle>();
  }
  const newMap = new Map<string, FeatureToggle>();
  featureToggles.forEach((toggle) => {
    if(toggle.metadata && toggle.metadata.type === ONOFF_METADATA) {
      newMap.set(toggle.name, toggle);
    }
  })
  return newMap;
}

export function getNotificationToggles (featureToggles: Map<string, FeatureToggle>): FeatureToggleNotis[] {
  const arr : FeatureToggleNotis[] = [];
  if (featureToggles.size === 0) return arr

  featureToggles.forEach((toggle) => {
    if(toggle?.metadata?.type === NOTIFICATION_METADATA) {
      arr.push(toggle as FeatureToggleNotis)
    }
  })
  return arr;
}

