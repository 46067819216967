import { useState } from 'react'
import { connect } from 'react-redux'
import { findOwnersForProperty } from 'redux/modules/fastighetsagande'
import { addProgress, endProgress } from 'redux/modules/fullscreenLoading'
//@ts-ignore
import {ButtonTop, ButtonTopWithPopover, ModalDialogSub} from 'redet-react-components'
import { Fastighet } from 'domain/Ansokan'

const mapStateToProps = (store: StoreType) => {
  return {
    valdaFastigheter: store.fastigheter.fastigheterList
  }
}

type StoreType = {
  fastigheter: {
    fastigheterList: Fastighet[]
  }
}

const mapDispatchToProps = {
  findOwnersForProperty,
  addProgress,
  endProgress
}

type ImportContactsProps = {
  valdaFastigheter: any[],
  addProgress: Function,
  endProgress: Function,
  findOwnersForProperty: Function
}

function ImportContactsButton(props: ImportContactsProps) {

  const [_showImportContactModal, setShowImportContactModal] = useState(false);
  const [_showNoContactsToImportModal, setShowNoContactsToImportModal] = useState(false);
  const [_showImportContactErrorModal, setShowImportContactErrorModal] = useState(false);

  const accepteraHamtning = (valdaFastigheterObjektIdentiteter: any) => {
    props.addProgress(false)
    showImportContactModal(false)
    props.findOwnersForProperty(valdaFastigheterObjektIdentiteter)
      .then((result: any) => {
        setTimeout(() => {
          if (result.agare.length === 0) {
            props.endProgress()
            showNoContactsToImportModal()
          } else {
            props.endProgress()
          }
        }, 500)
      })
      .catch(() => {
        props.endProgress()
        showImportContactErrorModal()
      })
  }

  const showImportContactModal = (show = true) => {
    setShowImportContactModal(show)
  }

  const showNoContactsToImportModal = (show = true) => {
    setShowNoContactsToImportModal(show)
  }

  const showImportContactErrorModal = (show = true) => {
    setShowImportContactErrorModal(show)
  }

    const valdaFastigheterObjektIdentiteter = props.valdaFastigheter.map(fastighet => {
      return { objektidentitet: fastighet.data.objektidentitet }
    })

  const importButtonProps= {
    id:"importContactsButtonId",
    "data-selenium-id":"importeraKontakter",
    icon:'downloading',
    text:'Hämta ägare',
    isDisabled: valdaFastigheterObjektIdentiteter.length === 0,
    onClick:showImportContactModal
  }
    return <>
      {valdaFastigheterObjektIdentiteter.length === 0 ?
          <ButtonTopWithPopover
              {...importButtonProps}
              popoverText={'Du kan inte hämta ägare eftersom ingen fastighet är tillagd i ärendet'}
              popoverPlacement={'top'}
              showPopoverOnDisabled={true}
              /> : <ButtonTop {...importButtonProps}/>}
      {_showImportContactModal &&
        <ImportContactsModal onClickAccept={() => accepteraHamtning(valdaFastigheterObjektIdentiteter)}
                             onClickDecline={() => showImportContactModal(false)}/>}
      {_showNoContactsToImportModal &&
        <NoContacsFoundModal closeModalCallback={() => showNoContactsToImportModal(false)}/>}
      {_showImportContactErrorModal &&
        <ImportContacsErrorModal closeModalCallback={() => showImportContactErrorModal(false)}/>}
    </>
}

export const ImportContactsModal = (props:{ onClickAccept: Function, onClickDecline: Function }) =>
  <ModalDialogSub
    modalTitle={'Vill du hämta kontaktuppgifter för alla fastighetsägare?'}
    description={<>Du kan hämta kontaktuppgifter för ägare till de fastigheter du lagt till, de som inte kan hämtas lägger du till själv via <strong>Lägg till part manuellt</strong>. Tänk på att en
      hämtad kontaktuppgift kommer sakna telefonnummer och e-postadress, du kan komplettera med detta genom att klicka på kontakten
      i listan efter att den hämtats.</>}
    acceptButton={{ text: 'Ja', onClick: props.onClickAccept }}
    declineButton={{ text: 'Nej', onClick: props.onClickDecline }}/>

export const NoContacsFoundModal = (props:{ closeModalCallback: Function }) =>
  <ModalDialogSub modalTitle={'Hämta ägare'}
                  description={'Tyvärr hittades inga kontakter som kunde hämtas'}
                  closeModalCallback={props.closeModalCallback}/>

export const ImportContacsErrorModal = (props:{ closeModalCallback: Function }) =>
  <ModalDialogSub modalTitle={'Ägare kunde inte hämtas'}
                  description={'Ett tekniskt fel inträffade när ägare skulle hämtas för de valda fastigheterna. Vänligen prova igen senare eller lägg till kontaktuppgifterna manuellt.'}
                  closeModalCallback={props.closeModalCallback}/>

export default connect(mapStateToProps, mapDispatchToProps)(ImportContactsButton)