import * as interactions from '../../components/Map/interactions';
import {ANSOKAN_FORM_DESTROY} from './sharedActions';
import {v4 as uuidv4} from 'uuid';
import {LOAD_SUCCESSFUL as ANSOKAN_LOAD_SUCCESSFUL} from "./ansokanCRUD";
import {Fetcher} from "../../repository/fetcher";
import {getFastighetsOmraden, RegisterOmradeResponse} from "../../repository/fastighetRepository";
import {Dispatch} from "redux";
import {RootState} from "../../store";

const CHANGE_CENTER = 'map/CHANGE_CENTER';
const ZOOM_TO = 'map/ZOOM_TO';
const CHANGE_DRAWING_FEATURES = 'map/CHANGE_DRAWING_FEATURES';
const CHANGE_FASTIGHET_FEATURES = 'map/CHANGE_FASTIGHET_FEATURES';
const SELECT_TOOL = 'map/SELECT_TOOL';
const EDIT_MAP = 'map/EDIT_MAP';
const EXPORT_MAP_IMAGE = 'map/EXPORT_MAP_IMAGE';
const DISCARD_CHANGES = 'map/DISCARD_CHANGES';
const ADD_MAP = 'map/ADD_MAP';
const CHANGE_FASTIGHETER = 'map/CHANGE_FASTIGHETER';
const SET_MAP_TITLE = 'map/SET_MAP_TITLE';

const FASTIGHETSOMRADEN_LOAD = 'map/FASTIGHETSOMRADEN_LOAD';
const FASTIGHETSOMRADEN_LOAD_SUCCESS = 'map/FASTIGHETSOMRADEN_LOAD_SUCCESS';
const FASTIGHETSOMRADEN_LOAD_FAIL = 'map/FASTIGHETSOMRADEN_LOAD_FAIL';

const initialState = {
  id: null,
  center: {
    x: 609924.45,
    y: 6877630.37
  },
  zoom: 0,
  drawingFeatures: {},
  fastighetFeatures: {},
  fastighetFeaturesRaw: {},
  mapTitle: 'Kartskiss',
  activeTool: interactions.PAN,
  isEditingMap: false,
  hasFeatures: false,
  dataURL: undefined,
  fastigheter: [],
  newMap: true,
  isLoading: true,
  isLoadingFastighetsOmraden: false,
};



export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ANSOKAN_LOAD_SUCCESSFUL:
      return action?.result?.data.map;

    case CHANGE_FASTIGHETER:
      return {
        ...state,
        fastigheter: action.fastigheter,
      };

    case EDIT_MAP:
      const newState = {
        ...state,
        ...action.map,
        isEditingMap: true,
        newMap: false
      };

      return {
        ...newState,
        hasFeatures: (Object.keys(newState.drawingFeatures).length > 0) && (JSON.parse(newState.drawingFeatures).features.length > 0),
      };

    case ADD_MAP:
      return {
        ...initialState,
        id: uuidv4(),
        isEditingMap: true,
      };

    case EXPORT_MAP_IMAGE:
      return {
        ...state,
        dataURL: action.dataURL,
      };

    case DISCARD_CHANGES:
      return {
        ...initialState,
      };

    case CHANGE_CENTER:
      return {
        ...state,
        center: {
          x: action.center.x,
          y: action.center.y
        }
      };

    case ZOOM_TO:
      return {
        ...state,
        zoom: action.zoom
      };

    case SET_MAP_TITLE:
      return {
        ...state,
        mapTitle: action.mapTitle
      };

    case CHANGE_DRAWING_FEATURES:
      return {
        ...state,
        drawingFeatures: action.drawingFeatures,
        hasFeatures: (Object.keys(action.drawingFeatures).length > 0) && (JSON.parse(action.drawingFeatures).features.length > 0),
      };

    case CHANGE_FASTIGHET_FEATURES:
      return {
        ...state,
        fastighetFeatures: action.fastighetFeatures,
      };


    case SELECT_TOOL:
      return {
        ...state,
        activeTool: (state.activeTool === action.tool ? interactions.PAN : action.tool)
      };

    case FASTIGHETSOMRADEN_LOAD_SUCCESS:
      return {
        ...state,
        fastighetFeaturesRaw: action.fastighetFeaturesRaw,
        isLoading: false,
        error: false,
        isLoadingFastighetsOmraden: false,

      };
    case FASTIGHETSOMRADEN_LOAD:
      return {
        ...state,
        isLoading: true,
        error: false,
        isLoadingFastighetsOmraden: true,

      };
    case FASTIGHETSOMRADEN_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
        isLoadingFastighetsOmraden: false,
      };

    case ANSOKAN_FORM_DESTROY:
      return initialState;

    default:
      return state;
  }
}

export function changeDrawingFeatures(drawingFeatures: any) {
  return {
    type: CHANGE_DRAWING_FEATURES,
    drawingFeatures: drawingFeatures
  };
}

export function changeFastighetFeatures(fastighetFeatures: any) {
  return {
    type: CHANGE_FASTIGHET_FEATURES,
    fastighetFeatures: fastighetFeatures
  };
}

export function zoomTo(zoom: number) {
  return {
    type: ZOOM_TO,
    zoom: zoom
  };
}

export function setMapTitle(mapTitle: string) {
  return {
    type: SET_MAP_TITLE,
    mapTitle: mapTitle
  };
}

export function selectTool(tool: string) {
  return {
    type: SELECT_TOOL,
    tool: tool,
  };
}

export function changeCenterXY(x: number, y: number) {
  return {
    type: CHANGE_CENTER,
    center: {
      x: x,
      y: y
    }
  };
}

export function changeCenterNE(northing: number, easting: number) {
  return {
    type: CHANGE_CENTER,
    center: {
      x: easting,
      y: northing
    }
  };
}

export function addMap() {
  return {
    type: ADD_MAP,
  };
}

export function editMap(map: any) {
  return {
    type: EDIT_MAP,
    map: map,
  };
}

export function changeFastigheter(fastigheter: Array<any>) {
  return {
    type: CHANGE_FASTIGHETER,
    fastigheter: fastigheter,
  };
}

export function discardChanges() {
  return {
    type: DISCARD_CHANGES,
  };
}

export function exportMapImage(dataURL: string) {
  return {
    type: EXPORT_MAP_IMAGE,
    dataURL: dataURL,
  }
}

export function loadingFastighetsomraden() {
  return {
    type: FASTIGHETSOMRADEN_LOAD,
  };
}

export function loadFastighetsomradenSuccess(data: RegisterOmradeResponse[]) {
  return {
    type: FASTIGHETSOMRADEN_LOAD_SUCCESS,
    fastighetFeaturesRaw: data,
  };
}

export function loadFastighetsomradenFailed() {
  return {
    type: FASTIGHETSOMRADEN_LOAD_FAIL,
  };
}

export function loadFastighetsomraden(valdaFastigheter: string[] = []) {
  return function action(dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch(loadingFastighetsomraden());
    let valdaFastigheterPromises = valdaFastigheter.map((fastighetObjektidentitet) => getFastighetsOmraden(new Fetcher(dispatch, getState, false), fastighetObjektidentitet))
    return Promise.all(valdaFastigheterPromises)
        .then(result => {
          dispatch(loadFastighetsomradenSuccess(result));
          return result;
        }).catch(() => {
          dispatch(loadFastighetsomradenFailed());
        });
  }
}
