import {CONTACT_TYPE_INDIVIDUAL} from '../redux/modules/contacts'
import {Contact} from "../domain/Contact";

/**
 * Returnerar en lista med namn på personer som företräds av ombudet utifrån ombudets kontaktuppgift
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @param ombudContactId Id på kontaktuppgiften för ombudet
 * @returns {string[]} En lista med namn på de som företräds av ombudet
 */
export const getForetraddaNamnForContactList = (contactList: Contact[], ombudContactId: string): string[] => {
    return getForetraddaForContact(contactList,ombudContactId).map(foretradd => getNamn(foretradd))
}

export const getForetraddaForContact = (contactList: Contact[], ombudContactId: string): Contact[] => {
    const ombudContact = contactList.find(contact => contact.id === ombudContactId)

    const foretradda: Contact[] = []
    ombudContact?.data?.ombudForPersoner?.forEach(foretraddId => {
            const foretradd = contactList.find(contact => contact.id === foretraddId)
            if (foretradd) {
                foretradda.push(foretradd)
            }
        }
    )
    return foretradda
}

/**
 * Hämta en lista med kontaktuppgifter som ska signera en ansökan, dvs de kontaktuppgifter som har
 * rollen sökande men företräds av ett ombud tas inte med i denna lista
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @returns {[]} En filtrerad kontaktlista med endast de kontakter som ska signera ansökan
 */
export const getAllContactsWhoShouldSignAnsokan = (contactList: Contact[]): Contact[] => {
    // Filtrera bort kontaktuppgifter som inte är sökande eller ombud samt de som har ett ombud
    return contactList.filter(contact =>
        (contact.data.rollSokande || contact.data.rollOmbud) &&
        !contactList.some(contactToTest =>
            contactToTest.data.ombudForPersoner?.some(ombudForId => contact.id === ombudForId)
        )
    )
}

/**
 * Hämta en lista med kontaktuppgifter som är sökande men har ett ombud, dvs ej ska signera ansökan
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @returns {[]} En filtrerad kontaktlista med endast de kontakter som är sökande men har ett ombud
 */
export const getAllContactsWithRollSokandeWhoHaveOmbud = (contactList: Contact[]): Contact[] => {
    return getAllContactsWhoHaveOmbudRegardlessRollSokande(contactList).filter((contact: Contact) => contact.data.rollSokande)
}

/**
 * Hämta en lista med kontaktuppgifter som har ett ombud, dvs ej ska signera ansökan
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @returns {[]} En filtrerad kontaktlista med endast de kontakter som har ett ombud
 */
export const getAllContactsWhoHaveOmbudRegardlessRollSokande = (contactList: Contact[]): Contact[] => {
    if (!contactList) {
        return []
    }
    return contactList.filter(contact =>
        contactList.some(contactToTest =>
            contactToTest.data.ombudForPersoner &&
            contactToTest.data.ombudForPersoner.length > 0 &&
            contactToTest.data.ombudForPersoner.some(ombudForId => contact.id === ombudForId)
        )
    )
}

/**
 * Hämta en lista med kontaktuppgifter som inte har rollen sökande men som har ett ombud
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @returns {[]} En filtrerad kontaktlista med endast de kontakter som inte är sökande men som har ett ombud
 */
export const getAllContactsWhoHaveOmbudButIsNotSokande = (contactList: Contact[]): Contact[] => {
    return getAllContactsWhoHaveOmbudRegardlessRollSokande(contactList).filter(contact => !contact.data.rollSokande)
}

/**
 * Tar reda på om en kontaktuppgift har ett ombud
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @param contactId Id på kontaktuppgiften man vill kolla har ett ombud eller ej
 * @returns {boolean} true om kontakten är ombud, annars false
 */
export const harOmbud = (contactList: Contact[], contactId: string): boolean => {
    if (!contactList) {
        return false
    }

    const inputContact = contactList.find(contact => contact.id === contactId)

    if (!inputContact) {
        return false
    }

    return contactList.some(contact =>
        contact.data?.ombudForPersoner?.some(contactIdToTest => contactIdToTest === contactId)
    )
}

/**
 * Hämta sträng av namn på företrädare för en kontaktuppgift
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @param foretraddContactId Id på kontaktuppgiften som företräds av ombud
 * @returns {string} Hittas företrädare returneras namnen på företrädarna, annars tom sträng
 */
export const getFortradareNamnForContact = (contactList: Contact[], foretraddContactId: string): string => {
    const ombudContacts = getForetradareForContact(contactList, foretraddContactId)
    return ombudContacts.length > 0 ? ombudContacts.map((contact: Contact) => getNamn(contact)).join(', ') : ''
}

/**
 * Tar reda på om en kontaktuppgift är ombud
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @param contactId Id på kontaktuppgiften man vill kolla är ombud eller ej
 * @returns {boolean} true om kontakten är ombud, annars false
 */
export const isForetradare = (contactList: Contact[], contactId: string): boolean => {
    const contact = contactList.find(contact => contact.id === contactId)
    if (!contact) {
        return false
    }
    return !!(contact.data.rollOmbud && contact.data.ombudForPersoner && contact.data.ombudForPersoner.length > 0)
}

export const getForetradareForContact = (contactList: Contact[], contactId: string): Contact[] => {
    return contactList.filter(contact =>
        contact.data &&
        contact.data.ombudForPersoner &&
        contact.data.ombudForPersoner.some(ombudForPersonId => ombudForPersonId === contactId))
}

/**
 * Tar reda på om en kontaktuppgift är sökande
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @param contactId Id på kontaktuppgiften man vill kolla är ombud eller ej
 * @returns {boolean} true om kontakten är ombud, annars false
 */
export const isSokande = (contactList: Contact[], contactId: string): boolean => {
    const contact = contactList.find(contact => contact.id === contactId)

    if (!contact) {
        return false
    }

    return !!contact.data.rollSokande
}

/**
 * Hämta namn på kontaktuppgiften
 * @param contact Kontaktuppgiften man vill hämta namn från
 * @returns {string} Om kontakten är en privatperson returneras 'namn', men är det en organisation
 * returneras organisationsnamnet
 */
export const getNamn = (contact: Contact): string => {
    return contact.type === CONTACT_TYPE_INDIVIDUAL ?
        `${contact.data.namn}` : // Privatperson
        contact.data.orgNamn // Organisation
}

/**
 * Hämta epost på kontaktuppgiften
 * @param contact Kontaktuppgiften man vill hämta epost från
 * @returns {string} Epost-adressen för kontaktuppgiften
 */
export const getEpost = (contact: Contact): string => {
    return contact.type === CONTACT_TYPE_INDIVIDUAL ?
        (contact.data.epost || "") : // Privatperson
        (contact.data.orgKontaktpersonEmail || "") // Organisation
}

/**
 * Ta bort en företrädd ur ombudets lista ombudForPersoner
 * @param contactList En kontaktlista med samtliga kontaktuppgifter
 * @param foretraddContactId Id på kontaktuppgiften som ska bort
 * @returns {list} returnerar kontaktlistan, med ombudet uppdaterat
 */
export const removeContactFromForetradare = (contactList: Contact[], foretraddContactId: string): Contact[] => {
    contactList.forEach(contact => clearForetraddForContact(contact, foretraddContactId))
    return contactList
}

const clearForetraddForContact = (contact: Contact, foretraddContactId: string): Contact => {
    if (contact.data?.ombudForPersoner?.some(ombudForPersonId => ombudForPersonId === foretraddContactId)) {
        contact.data.ombudForPersoner = removeIdFromList(contact, foretraddContactId)
    }
    return contact
}

const removeIdFromList = (contact: Contact, foretraddContactId: string): string[] => {
    return contact.data?.ombudForPersoner?.filter(id => id !== foretraddContactId) || []
}