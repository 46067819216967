import { SIGNER_STATUS, SIGNINGINSTANCE_STATUS } from 'domain/signing/SigningStatus'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { fetchSigningStatusKomplettering } from '../../../redux/modules/arendeKomplettering'

const initialState = {
  hasUpprattareApproved: false,
  hasUpprattareDeclined: false,
  hasSigningStarted: false,
  hasSigningDeclined: false,
  hasSigningSucceeded: false,
  hasSigningTimedOut: false,
  signers: [],
  hasSigningError: false,
  errorMsg: undefined
}

/**
 *
 * @param kompletteringId kompletteringens id.
 * @param config object, pollIntervallMs: med vilket intervall signeringsstatusen skall kollas, isPollingActive om denna signeringsstatuspoll är aktiv.
 * @returns {hasUpprattareApproved, hasUpprattareDeclined, hasSigningStarted, hasSigningDeclined, hasSigningSucceeded,
 *       hasSigningTimedOut, signers, hasSigningError, errorMsg, isActive}
 */
export function useKompletteringSigningStatusPoll (kompletteringId, config) {
  const userConfig = { pollIntervallMs: 2000, enabled: true, ...config }
  const [data, setData] = useState(initialState)
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    setIsEnabled(userConfig?.enabled === true && kompletteringId !== undefined)
    if(kompletteringId === undefined && data !== initialState) {
      setData(initialState);
    }
  }, [userConfig?.enabled, kompletteringId])

  useQuery({
    queryKey: [kompletteringId],
    queryFn: fetchingKomplettering,
    onSuccess: onFetchKompletteringSuccess,
    onError: onFetchKompletteringError,
    enabled: isEnabled === true && kompletteringId !== undefined,
    refetchInterval: userConfig.pollIntervallMs
  })

  const dispatch = useDispatch()

  function fetchingKomplettering (query) {
    const _kompletteringId = query.queryKey[0]
    return dispatch(fetchSigningStatusKomplettering(_kompletteringId))
  }

  function onFetchKompletteringSuccess (result) {
    const upprattare = result?.signingStatus?.signerStatus?.find(signer => (signer.status === SIGNER_STATUS.APPROVED || signer.status === SIGNER_STATUS.DECLINED))
    const upprattareStatus = upprattare?.status
    const hasUpprattareApproved = upprattareStatus === SIGNER_STATUS.APPROVED
    const hasUpprattareDeclined = upprattareStatus === SIGNER_STATUS.DECLINED
    const status = result?.signingStatus?.status
    const hasSigningStarted = status !== SIGNINGINSTANCE_STATUS.NOT_STARTED || status === SIGNINGINSTANCE_STATUS.PENDING
    const hasSigningDeclined = status === SIGNINGINSTANCE_STATUS.DECLINED
    const hasSigningSucceeded = status === SIGNINGINSTANCE_STATUS.SUCCEEDED
    const hasSigningTimedOut = status === SIGNINGINSTANCE_STATUS.TIMED_OUT
    const signers = result?.signingStatus?.signerStatus?.map(signer => ({
      email: signer.signerEmail,
      isUpprattare: (upprattare?.signingUrl === signer.signingUrl),
      hasApproved: signer.status === SIGNER_STATUS.APPROVED,
      hasDeclined: signer.status === SIGNER_STATUS.DECLINED
    }))
    if (hasSigningDeclined || hasSigningSucceeded || hasSigningTimedOut) {
      setIsEnabled(false)
    }
    setData({
      hasUpprattareApproved, hasUpprattareDeclined, hasSigningStarted, hasSigningDeclined, hasSigningSucceeded,
      hasSigningTimedOut, signers, hasSigningError: false, errorMsg: undefined
    })
  }

  function onFetchKompletteringError (err) {
    setData(prevState => ({ ...prevState, hasSigningError: true, errorMsg: err }))
  }

  return { ...data, isActive: isEnabled === true }
}