import {Navigation} from 'components'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {visaMoment} from 'redux/modules/help'
import {ActionBar} from 'views'
// @ts-ignore
import {Helmet} from 'react-helmet'
import {ArendeHjalp} from '../components/arendeRelated/hjalp/ArendeHjalp'
import {JumpLinkToMain} from '../components/General/JumpLinkToMain'
import {IE11modalDialogWarning} from './Ansokan'
import ArendeView from "../components/arendeRelated/new-plf-layout/ArendeView";
import {useAppDispatch} from "../hooks";
import {ArendeViewProvider} from "../components/arendeRelated/new-plf-layout/ArendeViewContext";

interface Props {
    asHandlaggare: boolean
}

function Arende({asHandlaggare}: Props) {

    const params = useParams()
    const arendenummer = params?.id
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(visaMoment('help-status', 'Förrättningsärende', false))
    }, [dispatch]);

    return (
        <>
            <JumpLinkToMain/>
            <Helmet>
                <title>Pågående ärende</title>
            </Helmet>
            <IE11modalDialogWarning/>
            <header>
                <Navigation/>
            </header>
            <main className="container" id="arendeMainContentId">
                {arendenummer &&
                    <ArendeViewProvider>
                        <ArendeView arendenummer={arendenummer} asHandlaggare={asHandlaggare}/>
                    </ArendeViewProvider>
                }
                {!asHandlaggare &&
                    <ActionBar saveAnsokanOnClose={false}/>
                }
            </main>
            <ArendeHjalp/>
        </>
    )
}

export default Arende