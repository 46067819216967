import {getLocalizedDateString} from '../../helpers/dates';
import React, {useEffect, useState} from 'react';
import {DocumentDetails} from '../../domain/Forratningsarende/DocumentDetails';
import {
    ListGroup,
    // @ts-ignore
    ListItem, VarningsRuta, IconLinkWithPopover, ExternalLink
} from 'redet-react-components';
import {ARENDE_LIST_PAGE_SIZE} from "../../helpers/KompletteringHelper";

interface Props {
    title?: string
    arendenummer: string,
    handlingar: DocumentDetails[],
    asHandlaggare: boolean
}

export function ArendeHandlingarList({title, arendenummer, handlingar, asHandlaggare, ...rest}: Props) {

    const [initList, setInitList] = useState<DocumentDetails[]>([])
    const [handlingarList, setHandlingarList] = useState<DocumentDetails[]>([])

    const listGroupOptions = {
        list: initList,
        listSetter: setHandlingarList,
        sortConfig: initList.length > 1 ? {
            text: 'Sortering',
            options: [
                {id: 'publishedDate', key: 'publishedDate', text: 'Datum', initial: true, desc: true},
                {id: 'documentName', key: 'documentName', text: 'Namn', thenBy: ['documentPart']},
                {id: 'akt', key: 'akt', text: 'Aktbilaga', thenBy: ['documentPart']}
            ]
        } : undefined,
        paginationConfig: {
            initPage: 0,
            maxNoPageButtons: 5,
            pageSize: ARENDE_LIST_PAGE_SIZE,
            onPageChangeScrollToTopOfList: true
        }
    }

    useEffect(() => {
        setInitList(handlingar.slice())
    }, [handlingar])

    const sanitizeDocumentNameFromDots = (documentName: string): string => {
        // Multiple dots, regex:
        if (!documentName) {
            return 'dokument';
        }
        return documentName.replace(/\./g, '');
    }

    const getDownloadButton = (handling: DocumentDetails, handlingIndex: number) => {
        return <IconLinkWithPopover
            icon={'file_download'}
            popoverText={'Ladda ner den här handlingen'}
            ariaLabel={'Ladda ner handling'}
            link={`/api/forrattningsinfo/forattningsarende/${arendenummer}/dokument/${handling.documentNumber}${asHandlaggare ? '?asHandlaggare=true' : ''}`}
            target={'_blank'}
            download={sanitizeDocumentNameFromDots(handling.documentName)}
            name={`downloadHandling-${handlingIndex}`}
            key={`downloadHandling-${handlingIndex}_key`}
            id={`downloadHandlingButton-${handling.documentNumber}`}
        />
    }

    return (<>
        <ListGroup id={'section-arende-handlingar'}
                   data-selenium-id={'arende-handlingar-list'}
                   title={title ?? ''}
                   description={<p>
                       Här visas de handlingar som handläggare har valt att publicera i det här ärendet.
                   </p>}
                   listOptions={listGroupOptions}
                   {...rest}>

            {handlingarList.map((handling, handlingIndex) =>
                <ListItem key={`${handling.documentNumber}_${handling.publishedDate}`}
                          id={`${handling.documentNumber}_${handlingIndex}_id`}
                          actionButtons={[getDownloadButton(handling, handlingIndex)]}
                          header={<ExternalLink text={handling.documentName ? handling.documentName : 'dokument'}
                                                url={'/api/forrattningsinfo/forattningsarende/' + arendenummer + '/dokument/' + handling.documentNumber + (asHandlaggare ? '?asHandlaggare=true' : '')}
                                                download={sanitizeDocumentNameFromDots(handling.documentName)}
                          />}
                          description={
                              <>Publicerad {getLocalizedDateString(handling.publishedDate, 'd MMMM y')}
                                  <span className='description'>
                                    {handling.akt &&
                                        <span>Aktbilaga <span className='item'>{handling.akt}</span></span>
                                    }
                                      {handling.documentPart &&
                                          <span className='item'>Del {handling.documentPart}</span>
                                      }
                                </span>
                              </>
                          }>
                </ListItem>
            )}

            {initList.length === 0 &&
                <section className='content'>
                    <p>
                        <span className='additional_information'>Inga handlingar har publicerats för det här ärendet.</span>
                    </p>
                </section>
            }
        </ListGroup>
        {initList.length > 0 && <>
            <br/>
            <VarningsRuta ariaTitle={'Meddelande'}
                          warningMessages={['Vid utskrift kan skalriktigheten på kartor misstämma.']}>
                <p className='optional' key={"Nagraavlantmaterietssystemskaparhandlingarsomiblandinte"}>
                    Några av lantmäteriets system skapar handlingar som ibland inte uppfyller alla tillgänglighetskrav.
                    <ExternalLink url={'https://www.lantmateriet.se/tillganglighet'}
                                  openInNewTab={true}
                                  text={'Läs mer om hur lantmäteriet löpande arbetar med tillgänglighet'}
                    />
                </p>
            </VarningsRuta>
        </>}
    </>)
}

export default ArendeHandlingarList