import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Arende} from 'domain/Arende'
import {ArendeMedSvarslistor} from 'domain/ArendeMedSvarslistor'
import {ArendeFilterType} from 'pages/welcome/components/ArendeList/ArendeListFilter'
import {Dispatch} from 'redux'
import {loadList} from 'repository/arendeRepository'
import {fakeLoadList} from 'repository/testdata/fakeArendeRepository'
import {RootState} from 'store'
import {Fetcher} from '../../repository/fetcher'
import {fetchSvarslistor} from '../../repository/svarslistaRepository'
import {combineArendenWithSvarslistor} from './arendenAndSvarslistJoiner'

const initialState: ArendeListState = {
    list: [],
    isLoading: false
}

export type ArendeListState = {
    list: ArendeMedSvarslistor[],
    isLoading: boolean
}

type LoadArendeListSuccessPayload = {
    arenden: Arende[],
}

const arendeListSlice = createSlice({
    name: 'arendeList',
    initialState: initialState,
    reducers: {
        loadArendeListAction(state, action: PayloadAction) {
            state.isLoading = true
        },
        loadArendeListSuccessAction(state, action: PayloadAction<LoadArendeListSuccessPayload>) {
            state.isLoading = false
            state.list = action.payload.arenden
        },
        loadArendeListFailedAction(state, action: PayloadAction) {
            state.isLoading = false
            state.list = []

        }
    }
})

export const loadArendeList = (filter: ArendeFilterType) => {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {

        dispatch(loadArendeListAction())

        const fetcherArende = new Fetcher(dispatch, getState, false)
        const fetcherSvarslistor = new Fetcher(dispatch, getState, false)
        const arendeLista = (getState().featureToggle.FRISSE_SERVICE_MOCK_ENABLED ? fakeLoadList() : loadList(fetcherArende, filter))

        // This happens if user is organization i.e only arendelista is fetched
        if (getState().auth.user?.isOrganization) {
            return arendeLista.then((arendeLista: any) => {
                const combinedArenden = combineArendenWithSvarslistor(arendeLista.arenden, [])
                dispatch(loadArendeListSuccessAction({arenden: combinedArenden}))
            }).catch((error: any) => {
                dispatch(loadArendeListFailedAction())
                throw error
            })
        }

        // This happens if user is not organization i.e fetch arendelist and svarslista
        return Promise.all([
            arendeLista,
            fetchSvarslistor(fetcherSvarslistor).catch((error: any) => []) // Ignorera fel från svarslistor.
        ]).then(arendenAndSvarslistor => {
            const combinedArenden = combineArendenWithSvarslistor(arendenAndSvarslistor[0].arenden, arendenAndSvarslistor[1])
            dispatch(loadArendeListSuccessAction({arenden: combinedArenden}))
        }).catch(error => {
            dispatch(loadArendeListFailedAction())
            throw error
        })
    }
}

export const {loadArendeListAction, loadArendeListFailedAction, loadArendeListSuccessAction} = arendeListSlice.actions
export default arendeListSlice.reducer

