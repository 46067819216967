import Log from 'utils/log'
import {logError} from 'utils/errorHandler'
import type {Fetcher} from "./fetcher";
import {
    FaultyPDFFileError,
    ForbiddenBifogadHandlingFiletypeException,
    InvalidEmailException,
    TooManyFilesInBifogadHandlingException,
    UserAbortException
} from "./BifogadHandlingException";
import axios from "axios";
import {ListedBifogadHandling} from "../domain/ListedBifogadHandling";

const repositoryUrl = '/api/ansokan-service/ansokan/'
const log = new Log('bifogadHandlingRepository')

export type HandlingType = {
    type: string,
    description: string,
    files: Array<any>,
    bestyrkare: string
}

export function loadList(fetcher: Fetcher, ansokanId: string) {
    return fetcher.fetching(repositoryUrl + ansokanId + '/bifogadHandling', {cache: 'no-store'})
        .then(function (response) {
            return response.json()
        })
        .then((storedRecords:ListedBifogadHandling[]) => {
            log.debug('Bifogade handlingar successfully loaded from server')
            return storedRecords
        }).catch(err => {
            log.error('An error has occurred while loading bifogad handling list from server with error code : ', err.status)
            throw err
        })
}

export function updateBestyrkare(fetcher: Fetcher, id: string, bestyrkare: string) {
    if (!id) {
        throw new Error('The id argument is missing')
    }
    if (!bestyrkare) {
        throw new Error('The bestyrkare argument is missing')
    }
    const data = new FormData()
    data.append('id', id)
    data.append('bestyrkare', bestyrkare)
    return fetcher.fetching(repositoryUrl + id, {body: data, cache: 'no-store', method: 'PUT'})
        .then(response => {
            return response.text()
        })
        .then(() => {
            log.debug('Successfully updated bifogad handling bestyrkare')
            return true
        }).catch(err => {
            log.error('An error has occurred while updating bifogad handling with error code: ', err.status)
            throw err
        })
}

export function remove(fetcher: Fetcher, ansokanId: string, id: string) {
    if (!id) {
        throw new Error('The id argument is missing')
    }
    return fetcher.fetching(repositoryUrl + ansokanId + '/bifogadHandling/' + id, {cache: 'no-store', method: 'DELETE'})
        .then(response => {
            return response.text()
        })
        .then(() => {
            log.debug('Successfully deleted bifogad handling from server')
            return true
        }).catch(err => {
            log.error('An error has occurred while deleting bifogad handling with error code: ', err.status)
            throw err
        })
}

export function getQuota(fetcher: Fetcher, id: string) {
    if (!id) {
        throw new Error('The id argument is missing')
    }

    return fetcher.fetching(`/api/ansokan-service/ansokan/${id}/bifogadHandling/quota`, {cache: 'no-store', method: 'GET'})
        .then(function (response) {
            return response.json()
        })
        .then(quota => {
            log.debug('Successfully fetched bifogadHandling quota from server')
            return quota
        }).catch(err => {
            log.error('An error has occurred while loading bifogad handling quota from server with error code: ', err.status)
            throw err
        })
}

export function upload(ansokanId: string, bifogadHandling: HandlingType, onProgressCallback: (percent?: number) => {}, abortController: AbortController) {
    //create form data
    const formData: FormData = new FormData()
    formData.append('type', bifogadHandling.type)
    formData.append('description', bifogadHandling.description)
    formData.append('bestyrkare', bifogadHandling.bestyrkare)
    for (const file of bifogadHandling.files) {
        formData.append('files', file)
    }
    const {signal} = abortController;
    return axios.post(
        `${repositoryUrl}${ansokanId}/bifogadHandling`,
        formData,
        {
            onUploadProgress: ({progress = 1}) => onProgressCallback(Math.ceil(progress * 100)),
            signal
        }
    ).then(response => {
        if (response.status === 201) {
            return response.status
        } else {
            throw new Error(`Misslyckades med att ladda upp bifogad handling, status ${response.status}`)
        }
    }).catch(error => {
        if (signal.aborted) {
            throw new UserAbortException('User has aborted the upload!')
        }
        logError(error, 'Error encountered while uploading bifogad handling')
        const errorCode = error?.response?.status
        const errorData = error?.response?.data
        if (errorCode === 403 && errorData?.exception?.includes('TooManyFilesInBifogadHandlingException')) {
            throw new TooManyFilesInBifogadHandlingException('Exceeded limit for number of files/pages in PDF for a bifogad handling')
        } else if (errorCode === 403 && errorData?.exception?.includes('ForbiddenBifogadHandlingException')) {
            throw new ForbiddenBifogadHandlingFiletypeException('Uploaded file was rejected, one cause for this is that the file contained a MIME-type which is not allowed')
        } else if (errorCode === 403 && errorData?.exception?.includes('InvalidFileTypeForBifogadHandlingException')) {
            throw new ForbiddenBifogadHandlingFiletypeException('Uploaded file was rejected, one cause for this is that the file contained a MIME-type which is not allowed')
        } else if (errorCode === 400 && errorData?.exception?.includes('InvalidEmailException')) {
            throw new InvalidEmailException('Invalid email submitted for bestyrkan')
        } else if (errorCode === 500 && errorData?.exception?.includes('FaultyPDFFileError')) {
            throw new FaultyPDFFileError('Faulty PDF-file uploaded')
        } else {
            throw new Error(`Misslyckades med att ladda upp bifogad handling, status: ${errorCode}`)
        }
    })
}