import { Dispatch, useEffect, useState } from 'react'
import { Term } from 'views'
import { Form } from 'react-final-form'
import FormStateToRedux from 'redux/FormStateToRedux'
import { formProperties } from 'redux/formProperties'
import { createValidator, required } from 'utils/validation'
import { connect } from 'react-redux'
import { initFormData } from 'redux/finalForm'
import { CheckboxFinalForm, Condition, TextAreaFinalForm, WhenFieldChanges } from '../../General/forms/finalForm'
//@ts-ignore
import { Column, FormFieldset, Row, PageSubContent} from 'redet-react-components'


type KlyvningProps = {
  initFormData: (atgard: string) => {}
}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: any) => {
  return { initFormData: (formField: string) => ownProps.initFormData || dispatch(initFormData(formField)) }
}

export let validateKlyvningForm = function (values: any) {
  return createValidator(values,
    {
      fordelaSFochGABeskrivning: values?.fordelaSFochGA ? [required] : [],
      bildaServitutBeskrivning: values?.bildaServitut ? [required] : []
    })
}

function KlyvningForm(props: KlyvningProps) {

  const [_formDataLoaded, setFormDataLoaded] = useState(false);
  const [_initData, setInitData] = useState({});

  useEffect(() => {
    const res = props.initFormData(formProperties.klyvning.name)
    setFormDataLoaded(true);
    setInitData(res)
  },[])

    return (
      <PageSubContent title={"Klyvning"} headerLevel={4} id="fastighetsforandring-klyvning" headerId="fastighetsforandring-klyvningHeader">
        <br/>
        <Form
          onSubmit={() => {}}
          initialValues={_initData}
          subscription={{ submitting: true, pristine: true }}
          validate={values => validateKlyvningForm(values)}>
          {() => {
            return (
              <form onSubmit={() => {}}>

                {_formDataLoaded && <FormStateToRedux form={formProperties.klyvning.name}/>}

                <Row>
                  <Column columns={'col-xs-12 col-md-6'}>
                    <FormFieldset title={'Beskriv delningen'}
                                  description={<> Beskriv hur du önskar att delningen ska se ut mellan delägarna.
                                      Referera gärna till kartskissen.</>}>

                      <TextAreaFinalForm id={'beskrivningId'} field={'beskrivning'} label={'Beskrivning'}
                                         mandatory={false} characterLimit={4000}/>
                    </FormFieldset>
                  </Column>
                  <Column columns={'col-xs-12 col-md-6'}>
                    <FormFieldset title={'Vad ska de nya fastigheterna användas till?'}
                                  description={<>Beskriv hur de nya fastighetena skall <Term label=" användas till"
                                                                                             moment="help-description"
                                                                                             term="term-andamal"/>
                                    , t.ex. permanentboende eller jord- och skogsbruk</>}>
                      <TextAreaFinalForm id={'andamalId'} field={'andamal'} label={'Beskrivning'} mandatory={false} characterLimit={4000}/>
                    </FormFieldset>
                  </Column>
                  <Column columns={'col-xs-12 col-md-6'}>
                    <FormFieldset title={'Ska ny rättighet bildas?'}>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field={'bildaServitut'} id={'klyvningBildaServitutId'}
                                         label={'Ja, ny rättighet ska bildas'}
                                         term={<Term label=" servitut" moment="help-description"
                                                     term="term-servitut"/>}/>
                      <WhenFieldChanges field={'bildaServitut'} becomes={false} set={'bildaServitutBeskrivning'}
                                        to={undefined}/>
                      {/* @ts-ignore */}
                      <Condition when="bildaServitut" is={true}>
                        <TextAreaFinalForm
                          id={'bildaServitutBeskrivningId'}
                          field={'bildaServitutBeskrivning'}
                          label={'Beskriv syftet med servitutet och vilken fastighetsdel ' +
                            'som har servitutet till förmån respektive vilken fastighet som är belastad'}
                          mandatory={true}
                          characterLimit={4000}
                        />
                      </Condition>
                    </FormFieldset></Column>
                  <Column columns={'col-xs-12 col-md-6'}>
                    <FormFieldset title={'Hur ska andelar i samfälligheter fördelas?'}>
                      {/* @ts-ignore */}
                      <CheckboxFinalForm field={'fordelaSFochGA'} id={'klyvningFordelaSFochGAId'}
                                         label={'Beskriv hur andelar i samfälligheter ska fördelas'}/>
                      <WhenFieldChanges field={'fordelaSFochGA'} becomes={false} set={'fordelaSFochGABeskrivning'}
                                        to={undefined}/>
                      {/* @ts-ignore */}
                      <Condition when="fordelaSFochGA" is={true}>
                        <TextAreaFinalForm
                          id={'fordelaSFochGABeskrivningId'}
                          field={'fordelaSFochGABeskrivning'}
                          characterLimit={4000}
                          label={<>Beskriv hur&nbsp;
                            <Term
                              label="klyvningsfastighetens"
                              moment="help-description"
                              term="term-klyvningsfastighet"/> andelar i&nbsp;
                            <Term label="samfälligheter" moment="help-description"
                                  term="term-samfallighet"/> eller&nbsp;
                            <Term label="gemensamhetsanläggningar" moment="help-description"
                                  term="term-gemensamhetsanlaggning"/> ska fördelas
                          </>}
                          mandatory={true}
                        />
                      </Condition>
                    </FormFieldset>
                  </Column>
                </Row>
                <Row>
                  <Column columns={'col-xs-12 col-md-6'}>
                    <FormFieldset title={'Finns det byggnader som hör till fastigheten?'}
                                  description={'Beskriv på vilken del av fastigheten bostadshus eller andra' +
                                    ' byggnader kommer att vara placerade efter förrättningen.'}>
                      <TextAreaFinalForm
                        id={'tillhorandeByggnaderId'}
                        field={'tillhorandeByggnader'}
                        label={'Beskrivning'}
                        mandatory={false}
                        characterLimit={4000}
                      />
                    </FormFieldset>
                  </Column>
                </Row>
              </form>
            )
          }}
        </Form>
      </PageSubContent>
    )
}


export default connect(undefined, mapDispatchToProps)(KlyvningForm)
