// noinspection XmlDeprecatedElement

import React from 'react'
import {PDFLinkButton} from '../index'
import {FormSubmit} from '../../views'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-deprecated
import {LinkButtonRouter} from './forms/routerLinks'

export function PageWizardNavigationToolbar (props) {
  const { buttonRightText, buttonRightLink, buttonLeftText, buttonLeftLink, children } = props

  return <>
    <div className="toolbar mobile-toolbar">
      <div className="button-group">
        {buttonLeftText && <LinkButtonRouter text={buttonLeftText}
                                             link={buttonLeftLink}
                                             class={'button-top wizard-step'}
                                             icon={'arrow_back'}/>}
      </div>
      <div className="button-group">
        {children}
        <PDFLinkButton/>

        {/* Visa endast "Skriv under"-knappen på sista steget */}
        {!buttonRightLink && !buttonRightText &&
            <FormSubmit/>
        }

        {buttonRightText && <LinkButtonRouter text={buttonRightText}
                                              link={buttonRightLink}
                                              class={'button-top wizard-step'}
                                              icon={'arrow_forward'}/>}
      </div>

    </div>
  </>
}

PageWizardNavigationToolbar.propTypes = {
  buttonRightText: PropTypes.string,
  buttonRightLink: PropTypes.string,
  buttonLeftText: PropTypes.string,
  buttonLeftLink: PropTypes.string
}
