import {KOMPLETTERINGSTATUS, KOMPLETTERINGTYP} from "../../../domain/Komplettering";
import {formatToDateAndTime} from "../../../helpers/dates";
import {ReactNode} from "react";

export type ConfirmDialogData = {
    heading: string
    introduction: string|ReactNode
    description: string|ReactNode
    acceptBtnTitle: string
    declineBtnTitle: string
}

export type PreviewData = {
    mainHeading: string
    secondHeading: string
    introduction: string|ReactNode
    description: string|ReactNode
    attentionbox: {
        heading?: string
        description: string|ReactNode
        checklist: string[]
    }
    approvalButtonTitle: string
    confirmApprovalDialog: ConfirmDialogData
    confirmDeclineDialog: ConfirmDialogData
}

export type SuccessData = {
    heading: string
    introduction: string|ReactNode
    description?: string|ReactNode
    confirmButtonText: string
}

export type ErrorData = {
    heading: string
    description: string|ReactNode
}

export default class SignDocumentTextHelper {
    static getDocumentPreviewViewModel = (typ: KOMPLETTERINGTYP): PreviewData | undefined => {
        switch (typ) {
            case KOMPLETTERINGTYP.YTTERLIGARE_HANDLING: {
                return {
                    mainHeading: 'Bestyrk bifogad handling',
                    secondHeading: 'Bestyrk kopia',
                    introduction: 'Här kan du bestyrka att den här kopian överensstämmer med originalet',
                    description: `Du kan välja att bestyrka kopian. Kom ihåg att granska de bifogade handlingarna. 
                               När du väljer att bestyrka kopian gör du det genom ytterligare en legitimering 
                               med hjälp av din e-legitimation.`,
                    attentionbox: {
                        description: 'Några exempel på när du bör avböja/neka att bestyrka är:',
                        checklist: [
                            'När ett dokument är av dålig kvalitet och därmed är svårt att läsa',
                            'När ett dokument inte ser ut som förväntat, t ex saknar en sida',
                            'När delar av originalet inte finns med, t ex har beskurits bort'
                        ]
                    },
                    approvalButtonTitle: 'Bestyrk kopia',
                    confirmApprovalDialog: {
                        heading: 'Bestyrk kopia',
                        introduction: 'Tänk på att du inte kan ångra att bestyrka kopian. I nästa steg kommer du att bestyrka kopian genom att legitimera dig med hjälp av din e-legitimation.',
                        description: 'Vill du gå vidare till E-legitimering och bestyrka kopian?',
                        acceptBtnTitle: 'Gå vidare till E-legitimering',
                        declineBtnTitle: 'Avbryt'
                    },
                    confirmDeclineDialog: {
                        heading: 'Avböj/neka bestyrkan',
                        introduction: 'Tänk på att du inte kan ångra att avböja/neka att bestyrka kopian. Om du gör det måste du börja om med din komplettering.',
                        description: 'Vill du avböja/neka?',
                        acceptBtnTitle: 'Avböj/neka',
                        declineBtnTitle: 'Avbryt'
                    }
                }
            }
            default:
                return undefined
        }
    }

    static getSignedAndSubmittedSuccessViewModel = (typ: KOMPLETTERINGTYP, submittedDate?: string): SuccessData | undefined => {
        const submittedDateText = submittedDate ? ` och har fått datum ${formatToDateAndTime(submittedDate)}` : ''
        switch (typ) {
            case KOMPLETTERINGTYP.YTTERLIGARE_HANDLING: {
                return {
                    heading: 'Kopian är nu bestyrkt och handlingen är inskickad till Lantmäteriet',
                    introduction: (<>
                        Handlingen har nu skickats in till Lantmäteriet{submittedDateText}.
                    </>),
                    description: submittedDateText ? (<>Notera ditt inskickningsdatum, det kan behövas vid kontakt med myndigheten gällande din kompletterande handling.
                        Din komplettering kommer att diarieföras och läggas till i ärendet som underlag för handläggning.</>) : undefined,
                    confirmButtonText: 'Ok, tillbaka till ärendet'
                }
            }
            default:
                return undefined
        }
    }

    static getSignedNotSubmittedSuccessViewModel = (typ: KOMPLETTERINGTYP): SuccessData | undefined => {
        switch (typ) {
            case KOMPLETTERINGTYP.YTTERLIGARE_HANDLING: {
                return {
                    heading: 'Kopian är nu bestyrkt',
                    introduction: (<>
                        Det gick inte att skicka in just nu men din handling kommer att skickas in automatiskt inom kort.
                    </>),
                    confirmButtonText: 'Ok, tillbaka till ärendet'
                }
            }
            default:
                return undefined
        }
    }

    static getDeclineViewModel = (typ: KOMPLETTERINGTYP): SuccessData | undefined => {
        switch (typ) {
            case KOMPLETTERINGTYP.YTTERLIGARE_HANDLING: {
                return {
                    heading: 'Kopian är ej bestyrkt',
                    introduction: 'Du har valt att inte bestyrka att den här kopian överensstämmer med originalet.',
                    confirmButtonText: 'Ok, tillbaka till ärendet'
                }
            }
            default:
                return undefined
        }
    }

    static getFaultyStatusError = (status: KOMPLETTERINGSTATUS): ErrorData | undefined => {
        switch (status) {
            case KOMPLETTERINGSTATUS.INSKICKAD:
                // COMPLETED (prosale)
                return {
                    heading: 'Underskrift utförd',
                    description: 'Den här förfrågan om underskrift är besvarad och det är inte möjligt att skriva under igen.'
                }
            case KOMPLETTERINGSTATUS.UTGATT:
                // EXPIRED (prosale)
                return {
                    heading: 'Avbruten underskrift',
                    description: 'Underskrift av detta dokument går ej längre att utföra eftersom tidsgränsen för ärendet har passerats.'
                }
            case KOMPLETTERINGSTATUS.AVBOJD:
                // DECLINED (prosale)
                return {
                    heading: 'Avbruten underskrift',
                    description: 'Underskrift har nekats av en eller flera mottagare, vilket gör att detta ärende har avbrutits.'
                }
            case KOMPLETTERINGSTATUS.UTKAST:
            case KOMPLETTERINGSTATUS.UNKNOWN:
            default:
                return {
                    heading: 'Tekniskt fel',
                    description: `Prova att ladda om sidan eller klicka på knappen nedan för att gå tillbaka och starta om signeringen`
                }
        }
    }

    static getSigningResponseError = (): ErrorData => {
        // Todo vill vi villkora på status? signeringsstaus?
        return {
            heading: 'Tekniskt fel',
            description: `Klicka på knappen nedan för att ta dig tillbaka till ärenden och starta om signeringen`
        }
    }

}