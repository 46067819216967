import jquery from 'jquery'

export function scrollToElement (selector, callback) {
  jquery(document).ready(() => {
    const target = jquery(selector)
    if (target.length > 0) {
      jquery('html, body').animate(
        { scrollTop: target.offset().top - jquery('.navbar-2_0').height() - 25 }, 500
      ).promise().then(() => {
        if (callback) {
          callback()
        }
      })
    }
  })
}

export function isScrolledIntoView (selector) {
  const elem = jquery(selector)[0]
  if (!elem) {
    return false
  }
  const rect = elem.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function scrollToMainTag (delayBeforeScrollingMillis = 300) {
  // Experimental sedan cirkus 5 oktober 2015 (scrollIntoView)
  try {
    if (window.innerWidth > 960 && window.innerHeight > 800) {
      // Om vi är på en skärm med stor bredd och höjd (webbläsare på laptop med bra upplösning t ex) scrollar vi till rubriken
      setTimeout(() => window.document.getElementById('ansokanFormSectionId')?.scrollIntoView({ behavior: 'smooth' }), delayBeforeScrollingMillis)
    } else {
      // Om vi är på en skärm med liten bredd eller höjd (webbläsare på mobil i stående eller liggnade läge eller på laptop med låg upplösning t ex) scrollar vi till fiskarna i wizarden
      setTimeout(() => window.document.getElementById('ansokanFormWizardId')?.scrollIntoView({ behavior: 'smooth' }), delayBeforeScrollingMillis)
    }
  } catch (error) {
    // Do nothing eg. no scrolling if element does not exist
  }
}

export function scrollToElementNative (elementId) {
  // Experimental sedan cirkus 5 oktober 2015 (scrollIntoView)
  try {
    document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    // Do nothing eg. no scrolling if element does not exist
  }
}