import PropTypes from 'prop-types'
//@ts-ignore
import { ButtonSub, ButtonToolbar, Column } from 'redet-react-components'
import {useId} from "react";

export default function KartskissThumbnail (props: KartskissThumbnailProps) {
  const headerId = useId()
  return (
    <Column columns="col-12 col-sm-6 col-xl-4">
      <div className="image-panel" role="listitem" aria-labelledby={headerId}>
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
        <header className={'map-heading'} data-selenium-id="mapName" id={headerId}>{props.map.mapTitle}</header>
        <figure style={{ height: '270px' }} aria-labelledby={headerId}><img src={props.map.dataURL} alt={`Bild av ${props.map.mapTitle}`}/></figure>
        <ButtonToolbar>
          <ButtonSub text={'Ändra'}
                     icon={'edit'}
                     onClick={props.onEditMapClick}
                     id="openMapButton"
                     aria-labelledby={`openMapButton ${headerId}`}
                     data-selenium-id="openMap"/>
          <ButtonSub text={'Duplicera'}
                     icon={'file_copy'}
                     onClick={props.onCopyMapClick}
                     id="duplicateMapButton"
                     aria-labelledby={`duplicateMapButton ${headerId}`}
                     data-selenium-id="duplicateMapButton"/>
          <ButtonSub text={'Ta bort'}
                     icon={'delete'}
                     onClick={props.onDeleteMapClick}
                     id="deleteMapButton"
                     aria-labelledby={`deleteMapButton ${headerId}`}
                     data-selenium-id="deleteMapButton"/>
        </ButtonToolbar>
      </div>
    </Column>
  )
}

type KartskissThumbnailProps = {
  onEditMapClick: Function,
  onDeleteMapClick: Function,
  onCopyMapClick: Function,
  map: {
    mapTitle: string,
    dataURL: string
  }
}
