import Log from "utils/log"
import { Fetcher } from "./fetcher"
const APPLICATION_JSON = 'application/json'
const repositoryUrl = '/survey'
const log = new Log('betygRepository')


export function skickaBetyg(fetcher: Fetcher, grade: number, feedbackText?: string): any {

        return fetcher.fetching(repositoryUrl,
            {
                cache: 'no-store',
                method: 'PUT',
                body: JSON.stringify({grade, feedbackText}),
            },
            {'Content-Type': APPLICATION_JSON})
            .then((response: Response) => {
                return response
            })
            .catch((error: Error) => {
                log.error("Betyg kom inte in som förväntat")
                throw error
            })
}