import Navigation from '../components/Navigation/Navigation'
//@ts-ignore
import { ButtonGroup, ButtonToolbarV2, LinkButtonSub, ModalDialogSub, PageHeader } from 'redet-react-components'

export default function SessionTimeout() {
    return (
      <>
        <header>
          <Navigation/>
          <PageHeader/>
        </header>
        <main>
          <ModalDialogSub modalTitle={'Du måste logga in på nytt'}
                          useDefaultToolbar={false}
                          description={'Du har blivit utloggad och behöver logga in på nytt.\n' +
                            '                            Det kan bero på att du har varit inaktiv för länge eller att du behöver kontrollera inställningarna\n' +
                            '                            för ditt nätverk eller din brandvägg.\n' +
                            '                        '}>
            <ButtonToolbarV2>
              <ButtonGroup>
                <LinkButtonSub text={'Logga in'} link={window.location.origin} icon={'arrow_forward'}/>
                <LinkButtonSub text={'Lantmäteriets hemsida'} link={'http://www.lantmateriet.se'}
                               icon={'arrow_forward'}/>
              </ButtonGroup>
            </ButtonToolbarV2>
          </ModalDialogSub>
        </main>
      </>
    )
}

