// @ts-ignore
import {ButtonToolbarV2, ButtonTop, ButtonGroup, Column, FormFieldset, ModalDialogSub, PageTopContent, Row} from "redet-react-components";
import React, {useEffect, useState} from "react";
import {Fastighetsinnehav, FastighetsvalSearchBox, Term, ValidationError} from "../index";
import {formProperties} from "../../redux/formProperties";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {updateFieldState} from "../../redux/finalForm";
import {
    failLoadFastighetsinnehav,
    loadRegisterbeteckningFunc,
    removeRegisterenhet,
    setFirstStepFinished,
    successLoadFastighetsinnehav
} from "../../redux/modules/fastigheter";
import {LoadFastighetException} from "../../repository/fastighetRepository";
import {logError} from "../../utils/errorHandler";
import {ValidationTypes} from "../validation/ValidationError";
import { Fastighet } from "domain/Ansokan";

type Props = {
    ansokanId: string,
}

type FormType = {
    name: string,
    displayName: string,
    save: boolean,
}

export default function FastighetAndAtgardVal(props: Props) {
    const dispatch = useAppDispatch();
    const [fastighetCouldNotBeLoadedModal, setFastighetCouldNotBeLoadedModal] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const egnaFastigheter = useSelector((store: RootState) => store.fastighetsinnehav.list);
    const ickeAgdaFastigheter = useSelector((store: RootState) => store.fastigheter.ickeAgdaFastigheter);
    const firstStepFinished = useAppSelector((store: RootState) => store.fastigheter?.firstStepFinished);
    const valdaFastigheter: Fastighet[] = useAppSelector((store: RootState) => store.fastigheter?.fastigheterList);

    const [error, setError] = useState(false)
    // @ts-ignore
    const klyvningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.klyvning)
    // @ts-ignore
    const avstyckningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.avstyckning)
    // @ts-ignore
    const fastighetsregleringIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.fastighetsreglering)
    // @ts-ignore
    const sammanlaggningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.sammanlaggning)
    // @ts-ignore
    const fastighetsbestamningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.fastighetsbestamning)
    // @ts-ignore
    const gransutmarkningIsSelected = useSelector((store: RootState) => store?.form?.fastighetsforandring.gransutmarkning)
    // @ts-ignore
    const atgarder = useSelector((store: RootState) => store?.form?.fastighetsforandring)

    const validationErrors = useSelector((store: RootState) => store.validation.errors)

    const navigate = useNavigate();

    const hasValidationErrors = buttonDisabled()


    useEffect(() => {
        if(firstStepFinished) {
            navigate("/privat/ansokan/" + props.ansokanId + "/forandringar")
        }
    }, [firstStepFinished]);

    function buttonDisabled() {
        for (const error of validationErrors) {
            if (error.category === "fastighet" || error.category === "atgard" && error.severity === 'error') {
                return true
            }
        }
        return false
    }

    function handleFastighetSelected(beteckning: string) {
        dispatch(loadRegisterbeteckningFunc(beteckning, []))
            .then((response: any) => {
                dispatch(successLoadFastighetsinnehav(response))
            })
            .catch((err) => {
                dispatch(failLoadFastighetsinnehav())
                if (err instanceof LoadFastighetException) {
                    showFastighetCouldNotBeLoadedModal()
                } else {
                    err.component = 'Fastighetsval.js'
                    logError(err, 'Fastighetsval-component')
                    showFastighetCouldNotBeLoadedModal()
                }
            })
    }

    function confirmUncheck(event: React.ChangeEvent<HTMLInputElement>, fieldName: FormType) {
        event.persist()
        if (!event.target.checked) {
            dispatch(updateFieldState(formProperties.fastighetsforandring.name, fieldName.name, false))
        } else {
            dispatch(updateFieldState(formProperties.fastighetsforandring.name, fieldName.name, true))
        }
    }

    function showFastighetCouldNotBeLoadedModal(show: boolean = true) {
        setFastighetCouldNotBeLoadedModal(show)
    }

    function startAnsokan() {
        if(hasValidationErrors) {
            setError(true)
        }
        else {
            dispatch(setFirstStepFinished());
            navigate("/privat/ansokan/" + props.ansokanId + "/forandringar")
        }
    }

    function checkErrorsAndShowInfoModal() {
        if(hasValidationErrors) {
            setError(true)
        }
        else {
            setShowInfoModal(true)
        }
    }

    function showPropertiesInModal() {
        let fastigheter = ""
        valdaFastigheter.forEach((fastighet: Fastighet, index) => {
            fastigheter += fastighet.registerbeteckning
            fastigheter += valdaFastigheter.length-1 !== index ? ", ": ""
        });
        return fastigheter;
    }

    function mapAtgarderToList(list: string[]) {
        if (list) {
          let atgardName = ""
          list.forEach((str: string, index: number) => {
             //@ts-ignore
             atgardName += formProperties[str].displayName
             atgardName += list.length -1 !== index ? ", ": ""
          });
         return atgardName
        }
    }
      
    return (
        <>
            <main id="main-content">
                <div className="container">
                    <h1>Välj åtgärd samt fastighet</h1>
                    <p className="introduction">Innan du börjar med ansökan behöver vi veta vilka åtgärder ansökan
                        gäller samt vilka fastigheter som berörs.</p>
                        <p><i>Observera att du inte kan ändra åtgärd eller fastigheter i en pågående ansökan. Vill du ansöka om något annat eller göra en förrättning med andra fastigheter skapar du en ny ansökan.</i></p>
                    <Row>
                        <Column className="col-12 col-lg-6">
                            <PageTopContent headerLevel={2} title={'Vad vill du göra?'}></PageTopContent>
                            <FormFieldset>
                                <legend>Markera den eller de åtgärder du vill ha hjälp med</legend>

                                <CheckboxWithConfirmation
                                    text={'Avstyckning'}
                                    checked={avstyckningIsSelected}
                                    namn={formProperties.avstyckning.name}
                                    term={'term-avstyckning'}
                                    termLabel={'avstyckning'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        confirmUncheck(event, formProperties.avstyckning)
                                    }}
                                />
                                <CheckboxWithConfirmation
                                    text={'Fastighetsreglering'}
                                    checked={fastighetsregleringIsSelected}
                                    namn={formProperties.fastighetsreglering.name}
                                    term={'term-fastighetsreglering'}
                                    termLabel={'fastighetsreglering'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        confirmUncheck(event, formProperties.fastighetsreglering)
                                    }}
                                />
                                <CheckboxWithConfirmation
                                    text={'Sammanläggning'}
                                    checked={sammanlaggningIsSelected}
                                    namn={formProperties.sammanlaggning.name}
                                    term={'term-sammanlaggning'}
                                    termLabel={'sammanläggning'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        confirmUncheck(event, formProperties.sammanlaggning)
                                    }}
                                />
                                <CheckboxWithConfirmation
                                    text={'Klyvning'}
                                    checked={klyvningIsSelected}
                                    namn={formProperties.klyvning.name}
                                    term={'term-klyvning'}
                                    termLabel={'klyvning'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        confirmUncheck(event, formProperties.klyvning)
                                    }}
                                />
                                <CheckboxWithConfirmation
                                    text={'Fastighetsbestämning'}
                                    checked={fastighetsbestamningIsSelected}
                                    namn={formProperties.fastighetsbestamning.name}
                                    term={'term-fastighetsbestamning'}
                                    termLabel={'fastighetsbestämning'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        confirmUncheck(event, formProperties.fastighetsbestamning)
                                    }}
                                />
                                <CheckboxWithConfirmation
                                    text={'Särskild gränsutmärkning'}
                                    checked={gransutmarkningIsSelected}
                                    namn={formProperties.gransutmarkning.name}
                                    term={'term-gransutmarkning'}
                                    termLabel={'gränsutmärkning'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        confirmUncheck(event, formProperties.gransutmarkning)
                                    }}
                                />
                            </FormFieldset>
                            {error &&
                                <>
                                <ValidationError tag={ValidationTypes.ATGARD}/>
                                </>
                                }
                        </Column>
                        <Column className="col-12 col-lg-6">
                            <div className="layout-group-top">
                                <h2>Vilken eller vilka fastigheter berörs?</h2>
                            </div>
                            <FormFieldset>
                              <legend>Ange fastigheter eller annat som berörs</legend>
                                <Fastighetsinnehav onFastighetSelected={handleFastighetSelected}
                                                   onFastighetDeselected={(beteckning: string) => dispatch(removeRegisterenhet(beteckning))}/>
                                    <FastighetsvalSearchBox addedPropertys={ickeAgdaFastigheter} ickeAgdaFastigheter={ickeAgdaFastigheter} egnaFastigheter={egnaFastigheter}/>
                                <br/>
                            </FormFieldset>
                            {error &&
                                <>
                                    <ValidationError tag={ValidationTypes.FASTIGHET}/>
                                </>
                            }
                        </Column>
                        {fastighetCouldNotBeLoadedModal &&
                            <FastighetCouldNotBeLoadedModal
                                closeModalCallback={() => showFastighetCouldNotBeLoadedModal(false)}/>}
                        {showInfoModal &&
                            <InfoModal closeModalCallback={() => setShowInfoModal(false)} 
                                        valdaFastigheter={() => showPropertiesInModal()} 
                                        startAnsokan={() => startAnsokan()}
                                        showAtgarder={() => mapAtgarderToList(Object.keys(atgarder).filter(forandring => atgarder[forandring]))}
                       ></InfoModal>}
                        <ButtonToolbarV2>
                            <ButtonGroup/>
                            <ButtonGroup>
                                <ButtonTop type="submit" value="gå vidare till ansökan-flödet" onClick={() => checkErrorsAndShowInfoModal()}
                                           icon={"arrow_forward"}
                                           data-selenium-id="FinishFirstStep"
                                           form="loginForm">Gå vidare</ButtonTop>
                            </ButtonGroup>
                        </ButtonToolbarV2>
                    </Row>
                </div>
            </main>
        </>
    )
}


const CheckboxWithConfirmation = (props: {
    text: string,
    checked: boolean,
    namn: string,
    term: string,
    termLabel: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => (
    <>
        <div className="form-checkbox with-description">
            <label data-selenium-id={props.namn} htmlFor={props.namn}>
                <input name={props.namn}
                       id={props.namn}
                       type="checkbox"
                       checked={props.checked}
                       onChange={props.onChange}
                />
                <span className="text">{props.text}</span>
            </label>
            <p>Läs mer om <Term label={props.termLabel} moment={'help-description'} term={props.term}/></p>
        </div>
    </>
)

export function FastighetCouldNotBeLoadedModal(props: { closeModalCallback: () => void }) {
    return <ModalDialogSub modalTitle={'Fastigheten kunde inte väljas'}
                           description={'Fastigheten kunde inte laddas och kan därför inte väljas.'}
                           closeModalCallback={props.closeModalCallback}/>
}

export function InfoModal(props: { closeModalCallback: () => void, startAnsokan: () => void, valdaFastigheter: () => any, showAtgarder: () => any}) {
    return <ModalDialogSub modalTitle={'Är du helt säker på att du vill gå vidare?'}
                           description={<>
                            <p>Du har valt <strong>{props.showAtgarder()}</strong> för <strong>{props.valdaFastigheter()}</strong></p>
                            <p>Är detta korrekt? Tänk på att du inte kan ändra dessa uppgifter senare i ansökningsprocessen. Vill du i ett senare skede ändra åtgärd eller fastigheter behöver du börja om med en ny ansökan.</p>
                            </>}
                           closeModalCallback={props.closeModalCallback}
                           acceptButton={{ text: 'Ja, gå vidare', onClick: () => props.startAnsokan()}}
                           declineButton={{ text: 'Nej' }}
                           id={'showInfoModalId'}
                           data-selenium-id="showInfoModalId"
                           />
  }
  