// eslint-disable-next-line
import logo from '!file-loader!redet-atomic/dist/gfx/logotypes/lantmateriet-rgb.svg'
import {ReactNode} from "react";
import LanguageSelector from "./LanguageSelector";

interface Props {
    displayLocaleSelector?: boolean,
    children: ReactNode
}

export function FullscreenContentDialog({displayLocaleSelector = true, children}: Props) {

    return <div className="redet realestate">
        <div className="modal themeout show" id="modal-form">
            <div className="modal-dialog">
                <div className="modal-content centered">
                    {displayLocaleSelector && <LanguageSelector rightAligned={true}/>}
                    <figure className="header-logo">
                        <img src={logo} alt="Lantmäteriets logotyp"/>
                    </figure>
                    {children}
                </div>
            </div>
        </div>
    </div>
}