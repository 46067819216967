import React, {useEffect} from 'react'
import {Navigation} from '../components'
import {useCookies} from 'react-cookie'
import {createTermsOfUsageHash} from '../utils/authentication'
import {useLocation, useNavigate} from 'react-router-dom'
import {TermsOfUsageModal} from '../components/General/TermsOfUsageModal'
import {PageHeader} from '../components/General/forms/layoutElements'
import {useAppSelector} from "../hooks";

// Increment this variable when terms are updated to force all users to accept the updated version
export const CURRENT_USER_TERMS_OF_USAGE_VERSION = 2
export const TERMS_OF_USAGE_COOKIE_NAME = 'enak_accepted_terms_of_usage'

export function TermsOfUsage() {

    const navigate = useNavigate()
    const location = useLocation()
    const user = useAppSelector((store) => store.auth.user);
    const [cookies, setCookie] = useCookies()

    useEffect(() => {
        const userHasAgreedToTerms = cookies[TERMS_OF_USAGE_COOKIE_NAME] && user && createTermsOfUsageHash(user) === cookies[TERMS_OF_USAGE_COOKIE_NAME];
        if (userHasAgreedToTerms) {
            if (location?.state?.from?.pathname) {
                navigate(location.state.from.pathname, {replace: true})
            } else {
                navigate('/privat', {replace: true})
            }
        }
    }, [cookies, location?.state?.from?.pathname, location?.state?.referrer, navigate, user]);
    function acceptUserAgreement() {
        if (user === undefined) navigate("/", {replace: true})

        const hash = createTermsOfUsageHash(user)
        setCookie(TERMS_OF_USAGE_COOKIE_NAME, hash, {
            httpOnly: false,
            expires: new Date(2030, 0),
            secure: false,
            sameSite: 'lax',
            path: '/'
        })
    }

    return (
        <div>
            <header>
                <Navigation/>
                <PageHeader title={<>Lantmäteri&shy;förrättning</>}/>
            </header>
            <main>
                <TermsOfUsageModal okClick={acceptUserAgreement}/>
            </main>
        </div>
    )
}

export default TermsOfUsage

