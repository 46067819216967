import {useEffect, useState} from 'react'
// @ts-ignore
import {ButtonGroup, ButtonSub, ButtonToolbarV2} from 'redet-react-components'
import ArendeListFilter, {ArendeFilterType} from './ArendeListFilter';


type ArendeFilterProps = {
    filter: ArendeFilterType | undefined,
    onFilterChange: (filter: ArendeFilterType) => void;
}

export const ArendeFilter = (props: ArendeFilterProps) => {

    const [isFilterExpanded, setFilterExpanded] = useState(props.filter !== undefined)

    useEffect(() => {
        setFilterExpanded(props.filter !== undefined)
    }, [props.filter]);


    return (
        <div id="sortera-filtrera-kontroller">
            <ButtonToolbarV2>
                <ButtonGroup>
                    <ButtonSub text={isFilterExpanded ? 'Dölj filtrering' : 'Filtrera ärendelistan'}
                        icon={isFilterExpanded ? 'expand_less' : 'expand_more'}
                        onClick={() => setFilterExpanded(!isFilterExpanded)}
                        id="arendeListFilterShowFilterButton" />
                </ButtonGroup>
            </ButtonToolbarV2>

            {isFilterExpanded &&
                <ArendeListFilter filter={props.filter} onFilterChange={props.onFilterChange}></ArendeListFilter>
            }
        </div>
    )
}
