// @ts-ignore
import {IconButton, ModalDialogSub, TextInput} from 'redet-react-components'

export const DeleteKompletteringConfirmationModal = (props: { closeModalCallback: Function, kompletteringTitle:string, onClickJaKnapp: Function }) => {
    return <ModalDialogSub modalTitle={'Ta bort komplettering'}
                           description={<> Om du tar bort en handling som har en påbörjad underskrift kommer denna att
                               avbrytas.< br/> <br/>
                               Vill du ta bort
                               handlingen {props.kompletteringTitle}?</>}
                           closeModalCallback={props.closeModalCallback}
                           acceptButton={{
                               text: 'Ja',
                               onClick: props.onClickJaKnapp
                           }
                           }
                           declineButton={{text: 'Nej'}}
    />
}

export const ArendeKompletteringSigningUrlModal = (props: { closeModalCallback: Function, epost: string | undefined }) => {
    const copyUrl = () => {
        const urlInput = (document.getElementById('urlTextInputId') as HTMLInputElement)
        // @ts-ignore
        urlInput.select()
        // @ts-ignore
        urlInput.setSelectionRange(0, 99999)
        navigator.clipboard.writeText(urlInput.value).then(() => true)
    }

    return (
        <>
            <ModalDialogSub
                name={'Signeringsurl-modaldialog'}
                modalTitle={'Inbjudningslänk'}
                description={
                    'Alla som ska skriva under eller bestyrka kommer få inbjudningslänken skickad till ' +
                    'den e-postadress du angivit men om något skulle gå snett ' +
                    'så kan du kopiera inbjudningslänken nedan och själv skicka till dem på valfritt sätt.'
                }
                closeModalCallback={props.closeModalCallback}>
                <div className="flex-line">
                    <TextInput id={'urlTextInputId'}
                               aria-label={'Inbjudningslänk'}
                               defaultValue={props.epost}
                        // @ts-ignore
                               onFocus={(event) => event.target.select()}/>
                    < IconButton ariaLabel={'Kopiera länk'}
                                 icon={'content_copy'}
                                 onClick={copyUrl}/>
                </div>
            </ModalDialogSub>
        </>
    )
}
