import {Contact} from "../../domain/Contact";
import React, {ReactNode} from "react";
import {getEpost, getNamn} from "../../utils/contactsHelper";

export function ContactNamesList(props: { contacts: Contact[] }) {

    const getHtmlNode = (): ReactNode => {

        const getContactHtml = (contact: Contact) => {
            return <>
                <strong>
                    {getNamn(contact)}
                </strong>{!contact.data.rollSokande && ` (${getEpost(contact)})`}
            </>
        }

        let contactsList = props.contacts ? [...props.contacts] : []
        const lastContact = contactsList.pop()

        if (lastContact) {
            if (contactsList.length === 0) return <span data-testid={'contact-names-list'}>{getContactHtml(lastContact)}</span>
            if (contactsList.length === 1)
                return <span data-testid={'contact-names-list'}>
                    {getContactHtml(contactsList[0])} och {getContactHtml(lastContact)}
                </span>
            if (contactsList.length > 1) {
                return <span data-testid={'contact-names-list'}>
                    {contactsList.map((contact, index) =>
                        <React.Fragment key={`contactNameKey${index}`}>
                            {getContactHtml(contact)}
                            {index < contactsList.length - 1 ? ', ' : ''}
                        </React.Fragment>
                    )} och {getContactHtml(lastContact)}
                </span>
            }
        }
        return <></>
    }

    return getHtmlNode()
}

export default ContactNamesList
