import * as interactions from '../interactions'
import PropTypes from 'prop-types'
import React from 'react'
import { MapButton } from 'redet-react-components'

export function ChangeToolsMenu (props) {
  return (
    <>
      <MapButton
        disabled={!props.hasFeatures}
        onClick={() => props.onClickToolCallback(interactions.MODIFY)}
        id="mapToolbarModify"
        isActive={props.activeTool === interactions.MODIFY}
        icon={'create'}
        text={'Redigera'}/>
      <MapButton
        disabled={!props.hasFeatures}
        onClick={() => props.onClickToolCallback(interactions.MOVE)}
        id="mapToolbarMove"
        isActive={props.activeTool === interactions.MOVE}
        icon={'open_with'}
        text={'Flytta'}/>
      <MapButton
        disabled={!props.hasFeatures}
        onClick={() => props.onClickToolCallback(interactions.DELETE)}
        id="mapToolbarDelete"
        isActive={props.activeTool === interactions.DELETE}
        icon={'clear'}
        text={'Ta bort'}/>
    </>
  )
}

ChangeToolsMenu.propTypes = {
  hasFeatures: PropTypes.bool,
  onClickToolCallback: PropTypes.func,
  activeTool: PropTypes.string
}