import {useContext, useEffect} from "react";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { ButtonGroup, PageSubContent, PageTopContent } from "redet-react-components";
import {
  AnsokanWizardSDGContext,
} from "./AnsokanWizardSDGContext";
import ApplicationSDGList from "./ApplicationSDGList";
import {
  filterFilesByAllowedTypes,
  filterFilesByCount,
  filterFilesByDuplicates,
  filterFilesBySize,
} from "./HandlingUtils";
import LinkButton from "./LinksForSDG";
import UploadFilesApplication from "./UploadFilesApplication";
import {scrollToMainTag} from "./util";

export default function AdditionalDocuments() {

  const { handlingar, setHandlingar } = useContext(AnsokanWizardSDGContext);
  const { t } = useTranslation();

    useEffect(() => {
        scrollToMainTag()
    }, []);

  function removeSelected(file: File) {
    setHandlingar((handling: File[]) =>
      handling.filter((handlingFile: File) => handlingFile.name !== file.name)
    );
  }

  return (
    <>
      <PageTopContent
        title={t("documents.title")}
        introduction={t('documents.introduction')}
        headerLevel={2}
        headerId={"additionalDocumentsId"}
      />
      <br />
      <PageSubContent
        title={t("documents.uploadLabel")}
        headerLevel={3}
        headerId={"uploadDocumentsId"}
      >
        <UploadFilesApplication
          multiple={true}
          selectedFiles={(selected) => setHandlingar([...handlingar, ...selected])}
          validationMethods={[
            (selected) => filterFilesByDuplicates(selected, handlingar),
            (selected) => filterFilesByAllowedTypes(selected, ["application/pdf"]),
            filterFilesBySize,
            (selected) => filterFilesByCount(selected, handlingar, 30),
          ]}
          labelText={<p>{t("documents.uploadFiles")}</p>}
        />
        {handlingar?.length > 0 &&
        <ApplicationSDGList deleteHandlingCallback={(selected) => removeSelected(selected)} items={handlingar} listSetter={setHandlingar}/>
        }

      </PageSubContent>
      <div className="toolbar mobile-toolbar">
        <ButtonGroup>
          <LinkButton to={"/euCitizenApplication/pdfApplication"} icon="arrow_back" text={t('documents.backBtn')}/>
        </ButtonGroup>
        <ButtonGroup>
          <LinkButton to={"/euCitizenApplication/realEstates"} icon="arrow_forward" text={t('documents.frwrdBtn')}/>
        </ButtonGroup>
      </div>
     </>
  );
}
