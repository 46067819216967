import OLMap from 'components/Map/OLMap';
import { useAppDispatch } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { addProgress, endProgress } from 'redux/modules/fullscreenLoading';
import { uploadKartskiss } from 'redux/modules/kartskiss';
import { selectTool, setMapTitle } from 'redux/modules/map';
import { RootState } from 'store';
import TooManyRequestModal from "../../pages/TooManyRequestModal";
import { visaMoment } from "../../redux/modules/help";
import { loadFastighetsomraden } from "../../redux/modules/map";
import { checkIfResponseIsTooManyRequests } from "../../utils/errorHandler";
import { NO_SCROLLING_CSS_CLASS } from "../../utils/globalConstants";
import Log from '../../utils/log';
import FastigheterIKartan from './FastigheterIKartan';
import MapPanel from './MapPanel';
import * as interactions from './interactions';
import Toolbar, { saveMapButtonText } from './toolbar/Toolbar';
//@ts-ignore
import { ModalDialogSub } from 'redet-react-components';
//@ts-ignore
import jquery from 'jquery';

type Props = {
    ansokanId: string
    discardChanges: () => void
}

export function MapControl (props: Props) {

    const log = new Log("MapControl");
    const map = useRef<typeof OLMap>(null);

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const valdaFastigheter: string[] = useSelector((store: RootState) => store.map.fastigheter);
    const drawingFeatures = useSelector((store: RootState) => store.map.drawingFeatures);
    const mapTitle = useSelector((store: RootState) => store.map.mapTitle);
    const mapId = useSelector((store: RootState) => store.map.id);

    const drawingFeaturesExists = () => {
        return Object.keys(drawingFeatures).length > 0
    }

    const [cropMode, setCropMode] = useState(false);
    const [showFastigheterIKartanModal, setShowFastigheterIKartanModal] = useState(false);
    const [showTooManyRequestModal, setShowTooManyRequestModal] = useState(false);
    const [showAbortModal, setShowAbortModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    //Detta körs när komponenten skapas
    useEffect(() => {
        dispatch(visaMoment('help-map', 'Kartskisser', false));
        handleScrollbarWhenEditingMap(true);
    }, [])

    useEffect(() => {
        setShowFastigheterIKartanModal(!drawingFeaturesExists())

        if(drawingFeaturesExists() && valdaFastigheter?.length > 0){

            dispatch(loadFastighetsomraden(valdaFastigheter))
            .then((res) => {
                const tooManyRequestsError = checkIfResponseIsTooManyRequests(res);
                if (tooManyRequestsError) {
                    setShowTooManyRequest();
                }
            }
        )
        }
    },[mapId])

    //Detta körs när komponenten tas bort
    useEffect(() => {
        return () => {
            handleScrollbarWhenEditingMap(false);
        }
    }, [])

    const handleScrollbarWhenEditingMap = (isEditingMap: boolean) => {
        if (isEditingMap) {
            jquery('body').addClass(NO_SCROLLING_CSS_CLASS);
        } else {
            jquery('body').removeClass(NO_SCROLLING_CSS_CLASS);
        }
    }

    const handleMapTitleChange = (filename: string) => {
        var cleanName = filename.replace(/[^[A-Za-zåÅäÄöÖ0-9 ;_\-=,:.!"@#$%&/()[\]{}?*'+]/g, '');
        dispatch(setMapTitle(cleanName));
    }

    const saveAndExit = (filename: string) => {
        handleMapTitleChange(filename)
        dispatch(addProgress(true))
        dispatch(selectTool(interactions.PAN))

        //@ts-ignore Får åtgärdas när OLMap har setts över
        map?.current?.mapExporter.exportMapImage()
            .then((imageData: any) => {
                dispatch(uploadKartskiss(props.ansokanId, imageData));
                props.discardChanges();
                dispatch(endProgress());
                navigate("/privat/ansokan/" + props.ansokanId + "/kartskiss", {replace: true});
            }).catch((error: Error) => {
            log.error(error);
            dispatch(endProgress());
            setShowErrorModal(true);
        });
    }

    const enterCropMode = () => {
        dispatch(visaMoment('help-map-crop', 'Kartskisser', false));
        dispatch(selectTool(interactions.PAN));
        setCropMode(true);
    }

    const exitCropMode = () => {
        dispatch(visaMoment('help-map', 'Kartskisser', false))
        setCropMode(false);
    }

    const checkIfBucketIsFull = (value: boolean) => {
        if (value === true) {
            setShowTooManyRequest();
        }
    }

    const setShowTooManyRequest = (show = true) => {
        setShowTooManyRequestModal(show);
    }

    const closeModalAndGoBack = () => {
        props.discardChanges()
        navigate("/privat/ansokan/" + props.ansokanId + "/kartskiss", {replace: true});
    }

    return (
        <div>
            {/* @ts-ignore på grund av ref som behöver typas bättre?*/}
            <OLMap ref={map} cropMode={cropMode}>
                <Toolbar
                    onSaveMapClick={() => enterCropMode()}
                    cropMode={cropMode}
                    onDiscardChangesClick={() => setShowAbortModal(true)}/>
                {cropMode &&
                    <div>
                        <MapPanel
                            saveAndExit={(filename: string) => saveAndExit(filename)}
                            exitCropMode={() => exitCropMode()}
                            mapTitle={mapTitle}
                        />
                    </div>
                }
            </OLMap>
            {showFastigheterIKartanModal && <FastigheterIKartan
                onButtonClose={() => setShowFastigheterIKartanModal(false)}
                tooManyRequestCallback={(value) => checkIfBucketIsFull(value)}
                showTooManyRequestModal={false}/>}
            {showTooManyRequestModal &&
                <TooManyRequestModal closeModalCallback={closeModalAndGoBack}/>}

            {showErrorModal && <ErrorModal
                message='Det gick inte att skapa en korrekt kartbild. Prova att spara kartan igen.'
                onAccept={() => setShowErrorModal(false)}></ErrorModal>}

            {showAbortModal &&
            <AbortKartskissModal
                onAccept={() => {
                    props.discardChanges();
                    navigate("/privat/ansokan/" + props.ansokanId + "/kartskiss", {replace: true});
                }}
                onDecline={() => setShowAbortModal(false)}></AbortKartskissModal>}
        </div>
    );
}

export function AbortKartskissModal ( props: {onAccept: () => void, onDecline: () => void}) {
    return <ModalDialogSub modalTitle={'Avbryt'}
                            description={
                            <span>
                                <p>Om du avbryter kommer inte dina ändringar att sparas. Vill du spara din kartskiss klickar du på
                                    <strong> {saveMapButtonText}</strong>.
                                </p>
                                <p className="modal-question">Vill du avbryta?</p></span>}
                            acceptButton={{ text: 'Ja', onClick: () => props.onAccept() }}
                            declineButton={{ text: 'Nej', onClick: () => props.onDecline() }}/>
}

export function ErrorModal ( props: {message: string, onAccept: () => void}) {
    return <ModalDialogSub modalTitle={'Ett fel har uppstått'}
                            description={
                            <span>{props.message}</span>
                            }
                            acceptButton={{ text: 'Ok', onClick: () => props.onAccept() }}/>
}


export default MapControl;