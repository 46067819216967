import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ValjFilBifogadKartskissWizardStep from './wizard/ValjFilBifogadKartskissWizardStep'
import { NamgeKartskissBifogadKartskissWizardStep } from './wizard/NamgeKartskissBifogadKartskissWizardStep'
import { connect } from 'react-redux'
import {
  displayFullscreenProgress,
  hideFullscreenProgress,
  updateFullscreenProgress
} from 'redux/modules/fullscreenProgress'
import { uploadBifogadKartskiss } from '../../redux/modules/bifogadKartskiss'
import {
  ForbiddenBifogadHandlingFiletypeException,
  TooManyPagesInPdfException,
  UserAbortException
} from '../../repository/bifogadKartskissRepository'
import WizardDialog, { WizardDialogStep } from '../../components/wizard/WizardDialog'
import ModalDialogError from '../../components/General/ModalDialogError'
import { MAX_NUMBER_OF_MAPS } from '../../utils/globalConstants'
import { logError } from '../../utils/errorHandler'
import { FELAKTIG_PDF } from 'views/BifogadeHandlingar/handlingConstants'
import {FaultyPDFFileError} from "../../repository/BifogadHandlingException";

const mapDispatchToProps = {
  uploadBifogadKartskiss,
  displayFullscreenProgress,
  updateFullscreenProgress,
  hideFullscreenProgress,
}

export class BifogadKartskissWizardUploadForm extends Component {
  static propTypes = {
    // Props
    ansokanId: PropTypes.string.isRequired,
    reloadBifogadKartskissList: PropTypes.func.isRequired,
    closeUploadForm: PropTypes.func.isRequired,

    // Fullscreen progress
    displayFullscreenProgress: PropTypes.func.isRequired,
    updateFullscreenProgress: PropTypes.func.isRequired,
    hideFullscreenProgress: PropTypes.func.isRequired,

    // Redux
    // övrigt
    uploadBifogadKartskiss: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)

    this.state = {
      selectedFile: null,
      kartskissNamn: '',
      errorMessage: undefined,
      ...props.initialState
    }
    this.setFile = this.setFile.bind(this)
    this.setName = this.setName.bind(this)
    this.startUpload = this.startUpload.bind(this)
  }

  onUploadComplete () {
    setTimeout(this.props.hideFullscreenProgress, 500)
    setTimeout(this.props.closeUploadForm, 500)
    this.props.reloadBifogadKartskissList()
  }

  onUploadFailed (error) {
    this.props.hideFullscreenProgress()
    const felVidUppladdning = 'Fel vid uppladdning';
    if (error instanceof ForbiddenBifogadHandlingFiletypeException) {
      this.setState({
        errorMessage: {
          title: felVidUppladdning,
          message: 'En eller flera av de filer som laddats upp nekades. Detta kan bero på att filinnehållet inte är av godkänd typ eller inte matchar filändelsen.'
        }
      })
    } else if (error instanceof TooManyPagesInPdfException) {
      this.setState({
        errorMessage: {
          title: felVidUppladdning,
          message: `Varje sida i den uppladdade PDF-filen blir en separat kartskiss och du kan endast ha ${MAX_NUMBER_OF_MAPS} kartskisser i en ansökan`
        }
      })
    } else if (error instanceof FaultyPDFFileError) {
      this.setState({
        errorMessage: {
          title: felVidUppladdning,
          message: FELAKTIG_PDF
        }
      })
    } else if (!(error instanceof UserAbortException)) {
      error.component = 'BifogadKartskissWizardUploadForm.js'
      logError(error, 'BifogadKartskissWizardUploadForm-component')
      this.setState({
        errorMessage: {
          title: felVidUppladdning,
          message: <span>Ett tekniskt fel uppstod vid uppladdning av kartskiss. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
            href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>
        }
      })
    }
  }

  onUploadProgress (event) {
    if (event.lengthComputable) {
      const percentComplete = parseInt((event.loaded) * 100 / event.total, 10)
      this.props.updateFullscreenProgress(percentComplete)
    }
  }

  startUpload () {
    const xhrProxy = {}
    const abortFunction = () => {
      xhrProxy.xhr.userForcedAbort = true
      xhrProxy.xhr.abort()
    }

    this.props.uploadBifogadKartskiss(
      this.state.kartskissNamn,
      this.props.ansokanId,
      this.state.selectedFile,
      xhrProxy,
      () => this.props.displayFullscreenProgress(abortFunction),
      (event) => this.onUploadProgress(event),
      () => this.onUploadComplete(),
      (error) => this.onUploadFailed(error)
    )
  }

  setFile (file) {
    this.setState({ selectedFile: file })
  }

  setName (name) {
    this.setState({ kartskissNamn: name })
  }

  render () {
    return (
      <>
        <WizardDialog
          title="Ladda upp befintlig kartskiss"
          cancelFunction={this.props.closeUploadForm}
          defaultNav={false}
          elementsToAllowFocus={['helpSidePanelId', 'validationSidePanelId']}
          id="BifogaKartskissModalWizardId">
          <WizardDialogStep stepTitle={'Välj fil'}>
            <ValjFilBifogadKartskissWizardStep setFileCallback={this.setFile}
                                               file={this.state.selectedFile}/>
          </WizardDialogStep>
          <WizardDialogStep stepTitle={'Namnge kartskiss'}>
            <NamgeKartskissBifogadKartskissWizardStep setKartskissNamnCallback={this.setName}
                                                      startUploadCallback={this.startUpload}
                                                      kartskissNamn={this.state.kartskissNamn}/>
          </WizardDialogStep>
        </WizardDialog>
        {this.state.errorMessage &&
          <ModalDialogError modalTitle={this.state.errorMessage.title}
                            button={{
                              text: 'OK',
                              icon: 'check',
                              onClickFunction: () => {this.setState({ errorMessage: undefined })}
                            }}
                            description={this.state.errorMessage.message}/>}
      </>

    )
  }
}

export default connect(undefined, mapDispatchToProps)(BifogadKartskissWizardUploadForm)