import {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {closeUploadForm, uploadBifogadHandling} from 'redux/modules/bifogadHandling'
import { ValjTypStep1Wizard } from './uploadWizard/ValjTypStep1Wizard'
import UploadFilesStep2Wizard from './uploadWizard/UploadFilesStep2Wizard'
import { BIFOGADHANDLINGTYPE, BIFOGADHANDLINGTYPE_NOT_SELECTED } from 'utils/globalConstants'
import { Term } from '../index'
import {
  displayFullscreenProgress,
  hideFullscreenProgress,
  updateFullscreenProgress
} from 'redux/modules/fullscreenProgress'
import WizardDialog, { WizardDialogStep } from '../../components/wizard/WizardDialog'
import { ANTAL_SIDOR_FELMEDDELANDE, FELAKTIG_PDF, FILTYP_FELMEDDELANDE } from './handlingConstants'
import { logError } from '../../utils/errorHandler'
import { ModalDialogAlert } from 'redet-react-components'
import {FaultyPDFFileError, ForbiddenBifogadHandlingFiletypeException, TooManyFilesInBifogadHandlingException, UserAbortException} from "../../repository/BifogadHandlingException";

const mapDispatchToProps = {
  uploadBifogadHandling,
  closeUploadForm,
  displayFullscreenProgress,
  updateFullscreenProgress,
  hideFullscreenProgress,
}

class BifogadeHandlingarWizardUploadForm extends Component {
  static propTypes = {
    uploadBifogadHandling: PropTypes.func.isRequired,
    ansokanId: PropTypes.string.isRequired,
    closeUploadForm: PropTypes.func.isRequired,
    onUploadCallback: PropTypes.func,
    displayFullscreenProgress: PropTypes.func.isRequired,
    updateFullscreenProgress: PropTypes.func.isRequired,
    hideFullscreenProgress: PropTypes.func.isRequired,
    initialStep: PropTypes.number,
  }

  static defaultProps = {
    initialStep: 1,
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      selectedFiles: [],
      attachmentType: props.attachmentType ?? '',
      ovrigtBeskrivning: '',
      errorMessage: [],
      bestyrkare: '',
      ...props.initialState
    }
    this.selectType = this.selectType.bind(this)
    this.startUpload = this.startUpload.bind(this)
    this.clearUploadErrorMessages = this.clearUploadErrorMessages.bind(this)
    this.removeSelected = this.removeSelected.bind(this)
  }

  removeSelected (file) {
    this.setState({ selectedFiles: [...this.state.selectedFiles.filter(selectedFile => selectedFile !== file)] })
  }

  selectType = (type) => {
    this.setState({ attachmentType: type })
  }

  setOvrigBeskrivning = (text) => {
    this.setState({ ovrigtBeskrivning: text })
  }

    startUpload() {
        const handling = {
            type: this.state.attachmentType,
            description: this.state.attachmentType === 'ANNAT' ? this.state.ovrigtBeskrivning : '',
            files: this.state.selectedFiles,
        }
        const abortController = new AbortController()
        //visa progress med avbryt/abort
        this.props.displayFullscreenProgress(() => abortController.abort())
        this.props.uploadBifogadHandling(
            this.props.ansokanId,
            handling,
            (percentComplete = 100) => {
                this.props.updateFullscreenProgress(percentComplete)
                if (percentComplete >= 95) {
                    //göm avbryt-knapp när uppladdning klar
                    this.props.displayFullscreenProgress(undefined, true)
                }
            },
            abortController)
            .then(() => this.onUploadComplete())
            .catch(error => {
                if (!(error instanceof UserAbortException)) {
                    this.onUploadFailed(error)
                }
            })
            .finally(() => setTimeout(this.props.hideFullscreenProgress, 500))
    }

  onUploadFailed (error) {

    const title = 'Fel vid uppladdning'
    if (error instanceof TooManyFilesInBifogadHandlingException) {
      this.setState({
        errorMessage: {
          title: title,
          message: ANTAL_SIDOR_FELMEDDELANDE
        }
      })
    } else if (error instanceof ForbiddenBifogadHandlingFiletypeException) {
      this.setState({
        errorMessage: {
          title: title,
          message: FILTYP_FELMEDDELANDE
        }
      })
    } else if (error instanceof FaultyPDFFileError) {
      this.setState({
        errorMessage: {
          title: title,
          message: FELAKTIG_PDF
        }
      })
    } else {
      error.component = 'BifogadeHandlingarWizardUploadForm.js'
      logError(error, 'BifogadeHandlingarWizardUploadForm-component')
      this.setState({
        errorMessage: {
          title: title,
          message: <span>Ett tekniskt fel uppstod vid uppladdning av bifogad handling. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
            href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>
        }
      })
    }
  }

  onUploadComplete () {
    if (this.props.onUploadCallback) {
      this.props.onUploadCallback()
    }
    this.props.closeUploadForm()
  }

  clearUploadErrorMessages () {
    this.setState({ errorMessage: [] })
  }

  setSelectedFiles = (filesToAdd) => {
    this.setState({ selectedFiles: [...this.state.selectedFiles, ...filesToAdd] })
  }

  render () {
    const typeOptions = BIFOGADHANDLINGTYPE.map(handling =>
      ({ key: handling.value, value: handling.value, description: handling.description, helpTerm: handling.helpTerm })
    )

    const instructionText = (<>Välj fil/filer som hör till <Term
      label={this.state.attachmentType ? (this.state.attachmentType === BIFOGADHANDLINGTYPE_NOT_SELECTED ? '' : BIFOGADHANDLINGTYPE.find(handling => handling.value === this.state.attachmentType).description) : 'help-upload'}
      moment="help-upload"
      term={this.state.attachmentType ? (this.state.attachmentType === BIFOGADHANDLINGTYPE_NOT_SELECTED ? '' : BIFOGADHANDLINGTYPE.find(handling => handling.value === this.state.attachmentType).helpTerm) : ''}/>.
      Om du lägger till flera filer
      kommer de att slås ihop till en handling.</>)
    return (
      <>
        <WizardDialog
          title="Lägg till en handling"
          cancelFunction={this.props.closeUploadForm}
          defaultNav={false}
          initialStep={this.props.initialStep}
          id="bifogadHandlingWizardModalId"
          elementsToAllowFocus={['helpSidePanelId', 'validationSidePanelId']}>
          <WizardDialogStep stepTitle={'Välj typ av handling'}>
            <ValjTypStep1Wizard
              attachmentType={this.state.attachmentType}
              helpChapter={'help-upload'}
              ovrigtBeskrivning={this.state.ovrigtBeskrivning}
              selectAttachmentTypeCallback={this.selectType}
              setOvrigtBeskrivningCallback={this.setOvrigBeskrivning}
              typeOptions={typeOptions}
              nextStepButtonText={'Välj filer'}/>
          </WizardDialogStep>

          <WizardDialogStep stepTitle={'Välj filer'}>
              <UploadFilesStep2Wizard
                attachmentType={this.state.attachmentType}
                instructionText={instructionText}
                ansokanId={this.props.ansokanId}
                nextStepButtonText={'Lägg till bifogad handling'}
                selectedFiles={this.state.selectedFiles}
                selectFile={this.setSelectedFiles}
                startUpload={this.startUpload}
                removeSelectedFile={this.removeSelected}
                clearFileUploadErrorMessages={this.clearUploadErrorMessages}
                fileUploadErrorMessages={this.state.errorMessage.length > 0 ? [this.state.errorMessage.message] : []}
                isLastStep={false}
                ansokan={true}
            />
          </WizardDialogStep>

        </WizardDialog>
        {this.state.errorMessage.length !== 0 &&
          <ModalDialogAlert modalTitle={this.state.errorMessage.title}
                            description={this.state.errorMessage.message}
                            acceptButton={{ text: 'OK', icon: 'check', onClick: this.clearUploadErrorMessages }}/>}
      </>
    )
  }
}

export default connect(undefined, mapDispatchToProps)(BifogadeHandlingarWizardUploadForm)
