import {useSelector} from 'react-redux'
import {Navigation} from '../components'
import {createTermsOfUsageHash} from '../utils/authentication'
import {PageHeader} from '../components/General/forms/layoutElements'
import {TermsOfUsageModalForSDG} from './euCitizenApplication/TermsOfUsageModalForSDG'
import {RootState} from 'store'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {useEffect} from "react";

// Increment this variable when terms are updated to force all users to accept the updated version
export const CURRENT_USER_TERMS_OF_USAGE_VERSION = 2
export const TERMS_OF_USAGE_COOKIE_NAME = 'enak_accepted_terms_of_usage'

export function TermsOfUsageSDG() {

  const { t } = useTranslation();

  const user = useSelector((store: RootState) => store.auth.user)
  const navigate = useNavigate()
  const location = useLocation()
  const [cookies, setCookie, removeCookie] = useCookies()

  useEffect(() => {
    const userHasAgreedToTerms = cookies[TERMS_OF_USAGE_COOKIE_NAME] && user && createTermsOfUsageHash(user) === cookies[TERMS_OF_USAGE_COOKIE_NAME];
    if (userHasAgreedToTerms) {
      if (location?.state?.from?.pathname) {
        navigate(location.state.from.pathname, {replace: true})
      } else {
        navigate('/privat', {replace: true})
      }
    }
  }, [cookies, location?.state?.from?.pathname, location?.state?.referrer, navigate, user])

  function acceptUserAgreement () {
    const hash = createTermsOfUsageHash(user)
    setCookie(TERMS_OF_USAGE_COOKIE_NAME, hash, {
      httpOnly: false,
      expires: new Date(2030, 0),
      secure: false,
      sameSite: 'lax',
      path: '/'
    })
  }

    return (
      <div>
        <header>
          <Navigation/>
          <PageHeader title={<>Lantmäteri&shy;förrättning</>}/>
        </header>
        <TermsOfUsageModalForSDG okButtonText={t('navigation.termsOfUsage_accept')} okClick={() => {
          acceptUserAgreement()
        }}/>
      </div>
    )
  }

  export default TermsOfUsageSDG

