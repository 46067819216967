import { useEffect, useState } from 'react'
import {
  ButtonTop,
  CircleLoading,
  ModalDialogAlert,
  WizardIntorduction,
  WizardNavigationButtonToolbar
} from 'redet-react-components'
import {useKompletteringSigningStatusPoll} from '../kompletteringSigningStatusPollHook'
import {YrkandeUnderskriftWizardModal} from "./YrkandeUnderskriftWizardModal";
import {ForbiddenBifogadHandlingFiletypeException, TooLongTextException, TooManyFilesInBifogadHandlingException} from "../../../../repository/BifogadHandlingException";

export function YrkandeWizardStepSignering ({
  setInskickadDatum,
  createKompletteringAndStartSigning,
  fetchSigningStatusKomplettering,
  refetchKompletteringarList,
  submitKomplettering,
  abortKompletteringSigning,
  prevButtonText,
  ...rest
}) {

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)

  const [signingUrl, setSigningUrl] = useState(undefined)
  const [showSigningModal, setShowSigningModal] = useState(false)
  const [abortedSigning, setAbortedSigning] = useState(false)

  const [kompletteringId, setKompletteringId] = useState(undefined)
  const [isPollingError, setIsPollingError] = useState(false)
  
  const maxAntalTeckenText = 'Texten för yrkandet överstiger maximalt antal tecken (4000st).'
  const maxAntalfilerText = 'Antalet uppladdade filer/sidor överstiger maximalt tillåtna antal (10st).'
  const filtypText = 'En eller flera av de filer som laddats upp nekades. Detta kan bero på att filinnehållet inte är av godkänd typ eller inte matchar filändelsen. Vid frågor vänligen kontakta kundcenter.'

  const loadingSpinnerInitProps = {
    percentageComplete: 0,
    text: 'Laddar upp yrkande och startar signering...',
    id: 'loadingYrkandeId',
    button: { text: 'Avbryt', icon: 'clear', onClick: () => {setShowLoadingSpinner(false)} }
  }

  const [loadingSpinnerProps, setLoadingSpinnerProps] = useState({ ...loadingSpinnerInitProps })
  const buttonText = 'Skriv under & skicka in'
  const [errorProps, setErrorProps] = useState({ errorMsg: 'Någonting gick fel. Vänligen försök igen senare.' })
  
  const signStatus = useKompletteringSigningStatusPoll(kompletteringId);

  useEffect(() => {
      if(hasSigningEnded()){
        setShowLoadingSpinner(false)
        setShowSigningModal(false)
      }
  }, [signStatus?.hasSigningDeclined, signStatus?.hasSigningSucceeded, signStatus?.hasSigningTimedOut])

  useEffect(() => {
      if(signStatus?.hasSigningSucceeded) {
        submitKomplettering(kompletteringId)
            .then(result => {
              setInskickadDatum(result.inskickadDatum)
              setShowSigningModal(false)
              rest.nextStep()
            }).catch(() => {
            // swallow error as this can happen if batch-job i running at the same time
          })
      }
  },[signStatus?.hasSigningSucceeded])

  const hasSigningEnded = () => {
    return signStatus?.hasSigningDeclined || signStatus?.hasSigningSucceeded || signStatus?.hasSigningTimedOut;
  }

  function onProgressCallback (progress) {
    setLoadingSpinnerProps({
      ...loadingSpinnerInitProps,
      percentageComplete: Math.trunc((progress.loaded / progress.total) * 100)
    })
  }

  function onFetchKompletteringError (err) {
    setErrorProps({ errorMsg: err })
    setIsPollingError(true)
    setShowLoadingSpinner(false)
  }

  function hideErrorModal () {
    setShowLoadingSpinner(false)
    setIsPollingError(false)
    setKompletteringId(undefined);
  }

  function hideDeclinedSigneringModal () {
    setAbortedSigning(false)
    setKompletteringId(undefined);
    refetchKompletteringarList();
  }

  function abortSigningModal() {
    setLoadingSpinnerProps({
      text: 'Förbereder...',
      isFocusTrapped:false
    })

    setShowLoadingSpinner(true)

    abortKompletteringSigning(kompletteringId)
        .then(result => setAbortedSigning(true))
        .finally(() => {
          setShowLoadingSpinner(false)
          setShowSigningModal(false)
        })
  }

  function onClickSkrivUnderOchSkickaIn () {
    const xhrProxy = {}
    const abortFunction = () => {
      xhrProxy.xhr.userForcedAbort = true
      xhrProxy.xhr.abort()
    }
    setShowLoadingSpinner(true)
    setLoadingSpinnerProps({
      percentageComplete: 0,
      text: 'Laddar upp yrkande och startar signering...',
      id: 'loadingYrkandeWaitingId',
      button: {text: 'Avbryt', icon: 'clear', onClick: abortFunction}
    })

    createKompletteringAndStartSigning(onProgressCallback, abortFunction)
        .then((response) => {

          setKompletteringId(response.kompletteringId)

          setSigningUrl(response?.upprattareSigneringUrl)
          setShowSigningModal(true)

          setShowLoadingSpinner(false)

        }).catch((err) => {
      if (err instanceof TooManyFilesInBifogadHandlingException) {
        onFetchKompletteringError(maxAntalfilerText)
      } else if (err instanceof TooLongTextException) {
        onFetchKompletteringError(maxAntalTeckenText)
      } else if (err instanceof ForbiddenBifogadHandlingFiletypeException) {
        onFetchKompletteringError(filtypText)
      } else {
        onFetchKompletteringError('Någoting gick fel. Vänligen försök igen senare.')
      }
    })
  }

  return <>
    <WizardIntorduction title={'Skriv under'}
                        description={`När du klickar på knappen "${buttonText}" får du möjlighet att skiva under yrkandet direkt. När det är klart kommer yrkandet att skickas in automatiskt.`}>
    </WizardIntorduction>
    <WizardNavigationButtonToolbar prevButtonText={prevButtonText}
                                   onClickPrevButton={rest.previousStep}>
      <ButtonTop text={buttonText} isActive={true} id={'skrivUnderOchSkickaInKnappId'}
                 onClick={onClickSkrivUnderOchSkickaIn} icon={'create'}/>
    </WizardNavigationButtonToolbar>

    {showLoadingSpinner && !signStatus?.hasSigningError && !hasSigningEnded() &&
        <CircleLoading isFullscreen={true}
                       attachToDomElementWithId={'elementAttachId' in rest ? rest.elementAttachId : 'rootBody'}
                       {...loadingSpinnerProps}/>}
    {showSigningModal && signingUrl && (
        <YrkandeUnderskriftWizardModal
            closeModalCallback={abortSigningModal}
            signingUrl={signingUrl}
            attachToDomElementWithId={'elementAttachId' in rest ? rest.elementAttachId : 'rootBody'}
        ></YrkandeUnderskriftWizardModal>
    )}
    {(signStatus?.hasSigningError || isPollingError) && <ModalDialogAlert modalTitle={'Ett fel uppstod'}
                                                              ingress={errorProps.errorMsg}
                                                              attachToDomElementWithId={'elementAttachId' in rest ? rest.elementAttachId : 'rootBody'}
                                                              acceptButton={{ onClick: hideErrorModal }}/>}
    {(signStatus?.hasSigningDeclined || abortedSigning) && <ModalDialogAlert
      modalTitle={'Yrkandet har avbrutits'}
      ingress={'Du har avböjt signeringen.'}
      description={<span>Klicka på "skriv under & skicka in" för att försöka signera igen eller stäng för att avsluta yrkandet.</span>}
      acceptButton={{ text: 'Ok', icon: 'check', onClick: hideDeclinedSigneringModal }}
    />
    }
  </>
}
