import {FeatureToggleNotis, FeatureToggleNotisSeverity} from '../featureToggle/FeatureToggle'
import {loadFeatureTogglesSuccess, LoadSuccessActionPayload} from '../featureToggle/featureToggleReducer'
import {getNotificationToggles} from '../featureToggle/FeatureToggleMapper'
import {sortNotiserOfTypeErrorAndUnreadAtTop} from '../../utils/helperMethods'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getValueFromLocalStorage, setValueInLocalStorage} from "../../utils/localStorageUtils";
import md5 from "md5";
import {Notis} from "redet-react-components";

const initialState: {
    showNotificationModal: boolean,
    notiser: FeatureToggleNotis[],
} = {
    showNotificationModal: false,
    notiser: [],
}

const notiserSlice = createSlice({
    name: 'notis',
    initialState: initialState,
    reducers: {
        visaNotisModal(state) {
            state.showNotificationModal = true;
        },
        doljNotisModal(state) {
            state.showNotificationModal = false;
        },
        markNotisAsRead(state, action: PayloadAction<Notis>) {
            if (state.notiser.some(notis => notis.name === action.payload.name)) {
                const newListOfNotiser = state.notiser.slice()
                markCurrentNotisAsRead(newListOfNotiser, action.payload);
                saveCurrentNotisAsReadToLocalstorage(action.payload);
                state.notiser = newListOfNotiser
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadFeatureTogglesSuccess, (state, action: PayloadAction<LoadSuccessActionPayload>) => {
            let notiser = getNotificationToggles(action.payload.toggles)
            const readAndUnreadNotiser = setNotiserThatHasBeenReadAsStatusRead(notiser.slice())
            state.notiser = sortNotiserOfTypeErrorAndUnreadAtTop(readAndUnreadNotiser)
        });
    }
})

function saveCurrentNotisAsReadToLocalstorage(notis: Notis) {
    const valueFromLocalStorage = getValueFromLocalStorage("readNotiser");
    const readNotiser: string[] = JSON.parse(valueFromLocalStorage ?? "[]")
    const notishash = getNotisHash(notis.name, notis.message, notis.severity, notis.header)
    if (!readNotiser.includes(notishash)) {
        readNotiser.push(notishash)
        setValueInLocalStorage("readNotiser", JSON.stringify(readNotiser))
    }
}

function setNotiserThatHasBeenReadAsStatusRead(notiser: FeatureToggleNotis[]): FeatureToggleNotis[] {
    const readNotiser: string[] = JSON.parse(getValueFromLocalStorage("readNotiser") ?? "[]")

    // Alla notiser olästa
    if (readNotiser.length === 0) {
        return notiser.map(notis => ({...notis, unread: true}))
    }

    // Sätt lästa notiser som lästa.
    return notiser.map(notis => {
        const notisHash = getNotisHash(notis.name, notis.metadata.message, notis.metadata.severity, notis.metadata.header);
        return {...notis, unread: !readNotiser.includes(notisHash)}
    })
}

export function getNotisHash(name: string, message?: string, severity?: string | FeatureToggleNotisSeverity, header?: string): string {
    return md5(`${name.toLowerCase()}${message?.toLowerCase()}${severity?.toLowerCase()}${header?.toLowerCase()}`)
}

function markCurrentNotisAsRead(newListOfNotiser: FeatureToggleNotis[], currentNotis: Notis) {
    const currentNotisThatsBeenRead = newListOfNotiser.find(notis => notis.name === currentNotis.name);
    if (currentNotisThatsBeenRead)
        currentNotisThatsBeenRead.unread = false
}

export const {visaNotisModal, doljNotisModal, markNotisAsRead} = notiserSlice.actions;

export default notiserSlice.reducer;
