// @flow
// 1.21 En kontaktperson med rollen sökande måste ha en epostadress angiven
import { BLOCKING, ID_NUMMER_MASTE_ANGES } from './constants'
import { getNamn, harOmbud } from '../../../../utils/contactsHelper'
import { Validation } from './model/Validation'
import { CONTACT_TYPE_INDIVIDUAL } from '../../contacts'

function createError (invalidContacts) {
  const invalidContactsName = invalidContacts.map(contacts => getNamn(contacts))
  if (invalidContacts) {
    return invalidContactsName.map((name) => (
      new Validation(ID_NUMMER_MASTE_ANGES, `Sökande ${name} saknar ID-nummer.`, 'contacts', BLOCKING, undefined)))
  }
}

const missingIdNumber = contact => {
  if (!contact.data.rollSokande) {
    return false
  }
  if (contact.type === CONTACT_TYPE_INDIVIDUAL) {
    return !contact.data.personnummer && !contact.data.ovrigtIdNummer && !contact.data.samordningsnummer
  }
}

function validate (state: Object): ?Array<Validation> {
  const contacts = state.contacts.contactList

  const invalidContacts = contacts.filter(missingIdNumber)
  //Vi vill ta fram alla importerade kontakter som har fått ett ombud
  const invalidWithOmbud = invalidContacts.filter(contact => (harOmbud(contacts, contact.id)))
  if (invalidWithOmbud.length > 0) {
    return createError(invalidWithOmbud)
  }
  return undefined
}

export default validate
