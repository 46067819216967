import React from "react";
import {BifogaHandlingProvider} from "./BifogaHandlingContext";
import {KompletteringBifogaHandlingWizardDialog} from "./ytterligareHandlingWizard/KompletteringBifogaHandlingWizardDialog";

type Props = {
    arendenummer: string
    onCloseWizardDialog: (currentStep: number) => void
    initialStep?: number
}

function KompletteringBifogaHandlingWizard({arendenummer, onCloseWizardDialog, initialStep = 1}: Props) {
    return (
        <BifogaHandlingProvider>
            <KompletteringBifogaHandlingWizardDialog arendenummer={arendenummer}
                                                     onCloseWizardDialog={(currentStep: number) => onCloseWizardDialog(currentStep)}
                                                     {...{initialStep: initialStep}}/>
        </BifogaHandlingProvider>
    )
}

export default KompletteringBifogaHandlingWizard