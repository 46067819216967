//@ts-ignore
import { ExternalLink, SidePanelChapterHeading, SidePanelSection } from 'redet-react-components'

export function LoginHelpContentPrivatperson () {
  return (
    <>
      <SidePanelChapterHeading header={'Ansök om lantmäteriförrättning'}>
      </SidePanelChapterHeading>
        <SidePanelSection id={'frontpage-help-elegitimation'}
                          heading={<>Inloggning och signatur med<br/>e-legitimation</>}>
            <p>
                Inloggning och elektronisk signatur i Lantmäteriets e-tjänst kräver säker identifiering, du behöver därför e-legitimation på din dator (utfärdat av BankID) eller Mobilt BankID på din mobila enhet. Det innebär att ditt personnummer måste anges och att det därmed även sparas då du loggar in i tjänsten samt när du skriver under ansökan. Om du inte vill ange de uppgifter som krävs för att använda dig av tjänsten, kan du skicka in din ansökan i pappersform.
            </p>
            <ExternalLink url={'https://www.lantmateriet.se/e-legitimation'}
                          text={'Läs mer om att logga in med e-legitimation'}
                          openInNewTab={true}
                          isStandAloneLink={true}/>
        </SidePanelSection>
        <SidePanelSection id={'frontpage-help-digitalhandling'}
                          heading={'Digitala handlingar'}>
            <p>Exempel på handlingar som kan behövas är förvärvshandlingar, bygglov/förhandsbesked, överenskommelser/avtal,
                behörighets­handlingar,
                strandskyddsdispenser eller förvärvstillstånd.</p>
            <p><strong>Handlingar kan laddas upp i formaten JPG, GIF, PNG och PDF.</strong></p>
        </SidePanelSection>
    </>)
}