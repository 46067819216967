
import {
  // @ts-ignore
  ButtonSub, Column, FormFieldset, ListGroup, ListItem, ListItemActionButton,
  // @ts-ignore
  Row, TextInput, WizardIntorduction, WizardNavigationButtonToolbar
} from 'redet-react-components'
import { EpostInputFieldWithValidation } from '../../../../components/General/EpostInputFieldWithValidation'
import { namnValidation } from '../../../../utils/validation'
import { useState } from 'react'


type Medyrkande = {
  name: string,
  email: string
}
type Props = {
  setMedyrkande: (medyrkande: Medyrkande[]) => void,
  setNotifieringsemail: (email: string) => void,
  initNotifieringsemail?: string,
  initMedyrkandeList?: Medyrkande[],
  nextStep?: () => void
  previousStep?: () => void
  prevButtonText: string
}

export function YrkandeWizardStepMedyrkande(props: Props) {


  const [notifieringEmail, setNotifieringEmail] = useState(props.initNotifieringsemail ?? '')
  const [medyrkande, setMedyrkande] = useState(props.initMedyrkandeList ? props.initMedyrkandeList : [])
  const [inbjudanEmail, setInbjudanEmail] = useState('')
  const [inbjudanNamn, setInbjudanNamn] = useState('')
  const [nameValidationError, setNameValidationError] = useState('')
  const [notifieringEmailError, setNotifieringEmailError] = useState(false)
  const [medsokanEmailError, setMedsokanEmailError] = useState(false)


  const setNotifieringemailEmailUppdateringarValue = (email: string) => {
    setNotifieringEmail(email)
    props.setNotifieringsemail(email)
  }

  const deleteEmailAndNameFromList = (index: number) => {
    const updatedMedyrkanden = medyrkande.filter((value, i) => i !== index);
    setMedyrkande(updatedMedyrkanden);
    props.setMedyrkande(updatedMedyrkanden)
  }

  const addEmailAndNameToList = () => {
    const updatedMedyrkande  = [...medyrkande, {
      name: inbjudanNamn,
      email: inbjudanEmail
    }]
    setMedyrkande(updatedMedyrkande)
    props.setMedyrkande(updatedMedyrkande)
    setInbjudanEmail('');
    setInbjudanNamn('');
  }

  const disableAddSignatoryEmailButton = () => {
    return !!(medsokanEmailError || nameValidationError || !inbjudanEmail || !inbjudanNamn)
  }

  const validateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let isErrorMessage = namnValidation(event.target.value);
    if (isErrorMessage === 'Obligatoriskt fält' && medyrkande?.length > 0) {
      setInbjudanNamn(event.target.value);
      setNameValidationError("");
    } else {
      setInbjudanNamn(event.target.value);
      setNameValidationError(isErrorMessage ? isErrorMessage : "");
    }

  }

  const isFormValid = () => {
    return medyrkande?.length > 0 && notifieringEmail?.length > 2 && !notifieringEmailError
  }

  return (
    <>
      <WizardIntorduction title={'Medyrkare'}/>
      <Row>
        <Column columns={'col-lg-6'}>
          <FormFieldset title={'Uppdateringar och kvittens'}
                        description={'Ange din e-postadress för att ta emot uppdateringar om underskrifter, och kvittens vid inskickande.'}>
            <EpostInputFieldWithValidation inputFieldHasWarningValidation={false}
                                           id={'statusuppdateringEmailInput'}
                                           defaultEpost={notifieringEmail}
                                           isRequired={true}
                                           callbackValidEmail={setNotifieringemailEmailUppdateringarValue}
                                           disabledButton={setNotifieringEmailError}/>
          </FormFieldset>
        </Column>
        <Column columns="col-lg-6" id="laggTillMedyrkandeId">
          <FormFieldset title={'Lägg till medyrkare'}
                        description={'Ange namn och e-postadresser till de, förutom dig, som också ska skriva under yrkandet.'}>
            <TextInput id={'medyrkandeNameInput'}
                       type={'text'}
                       label={'Namn'}
                       isRequired={true}
                       value={inbjudanNamn}
                       validationErrorMsg={nameValidationError}
                       maxLength={80}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => validateName(event)}/>
            <EpostInputFieldWithValidation inputFieldHasWarningValidation={false}
                                           emailList={medyrkande}
                                           id={'medyrkandemailInput'}
                                           isRequired={true}
                                           value={inbjudanEmail}
                                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInbjudanEmail(event.target.value)}
                                           callbackValidEmail={setInbjudanEmail}
                                           disabledButton={setMedsokanEmailError}/>
            <br/>
            <ButtonSub text={'Lägg till medyrkare'}
                       icon={'add'}
                       id="addEmailToListButtonId"
                       isDisabled={disableAddSignatoryEmailButton()}
                       onClick={addEmailAndNameToList}/>

          </FormFieldset>
          {
            medyrkande?.length > 0 &&
            <ListGroup title={'Medyrkare'} headerLevel={3} id={'medyrkandelistaId'}>
              {medyrkande?.map((medyrkande, index) =>
                <ListItem header={medyrkande.name}
                          key={`medyrkareKey${index}`}
                          description={medyrkande.email}
                          id={`medyrkare${index}Id`}
                          actionButtons={[<ListItemActionButton ariaLabel={'Ta bort'} icon={'delete'}
                                                                key={`medyrkare${index}taBortKey`}
                                                                id={`medyrkare${index}taBortId`}
                                                                onClick={() => deleteEmailAndNameFromList(index)}/>]}/>
              )}

            </ListGroup>
          }
        </Column>
      </Row>
      <WizardNavigationButtonToolbar prevButtonText={props.prevButtonText}
                                     onClickPrevButton={props.previousStep}
                                     nextButtonText={'Underskrift'}
                                     isNextButtonDisabled={!isFormValid()}
                                     onClickNextButton={props.nextStep}/>
    </>
  );
}
