// @flow
// 1.7 En ansökan ska innehålla minst 1 sökande

import { BLOCKING, MINST_EN_SOKANDE } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  const contacts = state.contacts.contactList
  const sokande = contacts.filter(contact => contact.data.rollSokande)
  if (sokande.length < 1) {
    return new Validation(MINST_EN_SOKANDE, 'Det finns inte någon sökande till ärendet. Lägg till minst en person med rollen sökande.', 'contacts', BLOCKING, undefined)
  }
  return undefined
}

export default validate
