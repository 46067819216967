/* eslint-disable no-console */

export const logInfo = (message) => {
  fetch('/api/log', {
    method: 'POST',
    body: JSON.stringify({ logMessage: message }),
    headers: { 'Content-Type': 'application/json' }
  }).then(() => {
    console.log('Sent log message to server')
  }).catch(() => {
    console.log('Failed sending log message to server')
  })
}

export class Log {

  constructor (category = '') {
    if (process.env.NODE_ENV) {
      this.level = (process.env.LOG_LEVEL !== undefined ? process.env.LOG_LEVEL : 4) // TRACE=5, DEBUG=4, INFO=3, WARN=2, ERROR=1, NONE=0
    } else {
      this.level = (process.env.LOG_LEVEL !== undefined ? process.env.LOG_LEVEL : 0)
    }
    this.category = category

    if (this.level >= 5 && (typeof console !== 'undefined')) {
      if (console.trace !== undefined) {
        this.trace = console.trace.bind(console)
      } else {
        this.trace = console.log.bind(console)
      }
    } else {
      this.trace = () => {}
    }

    if (this.level >= 4 && (typeof console !== 'undefined')) {
      if (console.debug !== undefined) {
        this.debug = console.debug.bind(console)
      } else {
        this.debug = console.log.bind(console)
      }
    } else {
      this.debug = () => {}
    }

    if (this.level >= 3 && (typeof console !== 'undefined')) {
      if (console.info !== undefined) {
        this.info = console.info.bind(console)
      } else {
        this.info = console.log.bind(console)
      }
    } else {
      this.info = () => {}
    }

    if (this.level >= 2 && (typeof console !== 'undefined')) {
      if (console.warn !== undefined) {
        this.warn = console.warn.bind(console)
      } else {
        this.warn = console.log.bind(console)
      }
    } else {
      this.warn = () => {}
    }

    if (this.level >= 1 && (typeof console !== 'undefined')) {
      if (console.error !== undefined) {
        this.error = console.error.bind(console)
      } else {
        this.error = console.log.bind(console)
      }
    } else {
      this.error = () => {}
    }
  }
}

export default (Log)