import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addProgress, endProgress } from '../../../redux/modules/fullscreenLoading'

// Higher Order Component
// Tar en komponent och kastar en endProgress när den har monterats.

function showLoader (WrappedComponent) {

  class Wrapper extends Component {

    constructor (props, context) {
      super(props, context)
      this.props.addProgress(true)
    }

    componentDidMount () {
      setTimeout(() => {
        this.props.endProgress()
      }, 1500) // ms
    }

    render () {
      return <WrappedComponent {...this.props}/>
    }
  }

  const mapDispatchToProps = {
    endProgress,
    addProgress,
  }
  return connect(null, mapDispatchToProps)(Wrapper)
}

export default showLoader
