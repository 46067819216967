import type {ValidationSeverity} from '../constants'
import {Validation} from './Validation'

export type ServerValidationErrorSeverity = 'ERROR' | 'WARNING';

export class ServerValidationError {
    category: string
    id: string
    msg: string
    severity: ServerValidationErrorSeverity

    constructor(category: string = '', id: string = '', msg: string = '', severity: ServerValidationErrorSeverity = 'WARNING') {
        this.category = category
        this.id = id
        this.msg = msg
        this.severity = severity
    }

    toValidation(): Validation {
        let severity: ValidationSeverity
        switch (this.severity) {
            case 'ERROR':
                severity = 'error'
                break
            case 'WARNING':
                severity = 'warning'
                break
            default:
                severity = 'warning'
        }

        let category
        switch (this.category) {
            case 'KONTAKTER':
                category = 'contacts'
                break
            case 'ATGARD':
                category = 'atgard'
                break
            case 'HANDLINGAR':
                category = 'handlingar'
                break
            case 'KARTSKISS':
                category = 'kartskiss'
                break
            case 'FASTIGHET':
                category = 'fastighet'
                break
            default:
                category = 'unknown'
        }

        return new Validation(this.id, this.msg, category, severity, undefined)
    }
}
