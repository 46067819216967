import { useQuery } from "@tanstack/react-query"
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { getSDGSigningStatusFromAnsokanId } from "redux/modules/sdg";
import { SDGSigningStatus } from "repository/sgdRepository";

export function usePollSigningStatus(ansokanId: string): [Error | null, SDGSigningStatus | undefined, () => void, () => void] {
    
    const [signingStatusPollEnabled, setSigningStatusPollEnabled] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSigningStatusPollEnabled(!!ansokanId);
    }, [ansokanId])
    
    const { error, data } = useQuery<SDGSigningStatus, Error>({
    queryKey: ['sdgSigningStatus'],
    refetchInterval: 3000,
    enabled: signingStatusPollEnabled,
    retry: false,
    queryFn: () => dispatch(getSDGSigningStatusFromAnsokanId(ansokanId))
    })

    const disableSigningStatusPoll = () => {
        setSigningStatusPollEnabled(false);
    }

    const enableSigningStatusPoll = () => {
        setSigningStatusPollEnabled(true);
    }

    return [error, data, disableSigningStatusPoll, enableSigningStatusPoll];
}