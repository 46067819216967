// @ts-ignore
import {Checkbox, ModalDialogSub,
  VarningsRuta, Msg} from 'redet-react-components'
import {BLOCKING, WARNING} from '../../redux/modules/validation/rules/constants'
import {Validation} from '../../redux/modules/validation/rules/model/Validation'
import {RootState} from 'store'
import {useAppDispatch, useAppSelector} from 'hooks'
import React, {ReactNode, useState} from "react";
import {setUserHasConfirmedAmountOfSokandeIsNotCorrect} from "../../redux/modules/contacts";
import {useSelector} from "react-redux";
import Term from "../Hjalp/TermLank";
import {saveAnsokan} from "../../redux/modules/ansokanCRUD";


export enum ValidationTypes {
  CONTACTS = "contacts",
  ATGARD = "atgard",
  HANDLINGAR = "handlingar",
  KARTSKISS = "kartskiss",
  FASTIGHET = "fastighet",
  UNKNOWN = "unknown"
}

type Props = {
  ruleId?: string,
  tag: ValidationTypes,
  ref?: string,
  children?: any
  id?: string
}

export function ValidationError(props: Props) {
  const wrongAmountOfSokandeCheckbox = useSelector((store: RootState) => store?.contacts?.userHasConfirmedAmountOfSokandeIsNotCorrect)
  const dispatch = useAppDispatch();
  const stateErrors = useAppSelector((state: RootState) => state?.validation?.errors)
  const [askUser, setAskUser] = useState(false)
  const getErrorsByRuleId = () => {
    const ruleId = props.ruleId
    if (!ruleId || !stateErrors) {
      return []
    }
    return stateErrors.filter(error => error.id === ruleId)
  }

  const getErrorsByTag = () => {
    const tag = props.tag
    if (!tag || !stateErrors) {
      return []
    }
    return stateErrors?.filter(error => error.category === tag)
  }

  const getMatchingErrors = () => {
    let errors = [] as Validation[];
    errors = errors.concat(getErrorsByRuleId())
    errors = errors.concat(getErrorsByTag())
    return errors
  }

  const errors = getMatchingErrors()
  if (errors === null) {
    return null
  }
  function wrongAmountOfSokandeCheckboxClick (event: any) {
    if(wrongAmountOfSokandeCheckbox) {
      dispatch(setUserHasConfirmedAmountOfSokandeIsNotCorrect(false))
      dispatch(saveAnsokan())
    }
    else {
      setAskUser(true);
    }
  }

  function userModalChoice (choice: boolean) {
    setAskUser(false)
    if (choice) {
      dispatch(setUserHasConfirmedAmountOfSokandeIsNotCorrect(true))
    }
    dispatch(saveAnsokan())
  }

  const checkErrorMessage = (errors: Validation) => {
    return errors.id === "FASTIGHET_HAR_RATT_ANTAL_SOKANDE"
  }

  const childExists = (error: Validation) : ReactNode | boolean => {
    return checkErrorMessage(error) &&
        <Checkbox label={"Jag vill gå vidare trots att alla ägare inte är sökande"} name={"confirmAmountOfSokandeIsNotCorrect"}
                  id={"confirmAmountOfSokandeIsNotCorrectId"}
                  checked={wrongAmountOfSokandeCheckbox}
                  onChange={(event: any) => wrongAmountOfSokandeCheckboxClick(event.target.value)}/>
  }

  return (
      <div data-selenium-id={"Valideringsfel"}>
      {Array.isArray(errors) && errors.length > 0 &&
          <VarningsRuta errorMessages={errors
                  .filter(error => error.severity === BLOCKING)
                  .map((error, index) => {
                    return {msg: checkErrorMessage(error) ? error.message?.toString().split('\n').map((str, index) => <p key={index}>{str}</p>) : error.message, subMsg: error.subMessage, child: childExists(error)}
                  })}
              warningMessages={errors
                  .filter(error => error.severity === WARNING)
                  .map((error, index) => {
                    return {msg: checkErrorMessage(error) ? error.message?.toString().split('\n').map((str, index) => <p key={index}>{str}</p>) : error.message, subMsg: error.subMessage, child: childExists(error)}
                  })} ariaTitle={"Valideringsfel"}>
          {props.children}
        </VarningsRuta>
      }
      {askUser &&
          <IncorrectAmountOfSokandeModal closeModalCallback={() => userModalChoice(false)}
                                  onClickJaKnapp={() => userModalChoice(true)}/>}
      </div>
  )

}

export function IncorrectAmountOfSokandeModal (props: { closeModalCallback: () => void, onClickJaKnapp: () => void }) {
  return <ModalDialogSub modalTitle={'Är du helt säker på att du inte ska lägga till fler kontakter med rollen sökande?'}
                         ingress={'För att din ansökan ska vara så komplett som möjligt är det bra att se till att alla fastighetsägare är inlagda och har rollen sökande.'}
                         description={<>
                           Ifall du inte anger rätt antal sökande kommer du bli tvungen att komplettera din ansökan i ett
                           senare skede,
                           vilket medför längre handläggningstider med ev. ökad kostnad som följd. Är du osäker på
                           hur du ska gå vidare kan du kontakta <Term label={'kundcenter'} moment={'help-support'}/>.</>}
                         closeModalCallback={props.closeModalCallback}
                         acceptButton={{ text: 'Ja, jag är säker', onClick: props.onClickJaKnapp }}
                         declineButton={{ text: 'Nej' }}
                         id={'showWrongAmountOSokandeWarningModalId'}
                         elementsToAllowFocus={['helpSidePanelId', 'validationSidePanelId']}/>
}

export default ValidationError;
