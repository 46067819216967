//@ts-ignore
import { ExternalLink } from 'redet-react-components'

export function BrowserUpdate () {
  return (
    <div className="page-alert">
      <div className="container">
        <h2>Uppdatera din webbläsare</h2>
        <p>Det verkar som du använder en webbläsare som
          vi tyvärr inte kan garantera fullständig funktionalitet i.<br/><ExternalLink
            text={'Klicka här för att läsa mer om att uppdatera webbläsaren.'}
            url={'https://www.lantmateriet.se/sv/om-lantmateriet/om-webbplatsen/tekniska-fragor/#anchor-2'}/>
        </p></div>
    </div>
  )
}
