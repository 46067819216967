import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
//@ts-ignore
import {ButtonGroup, ButtonSub, ButtonToolbarV2, ModalDialogAlert} from "redet-react-components";
import BifogadeHandlingarKompletteringWizardUploadForm
    from "../../../../views/BifogadeHandlingar/BifogadeHandlingarKompletteringWizardUploadForm";
import AnsokanKompletteringWizardAddSignatoriesForm
    from "../../../../views/BifogadeHandlingar/AnsokanKompletteringWizardAddSignatoriesForm";
import YrkandeWizard from "../../../../pages/arende/aktiviteter/YrkandeWizard";
import SkrivelseRenderComponent from "../../../skrivelse/SkrivelseRenderComponent";
import {ARENDE_STATUS, NO_SCROLLING_CSS_CLASS, ROLL_SOKANDE} from "../../../../utils/globalConstants";
import {
    fetchKompletteringar
} from "../../../../redux/modules/arendeKompletteringar";
import {useAppDispatch} from "../../../../hooks";
import {logError} from "../../../../utils/errorHandler";
import {KompletteringAtgard} from "../../../../domain/Forratningsarende/KompletteringAtgard";
import {Term} from "../../../../views";
import {KOMPLETTERINGTYP} from "../../../../domain/Komplettering";
import InactiveKompletteringReasons from "./InactiveKompletteringReasons";
import KompletteringBifogaHandlingWizard from "../../atgarder/KompletteringBifogaHandlingWizard";
import {signeringLeifWebYtterligareHandlingToggle, useFeatureToggle} from "../../../../hooks/useFeatureToggle";
import {ArendeViewContext} from "../ArendeViewContext";

interface Props {
    arendenummer: string,
    arendestatus: ARENDE_STATUS
}

function KompletteraArendeView({arendenummer, arendestatus}: Props) {

    const {setShowCloseModalConfirmationDialog, setOnConfirmClose} = React.useContext(ArendeViewContext);

    const [showSkickaInSkrivelseDialog, setShowSkickaInSkrivelseDialog] = useState(false)
    const [showSkickaInYrkandeDialog, setShowSkickaInYrkandeDialog] = useState(false)
    const [showSkickaInYtterligareHandlingDialog, setShowSkickaInYtterligareHandlingDialog] = useState(false)
    const [showBjudInMedsokandeDialog, setShowBjudInMedsokandeDialog] = useState(false)

    const [activeKomplettering, setActiveKomplettering] = useState<KOMPLETTERINGTYP | undefined>(undefined)

    //åtgärdsflaggor
    const uploadHandlingKompletteringAtgard: KompletteringAtgard = useSelector((store: RootState) => store.arende?.uploadHandlingKompletteringAtgard)
    const yrkandeAtgard: KompletteringAtgard = useSelector((store: RootState) => store.arende?.yrkandeAtgard)
    const bitradandeKompletteringAtgard: KompletteringAtgard = useSelector((store: RootState) => store.arende?.bitradandeKompletteringAtgard)
    const skrivelseKompletteringAtgard: KompletteringAtgard = useSelector((store: RootState) => store.arende?.skrivelseKompletteringAtgard)

    const userRoles: string[] = useSelector((store: RootState) => store.arende?.arende?.fastighetsArendeOversikt?.roller)

    const isSokande = (): boolean => userRoles?.includes(ROLL_SOKANDE)

    //toggle - signering leif
    const isSigneringLeifWeb = useFeatureToggle(signeringLeifWebYtterligareHandlingToggle)

    const dispatch = useAppDispatch()

    useEffect(() => {
        //add och remove scrollingclass when dialog is visible
        if (showSkickaInSkrivelseDialog ||
            showSkickaInYrkandeDialog ||
            showSkickaInYtterligareHandlingDialog ||
            showBjudInMedsokandeDialog) {
            setOnConfirmClose(() => () => confirmCloseDialog(true))
            document.body.classList?.add(NO_SCROLLING_CSS_CLASS)
        } else {
            document.body.classList?.remove(NO_SCROLLING_CSS_CLASS)
        }
    }, [showSkickaInSkrivelseDialog,
        showSkickaInYrkandeDialog,
        showSkickaInYtterligareHandlingDialog,
        showBjudInMedsokandeDialog])

    const getValidAtgarder = (): KompletteringAtgard[] => {
        let arr: KompletteringAtgard[] = []
        arr.push(uploadHandlingKompletteringAtgard)
        if (isSokande())
            arr.push(bitradandeKompletteringAtgard)
        arr.push(skrivelseKompletteringAtgard)
        arr.push(yrkandeAtgard)
        return arr
    }

    function toggleYtterligareHandling(show:boolean, showConfirm:boolean) {
        if (show) {
            setShowSkickaInYtterligareHandlingDialog(true)
        } else {
            if (showConfirm) {
                setShowCloseModalConfirmationDialog(true)
            } else {
                setShowSkickaInYtterligareHandlingDialog(false)
                setActiveKomplettering(undefined)
            }
        }
    }

    function toggleBitradande(show:boolean, showConfirm:boolean) {
        if (show) {
            setShowBjudInMedsokandeDialog(true)
        } else {
            if (showConfirm) {
                setShowCloseModalConfirmationDialog(true)
            } else {
                setShowBjudInMedsokandeDialog(false)
                setActiveKomplettering(undefined)
            }
        }
    }

    function toggleYrkande(show:boolean, showConfirm:boolean) {
        if (show) {
            setShowSkickaInYrkandeDialog(true)
        } else {
            if (showConfirm) {
                setShowCloseModalConfirmationDialog(true)
            } else {
                setShowSkickaInYrkandeDialog(false)
                setActiveKomplettering(undefined)
            }
        }
    }

    function toggleSkrivelse(show:boolean, showConfirm:boolean) {
        if (show) {
            setShowSkickaInSkrivelseDialog(true)
        } else {
            if (showConfirm) {
                setShowCloseModalConfirmationDialog(true)
            } else {
                setShowSkickaInSkrivelseDialog(false)
                setActiveKomplettering(undefined)
            }
        }
    }

    function toggleKompletteringDialog(show: boolean, komletteringTyp: KOMPLETTERINGTYP, showConfirm: boolean = false) {
        if (showConfirm) {
            setShowCloseModalConfirmationDialog(true)
        }

        setActiveKomplettering(komletteringTyp)

        switch (komletteringTyp) {
            case KOMPLETTERINGTYP.YTTERLIGARE_HANDLING:
                toggleYtterligareHandling(show, showConfirm)
                break
            case KOMPLETTERINGTYP.BITRADANDE:
                toggleBitradande(show, showConfirm)
                break
            case KOMPLETTERINGTYP.YRKANDE:
                toggleYrkande(show, showConfirm)
                break
            case KOMPLETTERINGTYP.SKRIVELSE:
                toggleSkrivelse(show, showConfirm)
                break
            default:
        }
    }

    function confirmCloseDialog(doClose: boolean) {
        if (doClose && activeKomplettering) {
            //användaren vill stänga dialog trots ändringar
            toggleKompletteringDialog(false, activeKomplettering, false);
        }
        setShowCloseModalConfirmationDialog(false)
    }

    function fetchKompletteringarList(): void {
        dispatch(fetchKompletteringar(arendenummer))
            .catch((error: Error) => {
                logError(error, 'ArendeView-Component')
            })
    }

    return (<>
            {/*komplettering*/}
            <h3 id={"komplettera-arende"}>Komplettera ärende</h3>
            <p className={"introduction"}>
                Komplettering innebär att <Term
                label="tillföra handlingar eller utföra andra aktiviteter" moment="help-komplettering"/> i ditt ärende.
                Alla handlingar som skickas till en Lantmäterimyndighet blir allmänna handlingar, vilket normalt innebär att vem som helst har rätt att se dem. Har du
                skyddade personuppgifter eller anser att en uppgift omfattas av sekretess? Ring då vårt kundcenter innan du skickar in uppgiften eller handlingen.
            </p>
                <ButtonToolbarV2 isMultiLine={true} data-testid={'komplettera-atgarder-buttons'}>
                    <ButtonGroup>
                        <ButtonSub text={'Skicka in ytterligare handling'}
                                   id='uploadHandlingKompletteringAtgardButton'
                                   isDisabled={!uploadHandlingKompletteringAtgard?.active}
                                   onClick={() => toggleKompletteringDialog(true, KOMPLETTERINGTYP.YTTERLIGARE_HANDLING)}/>
                        <>
                            {isSokande() &&
                                <ButtonSub text={'Bjud in medsökande'}
                                           id='addSignatoriesKompletteringAtgardButton'
                                           isDisabled={!bitradandeKompletteringAtgard?.active}
                                           onClick={() => toggleKompletteringDialog(true, KOMPLETTERINGTYP.BITRADANDE)}/>
                            }

                            <ButtonSub text={'Skicka in meddelande'}
                                       id='createSkrivelseAtgardButton'
                                       isDisabled={!skrivelseKompletteringAtgard?.active}
                                       onClick={() => toggleKompletteringDialog(true, KOMPLETTERINGTYP.SKRIVELSE)}/>

                        </>
                        <ButtonSub text={'Skicka in yrkande'}
                                   id='yrkandeAtgardButton'
                                   isDisabled={!yrkandeAtgard?.active}
                                   onClick={() => toggleKompletteringDialog(true, KOMPLETTERINGTYP.YRKANDE)}/>

                    </ButtonGroup>
                </ButtonToolbarV2>
                <div data-testid={'komplettera-atgarder-messages'} data-selenium-id={'komplettera-atgarder-messages'}>
                    <InactiveKompletteringReasons kompletteringAtgarder={getValidAtgarder()} arendestatus={arendestatus} />
                </div>

        {/*åtgärder dialogs*/}
        {showSkickaInYtterligareHandlingDialog && <>
            {isSigneringLeifWeb ? <>
                {/*ny wizard (typescript-migrerad) för signering i leif*/}
                <KompletteringBifogaHandlingWizard arendenummer={arendenummer}
                                                   onCloseWizardDialog={(currentStep: number) => toggleKompletteringDialog(false, KOMPLETTERINGTYP.YTTERLIGARE_HANDLING, currentStep > 1)}/>
            </> : <>
                {/*wizard för signering i signering-web*/}
                <BifogadeHandlingarKompletteringWizardUploadForm
                    arendenummer={arendenummer}
                    closeWizardFunction={async (handlingarInskickat: boolean) => toggleKompletteringDialog(false, KOMPLETTERINGTYP.YTTERLIGARE_HANDLING, !handlingarInskickat)}
                    onUpload={() => {
                    }}
                />
            </>}
        </>}
        {isSokande() && showBjudInMedsokandeDialog &&
            <AnsokanKompletteringWizardAddSignatoriesForm
                arendenummer={arendenummer}
                closeWizardFunction={(bitradandeSkapat: boolean) => toggleKompletteringDialog(false, KOMPLETTERINGTYP.BITRADANDE, !bitradandeSkapat)}/>
        }
        {showSkickaInYrkandeDialog &&
            <YrkandeWizard id={'yrkandeAktivitetWizardId'}
                           arendenummer={arendenummer}
                           refetchKompletteringarList={async () => fetchKompletteringarList()}
                           closeWizardFunction={async (yrkandeInskickat: boolean) => toggleKompletteringDialog(false, KOMPLETTERINGTYP.YRKANDE, !yrkandeInskickat)}
            />
        }
        {showSkickaInSkrivelseDialog &&
            <SkrivelseRenderComponent arendenummer={arendenummer}
                                      refetchKompletteringarList={async () => fetchKompletteringarList()}
                                      closeWizardFunction={async (skrivelseInskickad: boolean) => toggleKompletteringDialog(false, KOMPLETTERINGTYP.SKRIVELSE, !skrivelseInskickad)}
            />
        }
    </>)
}

export default KompletteraArendeView