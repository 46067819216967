import React, {MouseEvent, useMemo, useState} from "react";
import {BifogaHandlingContext} from "../BifogaHandlingContext";
import {BIFOGADHANDLINGTYPE, BIFOGADHANDLINGTYPE_NOT_SELECTED, BifogadHandlingViewData} from "../../../../utils/globalConstants";
// @ts-ignore
import {ButtonGroup, ButtonToolbarV2, ButtonTop, FormFieldset, Select, TextArea, ValideringsFelmeddelandeRuta, VarningsRuta} from "redet-react-components";
import {Term} from "../../../../views";
import {BifogadHandlingType} from "../../../../domain/BifogadhandlingType";

type Props = {
    options: BifogadHandlingType[]
    showSelectedDescription?: boolean
    nextStep?: () => void
}

export function BifogaHandlingValjTypStep({options, showSelectedDescription = true, nextStep}: Props) {

    const [valjTypValidationMsg, setValjTypValidationMsg] = useState<string>()

    //context
    const {typ, setTyp, ovrigBeskrivning, setOvrigBeskrivning} = React.useContext(BifogaHandlingContext)
    const selectedOptionViewData = useMemo(() => BIFOGADHANDLINGTYPE.find(option => option.value === typ?.value), [typ])

    function onSelectChange(evt: React.ChangeEvent<HTMLSelectElement>) {
        const selected = options.find(opt => opt.value === evt.target.value)
        if (selected?.value !== 'ANNAT')
            setOvrigBeskrivning('')
        setTyp(selected)
        // if validation was done once, reset
        if(valjTypValidationMsg)
            validate(selected)
    }

    function onOvrigBeskrivningChange(evt: React.ChangeEvent<HTMLInputElement>) {
        //städa sträng (?)
        const regex = /[^[A-Za-zåÅäÄöÖ0-9 ;_\-=,:.!"@#$%&/()[\]{}?*'+]/g
        const input = evt.target.value
        setOvrigBeskrivning(input.replace(regex, ''))
    }

    const validate = (value?:BifogadHandlingType) => {
        const valid = !!value
        const message = 'Du måste välja en typ'
        setValjTypValidationMsg(!valid ? message : '')
        return valid
    }

    function onNextStepClick(evt: MouseEvent<HTMLInputElement>) {
        //if valid, go to next step!
        if (nextStep && validate(typ))
            nextStep()
    }

    const getMoreInfoNode = (data?: BifogadHandlingViewData) => {
        return <>
            {data?.mdRef && <VarningsRuta ariaTitle={"Information om handlingtyp"}>
                {data.mdRef}
            </VarningsRuta>}
        </>
    }

    return (<>
        <FormFieldset title={<>Välj den <Term label={'typ'}
                                              moment={'help-upload'}
                                              term={selectedOptionViewData?.helpTerm ?? 'help-upload'}/> som beskriver handlingen du vill bifoga.</>}>

            <Select id={'ytterligare-handing-typ'}
                    label={'Vilken typ av handling vill du bifoga?'}
                    value={typ?.value}
                    options={options.map(typ =>
                        ({key: typ.value, value: typ.value, description: typ.visningsnamn})
                    )}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => onSelectChange(evt)}
                    initalDescriptiveOption={{
                        key: 'not_selected',
                        value: BIFOGADHANDLINGTYPE_NOT_SELECTED,
                        description: BIFOGADHANDLINGTYPE_NOT_SELECTED
                    }}/>
            {/*valideringmeddelande*/}
            {valjTypValidationMsg && <ValideringsFelmeddelandeRuta text={valjTypValidationMsg} isBlock={true} ariaTitle={'Felmeddelande'}/>}
            {typ?.value === 'ANNAT' && <>
                <TextArea id="ovrig-annat-beskrivning-input"
                          characterLimit={500} maxLength={500} rows={3}
                          label={'Beskrivning (valfritt)'}
                          initialText={ovrigBeskrivning}
                          onChange={onOvrigBeskrivningChange}/>
            </>}
        </FormFieldset>
        {(typ?.value === 'ANNAT' || showSelectedDescription) && <>
            {getMoreInfoNode(selectedOptionViewData)}
        </>}
        {/*navigeringsknappar*/}
        <ButtonToolbarV2>
            <ButtonGroup />
            <ButtonGroup>
                <ButtonTop text={'Välj filer'}
                           onClick={onNextStepClick}
                           icon={'arrow_forward'}
                           id={'next-step-button'}
                           ariaLabel={'Gå vidare för att välja filer'}/>
            </ButtonGroup>
        </ButtonToolbarV2>
    </>)
}