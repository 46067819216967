import { Contact } from "./Contact";
import { Kartskiss } from "./Kartskiss";

export interface Ansokan {
    _id: string,
    _schema_version: number,
    _skapad: Date,
    _andrad: Date,
    _personId: string,
    _status: AnsokanStatus,
    _statusAndrad: Date,
    _signingInstanceId?: string,
    _userEmail?: string,
    _signingHandlingInstanceId?: string,
    version: number,
    data: AnsokanData
};


export type AnsokanData = {
    currentSchemaVersion: number,
    fastighetsforandring: Fastighetsforandring,
    klyvning: Klyvning
    ovrigBeskrivning: OvrigBeskrivning,
    avstyckning: Avstyckning,
    fastighetsreglering: Fastighetsreglering,
    sammanlaggning: Sammanlaggning,
    gransutmarkning: Gransutmarkning,
    fastighetsbestamning: Fastighetsbestamning,
    contactList: Contact[],
    map: Kartskiss,
    fastigheterList: Fastighet[],
    ickeAgdaFastigheter: Fastighet[],
    firstStepFinished: boolean,
    wizardState: string,
    hasUserChosenNotToUploadBifogadHandlig: boolean,
    userHasConfirmedAmountOfSokandeIsNotCorrect: boolean,
    numberOfBifogadeHandlingar: number,
    hasUserChosenNotToAddAkartskiss: boolean,
    numberOfRitadeKartor: number,
    numberOfUppladdadeKartor: number
}

export type Fastighetsforandring = {
    avstyckning: boolean;
    fastighetsreglering: boolean;
    sammanlaggning: boolean;
    klyvning: boolean;
    gransutmarkning: boolean;
    fastighetsbestamning: boolean;
}

export type Klyvning = {
    beskrivning: string;
    andamal: string;
    bildaServitut: boolean;
    bildaServitutBeskrivning: string;
    fordelaSFochGA: boolean;
    fordelaSFochGABeskrivning: string;
    tillhorandeByggnader: string;
}

export type OvrigBeskrivning = {
    ovrigt: string
}

export type Avstyckning = {
    helarsbostad: boolean;
    fritidsbostad: boolean;
    mindreOdling: boolean;
    jordSkogsbruk: boolean;
    industriverksamhet: boolean;
    tredimensionellFastighet: boolean;
    annat: boolean;
    annatBeskrivning: string;
    ingenByggnad: boolean;
    befintlig: boolean;
    ny: boolean;
    inteckningsfri: boolean;
    bildaServitut: boolean;
    bildaServitutBeskrivning: string;
    overforFastighetstillbehor: boolean;
    overforFastighetstillbehorBeskrivning: string;
    berorsRattighetsinnehavare: boolean;
    berorsRattighetsinnehavareBeskrivning: string;
    fleraAvstyckningarFortydligande: string;
}

export type Fastighetsreglering = {
    avtalsservitutFortsatterGalla: string;
    markoverforingBeskrivningTillFran: string;
    markoverforingBeskrivning: string;
    berorsRattighetsinnehavare: boolean;
    berorsRattighetsinnehavareBeskrivning: string;
    bildaServitut: boolean;
    bildaServitutBeskrivning: string;
    overforFastighetstillbehor: boolean;
    overforFastighetstillbehorBeskrivning: string;
    overforIngaAndelarIsamfalligheter: boolean;
    bifogadForvarvshandling: boolean;
    overforAndelarSamfalligheter: boolean;
    overforAndelarSamfalligheterBeskrivning: string;
}

export type Sammanlaggning = {
    helarsbostad: boolean;
    fritidsbostad: boolean;
    mindreOdling: boolean;
    industriverksamhet: boolean;
    skogsbruk: boolean;
    annat: boolean;
    annatBeskrivning: string;
}

export type Gransutmarkning = {
    gransBeskrivning: string
}

export type Fastighetsbestamning = {
    fastighetersGranser: boolean;
    fastighetersGranserBeskrivning: string;
    rattigheterSkyldigheter: boolean;
    rattigheterSkyldigheterBeskrivning: string;
}

export enum AnsokanStatus {
	UTKAST = "UTKAST",
    LASTFORSIGNERING = "LASTFORSIGNERING",
    GRANSKA_EPOST = "GRANSKA_EPOST",
    SIGNERAS = "SIGNERAS",
    SIGNERAD = "SIGNERAD",
    INSKICKAD = "INSKICKAD",
    OKAND = "OKAND"
}

export type Fastighet = {
    registerbeteckning: string,
    data: FastighetData,
    objektidentitet: string,
    enhetsomrade: []
}

export type FastighetData = {
    objektidentitet: string,
    registerbeteckning: Registerbeteckning_Fastighetinfo,
    enhetsomrade: [],
}

export type Registerbeteckning_Fastighetinfo = {
    tidigareRegisterbeteckningar?: TidigareRegisterbeteckning_Fastighetinfo[],
    text: string,
    shortText?: string,
    registeromrade: string,
    traktnamn: string,
    block: string,
    enhet: number
}

export type Registerbeteckning_Regbet = {
    tidigareRegisterbeteckning?: TidigareRegisterbeteckning_Regbet,
    text: string,
    shortText?: string,
    registeromrade: string,
    trakt: string,
    block: string,
    objektidentitet: string,
    enhet: number
}

export type TidigareRegisterbeteckning_Fastighetinfo = {
    registeromrade: string,
    traktnamn: string,
    block?: string,
    enhet: number,
    aktbeteckning: string
}

export type TidigareRegisterbeteckning_Regbet = {
    beteckningsid: string,
    beteckningsstatus: string,
    registeromrade: string,
    trakt: string,
    block?: string,
    enhet: number,
    aktbeteckning: string,
    tidigareRegisterbeteckning?: TidigareRegisterbeteckning_Regbet
    markering: boolean
}


