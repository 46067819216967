import { Dispatch } from "redux"
import { skickaBetyg } from "repository/betygRepository"
import { Fetcher } from "repository/fetcher"
import { RootState } from "store"

export function sattBetyg (grade: number, feedbackText?: string) {
    return function action (dispatch: Dispatch<any>, getState: () => RootState) {
      const fetcher = new Fetcher(dispatch, getState, false)
      skickaBetyg (fetcher, grade, feedbackText)
    }
  }
