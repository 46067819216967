import {useState} from "react";
import {
    // @ts-ignore
    WizardDialog, WizardDialogStep,
} from "redet-react-components";
import {useSelector} from "react-redux";
import {submitSkrivelseToMyndighet} from "./skrivelseService";
import {SkrivelseKvittensResponse, SkrivelseRender} from "./model/skrivelse";
import {JsonMappingFailure} from "./skrivelseRepository";
import KvittensEpostStep from "./modalSteps/kvittensEpostStep";
import ForhandsgranskaStep from "./modalSteps/forhandsgranskaStep";
import { RootState } from "store";
import { useAppDispatch } from "hooks";
import InskickadStep from "./modalSteps/inskickadStep";
import UpprattaSkrivelseStep from "./modalSteps/upprattaSkrivelseStep";

type Props = {
    arendenummer: string
    refetchKompletteringarList: () => void
    closeWizardFunction: (skrivelseInskickad: boolean) => void
    initialStep?: number
    initialError?: string
}

export function SkrivelseRenderComponent({arendenummer, refetchKompletteringarList, closeWizardFunction, initialStep = 1, initialError = ''}: Props) {
    const user = useSelector((store: RootState) => store.auth.user)
    const dispatch = useAppDispatch();

    const [skrivelseInskickad, setSkrivelseInskickad] = useState<boolean>(false);
    const [meddelande, setMeddelande] = useState<string>('');
    const [epostKontakt, setEpostKontakt] = useState<string>('');
    const [telefonKontakt, setTelefonKontakt] = useState<string>('');
    const [epostKvittens, setEpostKvittens] = useState<string>('');
    const [kvittens, setKvittens] = useState<string>('');
    // Initial state är satt till false eftersom då kontrollerade checkboxar inte får gå från undefined -> true https://reactjs.org/link/controlled-components
    const [useSameEpost, setUseSameEpost] = useState<boolean>(false)
    // Initial state är satt till false eftersom då kontrollerade checkboxar inte får gå från undefined -> true https://reactjs.org/link/controlled-components
    const [godkantVillkor, setGodkantVillkor] = useState<boolean>(false)
    const [error, setError] = useState<string>(initialError);
    const [kvittensEmailSuccessfullySent, setKvittensEmailSuccessfullySent] = useState(true);
    const [kompletteringsTyp] = useState<string>("Meddelandet");

    function submitSkrivelse() {
        if(!user?.fullName){
            throw new Error("Namn saknas på användare");
        }
        const skrivelse : SkrivelseRender =
            {
                avsandare: user.fullName,
                arendenummer: arendenummer,
                meddelande: meddelande,
                epostKvittens: epostKvittens,
                epostKontakt: epostKontakt ? epostKontakt : undefined,
                telefon: telefonKontakt ? telefonKontakt : undefined,
            }
        return submitSkrivelseToMyndighet(skrivelse, dispatch).then((response: SkrivelseKvittensResponse) => {
            setError('');
            setKvittens(response.inskickadDatum)
            if(!response.kvittensEmailSuccessfullySent) {
                setKvittensEmailSuccessfullySent(false);
            }
            setSkrivelseInskickad(true)
        }).catch((err: any) => {
            if (err instanceof JsonMappingFailure) {
                setTimeout(() => {
                setError('Ett fel uppstod när en kvittens för den inskickade skrivelsen skulle skapas.' +
                    ' Skrivelsen är inskickad till Lantmäterimyndigheten och ett kvittensmail har skickats till angiven epostadress. Du kan stänga dialogen.')}, 2000);
            } else {
                setTimeout(() => {
                setError('Ett fel uppstod vid inskick av meddelande, vänligen navigera tillbaka och kontrollera uppgifterna i ditt meddelande och försök igen. ' +
                'Du kan även välja att stänga dialogen, men då försvinner ditt upprättade meddelande och du behöver upprätta det igen.')}, 2000);
            }
        });
    }

    return (
        <>
            {user?.isOrganization === false && (user?.fullName !== undefined || user?.fullName !== '') &&
                <WizardDialog title={"Skicka in meddelande"}
                              hasDefaultNav={false}
                              cancelFunction={() => closeWizardFunction(skrivelseInskickad)}
                              elementsToAllowFocus={['helpSidePanelId']}
                              initialStep={initialStep}>
                    <WizardDialogStep stepTitle={"Meddelande"}>
                        <UpprattaSkrivelseStep {...{meddelande, setMeddelande, epostKontakt, setEpostKontakt, telefonKontakt, setTelefonKontakt}}/>
                    </WizardDialogStep>
                    <WizardDialogStep stepTitle={"Kvittens"}>
                        <KvittensEpostStep {...{epostKvittens, setEpostKvittens, epostKontakt, useSameEpost, setUseSameEpost}}/>
                    </WizardDialogStep>
                    <WizardDialogStep stepTitle={"Förhandsgranskning"}>
                        <ForhandsgranskaStep {...{user, arendenummer, meddelande, epostKontakt, telefonKontakt, epostKvittens, godkantVillkor, setGodkantVillkor, submitSkrivelse}}/>
                    </WizardDialogStep>
                    <WizardDialogStep stepTitle={error ? 'Tekniskt fel' : 'Inskickat'}>
                        <InskickadStep closeWizardFunction={() => closeWizardFunction(skrivelseInskickad)} {...{kvittens, epostKvittens, refetchKompletteringarList, kvittensEmailSuccessfullySent, error, setError, kompletteringsTyp}}/>
                    </WizardDialogStep>
                </WizardDialog>
            }
        </>
    )
}
export default SkrivelseRenderComponent;