import { Response, Server } from 'miragejs'

export let ansokanRoutes = function (server: Server) {
  const authHeader = { 'svarstjanst.passedauthentication': 'true' }

  /*
  const errorMessage = JSON.parse(response._bodyText).message;
          if (errorMessage === "Endast ansökningar i status UTKAST får förändras") {
   */

  server.put('/api/ansokan-service/ansokan/ansokanSaveForbidden403', (schema, request) => {
    return new Response(403, authHeader, { message: 'Endast ansökningar i status UTKAST får förändras' })
  })
}
