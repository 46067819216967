import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const mapStateToProps = (store) => {
  return {
    toggles: store.featureToggle,
  }
}

class FeatureToggle extends Component {

  static propTypes = {
    featureId: PropTypes.string.isRequired,
    toggles: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
  }

  constructor (props) {
    super(props)
    this.isActive = this.isActive.bind(this)
  }

  isActive () {
    const active = this.props.toggles && this.props.toggles[this.props.featureId]
    return active === true
  }

  render () {
    if (!this.isActive()) {
      return null
    }

    return (<div>{this.props.children}</div>)
  }
}

export default connect(
  mapStateToProps,
  null)(FeatureToggle)

