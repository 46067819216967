import {
  ACTION_FETCH_KARTSKISSER_FAILED,
  ACTION_FETCH_KARTSKISSER_SUCCESS,
  ACTION_REMOVE_KARTSKISS_FAILED,
  ACTION_REMOVE_KARTSKISS_PENDING,
  ACTION_REMOVE_KARTSKISS_SUCCESS,
  ACTION_UPLOAD_KARTSKISS_FAILED,
  ACTION_UPLOAD_KARTSKISS_PENDING,
  ACTION_UPLOAD_KARTSKISS_SUCCESS,
  getKartskisserList
} from 'redux/modules/kartskiss'
import { addProgress, endProgress } from 'redux/modules/fullscreenLoading'

function displayLoadingAnimationIfAJAXPending (type, dispatch) {
  if (
    type === ACTION_UPLOAD_KARTSKISS_PENDING ||
    type === ACTION_REMOVE_KARTSKISS_PENDING
  ) {
    dispatch(addProgress(false))
  }
}

function hideLoadingAnimationIfAJAXDone (type, dispatch) {
  if (
    type === ACTION_FETCH_KARTSKISSER_SUCCESS ||
    type === ACTION_UPLOAD_KARTSKISS_SUCCESS ||
    type === ACTION_REMOVE_KARTSKISS_SUCCESS ||
    type === ACTION_FETCH_KARTSKISSER_FAILED ||
    type === ACTION_UPLOAD_KARTSKISS_FAILED ||
    type === ACTION_REMOVE_KARTSKISS_FAILED
  ) {
    dispatch(endProgress())
  }
}

function reloadListIfCUDActionWasSuccessful (type, ansokanId, dispatch) {
  if (
    type === ACTION_UPLOAD_KARTSKISS_SUCCESS ||
    type === ACTION_REMOVE_KARTSKISS_SUCCESS
  ) {
    dispatch(getKartskisserList(ansokanId))
  }
}

// redux-middleware for kartskisser
// Makes sure that the list of kartskisser is always updated after create, update and delete operations
// And that loading animations are displayed for AJAX operations
const kartskissMiddleware = ({ getState, dispatch }) => next => action => {
  // execute the action first
  const result = next(action)

  reloadListIfCUDActionWasSuccessful(action.type, getState().ansokanRepository.remoteDataKey, dispatch)
  displayLoadingAnimationIfAJAXPending(action.type, dispatch)
  hideLoadingAnimationIfAJAXDone(action.type, dispatch)

  return result
}

export default kartskissMiddleware
