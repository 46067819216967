import Log from 'utils/log'
import { getMeasureStyles } from '../styles'
import { v4 as uuidv4 } from 'uuid'
import { formatArea } from '../measurement.js'
import { unByKey } from 'ol/Observable'
import { Draw } from 'ol/interaction'

export default class MeasurePolygonInteraction {
  constructor (mapController, measureTooltip, setCursorFunc, ol, interactionDestructors, registerCurrentMultipointInteraction, unregisterCurrentMultipointInteractionFunc, featureHandler) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.measureTooltip = measureTooltip
    this.setCursorFunc = setCursorFunc
    this.ol = ol
    this.interactionDestructors = interactionDestructors
    this.registerCurrentMultipointInteraction = registerCurrentMultipointInteraction
    this.unregisterCurrentMultipointInteractionFunc = unregisterCurrentMultipointInteractionFunc
    this.featureHandler = featureHandler
  }

  init () {
    const interaction = new Draw({
      features: this.featureHandler.getDrawingFeatures(),
      type: 'Polygon',
      style: (feature) => getMeasureStyles(feature, this.mapController.getMap()),
    })

    const drawStart = interaction.on('drawstart',
      (evt) => {
        this.registerCurrentMultipointInteraction(interaction, evt.feature)
        evt.feature.set('isMeasurePolygon', true)
        evt.feature.getGeometry().on('change', (event) => {
          const geom = event.target
          if (geom.getCoordinates()[0].length > 2) {
            const output = formatArea(geom, this.mapController.getMap().getView().getProjection(), true)
            this.measureTooltip.changeOffset(0, 10)
            this.measureTooltip.updateContentAndLocation(output, geom.getInteriorPoint().getCoordinates())
          } else {
            this.measureTooltip.reset()
          }
        }, this)
      })

    const draw = this.mapController.getMap().on('singleclick', () => {
    })

    const drawEnd = interaction.on('drawend', (event) => {
      event.feature.setId(uuidv4())
      // Save last tooltip and re-create it once we're done drawing
      this.measureTooltip.saveAtOffset(0, 10)
      this.unregisterCurrentMultipointInteractionFunc()
    }, this)

    this.interactionDestructors.push(() => {
      this.log.debug('Unregister MEASURE_POLYGON interaction')
      unByKey(drawStart)
      unByKey(draw)
      unByKey(drawEnd)
      this.mapController.getMap().removeInteraction(interaction)
      this.setCursorFunc('auto')
    })

    this.log.debug('Register MEASURE_POLYGON interaction')
    this.mapController.getMap().addInteraction(interaction)

    this.setCursorFunc('crosshair')
  }
}
