import React, {useEffect, useState} from 'react'
import AnsokanForm from "./AnsokanForm.tsx";
import {fetchAnsokanStatus} from 'redux/modules/ansokanSPECIAL'
import {AnsokanNotFoundException} from 'repository/ansokanRepository'
import {
    ANSOKAN_STATUS_DRAFT,
    ANSOKAN_STATUS_LOCKED,
    ANSOKAN_STATUS_REVIEW_EMAILS,
    ANSOKAN_STATUS_SIGNED,
    ANSOKAN_STATUS_SIGNING,
    webblasarvarning
} from 'utils/globalConstants'
import {destroyAnsokanForm} from 'redux/modules/sharedActions'
import {useNavigate, useParams} from 'react-router-dom'
import {ExternalLink, ModalDialogAlert, ModalDialogTop} from 'redet-react-components'
import {ANSOKAN_STATUS_SUBMITTED} from '../utils/globalConstants'
import {logError} from '../utils/errorHandler'
import {Signing} from "./Signing";
import {useAppDispatch, useAppSelector} from "../hooks";

export function Ansokan() {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const ansokanStatus = useAppSelector((store) => store.ansokanRepository.ansokanStatus)
    const [ansokanId] = useState(params.id)
    const [showAnsokanNotFoundModal, setShowAnsokanNotFoundModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)


  useEffect(() => {
      dispatch(fetchAnsokanStatus(ansokanId))
        .catch(error => {
          if (error instanceof AnsokanNotFoundException) {
            setShowAnsokanNotFoundModal(true)
          } else {
            error.component = 'Ansokan.js'
            logError(error, 'Ansokan-component')
            setShowErrorModal( true )
          }
        })
    return () => dispatch(destroyAnsokanForm())
  }, []);

  function hideErrorModalAndReturnWelcomPage () {
    setShowErrorModal(false)
    navigate("/")
  }

    const showSigningSection = ansokanStatus === ANSOKAN_STATUS_LOCKED ||
      ansokanStatus === ANSOKAN_STATUS_REVIEW_EMAILS ||
      ansokanStatus === ANSOKAN_STATUS_SIGNING ||
      ansokanStatus === ANSOKAN_STATUS_SIGNED
    const showAnsokanSection = ansokanStatus === ANSOKAN_STATUS_DRAFT
    const showAnsokanInskickadModal = ansokanStatus === ANSOKAN_STATUS_SUBMITTED
    return (<>
      {showAnsokanSection &&
        <>
          <IE11modalDialogWarning/>
          <AnsokanForm ansokanId={ansokanId} match={navigate}/>
        </>
      }
      {showSigningSection &&
        <Signing ansokanId={ansokanId}/>
      }
      {showAnsokanNotFoundModal &&
        <AnsokanNotFoundModal closeModalCallback={() => navigate('/')}/>}
      {showAnsokanInskickadModal &&
        <AnsokanInskickadModal closeModalCallback={() => navigate('/')}/>}
      {showErrorModal &&
        <ModalDialogAlert
          modalTitle={'Tekniskt fel'}
          ingress={'Ett tekniskt fel uppstod när en ansökan skulle hämtas.'}
          description={<span>Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
            href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>}
          acceptButton={{ text: 'Ok', icon: 'check', onClick: hideErrorModalAndReturnWelcomPage }}
        />
      }
    </>)
}

export function IE11modalDialogWarning () {

  const isIE11 = /*@cc_on!@*/false || !!document.documentMode
  const [state, setState] = useState({ visible: true })

  return (isIE11 && state.visible) ?
    <ModalDialogTop
      closeModalCallback={() => setState(false)}
      modalTitle={'Uppdatera din webbläsare'}
      ingress={'Din webbläsare är tyvärr för gammal och bör uppdateras innan du fortsätter använda tjänsten.' +
        ' Tjänstens funktionalitet kan inte garanteras i kombination med din nuvarande webbläsare.'}
      description={webblasarvarning}
      isBig={true}
    /> : null
}

export function AnsokanInskickadModal (props) {
  return <ModalDialogAlert modalTitle={'Ansökan är redan inskickad'}
                         description={<>Ansökan kommer att diarieföras och få ett ärendenummer inom några dagar. En
                           bekräftelse med information om
                           ditt ärende och ärendenummer skickas därefter till dig per post. När du fått ditt
                           ärendenummer kan du följa ärendet
                           här: <a href="https://enak.lantmateriet.se">www.enak.lantmateriet.se</a>.<br/>Har
                           du frågor är
                           du välkommen att kontakta
                           Kundcenter <ExternalLink text={'www.lantmateriet.se/kontakt'}
                                                    url={'https://lantmateriet.se/kontakt'}/>.</>}
                         closeModalCallback={props.closeModalCallback}/>
}

export function AnsokanNotFoundModal (props) {
  return <ModalDialogAlert modalTitle={'Ansökan kunde inte öppnas'}
                         description={'Antingen så är ansökan redan inskickad, det finns det ingen ansökan med detta id eller så är det inte du som som skapat ' +
                           'ansökan. Endast den användare som skapat ansökan kan öppna den.'}
                         closeModalCallback={props.closeModalCallback}/>
}



export default Ansokan
