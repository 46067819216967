import PropTypes from "prop-types";
import {getLocalizedDateString} from "helpers/dates";
import {Arende, Arendetyp} from "domain/Arende";
import {Link} from "react-router-dom";
import {ArendeMedSvarslistor} from "domain/ArendeMedSvarslistor";
// @ts-ignore
import {ButtonToolbar, withPopover} from "redet-react-components";
import {IconLinkRouter} from "components/General/forms/routerLinks";
import {ARENDE_STATUS, ARENDE_STATUS_TYPES} from "utils/globalConstants";

type Props = {
    url: string
    arende: ArendeMedSvarslistor
}

export function ArendeListItem(props: Props) {

    const getStatusText = (arende: Arende) => {

        if(isSERSAM(arende)){
            return convertToSERSAMStatus(arende.status)
        }

        switch (arende.status) {
            case ARENDE_STATUS.GRANSKNING:
                return "inledande granskning"
            case ARENDE_STATUS.UTREDNING_PROVNING:
                return "utredning och prövning"
            case ARENDE_STATUS.BESLUT_TAGET:
                return "beslut har tagits"
            case ARENDE_STATUS.REGISTRERING:
                return "registrering pågår"
            case ARENDE_STATUS.SLUTFORT:
                return "ärendet är slutfört"
            default:
                return "felaktig status, kontakta kundtjänst"
        }
    };

    const isSERSAM = (arende: Arende) => arende.arendetyp === Arendetyp.SERSAM

    function convertToSERSAMStatus(status: ARENDE_STATUS){
        if (status === ARENDE_STATUS.SLUTFORT) {
            return "slutfört"
        }
        return "pågående"
    }

    const arendeUrl = `./arende/${props.arende.nummer}`;
    const IconLinkRouterWithPopover = withPopover(IconLinkRouter);
    const hasDelgivning = props.arende.svarslistor !== undefined;

    return (
        <div className="passthrough-row">
            <ButtonToolbar>
                <IconLinkRouterWithPopover
                    popoverText={"Visa det här ärendet"}
                    link={arendeUrl}
                    icon={"zoom_in"}
                    ariaLabel="Visa"
                    state={{svarslistor: props.arende.svarslistor}}
                    ariaLabelledBy={`${props.arende.nummer + "EditIcon"} ${props.arende.nummer
                        }`}
                    id={props.arende.nummer + "EditIcon"}
                />
            </ButtonToolbar>
            <section className="content">
                <h4 data-test-id="arendelist.item.no">
                    <Link
                        to={arendeUrl}
                        state={{svarslistor: props.arende.svarslistor}}
                        id={props.arende.nummer}
                    >
                        Ärende {props.arende.nummer}
                    </Link>
                    {hasDelgivning && (
                        <span className="assistance-group">
                            Klicka här för att bekräfta din delgivning
                        </span>
                    )}
                </h4>
                <p className="additional_information">
                    {props.arende.beskrivning.substr(0, 250)},{" "}
                    {getStatusText(props.arende)}, ankomstdatum{" "}
                    {getLocalizedDateString(props.arende.startDate, "d MMMM y")}
                </p>
                    <p>
                {props.arende.arendetyp === "F" && "Lantmäteriförättning"}{props.arende.arendetyp === "SERSAM" && "Bildande av samfällighetsförening"}{props.arende.mottagandeMyndighet &&
                <>,&nbsp;
                        {props.arende.mottagandeMyndighet}
                </>}
                    </p>
                <p>
                    {hasDelgivning && (
                        <>
                            <span
                                id={"delgivning-" + props.arende.nummer}
                                className={"description mandatory"}
                            >
                                <i className="material-icons">{"error_outline"}</i> Du behöver
                                bekräfta att du har blivit delgiven
                            </span>
                        </>
                    )}
                </p>
            </section>
        </div>
    );
}

ArendeListItem.propTypes = {
    arende: PropTypes.object.isRequired,
};

export default ArendeListItem;
