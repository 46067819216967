//@ts-ignore
import { SidePanelChapterHeading, SidePanelSection, VarningsRuta } from 'redet-react-components'
import { Term } from '../../../index'
import {
  optOutFromRepresentingFastighetLabel
} from '../../../../components/contactsRelated/Common/ContactFastighetSelection'

export const ContactsHelpContent = <>
  <SidePanelChapterHeading header={'Kontaktuppgifter till dig'}
                           sectionTitle={'Kontaktuppgifter'}
                           introduction={'För att vi inte ska behöva belasta ditt ärende med kostnad för att leta efter kontaktuppgifter\n' +
                             '        till dig och andra berörda är det viktigt att du fyller i kontaktuppgifterna så fullständigt som möjligt.\n' +
                             '        Tänk t.ex. på\n' +
                             '        att lämna e-post och telefonnummer där du enklast nås. I samband med en förrättning skickar\n' +
                             '        lantmäterimyndigheten ut\n' +
                             '        bekräftelse, kallelser till sammanträden, meddelande om beslut m.m. och behöver ofta ta kontakt med berörda\n' +
                             '        parter per\n' +
                             '        telefon och e-post.'}/>
  <SidePanelSection heading={'Kontaktuppgifter till andra berörda'}
                    id={'term-annanberord'}
                    text={'Fyll i de uppgifter du känner till eller enkelt kan ta reda på.'}/>

  <SidePanelSection heading={'Rollen upprättare'}
                    id={'term-rollUpprattare'}
                    text={'Upprättare kallas den person som är inloggad och har skapat ansökan om lantmäteriförrättning. Upprättaren läggs automatiskt till i listan av\n' +
                      '            kontakter vid skapandet av en ansökan och kan inte tas bort, men den kommer inte med i den slutliga ansökan såvida den\n' +
                      '            inte också innehar en annan roll utöver upprättare.'}/>

  <SidePanelSection heading={'Rollen sökande'}
                    id={'term-rollSokande'}
                    text={'Sökande kallas den eller de personer som ansöker om en lantmäteriförrättning. Sökande måste vara ägare till den fastighet som berörs, det\n' +
                      '            vill säga den som har lagfart eller är köpare med köpeavtal för hel eller del av fastighet. Samtliga fastighetsägare som ska skriva under\n' +
                      '            ansökan anges som sökande. En rättighetshavare, samfällighetsförening, kommun m.fl. kan också vara sökande i vissa fall.'}/>

  <SidePanelSection heading={'Rollen företrädare'}
                    id={'term-rollOmbud'}
                    text={'Företrädare kallas den person som företräder en eller flera andra som är sökande. ' +
                        'Det är personen med rollen företrädare som skriver under ansökan för de personer eller organisationer som företräds. ' +
                        'De som företräds av en annan skriver inte under ansökan själv och behöver alltså inte ha svenskt personnummer eller e-legitimation.'}/>

  <SidePanelSection heading={'Vem bör ha rollen sökande'}
                    id={'term-vemRollSokande'}>
    <p>
      I normalfallet bör <strong>samtliga ägare</strong> till berörda fastigheter ha rollen sökande. Enklast lägger du
      till aktuella ägare
      för valda fastigheter genom att välja <strong>hämta ägare</strong> under rubriken <strong>kontaktuppgifter och
      kostnader </strong>
      och låt rollen sökande vara ifylld. De ägare som hämtas in i tjänsten är de som är registrerade ägare på
      fastigheten (har lagfart).
      <br/>
      <br/>
      Om en berörd fastighet har nya ägare enligt köp eller annat förvärv och inte har lagfart läggs ny ägare till
      manuellt genom
      att klicka på<strong> part i förrättningen saknas</strong> och sedan<strong> lägg till privatperson </strong>
      eller <strong>lägg till organisation</strong>.
      Fyll i rollen sökande för respektive ägare. Kom ihåg att bifoga förvärvshandling!
      <br/>
      <br/>
      <strong>Undantag</strong> från när samtliga fastighetsägare måste skriva under ansökan kan vara:
      <br/>
      <br/>
      Då en företrädare t.ex. ett ombud har fullmakt att ansöka istället för fastighetsägare.
      Företrädaren lägges då till som kontaktuppgift med rollen företrädare samt bifogar fullmakt (behörighetshandling).
      <br/>
      <br/>
      Vid klyvning, då det räcker att en av delägarna i aktuell fastighet ansöker för att Lantmäteriet ska pröva
      ärendet. Är delägarna
      däremot överens om åtgärden ska alla delägare ha rollen sökande och skriva under ansökan för att undvika onödig
      tidsåtgång och
      kostnad för komplettering.
      <br/>
      <br/>
      Fastighetsreglering för t.ex. marköverföring eller bildande av servitut (rättighet) kan också prövas av
      Lantmäteriet trots att ägare
      till samtliga berörda fastigheter inte är överens om åtgärden. Det räcker då med att samtliga ägare till den
      fastighet som vill få
      ärendet prövat tvångsvis har rollen sökande och skriver under.
    </p>
  </SidePanelSection>

  <SidePanelSection heading={'Rollen annan berörd'}
                    id={'term-rollAnnanBerord'}
                    text={`Annan berörd är övriga personer som berörs av förrättningen men som inte är sökande. Det vanligaste är att detta är ägaren/ägarna av en 
                    fastighet som berörs av lantmäteriförrättningen men som inte vill vara med och ansöka, exempelvis om ett servitut ska bildas över en annan fastighets 
                    mark. Det kan även vara då exempelvis en Överenskommelse om Fastighetsreglering eller annan fångeshandling visar på att en person har rätt att 
                    ensam söka förrättning trots att flera fastigheter ingår i ärendet.`}
  />

  <SidePanelSection heading={'Rollen betalare'}
                    id={'term-rollFakturamottagare'}
                    text={'Den som ska betala kostnaderna för lantmäteriförrättningen. Det kan vara en eller flera betalare. Om kostnaderna ska fördelas på fler\n' +
                      '            personer eller fördelas mellan fler fastigheter så anger du hur fördelningen ska ske. Om till exempel Anders Andersson som äger By 1:1 ska\n' +
                      '            betala halva kostnaden och Per Persson som äger By 1:2 ska betala halva kostnaden så anges bägge personer som betalare och vid\n' +
                      '            "Kostnadsfördelning" så anges 1/2 vid andel av förrättningskostnaden. Om du ansöker om fler åtgärder, t ex. avstyckning och\n' +
                      '            fastighetsreglering och du vill att kostnaderna för avstyckningen betalas av den ena fastighetens ägare och kostnaderna för regleringen\n' +
                      '            betalas av den andra fastighetens ägare så kan du även ange den typen av fördelning. T ex kostnaderna för avstyckningen ska betalas av\n' +
                      '            ägarna till By 1:1 och kostnaderna för fastighetsregleringen ska betalas av ägarna till By 1:2.'}
  />

  <SidePanelSection heading={'Rollen aktmottagare'}
                    id={'term-rollAktmottagare'}
                    text={'Efter avslutad lantmäteriförrättning sätts handlingarna i ärendet ihop till en akt. Denna akt finns arkiverad och tillgänglig digitalt\n' +
                      '            efter att ärendet avslutats. En kopia av akten skickas dock ut till angiven aktmottagare. Ska akten skickas ut till fler än en person så\n' +
                      '            kan Lantmäterimyndigheten ta extra betalt för detta.'}
  />

  <SidePanelSection heading={'Kostnadsfördelning'}
                    id={'term-kostnadsfordelning'}
                    text={'Är det flera personer inblandade i en förrättning är det vanligt att dessa är överens om vem eller vilka som ska betala förrättningen. Är\n' +
                      '            ni överens ska du ange hur ni vill att fördelningen mellan er ska ske. I vissa sällsynta situationer kan Lantmäterimyndigheten besluta om\n' +
                      '            en annan fördelning än den ni har önskat här. Det kan t.ex. hända om er överenskommelser strider mot en bestämmelse i\n' +
                      '            Fastighetsbildningslagen. Om förrättningen ställs in eller om din ansökan återkallas är huvudregeln att det är sökande som betalar\n' +
                      '            förrättningskostnaderna. Du kan även ange om du anser att någon annan borde betala förrättningskostnaden. T ex i en förrättning där inte\n' +
                      '            bara du har nytta av slutresultatet utan även en angränsande fastighetsägare. Lantmäterimyndigheten fattar då beslut i förrättningen om\n' +
                      '            förrättningskostnadernas fördelning.\n' +
                      '            Uppges ingen betalare och/eller fördelning av förrättningskostnaden beslutar Lantmäterimyndigheten vem som ska betala efter vad som är\n' +
                      '            skäligt.'}
  />

  <SidePanelSection heading={'Alternativ adress'}
                    id={'term-alternativAdress'}
                    text={'Här kan du ange en alternativ adress där vi kan nå dig. Ofta behöver vi delge information till dig som är berörd av en förrättning såsom\n' +
                      '            kallelser till lantmäterisammanträden m.m. Kan du nås på någon annan adress än din hemadress så kan du ange det här. Det kan t.ex. vara\n' +
                      '            adressen till din arbetsplats eller säsongsboende.'}
  />

  <SidePanelSection heading={'Separat faktureringsadress'}
                    id={'term-separatFaktureringsadress'}>
    <p>Här kan du ange om faktura för förrättningskostnader ska skickas till någon annan adress än din postadress samt om fakturan ska ha någon särskild märkning.
      <br/><strong>Observera</strong>: Om ett företag/organisation ska betala förrättningen behöver de läggas in som person i ansökan och <b>markeras som Betalare</b>, även om det rör sig om dotterbolag eller liknande (med separata organisationsnummer) till redan inlagda företag. Det räcker alltså inte att lägga in det företagets adress i detta fält. </p>
  </SidePanelSection>

  <SidePanelSection heading={'ID-nummer'}
                    id={'term-idnummer'}
                    text={'ID-nummer är ett samlingsbegrepp för personnummer, samordningsnummer och övrigt ID-nummer.'}>
  </SidePanelSection>

  <SidePanelSection heading={'Personnummer'}
                    id={'term-personnummer'}
                    text={'Personnummer är en identitetsbeteckning som alla personer som folkbokförs i Sverige får av Skatteverket.'}>
  </SidePanelSection>

  <SidePanelSection heading={'Samordningsnummer'}
                    id={'term-samordningsnummer'}
                    text={'Samordningsnummer är en identitetsbeteckning på en person som inte är eller aldrig har varit folkbokförd i Sverige. Precis som med ett\n' +
                      '           personnummer så är ett samordningsnummer unikt och alltid kopplat till samma person.'}>
  </SidePanelSection>

  <SidePanelSection heading={'Övrigt ID-nummer'}
                    id={'term-ovrigtIdnummer'}
                    text={'Övrigt ID-nummer kan t ex vara ett utländskt ID-nummer eller annan personlig identitetsbeteckning som inte är svenskt personnummer eller samordningsnummer.'}>
  </SidePanelSection>

  <SidePanelSection heading={'Fastighetsregistret'}
                    id={'term-fastighetsregistret'}
                    text={'Fastighetsregistret är Sveriges officiella register över hur marken i vårt land är indelad och över vem som äger vad.\n' +
                      'På kontaktuppgiften ska du ange vilka fastigheter en person eller organisation finns registrerad som ägare på just nu.\n' +
                      'Om en fastighet nyligen bytt ägare är det inte säkert att ändringen införts i fastighetsregistret ännu. Du ska i så fall fylla i det nu gällande ägarförhållandet.'}
  />

  <SidePanelSection heading={'Företräda fastigheter'}
                    id={'term-foretradaFastighet'}>
    <p>
      De berörda fastigheter den sökande äger och företräder i denna ansökan ska vara ibockade. Detta är en hjälp för
      att avgöra vilka andra personer som också måste vara sökande.
      I normalfallet ska samtliga ägare till en fastighet ansöka tillsammans, ett undantag från detta är om ansökan
      innehåller åtgärden klyvning.
      <br/>
      <br/>
      Kan inte alla sökande skriva under själva kan en annan sökande agera <Term label="företrädare"
                                                                                 moment="help-contactinformation"
                                                                                 term="term-rollOmbud"/>
    </p>
    <h5>Hämtad ägare</h5>
    <p>När en ägare hämtas får den rollen sökande och det är automatiskt ibockat att personen ska företräda samtliga
      berörda fastigheter som denne äger.</p>

    <h5>Skapad kontaktuppgift</h5>
    <p>När en kontaktuppgift med rollen sökande skapas manuellt måste de berörda fastigheter denne äger och
      företräder bockas i manuellt, äger personen ingen fastighet ska "{optOutFromRepresentingFastighetLabel}"
      bockas i.
    </p>
    <VarningsRuta
      intro={'Det är möjligt för personer som inte äger någon av de berörda fastigheterna att vara sökande vid t.ex. avstyckning'} ariaTitle={'Viktig information'}/>

    <h5>När ska en sökande inte företräda samtliga fastigheter denne äger i ärendet</h5>
    <p>Det finns situationer då en sökande inte ska företräda alla berörda fastigheter som denne äger i ansökan.</p>

    <h5>Sökande äger flera berörda fastigheter med olika ägarförhållanden</h5>
    <p>De fastigheter där samtliga delägare inte ska vara sökande ska bockas ur.
    </p>
    <VarningsRuta
      intro={'Exempel: Om en person äger Fastighet 1:1 tillsammans med person X och Fastighet 2:2 tillsammans med person Y ska fastighet 2:2 bockas ur om inte Y ska vara sökande.'} ariaTitle={'Viktig information'}/>

    <h5>Sökande äger en eller flera fastigheter och ska även bli ägare av nybildad fastighet som styckas av med andra
      ägarförhållanden.</h5>
    <p>Om samtliga delägare till de befintliga fastigheterna inte ska vara sökande och den sökande enbart söker i
      egenskap av blivande ägare till en nybildad fastighet ska "{optOutFromRepresentingFastighetLabel}" bockas i.
    </p>
    <VarningsRuta
      intro={`Exempel: Om en person äger 1:1 tillsammans med med person X och ska äga en styckningslott ensam ska "${optOutFromRepresentingFastighetLabel}" bockas i om X inte ska vara sökande.`} ariaTitle={'Viktig information'}/>

  </SidePanelSection>

  <br/>
</>
