import {useCallback, useState} from "react"
import {Komplettering, KOMPLETTERINGSTATUS} from "../../../../domain/Komplettering"
import {SigningStatus} from "../../../../domain/signing/SigningStatus"
import {useAppDispatch} from "../../../../hooks"
import {fetchSigningStatusKomplettering} from "../../../../redux/modules/arendeKomplettering"
import {usePolling} from "../../../../hooks/usePolling";

/**
 * Hook för att polla efter statusförändringar i given komplettering
 * skiljer sig från useKompletteringSigningStatusPoll, undviker omrendering
 * @param kompletteringId
 * @param config
 */
export function useKompletteringStatusPoll(kompletteringId: string, config?: { interval?: number, enabled?: boolean }) {
    const _config = {interval: 2000, enabled: false, ...config}

    const [polling, setPolling] = useState<boolean>(_config.enabled)

    //det är endast statusändringar vi är intresserade av
    const [currentKompletteringStatus, setCurrentKompletteringStatus] = useState<KOMPLETTERINGSTATUS>()
    const [currentSigningStatus, setCurrentSigningStatus] = useState<SigningStatus>()
    //...och ev fel
    const [errorMessage, setErrorMessage] = useState<Error>()

    const dispatch = useAppDispatch()

    usePolling(() => kompletteringStatusFetch(), polling ? _config.interval : null)

    async function kompletteringStatusFetch() {
        let serverError: Error | undefined
        const refetchKompletteringStatus = (): Promise<Komplettering | undefined> => {
            return new Promise(resolve =>
                dispatch(fetchSigningStatusKomplettering(kompletteringId))
                    .then((result: Komplettering) => {
                        resolve(result)
                    })
                    .catch((err: Error) => {
                        serverError = err
                        resolve(undefined)
                    }))
        }
        const result = await refetchKompletteringStatus()
        if (result && !serverError) {
            // triggar ev gui ändring endast vid status förändringar
            setCurrentKompletteringStatus(result.status)
            // specialare för signeringsstatus listan,
            // kontrollera förändringar för att undvika rerender
            const signingStatus = result.signingStatus
            if (signingStatus) {
                setCurrentSigningStatus(prevState => {
                    const bothIsUnset = (!signingStatus.signerStatus && !prevState?.signerStatus)
                    const isEqual = bothIsUnset || (
                        signingStatus.status === prevState?.status &&
                        signingStatus.signerStatus !== null && prevState?.signerStatus !== null &&
                        signingStatus.signerStatus !== undefined && prevState?.signerStatus !== undefined &&
                        signingStatus.signerStatus.length === prevState?.signerStatus.length &&
                        signingStatus.signerStatus.every(post => prevState?.signerStatus.some(pre => post.status === pre.status && post.signerEmail === pre.signerEmail && post.signingUrl === pre.signingUrl))
                    )
                    return isEqual ? prevState : signingStatus
                })
            }
        }
        setErrorMessage(serverError)
    }

    const enable = useCallback((value: boolean) => {
        setPolling(value)
    }, []);

    return {
        kompletteringStatus: currentKompletteringStatus,
        signingStatus: currentSigningStatus,
        startPolling: () =>  enable(true),
        stopPolling: () => enable(false),
        error: errorMessage
    }
}