import React from 'react'
import { Term } from 'views'
import AddressForm from '../Common/AddressForm'
import { CheckboxFinalForm, Condition, Subscribe, TextInputFinalForm } from '../../General/forms/finalForm'
//@ts-ignore
import { FormFieldset } from 'redet-react-components'

export default function IndividualAddressForm (props:{ formAutocomplete: string }) {
  return (
    <>
      <FormFieldset title={'Adressuppgifter'}>
        <Subscribe fieldName={'rollFakturamottagare'}>
          {(props: any) => {
            const fakturaMottagareChecked = props.input.value
            return (
              <>
                {/* @ts-ignore */}
                <AddressForm
                  fields={{ adress: 'adress', postnummer: 'postnummer', postort: 'postort', land: 'land' }}
                  formAutocomplete={props.formAutocomplete}
                  formAutocompletePrefix={'section-hem '}
                  fieldsAreNotRequired={!fakturaMottagareChecked}/>
                {/* @ts-ignore */}
                <CheckboxFinalForm field={'alternativAdressAngiven'} label={'Ange en alternativ adress'}
                                   term={<Term label="alternativa adresser" moment="help-contactinformation"
                                               term="term-alternativAdress"/>}
                                   data-selenium-id={'alternativAdressAngiven'} id={'alternativAdressAngiven'}/>
              </>
            )
          }}
        </Subscribe>
      </FormFieldset>
      {/* @ts-ignore */}
      <Condition when={'alternativAdressAngiven'} is={true}>
        <FormFieldset title="Alternativ adress (t.ex. arbete, sommarstuga eller liknande)">
          <Subscribe fieldName={'alternativAdressAngiven'}>
            {(props:any) => {
              const alternativAdressAngiven = props.input.value
              return (
                <>
                {/* @ts-ignore */}
                  <AddressForm fields={{
                    adress: 'adressAlternativ',
                    postnummer: 'postnummerAlternativ',
                    postort: 'postortAlternativ',
                    land: 'landAlternativ'
                  }}
                               fieldsAreNotRequired={!alternativAdressAngiven}
                               formAutocomplete={props.formAutocomplete}
                               formAutocompletePrefix={'section-alternativ '}/>
                  {/* @ts-ignore */}
                  <TextInputFinalForm field={'kontaktKommentar'} label={'Kommentar'} maxLength={100}/>
                </>
              )
            }}
          </Subscribe>
        </FormFieldset>
      </Condition>
    </>
  )
}
