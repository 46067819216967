//@ts-ignore
import { Column } from 'redet-react-components'
import { TextInputFinalForm } from '../../General/forms/finalForm'

export function NameForm (props:{ formAutocomplete: string }) {
  const columnSetting = 'col-xs-12 col-md-8'
  return (
    <>
      <Column columns={columnSetting}>
        {/* @ts-ignore */}
        <TextInputFinalForm field={'namn'} label={'Namn'} maxLength={160} required={true}
                            autoComplete={props.formAutocomplete ? 'full-name' : undefined}/>
      </Column>
    
    </>
  )
}
