//@ts-ignore
import { JumpLink } from 'redet-react-components'

export function JumpLinkToMain () {
  const mainTag = document.getElementsByTagName('main')
  const mainTagId = mainTag.length > 0 ? mainTag[0].id : ''

  return (
      <div role={"region"}><JumpLink text={'Hoppa till huvudinnehållet'} anchor={mainTagId}/></div>
  )
}