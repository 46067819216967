import Log from '../utils/log'

const log = new Log('fastighetsinnehavRepository')
const genericError = 'Kunde inte ladda fastighetsinnehav'

export function loadList (fetcher, state) {
  const personnummer = state.auth.user.personnummer
  const url = '/api/fastighetsagare/' + personnummer + '/fastigheter'
  return fetcher.fetching(url,
    {
      cache: 'no-store',
      method: 'GET',
    }).then(result => {
    return result.json()
  }).catch(() => {
    log.error(genericError)
    // Custom handling of error message
    throw genericError
  })
}