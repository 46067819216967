import PropTypes from "prop-types";

type Props = {
    descending: boolean,
    onDescendingChange: (desc: boolean) => void,
    descendingLabel?: string,
    ascendingLabel?: string
}

export function SortButton({descending = true, onDescendingChange, descendingLabel = "Fallande", ascendingLabel = "Stigande"}: Props) {
    return (<>
        <div className="single-sort-options dropdown menu-sub">
            <button className="button-sub button-dropdown-trigger"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                <span className="material-icons">sort</span>
                Sortering
            </button>
            <ul className="dropdown-menu">
                <li className={!descending ? "active" : "in-active"}>
                    <button aria-current={!descending ? "true" : "false"} className="button-link button-ascending" onClick={() => onDescendingChange(false)}>
                        {!descending &&
                            <span className="material-icons">check</span>
                        }
                        {ascendingLabel}</button>
                </li>
                <li className={descending ? "active" : "in-active"}>
                    <button aria-current={descending ? "true" : "false"} className="button-link button-descending" onClick={() => onDescendingChange(true)}>
                        {descending &&
                            <span className="material-icons">check</span>
                        }
                        {descendingLabel}
                    </button>
                </li>
            </ul>
        </div>
    </>)
}

SortButton.propTypes = {
    descending: PropTypes.bool,
    onDescendingChange: PropTypes.func,
    descendingLabel: PropTypes.string,
    ascendingLabel: PropTypes.string
};