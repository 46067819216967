import {ARENDE_STATUS} from "utils/globalConstants";

export type Arende = {
    nummer: string;
    beskrivning: string;
    kommuner: any;
    status: ARENDE_STATUS;
    roller: string[];
    startDate: string;
    endDate?: string;
    handlaggareNamn: string;
    lan?: string;
    handlaggareEpost?: string;
    handlaggareTelefon?: string;
    mottagandeMyndighet: string;
    aktbeteckning?:string|null;
    tillatXmlInskick:boolean
    arendetyp: Arendetyp
};

export enum Arendetyp {
    F = 'F',
    SERSAM = 'SERSAM'
}