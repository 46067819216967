import { useEffect, useState } from 'react'
import BifogadeHandlingarList from './BifogadeHandlingarList'
import BifogadeHandlingarWizardUploadForm from './BifogadeHandlingarWizardUploadForm'
import { MAX_NUMBER_OF_BIFOGAD_HANDLING_IN_ANSOKAN } from 'utils/globalConstants'
import { PageWizardNavigationToolbar } from '../../components/General/PageWizardNavigationToolbar'
import { visaMoment } from '../../redux/modules/help'
import { scrollToMainTag } from '../../utils/scrollToElement'
//@ts-ignore
import {ButtonToolbar, ButtonTop, Checkbox, ModalDialogSub, PageContentHeader, VarningsRuta} from 'redet-react-components'
import Term from '../Hjalp/TermLank'
import { NO_SCROLLING_CSS_CLASS } from '../../utils/globalConstants'
// eslint-disable-next-line import/no-deprecated
import ValidationError, { ValidationTypes } from '../validation/ValidationError'
import { useAppDispatch } from 'hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  displayUploadForm,
  getBifogadHandlingList,
  getBifogadHandlingQuotaForAnsokan,
  removeBifogadHandling
} from 'redux/modules/bifogadHandling'
import { setUserChooseNotToUploadBifogadHandlig } from '../../redux/modules/bifogadHandling'
import { Link } from 'react-router-dom'
import {useFeatureToggle} from "../../hooks/useFeatureToggle";

type Props = {
  ansokanId: string,
}

function BifogadeHandlingar(props: Props) {

  const bifogadeHandlingarExists = useSelector((store: RootState) => store.bifogadHandling.list.length > 0)
  const bifogadHandlingList = useSelector((store: RootState) => store.bifogadHandling.list)
  const isUploadFormVisible = useSelector((store: RootState) => store.bifogadHandling.isUploadFormVisible)
  const numberOfBifogadeHandlingar = useSelector((store: RootState) => store.bifogadHandling.list.length)
  const hasUserChosenNotToUploadBifogadHandlig = useSelector((store: RootState) => store.bifogadHandling.hasUserChosenNotToUploadBifogadHandlig)
  const ombudExists = useSelector((store: RootState) => store.contacts.contactList.filter((contact: any) => contact.data.rollOmbud).length > 0)

  const dispatch = useAppDispatch();

  const [showNoBifogadHandligWarning, setShowNoBifogadHandligWarning] = useState(false)
  const [showDeleteHandling, setShowDeleteHandling] = useState(false)
  const [handlingIdToBeDeleted, setHandlingIdToBeDeleted] = useState<string>()
  const [showHandlingarMaxSizeExceeded, setShowHandlingarMaxSizeExceeded] = useState(false)
  const [showMaxNoHandlingarExceeded, setShowMaxNoHandlingarExceeded] = useState(false)
  const [totaltSizeOfBifogadeHandlingar, setTotaltSizeOfBifogadeHandlingar] = useState(undefined)

  useEffect(() => {
    dispatch(visaMoment('help-upload', 'Handlingar'))
    dispatch(getBifogadHandlingList(props.ansokanId))
    scrollToMainTag()
  }, [])

  function createNewBifogadHandling () {

    document.body.classList.add(NO_SCROLLING_CSS_CLASS)

    if (numberOfBifogadeHandlingar >= MAX_NUMBER_OF_BIFOGAD_HANDLING_IN_ANSOKAN) {
      showMaxNoHandlingarExceededModal()
      return
    }

    dispatch(getBifogadHandlingQuotaForAnsokan(props.ansokanId))
      .then((quota: any) => {
        if (quota.remainingSize <= 0) {
          showHandlingarMaxSizeExceededModal(true, quota.remainingSize)
        }
        dispatch(displayUploadForm())
      })
  }

  const removeHandling = () => {
    if(handlingIdToBeDeleted) {
      dispatch(removeBifogadHandling(props.ansokanId, handlingIdToBeDeleted))
          .then(() => {
            dispatch(getBifogadHandlingList(props.ansokanId))
          })
    }
  }

  const showDeleteHandlingModal = (show = true, handlingid?: string) => {
    setShowDeleteHandling(show)
    setHandlingIdToBeDeleted(handlingid)
  }

  const showHandlingarMaxSizeExceededModal = (show = true, size = undefined) => {
    setShowHandlingarMaxSizeExceeded(show)
    setTotaltSizeOfBifogadeHandlingar(size)
  }

  const showMaxNoHandlingarExceededModal = (show = true) => {
    setShowMaxNoHandlingarExceeded(show)
  }

  function onClickNoBifogadHandling () {
    if (hasUserChosenNotToUploadBifogadHandlig)
      dispatch(setUserChooseNotToUploadBifogadHandlig(false))
    else {
      setShowNoBifogadHandligWarning(true)
    }
  }

  function noBifogadHandligWarningModalChoice (choice: boolean) {
    setShowNoBifogadHandligWarning(false)
    if (choice)
     dispatch(setUserChooseNotToUploadBifogadHandlig(true))
  }

    return (
      <>
        <div id="section-bifogadehandlingar">
          <PageContentHeader title={'Bifogade handlingar'} headerLevel={2} headerId={'bifogadeHandlingarHeaderId'}>
            <p>Observera att kartskisser inte ska läggas till här, utan under <PageWizardNavigationLink
              kartskissText={'Kartskisser'}
              kartskissLink={'../kartskiss'}/>
              <br/><br/>
              För att din ansökan ska vara så komplett som möjlig kan du behöva bifoga handlingar som är av betydelse
              för ditt ärende.
              Exempel på handlingar är förhandsbesked och köpeavtal.
              <br/><br/>
              Vill du bifoga flera handlingar får du lägga till flera gånger.

            </p>
            <ButtonToolbar>
              <ButtonTop id="createNewBifogadHandlingButton"
                         text={'Lägg till handling'}
                         icon={'add'}
                         onClick={createNewBifogadHandling}
                         isDisabled={hasUserChosenNotToUploadBifogadHandlig && !ombudExists}/>
            </ButtonToolbar>
            <Checkbox label={'Jag behöver inte bifoga någon handling'}
                      id={'noBifogadHandligCheckButtonId'}
                      onChange={onClickNoBifogadHandling}
                      checked={hasUserChosenNotToUploadBifogadHandlig && !ombudExists}
                      isDisabled={bifogadeHandlingarExists || ombudExists}/>
            <p>Läs mer om <Term label={'bifogade handlingar'} moment={'help-upload'}/></p>
          </PageContentHeader>
          {bifogadHandlingList.length > 0 &&
            <BifogadeHandlingarList ansokanId={props.ansokanId}
                                    deleteHandlingCallback={(id: string) => showDeleteHandlingModal(true, id)}/>
          }
          <>
            <br/>
            <ValidationError tag={ValidationTypes.HANDLINGAR}/>
            {!bifogadeHandlingarExists ?? <VarningsRuta
              children={<ValidationError tag={ValidationTypes.HANDLINGAR}/>}
              description={<> Ifall du inte bifogar någon handling kan du bli tvungen att komplettera din
                ansökan i ett senare skede,
                vilket kan medföra längre handläggningstider med ev. ökad kostnad som följd.
                <br/>
                Är du osäker på vad du ska bifoga,
                se vårt hjälpavsnitt om <Term label={'bifogade handlingar'} moment={'help-upload'}/>.</>}
             ariaTitle={"Varning"}
            />
            }
          </>
          <PageWizardNavigationToolbar buttonLeftText={'Lägg till kontakter'} buttonLeftLink={'../kontakter'}
                                        buttonRightText={'Sammanfattning'} buttonRightLink={'../sammanfattning'}/>
        </div>
        {isUploadFormVisible &&
          <BifogadeHandlingarWizardUploadForm ansokanId={props.ansokanId}
                                              onUploadCallback={() => dispatch(getBifogadHandlingList(props.ansokanId))}/>
        }
        {showNoBifogadHandligWarning &&
          <NoHandlingWarningModal closeModalCallback={() => noBifogadHandligWarningModalChoice(false)}
                                  onClickJaKnapp={() => noBifogadHandligWarningModalChoice(true)}/>}
        {showDeleteHandling &&
          <DeleteHandlingModal
            closeModalCallback={() => showDeleteHandlingModal(false)}
            onClickJaKnapp={() => removeHandling()}/>}
        {showHandlingarMaxSizeExceeded &&
          <HandlingarMaxSizeExceededModal totaltSizeOfBifogadeHandlingar={totaltSizeOfBifogadeHandlingar}
                                          closeModalCallback={() => showHandlingarMaxSizeExceededModal(false)}/>

        }
        {showMaxNoHandlingarExceeded &&
          <MaxNoHandlingarExceededModal closeModalCallback={() => showMaxNoHandlingarExceededModal(false)}/>}
      </>
    )
}


export function MaxNoHandlingarExceededModal (props: {closeModalCallback: () => void}) {
  return <ModalDialogSub modalTitle={'Du får inte lägga till fler handlingar'}
                         description={`En ansökan får maximalt innehålla ${MAX_NUMBER_OF_BIFOGAD_HANDLING_IN_ANSOKAN} bifogade handlingar`}
                         closeModalCallback={props.closeModalCallback}/>
}

export function HandlingarMaxSizeExceededModal (props: {closeModalCallback: () => void, totaltSizeOfBifogadeHandlingar: any}) {
  return <ModalDialogSub modalTitle={'Du får inte lägga till fler handlingar'}
                         description={`Du har överskridit gränsen för den sammanlagda storleken av bifogade handlingar i en ansökan 
                                som är ${props.totaltSizeOfBifogadeHandlingar / 1024 / 1024}MB`}
                         closeModalCallback={props.closeModalCallback}/>
}

export function NoHandlingWarningModal (props: { closeModalCallback: () => void, onClickJaKnapp: () => void }) {
  return <ModalDialogSub modalTitle={'Är du helt säker på att du inte ska bifoga någon handling?'}
                         ingress={'För att din ansökan ska vara så komplett som möjlig kan du behöva bifoga handlingar som är av ' +
                           'betydelse för ditt ärende. Exempel på handlingar är förhandsbesked och köpeavtal.'}
                         description={<>
                           Ifall du inte bifogar någon handling kan du bli tvungen att komplettera din ansökan i ett
                           senare skede,
                           vilket kan medföra längre handläggningstider med ev. ökad kostnad som följd. Är du osäker på
                           vad du ska
                           bifoga, se vårt hjälpavsnitt om <Term label={'bifogade handlingar'}
                                                                 moment={'help-upload'}/> och om du
                           fortfarande
                           är osäker kan du kontakta <Term label={'kundcenter'} moment={'help-support'}/>.</>}
                         closeModalCallback={props.closeModalCallback}
                         acceptButton={{ text: 'Ja, jag är säker', onClick: props.onClickJaKnapp }}
                         declineButton={{ text: 'Nej' }}
                         id={'showNoBifogadHandligWarningModalId'}
                         elementsToAllowFocus={['helpSidePanelId', 'validationSidePanelId']}/>
}

export function DeleteHandlingModal (props: { closeModalCallback: () => void, onClickJaKnapp: () => void }) {
  return <ModalDialogSub modalTitle={'Ta bort bifogad handling'}
                         description={'Vill du ta bort den här bifogade handlingen?'}
                         closeModalCallback={props.closeModalCallback}
                         acceptButton={{ text: 'Ja', onClick: props.onClickJaKnapp }}
                         declineButton={{ text: 'Nej'}}/>
}

export function PageWizardNavigationLink (props: {kartskissText: string, kartskissLink: string}) {
  return <>
    {props.kartskissText && <Link to={props.kartskissLink}>{props.kartskissText}</Link>}
  </>
}

export default (BifogadeHandlingar)
