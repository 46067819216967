export function scrollToMainTag (delayBeforeScrollingMillis = 300) {
    // Experimental sedan cirkus 5 oktober 2015 (scrollIntoView)
    try {
        if (window.innerWidth > 960 && window.innerHeight > 800) {
            // Om vi är på en skärm med stor bredd och höjd (webbläsare på laptop med bra upplösning t ex) scrollar vi till rubriken
            setTimeout(() => window.document.getElementsByTagName('main')[0]?.scrollIntoView({ behavior: 'smooth' }), delayBeforeScrollingMillis)
        } else {
            // Om vi är på en skärm med liten bredd eller höjd (webbläsare på mobil i stående eller liggnade läge eller på laptop med låg upplösning t ex) scrollar vi till fiskarna i wizarden
            setTimeout(() => window.document.getElementById('wizardForumlarDelarId')?.scrollIntoView({ behavior: 'smooth' }), delayBeforeScrollingMillis)
        }
    } catch (error) {
        // Do nothing eg. no scrolling if element does not exist
    }
}
