import React, {useEffect, useState} from "react";
import {scrollToMainTag} from "../../../utils/scrollToElement";
import {formProperties} from "../../../redux/formProperties";
import {Form} from "react-final-form";
import FormStateToRedux from "../../../redux/FormStateToRedux";
import {TextAreaFinalForm} from "../../General/forms/finalForm";
import {PageWizardNavigationToolbar} from "../../General/PageWizardNavigationToolbar";
import {initFormData} from "../../../redux/finalForm";
import {visaMoment as visaHjalpAvsnitt} from "../../../redux/modules/help";
// @ts-ignore
import {Column, FormFieldset, PageContentHeader, Row,
    // ListItemActionButton, ListGroup, ListItem,
    PageSubContent
} from "redet-react-components";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import * as contactsHelper from "../../../utils/contactsHelper";
import {
    getContactInfoSammanfattning,
    mapAtgardListToStringArray,
    parseFastigheterArrayToStringArray
} from "../../../utils/ansokanDataExtractor";


export function Sammanfattning() {
    const [_formDataLoaded, setFormDataLoaded] = useState(false);
    const [_initData, setInitData] = useState(initFormData ?? {ovrigt: ''});

    const ansokanDataLoaded = useAppSelector<boolean>(store => store.ansokanRepository?.loadSuccessful === undefined ? false : store.ansokanRepository?.loadSuccessful)
    const contactList = useAppSelector(store => store.contacts.contactList);
    const dispatch = useAppDispatch();
    const fastigehterIansokan: any[] = useAppSelector(store => store.fastigheter.fastigheterList);
    const [ansokanFastigheter, setAnsokanFastigheter] = useState<any[]>([])
    // @ts-ignore
    const bifogadHandlingList: any[] = useAppSelector(store => store?.handlingarMetadata.list) ?? [];
    // @ts-ignore
    const fastighetforandring = useAppSelector(store => store.form.fastighetsforandring);
    const [ansokanAtgarder, setAnsokanAtgarder] = useState<any[]>([])
    // @ts-ignore
    const antalRitadeKartor = useAppSelector(store => store.kartskiss?.numberOfRitadeKartor) ?? 0;
    // @ts-ignore
    const antalUppladdadeKartor = useAppSelector(store => store.bifogadKartskiss?.numberOfUppladdadeKartor) ?? 0;
    const [totaltAntalKartor, setTotaltAntalKartor] = useState(0)
    useEffect(() => {
        dispatch(visaHjalpAvsnitt('help-sammanfattning', 'Sammanfattning'))
        scrollToMainTag()
    },[])

    useEffect(() => {
        setTotaltAntalKartor(antalRitadeKartor+antalUppladdadeKartor)
    }, [antalRitadeKartor, antalUppladdadeKartor]);

    useEffect(() => {
        if (fastigehterIansokan) setAnsokanFastigheter(parseFastigheterArrayToStringArray(fastigehterIansokan) as any[])
        if (fastighetforandring) setAnsokanAtgarder(mapAtgardListToStringArray(Object.keys(fastighetforandring).filter(forandring =>fastighetforandring[forandring])))
    },[fastigehterIansokan, fastighetforandring])

    useEffect(() => {
        if (ansokanDataLoaded && !_formDataLoaded) {
            const res = dispatch(initFormData(formProperties.ovrigBeskrivning.name))
            setFormDataLoaded(true);
            setInitData(res)
        }
    }, [ansokanDataLoaded])

    const contacts = [
        ...contactsHelper.getAllContactsWhoShouldSignAnsokan(contactList),
        ...contactsHelper.getAllContactsWithRollSokandeWhoHaveOmbud(contactList)
    ];
    return (
        <div>
            <PageContentHeader title={'Sammanfattning'}
                               headerLevel={2}
                               description={'Här visas en sammanfattning av de uppgifter du angivit. Vill du förtydliga något kan du göra det längst ner på denna sida.'}/>
            <br/>
            <PageSubContent headerLevel={3} title={'Jag/vi ansöker om följande Lantmäteriförrättning'}>
                <FormFieldset>
                    <Row>
                        <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <h4>Berörd fastighet</h4>
                                {ansokanFastigheter?.map((fastighet) =>
                                    <p key={`key_fast${fastighet}`}>{fastighet}</p>
                                )}
                            </div>
                        </Column>
                        <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <h4>Ändring</h4>
                                {ansokanAtgarder?.map((atgarder) =>
                                    <p key={`key_atag${atgarder}`}>{atgarder}</p>
                                )}
                            </div>
                        </Column>
                    </Row>
                    <Row id="sokandeListContainer">
                        {contacts?.map((contact, index) =>
                            contact.data.rollSokande &&
                            <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                                    key={`key_cont${contact.id ?? index}`}
                                    id={`sokandeContainer${index}`}>
                                <div className="form-group" data-testid={`contact-info-${index}`}>
                                    {getContactInfoSammanfattning(contacts, contact, index)}
                                </div>
                            </Column>
                        )}
                    </Row>
                    <Row>
                        <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <h4>Handlingar</h4>
                                {bifogadHandlingList?.map((handling: any) =>
                                    <p key={`key_hand${handling._id}`}>{handling.description}</p>
                                )}
                            </div>
                        </Column>
                        <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="form-group">
                                <h4>Kartor</h4>
                                <p>{totaltAntalKartor}st</p>
                            </div>
                        </Column>
                    </Row>
                </FormFieldset>
            </PageSubContent>
            <Form
                onSubmit={() => {
                }}
                initialValues={_initData}
                subscription={{ submitting: true, pristine: true }}>
                {() => {
                    return (
                        <form onSubmit={() => {
                        }}>
                            {_formDataLoaded && <FormStateToRedux form={formProperties.ovrigBeskrivning.name}/>}
                            <FormFieldset>
                                <TextAreaFinalForm id={'ovrigtId'} field={'ovrigt'} label={'Förtydligande'} rows={7}
                                                   maxLength={4000} characterLimit={4000} mandatory={false}/>
                            </FormFieldset>
                        </form>
                    )
                }}
            </Form>
            <PageWizardNavigationToolbar buttonLeftText={'Bifoga handlingar'} buttonLeftLink={'../handlingar'}/>
        </div>
    )
}
