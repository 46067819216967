import { PageHeader } from 'components/General/forms/layoutElements'
import Navigation from 'components/Navigation/Navigation'
import { Arende } from 'domain/Arende'
import { useAppDispatch } from 'hooks'
import { IE11modalDialogWarning } from 'pages/Ansokan'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {useLocation, useNavigate} from "react-router-dom"
import {
    ButtonToolbar,
    // @ts-ignore
    ExternalLink, LinkButtonSub, PageContentHeader, SidePanelChapter, VarningsRuta
} from 'redet-react-components'
import { loadArendeList } from "redux/modules/arendeList"
import { visaMoment } from 'redux/modules/help'
import { RootState } from 'store'
import Hjalp from 'views/Hjalp/Hjalp'
import Term from 'views/Hjalp/TermLank'
import { FeedbackHelpContent } from 'views/Hjalp/texter/FeedbackHelpContent'
import { KontaktaOssHelpContent } from 'views/Hjalp/texter/KontaktaOssHelpContent'
import { PersonuppgifterHelpContent } from 'views/Hjalp/texter/PersonuppgifterHelpContent'
import FilterQueryHelper from "../../utils/filterQueryHelper"
import { DINA_PAGAENDE_LANTMATERIFORRATTNINGAR_HEADER } from "../../utils/globalConstants"
import { ArendeFilter } from './components/ArendeList/ArendeFilter'
import { ArendeList } from './components/ArendeList/ArendeList'
import { ArendeFilterType, DefaultArendeFilter } from './components/ArendeList/ArendeListFilter'

export const WelcomeOrganization = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const isHjalpVisible = useSelector((state: RootState) => state.help.visible)

    const arenden: Arende[] = useSelector((state: RootState) => state.arendeList.list)
    const arendenIsLoading: boolean = useSelector((state: RootState) => state.arendeList.isLoading)

    const [arendeError, setArendeError] = useState(false)
    const [arendeFilter, setArendeFilter] = useState<ArendeFilterType | undefined>(undefined)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (isHjalpVisible) {
            dispatch(visaMoment("help-support", "help-personuppgift", true))
        } else {
            dispatch(visaMoment("help-support", "help-personuppgift", false))
        }

        const filter = FilterQueryHelper.getArendeFilterFromQuery(new URLSearchParams(location.search)) || FilterQueryHelper.getArendeFilterFromStorage() || arendeFilter
        if (filter) {
            setArendeFilter(filter)
            appendFilterQueryToUrl(filter)
        }

        fetchArendeList(filter);

    }, [])

    const fetchArendeList = (filter: ArendeFilterType = DefaultArendeFilter) => {
        try {
            dispatch(loadArendeList(filter)).then(() => {
                setArendeError(false);
            }).catch(() => {
                setArendeError(true)
            })
            setArendeError(false);
        } catch (error: any) {
            setArendeError(true)
        }
    }

    const pagaendeArendenIngress = <>Här kan du se vilka pågående lantmäteriförrättningar du är berörd av. Efter att en
        ansökan skickats in kommer den diarieföras som ett eller flera förättningsärenden. Efter några dagar kan
        du se
        dina ärenden och ärenden som du berörs av här.</>

    const pagaendeArendenDescription = <>Uppgifterna om ärendet tas bort 30 dagar efter att förrättningen slutförts.
        Handlingarna finns därefter
        att
        nå
        via tjänsten <strong><ExternalLink url={'https://www.lantmateriet.se/sv/fastighet-och-mark/information-om-fastigheter/Min-fastighet/'}
            text={'Min fastighet'} /></strong> där
        du
        som privatperson kan logga in med e-legitimation. För organisationer krävs därefter ett abonnemang på
        tjänsten <strong><ExternalLink
            url={'http://www.lantmateriet.se/sv/Fastigheter/Fastighetsinformation/Forrattningsakter/?faq=1860'}
            text={'ArkivSök'} /></strong> för att komma åt handlingar, klicka på länken för mer information och
        kostnader.
        Du kan även vända dig till <Term label={'Lantmäteriets kundcenter'} moment={'help-support'} />.</>

    const updateFilter = (filter: ArendeFilterType) => {
        appendFilterQueryToUrl(filter)
        fetchArendeList(filter);
    }

    const appendFilterQueryToUrl = (filter: ArendeFilterType) => {
        //create url query params
        const urlQuery = FilterQueryHelper.createUrlQueryString(filter)
        navigate({pathname: location.pathname, search:urlQuery})
    }

    return (
        <>
            <header>
                <Navigation />
                <PageHeader
                    title={<>Pågående<br/>lantmäteri&shy;förrättningar</>}
                    introduction={<p className="introduction">Efter att en ansökan skickats in kommer den diarieföras som ett
                        eller flera
                        förättningsärenden. Efter några dagar kan du se dina ärenden och ärenden som du berörs av här.</p>}
                />
            </header>
            <main>
                <IE11modalDialogWarning />
                <div className="container">
                    <PageContentHeader headerId="pagaendeForrattningHeader" headerLevel={2}
                        title={DINA_PAGAENDE_LANTMATERIFORRATTNINGAR_HEADER}
                        introduction={pagaendeArendenIngress}
                        description={pagaendeArendenDescription} />

                    <ArendeFilter filter={arendeFilter} onFilterChange={updateFilter} />
                    <ArendeList showArendenLoadingSpinner={arendenIsLoading} arenden={arenden} pageSize={10} error={arendeError} />
                    <VarningsRuta intro={'Behöver du lägga till en användare eller uppdatera ett lösenord?'}
                        description={'Du som är administratör för den här organisationen kan lägga till och ta bort användare samt hantera lösenord.'}
                                  ariaTitle={"Information om inloggning"}>

                        <ButtonToolbar>
                            <div className="button-group">
                                <LinkButtonSub text={'Administrera behörigheter'}
                                    isExternalLink={true}
                                    link={'https://etjanster.lantmateriet.se/ident/requestForm.do?jsa=%7B%22submit%22%3A%22submitThenOpener(3000)%22%2C%22cancel%22%3A%22window.close()%22%7D&uid=cn%3Dak000179%2Ccn%3Drequestdefs%2Ccn%3Dappconfig%2Ccn%3Dak000089-ui%2Ccn%3Ddriverset%2Co%3Dsystem&aqua=true'}
                                    icon={'open_in_new'} />
                            </div>
                        </ButtonToolbar>
                    </VarningsRuta>
                </div>
            </main>
            <Hjalp>
                <SidePanelChapter name={'help-support'}>
                    <KontaktaOssHelpContent/>
                </SidePanelChapter>
                <SidePanelChapter name={'help-personuppgift'}>
                    <PersonuppgifterHelpContent/>
                </SidePanelChapter>
                <SidePanelChapter name={'help-feedback'}>
                    <FeedbackHelpContent/>
                </SidePanelChapter>
            </Hjalp>
        </>
    )
}

export default WelcomeOrganization
