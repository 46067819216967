import {loadList, remove, upload} from 'repository/kartskissRepository';
import { v4 as uuidv4 } from 'uuid';
import {Fetcher} from "repository/fetcher";
import {LOAD_SUCCESSFUL as ANSOKAN_LOAD_SUCCESSFUL} from "./ansokanCRUD";
import {ANSOKAN_FORM_DESTROY} from 'redux/modules/sharedActions';

export const ACTION_FETCH_KARTSKISSER_PENDING = 'kartskiss/ACTION_FETCH_KARTSKISSER_PENDING';
export const ACTION_FETCH_KARTSKISSER_SUCCESS = 'kartskiss/ACTION_FETCH_KARTSKISSER_SUCCESS';
export const ACTION_FETCH_KARTSKISSER_FAILED = 'kartskiss/ACTION_FETCH_KARTSKISSER_FAILED';

export const ACTION_UPLOAD_KARTSKISS_PENDING = 'kartskiss/ACTION_UPLOAD_KARTSKISS_PENDING';
export const ACTION_UPLOAD_KARTSKISS_SUCCESS = 'kartskiss/ACTION_UPLOAD_KARTSKISS_SUCCESS';
export const ACTION_UPLOAD_KARTSKISS_FAILED = 'kartskiss/ACTION_UPLOAD_KARTSKISS_FAILED';

export const ACTION_REMOVE_KARTSKISS_PENDING = 'kartskiss/ACTION_REMOVE_KARTSKISS_PENDING';
export const ACTION_REMOVE_KARTSKISS_SUCCESS = 'kartskiss/ACTION_REMOVE_KARTSKISS_SUCCESS';
export const ACTION_REMOVE_KARTSKISS_FAILED = 'kartskiss/ACTION_REMOVE_KARTSKISS_FAILED';

export const USER_CHOOSE_NOT_TO_ADD_A_KARTSKISS = 'kartskiss/USER_CHOOSE_NOT_TO_ADD_A_KARTSKISS';
export const USER_CHOOSE_TO_ADD_A_KARTSKISS = 'kartskiss/USER_CHOOSE_TO_ADD_A_KARTSKISS';

const initialState = {
    list: [],
    hasUserChosenNotToAddAkartskiss: false,
    numberOfRitadeKartor: 0,
    isLoadingList: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {

        case ACTION_FETCH_KARTSKISSER_SUCCESS:
            return {
                ...state,
                list: [...action.result],
                isLoadingList: false,
                numberOfRitadeKartor: action.result.length
            };
        case ACTION_REMOVE_KARTSKISS_SUCCESS:
            return {
                ...state,
                numberOfRitadeKartor: state.numberOfRitadeKartor - 1
            };
        case ACTION_UPLOAD_KARTSKISS_SUCCESS:
            return {
                ...state,
                numberOfRitadeKartor: state.numberOfRitadeKartor + 1
            };
        case ACTION_FETCH_KARTSKISSER_PENDING:
            return {
                ...state,
                isLoadingList: true
            };
        case ACTION_FETCH_KARTSKISSER_FAILED:
            return {
                ...state,
                error: action.msg,
                isLoadingList: false,
            };
        case USER_CHOOSE_NOT_TO_ADD_A_KARTSKISS:
        case USER_CHOOSE_TO_ADD_A_KARTSKISS:
            return {
                ...state,
                hasUserChosenNotToAddAkartskiss: action.choice,
            };
        case ANSOKAN_LOAD_SUCCESSFUL:
            return {
                ...state,
                hasUserChosenNotToAddAkartskiss: action.result.data.hasUserChosenNotToAddAkartskiss === undefined ? false : action.result.data.hasUserChosenNotToAddAkartskiss,
                numberOfRitadeKartor: action.result.data.numberOfRitadeKartor === undefined ? 0 : action.result.data.numberOfRitadeKartor,
            };
        case ANSOKAN_FORM_DESTROY:
            return initialState;
        default:
            return state;
    }
}

export function getKartskisserList(ansokanId) {
    return function action(dispatch, getState) {
        dispatch(loadKartskisserList());
        const fetcher = new Fetcher(dispatch, getState);
        return loadList(fetcher, ansokanId)
            .then(kartskisser => {
                dispatch(loadKartskisserListSuccess(kartskisser));
            }).catch(error => {
                dispatch(loadKartskisserListFailed(error));
            });
    }
}

export function loadKartskisserListSuccess(kartskisser) {
    return {
        type: ACTION_FETCH_KARTSKISSER_SUCCESS,
        result: kartskisser
    };
}

export function loadKartskisserList() {
    return {
        type: ACTION_FETCH_KARTSKISSER_PENDING,
    };
}

export function loadKartskisserListFailed(errorMsg) {
    return {
        type: ACTION_FETCH_KARTSKISSER_FAILED,
        msg: errorMsg
    };
}

export function uploadKartskiss(ansokanId, imageData, pendingCallback, successCallback, failCallback) {
    return {
        types: [ACTION_UPLOAD_KARTSKISS_PENDING, ACTION_UPLOAD_KARTSKISS_SUCCESS, ACTION_UPLOAD_KARTSKISS_FAILED],
        backendPromise: (ajaxDriver, state) => {
            const kartskissId = state.map.id;
            const kartskiss = {
                center: state.map.center,
                zoom: state.map.zoom,
                fastighetFeatures: undefined,
                drawingFeatures: state.map.drawingFeatures,
                fastigheter: state.map.fastigheter,
                mapTitle: state.map.mapTitle,
                dataURL: imageData,
            };

            return upload(ajaxDriver, ansokanId, kartskissId, kartskiss);
        },
        callbacks: {pending: pendingCallback, success: successCallback, fail: failCallback}
    };
}

export function setUserChooseNotToAddaKartskiss(choice) {
    if (choice) {
        return {
            type: USER_CHOOSE_NOT_TO_ADD_A_KARTSKISS,
            choice: true
        };
    } else {
        return {
            type: USER_CHOOSE_TO_ADD_A_KARTSKISS,
            choice: false
        };
    }
}

export function copyKartskiss(ansokanId, id, pendingCallback, successCallback, failCallback) {
    return {
        types: [ACTION_UPLOAD_KARTSKISS_PENDING, ACTION_UPLOAD_KARTSKISS_SUCCESS, ACTION_UPLOAD_KARTSKISS_FAILED],
        backendPromise: (ajaxDriver, state) => {
            const toCopy = state.kartskiss.list.find(skiss => skiss.id === id);
            const kartskissId = uuidv4();
            const kartskiss = {
                center: toCopy.center,
                zoom: toCopy.zoom,
                fastighetFeatures: toCopy.fastighetFeatures,
                drawingFeatures: toCopy.drawingFeatures,
                fastigheter: toCopy.fastigheter,
                mapTitle: toCopy.mapTitle + ' (kopia)',
                dataURL: toCopy.dataURL,
            };

            return upload(ajaxDriver, ansokanId, kartskissId, kartskiss);
        },
        callbacks: {pending: pendingCallback, success: successCallback, fail: failCallback}
    };
}

export function removeKartskiss(ansokanId, id, pendingCallback, successCallback, failCallback) {
    return {
        types: [ACTION_REMOVE_KARTSKISS_PENDING, ACTION_REMOVE_KARTSKISS_SUCCESS, ACTION_REMOVE_KARTSKISS_FAILED],
        backendPromise: (ajaxDriver) => remove(ajaxDriver, ansokanId, id),
        callbacks: {pending: pendingCallback, success: successCallback, fail: failCallback}
    };
}
