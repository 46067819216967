import Navigation from 'components/Navigation/Navigation'
//@ts-ignore
import { ButtonGroup, ButtonToolbarV2, LinkButtonSub, ModalDialogAlert, PageHeader } from 'redet-react-components'


type GenericErrorProps = {
  header?: string,
  body?: string,
  modalVisible: boolean
}

export default function GenericErrorBoundaryPage (props: GenericErrorProps) {
  const header = 'Ett tekniskt fel har uppstått'
  const body = 'Vi ber om ursäkt för detta och kommer att försöka åtgärda felet så fort som möjligt.'

  return (
    <div>
      <header>
        <Navigation/>
        <PageHeader/>
      </header>
      <main>
        {props.modalVisible &&
          <ModalDialogAlert modalTitle={props.header === undefined ? header : props.header}
                            description={props.body === undefined ? body : props.body}
                            useDefaultToolbar={false}>
            <ButtonToolbarV2>
              <ButtonGroup>
                <LinkButtonSub text={'Ok'} link={window.location.origin} icon={'arrow_forward'}/>
              </ButtonGroup>
            </ButtonToolbarV2>
          </ModalDialogAlert>
        }
      </main>
    </div>
  )
}


