import { Dispatch, SetStateAction, useState } from "react";
import {
    // @ts-ignore
    Checkbox, Column, Row, TextInput, WizardIntorduction, WizardNavigationButtonToolbar
} from "redet-react-components";
import { email } from "utils/validation";

type Props = {
    epostKvittens: string
    setEpostKvittens: Dispatch<SetStateAction<string>>
    epostKontakt: string
    useSameEpost: boolean
    setUseSameEpost: Dispatch<boolean>
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
    previousStep?: () => void
}

export function KvittensEpostStep(props: Props) {
    const [epostKvittensValidationError, setEpostKvittensValidationError] = useState<string>('');

    return <>
        <WizardIntorduction
            title={"Kvittens"}
            ingress={"Vi skickar en kvittens till nedan angiven e-postadress när meddelandet är inskickad till Lantmäterimyndigheten. " +
                "Denna e-postadress kommer inte att visas för ansvariga handläggare om du valt att inte ange denna i föregående steg."}>
            <Row>
                <Column columns={"form-group col-xs-12 col-sm-6 col-xl-6"}>
                    <TextInput
                        label={"E-post"}
                        isRequired={true}
                        autoComplete={'email'}
                        type={"email"}
                        id={"mailId"}
                        maxLength={100}
                        value={props.epostKvittens}
                        validationErrorMsg={epostKvittensValidationError}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const error = email(evt.target.value);
                            if (error) {
                                setEpostKvittensValidationError(error);
                                props.setEpostKvittens(evt.target.value)
                            } else {
                                setEpostKvittensValidationError('');
                                props.setEpostKvittens(evt.target.value)
                            }
                        }}
                    />
                    <Checkbox
                        id={'useCurrentEpostId'}
                        label={'Använd samma epostadress som i föregående steg'}
                        isDisabled={!props.epostKontakt}
                        isChecked={props.useSameEpost}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            props.setUseSameEpost(evt.target.checked);
                            const error = email(props.epostKontakt);
                            setEpostKvittensValidationError(error || '');
                            (evt.target.checked && props.epostKontakt) ? props.setEpostKvittens(props.epostKontakt) : props.setEpostKvittens('');
                        }}/>
                </Column>
            </Row>
        </WizardIntorduction>
        <WizardNavigationButtonToolbar
            prevButtonText={"Ange skrivelse"}
            nextButtonText={"Förhandsgranska"}
            isNextButtonDisabled={!props.epostKvittens || epostKvittensValidationError}
            onClickNextButton={props.nextStep}
            onClickPrevButton={props.previousStep}/>
    </>;
}

export default KvittensEpostStep;