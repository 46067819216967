// eslint-disable-next-line
import logo from '!file-loader!redet-atomic/dist/gfx/logotypes/lantmateriet-rgb.svg'
import { BrowserUpdate } from '../components/General/BrowserUpdate'
//@ts-ignore
import { ButtonGroup, ButtonToolbarV2, ButtonSub, FormFieldset, VarningsRuta } from 'redet-react-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'hooks'
import { RootState } from 'store'
import LanguageSelector from 'components/General/LanguageSelector'
import {useNavigate} from "react-router-dom";

export function Start() {

    const navigate = useNavigate();
    const [visitor, setVisitor] = useState('privat')
    const { t, i18n: {language} } = useTranslation();
    const browserUpdateVisible = useAppSelector((store: RootState) => store.browserUpdate.browserUpdateVisible);

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setVisitor(selectedValue);
    };

    const visitorUrl = `/start/${visitor}`;

    return (
        <div className="redet realestate">
        <div className="modal themeout show" id="modal-form">
        {browserUpdateVisible && <div id="browser-update-container">
              <BrowserUpdate/>
            </div>}
            <div className="modal-dialog">
                <div className="modal-content centered" data-selenium-id="startsida-modal">
                    <LanguageSelector rightAligned = {true}/>
                    <figure className="header-logo">
                        <img src={logo} alt="Lantmäteriets logotyp"/>
                    </figure>
                    <h1>{t('start.welcome')}</h1>
                    <p className="introduction">{t('start.introductions.one', {count: 1})}</p>
                    <p>{t('start.introductions.two', {count: 2})}</p>
                    <p>{t('start.introductions.three', {count: 3})}</p>
                    <p>{t('start.introductions.four', {count: 4})}</p>
                    <FormFieldset className="form-group">
                        <label className="label" htmlFor="visitor">{t('start.login_as')}</label>
                        <select className="form-select" id={'visitor'} onChange={handleDropdownChange} defaultValue={'privat'} data-testid='visitor'>
                            <option value={'privat'}>{t('start.private')}</option>
                            <option value={'sdg'} >{t('start.eu_citizen')}</option>
                            <option value={'org'}>{t('start.organization')}</option>
                        </select>
                    </FormFieldset>
                    <VarningsRuta ariaTitle={t('start.login_info_aria')}>
                    {(visitor === 'sdg') &&
                        <p><strong>{t('start.warnSDG')}</strong></p>}
                    {(visitor === 'org') &&
                        <p><strong>{t('start.warnOrg')}</strong></p>}
                    {(visitor === 'privat') &&
                        <p><strong>{t('start.warnSwe')}</strong></p>}
                    {(language !== 'sv' && visitor !== 'sdg') &&
                         <p className="optional">{t('start.warning')}</p>}
                    </VarningsRuta>
                       <ButtonToolbarV2 className="toolbar left-aligned">
                        <ButtonGroup>
                            <ButtonSub id={'login-next-button'}
                                       onClick={() => navigate(visitorUrl)}
                                       className="button-top">
                                <span className="material-icons" aria-hidden="true">arrow_forward</span>{t('start.proceed')}</ButtonSub>
                        </ButtonGroup>
                    </ButtonToolbarV2>
                </div>
            </div>
        </div>
    </div>
    )
}
 export default Start;
