import {useState} from "react";
// @ts-ignore
import {WizardDialog, WizardDialogStep,} from "redet-react-components";
import {useSelector} from "react-redux";
import {submitAterkallandeToMyndighet} from "./aterkallandeService";
import {AterkallandeKvittensResponse, AterkallandeRender} from "./model/aterkallande";
import {JsonMappingFailure} from "./aterkallandeRepository";
import {RootState} from "store";
import {useAppDispatch} from "hooks";
import InskickadStep from "../skrivelse/modalSteps/inskickadStep";
import UpprattaAterkallandeStep from "./modalSteps/upprattaAterkallandeStep";
import ForhandsGranskaAterkallande from "./modalSteps/forhandsGranskaAterkallande";
import KvittensEpostAterkallandeStep from "./modalSteps/KvittensEpostAterkallandeStep";

type Props = {
    arendenummer: string
    refetchKompletteringarList: () => void
    closeWizardFunction: () => void
    initialStep?: number
    initialError?: string
    aterkallandeInskickat: () => void
}

export function AterkallandeRenderComponent({arendenummer, refetchKompletteringarList, closeWizardFunction, initialStep = 1, initialError = '', aterkallandeInskickat}: Props) {
    const user = useSelector((store: RootState) => store.auth.user)
    const dispatch = useAppDispatch();

    const [meddelande, setMeddelande] = useState<string>('');
    const [epostKontakt, setEpostKontakt] = useState<string>('');
    const [telefonKontakt, setTelefonKontakt] = useState<string>('');
    const [epostKvittens, setEpostKvittens] = useState<string>('');
    const [kvittens, setKvittens] = useState<string>('');
    // Initial state är satt till false eftersom då kontrollerade checkboxar inte får gå från undefined -> true https://reactjs.org/link/controlled-components
    const [useSameEpost, setUseSameEpost] = useState<boolean>(false)
    // Initial state är satt till false eftersom då kontrollerade checkboxar inte får gå från undefined -> true https://reactjs.org/link/controlled-components
    const [error, setError] = useState<string>(initialError);
    const [kvittensEmailSuccessfullySent, setKvittensEmailSuccessfullySent] = useState(true);
    const [kompletteringsTyp] = useState<string>("Återkallande");
    const arende = useSelector((store: RootState) => store.arende.arende)
    const selectedAktbilagor = useSelector((store: RootState) => store.arendeKompletteringar.selectedAktbilagebeteckningar)


    function aterkallandeSubmit() {
        if(!user?.fullName){
            throw new Error("Namn saknas på användare");
        }
        const aterkallande : AterkallandeRender =
            {
                avsandare: user.fullName,
                arendenummer: arendenummer,
                meddelande: meddelande,
                epostKvittens: epostKvittens,
                epostKontakt: epostKontakt,
                telefon: telefonKontakt,
                aktbilagebeteckning: selectedAktbilagor,
            }
        return submitAterkallandeToMyndighet(aterkallande, dispatch).then((response: AterkallandeKvittensResponse) => {
            setError('');
            setKvittens(response.inskickadDatum)
            if(!response.kvittensEmailSuccessfullySent) {
                setKvittensEmailSuccessfullySent(false);
            }
            aterkallandeInskickat();
        }).catch((err: any) => {
            if (err instanceof JsonMappingFailure) {
                setTimeout(() => {
                    setError('Ett fel uppstod när en kvittens för den inskickade återkallelsen skulle skapas.' +
                        ' Återkallelsen är inskickad till Lantmäterimyndigheten och ett kvittensmail har skickats till angiven epostadress. Du kan stänga dialogen.')}, 2000);
            } else {
                setTimeout(() => {
                    setError('Ett fel uppstod vid inskick av återkallande, vänligen navigera tillbaka och kontrollera uppgifterna i ditt meddelande och försök igen. ' +
                        'Du kan även välja att stänga dialogen, men då försvinner ditt upprättade återkallande och du behöver upprätta det igen.')}, 2000);
            }
        });
    }

    return (
        <>
            {user?.isOrganization === false && (user?.fullName !== undefined || user?.fullName !== '') &&
                <WizardDialog title={"Skicka in återkallande av ansökan"}
                              hasDefaultNav={false}
                              cancelFunction={closeWizardFunction}
                              elementsToAllowFocus={['helpSidePanelId']}
                              initialStep={initialStep}>
                    <WizardDialogStep stepTitle={"Återkallande av ansökan"}>
                        <UpprattaAterkallandeStep {...{meddelande, setMeddelande, epostKontakt, setEpostKontakt, telefonKontakt, setTelefonKontakt, arende}}/>
                    </WizardDialogStep>
                    <WizardDialogStep stepTitle={"Kvittens"}>
                        <KvittensEpostAterkallandeStep {...{epostKvittens, setEpostKvittens, epostKontakt, useSameEpost, setUseSameEpost}}/>
                    </WizardDialogStep>
                    <WizardDialogStep stepTitle={"Förhandsgranskning"}>
                        <ForhandsGranskaAterkallande {...{user, arendenummer, meddelande, epostKontakt, telefonKontakt, epostKvittens, aterkallandeSubmit}}/>
                    </WizardDialogStep>
                    <WizardDialogStep stepTitle={error ? 'Tekniskt fel' : 'Inskickat'}>
                        <InskickadStep {...{closeWizardFunction, kvittens, epostKvittens, refetchKompletteringarList, kvittensEmailSuccessfullySent, error, setError, kompletteringsTyp}}/>
                    </WizardDialogStep>
                </WizardDialog>
            }
        </>
    )
}
export default AterkallandeRenderComponent