import * as olSphere from 'ol/sphere'
import { transform } from 'ol/proj'

export function formatLength (line, sourceProj) {
  let length
  const EARTH_RADIUS_IN_METER = 6378137
  const wgs84Sphere = olSphere
  const coordinates = line.getCoordinates()
  length = 0
  for (let i = 0, ii = coordinates.length - 1; i < ii; ++i) {
    // Need to transform coordinates to the OL standard projection so that the sphere calculations are correct (WGS84 === EPSG:4326)
    const c1 = transform(coordinates[i], sourceProj, 'EPSG:4326')
    const c2 = transform(coordinates[i + 1], sourceProj, 'EPSG:4326')
    // It's important to use EARTH RADIUS IN METER -
    length += wgs84Sphere.getDistance(c1, c2, EARTH_RADIUS_IN_METER)
  }

  let output
  if (length > 100) {
    output = (Math.round(length / 1000 * 100) / 100) + ' km'
  } else {
    output = (Math.round(length * 10) / 10) + ' m'
  }
  return output
}

export function formatArea (polygon, sourceProj, htmlSuperscript) {
  let area

  area = polygon.getArea()

  let output
  if (area > 10000) {
    output = (Math.round(area / 1000000 * 100) / 100) +
      ' ' + (htmlSuperscript ? 'km<sup>2</sup>' : 'km²')
  } else {
    output = (Math.round(area)) +
      ' ' + (htmlSuperscript ? 'm<sup>2</sup>' : 'm²')
  }
  return output
}
