import Log from 'utils/log'

export default class ToolCoordinator {
  constructor () {
    this.log = new Log(this.constructor.name)
  }

  setActiveTool (tool) {
    this.activeTool = tool
  }

  getActiveTool () {
    return this.activeTool
  }
}
