import { findOwners, importeraFastighetsagare } from 'repository/fastighetRepository'
import { Fetcher } from 'repository/fetcher'
import { mapFastighetsAgareToContact } from '../../utils/mapFastighetsAgareToContact'
import { createContacts } from 'redux/modules/contacts'
import { loadAnsokan } from './ansokanCRUD'

export function findOwnersForProperty (objektidentiteter, saveContatct = true) {
  return function action (dispatch, getState) {
    const fetcher = new Fetcher(dispatch, getState, false)
    return findOwners(fetcher, objektidentiteter)
      .then(response => {
        if (saveContatct) {
          const contacts = mapFastighetsAgareToContact(response.agare, getState().fastigheter.fastigheterList)
          dispatch(createContacts(contacts))
        }
        return response
      })
      .catch(error => {
        // Rethrow error for other catch-promises down the execution chain
        throw error
      })
  }
}

/**
 * Hämta ägare för valda fastigheter och lägg in eller uppdatera kontaktuppgifter för dessa i ansökan
 * (Hämtning av ägare samt uppdatering av ansökan sker i backend)
 */
export function importFastighetsagare () {
  return function action (dispatch, getState) {
    const fetcher = new Fetcher(dispatch, getState, false)
    return importeraFastighetsagare(fetcher, getState().ansokanRepository.remoteDataKey)
      .then(response => {
        dispatch(loadAnsokan(getState().ansokanRepository.remoteDataKey))
        return response
      })
      .catch(error => {
        // Rethrow error for other catch-promises down the execution chain
        throw error
      })
  }
}