// @ts-ignore
import {ExternalLink, SidePanelChapterHeading, SidePanelSection} from "redet-react-components";

export const OmTjansten = <>
    <SidePanelChapterHeading header={'Om tjänsten'} sectionTitle={'Om tjänsten'}/>
    <SidePanelSection id={'term-omTjansten'}>
        <p>Inget skickas in om inte du klickar på <em>Skicka in ansökan</em>.Ansökan sparas automatiskt varje gång du gör en ändring.<br/>
            Du kan när som helst gå tillbaka till ett tidigare steg om du behöver ändra något.<br/>
            När du fyllt i alla nödvändiga uppgifter aktiveras knappen <strong>Skriv under</strong> som tar dig till
            underskrifter och
            möjlighet att skicka in.<br/>
            För att få hjälp på sidan där du befinner dig, kan du trycka på frågetecknet uppe i högra hörnet.<br/>
            Läs mer om tjänsten på Lantmäteriets sida <ExternalLink text={'Ansök, följ eller komplettera ett ärende'}
                                                                    url={'https://www.lantmateriet.se/sv/Fastigheter/Andra-fastighet/ansok-folj-eller-komplettera-ett-arende/'}/>
        </p>
    </SidePanelSection>
</>