import { ModalDialogAlert } from 'redet-react-components'

export function AnsokanCantBeChangedModal () {
  function refresh () {
    window.location.reload()
    return false
  }

  return <ModalDialogAlert modalTitle={'Ladda om sidan'}
                           ingress={'Ansökan är låst för förändring. Ladda om sidan för att läsa in den senaste versionen av ansökan.'}
                           description={'Detta kan uppstå ifall ansökan har förändrats i en annan flik där den har gått vidare till ett läge där den blivit låst för förändring.'}
                           acceptButton={{ text: 'Ladda om sidan', icon: 'refresh', onClick: refresh }}
                           id={'ansokanCantBeChangedModalId'}/>
}
