//@ts-ignore
import { IconButton } from 'redet-react-components'

const iframeStyle = {
  overflow: "hidden",
  border: 0
}

export function Lantmateriforattningfilm () {
  return <div className="quickchannel">
    <iframe src="https://play.quickchannel.com/embed/cv3kesf?rel=0&amp;showinfo=0&amp;AUTOSTART=0"
            title="Att ansöka om en lantmäteriförrättning"
            style={iframeStyle}>
    </iframe>
  </div>
}

type LantmateriforattningfilmIlightboxProps = {
  onButtonCloseClick: any
}

export function LantmateriforattningfilmIlightbox ({ onButtonCloseClick }: LantmateriforattningfilmIlightboxProps) {
  return <div className="panel-player">
    <div className="container">
      <Lantmateriforattningfilm/>
      <IconButton icon={'clear'} ariaLabel={'Stäng'} onClick={onButtonCloseClick}/>
    </div>
  </div>
}
