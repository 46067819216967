import  {  DragEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
//@ts-ignore
import { FormFieldset, VarningsRuta } from 'redet-react-components'
import { ValidationError } from './HandlingUtils';

type Props = {
  selectedFiles: (selectFile: File[]) => void,
  titleText?: string,
  validationMethods: ((validateFiles: File[]) => { filteredFiles: File[]; errorMessage: ValidationError | null; })[],
  labelText: string | JSX.Element,
  multiple: boolean
}

function UploadFilesApplication(props: Props) {

  const [fileSelectErrorMessages, setFileSelectErrorMessages] = useState<ValidationError[]>([])
  const filesSelectedInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  function fileDropped (event: DragEvent) {
    preventDefault(event)
    addFilesToList([...event.dataTransfer.files])
  }

  function addFilesToList (files: File[]) {
    setFileSelectErrorMessages([])
    let errorMessage: ValidationError[] = []

    props.validationMethods.forEach((validationMethod) => {
      const result = validationMethod(files)
      if(result.errorMessage) {
        errorMessage.push(result.errorMessage)
      }
    })

    if(errorMessage.length > 0) {
      setFileSelectErrorMessages(errorMessage)
    } else {
      props.selectedFiles(files)
    }
  }

  function onFileAdded (event: any) {
    if (event.target.files.length > 0) {
      addFilesToList([...event.target.files])
      // Clear the input control once we have its data so that it can be reused without any odd behaviour
      event.target.value = null
    }
  }

  function preventDefault (event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
  }

    return (
      <>
        <FormFieldset title={props.titleText}>
          <div className="file-upload">
              <label htmlFor="fileSelectionInput">{props.labelText}</label>
              <span className="area"
               onClick={() => filesSelectedInputRef.current?.click()}
               onDrop={fileDropped}
               onDrag={preventDefault}
               onDragStart={preventDefault}
               onDragEnd={preventDefault}
               onDragOver={preventDefault}
               onDragEnter={preventDefault}
               onDragLeave={preventDefault}
               onKeyDown={(event) => event.preventDefault}>
                  <span className="active" >{t('application.uploadBtn')}</span>
              </span>
            <input id="fileSelectionInput" type="file" ref={filesSelectedInputRef} onChange={onFileAdded}
                   multiple={props.multiple} className="multiple-files" aria-label={t('application.ariaLabel')}
                   accept=".pdf"  style={{ display: 'none' }} tabIndex={0}/>
          </div>
        </FormFieldset>
        {(fileSelectErrorMessages.length > 0) &&
          <VarningsRuta ariaTitle={"Valideringsmeddelanden"}>
            {fileSelectErrorMessages.map((fileSelectErrorMessage, index) =>
              <p key={index} className="mandatory">
                {fileSelectErrorMessage.maxNrAllowed ?
                t(fileSelectErrorMessage.errorMessage, {maxNrAllowed: fileSelectErrorMessage.maxNrAllowed.toString()}) :
                t(fileSelectErrorMessage.errorMessage)}
              </p>
            )}
          </VarningsRuta>
        }
      </>
    )
}

export default (UploadFilesApplication)
