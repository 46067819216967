import { AnsokanListItemType } from "domain/AnsokanListItemType";
import {DINA_PAGAENDE_LANTMATERIFORRATTNINGAR_HEADER, ISO8601} from "utils/globalConstants";
import {
  // @ts-ignore
  ListItem, ListItemActionButton, LinkButtonText,
  ModalDialogAlert
} from "redet-react-components";
import { getLocalizedDateString } from "helpers/dates";
import { undefinedAsString } from "helpers/textHelpers";
import { Link } from "react-router-dom";
import {useState} from "react";
import Term from "../../../../views/Hjalp/TermLank";

type InskickadAnsokanProps = {
  ansokan: AnsokanListItemType;
};

export const InskickadAnsokanListItem = (props: InskickadAnsokanProps) => {
  const [showErrorModal, setShowErrorModal] = useState(false)
  const openPdf = (ansokanId: string): void => {
    fetch(`/api/ansokan-service/ansokan/${ansokanId}/pdf`).then(response => {
      if(response.status !== 200)
        throw new Error("Kunde inte hämta ansökandokument.")
      return response.blob()
    }).then(blob => {
      const pdfURL = window.URL.createObjectURL(blob);
      window.open(pdfURL,"_blank", "noopener,noreferrer")
    }).catch(() => {
      setShowErrorModal(true)
    })
  };

  const pdfButton = (
    <ListItemActionButton
      ariaLabel={"Ladda ned"}
      icon={"file_download"}
      id={`btn_inskickad_ansokan_${props.ansokan.ansokanId}`}
      key={`pdf_btn_${props.ansokan.ansokanId}`}
      popover={{ text: "Ladda ned ansökan som PDF" }}
      target={"_blank"}
      onClick={() => openPdf(props.ansokan.ansokanId)}
    />
  );

  const getModifiedDateAsText = (ansokan: any): string => {
    return undefinedAsString(
      getLocalizedDateString(
        ansokan.ansokanStatusModified.toISOString(),
        ISO8601
      )
    );
  };

  return (
      <>
        <ListItem
          header={
            <LinkButtonText text={`Ansökan ${getLocalizedDateString(props.ansokan.createdDate.toISOString(), ISO8601)}`}
                            onClick={() => openPdf(props.ansokan.ansokanId)}/>}
          description={`Aktualitetsdatum: ${getModifiedDateAsText(props.ansokan)}`}
          status={{
            icon: "schedule",
            text: `Status: Inskickad`,
          }}
          id={`ansokan_${props.ansokan.ansokanId}`}
          key={`inskickad_key_ansokan_item_${props.ansokan.ansokanId}`}
          actionButtons={[pdfButton]}
      />
        {showErrorModal &&
            <ModalDialogAlert modalTitle={"Kunde inte hämta ansökandokumentet"}
                              description={<>Ifall du är en del av denna ansökan kan du hitta den under '{DINA_PAGAENDE_LANTMATERIFORRATTNINGAR_HEADER}'. Annars vänd dig till <Term label={'Lantmäteriets kundcenter'} moment={'help-support'} />.</>}
                              acceptButton={{text: "Stäng", icon: "clear",
            }}
                           closeModalCallback={() => setShowErrorModal(false)}
        />}
      </>
  );
};
