import { filesEqual } from "helpers/fileHelpers"
import { MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL, MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING } from "utils/globalConstants"
import { logInfo } from "utils/log"
import i18n from 'i18next';

export type ValidationError = {
  errorMessage: string
  maxNrAllowed?: number
}

export function filterFilesBySize (files: File[]) {
    let filteredFiles = files
    let errorMessage: ValidationError | null = null;
    const maxFileSizeInBytes = MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL * 1024 * 1024

    if (filteredFiles.some((file: File) => file.size > maxFileSizeInBytes)) {
      errorMessage = {errorMessage: 'errorMsg.size', maxNrAllowed: MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL}
      filteredFiles = files.filter((file: File) => file.size <= maxFileSizeInBytes)
    }
    return { filteredFiles, errorMessage }
  }

  export function filterFilesByAllowedTypes (files: File[], allowedType: string[]) {

    const filteredFiles = files.filter((file: File) => file.type && allowedType.includes(file.type))
    let errorMessage: ValidationError | null = null;

    if (filteredFiles.length !== files.length) {
      errorMessage = {errorMessage: 'errorMsg.type'}
    }

    return {filteredFiles, errorMessage }
  }

  export function filterFilesByCount (files: File[], selectedFiles: File[], nrOfFilesAllowed: number) {
    let filteredFiles = files
    let errorMessage: ValidationError | null = null;
  
    if ((selectedFiles.length + files.length) > nrOfFilesAllowed) {
      errorMessage = {errorMessage: 'errorMsg.count', maxNrAllowed: nrOfFilesAllowed}
      filteredFiles = files.slice(0, 1 - selectedFiles.length)
      logInfo(i18n.t('errorMsg.count', {maxNrAllowed: nrOfFilesAllowed.toString()}))
    }
    return { filteredFiles, errorMessage }
  }
 
  export function filterFilesByDuplicates (files: File[], selectedFiles: File[]) {
    const filteredFiles: File[] = []
    let errorMessage: ValidationError | null = null;
    files.forEach((file: File) => {
      // Only add the files that aren't already added and filter out any duplicated files selected (though this should have been prevented by the OS already)
      if (!(selectedFiles.some((selectedFile: File) => filesEqual(selectedFile, file)) || filteredFiles.some(filteredFile => filesEqual(filteredFile, file)))) {
        filteredFiles.push(file)
      } else {
        errorMessage = {errorMessage: 'errorMsg.duplicate'}
      }
    })
    return { filteredFiles, errorMessage }
  }

  export function filterFilesByAccumulatedSize (files: File[]) {
    const filteredFiles: File[] = []
    let errorMessage: ValidationError | null = null;
    const maxTotalFileSizeInBytes = MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING * 1024 * 1024

    files.forEach(file => {
      const alreadyAddedFilesTotalSize = files.map(selectedFile => selectedFile.size).reduce((x, y) => x + y, 0)
      const filesAlreadyPassedFilterTotalSize = filteredFiles.map(filePassed => filePassed.size).reduce((x, y) => x + y, 0)
      if ((alreadyAddedFilesTotalSize + filesAlreadyPassedFilterTotalSize + file.size) <= maxTotalFileSizeInBytes) {
        filteredFiles.push(file)
      } else {
        errorMessage = {errorMessage: 'errorMsg.size', maxNrAllowed: MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING}
      }
    })

    return { filteredFiles, errorMessage }
  }

export function formatFileSize (filesize: number) {
  let formatedOutput = ''

  if (filesize >= (1024 * 1024)) {
    formatedOutput= Math.round((filesize * 10) / (1024 * 1024)) / 10 + 'MB'
  } else if (filesize >= 1024) {
    formatedOutput = Math.round(filesize / 1024) + 'KB'
  } else {
    formatedOutput = filesize + 'B'
  }

  return formatedOutput
}