import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as interactions from '../interactions'
import { connect } from 'react-redux'
import { selectTool } from 'redux/modules/map'
import { DrawToolsMenu } from './DrawToolsMenu'
import { ChangeToolsMenu } from './ChangeToolsMenu'
import { MeasureToolsMenu } from './MeasureToolsMenu'
import { visaTerm } from '../../../redux/modules/help'
import { MapButton, MapDropdown, MapMenu } from 'redet-react-components'

const mapStateToProps = (store) => {
  return {
    activeTool: store.map.activeTool,
    hasFeatures: store.map.hasFeatures
  }
}

const mapDispatchToProps = {
  selectTool,
  visaTerm
}

export const saveMapButtonText = 'Slutför kartskiss'

class Toolbar extends Component {
  static propTypes = {
    selectTool: PropTypes.func.isRequired,
    activeTool: PropTypes.string.isRequired,
    hasFeatures: PropTypes.bool.isRequired,
    onSaveMapClick: PropTypes.func.isRequired,
    onDiscardChangesClick: PropTypes.func.isRequired,
    cropMode: PropTypes.bool.isRequired,
  }

  constructor (props, context) {
    super(props, context)
    this.activateTool = this.activateTool.bind(this)
  }

  componentDidUpdate () {
    // If there are no drawn features disable the modification tools
    if (!this.props.hasFeatures) {
      switch (this.props.activeTool) {
        case interactions.DELETE:
        case interactions.MOVE:
        case interactions.MODIFY:
          this.props.selectTool(interactions.PAN)
          break
        default:
        // deactivate only buttons that can be disabled
      }
    }
  }

  hide (id) {
    document.getElementById(id).style.visibility = 'hidden'
  }

  activateTool (interaction) {
    this.props.selectTool(interaction)
  }

  isPartOfRitaGroup (tool) {
    const groupRita = [interactions.POINT, interactions.LINE_STRING, interactions.POLYGON, interactions.TEXT]
    return groupRita.find((toolInList) => {
      return tool === toolInList
    })
  }

  isPartOfAndraGroup (tool) {
    const groupAndra = [interactions.MODIFY, interactions.MOVE, interactions.DELETE]
    return groupAndra.find((toolInList) => {
      return tool === toolInList
    })
  }

  isPartOfMataGroup (tool) {
    const groupMata = [interactions.MEASURE_LINE, interactions.MEASURE_POLYGON]
    return groupMata.includes(tool)
  }

  render () {
    return (
      <MapMenu isBottomAligned={true}>
        <MapButton
          text={'Panorera'}
          isActive={this.props.activeTool === interactions.PAN}
          icon={'pan_tool'}
          onClick={() => {this.props.selectTool(interactions.PAN)}}/>
        <MapDropdown
          text={'Rita'}
          icon={'brush'}
          aria-expanded={this.isPartOfRitaGroup(this.props.activeTool)}
          isDisabled={this.props.cropMode}>
          <DrawToolsMenu activeTool={this.props.activeTool} onClickToolCallback={this.activateTool}/>
        </MapDropdown>
        <MapDropdown
          text={'Ändra'}
          icon={'build'}
          aria-expanded={this.isPartOfAndraGroup(this.props.activeTool)}
          isDisabled={this.props.cropMode}>
          <ChangeToolsMenu activeTool={this.props.activeTool} onClickToolCallback={this.activateTool}
                           hasFeatures={this.props.hasFeatures}/>
        </MapDropdown>
        <MapDropdown
          text={'Mät'}
          icon={'straighten'}
          aria-expanded={this.isPartOfMataGroup(this.props.activeTool)}
          isDisabled={this.props.cropMode}>
          <MeasureToolsMenu activeTool={this.props.activeTool} onClickToolCallback={this.activateTool}/>
        </MapDropdown>
        <MapButton
          text={saveMapButtonText}
          isActive={this.props.hasFeatures ? this.props.hasFeatures : false}
          isDisabled={this.props.cropMode}
          icon={'check_circle'}
          data-selenium-id="toolbarSpara"
          id="toolbarSpara"
          onClick={() => {
            this.props.onSaveMapClick()
          }}/>
        <MapButton
          text={'Avbryt'}
          isDisabled={this.props.cropMode}
          icon={'cancel'}
          data-selenium-id="toolbarStang"
          id="toolbarStang"
          onClick={() => {
            this.props.onDiscardChangesClick()
          }}/>
        <MapButton
          text={'Hjälp'}
          icon={'help'}
          id="mapToolbarHelp"
          onClick={() => {
            this.props.visaTerm('help-map')
          }}/>
      </MapMenu>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
