import PropTypes from "prop-types";
import React, {useState} from "react";
// @ts-ignore
import {ButtonToolbar, CircleLoading, LinkButtonTop, TileWizardDialog} from "redet-react-components";

type Props = {
    closeModalCallback: () => void,
    signingUrl: string,
    attachToDomElementWithId?: string
}

export function YrkandeUnderskriftWizardModal(props: Props) {

    const [activeIndex, setActiveIndex] = useState(0)
    const goToUnderskriftButtonId = 'goToSigningBtn'

    const getTiles = (): any[] => [
        {
            title: 'Steg 1',
            pendingDescription: (
                <>
                    För att skriva under yrkandet klickar du på{' '}
                    <strong>Gå till underskrift</strong>, en ny flik öppnas
                    där du skriver under.
                </>
            ),
            children: (<>
                <ButtonToolbar>
                    <LinkButtonTop
                        text='Gå till underskrift'
                        link={props.signingUrl}
                        isExternalLink={true}
                        isOpenedInNewTab={true}
                        icon="open_in_new"
                        id={goToUnderskriftButtonId}
                        onClick={() => setActiveIndex(1)}
                    ></LinkButtonTop>
                </ButtonToolbar>
            </>),
        },
        {
            title: 'Steg 2',
            pendingDescription: (
                <>
                    Skriv under yrkandet, kom ihåg att granska handlingen.
                    När du väljer att skriva under gör du det genom ytterligare en legitimering med hjälp av din e-legitimation.
                    När underskriften är färdig kan du stänga den nya fliken och fortsätta här.
                </>
            ),
            children: (<>
                <CircleLoading text="Underskrift pågår..." isFocusTrapped={false} id="underskriftModalLoader"/>
            </>)
        }
    ]

    return (<TileWizardDialog
        id="yrkandeUnderskriftWizard"
        title="Skriv under"
        description={<>
            Du behöver nu skriva under. Om du
            inte har tillgång till BankID eller om du av någon anledning behöver
            avbryta klickar du på <strong>Avbryt underskrift</strong> längst ned i
            den här rutan.
        </>}
        activeIndex={activeIndex}
        tiles={getTiles()}
        closeModalCallback={props.closeModalCallback}
        cancelButtonText="Avbryt underskrift"
        firstFocus={goToUnderskriftButtonId}
        attachToDomElementWithId={props.attachToDomElementWithId}
    ></TileWizardDialog>)
}

YrkandeUnderskriftWizardModal.propTypes = {
    closeModalCallback: PropTypes.func.isRequired,
    signingUrl: PropTypes.string.isRequired,
    attachToDomElementWithId: PropTypes.string
}