import { useState } from 'react'
import { BIFOGADHANDLINGTYPE, BIFOGADHANDLINGTYPE_NOT_SELECTED } from 'utils/globalConstants'
import { Term } from '../../index'
//@ts-ignore
import { ButtonTop, FormFieldset, Select, TextArea, VarningsRuta, WizardNavigationButtonToolbar } from 'redet-react-components'

type Props = {
  selectAttachmentTypeCallback: (evt: string) => void,
  setOvrigtBeskrivningCallback: (ovrigtBeskrivning: string) => void,
  typeOptions: typeof BIFOGADHANDLINGTYPE,
  showAttachmentTypeDescription: boolean,
  attachmentType?: string,
  ovrigtBeskrivning: string,
  helpChapter: string,
  nextStepButtonText?: string,
  nextStepButtonAriaLabel?: string,
  nextStep?: () => void // Denna prop kommer in via WixzardDialog på något sätt
}

ValjTypStep1Wizard.defaultProps = {
  showAttachmentTypeDescription: true
}

export function ValjTypStep1Wizard(props: Props) {

  const [attachmentType, setAttachmentType] = useState<string>(props.attachmentType || BIFOGADHANDLINGTYPE_NOT_SELECTED);
  const [attachmentTypeHelpTerm, setAttachmentTypeHelpTerm] = useState<string>('help-upload');
  const [ovrigtBeskrivning, setOvrigtBeskrivning] = useState<string>(props.ovrigtBeskrivning);

  function checkTextOvrigtAnteckningForInvalidChars (text: string) {
    return text.replace(/[^[A-Za-zåÅäÄöÖ0-9 ;_\-=,:.!"@#$%&/()[\]{}?*'+]/g, '')
  }

  function getHelpTermFromType (type: string) {
    const handlingType = props.typeOptions.find(obj => obj.value === type)
    if (handlingType !== undefined) { // This is only needed for stupid IE which doesn't hide the select list option even though it has the hidden tag
      setAttachmentTypeHelpTerm(handlingType.helpTerm ? handlingType.helpTerm : 'help-upload')
    } else {
      setAttachmentTypeHelpTerm('help-upload')
    }
  }

  function ovrigtBeskrivningOnChange (evt: React.ChangeEvent<HTMLInputElement>) {
    setOvrigtBeskrivning(checkTextOvrigtAnteckningForInvalidChars(evt.target.value))
  }

  function onSelectType (evt: React.ChangeEvent<HTMLInputElement>) {
    setAttachmentType(evt.target.value);
    getHelpTermFromType(evt.target.value);
    props.selectAttachmentTypeCallback(evt.target.value);
    if (evt.target.value !== 'ANNAT') {
      setOvrigtBeskrivning('');
    }
  }

    return (
      <>
        <FormFieldset title={<>
          Välj den <Term label="typ"
                         moment={props.helpChapter}
                         term={attachmentTypeHelpTerm}/> som beskriver handlingen du vill bifoga.</>}>
          <Select id={'attachmentType'} label={'Vilken typ av handling vill du bifoga?'}
                  value={attachmentType}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onSelectType(evt)}
                  options={props.typeOptions}
                  initalDescriptiveOption={{
                    key: BIFOGADHANDLINGTYPE_NOT_SELECTED,
                    value: BIFOGADHANDLINGTYPE_NOT_SELECTED,
                    description: 'Inget valt'
                  }}/>
        </FormFieldset>
        {attachmentType === 'ANNAT' &&
          <div>
            <TextArea id="ovrigtAnnatBeskrivning" characterLimit={500} label={'Beskrivning'} initialText={ovrigtBeskrivning}
                      maxLength={500} rows={3}
                      onChange={(evt) => {
                        ovrigtBeskrivningOnChange(evt)
                      }}/>
            <br/>
            <VarningsRuta ariaTitle={"Information om handlingtypen annat"}>
              {BIFOGADHANDLINGTYPE.find(handling => handling.value === 'ANNAT')?.mdRef}
            </VarningsRuta>
          </div>
        }
        {attachmentType !== 'ANNAT' && attachmentType !== BIFOGADHANDLINGTYPE_NOT_SELECTED && props.showAttachmentTypeDescription &&
          <VarningsRuta ariaTitle={"Information om handlingtyp"}>
            {BIFOGADHANDLINGTYPE.find(handling => handling.value === attachmentType)?.mdRef}
          </VarningsRuta>
        }
        <WizardNavigationButtonToolbar>
          <ButtonTop text={props.nextStepButtonText}
                     onClick={() => {
                       props.setOvrigtBeskrivningCallback(ovrigtBeskrivning)
                       if(props.nextStep){props.nextStep()};
                      }
                    }
                     ariaLabel={props.nextStepButtonAriaLabel}
                     isDisabled={attachmentType === BIFOGADHANDLINGTYPE_NOT_SELECTED}
                     icon={'arrow_forward'}
                     id="uploadFormDisplayNextStepButton"/>
        </WizardNavigationButtonToolbar>
      </>

    )
}

