import {Aktbilagebeteckning, Epost, Telefonnummer} from "../../../domain/Common";


export class Aterkallande {
    arendenummer: string
    meddelande: string
    avsandare: string
    epostKvittens: Epost
    epostKontakt: Epost
    telefon: Telefonnummer
    aktbilagebeteckning?: Aktbilagebeteckning


    constructor(arendenummer: string, meddelande: string, avsandare: string, epostKvittens: Epost, epostKontakt: Epost, telefon: Telefonnummer, aktbilagebeteckning?: Aktbilagebeteckning) {
        this.arendenummer = arendenummer;
        this.meddelande = meddelande;
        this.avsandare = avsandare;
        this.epostKvittens = epostKvittens;
        this.epostKontakt = epostKontakt;
        this.telefon = telefon;
        this.aktbilagebeteckning = aktbilagebeteckning;
    }
}

export class AterkallandeRequest {
    arendenummer: string;
    meddelande: string;
    avsandare: string;
    epostKvittens: string;
    epostKontakt?: string;
    telefon?: string;
    aktbilagebeteckning?: string[];

    constructor(aterkallande: Aterkallande) {
        this.arendenummer = aterkallande.arendenummer;
        this.meddelande = aterkallande.meddelande;
        this.avsandare = aterkallande.avsandare;
        this.epostKvittens = aterkallande.epostKvittens.epost;
        this.epostKontakt = aterkallande.epostKontakt?.epost;
        this.telefon = aterkallande.telefon?.nummer;
        this.aktbilagebeteckning = aterkallande.aktbilagebeteckning?.aktbilagebeteckning;
    }
}

export type AterkallandeRender = {
    arendenummer: string;
    meddelande: string;
    avsandare: string;
    epostKvittens: string;
    epostKontakt: string;
    telefon: string;
    aktbilagebeteckning?: string[];
}

export type AterkallandeKvittensResponse = {
    inskickadDatum: string;
    kvittensEmailSuccessfullySent: boolean
}
