import fullscreenLoading from './modules/fullscreenLoading'
import fullscreenProgress from './modules/fullscreenProgress'
import modalDialog from './modules/modalDialog'
import log from './modules/log'
import auth from './modules/auth'
import help from './modules/help'
import ansokanList from './modules/ansokanList'
import featureToggle from './modules/featureToggle'
import ansokanCrud from './modules/ansokanCRUD'
import ansokanSpec from './modules/ansokanSPECIAL'
import reduceReducers from 'reduce-reducers'
import wizardDialog from './modules/wizardDialog'
import bifogadHandling from './modules/bifogadHandling'
import kartskiss from './modules/kartskiss'
import map from './modules/map'
import fastigheter from './modules/fastigheter'
import contacts from './modules/contacts'
import arendeList from './modules/arendeList'
import arendecommon, {initialState as arendeCommonInitialState} from './modules/arendecommon'
import arende from './modules/arende'
import arendesvarslistor from './modules/arendesvarslistor'
import fastighetsinnehav from './modules/fastighetsinnehav-reducer'
import validation from './modules/validation/validation'
import signing from './modules/signing'
import feedback from './modules/feedback'
import arendeKompletteringar from './modules/arendeKompletteringar'
import bifogadHandlingKomplettering from './modules/kompletteringBifogaHandling'
import ansokanKomplettering from './modules/ansokanKomplettering'
import bifogadKartskiss from './modules/bifogadKartskiss'
import browserUpdate from './browserUpdate'
import featureToggleReducer from './../components/featureToggle/featureToggleReducer'
import notisReducer from './../components/notis/notisReducer'
import handlingarMetadata from './modules/handlingarMetadata'

export const allOurReducers =
{
    modalDialog,
    fullscreenLoading,
    fullscreenProgress,
    log,
    // @ts-ignore
    ansokanRepository: reduceReducers(ansokanCrud, ansokanSpec), // reduceReducers, slår ihop två reducers platt. combineReducers, gör samma fast skapar två olika grenar.
    auth,
    help,
    ansokanList,
    featureToggle,
    toggles: featureToggleReducer,
    notiser: notisReducer,
    wizardDialog,
    bifogadHandling,
    handlingarMetadata,
    map,
    kartskiss,
    contacts,
    fastigheter,
    arendeList,
    // @ts-ignore
    arende: reduceReducers<arendeCommonInitialState>(arendecommon, arende, arendesvarslistor),
    fastighetsinnehav,
    validation,
    signing,
    feedback,
    arendeKompletteringar,
    bifogadHandlingKomplettering,
    ansokanKomplettering,
    bifogadKartskiss,
    browserUpdate,
}
