import {useState} from "react";
// @ts-ignore
import {Felruta, ListGroup, ModalDialogAlert, ModalDialogSub,} from "redet-react-components";
import SmallLoading from "components/General/SmallLoading";
import {AnsokanListItem} from "./AnsokanListItem";
import {AnsokanListItemType} from "domain/AnsokanListItemType";
import {ANSOKAN_STATUS_SUBMITTED} from "utils/globalConstants";
import {InskickadAnsokanListItem} from "./InskickadAnsokanListItem";

type AnsokanListProps = {
    ansokningarIsLoading: boolean;
    ansokningarListLoadFailed: boolean;
    deleteAnsokan: Function;
    ansokningarList: Array<any>;
};

export const AnsokanList = (props: AnsokanListProps) => {
    const [ansokanToDelete, setAnsokanToDelete] =
        useState<string | undefined>(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const toggleDeleteModal = (id?: string, show = true) => {
        setAnsokanToDelete(id);
        setShowDeleteModal(show);
    };

    // @ts-ignore
    return (
        <>
            <div id={"ansökan-list"}>
                {props.ansokningarIsLoading && (
                    <SmallLoading text={"Laddar dina ansökningar"}></SmallLoading>
                )}

                {props.ansokningarListLoadFailed &&
                  <Felruta errorMessages={["Ett fel inträffade och dina ansökningar gick inte att ladda."]}/>}

                {!props.ansokningarListLoadFailed &&
                props.ansokningarList.length === 0 &&
                !props.ansokningarIsLoading ? (
                    <p className="with-top-margin">
                        Du har inte påbörjat några ansökningar ännu.
                    </p>
                ) : (
                    ""
                )}

                {!props.ansokningarIsLoading && props.ansokningarList.length > 0 && (
                    <ListGroup
                        title={"Ansökningar"}
                        id="ansokningar_lista"
                        headerLevel={3}
                    >
                        {props.ansokningarList
                            .slice()
                            .sort((current, next) => current.createdDate - next.createdDate)
                            .sort((current) => current.ansokanStatus === ANSOKAN_STATUS_SUBMITTED ? 1 : -1)
                            .map((ansokan: AnsokanListItemType) => {
                                if (ansokan.ansokanStatus === ANSOKAN_STATUS_SUBMITTED) {
                                    return (
                                        <InskickadAnsokanListItem
                                            ansokan={ansokan}
                                            key={ansokan.ansokanId}
                                        />
                                    );
                                }

                                return (
                                    <AnsokanListItem
                                        ansokan={ansokan}
                                        deleteAnsokanCallback={toggleDeleteModal}
                                        key={ansokan.ansokanId}
                                    />
                                );
                            })}
                    </ListGroup>
                )}
            </div>
            {showDeleteModal && (
                <ModalDialogSub
                    modalTitle={"Ta bort påbörjad ansökan"}
                    ingress={"Vill du ta bort den här ansökan?"}
                    acceptButton={{
                        text: "Ja",
                        icon: "check",
                        onClick: () => props.deleteAnsokan(ansokanToDelete),
                    }}
                    declineButton={{text: "Nej", icon: "clear"}}
                    closeModalCallback={() => toggleDeleteModal(undefined, false)}
                />
            )}
            {showErrorModal && (
                <ModalDialogAlert
                    modalTitle={"Tekniskt fel"}
                    ingress={"Ett tekniskt fel uppstod när en ansökan skulle raderas."}
                    description={
                        <span>
                            Vänligen försök igen om en liten stund. Skulle problemet kvarstå
                            vänligen kontakta{" "}
                            <a href="https://www.lantmateriet.se/kontakt">kundcenter</a>.
                        </span>
                    }
                    acceptButton={{
                        text: "Ok",
                        icon: "check",
                        // @ts-ignore
                        onClick: () => setShowErrorModal(false),
                    }}
                ></ModalDialogAlert>
            )}
        </>
    );
};
