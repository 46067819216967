export function removeInvalidCharsFromText(text: string) {
    // eslint-disable-next-line no-useless-escape
    return text.replace(/[^[A-Za-zåÅäÄöÖ0-9 ;_\-=,:.!"@#\$%&\/\(\)\[\]{}?\*'+]/g, "");
}

export function removeWhiteSpaces(text: string): string {
    if (text !== undefined && text !== null) {
        return text.replace(/ /g, "");
    }
    return text;
}

export function undefinedAsString(input?: any, as = ""): string {
  if (input === undefined) {
    return as;
  }
  return input;
}
