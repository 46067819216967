// @flow
import { BLOCKING, MINST_EN_FORETRADARE_VID_OMBUD } from './constants'
import { getNamn } from '../../../../utils/contactsHelper'
import { Validation } from './model/Validation'

function createError (invalidContacts): ?Array<Validation> {
  const invalidContactsName = invalidContacts.map(contacts => getNamn(contacts))
  if (invalidContacts) {
    return invalidContactsName.map((name) => (new Validation(MINST_EN_FORETRADARE_VID_OMBUD, `Ombudet ${name} måste företräda någon.`, 'contacts', BLOCKING, undefined)))
  }
  return undefined
}

const ombudUtanForetradare = contact => {
  if (!contact.data.rollOmbud) {
    return false
  }
  if (contact.type === 'contacts/CONTACT_TYPE_INDIVIDUAL') {
    return !contact.data.ombudForPersoner || contact.data.ombudForPersoner?.length < 1
  }
}

function validate (state: Object): ?Array<Validation> {
  const contacts = state.contacts.contactList

  const invalidContacts = contacts.filter(ombudUtanForetradare)

  if (invalidContacts.length > 0) {
    return createError(invalidContacts,)
  }
  return undefined
}

export default validate
