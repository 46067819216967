import {load, LoadArendeRequest} from 'repository/arendeRepository'
import {fakeLoad} from 'repository/testdata/fakeArendeRepository'
import {ARENDE_STATUS, ROLL_SOKANDE} from 'utils/globalConstants'
import {Fetcher} from '../../repository/fetcher'
import {Severity} from "redux/modules/model/helpText"
import {Dispatch, Store} from "redux";
import {RootState} from "../../store";
import {Forrattningsarende} from "domain/Forratningsarende/Forratningsarende";
import {DocumentDetails} from "domain/Forratningsarende/DocumentDetails";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {KompletteringAtgard} from "../../domain/Forratningsarende/KompletteringAtgard";
import {useSelector} from "react-redux";
import {Arendetyp} from "../../domain/Arende";

const ARENDE_AVSLUTAT = [ARENDE_STATUS.SLUTFORT, ARENDE_STATUS.REGISTRERING]
const ARENDE_TILLDELAT = [ARENDE_STATUS.UTREDNING_PROVNING, ARENDE_STATUS.BESLUT_TAGET]
const ARENDE_UTREDNING_PROVNING = [ARENDE_STATUS.UTREDNING_PROVNING]

export const initialState: ArendeState = {
  arende: undefined,
  shouldDisplayAtgarder: false,
  isLoading: false
}

export type ArendeState = {
  arende?: Forrattningsarende,
  shouldDisplayAtgarder: boolean,
  isLoading: boolean,
  uploadHandlingKompletteringAtgard?: KompletteringAtgard,
  yrkandeAtgard?: KompletteringAtgard,
  bitradandeKompletteringAtgard?: KompletteringAtgard,
  skrivelseKompletteringAtgard?: KompletteringAtgard,
  aterkallandeKompletteringAtgard?: KompletteringAtgard,
}

type LoadArendeSuccessPayload = {
  result: Forrattningsarende,
  isOrganization: boolean,
  asHandlaggare: boolean,
  shouldDisplayAtgarder: boolean,
  uploadHandlingKompletteringAtgard: KompletteringAtgard,
  yrkandeAtgard: KompletteringAtgard,
  bitradandeKompletteringAtgard: KompletteringAtgard,
  skrivelseKompletteringAtgard: KompletteringAtgard
  aterkallandeKompletteringAtgard: KompletteringAtgard
}

export const arendeSlice = createSlice({
  name: 'arende',
  initialState: initialState,
  reducers: {
    loadArendeAction(state, action: PayloadAction) {
      state.isLoading = true
    },
    loadArendeSuccessAction(state, action: PayloadAction<LoadArendeSuccessPayload>) {
      state.arende = action.payload.result
      state.isLoading = false
      state.shouldDisplayAtgarder = action.payload.shouldDisplayAtgarder
      state.uploadHandlingKompletteringAtgard = action.payload.uploadHandlingKompletteringAtgard
      state.yrkandeAtgard = action.payload.yrkandeAtgard
      state.bitradandeKompletteringAtgard = action.payload.bitradandeKompletteringAtgard
      state.skrivelseKompletteringAtgard = action.payload.skrivelseKompletteringAtgard
      state.aterkallandeKompletteringAtgard = action.payload.aterkallandeKompletteringAtgard
    },
    loadArendeFailAction(state, action: PayloadAction) {
      state.arende = undefined
      state.isLoading = false
    }
  }
})

export function loadArende (loadArendeRequest: LoadArendeRequest, isOrganization: boolean) {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {

    dispatch(loadArendeAction())

    const fetcher = new Fetcher(dispatch, getState, false)
    return (getState().featureToggle.FRISSE_SERVICE_MOCK_ENABLED ? fakeLoad() : load(fetcher, loadArendeRequest))
      .then((arende: Forrattningsarende) => {
        const loadArendeSuccessPayload = loadArendeSuccess(arende, isOrganization, loadArendeRequest.asHandlaggare, getState())
        dispatch(loadArendeSuccessAction(loadArendeSuccessPayload))
        return arende
      }).catch((error: any) => {
        dispatch(loadArendeFailAction())
        throw error
      })
  }
}

export function loadArendeSuccess (arende: Forrattningsarende, isOrganization: boolean, asHandlaggare: boolean, store: RootState): LoadArendeSuccessPayload {
  return {
    result: arende,
    isOrganization: isOrganization,
    asHandlaggare: asHandlaggare,
    shouldDisplayAtgarder: !isOrganization && !asHandlaggare,
    uploadHandlingKompletteringAtgard: {
      active: !ARENDE_AVSLUTAT.includes(arende.fastighetsArendeOversikt.status),
      inactiveReasons: [{severity: Severity.INFORMATION, message: 'Du kan inte lägga till ytterligare handlingar eftersom ärendet är avslutat'}]
    },
    yrkandeAtgard: {
      active: !ARENDE_AVSLUTAT.includes(arende.fastighetsArendeOversikt.status) &&
        (arende.fastighetsArendeOversikt.mottagandeMyndighet === 'Lantmäteriet'), // Ta bort detta villkor när även ärenden som handläggs av KLM ska kunna skicka in yrkande.
      inactiveReasons: getInactiveReasonsForYrkandeAtgard(arende)
    },
    bitradandeKompletteringAtgard: {
      active: !ARENDE_AVSLUTAT.includes(arende.fastighetsArendeOversikt.status) &&
        arende.fastighetsArendeOversikt.roller.includes(ROLL_SOKANDE) &&
        !documentListEmptyOrMissingAnsokan(arende.documentDetails) &&
        (arende.fastighetsArendeOversikt.mottagandeMyndighet === 'Lantmäteriet'), // Ta bort detta villkor när även ärenden som handläggs av KLM ska kunna kompletteras med biträdanden.
      inactiveReasons: getInactiveReasonsForBitradandeKompletteringAtgard(arende)
    },
    skrivelseKompletteringAtgard: {
      active: !ARENDE_AVSLUTAT.includes(arende.fastighetsArendeOversikt.status) && ARENDE_TILLDELAT.includes(arende.fastighetsArendeOversikt.status) &&
          (arende.fastighetsArendeOversikt.mottagandeMyndighet === 'Lantmäteriet') && // Ta bort detta villkor när även ärenden som handläggs av KLM ska kunna kompletteras med skrivelse
          (arende.fastighetsArendeOversikt.tillatXmlInskick === true),
      inactiveReasons: getInactiveReasonsForSkrivelseAtgard(arende)
    },
    aterkallandeKompletteringAtgard: {
      active: ARENDE_UTREDNING_PROVNING.includes(arende.fastighetsArendeOversikt.status) &&
          arende.fastighetsArendeOversikt.roller.includes(ROLL_SOKANDE) &&
          (arende.fastighetsArendeOversikt.arendetyp === Arendetyp.F) &&
          (arende.fastighetsArendeOversikt.mottagandeMyndighet === 'Lantmäteriet') && // Ta bort detta villkor när även ärenden som handläggs av KLM ska kunna återkallas
          (arende.fastighetsArendeOversikt.tillatXmlInskick === true),
      inactiveReasons: [] // Hela 'Återkalla ansökan'-sektionen döljs om inte ovanstående villkor uppfylls, så vi behöver inte visa några meddelanden.
    },
  }
}

function getInactiveReasonsForYrkandeAtgard (arende: Forrattningsarende) {
  let inactiveReasons = []
  if (ARENDE_AVSLUTAT.includes(arende.fastighetsArendeOversikt.status)) {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte skicka in ett yrkande eftersom ärendet är avslutat'})
    return inactiveReasons // Om ärendet är avslutat behöver inte resterande meddelanden visas.
  }

  if (arende.fastighetsArendeOversikt.mottagandeMyndighet !== 'Lantmäteriet') {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte skapa ett yrkande eftersom ärendet inte handläggs av Lantmäteriet'})
  }
  return inactiveReasons

}

function getInactiveReasonsForSkrivelseAtgard (arende: Forrattningsarende) {
  let inactiveReasons = []
    if (ARENDE_AVSLUTAT.includes(arende.fastighetsArendeOversikt.status)) {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte skicka in ett meddelande eftersom ärendet är avslutat'})
    return inactiveReasons // Om ärendet är avslutat behöver inte resterande meddelanden visas.
  }

  if(!ARENDE_TILLDELAT.includes(arende.fastighetsArendeOversikt.status)) {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte skicka in ett meddelande eftersom ärendet inte har tilldelats en handläggare än'})
    return inactiveReasons // Om ärendet inte har fått status handläggning så ska det inte gå att skicka in en skrivelse
  }

  if (arende.fastighetsArendeOversikt.mottagandeMyndighet !== 'Lantmäteriet') {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte skapa ett meddelande eftersom ärendet inte handläggs av Lantmäteriet'})
  }

  if (!arende.fastighetsArendeOversikt.tillatXmlInskick) {
    inactiveReasons.push({severity: Severity.INFORMATION, message: 'Du kan inte skapa ett meddelande för detta ärende.'})
  }
  return inactiveReasons

}

function getInactiveReasonsForBitradandeKompletteringAtgard (arende: Forrattningsarende) {
  let inactiveReasons = []

  if (ARENDE_AVSLUTAT.includes(arende.fastighetsArendeOversikt.status)) {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte bjuda in medsökande eftersom ärendet är avslutat'})
    return inactiveReasons // Om ärendet är avslutat behöver inte resterande meddelanden visas.
  }

  if (!arende.fastighetsArendeOversikt.roller.includes(ROLL_SOKANDE)) {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte bjuda in medsökande eftersom du inte är sökande i ärendet'})
  }

  if (documentListEmptyOrMissingAnsokan(arende.documentDetails)) {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte bjuda in medsökande eftersom ärendet saknar en handling av typen Ansökan'})
  }

  if (arende.fastighetsArendeOversikt.mottagandeMyndighet !== 'Lantmäteriet') {
    inactiveReasons.push({severity: Severity.INFORMATION, message:'Du kan inte bjuda in medsökande eftersom ärendet inte handläggs av Lantmäteriet'})
  }

  return inactiveReasons
}

function documentListEmptyOrMissingAnsokan(documentList: DocumentDetails[]) {
  const foundDocument = documentList.find((document) =>
          // Se MappningsTabellDokumentTyp i forrattningsinfo-service
          document.documentTypeID === 'ANSÖKAN'
  )

  return documentList.length === 0 || foundDocument === undefined
}

export const {loadArendeAction, loadArendeSuccessAction, loadArendeFailAction} = arendeSlice.actions
export default arendeSlice.reducer