import {Fetcher} from 'repository/fetcher'
import {loadList} from '../../repository/ansokanRepository'
import {AnsokanListItemType} from 'domain/AnsokanListItemType'
import {Dispatch} from 'redux'
import {RootState} from 'store'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState: AnsokanListState = {
    list: [],
    isLoading: false,
    failed: false,
    errorMsg: ''
}

export type AnsokanListState = {
    list: AnsokanListItemType[],
    isLoading: boolean,
    failed: boolean,
    errorMsg: string
}

type LoadSuccessActionPayload = {
    ansokningar: AnsokanListItemType[]
}

type LoadFailActionPayload = {
    msg: string
}

const ansokanListSlice = createSlice({
    name: 'ansokanList',
    initialState: initialState,
    reducers: {
        loadAnsokanListSuccess(state, action: PayloadAction<LoadSuccessActionPayload>) {
            state.list = action.payload.ansokningar
            state.isLoading = false
        },
        loadAnsokanListFailed(state, action: PayloadAction<LoadFailActionPayload>) {
            state.list = []
            state.errorMsg = action.payload.msg
            state.failed = true
            state.isLoading = false
        },
        loadAnsokanList(state, action: PayloadAction) {
            state.isLoading = true
        }
    }
})


export const fetchAnsokanList = () => {
    return function action(dispatch: Dispatch<any>, getState: () => RootState) {

        dispatch(loadAnsokanList())

        const fetcher = new Fetcher(dispatch, getState, false)

        return loadList(fetcher).then((ansokningar: AnsokanListItemType[]) => {
            dispatch(loadAnsokanListSuccess({ansokningar: ansokningar}))
        }).catch(error => {
            dispatch(loadAnsokanListFailed({msg: error.statusText}))
            throw error
        })
    }
}

export const {loadAnsokanListSuccess, loadAnsokanListFailed, loadAnsokanList} = ansokanListSlice.actions
export default ansokanListSlice.reducer
