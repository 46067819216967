import { Dispatch, useEffect, useState } from "react";
//@ts-ignore
import { Column, FormFieldset, Row, PageSubContent } from 'redet-react-components'
import { Form } from 'react-final-form'
import { createValidator, required } from 'utils/validation'
import { initFormData } from 'redux/finalForm'
import FormStateToRedux from 'redux/FormStateToRedux'
import { connect } from 'react-redux'
import { formProperties } from 'redux/formProperties'
import { CheckboxFinalForm, TextAreaFinalForm, Condition, WhenFieldChanges } from '../../General/forms/finalForm'

type Props = {
  initFormData: (atgard: string) => {}
}

export let validateFatighetsBestamningForm = function (values: any) {
  return createValidator(values,
    {
      rattigheterSkyldigheterBeskrivning: values?.rattigheterSkyldigheter ? [required] : [],
      fastighetersGranserBeskrivning: values?.fastighetersGranser ? [required] : []
    })
}
    
const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: any) => {
    return { initFormData: (formField: string) => ownProps.initFormData || dispatch(initFormData(formField)) }
}

function FastighetsBestamningForm(props: Props) {

  const [_formDataLoaded, setFormDataLoaded] = useState(false);
  const [_initData, setInitData] = useState({});

  useEffect(() => {
    const res = props.initFormData(formProperties.fastighetsbestamning.name)
    setFormDataLoaded(true);
    setInitData(res)
  },[])

  return (
          <PageSubContent title={"Fastighetsbestämning"} id="fastighetsforandring-fastighetsbestamning" headerLevel={4} headerId="fastighetsforandring-fastighetsbestamningHeader">
            <br/>
            <Form
              onSubmit={() => {}}
              initialValues={_initData}
              subscription={{ submitting: true, pristine: true }}
              validate={values => validateFatighetsBestamningForm(values)}>
              {() => {
                return (
                  <form onSubmit={() => {}}>
                      {_formDataLoaded && <FormStateToRedux form={formProperties.fastighetsbestamning.name}/>}
                    <Row>
                     <Column columns={'col-xs-12 col-md-6'}>
                      <FormFieldset title={"Vad vill du utreda?"}>
                        <CheckboxFinalForm field={'fastighetersGranser'}
                          id={'fastighetersGranserId'}
                          label={'Utreda fastigheters gränser'}
                          termPostfixText={undefined} warningMessage={undefined} disabled={undefined} children={undefined} header={undefined} labelChildren={undefined} term={undefined}/>

                          <Condition when="fastighetersGranser" is={true} isNot={undefined}>
                          <TextAreaFinalForm
                            id={'fastighetersGranserBeskrivningId'}
                            field={'fastighetersGranserBeskrivning'}
                            label={'Beskriv vilka fastighetsgränser som ska utredas (du kan även rita i kartskissen).'}
                            mandatory={true}
                            characterLimit={4000}/>
                          </Condition>
                          <WhenFieldChanges field={'fastighetersGranser'} becomes={false} set={'fastighetersGranserBeskrivning'} to={undefined}/>

                          <CheckboxFinalForm field={'rattigheterSkyldigheter'} id={'rattigheterSkyldigheterId'} label={'Utreda vilka rättigheter och skyldigheter som gäller för ett servitut eller en gemensamhetsanläggning'}
                          termPostfixText={undefined} warningMessage={undefined} disabled={undefined} children={undefined} header={undefined} labelChildren={undefined} term={undefined}/>

                          <Condition when="rattigheterSkyldigheter" is={true} isNot={undefined}>
                          <TextAreaFinalForm
                            id={'rattigheterSkyldigheterBeskrivningId'}
                            field={'rattigheterSkyldigheterBeskrivning'}
                            label={'Beskriv vilka servitut och gemensamhetsanläggningar som ska utredas (du kan även rita i kartskissen).'}
                            mandatory={true}
                            characterLimit={4000}/>
                          </Condition>
                        <WhenFieldChanges field={'rattigheterSkyldigheter'} becomes={false} set={'rattigheterSkyldigheterBeskrivning'} to={undefined}/>
                      </FormFieldset>
                     </Column>
                    </Row>
                  </form>
                )
              }}
            </Form>
          </PageSubContent>
  )
}

export default connect(undefined, mapDispatchToProps)(FastighetsBestamningForm)
