import React, {useEffect, useState} from 'react'
import {OmbudForDescription, SigningText} from 'components'
import {addProgress, endProgress} from 'redux/modules/fullscreenLoading'
import {changeAnsokanStatus, fetchAnsokanStatus, unlockAnsokan} from 'redux/modules/ansokanSPECIAL'
import {
  ANSOKAN_SIGNING_STATUS_SUCCEEDED,
  ANSOKAN_STATUS_LOCKED,
  ANSOKAN_STATUS_REVIEW_EMAILS,
  NO_SCROLLING_CSS_CLASS
} from '../../utils/globalConstants'
// @ts-ignore
import {ButtonGroup, ButtonTop, Column, FormFieldset, ModalDialogAlert, PageSubContent, PageTopContent, Row, VarningsRuta
} from 'redet-react-components'
import {StepVisualisationFish} from 'pages/Signing'
import {fetchPhosSigningInstanceStatus, initiateSigningInstance} from '../../redux/modules/signing'
import * as contactsHelper from 'utils/contactsHelper'
import {useAppDispatch} from "../../hooks";
import {Contact} from 'domain/Contact'
import {SIGNINGINSTANCE_STATUS} from "../../domain/signing/SigningStatus";

export const skickaInbjudanButtonText = 'Påbörja underskrifter'

type InviteToSignProps = {
  ansokanId: string
  onFormSubmit: Function
  contactList: Contact[]
}

export function InviteToSign (props: InviteToSignProps){

  const [upprattare, setUpprattare] = useState<Contact>()
  const [initiateSigningError, setinitiateSigningError] = useState(false)
  const [hasPhosBestyrkan, setHasPhosBestyrkan] = useState(false)

  const dispatch = useAppDispatch();

  useEffect(() => {
  loadData();
  getHandlingarSigningStatus();
  }, [])

  useEffect(() => {
    loadData();
  },[props.contactList.length])

  function loadData () {
    const upprattare = props.contactList.find(kontakt => kontakt.data.rollUpprattare === true)
    setUpprattare(upprattare);

    document.body.classList.add('no-menu')
    document.body.classList.remove(NO_SCROLLING_CSS_CLASS)
  }

  function unlockAnsokanAndGoBackToAnsokanClick () {
    dispatch(unlockAnsokan(props.ansokanId))
      .then(() => {
        setTimeout(endProgress, 500)
        dispatch(addProgress())
        dispatch(fetchAnsokanStatus(props.ansokanId))
          .then(() => {
            setTimeout(endProgress, 500)
            setTimeout(endProgress, 500)
          })
      })
  }

  function getHandlingarSigningStatus () {
    dispatch(fetchPhosSigningInstanceStatus(props.ansokanId))
      .then((signingStatus: SIGNINGINSTANCE_STATUS) => {
        setHasPhosBestyrkan(signingStatus === ANSOKAN_SIGNING_STATUS_SUCCEEDED)
      })
  }

  function goBackToBestyrkanClick () {
    dispatch(changeAnsokanStatus(props.ansokanId, ANSOKAN_STATUS_LOCKED))
      .then(() => {
        dispatch(fetchAnsokanStatus(props.ansokanId))
      })
  }

  function detectDuplicateEmail () {
    let allOfThem = props.contactList.filter(contact => contact.data.rollOmbud || (contact.data.rollSokande && !contactsHelper.harOmbud(props.contactList, contact.id))).map(contact => contact.data.epost)
    const uniques = new Set(allOfThem)
    return allOfThem.length > uniques.size
  }

    const hasDuplicatedEmail = detectDuplicateEmail()

    const sendInvitationsClick = () => {
      dispatch(addProgress(false, 'Skickar inbjudningar...'))
      dispatch(initiateSigningInstance(props.ansokanId))
        .then(() => {
          dispatch(endProgress())
          props.onFormSubmit()
        }).catch(() => {
        dispatch(endProgress())
        setinitiateSigningError(true);
      })
    }

    const showGoBackToBestyrkanKnapp = hasPhosBestyrkan

  return (
      <>
        <div className="wizard" id="step-2">
          <StepVisualisationFish activeStep={2}/>

          <PageTopContent title={'Granska e-postadresser'}
                          headerLevel={2}
                          headerId={'bubbelgum3000Id'}
                          introduction={'De som ska skriva under ansökan kommer att få en länk via e-post som\n leder till tjänsten ' +
                            'där de kan logga in med e-legitimation och göra en digital underskrift.\n Din ansökan måste ' +
                            'skrivas under av dig och eventuella medsökande innan den kan skickas in.'}
                          description={<>Kontrollera att uppgifterna nedan stämmer. Om allt ser bra ut klickar du
                            på <strong>{skickaInbjudanButtonText}</strong>. Är något fel så kan du gå tillbaka till
                            ansökan där du kan
                            rätta uppgifterna.</>}/>
          <br/>

          <PageSubContent title={'Underskrifter av ansökan'} headerId={'invite-to-sign-view'}
                          headerLevel={3}>
            <FormFieldset title={'E-postadresser till de som ska skriva under ansökan'}
                          description={'Om signeringsinbjudan ska skickas till en annan e-postadress än den du angett tidigare måste du gå tillbaka och ändra det för respektive sökande eller företrädare för sökande.'}>
              <Row id="signerareList">
                {contactsHelper.getAllContactsWhoShouldSignAnsokan(props.contactList).map((contact, index) =>
                  <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                          key={`signerareContainer${index}`} id={`signerareContainer${index}`}>
                    {(contact.data.rollSokande || contact.data.rollOmbud) && <>
                      <div className="form-group" id={`signerareInfo${index}`}>
                        <label className="label" htmlFor={`signerare${index}`}>E-postadress
                          till {contactsHelper.getNamn(contact)}</label>
                        <p id={`signerare${index}`}>{contactsHelper.getEpost(contact)}
                          {contact.data.rollOmbud && contact.data.ombudForPersoner && contact.data.ombudForPersoner.length > 0 &&
                            <OmbudForDescription ombudNamn={contactsHelper.getNamn(contact)}
                                                 isSokande={contact.data.rollSokande}
                                                 namnList={contactsHelper.getForetraddaNamnForContactList(props.contactList, contact.id)}
                                                 id={`sokandeOmbudForDescription${index}`}/>
                          }
                        </p>
                      </div>
                    </>}
                  </Column>
                )}
              </Row>
            </FormFieldset>
          </PageSubContent>

          <PageSubContent title={'Upprättare'} headerId={'upprattareListId'} headerLevel={3}>
            <FormFieldset
              title={'E-postadress till dig som upprättare av ansökan'}
              description={'Som upprättare får du statusuppdateringar, kvittenser och meddelande om att att ansökan är klar att skickas in.'}>
              <div className="form-group">
                <label className="label">E-postadress till upprättare</label>
                <p
                  id="notifieringepost">{upprattare !== undefined ? upprattare.data.epost : ''}</p>
              </div>
              {hasDuplicatedEmail &&
                <VarningsRuta id={"sammaepostvarningsrutaid"}
                  description={'Du har angett samma e-postadress till mer än en sökande eller företrädare för sökande. Tänk på att inbjudan är personlig och att samtliga inbjudna måste signera sin egen inbjudan.'}
                              ariaTitle={"Valideringsinformation"}>
                </VarningsRuta>}
            </FormFieldset>
          </PageSubContent>

          <SigningText ansokanStatus={ANSOKAN_STATUS_REVIEW_EMAILS}/>

          <div className="toolbar mobile-toolbar">
            <ButtonGroup>
              {showGoBackToBestyrkanKnapp ?
                <ButtonTop data-selenium-id="cancel-invite"
                           onClick={goBackToBestyrkanClick} icon="arrow_back"
                           text={'Återgå till bestyrkan'}/>
                :
                <ButtonTop data-selenium-id="cancel-invite"
                           onClick={unlockAnsokanAndGoBackToAnsokanClick} icon="arrow_back"
                           text={'Återgå till ansökan'}/>
              }
            </ButtonGroup>
            <ButtonGroup>
              <ButtonTop text={skickaInbjudanButtonText}
                         icon="arrow_forward"
                         id="sendSigningInvitations" onClick={sendInvitationsClick}/>

            </ButtonGroup>
          </div>
        </div>
        {initiateSigningError &&
          <ModalDialogAlert
            modalTitle={'Ett fel uppstod'}
            id={'modalInitiateSigningErrorDialog'}
            closeModalCallback={() => setinitiateSigningError(false)}
            description={
              <span>Ett fel uppstod när signeringen skulle startas. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta&nbsp;
                <a href="https://www.lantmateriet.se/kontakt">kundcenter</a>.
                                </span>
            }
          />
        }
      </>
    )
  }

export default (InviteToSign)