import React, {useCallback, useEffect, useRef, useState} from 'react'
import { SigningText } from 'components'
import { endProgress } from 'redux/modules/fullscreenLoading'
import { changeAnsokanStatus, fetchAnsokanStatus, unlockAnsokan } from 'redux/modules/ansokanSPECIAL'
import {BifogadHandlingListItem, getBifogadHandlingList} from 'redux/modules/bifogadHandling'
import {
    ANSOKAN_SIGNING_STATUS_NOT_STARTED,
    ANSOKAN_SIGNING_STATUS_PENDING,
    ANSOKAN_SIGNING_STATUS_SUCCEEDED,
    ANSOKAN_SIGNING_STATUS_TIMED_OUT,
    ANSOKAN_STATUS_REVIEW_EMAILS
} from 'utils/globalConstants'

import {
    // @ts-ignore
    CircleLoading, Column, FormFieldset, PageSubContent, PageTopContent, ButtonToolbarV2, ButtonTop,
    ButtonGroup,
    ExternalLink,
    ModalDialogAlert,
    VarningsRuta
} from 'redet-react-components'
import PhosBifogadeHandlingarTable from 'components/Signing/BifogadeHandlingarTable'
import { StepVisualisationFish } from 'pages/Signing'
import { fetchPhosSigningInstanceStatus, initiatePhosSigningInstance} from '../../redux/modules/signing'
import {useAppDispatch} from "../../hooks";
import {HederOchSamveteModal} from "./HederOchSamveteModal";

export const AVBRYT_INBJUDAN_BUTTON_TEXT = 'Återgå till ansökan'
export const EPOSTADRESSER_BUTTON_TEXT = 'Granska e-postadresser'
export const PHOS_SKRIV_UNDER_BUTTON_TEXT = 'Bestyrk handlingar'

export type SigningHederOchSamveteProps = {
    ansokanId: string
}

export function SigningHederOchSamvete(props: SigningHederOchSamveteProps) {

    const dispatch = useAppDispatch()
    const [bifogadeHandlingar, setBifogadeHandlingar] = useState<BifogadHandlingListItem[]>([])
    const [showHederOchSamveteModal, setShowHederOchSamveteModal] = useState(false)
    const [hasHederOchSamveteModalError, setHasHederOchSamveteModalError] = useState(false)
    const [handlingarSigneringStatus, setHandlingarSigneringStatus] = useState<string | undefined>(undefined)
    const [attachCircleLoadingToDomElementWithId, setAttachCircleLoadingToDomElementWithId] = useState("rootBody")
    const [promiseResolved, setPromiseResolved] = useState(true)
    const [handlingarSigningWebUrl, setHandlingarSigningWebUrl] = useState<string | undefined>()

    useEffect(() => {
        loadBifogadeHandlingar()
        getHandlingarSigningStatus()
        return () => stopCheckHandlingarSigningStatusTimer()
    }, []);

    const handlingarStatusTimerId = useRef<NodeJS.Timeout>();

    const startCheckHandlingarSigningStatusTimer = useCallback(() => {
        if (handlingarStatusTimerId.current === undefined) {
            handlingarStatusTimerId.current = setInterval(() => {
                getHandlingarSigningStatus()
            }, 5000)
        }
    }, [getHandlingarSigningStatus]);



    function loadBifogadeHandlingar () {
        // @ts-ignore
        dispatch(getBifogadHandlingList(props.ansokanId))
            .then(result => {
                setBifogadeHandlingar(result)
            })
    }

    function getHandlingarSigningStatus () {
        dispatch(fetchPhosSigningInstanceStatus(props.ansokanId))
            .then(({ signingStatus, signingWebUrl }) => {
                setHandlingarSigningWebUrl(signingWebUrl)
                setHandlingarSigneringStatus(signingStatus)
                if (signingStatus === ANSOKAN_SIGNING_STATUS_SUCCEEDED || signingStatus === ANSOKAN_SIGNING_STATUS_TIMED_OUT) {
                    setShowHederOchSamveteModal(false)
                }
                if (signingStatus === ANSOKAN_SIGNING_STATUS_PENDING) {
                    startCheckHandlingarSigningStatusTimer()
                } else {
                    stopCheckHandlingarSigningStatusTimer()
                }
            })
    }

    function showHederOchSamveteModalClick (show = true): void {
        setShowHederOchSamveteModal(show)
    }

    function unlockAnsokanAndGoBackClick () {
        dispatch(unlockAnsokan(props.ansokanId))
            .then(() => {
                setTimeout(() => dispatch(endProgress), 500)
                dispatch(fetchAnsokanStatus(props.ansokanId))
                    .then(() => {
                        setTimeout(() => dispatch(endProgress), 500)
                    })
            })
    }

    function toEpostadresserClick () {
        //PromiseResolved förhindrar att det smäller om användaren klickar många gånger på knappen
        if(promiseResolved) {
            setPromiseResolved(false)
            dispatch(changeAnsokanStatus(props.ansokanId, ANSOKAN_STATUS_REVIEW_EMAILS))
                .then(() => {
                    dispatch(fetchAnsokanStatus(props.ansokanId))
                    setPromiseResolved(true)
                })
        }
    }

    const initiateSigning = () => {
        dispatch(initiatePhosSigningInstance(props.ansokanId))
            .then(({ signingWebUrl } ) => {
                setHandlingarSigningWebUrl(signingWebUrl)
                setHandlingarSigneringStatus(ANSOKAN_SIGNING_STATUS_PENDING)
                startCheckHandlingarSigningStatusTimer()
            })
            .catch(() => {
                setHasHederOchSamveteModalError(true)
            })
    }

    function stopCheckHandlingarSigningStatusTimer () {
        if (handlingarStatusTimerId !== undefined) {
            clearInterval(handlingarStatusTimerId.current)
        }
    }

    function markAnsokanAsGranskaEpost () {
        setHandlingarSigneringStatus(ANSOKAN_SIGNING_STATUS_SUCCEEDED)
        stopCheckHandlingarSigningStatusTimer()
    }

    const isSigningDone = handlingarSigneringStatus === ANSOKAN_SIGNING_STATUS_SUCCEEDED
    const isSigningDoneOrUndefined = isSigningDone || handlingarSigneringStatus === undefined
    const isSigningPending = handlingarSigneringStatus === ANSOKAN_SIGNING_STATUS_PENDING
    return (
        <>
            <div className="wizard" id="step-1" data-testid={'phos-signing-web'}>
                <StepVisualisationFish activeStep={1}/>
                <PageTopContent title={'Bestyrk handlingar'} headerLevel={2} headerId={'bestyrkHandlingarTopId'}>
                    <p>Vill du skriva under försäkran klickar du
                        på <strong>{PHOS_SKRIV_UNDER_BUTTON_TEXT}</strong>. Är något fel så kan du gå tillbaka till ansökan där du
                        kan
                        rätta uppgifterna.</p>
                    <p className="introduction">Genom att skriva under med e-legitimation försäkrar du på heder och
                        samvete att samtliga handlingar som du bifogat ansökan överensstämmer med originalet.</p>

                    <VarningsRuta
                        ariaTitle='Information om signering'
                        warningMessages={[
                            {msg:<>
                                    Din elektroniska signatur kommer att synas på den inskickade handlingen, i form av namn, personnummer och tidpunkt för underskrift.
                                    Den inskickade handlingen kan bli tillgänglig för de som är berörda av ärendet i lantmäterimyndighetens e-tjänst för
                                    lantmäteriförrättningar. Den inskickade handlingen kan även skickas till de som är berörda av ärendet och till andra myndigheter, samt arkiveras.
                                    Ändamålet med behandlingen är ärendehandläggning. Ytterligare information om hantering av allmänna handlingar hittar du
                                    <ExternalLink url={"https://www.lantmateriet.se/personuppgifter"} text={"här"} />
                                </>}
                        ]}
                    >
                    </VarningsRuta>

                    <ButtonTop text={PHOS_SKRIV_UNDER_BUTTON_TEXT}
                               icon="assignment_turned_in"
                               isDisabled={isSigningDoneOrUndefined}
                               id="intygaHederOchSamveteButton"
                               onClick={isSigningDoneOrUndefined ? undefined : showHederOchSamveteModalClick}/>
                </PageTopContent>
                <br/>
                <section id="sign-phos-view">
                    <div className="row md-equal-heights">


                        <Column columns="col-xs-12 col-md-12">
                            <PageSubContent title={'Bifogade handlingar'} headerId={'bifogadeHandlingarListId'} headerLevel={3}>
                                <FormFieldset description={'Följande handlingar har bifogats ansökan'}>
                                    <PhosBifogadeHandlingarTable handlingar={bifogadeHandlingar}
                                                                 isBestyrkt={isSigningDone}/>
                                </FormFieldset>
                            </PageSubContent>
                        </Column>

                    </div>
                    <SigningText ansokanStatus={ANSOKAN_SIGNING_STATUS_NOT_STARTED}/>
                    <ButtonToolbarV2>
                        <ButtonGroup>
                            <ButtonTop data-selenium-id="cancel-signing"
                                       onClick={unlockAnsokanAndGoBackClick} icon="arrow_back"
                                       text={AVBRYT_INBJUDAN_BUTTON_TEXT}/>
                        </ButtonGroup>
                        <ButtonGroup
                            buttonPopupText={!isSigningDone ? 'Du måste bestyrka dina handlingar för att gå vidare.' : undefined}>
                            <ButtonTop text={EPOSTADRESSER_BUTTON_TEXT}
                                       icon="arrow_forward"
                                       id="toEpostadresser"
                                       onClick={isSigningDone ? toEpostadresserClick : undefined}
                                       isDisabled={!isSigningDone}
                            />
                        </ButtonGroup>
                    </ButtonToolbarV2>
                </section>
            </div>
            {showHederOchSamveteModal && !hasHederOchSamveteModalError &&
                <HederOchSamveteModal closeModalCallback={() => showHederOchSamveteModalClick(false)}
                                        initiateHandlingarSigningCallback={initiateSigning}
                                        handlingarSigneringWebUrl={handlingarSigningWebUrl + '/approve'}
                                        isSigneringPending={isSigningPending}
                                        attachCircleLoadingToDomElementWithId={attachCircleLoadingToDomElementWithId}
                />}
            {hasHederOchSamveteModalError &&
                <ModalDialogAlert modalTitle={'Någonting gick fel'}
                                  ingress={'Någonting gick fel när dina handlingar skulle bestyrkas. Försök igen om en liten stund.'}
                                  description={'Du behöver inte felanmäla detta. En felrapport har skickats till Lantmäteriets tekniker.'}
                                  acceptButton={{
                                      text: 'Stäng', icon: 'clear',
                                      onClick: () => setHasHederOchSamveteModalError(false)
                                  }}
                />
            }
            {!promiseResolved && <CircleLoading isFullscreen={true} isFocusTrapped={false} text={"Uppdaterar status på ansökan..."}/>}
        </>
    )
}


export default SigningHederOchSamvete
