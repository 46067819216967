import * as interactions from './interactions';
import {getViewStyles, getEditStyles} from './styles';
import Log from 'utils/log';
import {unByKey} from "ol/Observable";
import {GeoJSON} from "ol/format";
import Feature from "ol/format/Feature";

export default class PropertyToMapSynchronizer {
  constructor (mapController, saveCurrentFeaturesFunc, changeCenterXYFunc, zoomToFunc, mapInteractions, finishButtonOverLay, textOverlay, destructors, featureHandler) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.saveCurrentFeaturesFunc = saveCurrentFeaturesFunc
    this.changeCenterXYFunc = changeCenterXYFunc
    this.zoomToFunc = zoomToFunc
    this.mapInteractions = mapInteractions
    this.finishButtonOverLay = finishButtonOverLay
    this.textOverlay = textOverlay
    this.destructors = destructors
    this.featureHandler = featureHandler

    this.setIsProcessingExternalChange(false);
  }

  setIsProcessingExternalChange(value) {
    if (value) {
      this.log.debug('Start processing external change');
    } else {
      this.log.debug('Stop processing external change');
    }
    this.isProcessingExternalChange = value;
  }

  getIsProcessingExternalChange() {
    return this.isProcessingExternalChange;
  }

  applyMapToPropSync() {
    this.applyFeatureSyncToMap();
    this.applyZoomSyncToMap();
  }

  applyFeatureSyncToMap() {

    const featureAddListener = this.featureHandler.getDrawingFeatures().on('add', () => {
      if (!this.getIsProcessingExternalChange()) {
        this.log.debug('Add new feature');
        this.saveCurrentFeaturesFunc();
      }
    });

    const featureRemoveListener = this.featureHandler.getDrawingFeatures().on('remove', () => {
      if (!this.getIsProcessingExternalChange()) {
        this.log.debug('Remove feature');
        this.saveCurrentFeaturesFunc();
      }
    });

    this.destructors.push(()=> {
      unByKey(featureAddListener);
      unByKey(featureRemoveListener);
    });
  }

  applyModifyFeatureSyncToMap(modifyInteraction) {

    const modifyFeatureStartListener = modifyInteraction.on('modifystart', () => {
      this.log.debug('Start modify feature');
      this.mapInteractions.setIsModifying(true);
    });

    const modifyFeatureEndListener = modifyInteraction.on('modifyend', () => {
      this.log.debug('Stop modify feature');
      this.mapInteractions.setIsModifying(false);
      this.saveCurrentFeaturesFunc();
    });

    this.destructors.push(()=> {
      unByKey(modifyFeatureStartListener);
      unByKey(modifyFeatureEndListener);
    });
  }

  applyZoomSyncToMap() {
    const observableKey = this.mapController.getMap().getView().on('change:resolution', () => {
      if (!this.getIsProcessingExternalChange()) {
        this.zoomToFunc(this.mapController.getMap().getView().getZoom());
      }
    });

    this.destructors.push(()=> {
      unByKey(observableKey);
    });
  }

  syncPropsWithMap(currentProps, nextProps, force = false) {
    this.handleToolChanged(currentProps, nextProps, force);

    // Everything below affects map or feature state
    this.setIsProcessingExternalChange(true);
    if (this.mapController.getMap()) {
      this.handleZoomChanged(nextProps, force);
      this.handleFastighetFeaturesChanged(currentProps, nextProps, force);
      this.handleDrawingFeaturesChanged(currentProps, nextProps, force);
    }
  }

   handleZoomChanged(nextProps, force) {
     const zoom = this.mapController.getMap().getView().getZoom();
     if (!force) {
       if (zoom === nextProps.zoom) {
         return;
       }
     }

     this.log.debug('Zoom changed, update map. Previous zoom: ' + zoom + ' new zoom: ' + nextProps.zoom);
     this.mapController.getMap().getView().setZoom(nextProps.zoom);
     this.mapController.getMiniMap().getView().setZoom(nextProps.zoom);
   }

  handleDrawingFeaturesChanged(currentProps, nextProps, force) {
    if (!force) {
      if (currentProps.drawingFeatures === nextProps.drawingFeatures) {
        return;
      }
    }

    this.log.debug('Drawing features changed, update map');
    // diffing algorithm anyone? :-) this seems to be just fine
    // for the number of features we handle though.
    this.mapController.clearDrawingLayer();

    const format = new GeoJSON();
    if(nextProps.drawingFeatures.length > 0) {
      const features = format.readFeatures(nextProps.drawingFeatures);
      this.mapController.getDrawingLayer().getSource().addFeatures(features);
    }
  }

  fastighetsomradenTillFeatures(result) {

    const format = new GeoJSON();

    const addAsFeature = (yta) => {
      const feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: yta.type,
          coordinates: yta.coordinates
        }
      };
      this.mapController.getFastighetLayer()?.getSource()?.addFeature(format.readFeature(feature));

    };
    result.forEach((fastighet) => {
      if (fastighet !== undefined && fastighet.enhetsomrade !== undefined && fastighet.enhetsomrade !== null) {
        fastighet.enhetsomrade.forEach((enhetsomrade) => {
          if (enhetsomrade.yta) {
            enhetsomrade.yta.forEach((yta) => {
              addAsFeature(yta);
            })
          }
        })
      }

      if (fastighet !== undefined && fastighet.enhetsutrymme !== undefined && fastighet.enhetsutrymme !== null) {
        fastighet.enhetsutrymme.forEach((enhetsutrymme) => {
          if (enhetsutrymme.yta) {
            enhetsutrymme.yta.forEach((yta) => {
              addAsFeature(yta);
            })
          }
        })
      }

      if (fastighet !== undefined && fastighet.outrettOmrade !== undefined && fastighet.outrettOmrade !== null) {
        fastighet.outrettOmrade.forEach((outrettOmrade) => {
          if (outrettOmrade.yta) {
            outrettOmrade.yta.forEach((yta) => {
              addAsFeature(yta);
            })
          }
        })
      }

      if (fastighet !== undefined && fastighet.fiskeomrade !== undefined && fastighet.fiskeomrade !== null) {
        fastighet.fiskeomrade.forEach((fiskeomrade) => {
          if (fiskeomrade.yta) {
            fiskeomrade.yta.forEach((yta) => {
              addAsFeature(yta);
            })
          }
        })
      }
    })
  }
  handleFastighetFeaturesChanged(currentProps, nextProps, force) {
    if (!force) {
      if (currentProps.fastighetFeaturesRaw === nextProps.fastighetFeaturesRaw) {
        return;
      }
    }
    if (nextProps.fastighetFeaturesRaw === undefined || nextProps.fastighetFeaturesRaw === null || !nextProps.fastighetFeaturesRaw.length > 0) return
    this.mapController.getMap().updateSize();
    this.log.debug('Fastighet features changed, update map');
    // diffing algorithm anyone? :-) this seems to be just fine
    // for the number of features we handle though.
    this.mapController.clearFastighetLayer();
    this.fastighetsomradenTillFeatures(nextProps.fastighetFeaturesRaw);


    const fastighetExtent = this.mapController.getFastighetLayer()?.getSource()?.getExtent();
    if (fastighetExtent !== undefined && fastighetExtent[0] !== Infinity && currentProps.newMap) {
      this.mapController.getMap().getView().fit(fastighetExtent, this.mapController.getMap().getSize());
      this.changeCenterXYFunc(this.mapController.getMap().getView().getCenter()[0], this.mapController.getMap().getView().getCenter()[1]);
      const zoom = this.mapController.getMap().getView().getZoom();
      this.zoomToFunc(zoom);
    }

    if (!force) {
      this.saveCurrentFeaturesFunc();
    }

    // If no fastighet is selected, zoom out/reset map.
    if (nextProps.fastigheter.length === 0) {
      this.zoomToFunc(0);
    }
    this.mapController.getMap().renderSync();
  }

  handleToolChanged(currentProps, nextProps, force) {
    if (currentProps.activeTool === nextProps.activeTool) {
      return;
    }

    if (!force) {
      this.textOverlay.hide();
    }

    this.mapInteractions.unregisterCurrentMultipointInteraction();
    this.mapInteractions.removeAllInteractions();
    this.finishButtonOverLay.hide();

    // Remove measure features if measure tools are no longer active
    if (nextProps.activeTool !== interactions.MEASURE_LINE && nextProps.activeTool !== interactions.MEASURE_POLYGON) {
      this.log.debug('Removing all measure features as measure tools are no longer active');
      this.mapController.getDrawingLayer().getSource().getFeatures().filter(feature => feature.get('isMeasureLine') || feature.get('isMeasurePolygon')).forEach(feature => this.mapController.getDrawingLayer().getSource().removeFeature(feature));
      this.mapController.getMap().getOverlays().getArray().filter(array => array.get('isMeasureTooltip')).forEach(array => this.mapController.getMap().removeOverlay(array));
      this.mapInteractions.getMeasureTooltip().rebuild();
      this.saveCurrentFeaturesFunc();
    }

    if (nextProps.activeTool === interactions.PAN) {
      this.mapInteractions.unlockInteractions();
      this.mapController.getDrawingLayer().setStyle((feature) => getViewStyles(feature, this.mapController.getMap()));
      this.mapInteractions.activatePanInteraction();
    } else {
      this.mapInteractions.lockInteractions();
      this.mapController.getDrawingLayer().setStyle((feature) => getEditStyles(feature, this.mapController.getMap()));
    }

    if (nextProps.activeTool === interactions.DELETE) {
      this.mapInteractions.activateDeleteInteraction();
    } else if (nextProps.activeTool === interactions.MOVE) {
      this.mapInteractions.activateMoveInteraction();
    } else if (nextProps.activeTool === interactions.MODIFY) {
      const modifyInteraction = this.mapInteractions.activateModifyInteraction();
      this.applyModifyFeatureSyncToMap(modifyInteraction);
    } else if (nextProps.activeTool === interactions.LINE_STRING) {
      this.mapInteractions.activateLineStringInteraction(false);
    } else if (nextProps.activeTool === interactions.POLYGON) {
      this.mapInteractions.activatePolygonInteraction(false);
    } else if (nextProps.activeTool === interactions.TEXT) {
      this.mapInteractions.activateTextInteraction();
    } else if (nextProps.activeTool === interactions.POINT) {
      this.mapInteractions.activatePointInteraction();
    } else if (nextProps.activeTool === interactions.MEASURE_LINE) {
      this.mapInteractions.activateMeasureLineInteraction();
    } else if (nextProps.activeTool === interactions.MEASURE_POLYGON) {
      this.mapInteractions.activateMeasurePolygonInteraction();
    }
  }

}
