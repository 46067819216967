import React from 'react'
import { EmailInputFinalForm, TextInputFinalForm } from '../../General/forms/finalForm'
import { FormSpy } from 'react-final-form'
import { Column, FormFieldset, Row } from 'redet-react-components'
import { harOmbud } from '../../../utils/contactsHelper'

export default function IndividualPhoneEmailForm ({ formAutocomplete, contactList, contactId }) {
  const ombudFinns = harOmbud(contactList, contactId)
  return (
    <FormFieldset title={'Telefon och e-post'}>
      <Row>
        <Column>
          <TextInputFinalForm field={'telefonMobil'} label={'Mobiltelefon'} maxLength={20} type={'tel'}
                              autoComplete={formAutocomplete ? 'mobile tel-national' : undefined}/>
        </Column>
        <Column>
          <TextInputFinalForm field={'telefonHem'} label={'Hemtelefon'} maxLength={20} type={'tel'}
                              autoComplete={formAutocomplete ? 'home tel-national' : undefined}/>
        </Column>
        <Column>
          <TextInputFinalForm field={'telefonDagtid'} label={'Telefon dagtid'} maxLength={20} type={'tel'}
                              autoComplete={formAutocomplete ? 'work tel-national' : undefined}/>
        </Column>
        <Column>
          <FormSpy subscription={{ values: true }}>
            {(props) => (
              <EmailInputFinalForm
                field={'epost'}
                label={'E-postadress'}
                maxLength={100}
                required={(!ombudFinns && props.values.rollSokande) || props.values.rollUpprattare || props.values.rollOmbud}
                autoComplete={formAutocomplete ? 'email' : undefined}
              />
            )}
          </FormSpy>
        </Column>
      </Row>
    </FormFieldset>
  )
}
