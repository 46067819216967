import { useTranslation } from "react-i18next";
//@ts-ignore
import { ButtonGroup, ButtonToolbarV2, ButtonSub, ButtonTile} from 'redet-react-components'


export function LanguageSelector(props: { rightAligned?: boolean }) {

    const { t, i18n: {changeLanguage, language} } = useTranslation();

    const handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        changeLanguage(event.target.value);
    }

    return (
        <ButtonToolbarV2 className={"toolbar" + (props.rightAligned ? " right-aligned" : "")}>
            <ButtonGroup>
                <div className="dropdown dropdown-tile">
                    <ButtonTile className="button-tile dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" icon={'language'} ariaLabel={t('language.aria')}  text={t('language.title')}>{t('language.title')}</ButtonTile>
                    <ul className="dropdown-menu">
                        <li className={language === "en" ? "active" : ""} ><ButtonSub className="button-link" value={'en'}  onClick={handleChangeLanguage} text={t('language.english')}></ButtonSub></li>
                        <li className={language === "sv" ? "active" : ""}><ButtonSub className="button-link" value={'sv'} onClick={handleChangeLanguage} text={t('language.swedish')}></ButtonSub></li>
                    </ul>
                </div>
            </ButtonGroup>
        </ButtonToolbarV2>
    )

}
export default LanguageSelector;