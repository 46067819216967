import {FeatureToggleNotis} from '../featureToggle/FeatureToggle'
import {Notis} from "redet-react-components";

/**
 * Function that converts array of FeatureToggles objects to an array of NotisRender (objects that contain a subset of FeatureToggles of type Notis)
 * @param notiser
 * @returns {[]}
 */
export function convertEnabledNotiserToFlatStructure(notiser: FeatureToggleNotis[]): Notis[] {
  const flatNotiserArray: Notis[] = []
  if (notiser && Array.isArray(notiser)) {
    notiser.filter((notis => notis.enabled))
        .map((enabledNotis) => {
          return flatNotiserArray.push({
              name: enabledNotis.name,
              header: enabledNotis.metadata?.header ?? "",
              message: enabledNotis.metadata?.message ?? "",
              severity: capitalize(enabledNotis.metadata?.severity ?? "Information")
          } as Notis)
        })
  }
  return flatNotiserArray
}

/**
 * Function that returns first letter uppercase and the rest lower case
 * OBS! Function will return emtpy string if non-string value is omitted to the function
 * @param str
 * @returns {string}
 */
function capitalize (str?: string): string {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  } else {return ''}
}
