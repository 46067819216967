import Log from '../utils/log'
import {logError} from '../utils/errorHandler'
import type {Fetcher} from "./fetcher";
import {DocumentPreview} from "../domain/pdf/DocumentAndAttachments";
import {SigningFormValues} from "../domain/signing/SigningModels";
import {RootState} from "../store";

const repositoryUrl = '/api/ansokan-service/ansokan/'
const singingRepositoryUrl = '/api/signering-service/'
const log = new Log('ansokanRepository')

const APPLICATION_JSON = 'application/json'

const KEY_ARGUMENT_ERROR_MESSAGE = 'The key argument is missing'


type RequestId = {
    id: string
}

export function initiateSigning(fetcher: Fetcher, key: string, shouldMockSigningService: boolean) {
    if (!key) {
        throw new Error(KEY_ARGUMENT_ERROR_MESSAGE)
    }
    const mockSigningService = shouldMockSigningService ? {'enak-mock-signing-service': 'true'} : null

    return fetcher.fetching(`${repositoryUrl + key}/signera`,
        {
            cache: 'no-store',
            method: 'POST'
        },
        {
            ...mockSigningService,
            'Content-Type': APPLICATION_JSON,
        }
    ).then(() => {
        log.debug('Signering successfully initiated')
    }).catch(error => {
        logError('Error encountered while initiating signering')
        throw error
    })
}

export function initiatePhosSigning(fetcher: Fetcher, key: string) {
    if (!key) {
        throw new Error(KEY_ARGUMENT_ERROR_MESSAGE)
    }

    return fetcher.fetching(`${repositoryUrl + key}/handlingar/signera`,
        {
            cache: 'no-store',
            method: 'POST',
        },
        {
            'Content-Type': APPLICATION_JSON,
        }
    ).then((response) => {
        return response.json()
    }).then((response) => {
        return response
    }).catch(error => {
        logError(error, 'Error encountered while initiating phossignering')
        throw error
    })
}

export async function initiatePhosSigningV2(fetcher: Fetcher, key: string, getState: () => RootState) {
    if (!key) {
        throw new Error(KEY_ARGUMENT_ERROR_MESSAGE)
    }
    // ansokan/{ansokanId}/handlingar/bestyrk
    const requestId = await fetcher.fetching(`${repositoryUrl + key}/handlingar/bestyrk`,
        {
            cache: 'no-store',
            method: 'POST',
        },
        {
            'Content-Type': APPLICATION_JSON,
        }
    ).then((response) => {
        return response.json()
    }).then((requestId: RequestId) => {
        return requestId
    }).catch(error => {
        logError(error, 'Error encountered while initiating phossignering: ' + JSON.stringify(error))
        throw error
    });

    //fetch form
    if (getState().featureToggle.PHOS_MOCK_SIGNING_ENABLED) {
        return {action: "https://dummy.lantmateriet.se/jadajada", binding: "dummybinding", eidSignRequest: "dummyeidSignRequest", relayState: "dummyrelayState"}
    } else {
        const redirectUrl = encodeURIComponent(document.location.href)
        return fetchAutoPostForm(fetcher, requestId.id, redirectUrl)
    }
}

export function fetchPhosSigningStatus(fetcher: Fetcher, key: string) {
    if (!key) {
        throw new Error(KEY_ARGUMENT_ERROR_MESSAGE)
    }
    return fetcher.fetching(`${repositoryUrl + key}/handlingar/signering`,
        {
            cache: 'no-store',
            method: 'GET',
        },
        {
            'Content-Type': APPLICATION_JSON,
        }
    ).then((response) => {
        return response.json()
    }).then((response) => {
        log.debug('Phossigneringstatus successfully fetched')
        return response
    }).catch(error => {
        logError('Error encountered while fetching phossigneringstatus')
        throw error
    })
}

export function abortSigning(fetcher: Fetcher, key: string, shouldMockSigningService: boolean) {
    if (!key) {
        throw new Error(KEY_ARGUMENT_ERROR_MESSAGE)
    }
    const mockSigningService = shouldMockSigningService ? {'enak-mock-signing-service': 'true'} : null

    return fetcher.fetching(`${repositoryUrl + key}/signera`,
        {
            cache: 'no-store',
            method: 'DELETE',
        },
        mockSigningService,
    ).then(() => {
        log.debug('Signering successfully aborted on server')
    }).catch(error => {
        logError('Error encountered while aborting signing on server')
        throw error
    })
}

export function fetchStatus(fetcher: Fetcher, key: string, shouldMockSigningService: boolean) {
    const mockSigningService = shouldMockSigningService ? {'enak-mock-signing-service': 'true'} : null
    return fetcher.fetching(`${repositoryUrl + key}/signera/status/uppdatera`,
        {
            method: 'PUT',
            cache: 'no-store',
        },
        mockSigningService,
    ).then((response) => {
        return response.json()
    }).then(status => {
        log.debug('Signing status for ansokan successfully loaded from server')
        return status
    }).catch(error => {
        logError('Error encountered while fetching signing status for ansokan from server')
        throw error
    })
}

export function fetchPreview(fetcher: Fetcher, requestId: string): Promise<DocumentPreview> {
    const url = `${singingRepositoryUrl}${requestId}/preview`
    return fetcher.fetching(url,
        {
            method: 'GET',
            cache: 'no-store'
        }, {'Content-Type': APPLICATION_JSON})
        .then((response) => {
            return response.json()
        }).then((preview: DocumentPreview) => {
            log.debug('Document preview successfully loaded from server')
            return preview
        }).catch(error => {
            logError('Error encountered while fetching document preview from server')
            throw error
        })
}

export function fetchAutoPostForm(fetcher: Fetcher, requestId: string, redirectUrl: string): Promise<SigningFormValues> {
    const url: string = `${singingRepositoryUrl}${requestId}/request?redirectUrl=${redirectUrl}`
    return fetcher.fetching(url,
        {
            cache: 'no-store',
            method: 'GET',
        }, {'Content-Type': APPLICATION_JSON})
        .then((response) => {
            return response.json()
        }).then((formValues: SigningFormValues) => {
            return formValues
        }).catch(error => {
            logError('Error encountered while fetching signing-form from server')
            throw error
        });
}

export function declineSigning(fetcher: Fetcher, requestId: string): Promise<boolean> {
    const url: string = `${singingRepositoryUrl}${requestId}/decline`
    return fetcher.fetching(url,
        {
            cache: 'no-store',
            method: 'POST',
        }, {'Content-Type': APPLICATION_JSON})
        .then((response) => {
            return response.status === 200
        }).catch(error => {
            logError('Error encountered while declining signing request')
            throw error
        });
}


