import Navigation from '../components/Navigation/Navigation'
import {PageHeader} from '../components/General/forms/layoutElements'
// @ts-ignore
import {ButtonToolbar, ExternalLink, LinkButtonSub, ModalDialogAlert, ModalDialogSub} from 'redet-react-components'


export const NotFound = () => {
    return (
        <>
            <header>
                <Navigation />
                <PageHeader />
            </header>
            <main>
                <ModalDialogAlert modalTitle={'Tyvärr kan vi inte hitta sidan du letar efter'}
                    ingress={'Sidan kan ha tagits bort eller flyttats till en annan adress.'}
                    useDefaultToolbar={false}>
                    <ul>
                        <li>Backa till sidan du besökte innan felet uppstod</li>
                        <li>Gå till startsidan e-tjänsten för <a
                            href={window.location.origin}>lantmäteriförrättningar</a></li>
                        <li><p>Leta på <ExternalLink url={'http://www.lantmateriet.se'} text={'Lantmäteriets hemsida'}
                            openInNewTab={false} />, där
                            hittar du länkar till alla Lantmäteriets e-tjänster</p></li>
                    </ul>
                    <ButtonToolbar>
                        <LinkButtonSub text={'Lantmäteriförrättning'} link={window.location.origin} icon={'arrow_forward'} />
                        <LinkButtonSub text={'Lantmäteriets hemsida'} link={'http://www.lantmateriet.se'} icon={'arrow_forward'} />
                    </ButtonToolbar>
                </ModalDialogAlert>
            </main>
        </>
    )
}
