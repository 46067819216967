import React, {useCallback, useEffect, useRef, useState} from 'react'
import {SigningText} from 'components'
import {endProgress} from 'redux/modules/fullscreenLoading'
import {changeAnsokanStatus, fetchAnsokanStatus, unlockAnsokan} from 'redux/modules/ansokanSPECIAL'
import {BifogadHandlingListItem, getBifogadHandlingList} from 'redux/modules/bifogadHandling'
import {
    ANSOKAN_SIGNING_STATUS_NOT_STARTED,
    ANSOKAN_SIGNING_STATUS_PENDING,
    ANSOKAN_SIGNING_STATUS_SUCCEEDED,
    ANSOKAN_SIGNING_STATUS_TIMED_OUT,
  ANSOKAN_STATUS_REVIEW_EMAILS, ANSOKAN_SIGNING_STATUS_TYPE
} from 'utils/globalConstants'
import {
    // @ts-ignore
  CircleLoading, Column, FormFieldset, PageSubContent, PageTopContent, ButtonToolbarV2, ButtonTop,
    ButtonGroup,
    ExternalLink,
    ModalDialogAlert,
    VarningsRuta
} from 'redet-react-components'
import PhosBifogadeHandlingarTable from 'components/Signing/BifogadeHandlingarTable'
import {StepVisualisationFish} from 'pages/Signing'
import {fetchPhosSigningInstanceStatus, initiatePhosSigningInstanceV2} from '../../redux/modules/signing'
import {HederOchSamveteModalV2} from './HederOchSamveteModalV2'
import {useAppDispatch, useAppSelector} from "../../hooks";
import {SigningFormValues} from "../../domain/signing/SigningModels";
import {useSearchParams} from "react-router-dom";

export const AVBRYT_INBJUDAN_BUTTON_TEXT = 'Återgå till ansökan'
export const EPOSTADRESSER_BUTTON_TEXT = 'Granska e-postadresser'
export const PHOS_SKRIV_UNDER_BUTTON_TEXT = 'Bestyrk handlingar'

export type SigningHederOchSamveteProps = {
  ansokanId: string
}

export function SigningHederOchSamveteV2(props: SigningHederOchSamveteProps) {

  const dispatch = useAppDispatch()
  const [bifogadeHandlingar, setBifogadeHandlingar] = useState<BifogadHandlingListItem[]>([])
  const [showHederOchSamveteModal, setShowHederOchSamveteModal] = useState(false)
  const [hasHederOchSamveteModalError, setHasHederOchSamveteModalError] = useState(false)
  const [handlingarSigneringStatus, setHandlingarSigneringStatus] = useState<ANSOKAN_SIGNING_STATUS_TYPE | undefined>(undefined)
  const [attachCircleLoadingToDomElementWithId] = useState("rootBody")
  const [promiseResolved, setPromiseResolved] = useState(true)
  const [formData, setFormData] = useState<SigningFormValues | undefined>()
  let [searchParams, setSearchParams] = useSearchParams();
  const isPhosMockEnabled = useAppSelector(store => store?.featureToggle?.PHOS_MOCK_SIGNING_ENABLED)

  useEffect(() => {
    if (props.ansokanId !== undefined && dispatch !== undefined) {
      loadBifogadeHandlingar()
      getHandlingarSigningStatus()
    }
    return () => stopCheckHandlingarSigningStatusTimer()
  }, [props.ansokanId, dispatch]);

  useEffect(() => {
    if (searchParams.has("error"))
      setHasHederOchSamveteModalError(true)
  }, [searchParams]);

  const handlingarStatusTimerId = useRef<NodeJS.Timeout>();

  const startCheckHandlingarSigningStatusTimer = useCallback(() => {
    if (handlingarStatusTimerId.current === undefined && handlingarSigneringStatus !== ANSOKAN_SIGNING_STATUS_SUCCEEDED) {
      handlingarStatusTimerId.current = setInterval(() => {
        getHandlingarSigningStatus()
      }, 5000)
    }
    if (handlingarStatusTimerId.current !== undefined && (handlingarSigneringStatus === ANSOKAN_SIGNING_STATUS_SUCCEEDED || handlingarSigneringStatus === ANSOKAN_SIGNING_STATUS_TIMED_OUT)) {
      clearInterval(handlingarStatusTimerId.current)
    }
  }, [getHandlingarSigningStatus, handlingarSigneringStatus]);



  function loadBifogadeHandlingar () {
    const id = props.ansokanId
    dispatch(getBifogadHandlingList(id))
      .then(result => {
        setBifogadeHandlingar(result)
      })
  }

  function getHandlingarSigningStatus () {
    dispatch(fetchPhosSigningInstanceStatus(props.ansokanId))
      .then(({ signingStatus, signingWebUrl }) => {
        setHandlingarSigneringStatus(signingStatus)
        if (signingStatus === ANSOKAN_SIGNING_STATUS_SUCCEEDED || signingStatus === ANSOKAN_SIGNING_STATUS_TIMED_OUT) {
          setShowHederOchSamveteModal(false)
        }
        if (signingStatus === ANSOKAN_SIGNING_STATUS_PENDING) {
          startCheckHandlingarSigningStatusTimer()
        } else {
          stopCheckHandlingarSigningStatusTimer()
        }
      })
  }

    function unlockAnsokanAndGoBackClick () {
    dispatch(unlockAnsokan(props.ansokanId))
      .then(() => {
        setTimeout(() => dispatch(endProgress), 500)
        dispatch(fetchAnsokanStatus(props.ansokanId))
          .then(() => {
            setTimeout(() => dispatch(endProgress), 500)
          })
      })
  }

  function toEpostadresserClick () {
    //PromiseResolved förhindrar att det smäller om användaren klickar många gånger på knappen
    if(promiseResolved) {
      setPromiseResolved(false)
    dispatch(changeAnsokanStatus(props.ansokanId, ANSOKAN_STATUS_REVIEW_EMAILS))
      .then(() => {
        dispatch(fetchAnsokanStatus(props.ansokanId))
        setPromiseResolved(true)
      })
    }
  }

  const initiateSigning = () => {
    dispatch(initiatePhosSigningInstanceV2(props.ansokanId))
      .then((signingFormData ) => {
        setFormData(signingFormData)
        setHandlingarSigneringStatus(ANSOKAN_SIGNING_STATUS_PENDING)
        if(isPhosMockEnabled) getHandlingarSigningStatus()
      })
      .catch(() => {
        setHasHederOchSamveteModalError(true)
      })
  }

  function stopCheckHandlingarSigningStatusTimer () {
    if (handlingarStatusTimerId !== undefined) {
      clearInterval(handlingarStatusTimerId.current)
    }
  }

    const isSigningDone = handlingarSigneringStatus === ANSOKAN_SIGNING_STATUS_SUCCEEDED
    const isSigningDoneOrUndefined = isSigningDone || handlingarSigneringStatus === undefined
    const isSigningPending = handlingarSigneringStatus === ANSOKAN_SIGNING_STATUS_PENDING

  function closeErrorModal() {
    setSearchParams(new URLSearchParams(undefined))
    setHasHederOchSamveteModalError(false)
  }

  return (
      <>
        <div className="wizard" id="step-1" data-testid={'phos-leif-web'}>
          <StepVisualisationFish activeStep={1}/>
          <PageTopContent title={'Bestyrk handlingar'} headerLevel={2} headerId={'bestyrkHandlingarTopId'}>
            <p>Vill du skriva under försäkran klickar du
              på <strong>{PHOS_SKRIV_UNDER_BUTTON_TEXT}</strong>. Är något fel så kan du gå tillbaka till ansökan där du
              kan
              rätta uppgifterna.</p>
            <p className="introduction">Genom att skriva under med e-legitimation försäkrar du på heder och
              samvete att samtliga handlingar som du bifogat ansökan överensstämmer med originalet.</p>

            <VarningsRuta
              ariaTitle='Information om signering'
              warningMessages={[
                {msg:<>
                    Din elektroniska signatur kommer att synas på den inskickade handlingen, i form av namn, personnummer och tidpunkt för underskrift.
                    Den inskickade handlingen kan bli tillgänglig för de som är berörda av ärendet i lantmäterimyndighetens e-tjänst för
                    lantmäteriförrättningar. Den inskickade handlingen kan även skickas till de som är berörda av ärendet och till andra myndigheter, samt arkiveras.
                    Ändamålet med behandlingen är ärendehandläggning. Ytterligare information om hantering av allmänna handlingar hittar du
                    <ExternalLink url={"https://www.lantmateriet.se/personuppgifter"} text={"här"} />
                  </>}
              ]}
            >
            </VarningsRuta>

            <ButtonTop text={PHOS_SKRIV_UNDER_BUTTON_TEXT}
                       icon="assignment_turned_in"
                       isDisabled={isSigningDoneOrUndefined}
                       id="intygaHederOchSamveteButton"
                       onClick={isSigningDoneOrUndefined ? undefined : () => setShowHederOchSamveteModal(true)}/>
          </PageTopContent>
          <br/>
          <section id="sign-phos-view">
            <div className="row md-equal-heights">


              <Column columns="col-xs-12 col-md-12">
                <PageSubContent title={'Bifogade handlingar'} headerId={'bifogadeHandlingarListId'} headerLevel={3}>
                  <FormFieldset description={'Följande handlingar har bifogats ansökan'}>
                    <PhosBifogadeHandlingarTable handlingar={bifogadeHandlingar}
                                                 isBestyrkt={isSigningDone}/>
                  </FormFieldset>
                </PageSubContent>
              </Column>

            </div>
            <SigningText ansokanStatus={ANSOKAN_SIGNING_STATUS_NOT_STARTED}/>
            <ButtonToolbarV2>
              <ButtonGroup>
                <ButtonTop data-selenium-id="cancel-signing"
                           onClick={unlockAnsokanAndGoBackClick} icon="arrow_back"
                           text={AVBRYT_INBJUDAN_BUTTON_TEXT}/>
              </ButtonGroup>
              <ButtonGroup
                buttonPopupText={!isSigningDone ? 'Du måste bestyrka dina handlingar för att gå vidare.' : undefined}>
                <ButtonTop text={EPOSTADRESSER_BUTTON_TEXT}
                           icon="arrow_forward"
                           id="toEpostadresser"
                           onClick={isSigningDone ? toEpostadresserClick : undefined}
                           isDisabled={!isSigningDone}
                />
              </ButtonGroup>
            </ButtonToolbarV2>
          </section>
        </div>
        {showHederOchSamveteModal && !hasHederOchSamveteModalError &&
          <HederOchSamveteModalV2 closeModalCallback={() => setShowHederOchSamveteModal(false)}
                                  initiateHandlingarSigningCallback={initiateSigning}
                                  formData={formData}
                                  isSigneringPending={isSigningPending}
                                  attachCircleLoadingToDomElementWithId={attachCircleLoadingToDomElementWithId}
          />}
        {hasHederOchSamveteModalError &&
          <ModalDialogAlert modalTitle={'Någonting gick fel'}
                            ingress={'Någonting gick fel när dina handlingar skulle bestyrkas. Försök igen om en liten stund.'}
                            description={'Du behöver inte felanmäla detta. En felrapport har skickats till Lantmäteriets tekniker.'}
                            acceptButton={{
                              text: 'Stäng', icon: 'clear',
                              onClick: closeErrorModal
                            }}
          />
        }
        {!promiseResolved && <CircleLoading isFullscreen={true} isFocusTrapped={false} text={"Uppdaterar status på ansökan..."}/>}
      </>
    )
}


export default SigningHederOchSamveteV2
