const DISPLAY_MESSAGE_DIALOG = 'modalDialog/DISPLAY_MESSAGE_DIALOG'
const DISPLAY_MINI_CONFIRM_DIALOG = 'modalDialog/DISPLAY_MINI_CONFIRM_DIALOG'
const DISPLAY_CONFIRM_DIALOG = 'modalDialog/DISPLAY_CONFIRM_DIALOG'
const DISPLAY_CONFIRM_DIALOG_WITH_ICON_OPTIONS = 'modalDialog/DISPLAY_CONFIRM_DIALOG_WITH_ICON_OPTIONS'
const ENABLE_ACCEPT_BUTTON = 'modalDialog/ENABLE_ACCEPT_BUTTON'
const DISABLE_ACCEPT_BUTTON = 'modalDialog/DISABLE_ACCEPT_BUTTON'
const DISPLAY_GENERIC_ERROR_FORM_DIALOG = 'modalDialog/DISPLAY_GENERIC_ERROR_FORM_DIALOG'
const CLOSE = 'modalDialog/CLOSE'

const initialState = {
  isVisible: false,
  acceptButtonText: undefined,
  declineButtonText: undefined,
  modalTitle: '',
  iconConfirm: 'check',
  iconDecline: 'clear',
  // Depcreacated
  showDeclineButton: false,
  disableAcceptButton: false,
  callback: null,
  content: null,
  // V2
  isVisibleV2: false,
  acceptClickFunction: undefined,
  declineClickFunction: undefined,
  ingress: undefined,
  description: undefined,
  showErrorDialog: false
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case DISPLAY_MESSAGE_DIALOG:
      return {
        ...state,
        isVisible: true,
        callback: action.callback,
        content: action.content,
        showDeclineButton: false,
        acceptButtonText: action.acceptButtonText,
        modalTitle: action.modalTitle,
      }
    case DISPLAY_CONFIRM_DIALOG:
      return {
        ...state,
        isVisible: true,
        callback: action.callback,
        content: action.content,
        showDeclineButton: true,
        acceptButtonText: action.acceptButtonText,
        declineButtonText: action.declineButtonText,
        modalTitle: action.modalTitle,
      }
    case DISPLAY_MINI_CONFIRM_DIALOG:
      return {
        ...state,
        isVisible: true,
        callback: action.callback,
        description: action.content,
        showDeclineButton: true,
        acceptButtonText: action.acceptButtonText,
        declineButtonText: action.declineButtonText,
        modalTitle: action.modalTitle,
      }
    case DISPLAY_CONFIRM_DIALOG_WITH_ICON_OPTIONS:
      return {
        ...state,
        isVisible: true,
        callback: action.callback,
        description: action.content,
        showDeclineButton: true,
        acceptButtonText: action.acceptButtonText,
        declineButtonText: action.declineButtonText,
        modalTitle: action.modalTitle,
        iconConfirm: action.iconConfirm,
        iconDecline: action.iconDecline,
      }
    case ENABLE_ACCEPT_BUTTON:
      return {
        ...state,
        disableAcceptButton: false
      }
    case DISPLAY_GENERIC_ERROR_FORM_DIALOG:
      return {
        ...state,
        showErrorDialog: true
      }
    case DISABLE_ACCEPT_BUTTON:
      return {
        ...state,
        disableAcceptButton: true,
        acceptButtonTitle: action.acceptButtonTitle
      }
    case CLOSE:
      return initialState
    default:
      return state
  }
}

/**
 * @deprecated Använd en modal-komponent som renederas i samma komponent där den används istället.
 * @param content
 * @param modalTitle
 * @param callback
 * @param acceptButtonText
 * @returns {{modalTitle: *, acceptButtonText: string, callback: *, type: string, content: *}}
 */
export function displayModalMessageDialog (content, modalTitle, callback, acceptButtonText = 'OK') {
  return {
    type: DISPLAY_MESSAGE_DIALOG,
    content: content,
    callback: callback,
    acceptButtonText,
    modalTitle
  }
}

/**
 * @deprecated Använd en alert-modal-komponent som renederas i samma komponent där den används istället.
 * @returns {{type: string}}
 */
export function displayGenericErrorFormDialog () {
  return {
    type: DISPLAY_GENERIC_ERROR_FORM_DIALOG
  }
}

/**
 * @Deprecated Använd en modal-komponent som renderas i samma komponent där den används istället.
 * @param content
 * @param modalTitle
 * @param callback
 * @param acceptButtonText
 * @param declineButtonText
 * @returns {{modalTitle: *, declineButtonText: string, acceptButtonText: string, callback: *, type: string, content: *}}
 */
export function displayModalConfirmDialog (content, modalTitle, callback, acceptButtonText = 'Ja', declineButtonText = 'Nej') {
  return {
    type: DISPLAY_CONFIRM_DIALOG,
    content: content,
    callback: callback,
    acceptButtonText,
    declineButtonText,
    modalTitle
  }
}

export function enableAcceptButton () {
  return {
    type: ENABLE_ACCEPT_BUTTON
  }
}

export function disableAcceptButton (acceptButtonTitle = '') {
  return {
    type: DISABLE_ACCEPT_BUTTON,
    acceptButtonTitle
  }
}

export function closeModalDialog () {
  return {
    type: CLOSE
  }
}
