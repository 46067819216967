import {Contact, ContactTypes} from "../domain/Contact";
import {ListedBifogadHandling} from "../domain/ListedBifogadHandling";

export function contactSorter(current: Contact, next: Contact) {
    const validateType = (type: ContactTypes) => type === ContactTypes.CONTACT_TYPE_ORGANIZATION || type === ContactTypes.CONTACT_TYPE_INDIVIDUAL
    if (!validateType(current.type) || !validateType(next.type)) {
        throw new Error('Unknown type in contactSorter: ' + current.type)
    }
    const compareMethod = () => {
        return current.type === ContactTypes.CONTACT_TYPE_ORGANIZATION ?
            current.data.orgNamn.toLowerCase().localeCompare(next.data.orgNamn.toLowerCase()) :
            current.data.namn.toLowerCase().localeCompare(next.data.namn.toLowerCase())
    }
    // First compare types, then fields
    return current.type === next.type ? compareMethod() : current.type.localeCompare(next.type)
}

// No unit test for this method as PhantomJS doesn't yet support localeCompare, see https://github.com/ariya/phantomjs/issues/14496
function naturalSorter(current: string, next: string) {
    return current.localeCompare(next, undefined, {numeric: true, sensitivity: 'base'})
}

export function bifogadeHandlingarSorter(current: ListedBifogadHandling, next: ListedBifogadHandling) {
    return naturalSorter(current.listName, next.listName)
}


