import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import enJSON from './locale/en.json'
import svJSON from './locale/sv.json'


i18n.use(detector).use(initReactI18next).init({
    //debug: true,
    interpolation: {
        // React already does escaping
        escapeValue: false
   },
    resources: {
        en: { ...enJSON },
        sv: { ...svJSON },
    },
    fallbackLng: "en"
});