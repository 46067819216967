// @flow
// 1.21 En kontaktperson med rollen sökande måste ha en epostadress angiven

import { BLOCKING, SOKANDE_ORGANISATION_MASTE_HA_OMBUD } from './constants'
import { getNamn, harOmbud } from '../../../../utils/contactsHelper'
import { Validation } from './model/Validation'

function createError (invalidContacts): ?Array<Validation> {
  const invalidContactsName = invalidContacts.map(contacts => getNamn(contacts))
  if (invalidContacts) {
    return invalidContactsName.map((name) => (new Validation(SOKANDE_ORGANISATION_MASTE_HA_OMBUD, `Sökande ${name} måste företrädas av någon.`, 'contacts', BLOCKING, undefined)))
  }
  return undefined
}

const organisationerSomArSokande = contact => {
  if (contact.type === 'contacts/CONTACT_TYPE_ORGANIZATION') {
    if (contact.data.rollSokande) {
      return true
    }
  }
  return false
}

function validate (state: Object): ?Array<Validation> {
  const contacts = state.contacts.contactList

  const invalidContacts = contacts.filter(organisationerSomArSokande)
  const utanOmbud = invalidContacts.filter(contact => (!harOmbud(contacts, contact.id)))
  if (utanOmbud.length > 0) {
    return createError(utanOmbud)
  }
  return undefined
}

export default validate
