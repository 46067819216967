import {Svar} from "./Forratningsarende/Svar";
import {Handling} from "./Handling";

export type Svarslista = {
    id: string
    skapad: string
    arendenummer: string
    svar?: Svar
    handlingar: Handling[]
    efterfragaDeltagandePaSammantrade?: boolean
};

export type Svarslistor = {
    besvarade: Svarslista[]
    obesvarade: Svarslista[]
};

export enum DeltarPaSammantrade {
    EJANGIVET = "EjAngivet",
    JA = "Ja",
    NEJ = "Nej"
}
