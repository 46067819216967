import {Arende, Arendetyp} from 'domain/Arende'
import {ARENDE_STATUS, MYNDIGHET_LANTMATERIET, ROLL_SOKANDE} from 'utils/globalConstants'
import {Forrattningsarende} from "../../domain/Forratningsarende/Forratningsarende";

export const fakedLoadingTime = 500

export const loadListTestData: Arende[] = [
    {
        nummer: 'TS0001',
        beskrivning: 'Per SLM testärende i utvecklingsmiljön',
        kommuner: [
            'LILLA MO'
        ],
        status: ARENDE_STATUS.GRANSKNING,
        startDate: '2017-10-06T00:00:00.000Z',
        handlaggareNamn: 'Peter Trotest',
        roller: [],
        tillatXmlInskick: true,
        mottagandeMyndighet: 'Lantmäteriet',
        arendetyp: Arendetyp.F
    },
    {
        nummer: 'TS0002',
        beskrivning: 'AÅ testar omarrondering\nsdASDasdASasASSSSSSSSSSSASDasdAS',
        kommuner: [
            'DALNÄS',
            'ÖSTERHAMN'
        ],
        tillatXmlInskick: false,
        status: ARENDE_STATUS.UTREDNING_PROVNING,
        startDate: '2017-10-04T00:00:00.000Z',
        handlaggareNamn: 'Bertil Trotest',
        roller: [],
        mottagandeMyndighet: 'Lantmäteriet',
        arendetyp: Arendetyp.F
    },
    {
        nummer: 'TS0003',
        beskrivning: 'Testärende 3',
        kommuner: [
            'DALNÄS',
            'ÖSTERHAMN'
        ],
        tillatXmlInskick: true,
        status: ARENDE_STATUS.BESLUT_TAGET,
        startDate: '2017-10-08T00:00:00.000Z',
        handlaggareNamn: 'Kalle Kuling',
        roller: [],
        mottagandeMyndighet: 'Lantmäteriet',
        arendetyp: Arendetyp.F
    },
    {
        nummer: 'TS0004',
        beskrivning: 'Testärende 4',
        kommuner: [
            'DALNÄS',
            'ÖSTERHAMN'
        ],
        tillatXmlInskick: true,
        status: ARENDE_STATUS.REGISTRERING,
        startDate: '2017-10-05T00:00:00.000Z',
        handlaggareNamn: 'Test Testsson',
        handlaggareEpost: 'test.testsson@lm.se',
        roller: [],
        mottagandeMyndighet: 'Lantmäteriet',
        arendetyp: Arendetyp.F
    },
    {
        nummer: 'TS0005',
        beskrivning: 'Testärende 5',
        kommuner: [
            'DALNÄS',
            'ÖSTERHAMN'
        ],
        tillatXmlInskick: true,
        status: ARENDE_STATUS.SLUTFORT,
        startDate: '2017-10-08T00:00:00.000Z',
        handlaggareNamn: 'Bil Bulle',
        roller: [],
        mottagandeMyndighet: 'Lantmäteriet',
        arendetyp: Arendetyp.F
    }
]

export const loadSvarslistaTestData = (shouldContainAnswer = false) => {
    return [

        {
            arendenummer: 'TS0001',
            id: 'eb9b91f3-b8e4-422f-a184-282c5e3f6720',
            skapad: '2017-10-08T00:00:00.000Z',
            svar: shouldContainAnswer ?
                {
                    svartyp: 'ETJANST',
                    svarstid: '2022-05-19T10:47:01.194154'
                } : undefined,
            handlingar: [
                {
                    'namn': 'Handling1',
                    'aktbilagebeteckning': 'A1'
                },
                {
                    'namn': 'Handling2',
                    'aktbilagebeteckning': 'A2'
                }
            ]
        },
        {
            arendenummer: 'TS0002',
            id: '40205b74-085b-4e8b-ba66-9619eaed6380',
            skapad: '2017-10-08T00:00:00.000Z',
            svar: shouldContainAnswer ?
                {
                    svartyp: 'ETJANST',
                    svarstid: '2022-05-19T10:47:59.103877'
                } : undefined,
            handlingar: [
                {
                    'namn': 'Handling3',
                    'aktbilagebeteckning': 'A3'
                },
                {
                    'namn': 'Handling4',
                    'aktbilagebeteckning': 'A4'
                }
            ]
        },
        {
            arendenummer: 'TS0004',
            id: '40205b74-085b-4e8b-ba66-9619eaed6380',
            skapad: '2017-10-08T00:00:00.000Z',
            svar: shouldContainAnswer ?
                {
                    svartyp: 'ETJANST',
                    svarstid: '2022-05-19T10:57:02.305438'
                } : undefined,
            handlingar: [
                {
                    'namn': 'Handling3',
                    'aktbilagebeteckning': 'A3'
                },
                {
                    'namn': 'Handling4',
                    'aktbilagebeteckning': 'A4'
                }
            ]
        }
    ]
}

export function fakeLoadList() {
    return new Promise((resolve) => {
        
        setTimeout(() => resolve({arenden: loadListTestData}), fakedLoadingTime)
    })
}

export const loadTestData:Forrattningsarende = {
    fastighetsArendeOversikt: {
        nummer: 'TS0001',
        beskrivning: 'Per SLM testärende i utvecklingsmiljön',
        kommuner: null,
        roller: [ROLL_SOKANDE],
        status: ARENDE_STATUS.UTREDNING_PROVNING,
        startDate: '2020-10-04T09:45:27.282+0000',
        handlaggareNamn: 'Peter Trotest',
        endDate: '2020-10-05T09:45:27.282+0000',
        lan: 'Ängland',
        handlaggareEpost: 'test.testsson@lm.se',
        handlaggareTelefon: '070-13371337',
        tillatXmlInskick:true,
        mottagandeMyndighet: MYNDIGHET_LANTMATERIET,
        aktbeteckning: undefined,
        arendetyp: Arendetyp.F
    },
    documentDetails: [{
        documentTypeID: 'YTTRANDE',
        documentNumber: '18765',
        documentName: 'Andelstalslängd 69S',
        publishedDate: '2020-10-01T09:59:35.172+0000',
        akt: null
    }, {
        documentTypeID: 'YTTRANDE',
        documentNumber: '17100',
        documentName: 'Redogörelse-gränsmärke 1S',
        publishedDate: '2020-10-02T09:59:35.172+0000',
        akt: 'RE'
    }, {
        documentTypeID: 'KARTA',
        documentNumber: '20020',
        documentName: 'TROSSEN-stor karta - För stor Tiff',
        publishedDate: '2020-10-03T09:59:35.172+0000',
        akt: null
    }, {
        documentTypeID: 'KARTA',
        documentNumber: '22846',
        documentName: 'Plankarta Kolbäcksån',
        publishedDate: '2020-10-04T09:59:35.172+0000',
        akt: null
    }, {
        documentTypeID: 'KARTA',
        documentNumber: '20286',
        documentName: 'Förrättningskarta_A4S',
        publishedDate: '2020-10-05T09:59:35.172+0000',
        akt: 'KA'
    }, {
        documentTypeID: 'KARTA',
        documentNumber: '20288',
        documentName: 'MergedMaps_11S',
        publishedDate: '2020-10-06T09:59:35.172+0000',
        akt: 'IS'
    }, {
        documentTypeID: 'BEKRÄFT',
        documentNumber: '17878',
        documentName: 'Bekräftelse av ansökan',
        publishedDate: '2020-10-07T09:59:35.172+0000',
        akt: null
    }],
    fastighetsArendeDagbok: {
        lantmatarDagbokAnteckning: [{
            date: '2020-10-05T09:45:27.282+0000',
            anteckning: 'Saker händer här',
            akt: 'Akt 1'
        }, {
            date: '2020-10-05T09:45:27.282+0000',
            anteckning: 'Saker händer här',
            akt: null
        }],
        arendeKalenderPosts: [{
            date: '1970-01-16T11:00:08.907+0000',
            activity: 'Testdata nr 1 - det här är en nyhetstext som är ganska lång. Den bara fortsätter och fortsätter.\nAntal besökare som lämnar synpunkter på planförslaget har nu börjat avta. Vi har tagit emot många frågor vid besöken och via telefon samt via brev. Vi har för avsikt att besvara alla frågor men beroende på mängden kommer endel att få vänta till efter helgerna på svar. I januari kommer vi att påbörja revideringar av planen. Dessa revideringar kommer sedan att kommuniceras med de markägare som berörs.'
        }, {
            date: '1970-01-17T11:00:08.907+0000',
            activity: 'Testdata nr 2 - det här är en nyhetstext som är ganska lång. Den bara fortsätter och fortsätter.\nAntal besökare som lämnar synpunkter på planförslaget har nu börjat avta. Vi har tagit emot många frågor vid besöken och via telefon samt via brev. Vi har för avsikt att besvara alla frågor men beroende på mängden kommer endel att få vänta till efter helgerna på svar. I januari kommer vi att påbörja revideringar av planen. Dessa revideringar kommer sedan att kommuniceras med de markägare som berörs.'
        }, {
            date: '1970-01-18T11:00:08.907+0000',
            activity: 'Testdata nr 3 - det här är en nyhetstext som är ganska lång. Den bara fortsätter och fortsätter.\nAntal besökare som lämnar synpunkter på planförslaget har nu börjat avta. Vi har tagit emot många frågor vid besöken och via telefon samt via brev. Vi har för avsikt att besvara alla frågor men beroende på mängden kommer endel att få vänta till efter helgerna på svar. I januari kommer vi att påbörja revideringar av planen. Dessa revideringar kommer sedan att kommuniceras med de markägare som berörs.'
        }, {
            date: '1970-01-19T11:00:08.907+0000',
            activity: 'Testdata nr 4 - det här är en nyhetstext som är ganska lång. Den bara fortsätter och fortsätter.\nAntal besökare som lämnar synpunkter på planförslaget har nu börjat avta. Vi har tagit emot många frågor vid besöken och via telefon samt via brev. Vi har för avsikt att besvara alla frågor men beroende på mängden kommer endel att få vänta till efter helgerna på svar. I januari kommer vi att påbörja revideringar av planen. Dessa revideringar kommer sedan att kommuniceras med de markägare som berörs.'
        }]
    }
}

export function fakeLoad() {
    return new Promise((resolve) => {
        setTimeout(() => resolve(loadTestData), fakedLoadingTime)
    })
}
