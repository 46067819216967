import React, {useCallback, useEffect, useState} from "react";
import {Komplettering} from "../../../../domain/Komplettering";
import {ListGroup} from "redet-react-components";
import {Svarslista} from "../../../../domain/Svarslistor";
import {sortBy} from "../../../../helpers/arrays";
import {KompletteringSubmittedListItem} from "./listitems/KompletteringSubmittedListItem";
import KompletteringHelper, {ARENDE_LIST_PAGE_SIZE} from "../../../../helpers/KompletteringHelper";

interface Props {
    title?: string
    introduction?: string
    kompletteringar: Komplettering[]
    svarslistor: Svarslista[]
    id?: string
}

/**
 * Skapar en paginerad lista med ETT URVAL avslutade kompletteringar och svarslistor och dess status
 * @param title
 * @param kompletteringar
 * @param svarslistor
 * @param id
 * @param rest
 * @constructor
 */
export function AvslutadeKompletteringarList({title, introduction, kompletteringar, svarslistor, id, ...rest}: Props) {

    //lokal typ för hantering av items
    type Aktivitet = { id: string, header: string, submittedDate?: string }
    const createAktiviteter = useCallback((): Aktivitet[] => {
        const list: Aktivitet[] = [
            ...kompletteringar.slice().map(komp => {
                return {
                    id: komp.kompletteringId,
                    header: KompletteringHelper.getKompletteringTypeTitleByItem(komp),
                    submittedDate: komp.inskickadDatum
                }
            }), ...svarslistor.slice().map(lista => {
                return {
                    id: lista.id,
                    header: 'Delgivningsbekräftelse',
                    submittedDate: lista.svar?.svarstid
                }
            }) ?? []]
        return sortBy(list, 'submittedDate').reverse()
    }, [kompletteringar, svarslistor])

    const [initList, setInitList] = useState<Aktivitet[]>([])
    const [aktiviteterList, setAktiviteterList] = useState<Aktivitet[]>([])

    const listGroupOptions = {
        list: initList,
        listSetter: setAktiviteterList,
        paginationConfig: {
            initPage: 0,
            maxNoPageButtons: 5,
            pageSize: ARENDE_LIST_PAGE_SIZE,
            onPageChangeScrollToTopOfList: false
        }
    }

    useEffect(() => {
        setInitList(createAktiviteter())
    }, [kompletteringar, svarslistor])

    return (<ListGroup title={title ?? ''}
                       description={<p>{introduction}</p>}
                       listOptions={listGroupOptions}
                       id={id}
                       {...rest}>

        {aktiviteterList.map(aktivitet =>
            <KompletteringSubmittedListItem key={`komplettering-item-${aktivitet.id}`}
                                            id={aktivitet.id}
                                            header={aktivitet.header}
                                            submittedDate={aktivitet.submittedDate}/>
        )}

        {initList.length === 0 &&
            <section className='content'>
                <p>
                    <span className='additional_information'>Det finns inga slutförda aktiviteter för det här ärendet.</span>
                </p>
            </section>
        }
    </ListGroup>)
}

export default AvslutadeKompletteringarList