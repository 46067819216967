const BROWSER_UPDATE_SHOW = 'browserUpdate/BROWSER_UPDATE_SHOW'
const BROWSER_UPDATE_HIDE = 'BROWSER_UPDATE_HIDE'

const initialState = {
  browserUpdateVisible: false,
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case BROWSER_UPDATE_SHOW:
      return {
        ...state,
        browserUpdateVisible: true
      }
    case BROWSER_UPDATE_HIDE:
      return {
        ...state,
        browserUpdateVisible: false
      }
    default:
      return state
  }
}

export function showBrowserUpdate () {
  return {
    type: BROWSER_UPDATE_SHOW
  }
}

export function hideBrowserUpdate () {
  return {
    type: BROWSER_UPDATE_HIDE
  }
}