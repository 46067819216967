import Log from 'utils/log'

// Responsible for keeping the session alive by pinging the backend as long as the user is active
class UserActivityMonitor {
  constructor (pingFunc, destructors) {
    this.log = new Log(this.constructor.name)
    this.pingFunc = pingFunc
    this.intervalTime = 10000
    this.timeoutId = this.startKeepSessionAlivePing()

    destructors.push(() => {
      clearInterval(this.timeoutId)
    })
  }

  startKeepSessionAlivePing () {
    const intervalId = setInterval(() => {
      if (this.userIsActive) {
        this.userIsActive = false
        this.log.trace('User is active. Executing ping funciton to keep session alive.')
        // Dispatch save-action to keep session alive
        this.pingFunc()
      }
    }, this.intervalTime)
    return intervalId
  }

  registerActivity () {
    this.userIsActive = true
  }
}

export default (UserActivityMonitor)