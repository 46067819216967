import {filesEqual} from "../../../../../helpers/fileHelpers";
import {MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING, MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL, MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING} from "../../../../../utils/globalConstants";

export type FilteredFilesResult = {
    filtered: File[]
    message?: string
}

export default class BifogadFileFilter {
    static filterByDuplicates(list: File[], selected: File[]) {
        const result: FilteredFilesResult = {
            filtered: []
        }

        selected.forEach(added => {
            const existsInList = !!list.find(listfile => filesEqual(listfile, added))
            const existsInFiltered = !!result.filtered.find(filteredfile => filesEqual(filteredfile, added))
            if (existsInList || existsInFiltered) {
                result.message = 'Du har valt samma fil flera gånger, dubbletter har därför filtrerats bort.'
            } else {
                //fil ok, lägg till i resultat
                result.filtered.push(added)
            }
        })

        return result;
    }

    static filterByFileType(selected: File[]) {
        const result: FilteredFilesResult = {
            filtered: []
        }

        result.filtered = selected.filter(file => file.type && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'application/pdf'))
        if (selected.length !== result.filtered.length)
            result.message = 'En eller flera av de filer som du valt har en otillåten filtyp och har därför filterats bort.'

        return result;
    }

    static filterByDistinctFileFormat(list: File[], selected: File[]) {
        const result: FilteredFilesResult = {
            filtered: []
        }

        const imageFilter = (file: File) => file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
        const pdfFilter = (file: File) => file.type === 'application/pdf'
        //filter images

        const images = selected.filter(imageFilter)
        const pdfs = selected.filter(pdfFilter)

        //validera att endast en filtyp tillåts per handling
        if (images.length > 0 && pdfs.length > 0) {
            result.message = 'En bifogad handling kan bara innehålla filer av samma typ. Skapa separata bifogade handlingar för att ladda upp filer av olika typ.'
            return result;
        }
        if (list.length > 0) {
            const existsingImages = list.filter(imageFilter)
            const existingPdfs = list.filter(pdfFilter)

            if (images.length > 0 && existingPdfs.length > 0) {
                result.message = 'Det finns redan minst en PDF-fil vald. Skapa en separat bifogad handling för bildfiler.'
                return result
            }
            if (pdfs.length > 0 && existsingImages.length > 0) {
                result.message = 'Det finns redan minst en bildfil vald. Skapa en separat bifogad handling för PDF-filer.'
                return result
            }
        }

        result.filtered = images.length > 0 ? images : pdfs
        return result;
    }

    static filterBySize(selected: File[]) {
        const result: FilteredFilesResult = {
            filtered: []
        }
        const maxFileSizeBytes = MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL * 1024 * 1024
        result.filtered = selected.filter(file => file.size <= maxFileSizeBytes)
        if (result.filtered.length !== selected.length)
            result.message = `En eller fler av de valda filerna har tagits bort eftersom maxstorleken på ${MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_FIL}MB överskreds.`

        return result;
    }

    static filterByAccumulatedSize(list: File[], selected: File[]) {
        const result: FilteredFilesResult = {
            filtered: []
        }
        const maxTotalFileSizeBytes = MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING * 1024 * 1024
        const totalSize = list.map(existing => existing.size).reduce((x, y) => x + y, 0)
        selected.forEach(file => {
            const filteredFilesSize = result.filtered.map(passed => passed.size).reduce((x, y) => x + y, 0)
            if ((totalSize + filteredFilesSize + file.size) <= maxTotalFileSizeBytes) {
                result.filtered.push(file)
            } else {
                result.message = `En eller fler av de valda filerna har tagits bort eftersom maxstorleken på ${MAX_SIZE_IN_MB_ALLOWED_FOR_BIFOGAD_HANDLING}MB överskreds.`
            }
        })

        return result;
    }

    static filterByCount(list: File[], selected: File[]) {
        const result: FilteredFilesResult = {
            filtered: selected.slice(0, MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING - list.length)
        }
        if (result.filtered.length !== selected.length) {
            result.message = `En bifogad handling får max innehålla ${MAX_NUMBER_OF_FILES_IN_BIFOGAD_HANDLING} filer`
        }

        return result;
    }
}