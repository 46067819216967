import {BekraftaDelgivningContext} from "../BekraftaDelgivningContext";
import {Svarslista} from "../../../../domain/Svarslistor";
import WizardDialog, {WizardDialogStep} from "../../../wizard/WizardDialog";
import ValjDelgivningStep from "./ValjDelgivningStep";
import {DelgivningKontaktuppgifterStep} from "./DelgivningKontaktuppgifterStep";
import {DelgivningHandlingarConfirmStep} from "./DelgivningHandlingarConfirmStep";
import React, {useEffect, useState} from "react";
import {ArendeViewContext} from "../../new-plf-layout/ArendeViewContext";

type Props = {
    obesvaradeSvarslistor: Svarslista[]
    isLoadingSvarslistor: boolean,
    onCloseDialog: () => void,
    onSubmitted: () => void
}

function BekraftaDelgivningWizardDialog({obesvaradeSvarslistor, isLoadingSvarslistor, onSubmitted, onCloseDialog}: Props) {

    const {delgivning} = React.useContext(BekraftaDelgivningContext);
    const {setShowCloseModalConfirmationDialog, setOnConfirmClose} = React.useContext(ArendeViewContext);

    const [currentStep, setCurrentStep] = useState<number>(1)

    useEffect(() => {
        setOnConfirmClose(() => onCloseDialog)
    }, [])

    const onCloseModal = () => {
        if (delgivning && currentStep > 1) {
            //show confirm dialog
            setShowCloseModalConfirmationDialog(true)
        } else {
            onCloseDialog()
        }
    }

    return (<>
        <WizardDialog
            title={'Bekräfta delgivning'}
            cancelFunction={() => onCloseModal()}
            defaultNav={false}
            onStepChange={(evt: any) => setCurrentStep(evt.activeStep)}
            id="BekraftaDelgivningWizardModalId">

            <WizardDialogStep stepTitle={'Välj delgivning'}>
                <ValjDelgivningStep obesvaradeSvarslistor={obesvaradeSvarslistor}
                                    svarslistorIsLoading={isLoadingSvarslistor}/>
            </WizardDialogStep>

            <WizardDialogStep stepTitle={'Kontaktuppgifter'}>
                <DelgivningKontaktuppgifterStep/>
            </WizardDialogStep>

            <WizardDialogStep stepTitle={'Bekräfta'}>
                <DelgivningHandlingarConfirmStep obesvaradeSvarslistor={obesvaradeSvarslistor}
                                                 svarslistorIsLoading={isLoadingSvarslistor}
                                                 onSubmitSuccess={onSubmitted}
                                                 onCloseModal={onCloseDialog}/>
            </WizardDialogStep>

        </WizardDialog>
    </>)
}

export default BekraftaDelgivningWizardDialog