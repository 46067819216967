import Log from '../utils/log'
import {FetchClientError, logError, NetworkError} from '../utils/errorHandler'
import {Fetcher} from "./fetcher";
import {DeltarPaSammantrade} from "../domain/Svarslistor";

const SVARSTJANST_BACKEND_PATH = '/api/svarstjanst-backend'
const APPLICATION_JSON = 'application/json'
const log = new Log('svarstjanstRepository')

export class BehorighetSaknasException {
    errorMessage: Response

    constructor(response: Response) {
        this.errorMessage = response
    }
}

export class SvartjanstServerErrorException {
    errorMessage: Response

    constructor(response: Response) {
        this.errorMessage = response
    }
}

class CouldNotAnswerSvarslistaException {
    errorMessage: Response

    constructor(response: Response) {
        this.errorMessage = response
    }
}

export type SvaraPaDelgivningRequest = {
    deltarPaSammantrade?: DeltarPaSammantrade,
    kontaktuppgift?: {
        telefonnummer?: string,
        epostadress?: string
    }
}

export function fetchSvarslistor(fetcher: Fetcher, arendenummer: string | undefined = undefined) {
    let url = `${SVARSTJANST_BACKEND_PATH}/user/svarslistor/delgivning`
    if (arendenummer)
        url = `${url}?arendenummer=${encodeURIComponent(arendenummer)}`
    return fetcher.fetching(url, {
            cache: 'no-store',
            method: 'GET',
        },
        {'Content-Type': 'application/json'}
    ).then(response => {
        return response.json()
    }).then((svarslistor) => {
        log.info('Svarslista successfully loaded from server')
        return svarslistor
    }).catch(err => {
        log.error('Error encountered while loading svarslista from server')
        let error = err
        // Custom error handling
        if (err instanceof TypeError) {
            error = new NetworkError('TypeError', err.message, url)
        }
        if (err instanceof Response) {
            error = new FetchClientError(err, 'GET')
        }
        logError(error)
        throw(error)
    })
}

/**
 * @deprecated utbytt till confirmSvarslista för hantering av närvaro på sammanträde och kontaktuppgifter
 */
export function answerSvarslista(fetcher: Fetcher, svarslistaId: string) {
    const url = `${SVARSTJANST_BACKEND_PATH}/svarslista/${svarslistaId}/svara`
    return fetcher.fetching(url, {method: 'POST',})
        .then(response => {
            if (response.status === 204) {
                log.info('Svarslista successfully answerd.')
                return 204
            } else {
                log.error('Error encountered while answering svarslista.')
                logError(response)
                throw new CouldNotAnswerSvarslistaException(response)
            }
        })
        .catch(err => {
            log.error('Error encountered while loading svarslista from server.')
            let error = err
            // Custom error handling
            if (err instanceof TypeError) {
                error = new NetworkError('TypeError', err.message, url)
            }
            if (err instanceof Response) {
                error = new FetchClientError(error, 'POST', '', 'Promise resolvas! Se nästa logg-meddelande...')
                err.json().then((json) =>
                    logError(new FetchClientError(err, 'POST', '', json.felmeddelande))
                )
            }
            logError(error)
            throw(error)
        })
}

export function svaraPaDelgivning(fetcher: Fetcher, svarslistaId: string, request: SvaraPaDelgivningRequest): Promise<number> {
    const url = `${SVARSTJANST_BACKEND_PATH}/svarslista/delgivning/${svarslistaId}/svara`
    return fetcher.fetching(url,
        {
            cache: 'no-store',
            method: 'POST',
            body: JSON.stringify(request)
        }, {'Content-type': APPLICATION_JSON})
        .then(async response => {
            switch (response.status) {
                case 204:
                    log.info('Svarlista successfully answered')
                    return 204
                default:
                    log.error('Error encountered while answering svarslista')
                    const json = await response.json()
                    logError(json)
                    throw new CouldNotAnswerSvarslistaException(response)
            }
        })
        .catch(async err => {
            log.error(`Error encountered when answering svarslista from server (id: ${svarslistaId})`)
            // Custom error handling
            logError(err)
            if (err instanceof TypeError) {
                throw new NetworkError('TypeError', err.message, url)
            }
            if (err instanceof Response) {
                throw new FetchClientError(err, 'POST', '', await err.json())
            }
            throw err
        })
}
