import { Fastighet } from 'domain/Ansokan'
import { Fetcher } from 'repository/fetcher'
import { RootState } from 'store'
import {Dispatch} from 'redux'
import { getSDGSigningStatus, initiateSDGSigning, submitSDG, withdrawSDGSigning } from 'repository/sgdRepository'

export function initiateSDGSigningInstance (fastigheter: Fastighet[], handlingar: File[], ansokan: File) {
    return function action (dispatch: Dispatch<any>, getState: () => RootState) {
      const fetcher = new Fetcher(dispatch, getState, false)
      return initiateSDGSigning(fetcher, fastigheter, handlingar, ansokan)
        .then(response => {
          return response
        }).catch((error: Error) => {
          throw error
        })
    }
  }
  
  export function getSDGSigningStatusFromAnsokanId (ansokanId: string) {
    return function action (dispatch: Dispatch<any>, getState: () => RootState) {
      const fetcher = new Fetcher(dispatch, getState, false)
      return getSDGSigningStatus(fetcher, ansokanId)
        .then((response) => {
          return response
        }).catch((error: Error) => {
          throw error
        })
    }
  }
  
  export function withdrawSDGSigningInstance (ansokanId: string) {
    return function action (dispatch: Dispatch<any>, getState: () => RootState) {
      const fetcher = new Fetcher(dispatch, getState, false)
      return withdrawSDGSigning(fetcher, ansokanId)
        .catch((error: Error) => {
          throw error
        })
    }
  }

  export function submitSDGInstance (ansokanId: string) {
    return function action (dispatch: Dispatch<any>, getState: () => RootState) {
      const fetcher = new Fetcher(dispatch, getState, false)
      return submitSDG(fetcher, ansokanId)
        .catch((error: Error) => {
          throw error
        })
    }
  }