export const forvarvshandling = <>
  <p>Handling som överlåtelse av mark grundas på och som kan läggas till grund för den
    åtgärd du ansöker om.
    <br/>Exempel på förvärvshandlingar är:</p>

  <ul>
    <li> Köpeavtal/köpebrev</li>
    <li> Gåvoavtal/gåvobrev</li>
    <li> Bytesavtal</li>
    <li> Legat (testamente)</li>
  </ul>
</>
