import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchAutoCompleteOptions, setFastighetSearchString } from 'redux/modules/fastigheter'
//@ts-ignore
import Autosuggest from 'react-autosuggest'
//@ts-ignore
import { ButtonSub, FormFieldset } from 'redet-react-components'
import { useQuery } from '@tanstack/react-query'
import { RootState } from 'store'
import { useAppDispatch } from 'hooks'
import { AnsokanWizardSDGContext } from 'pages/euCitizenApplication/AnsokanWizardSDGContext'
import { useTranslation } from 'react-i18next'

type Props = {
  swedishLetters?: boolean,
  searchFastighetCallback: (searchString: string) => void
}

export function FastighetsvalSearchBoxUnconnected(props: Props) {

  const searchFeedback = useSelector((store: RootState) => store.fastigheter.fastighetSearchFeedback)

  const {fastigheter} = useContext(AnsokanWizardSDGContext)

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showSweLetters, setShowSweLetters] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [queryKey, setQueryKey] = useState('')
  const [fastighetsData, setFastighetsData] = useState<string[]>([])

  let åBtn = 'Å';
  let äBtn = 'Ä';
  let öBtn = 'Ö';

  const fetchOptions = (query: any) => {
    return dispatch(fetchAutoCompleteOptions(query.queryKey[0]))
  }

  const { data } = useQuery({
    queryKey: [queryKey],
    queryFn: fetchOptions,
    initialData: [],
    onSuccess: (fastData) => {setFastighetsData(fastData)}
  })

  function loadAutoCompleteSuggestions (updatedSearchString: string) {
    setQueryKey(updatedSearchString)
  }

  function clearInputField () {
    setSearchString('')
  }

  function showSwedishLetters () {
    setShowSweLetters(!showSweLetters)
  } 

  function ÅÄÖBtn(swedishLetter: string) {
    loadAutoCompleteSuggestions(searchString + swedishLetter);
    setSearchString(searchString + swedishLetter)
  }

  function endsWithNumber(searchString: string) {
    return /[0-9]+$/.test(searchString);
  }

  function addSpaceIfFastighetDoesNotEndWithNmbrOrHyphen(searchString: string) {

    if(searchString.endsWith('-')) {
      loadAutoCompleteSuggestions(searchString);
      return;
    } else if (endsWithNumber(searchString)) {
      loadAutoCompleteSuggestions('');
      return;
    }
    const searchStringWithSpace = searchString + ' ';
    setSearchString(searchStringWithSpace)
    loadAutoCompleteSuggestions(searchStringWithSpace);
  }
  
  function handleInputChange(_unused: any, { newValue, method } : {newValue: string, method: string}) {
    //Rensar felmeddelanden
    dispatch(setFastighetSearchString(''))

    if (method === 'type') {
      loadAutoCompleteSuggestions(newValue);
    }
    setSearchString(newValue?.toUpperCase());
  }

  return (
      <>
        <FormFieldset title={t('property.propUnits')}
                      description={<>{t('property.addProperty')} <br/> {t('property.description')}
                      {fastigheter.length === 0 && <p><strong>{t('property.mustAddProperty')}</strong></p> }
                      </>}
                      id="fastighetSearchFieldId">
          <div className="form-group">
           <label className="label" htmlFor={'fastighetsbeteckning'}>{t('property.designation')}</label>
            <div className="flex-line">
              <div className="flex-group">
                <div className="enak-autocomplete">
                  <Autosuggest
                    suggestions={fastighetsData}
                    onSuggestionsFetchRequested={() => {}}
                    onSuggestionSelected={(_unused: any, { suggestionValue } : {suggestionValue:string}) => addSpaceIfFastighetDoesNotEndWithNmbrOrHyphen(suggestionValue)}
                    onSuggestionsClearRequested={() => {}}
                    alwaysRenderSuggestions={false} 
                    getSuggestionValue={(suggestion: string) => suggestion}
                    renderSuggestion={(suggestion: string) => (<>{suggestion}</>)}
                    renderInputComponent={(inputProps: any) => (
                      <input {...inputProps} aria-multiline={false} aria-label={t('property.ariaSearch')}/>
                    )}
                    inputProps={
                      {                        
                        value: searchString,         
                        onChange: handleInputChange,       
                        type: 'search',
                        className: 'form-input-text',
                        id: 'fastighetsbeteckning',
                        title: t('property.inputTitle'),
                        placeholder: t('property.placeholder'),
                        autoComplete: 'off',
                      }
                    }
                    renderSuggestionsContainer={(props: { containerProps: any, children: any, query: any }) =>
                      //WCAG kräver att role:listbox ska bort
                    {
                      delete props.containerProps.role
                      return <div {...props.containerProps} aria-label={t('property.ariaResult')} role="listbox">
                        {props.children}
                      </div>
                    }}
                    theme={{
                      suggestionsList: 'form-autocomplete',
                      suggestionHighlighted: 'form-autocomplete-suggestion-active',
                    }}
                  />
                </div>
                {searchFeedback.length > 0 &&
                  <label className="validation" data-selenium-id="fastighetssok-felmeddelande" 
                  htmlFor="fastighetsbeteckning" aria-label={t('property.ariaErrMsg')}>{t(searchFeedback)}</label>
                }
              </div>
              {showSweLetters && 
                  <div className="flex-group separated-buttons" id="umlaut">
                    <ButtonSub className="button-sub button-umlaut" onClick={() => ÅÄÖBtn(åBtn)} text={'Å'} tabIndex={0}/>
                    <ButtonSub className="button-sub button-umlaut" onClick={() => ÅÄÖBtn(äBtn)} text={'Ä'} tabIndex={0}/>
                    <ButtonSub className="button-sub button-umlaut" onClick={() => ÅÄÖBtn(öBtn)} text={'Ö'} tabIndex={0}/>
                </div>
              }
              {props.swedishLetters === true &&
                <ButtonSub id="toggle-umlaut" onClick={showSwedishLetters} text={'ÅÄÖ'}/>
              }
              <ButtonSub text={t('property.addBtn')}
                         icon="add"
                         onClick={() => {
                          props.searchFastighetCallback(searchString)
                          clearInputField()}}
                         id="fastighetssok-lagg-till"/>
            </div>
          </div>
        </FormFieldset>
      </>
    )
  }


export default (FastighetsvalSearchBoxUnconnected)