//@ts-ignore
import { ExternalLink, SidePanelChapterHeading, SidePanelSection } from 'redet-react-components'

export const BifogadeHandlingarArendeHelpContent =
  <>
    <SidePanelChapterHeading sectionTitle={'Handlingar'}
                             header={'Bifogade handlingar'}>
    </SidePanelChapterHeading>
    <SidePanelSection heading={'Bifoga handlingar'}
                      id={'term-bifogadehandlingar'}>
      <p>
        Du kan ladda upp följande filformat: JPG, JPEG, GIF, PNG, PDF. Välj vilken typ av handling som den uppladdade
        filen avser. Du kan välja att ladda upp flera filer som hör till en och
        samma handling och
        de uppladdade filerna läggs då samman till en handling.
        T.ex. om du har flera filer som utgör en tresidig handling, då laddar du upp de tre filerna och
        filerna läggs då samman till en handling i tjänsten och motsvarar den ursprungliga handlingen.
        Om du har flera filer som utgör separata handlingar, skickar du in varje handling för sig.
      </p>
    </SidePanelSection>
    <SidePanelSection heading={'Köp eller annan förvärvshandling'}
                      id={'term-forvarvshandling'}
                      text={'      Det kan vara ett köpeavtal, köpebrev, gåvobrev eller bytesavtal som visar att du förvärvat fastighet eller del av\n' +
                        '            fastighet. Det kan även vara ett testamente där berörd fastighet eller del av fastighet särskilt testamenterats till viss\n' +
                        '            person (s.k. legat). Om förvärvshandlingen ligger till grund för den åtgärd du vill genomföra ska den bifogas till\n' +
                        '            ansökan. OBS! Om förvärvshandlingen avser en del av fastighet (visst markområde) får inte förvärvet vara äldre än 6\n' +
                        '            månader då ansökan om fastighetsbildning kommer in till Lantmäterimyndigheten. Vid en fastighetsreglering (marköverföring)\n' +
                        '            får det inte finnas några ouppfyllda villkor kvar i handlingen som gör att överlåtelsen kan hävas. För köp behöver\n' +
                        '            samtliga nuvarande ägare och samtliga köpare skriva under den fysiska handlingen innan den skickas in. Gäller även annan\n' +
                        '            förvärvshandling. Du behöver inte skicka in förvärvshandling enbart som bevis på att du äger en viss fastighet om du redan\n' +
                        '            har lagfart på denna.'}
    />

    <SidePanelSection heading={'Bygglov eller förhandsbesked'}
                      id={'term-bygglov'}
                      text={'Om du ska kunna uppföra en ny byggnad kan förhandsbesked eller bygglov behövas. Det är den kommunala byggnadsnämnden som beviljar\n' +
                        '            förhandsbesked och bygglov. Har du frågor som rör detta ska du vända dig till kommunen.'}/>

    <SidePanelSection heading={'Överenskommelse'}
                      id={'term-overenskommelse'}
                      text={'Ofta är berörda fastighetsägare överens om ansökt åtgärd. Finns det överenskommelser som ska läggas till grund för\n' +
                        '                Lantmäterimyndighetens\n' +
                        '                beslut ska dessa bifogas. Den fysiska överenskommelsen ska skrivas under av samtliga delägare i berörd fastighet innan den skickas in.\n' +
                        '                Ofta behöver även maka/makes medgivande till åtgärden finnas med på överenskommelsen för en fastighet som avstår mark eller upplåter\n' +
                        '                rättighet.'}/>

    <SidePanelSection heading={'Avtal'}
                      id={'term-avtal'}
                      text={'Avtal som är av betydelse för ansökt åtgärd och som kan läggas till grund för Lantmäterimyndighetens beslut. Det kan t ex vara\n' +
                        '                servitutsavtal (rättighet mellan fastigheter), nyttjanderättsavtal (rättighet mellan fastighet och juridisk/fysisk person) eller avtal\n' +
                        '                mellan makar (för åtgärden sammanläggning). Rättigheter som bildats i samband med Lantmäteriförrättning eller som finns inskrivna i\n' +
                        '                Fastighetsregistret behöver inte bifogas.'}/>

    <SidePanelSection heading={'Behörighetshandling'}
                      id={'term-behorighetshandling'}
                      text={'Om du ansöker på någon annans uppdrag eller företräder någon i förrättningen ska du bifoga en fullmakt som styrker din behörighet. Om\n' +
                        '                ett\n' +
                        '                dödsbo äger fastigheten ska en bouppteckning bifogas. Ägs fastigheten av en juridisk person är det en fördel om ett registerutdrag\n' +
                        '                från\n' +
                        '                Bolagsverket bifogas. För förening som ansöker om förrättning eller godkänner överenskommelser behöver behörigheten styrkas genom att\n' +
                        '                bifoga stämmoprotokoll och stadgar där behörig firmatecknare framgår.'}/>

    <SidePanelSection heading={'Kartskiss'}
                      id={'term-kartskiss'}
                      text={'Om du inte skapat någon kartskiss i den här tjänsten kan du bifoga en egen skiss.'}/>

    <SidePanelSection heading={'Strandskyddsdispens'}
                      id={'term-strandskyddsdispens'}
                      text={'Beviljad strandskyddsdispens ska bifogas om det finns. Om förrättningen berör mark inom strandskyddat område kan en dispens behövas.\n' +
                        '                Strandskyddet gäller i normalfallet inom 100 meter från sjöar och vattendrag. Det är kommunen som beviljar strandskyddsdispenser. Har\n' +
                        '                du\n' +
                        '                frågor om du behöver en dispens eller inte ska du vända dig till kommunen där fastigheten finns.'}/>

    <SidePanelSection heading={'Jordförvärvstillstånd'}
                      id={'term-forvarvstillstand'}>
      <p>Vissa köp kräver att den som köper fastigheten eller del av den får tillstånd från
        Länsstyrelsen/Jordbruksverket för att köpet
        ska kunna genomföras. Detta kallas förvärvstillstånd. Har du fått förvärvstillstånd ska detta bifogas. Är du
        osäker på om det
        krävs förvärvstillstånd i ditt fall kan du kontakta Länsstyrelsen/Jordbruksverket, eller läsa
        mer <ExternalLink text={'här'}
                          url={'https://webbutiken.jordbruksverket.se/sv/artiklar/vem-behover-jordforvarvstillstand.html'}/>.
      </p>
    </SidePanelSection>

    <SidePanelSection heading={'Övrigt/Annat'}
                      id={'term-annat'}>
      <p>Övriga handlingar som kan vara relevant för ansökt åtgärd, några exempel:</p>
      <h5>Skogsbruksplan</h5>
      <p>En skogsbruksplan består av en karta och en beskrivning av skogen, i ord och siffror, baserad på mätningar och
        bedömningar. På
        kartan är skogen är uppdelad i avdelningar/bestånd. I avdelningsbeskrivningen finns uppgifter om skogstillstånd
        och förslag på
        åtgärder för varje avdelning. I planen brukar det dessutom finnas en <b>sammanställning med uppgifter</b> för
        hela fastigheten.
        Uppgifter om virkesförråd i en skogsbruksplan bygger på stickprov och är därför ungefärliga men kan innehålla
        viktig information
        för Lantmäterimyndigheten. Det kan t ex vid klyvning vara så att parterna inte är överens om ersättningen. Då
        kan en uppdaterad
        skogsbruksplan underlätta Lantmäterimyndighetens arbete.</p>
      <h5>Projekteringshandling till anläggning</h5>
      <p>Om t ex en anläggning ska vara gemensam för flera fastigheter, s k gemensamhetsanläggning, behöver ni ansöka om
        en
        anläggningsförrättning.
        Finns projekteringshandlingar för anläggningen är det en fördel om Lantmäterimyndigheten får ta del av dessa
        handlingar för att
        kunna utforma ett så fullständigt anläggningsbeslut som möjligt. I projekteringshandlingarna förtydligas vad som
        ska göras i de
        kommande anläggningsarbetena.</p>
      <h5>Samrådshandlingar /tillstånd</h5>
      <p>Samråd med exempelvis Byggnadsnämnd eller Länsstyrelsen för de åtgärder du vill genomföra. Ibland kan det
        krävas särskilda
        tillstånd om åtgärden innebär ändrad markanvändning (t ex ändring från jordbruksmark till tomtmark) eller i
        övrigt påverkar
        natur- och kulturmiljö samt andra allmänna intressen. Lantmäterimyndigheten kontrollerar i förrättningen om
        behövliga tillstånd
        finns samt samråder med berörda myndigheter. Men finns redan tillstånd eller samrådshandlingar är det en fördel
        om dessa bifogas.
      </p>
    </SidePanelSection>
  </>