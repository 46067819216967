import {createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState} from "react";
import {BifogadHandlingType} from "../../../domain/BifogadhandlingType";

export type BifogaHandlingContextType = {
    typ?: BifogadHandlingType,
    setTyp: Dispatch<SetStateAction<BifogadHandlingType | undefined>>
    ovrigBeskrivning: string,
    setOvrigBeskrivning: Dispatch<SetStateAction<string>>,
    handlingar: File[],
    setHandlingar: Dispatch<SetStateAction<File[]>>,
    epost: string
    setEpost: Dispatch<SetStateAction<string>>
    clearForm: () => void
    validateForm: () => string[]
}

export const BifogaHandlingContext = createContext<BifogaHandlingContextType>({
    typ: undefined, setTyp: () => {},
    ovrigBeskrivning: '', setOvrigBeskrivning: () => {},
    handlingar: [] as File[], setHandlingar: () => {},
    epost: '', setEpost: () => {},
    clearForm: () => {},
    validateForm: () => []
})

export function BifogaHandlingProvider(props: { children: ReactNode }) {
    const [typ, setTyp] = useState<BifogadHandlingType>()
    const [ovrigBeskrivning, setOvrigBeskrivning] = useState<string>('')
    const [handlingar, setHandlingar] = useState<File[]>([])
    const [epost, setEpost] = useState<string>('')

    const validateForm = (): string[] => {
        const msgs: string[] = []
        if (!typ) {
            msgs.push('Du måste ange en handlingstyp')
        }
        if (handlingar.length === 0) {
            msgs.push('Du måste bifoga minst en fil till handlingen')
        }
        if (!epost) {
            msgs.push('Du måste ange en epost')
        }
        return msgs
    }

    const clearForm = () => {
        setTyp(undefined)
        setOvrigBeskrivning('')
        setHandlingar([]);
        setEpost('')
    }

    return (
        <BifogaHandlingContext.Provider value={useMemo(() => ({
            typ, setTyp,
            ovrigBeskrivning, setOvrigBeskrivning,
            handlingar, setHandlingar,
            epost, setEpost,
            clearForm, validateForm
        }), [typ, ovrigBeskrivning, handlingar, epost])}>
            {props.children}
        </BifogaHandlingContext.Provider>
    )
}