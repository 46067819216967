type Props = {
    content: string,
    title: string,
    width?: string,
    height?: string
}

export function EmbeddedPdf({content, title, width = "100%", height = "800px", ...rest}: Props) {

    return <iframe title={title}
                   width={width}
                   height={height}
                   src={content}
                   {...rest}/>
}