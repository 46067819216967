import React from 'react'
// @ts-ignore
import {ButtonGroup, ButtonSub, ButtonToolbarV2, ModalDialogSub} from "redet-react-components";


function TooManyRequestModal(props: { closeModalCallback: (arg0: boolean) => void}) {
    const header:string = 'Ett tekniskt fel inträffade'
    const body:string = 'Vänligen prova igen eller försök igen senare.'

    function closeModal() {
        props.closeModalCallback(false)
    }

    return (
        <>
            <div>
                    <ModalDialogSub modalTitle={header}
                                    description={body}
                                    acceptButtonText={false}
                                    useDefaultToolbar={false}>
                        <ButtonToolbarV2>
                            <ButtonGroup>
                                <ButtonSub text={'Ok'} onClick={closeModal} icon={'arrow_forward'}/>
                            </ButtonGroup>
                        </ButtonToolbarV2>
                    </ModalDialogSub>
            </div>
        </>
    )
}

export default (TooManyRequestModal)
