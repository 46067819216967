/**
 * Giltigt format:
 *  +460706880178
 *  0706880178
 *
 *  Abonnentnummerlängd
 *  Abonnentnummerlängden för geografiska telefonnummer kan vara fem-, sex-, sju- eller åttasiffrigt
 *  och kan variera inom samma riktnummerområde. För mobilnummer är abonnentnummerlängden alltid sju siffror.
 *
 *  Ett internationellt telefonnummer består av internationellt prefix, landskod,
 *  nationell destinationskod och abonnentnummer.
 *  Telefonnumret får vara högst 15 siffror efter det internationella prefixet
 *
 *  https://www.pts.se/sv/bransch/telefoni/nummer-och-adressering/telefoninummerplanen/telefonnummers-struktur/
 */


export function validatePhonenumber (number, minLength = 5, maxLength = 20) {
  if (number === undefined || number == null) {
    return undefined
  }

  if (number.length < minLength && number.length > 0) {
    return 'Telefonnummer för kort. Får minst vara 5 tecken långt.'
  }

  if (number.length > maxLength) {
    return 'Telefonnummer för långt. Får max vara 20 tecken långt.'
  }

  if (!isValidFormat(number)) {
    return `Ogiltigt format '${number}' får endast innehålla siffror. Om landskod är angivet så är inledande '+' tillåtet.`
  }

  return undefined
}

function isValidFormat (number) {

  let purenumber = undefined
  if (number[0] === '+') {
    purenumber = number.substr(1, number.length - 1)
  } else {
    purenumber = number
  }

  return !isNaN(purenumber)
}