import {
    abortSigning,
    fetchPhosSigningStatus,
    fetchStatus, fetchPreview,
    initiatePhosSigning, initiatePhosSigningV2,
    initiateSigning, fetchAutoPostForm, declineSigning,
} from 'repository/signeringRepository'
import {mockMarkAsSigned} from 'repository/devSupportRepository'
import {Fetcher} from 'repository/fetcher'
import {addProgress, endProgress} from './fullscreenLoading'
import {Dispatch} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {DocumentPreview} from "../../domain/pdf/DocumentAndAttachments";
import {SigningFormValues} from "../../domain/signing/SigningModels";

export const INITIATE_PENDING = 'signeringRepository/INITIATE_PENDING'
export const INITIATE_SUCCESSFUL = 'signeringRepository/INITIATE_SUCCESSFUL'
export const INITIATE_FAILED = 'signeringRepository/INITIATE_FAILED'

export const ABORT_PENDING = 'signeringRepository/ABORT_PENDING'
export const ABORT_SUCCESSFUL = 'signeringRepository/ABORT_SUCCESSFUL'
export const ABORT_FAILED = 'signeringRepository/ABORT_FAILED'

export const MARK_AS_SIGNED_PENDING = 'signeringRepository/MARK_AS_SIGNED_PENDING'
export const MARK_AS_SIGNED_SUCCESSFUL = 'signeringRepository/MARK_AS_SIGNED_SUCCESSFUL'
export const MARK_AS_SIGNED_FAILED = 'signeringRepository/MARK_AS_SIGNED_FAILED'

export const GET_SIGNERING_STATUS_PENDING = 'signeringRepository/GET_SIGNERING_STATUS_PENDING'
export const GET_SIGNERING_STATUS_SUCCESSFUL = 'signeringRepository/GET_SIGNERING_STATUS_SUCCESSFUL'
export const GET_SIGNERING_STATUS_FAILED = 'signeringRepository/GET_SIGNERING_STATUS_FAILED'

export default function reducer(state = {}) {
    return state
}

export function initiateSigningInstance(key: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        dispatch({type: INITIATE_PENDING})
        const fetcher = new Fetcher(dispatch, getState, false)
        return initiateSigning(fetcher, key, getState().featureToggle.SIGNING_SERVICE_MOCK_ENABLED)
            .then(() => {
                dispatch({type: INITIATE_SUCCESSFUL})
            }).catch((err) => {
                dispatch({type: INITIATE_FAILED})
                throw err
            })
    }
}

export function initiatePhosSigningInstance(key: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        dispatch({type: INITIATE_PENDING})
        const fetcher = new Fetcher(dispatch, getState, false)
        return initiatePhosSigning(fetcher, key)
            .then((response) => {
                dispatch({type: INITIATE_SUCCESSFUL})
                return response
            }).catch((error) => {
                dispatch({type: INITIATE_FAILED})
                throw error
            })
    }
}

export function initiatePhosSigningInstanceV2(key: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        dispatch({type: INITIATE_PENDING})
        const fetcher = new Fetcher(dispatch, getState, false)
        return initiatePhosSigningV2(fetcher, key, getState)
            .then((response) => {
                dispatch({type: INITIATE_SUCCESSFUL})
                return response
            }).catch((error) => {
                dispatch({type: INITIATE_FAILED})
                throw error
            })
    }
}

export function abortSigningInstance(key: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        dispatch({type: ABORT_PENDING})
        const fetcher = new Fetcher(dispatch, getState, false)
        return abortSigning(fetcher, key, getState().featureToggle.SIGNING_SERVICE_MOCK_ENABLED)
            .then(() => {
                dispatch({type: ABORT_SUCCESSFUL})
            }).catch((err) => {
                dispatch({type: ABORT_FAILED})
                throw err
            })
    }
}

export function markAnsokanAsSigned(key: string) {
    return function (dispatch: Dispatch, getState: () => RootState) {
        dispatch(addProgress())
        dispatch({type: MARK_AS_SIGNED_PENDING})
        const fetcher = new Fetcher(dispatch, getState)
        return mockMarkAsSigned(fetcher, key)
            .then(() => {
                dispatch({type: MARK_AS_SIGNED_SUCCESSFUL})
            }).catch(() => {
                dispatch({type: MARK_AS_SIGNED_FAILED})
            }).finally(() => {
                dispatch(endProgress())
            })
    }
}

export function fetchSigningStatus(key: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        dispatch({type: GET_SIGNERING_STATUS_PENDING})
        const fetcher = new Fetcher(dispatch, getState)
        return fetchStatus(fetcher, key, getState().featureToggle.SIGNING_SERVICE_MOCK_ENABLED)
            .then((status) => {
                dispatch({type: GET_SIGNERING_STATUS_SUCCESSFUL})
                return status
            }).catch(() => {
                dispatch({type: GET_SIGNERING_STATUS_FAILED})
            }).finally(() => {
                dispatch(endProgress())
            })
    }
}

export function fetchPhosSigningInstanceStatus(key: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState)
        return fetchPhosSigningStatus(fetcher, key)
            .then((status) => {
                return status
            }).catch((error) => {
                return error
            })
    }
}

export function fetchDocumentPreview(requestId: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState)
        return fetchPreview(fetcher, requestId)
            .then((result:DocumentPreview) => result)
            .catch(() => {
              throw Error('Kunde inte hämta förhandsgranskning av dokument')
            })
    }
}

export function fetchSigningFormValues(requestId: string, redirectUrl: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState)
        return fetchAutoPostForm(fetcher, requestId, redirectUrl)
            .then((result: SigningFormValues) => result)
            .catch(() => {
                throw Error('Kunde inte hämta formulär för signering.')
            })
    }
}


export function declineSigningRequest(requestId: string) {
    return function action(dispatch: Dispatch, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState)
        return declineSigning(fetcher, requestId)
            .then(result => result)
            .catch(() => {
                throw Error('Kunde inte avböja signering.')
            })
    }
}

