import React from 'react'
//@ts-ignore
import {Column, ExternalLink, ModalDialogTop, Row} from 'redet-react-components'


type ToU_Props = {
    okClick?: Function,
    okButtonText?: string,
    okButtonIcon?: string
}

TermsOfUsageModal.defaultProps = {
    okButtonText: 'Jag godkänner villkoren',
    okButtonIcon: 'check',
    okClick: undefined
}

export function TermsOfUsageModal(props: ToU_Props) {

    const introText = 'Innan du börjar använda tjänsten måste du acceptera ' +
        'användarvillkoren. Detta behöver du bara göra första gången du använder tjänsten på en enhet eller när användarvillkoren har ' +
        'uppdaterats sedan ditt senaste besök.'

    return (
        <ModalDialogTop modalTitle={'Användarvillkor'}
                        description={introText}
                        acceptButton={{
                            text: props.okButtonText,
                            icon: props.okButtonIcon,
                            onClick: props.okClick
                        }}
                        elementsToAllowFocus={["helpSidePanelId"]}
                        id={'termsOfUsageModalId'}
                        isBig={true}>
            <Row>
                <Column columns="col-sm-6">
                    <small>
                        <h3>1. Om villkoren</h3>
                        <p>Dessa villkor gäller för e-tjänsten <strong>Fastighetsbildning</strong> som tillhandahålls
                            av
                            Lantmäteriet på
                            webbplatsen <ExternalLink text={'www.lantmateriet.se'}
                                                      url={'https://www.lantmateriet.se'}/> (nedan
                            kallad
                            e-tjänsten). Genom att använda e-tjänsten godkänner du användarvillkoren och förbinder du
                            dig att följa
                            dem.</p>
                        <h3>2. Ändamålet med e-tjänsten</h3>
                        <p>E-tjänsten tillhandahålls för att du ska kunna få hjälp att upprätta en ansökan om lantmäteriförrättning elektroniskt,
                            samt att följa pågående lantmäteriförrättningar och ärenden hos Lantmäteriet
                            för bildande av samfällighetsföring som du är berörd av. Genom
                            e-tjänsten tillhandahålls du ett eget utrymme där du får hjälp med att utforma utkast till
                            ansökan och
                            andra
                            handlingar. I
                            vissa fall får du även uppgifter förifyllda eller på annat sätt utlämnade av Lantmäteriet
                            innan du
                            skickar in
                            handlingarna
                            till lantmäterimyndigheten. För att använda e-tjänsten behöver du en e-legitimation.</p>
                        <h3>3. Lantmäteriets ansvar</h3>
                        <p>E-tjänsten tillhandahålls utan garanti för fel, kvalitet eller tillgänglighet och
                            Lantmäteriet ansvarar
                            heller inte
                            för
                            eventuella avbrott. Finns felaktig information i e-tjänsten påverkar inte det handläggningen
                            i enskilda
                            ärenden.</p>
                        <h3>4. Ditt ansvar</h3>
                        <p>E-tjänsten får bara användas för det avsedda ändamålet och på ett sätt som stämmer överens
                            med
                            användarvillkoren. I
                            det
                            egna utrymmet får således inte föras in eller behandlas information (exempelvis uppgifter
                            eller
                            handlingar) som saknar
                            betydelse för det avsedda ändamålet med e-tjänsten; sådana uppgifter eller handlingar ska
                            genast rensas
                            bort.
                            <br/>
                            <br/>
                            Du är ansvarig för att skydda dina inloggningsuppgifter och skyldig att följa eventuella
                            instruktioner
                            om användningen
                            av
                            e-tjänsten. Lantmäteriet får stänga av användare som inte följer användarvillkoren eller gör
                            något som
                            strider mot
                            författning, myndighetsbeslut eller de instruktioner som lämnats om e-tjänsten. Samtidigt
                            får
                            Lantmäteriet avsluta det
                            egna utrymmet och rensa bort de uppgifter som finns där.</p>
                    </small>
                </Column>
                <Column columns="col-sm-6">
                    <small>
                        <h3>5. Säkerhet</h3>
                        <p>Lantmäteriet använder kakor (cookies) för att hantera inloggning på den här webbplatsen.
                            Genom att
                            använda webbplatsen
                            accepterar du användandet av dessa kakor. Läs mer om kakor
                            på <ExternalLink url={'https://www.lantmateriet.se/cookie'}
                                             text={'Lantmäteriets webbplats'}/>.
                            <br/>
                            <br/>
                            Det egna utrymmet får inte användas så att det kan uppkomma brister i skyddet för
                            informationssäkerheten. Tänk på att
                            vara
                            försiktig om du använder en allmän dator eftersom du inte känner till vad som finns
                            installerat på
                            datorn eller om det
                            finns ett fungerande virusskydd. Det är också viktigt att du loggar ut, stänger webbläsaren
                            och rensar
                            kakor (cookies)
                            när
                            du är klar och lämnar datorn.</p>
                        <h3>6. Drift och underhåll</h3>
                        <p>Lantmäteriet har rätt att begränsa tillgängligheten på grund av utveckling, underhåll,
                            säkerhetsmässiga
                            eller
                            driftsmässiga skäl samt att göra förändringar i e-tjänsten. Sådana åtgärder kan leda till
                            att handlingar
                            i det egna
                            utrymmet raderas. Lantmäteriet får inte bereda sig tillgång till ditt eget utrymme eller ta
                            del av
                            information som
                            finns
                            där, om det inte är nödvändigt för att rätta tekniska fel eller att tillgodose
                            informationssäkerheten.
                            Vid längre tids
                            inaktivitet har Lantmäteriet dock rätt att radera innehållet i det egna utrymmet helt och
                            hållet. Tänk
                            därför på att
                            inte
                            lagra information i ditt eget utrymme annat än i begränsad omfattning och då endast för det
                            avsedda
                            ändamålet med
                            e-tjänsten.</p>
                        <h3>7. Giltighetstid</h3>
                        <p>Dessa användarvillkor gäller tills vidare. Villkoren kan dock komma att ändras. Ändringar
                            aviseras när
                            du loggar
                            in.</p>
                        <h3>8. Tillämplig lag</h3>
                        <p>Dessa användarvillkor ska tolkas och tillämpas i enlighet med svensk rätt, svenska
                            lagvalsregler ska
                            inte vara
                            tillämpliga
                            och tvister angående tolkningen och tillämpningen av användarvillkoren och därmed
                            sammanhängande
                            rättsförhållanden ska
                            avgöras av svensk domstol.</p>
                    </small>
                </Column>
            </Row>
        </ModalDialogTop>
    )
}

