//@ts-ignore
import { ExternalLink, SidePanelChapterHeading, SidePanelSection } from 'redet-react-components'

export const PaymentsHelpContent = <>
  <SidePanelChapterHeading header={'Kostnader'}
                           sectionTitle={'Kostnader'}
                           introduction={'Avgiften för lantmäteriförrättningen kan antingen vara ett överenskommet fast pris, som\n' +
                             '                            Lantmäterimyndigheten lämnar när detta är möjligt, eller debiteras enligt löpande räkning, tidsersättning.\n' +
                             '                            Kostnaderna för\n' +
                             '                            lantmäteriförrättningar betalas av berörda sakägare. Det är ärendets omfattning som avgör priset. Typen av\n' +
                             '                            förrättning och\n' +
                             '                            storleken på berörda fastigheter har betydelse för arbetsinsatsen. Det kan också finnas problem och\n' +
                             '                            svårigheter som gör\n' +
                             '                            att förrättningen tar mer tid - exempelvis krånglig fastighetsindelning, oklara plan- eller\n' +
                             '                            bebyggelseförutsättningar,\n' +
                             '                            belastande rättigheter, tekniska svårigheter vid mätning eller att sakägarna inte är överens. Ibland kan\n' +
                             '                            olika hinder\n' +
                             '                            medföra att en förrättning som sökts inte kan genomföras. Om ansökan återkallas eller om önskad åtgärd inte\n' +
                             '                            kan fullföljas\n' +
                             '                            av annan orsak debiteras ersättning för den tid som lagts ned på ärendet.'}
  />
  <SidePanelSection heading={'AVGIFTER'}
                    id={'kostnader-avgifter-id'}>
    <p>Det finns en statlig Lantmäterimyndighet och flera kommunala Lantmäterimyndigheter. Prislista hittar du på
      respektive
      Lantmäterimyndighets hemsida.

      Hjälp att hitta vilken Lantmäterimyndighet din fastighet tillhör får
      du <ExternalLink text={'här'}
                       url={'https://www.lantmateriet.se/sv/Om-Lantmateriet/Kontakta-oss/vara-kontor-och-adresser/'}/>.
      Om ärendet handläggs av statliga Lantmäterimyndigheten hittar du
      prislistan <ExternalLink text={'här'}
                               url={'https://www.lantmateriet.se/sv/Fastigheter/Andra-fastighet/priser-och-prisexempel/'}/>.
    </p>
  </SidePanelSection>
</>
