import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../hooks";
import {fetchDocumentPreview} from "../../redux/modules/signing";

// @ts-ignore
import {ButtonGroup, ButtonTop, Column, Felruta, IconLinkWithPopover, ListGroup, ListItem, ModalDialogSub, PageTopContent, Row, VarningsRuta} from "redet-react-components";
import {EmbeddedPdf} from "./EmbeddedPdf";
import {DocumentPreview} from "../../domain/pdf/DocumentAndAttachments";
import {formatFileSize} from "../../pages/euCitizenApplication/HandlingUtils";
import {PreviewData} from "./helper/SignDocumentTextHelper";
import SmallLoading from "../General/SmallLoading";

type Props = {
    requestId: string
    viewModel: PreviewData,
    onApproveButtonClick: (evt: any) => void
    onDeclineButtonClick: (evt: any) => void
    onBackButtonClick?: (evt: any) => void
}

export function SigningDocumentPreview({
                                           requestId,
                                           viewModel,
                                           onApproveButtonClick,
                                           onDeclineButtonClick,
                                           onBackButtonClick
                                       }: Props) {

    const [documentPreview, setDocumentPreview] = useState<DocumentPreview>()
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)

    const [showConfirmApprovalDialog, setShowConfirmApprovalDialog] = useState(false)
    const [showConfirmDeclineDialog, setShowConfirmDeclineDialog] = useState(false)

    const attachmentsExist = () => documentPreview && documentPreview.documentAndAttachments.attachments && documentPreview.documentAndAttachments.attachments.length > 0
    const getPdfContent = (content: string) => `data:application/pdf;base64,${content}`
    const dispatch = useAppDispatch()

    useEffect(() => {
        setIsLoading(true)
        dispatch(fetchDocumentPreview(requestId))
            .then((documentPreview: DocumentPreview) => {
                setDocumentPreview(documentPreview)
            })
            .catch((error: any) => {
                setErrorMessage('Ett fel har inträffat vid hämtning av dokument. Prova ladda om sidan eller starta signeringen igen')
            })
            .finally(() => setIsLoading(false))
    }, [requestId]);

    return (<>
        {viewModel &&
            <>
                <section>
                    {/*top-content*/}
                    {viewModel.mainHeading && <h1>{viewModel.mainHeading}</h1>}
                    <PageTopContent headerLevel={2} title={viewModel.secondHeading} introduction={viewModel.introduction} description={viewModel.description} data-testid={'document-preview-intro'}>
                        <VarningsRuta ariaTitle={'Observera'}>
                            <p className="optional">Din elektroniska signatur kommer att synas på den inskickade handlingen, i form av namn, personnummer och tidpunkt för
                                underskrift. Den inskickade handlingen kan bli tillgänglig för de som är berörda av ärendet i lantmäterimyndighetens e-tjänst för
                                lantmäteriförrättningar. Den inskickade handlingen kan även skickas till de som är berörda av ärendet och till andra myndigheter, samt arkiveras.
                                Ändamålet med behandlingen är ärendehandläggning. Ytterligare information om hantering av allmänna handlingar hittar du
                                <a className="external-link" href="https://www.lantmateriet.se/personuppgifter" target="_blank" rel="noopener noreferrer">här</a>.</p>
                            {documentPreview?.documentSigner.isForetradare && <p className="mandatory" data-testid={'document-preview-foretradare-info'}>Observera att du skriver
                                under ansökan både för din egen räkning samt <strong>som företrädare</strong> för annan sökande. Se ansökan nedan för att se vilka andra du företräder.</p>}
                        </VarningsRuta>
                    </PageTopContent>
                    {/*preview*/}
                    {isLoading && <Row>
                        <Column columns={'col-md-12'}>
                            <br/>
                            <SmallLoading text={'Förbereder dokument ...'}/>
                            <br/>
                        </Column>
                    </Row>}
                    {documentPreview && <Row>
                        <Column columns={attachmentsExist() ? 'col-sm-7 col-md-9' : 'col-md-12'}>
                            {/*main document*/}
                            <EmbeddedPdf data-testid={'embedded-pdf'} content={getPdfContent(documentPreview.documentAndAttachments.document.content)} title={documentPreview.documentAndAttachments.document.documentName ?? 'Pdf'}/>
                        </Column>
                        {attachmentsExist() && <Column columns={'col-sm-5 col-md-3'}>
                            <h2>Bifogade Handlingar</h2>
                            {/*attachments*/}
                            <ListGroup data-testid={'pdf-attachments-list'} hasZebraBackground={true}>
                                {documentPreview.documentAndAttachments.attachments.map(attachment => (
                                    <ListItem
                                        actionButtons={[
                                            <IconLinkWithPopover
                                                id={`download-handling-${attachment.id}`}
                                                key={`download-handling-${attachment.id}`}
                                                icon={'file_download'}
                                                popoverText={'Ladda ner den här handlingen'}
                                                ariaLabel={''}
                                                link={getPdfContent(attachment.content)}
                                                download={attachment.documentName}
                                                target={'_blank'}/>
                                        ]}
                                        key={`listitem-attackment-link-${attachment.id}`}
                                        id={`${attachment.id}`}
                                        header={attachment.documentName}
                                        status={{text: formatFileSize(atob(attachment.content).length), icon: 'picture_as_pdf'}}>
                                    </ListItem>))}
                            </ListGroup>
                        </Column>}
                    </Row>}
                </section>
                {!documentPreview && errorMessage && <>
                    <br/>
                    <Felruta aria-label={'Felmeddelande'} errorMessages={[errorMessage]}/>
                    <br/>
                </>}
                {/*attention-box*/}
                {viewModel.attentionbox.checklist && <VarningsRuta ariaTitle={'Information som är bra att känna till'} data-testid={'document-preview-attention-box'}
                                                                   header={{text: viewModel.attentionbox.heading ?? 'Bra att veta!'}}
                                                                   description={viewModel.attentionbox.description}>
                    <ul className="checklist">
                        {viewModel.attentionbox.checklist.map(warning => (
                            <li className="mandatory" key={Math.random()}>{warning}</li>
                        ))}
                    </ul>
                </VarningsRuta>}
                {/*buttons*/}
                <div className="toolbar mobile-toolbar">
                    <ButtonGroup>
                        <ButtonTop text={viewModel.approvalButtonTitle}
                                   data-selenium-id='approve-documents-button'
                                   icon={'check'}
                                   onClick={() => setShowConfirmApprovalDialog(true)}
                                   isActive={true}
                                   id={'approve-documents-button'}
                                   ariaLabel={viewModel.approvalButtonTitle}
                                   isDisabled={!documentPreview}/>
                        <ButtonTop text={'Avböj/neka'}
                                   data-selenium-id='decline-documents-button'
                                   icon={'block'}
                                   onClick={() => setShowConfirmDeclineDialog(true)}
                                   id={'decline-documents-button'}
                                   ariaLabel={'Avböj/neka'}
                                   isDisabled={!documentPreview}/>
                        {onBackButtonClick && <ButtonTop text={'Avbryt'}
                                                         data-selenium-id='cancel-documents-button'
                                                         icon={'block'}
                                                         onClick={(evt: any) => onBackButtonClick(evt)}
                                                         id={'cancel-documents-button'}
                                                         ariaLabel={'Avbryt'}/>}
                    </ButtonGroup>
                </div>
                {/*confirm aproval dialog*/}
                {showConfirmApprovalDialog &&
                    <ModalDialogSub modalTitle={viewModel.confirmApprovalDialog.heading}
                                    ingress={viewModel.confirmApprovalDialog.introduction}
                                    description={viewModel.confirmApprovalDialog.description}
                                    acceptButton={{
                                        text: viewModel.confirmApprovalDialog.acceptBtnTitle,
                                        icon: 'check',
                                        onClick: (evt: any) => {
                                            setShowConfirmApprovalDialog(false)
                                            onApproveButtonClick(evt)
                                        }
                                    }}
                                    declineButton={{
                                        text: viewModel.confirmApprovalDialog.declineBtnTitle,
                                        icon: 'clear',
                                        onClick: () => setShowConfirmApprovalDialog(false)
                                    }}>
                    </ModalDialogSub>}
                {/*confirm aproval dialog*/}
                {showConfirmDeclineDialog &&
                    <ModalDialogSub modalTitle={viewModel.confirmDeclineDialog.heading}
                                    ingress={viewModel.confirmDeclineDialog.introduction}
                                    description={viewModel.confirmDeclineDialog.description}
                                    acceptButton={{
                                        text: viewModel.confirmDeclineDialog.acceptBtnTitle,
                                        icon: 'block',
                                        onClick: (evt: any) => {
                                            setShowConfirmDeclineDialog(false)
                                            onDeclineButtonClick(evt)
                                        }
                                    }}
                                    declineButton={{
                                        text: viewModel.confirmDeclineDialog.declineBtnTitle,
                                        icon: 'clear',
                                        onClick: () => setShowConfirmDeclineDialog(false)
                                    }}>
                    </ModalDialogSub>}
            </>}
    </>)
}