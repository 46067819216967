import {Term} from "../../../../views";
// @ts-ignore
import {ButtonGroup, ButtonSub, ButtonToolbarV2} from "redet-react-components";
import React, {useEffect, useState} from "react";
import {KompletteringAtgard} from "../../../../domain/Forratningsarende/KompletteringAtgard";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {Svarslistor} from "../../../../domain/Svarslistor";
import InactiveKompletteringReasons from "./InactiveKompletteringReasons";
import {ARENDE_STATUS, NO_SCROLLING_CSS_CLASS} from "../../../../utils/globalConstants";
import BekraftaDelgivningWizard from "../../atgarder/BekraftaDelgivningWizard";

type Props = {
    arendenummer: string
    arendestatus: ARENDE_STATUS
}

function BekraftaDelgivningView({arendenummer, arendestatus}: Props) {

    const svarslistor: Svarslistor = useSelector((store: RootState) => store.arende?.svarslistor)
    const delgivningBekraftelseAtgard: KompletteringAtgard = useSelector((store: RootState) => store.arende?.delgivningBekraftelseAtgard)
    const [showBekraftaDelgivningDialog, setShowBekraftaDelgivningDialog] = useState(false)

    const obesvaradeSvarslistorExists = (): boolean => svarslistor && svarslistor.obesvarade?.length > 0
    const besvaradeSvarslistorExists = (): boolean => svarslistor && svarslistor.besvarade?.length > 0

    useEffect(() => {
        //add och remove scrollingclass when dialog is visible
        if (showBekraftaDelgivningDialog) {
            document.body.classList?.add(NO_SCROLLING_CSS_CLASS)
        } else {
            document.body.classList?.remove(NO_SCROLLING_CSS_CLASS)
        }
    }, [showBekraftaDelgivningDialog])

    return (<>
        <h3 id={"delgivning"} data-testid={'delgivning'}>Bekräfta att du blivit delgiven</h3>
        <p className={"introduction"}>Har du fått ett utskick med uppmaning om att du behöver <Term label="bekräfta en delgivning"
                                                                                                    moment="help-komplettering"
                                                                                                    term="term-delgivning"/>
            Klicka på Bekräfta delgivning nedan. <br/> Om knappen är inaktiv betyder det att du inte har någon delgivning att bekräfta.</p>
        <ButtonToolbarV2 isMultiLine={true} data-testid={'bekrafta-delgivning-buttons'}>
            <ButtonGroup>
                <ButtonSub text={'Bekräfta att du blivit delgiven'}
                           id='delgivningsvarAtgardButton'
                           isDisabled={!delgivningBekraftelseAtgard?.active || !obesvaradeSvarslistorExists()}
                           onClick={() => setShowBekraftaDelgivningDialog(true)}/>
            </ButtonGroup>
        </ButtonToolbarV2>
        {/*attention*/}
        {delgivningBekraftelseAtgard?.active && <>
            <div className="message top-left assistance">
                <p>Klicka här för att bekräfta din delgivning</p>
            </div>
            <br/>
        </>}
        <div data-testid={'bekrafta-delgivning-messages'} data-selenium-id={'bekrafta-delgivning-messages'}>
            <InactiveKompletteringReasons kompletteringAtgarder={[delgivningBekraftelseAtgard]} arendestatus={arendestatus} />
        </div>
        {/*info om klara delgivningar*/}
        {besvaradeSvarslistorExists() && <p>Delgivningar du har besvarat i tjänsten visas under "Slutförda aktiviteter" nedan</p>}
        {/*bekräfta dialog*/}
        {showBekraftaDelgivningDialog &&
            <BekraftaDelgivningWizard obesvaradeSvarslistor={svarslistor.obesvarade}
                                      arendenummer={arendenummer}
                                      onCloseModal={() => setShowBekraftaDelgivningDialog(false)}
            />
        }
    </>)
}

export default BekraftaDelgivningView