import WizardDialog, {WizardDialogStep} from "../../../wizard/WizardDialog";
import React, {ReactNode, useState} from "react";
import {BifogaHandlingValjTypStep} from "./BifogaHandlingValjTypStep";
import {BIFOGADHANDLINGTYPES} from "../../../../utils/globalConstants";
import {BifogadHandlingType} from "../../../../domain/BifogadhandlingType";
import {BifogaHandlingLaddaUppFilerStep} from "./BifogaHandlingLaddaUppFilerStep";
import {BifogaHandlingAngeEpostStep} from "./BifogaHandlingAngeEpostStep";
import {BifogaHandlingContext} from "../BifogaHandlingContext";
import {useAppDispatch} from "../../../../hooks";
import {signKompletteringStartPhos, uploadKompletteringFromBifogadFilePhos} from "../../../../redux/modules/kompletteringBifogaHandling";
import {StartSigningResponse} from "../../../../domain/signing/SigningModels";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import {CircleLoading, ModalDialogAlert} from "redet-react-components";
import {
    ForbiddenBifogadHandlingFiletypeException, InvalidEmailException,
    BifogadHandlingException, KompletteringNotFoundException,
    TooManyFilesInBifogadHandlingException,
    UserAbortException
} from "../../../../repository/BifogadHandlingException";
import {ANTAL_SIDOR_FELMEDDELANDE, EPOST_FELMEDDELANDE, FILTYP_FELMEDDELANDE} from "../../../../views/BifogadeHandlingar/handlingConstants";
import {BifogaHandlingConfirmStep} from "./BifogaHandlingConfirmStep";
import {YtterligareHandlingRequest} from "../../../../repository/kompletteringBifogaHandlingRepository";

type Props = {
    arendenummer: string
    onCloseWizardDialog: (currentStep: number) => void
}

export function KompletteringBifogaHandlingWizardDialog({arendenummer, onCloseWizardDialog, ...rest}: Props) {

    const wizardRestProps = {initialStep: 1, ...rest}

    const fileTypeOptions: BifogadHandlingType[] = BIFOGADHANDLINGTYPES
        .filter(handling => handling.isValbarIKomplettering && !handling.isDeprecated)
        .sort((first, second) => first.visningsnamn.localeCompare(second.visningsnamn))
        .map(handling => handling)

    const [currentStep, setCurrentStep] = useState<number>(wizardRestProps.initialStep)

    const [showLoading, setShowLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [loadingText, setLoadingText] = useState<string>('');
    const [uploadAbortController, setUploadAbortController] = useState<AbortController>();

    const [errorMessageContent, setErrorMessageContent] = useState<ReactNode>()

    //context
    const {typ, ovrigBeskrivning, epost, handlingar, validateForm} = React.useContext(BifogaHandlingContext)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const onUserConfirmation = async () => {
        if (validateForm().length === 0) {
            //show loading
            setLoadingText('Laddar upp handling ...')
            setShowLoading(true)
            const kompletteringId = await uploadFiles()
                .catch((error: BifogadHandlingException) => {
                    if(!(error instanceof UserAbortException)) {
                        handleUploadFilesError(error)
                    }
                    setShowLoading(false)
                })
            //komplettering uppladdad, fortsätter med signering
            if (kompletteringId) {
                setLoadingText('Förbereder ...')
                const signingResponse = await initiateSigning(kompletteringId)
                    .catch((error: BifogadHandlingException) => {
                        handleInitiateSigningError(error)
                        setShowLoading(false)
                    })
                if (signingResponse?.requestId) {
                    // skicka användaren till förhansgrankning
                    const signingurl = 'komplettering/' + kompletteringId + '/preview'
                    navigate(signingurl, {replace: true})
                }
            }
            //add delay for redirect
            setTimeout(() => setShowLoading(false), 3000)
        }
    }

    function uploadFiles(): Promise<string> {
            const handling: YtterligareHandlingRequest = {
                type: typ!!.value,
                description: typ!!.value === 'ANNAT' ? ovrigBeskrivning : '',
                files: handlingar,
                inbjudanEmail: 'noreply@lm.se', //PHOS DUMMY EMAIL
                notifieringEmail: epost
            }

            const abortController = new AbortController()
            setUploadAbortController(abortController)
            return dispatch(uploadKompletteringFromBifogadFilePhos(
                arendenummer,
                handling,
                (percentComplete = 100) => {
                    setLoadingProgress(percentComplete)
                    if (percentComplete >= 95) {
                        //göm avbryt-knapp
                        setUploadAbortController(undefined)
                    }
                },
                abortController))
    }

    function initiateSigning(kompletteringId: string): Promise<StartSigningResponse> {
        return dispatch(signKompletteringStartPhos(kompletteringId))
    }

    function handleUploadFilesError(error: BifogadHandlingException) {
        if (error instanceof TooManyFilesInBifogadHandlingException) {
            setErrorMessageContent(<span>
                {ANTAL_SIDOR_FELMEDDELANDE}</span>)
        } else if (error instanceof ForbiddenBifogadHandlingFiletypeException) {
            setErrorMessageContent(<span>
                {FILTYP_FELMEDDELANDE}</span>)
        } else if (error instanceof InvalidEmailException) {
            setErrorMessageContent(<span>
                {EPOST_FELMEDDELANDE}</span>)
        } else {
            setErrorMessageContent(
                <span>Ett tekniskt fel uppstod vid uppladdning av bifogad handling. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
                    href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>)
        }
    }

    function handleInitiateSigningError(error: BifogadHandlingException) {
        if (error instanceof KompletteringNotFoundException) {
            setErrorMessageContent(<span>
                Ett fel inträffade när handlingen laddades upp. Försök igen senare genom att klicka på <strong>Lägg till ytterligare handling</strong> och börja om.</span>)
        } else {
            setErrorMessageContent(
                <span>Ett tekniskt fel uppstod vid uppladdning av bifogad handling. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta <a
                    href="https://www.lantmateriet.se/kontakt">kundcenter</a>.</span>)
        }
    }

    const onCloseModalClick = () => {
        onCloseWizardDialog(currentStep)
    }

    return (<>
        <WizardDialog title={'Skicka in handling'}
                      cancelFunction={() => onCloseModalClick()}
                      defaultNav={false}
                      onStepChange={(evt: any) => setCurrentStep(evt.activeStep)}
                      elementsToAllowFocus={['helpSidePanelId']}
                      id={'ytterligare-handling-wizard'}
                      {...wizardRestProps}>
            <WizardDialogStep stepTitle={'Typ av handling'}>
                <BifogaHandlingValjTypStep options={fileTypeOptions}/>
            </WizardDialogStep>
            <WizardDialogStep stepTitle={'Filer'}>
                <BifogaHandlingLaddaUppFilerStep/>
            </WizardDialogStep>
            <WizardDialogStep stepTitle={'Ange epost'}>
                <BifogaHandlingAngeEpostStep/>
            </WizardDialogStep>
            <WizardDialogStep stepTitle={'Bestyrk handling'}>
                <BifogaHandlingConfirmStep onConfirm={onUserConfirmation}/>
            </WizardDialogStep>
        </WizardDialog>

        {showLoading && <CircleLoading elementsToAllowFocus={["ytterligare-handling-wizard"]}
                                       text={loadingText}
                                       percentageComplete={loadingProgress}
                                       isFullscreen={true}
                                       button={uploadAbortController ? {onClick: () => uploadAbortController?.abort(), text: 'Avbryt'} : undefined}
        />}

        {errorMessageContent && <ModalDialogAlert modalTitle={'Fel vid uppladdning'}
                                                  description={errorMessageContent}
                                                  acceptButton={{
                                                      text: 'Ok',
                                                      icon: 'check',
                                                      onClick: () => setErrorMessageContent(undefined)
                                                  }}/>}
    </>)
}

