import * as interactions from '../interactions'
import PropTypes from 'prop-types'
import React from 'react'
import { MapButton } from 'redet-react-components'

export function MeasureToolsMenu (props) {
  return (
    <>
      <MapButton
        text={'Mät med linje'}
        icon={'straighten'}
        onClick={() => props.onClickToolCallback(interactions.MEASURE_LINE)}
        id="mapToolbarMeasureLine"
        isActive={props.activeTool === interactions.MEASURE_LINE}/>
      <MapButton
        text={'Mät med yta'}
        icon={'straighten'}
        onClick={() => props.onClickToolCallback(interactions.MEASURE_POLYGON)}
        id="mapToolbarMeasurePolygon"
        isActive={props.activeTool === interactions.MEASURE_POLYGON}/>
    </>
  )
}

MeasureToolsMenu.propTypes = {
  onClickToolCallback: PropTypes.func,
  activeTool: PropTypes.string
}
