import {User} from "domain/User";
import React, {useEffect, useState} from "react";
// @ts-ignore
import {Checkbox, Column, ExternalLink, FormFieldset, ModalDialogAlert, ModalDialogSub, PageSubContent, Row, VarningsRuta, WizardIntorduction, WizardNavigationButtonToolbar} from "redet-react-components";
import Term from "../../../views/Hjalp/TermLank";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
type Props = {
    user: User
    arendenummer: string
    meddelande: string
    epostKontakt: string
    telefonKontakt: string
    epostKvittens: string
    aterkallandeSubmit: () => Promise<void>
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
    previousStep?: () => void
}

export function ForhandsGranskaAterkallande(props: Props) {
    //Errormessage är till för att felmeddelande endast ska visas efter första knapptrycket och inte innan dess
    const [errorMessage, setErrorMessage] = useState<boolean>(false)
    const [godkantVillkor, setGodkantVillkor] = useState<boolean>(false)
    const [askUserIfHeWantsToSubmit, setAskUserIfHeWantsToSubmit] = useState<boolean>(false)
    const selectedAnsokningar = useSelector((store: RootState) => store.arendeKompletteringar.selectedAktbilagebeteckningar)

    function userHasAgreedInfoIsOk() {
        if(godkantVillkor) {
            setErrorMessage(false)
            setAskUserIfHeWantsToSubmit(true)
        } else {
            setErrorMessage(true)
            setGodkantVillkor(false)
        }
    }

    function sendIt() {
        props.aterkallandeSubmit()
        if(props.nextStep){props.nextStep()}
    }

    const mapAktbilagor = () => {
        let aktbilagor = [""];
        selectedAnsokningar.map((aktbilaga, index) => {
            aktbilagor.push(aktbilaga)
            aktbilagor.push(selectedAnsokningar.length-1 !== index ? ", ": "")
        })
        return aktbilagor;
    }

    return <>
        <WizardIntorduction title={'Förhandsgranskning av återkallande'}>
            <VarningsRuta intro={<p>Den inskickade handlingen kan bli tillgänglig för de som är berörda av ärendet i lantmäterimyndighetens e-tjänst för lantmäteriförrättningar.
                Den inskickade handlingen kan även skickas till de som är berörda av ärendet och till andra myndigheter, samt arkiveras.
                Ändamålet med behandlingen är ärendehandläggning. Ytterligare information om hantering av allmänna handlingar hittar du
                <a title={'här'} href={'https://www.lantmateriet.se/personuppgifter'} rel="external noopener noreferrer" target="_blank"> här</a>.</p>}
                          ariaTitle={"Information om handlingar"}/>
            <PageSubContent headerId={"skrivelseForhandsgranskningId"} headerLevel={3} title={'Förhandsgranskning'}>
                <FormFieldset description={'Jag skickar in följande återkallande till myndigheten'}>
                    {props.user?.fullName &&
                        <Row>
                            <Column data-testid="avsandareId" className={'form-group col-xs-12 col-sm-6 col-xl-12'}>
                                <label className={'label'}>Avsändare</label>
                                <p id={'avsandareId'} className="preview-message">{props.user?.fullName}</p>
                            </Column>
                        </Row>
                    }
                    {selectedAnsokningar?.length > 0 &&
                        <Row>
                            <Column data-testid="aktbilagaId" className={'form-group col-xs-12 col-sm-6 col-xl-12'}>
                                <label className={'label'}>Aktbilaga</label>
                                <p id={'aktbilagaId'} className="preview-message">{mapAktbilagor()}</p>
                            </Column>
                        </Row>
                    }
                    {props.arendenummer &&
                        <Row>
                            <Column data-testid="arendenummerId" className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>Ärendenummer</label>
                                <p id={'arendenummerId'} className="preview-message">{props.arendenummer}</p>
                            </Column>
                        </Row>
                    }
                        <Row>
                            <Column data-testid="meddelandeId" className={'form-group col-xs-12 col-sm-12 col-xl-12'}>
                                <label className={'label'}>Meddelande</label>
                                <p id={'meddelandeId'} className="preview-message">{props.meddelande}</p>
                            </Column>
                        </Row>
                    {props.epostKvittens &&
                        <Row>
                            <Column data-testid={"epostKvittensId"} className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>E-post kvittens</label>
                                <p id={'epostKvittensId'} className="preview-message">{props.epostKvittens}</p>
                            </Column>
                        </Row>
                    }
                    {props.epostKontakt &&
                        <Row>
                            <Column data-testid={"epostKontaktId"} className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>E-post kontakt</label>
                                <p id={'epostKontaktId'} className="preview-message">{props.epostKontakt}</p>
                            </Column>
                        </Row>
                    }
                    {props.telefonKontakt &&
                        <Row>
                            <Column data-testid={"telefonnummerId"} className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>Telefonnummer kontakt</label>
                                <p id={'telefonnummerId'} className="preview-message">{props.telefonKontakt}</p>
                            </Column>
                        </Row>
                    }
                </FormFieldset>
            </PageSubContent>
            <Checkbox
                id={"godkantVillkorId"}
                label={"Jag har granskat informationen ovan och är medveten om att all information ovan blir allmän handling"}
                isChecked={godkantVillkor}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setGodkantVillkor(evt.target.checked)}
            />
        </WizardIntorduction>
        <WizardNavigationButtonToolbar
            prevButtonText={"Ange epost för kvittens"}
            nextButtonText={"Skicka in"}
            onClickNextButton={() => {userHasAgreedInfoIsOk()}}
            onClickPrevButton={props.previousStep}/>

        {!godkantVillkor && errorMessage &&
            <VarningsRuta errorMessages={["Du måste godkänna att informationen stämmer innan du kan skicka in återkallandet"]} ariaTitle={"Felmeddelande"}/>
        }
        {askUserIfHeWantsToSubmit &&
            <ModalDialogSub modalTitle={"Är du säker?"}
                            description={<>När du återkallar ansökan kommer Lantmäteriet ta betalt för det arbete vi har lagt ner hittills i din ansökan. Mer information finns att läsa &nbsp;
                                <ExternalLink text={'här'} url={'https://www.lantmateriet.se/sv/fastighet-och-mark/forandra-lagg-ihop-eller-dela/vad-kommer-det-att-kosta/#anchor-2'}/></>}
                            ingress={"Är du helt säker på att du vill återkalla ansökan?"} declineButton={{text: 'Avbryt', onClick: () => setAskUserIfHeWantsToSubmit(false)}}
                            acceptButton={{text: 'Ja, återkalla ansökan', onClick: () => sendIt()}}/>
        }

    </>;
}
export default ForhandsGranskaAterkallande;