import React, {useEffect, useState} from 'react'
import {ActionBar} from 'views'
import {Navigation} from 'components'
import {useLocation} from 'react-router-dom'
import {MYNDIGHET_LANTMATERIET, NO_SCROLLING_CSS_CLASS} from '../utils/globalConstants'
// @ts-ignore
import {Column, ExternalLink, PageContentHeader, VarningsRuta, FeedbackBox, ModalDialogSub, SidePanelChapter} from 'redet-react-components'
import {sattBetyg} from 'redux/modules/betyg'
import {useAppDispatch} from "../hooks";
import Hjalp from "../views/Hjalp/Hjalp";
import {FeedbackHelpContent} from "../views/Hjalp/texter/FeedbackHelpContent";
import {Term} from "../views";
import {KontaktaOssHelpContent} from "../views/Hjalp/texter/KontaktaOssHelpContent";
import {PersonuppgifterHelpContent} from "../views/Hjalp/texter/PersonuppgifterHelpContent";
import {visaMoment} from "../redux/modules/help";

interface Props {
    displayFeedback?: boolean
}

export function Kvittens({displayFeedback = true}: Props) {

    const [showFeedback, setShowFeedback] = useState(displayFeedback)
    const [showThankYouModal, setShowThankYouModal] = useState(false)

    const [kvittensId, setKvittensId] = useState<string>()
    const [mottagandeMyndighet, setMottagandeMyndighet] = useState<string>()
    const [fastighetsbeteckningar, setFastighetsbeteckningar] = useState<string[]>()

    const {state} = useLocation()

    const dispatch = useAppDispatch()

    const isSLM = () => mottagandeMyndighet === MYNDIGHET_LANTMATERIET

    const toggleThankYouModal = (show: boolean) => setShowThankYouModal(show)
    const toggleFeedback = (show: boolean) => setShowFeedback(show)

    const getMottagandeMyndighetRegards = () => !isSLM() ? `Lantmäterimyndigheten i ${mottagandeMyndighet}` : MYNDIGHET_LANTMATERIET
    const getIntroTextNode = () => isSLM() ?
        <>Din ansökan om lantmäteriförrättning gällande nedanstående
            fastighet(er) har skickats in till Lantmäteriet och fått
            kvittensnummer <strong data-selenium-id="kvittens-id">{kvittensId}</strong>.</>
        :
        <>Din ansökan om lantmäteriförrättning gällande nedanstående fastighet(er) har skickats in till
            den kommunala lantmäterimyndigheten i <span
                data-selenium-id="mottagande-myndighet">{mottagandeMyndighet}</span> och fått
            kvittensnummer <strong data-selenium-id="kvittens-id">{kvittensId}</strong>.</>

    function sendFeedback(grade: number, text?: string) {
        dispatch(sattBetyg(grade, text))
        toggleFeedback(false)
        toggleThankYouModal(true)
    }

    useEffect(() => {
        //ladda kontakta oss initialt i hjälp-panelen
        dispatch(visaMoment('help-support'))

        window.scrollTo(0, 0)
        if (state?.kvittens) {
            setKvittensId(state.kvittens.kvittensId)
            setMottagandeMyndighet(state.kvittens.mottagandeMyndighet)
            setFastighetsbeteckningar(state.kvittens.fastighetsbeteckningar)
        }
        document.body.classList.add('no-menu')
        document.body.classList.remove(NO_SCROLLING_CSS_CLASS)
    }, [])

    return (<>
            <header>
                <Navigation showHelp={false}/>
                <div className="container">
                    <h1>Tack för din ansökan!</h1>
                </div>
            </header>
            <main>
                <div className="container"  data-testid={'kvittens-main-container'}>
                    <Column columns={'col-md-12 col-lg-12'}>
                        <>
                            <PageContentHeader headerId="kvittensHeader" headerLevel={2} title={'Din ansökan är inskickad'}>
                                <p id="kvittensText">{getIntroTextNode()}</p>
                                <ul>
                                    {fastighetsbeteckningar?.map(fastighetsbeteckning => (
                                        <li key={fastighetsbeteckning}>{fastighetsbeteckning}</li>
                                    ))}
                                </ul>
                            </PageContentHeader>
                            <br/>
                            <PageContentHeader title={'Information om ansökan'} headerLevel={2}>
                                <p><strong>Ansökan kommer att diarieföras och få ett ärendenummer inom några dagar. En bekräftelse med
                                    information om
                                    ditt ärende och ärendenummer skickas därefter till dig per post.</strong></p>
                                <p>Klicka här för att läsa om <ExternalLink
                                    text={'handläggningstider och vad som händer med ärendet nu'}
                                    url={'https://www.lantmateriet.se/sv/fastigheter/Andra-fastighet/hur-lang-tid-tar-en-lantmateriforrattning/'}/>.
                                    <br/>När du fått ditt ärendenummer kan du <ExternalLink text={'följa ärendet här'}
                                                                                            url={'https://enak.lantmateriet.se'}/>.
                                    <br/>Har du frågor är du välkommen att kontakta <ExternalLink
                                        url={'https://www.lantmateriet.se/kontakt'} text={'kundcenter'}/>.</p>
                                <p id="kvittensHalsning">Med vänliga hälsningar<br/>
                                    {getMottagandeMyndighetRegards()}
                                </p>
                            </PageContentHeader>
                            <br/>
                            <VarningsRuta ariaTitle='Information om kvittensnummer'
                                intro={'Tänk på att kvittensnumret endast gäller som referens tills dess att du har fått ett ärendenummer.'}/>
                        </>

                    </Column>
                    {showFeedback && <FeedbackBox
                        onSubmit={(grade, feedbackText) => {
                            sendFeedback(grade, feedbackText)
                        }}
                        texts={{description: <p className={'description'}>Läs mer om hur vi <Term label={'hanterar ditt betyg'} moment="help-feedback"/></p>}}/>
                    }
                    {showThankYouModal && <ModalDialogSub modalTitle={'Tack för din feedback'}
                                                          description={'Vi är tacksamma för ditt svar, det ger oss möjlighet att göra en så bra tjänst som möjligt!'}
                                                          closeModalCallback={() => toggleThankYouModal(false)}/>}
                </div>
                <ActionBar saveAnsokanOnClose={false}/>
                <Hjalp>
                    <SidePanelChapter name={"help-support"}>
                        <KontaktaOssHelpContent/>
                    </SidePanelChapter>
                    <SidePanelChapter name={"help-personuppgift"}>
                        <PersonuppgifterHelpContent/>
                    </SidePanelChapter>
                    <SidePanelChapter name={'help-feedback'}>
                        <FeedbackHelpContent/>
                    </SidePanelChapter>
                </Hjalp>
            </main>
        </>
    )
}

export default Kvittens