import {AnsokanWizardSDGProvider} from "./euCitizenApplication/AnsokanWizardSDGContext";
import AnsokanWizardSDG from "./AnsokanWizardSDG";

function ContextProviderWrapperSDG() {

  return (
      <AnsokanWizardSDGProvider>
        <AnsokanWizardSDG/>
      </AnsokanWizardSDGProvider>
  );
}

export default (ContextProviderWrapperSDG);
