import { FullscreenLoading, FullscreenProgress } from 'components'
import ModalDialog from 'components/General/ModalDialog'
import { useAppSelector } from 'hooks'
import GenericErrorFormDialog from '../components/General/GenericErrorFormDialog'
import { AnsokanCantBeChangedModal } from '../components/ansokan/AnsokanCantBeChangedModal'
import { RootState } from '../store'
import { Notiser } from '../components/notis/Notiser'

function OverlayComponents() {

    const showProgressBar = useAppSelector((store: RootState) => store.fullscreenLoading.visible);
    const showModalDialog = useAppSelector((store: RootState) => store.modalDialog.isVisible);
    const showErrorDialog = useAppSelector((store: RootState) => store.modalDialog.showErrorDialog);
    const progressBarText: string = useAppSelector((store: RootState) => store.fullscreenLoading.text);
    const progressBarShouldHideContent: boolean = useAppSelector((store: RootState) => store.fullscreenLoading.hideContent);
    //@ts-ignore fixas med bättre typad ansokanRepository
    const isAnsokanStatusChangeError: boolean = useAppSelector((store: RootState) => store.ansokanRepository.isAnsokanStatusChangeError);
    const user = useAppSelector((store: RootState) => store.auth?.user);

    const hideNotiser = () => (
        window.location.pathname === "/anvandarvillkor" ||
        window.location.pathname === '/' ||                                 //startsidan eller
        window.location.pathname.split('/').includes('sdg') ||      //url:en innehåller /sdg (innan inloggning) eller
        user?.isSdg === true                                                //användaren är inloggad med eIDAS
    )

    return (
        <section id='top-component-wrapper' aria-label='Dialoger och laddningsindikatorer.'>
            <>
                {showProgressBar &&
                    <FullscreenLoading hideContent={progressBarShouldHideContent} text={progressBarText}/>}
                {showModalDialog && <ModalDialog/>}
                {showErrorDialog && <GenericErrorFormDialog/>}
                {isAnsokanStatusChangeError && <AnsokanCantBeChangedModal/>}
                <FullscreenProgress/>
                {!hideNotiser() && <Notiser/>}
            </>
        </section>
    )
}

export default OverlayComponents