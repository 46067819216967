import {AterkallandeKvittensResponse, AterkallandeRequest} from "./model/aterkallande";
import {submit} from "./aterkallandeRepository";
import {Fetcher} from "../../repository/fetcher";
import { RootState } from "store";

export function submitAterkallande(aterkallande: AterkallandeRequest) {
    return function action(dispatch: any, getState: () => RootState) {
        const fetcher = new Fetcher(dispatch, getState, false);
        return submit(fetcher, aterkallande).then((aterkallande : AterkallandeKvittensResponse) => aterkallande).catch((err) => {throw err});
    }
}
