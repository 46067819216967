//@ts-ignore
import { ExternalLink, SidePanelChapterHeading, SidePanelSection } from 'redet-react-components'

export function LoginHelpContentOrganisation () {
  return (
    <>
      <SidePanelChapterHeading header={'Ansök om lantmäteriförrättning'}/>
      <SidePanelSection id={'frontpage-help-org-login'}
                        heading={'Logga in med lösenord'}
                        text={'En organisation kan inte ha en e-legitimation eftersom den är kopplad till ett personnummer. För att organisationen ' +
                          'ska kunna följa sina pågående ärenden behövs därför en separat inloggning med lösenord från Lantmäteriet.'}>
        <ExternalLink
          url={'https://www.lantmateriet.se/sv/Fastigheter/Andra-fastighet/ansok-folj-eller-komplettera-ett-arende/ansok-om-inloggning-och-administratorsrattigheter/'}
          text={'Läs mer om att logga in med lösenord'}
          isStandAloneLink={true}
          openInNewTab={true}/>
      </SidePanelSection>
    </>
  )
}