import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {submitAnsokan} from 'redux/modules/ansokanSPECIAL'
import {addProgress, endProgress} from 'redux/modules/fullscreenLoading'
import {DocumentPullupList} from 'components'
import {fetchAnsokan} from 'redux/modules/ansokanCRUD'
import {
    getContactInfo,
    mapAtgardListToStringArray,
    parseFastigheterArrayToStringArray
} from 'utils/ansokanDataExtractor'
import {AbortSigningModal} from './AbortSigningModal'
import {abortSigningInstance} from '../../redux/modules/signing'
import {
    ButtonGroup,
    ButtonTop,
    Column,
    FormFieldset,
    LinkButtonTop,
    ModalDialogAlert,
    ModalDialogSub,
    PageSubContent,
    PageTopContent,
    Row
} from 'redet-react-components'
import {StepVisualisationFish} from 'pages/Signing'
import {getBifogadHandlingList} from '../../redux/modules/bifogadHandling'
import * as contactsHelper from '../../utils/contactsHelper'
import {logError} from '../../utils/errorHandler'

const mapStateToProps = (store) => {
    return {
        bifogadHandlingList: store.bifogadHandling.list,
    }
}

const mapDispatchToProps = {
    fetchAnsokan,
    submitAnsokan,
    addProgress,
    endProgress,
    abortSigningInstance,
    getBifogadHandlingList,
}

export class SigningFinished extends Component {
    static propTypes = {
        // Constructor
        ansokanId: PropTypes.string.isRequired,
        signingStatus: PropTypes.object.isRequired,
        onFormSubmit: PropTypes.func.isRequired,
        contactList: PropTypes.array.isRequired,

        // Dispatch
        fetchAnsokan: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,
        submitAnsokan: PropTypes.func.isRequired,
        endProgress: PropTypes.func.isRequired,
        addProgress: PropTypes.func.isRequired,
        abortSigningInstance: PropTypes.func.isRequired,
        getBifogadHandlingList: PropTypes.func.isRequired,
        bifogadHandlingList: PropTypes.array.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            ansokanFastigheter: [],
            ansokanAtgarder: [],
            showAbortSigningModal: false,
            showReadyToBeSentInModal: false,
            showSendInConfirmationModal: false,
            abortSigningError: false,
            submitAnsokanError: false,
        }
    }

    componentDidMount() {
        this.fetchAndSetAnsokanInState()
        this.props.getBifogadHandlingList(this.props.ansokanId)
        this.showReadyToBeSentInModal()
    }

    fetchAndSetAnsokanInState() {
        this.props.fetchAnsokan(this.props.ansokanId).then((result) => {
            this.setState({
                ansokanFastigheter: parseFastigheterArrayToStringArray(result.data.fastigheterList),
                ansokanAtgarder: mapAtgardListToStringArray(Object.keys(result.data.fastighetsforandring).filter(forandring => result.data.fastighetsforandring[forandring]))
            })
        })
    }

    submitAnsokanToMyndighet = () => {
        this.props.submitAnsokan(this.props.ansokanId)
            .then((response) => {
                setTimeout(() => {
                    this.props.navigate('/kvittens', {state:{kvittens: response}})
                    this.props.endProgress()
                })
            }).catch((error) => {
                this.props.endProgress()
                this.setState({submitAnsokanError: true})
                logError(error, 'Fel vid inskick av ansökan')
            });
    }

    abortSigning = () => {
        this.props.addProgress(false, 'Avbryter underskrifter...')
        this.props.abortSigningInstance(this.props.ansokanId)
            .then(() => {
                this.props.endProgress()
                this.props.onFormSubmit()
            }).catch(() => {
                this.props.endProgress()
                this.setState({abortSigningError: true})

            })

    }

    showAbortSigningModal = (show = true) => {
        this.setState({showAbortSigningModal: show})
    }

    showReadyToBeSentInModal = (show = true) => {
        this.setState({showReadyToBeSentInModal: show})
    }
    showSendInConfirmationModal = (show = true) => {
        this.setState({showSendInConfirmationModal: show})
    }

    render() {
        return (
            <>
                <div className="wizard" id="step-4">
                    <StepVisualisationFish activeStep={4} />
                    <PageTopContent title={'Skicka in ansökan'} headerLevel={2} headerId={'skickainansokanheaderId'}
                        introduction={'Ansökan är färdig att skickas in'}
                        description={'Förhandsgranska informationen nedan, du ska sedan skicka in ansökan tillsammans med de bifogade handlingarna. När du skickat in din ansökan kommer du få en kvittens/bekräftelse på skärmen och via e-post. Tänk på att kvittensnumret endast gäller som referens tills dess att du har fått ett ärendenummer.'}
                    />
                    <br />
                    <PageSubContent title={'Ansökan om Lantmäteriförrättning'} headerId={'skicksubid'}>
                        <FormFieldset description={'Jag/vi ansöker om följande Lantmäteriförrättning'}>
                            <Row>
                                <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="label">Berörd fastighet</label>
                                        {this.state.ansokanFastigheter.map((fastighet, index) =>
                                            <p key={'key_' + fastighet}>{fastighet}</p>
                                        )}
                                    </div>
                                </Column>
                                <Column columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="label">Ändring</label>
                                        {this.state.ansokanAtgarder.map((atgarder, index) =>
                                            <p key={'key_' + atgarder}>{atgarder}</p>
                                        )}
                                    </div>
                                </Column>
                            </Row>
                            <Row id="sokandeListContainer">
                                {[
                                    ...contactsHelper.getAllContactsWhoShouldSignAnsokan(this.props.contactList),
                                    ...contactsHelper.getAllContactsWithRollSokandeWhoHaveOmbud(this.props.contactList)
                                ].map((contact, index) =>
                                    contact.data.rollSokande &&
                                    <Column key={'contaner_' + contact.id} columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                                            id={`sokandeContainer${index}`}>
                                        <div key={'form-group_' + contact.id} data-testid={`contact-info-${index}`} className="form-group">
                                            {getContactInfo(this.props.contactList, contact, index)}
                                        </div>
                                    </Column>
                                )}
                            </Row>
                            <Row>
                                {this.props.bifogadHandlingList.map((handling, index) =>
                                    <Column key={'top_' + handling.id} columns="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                        <div key={'group_' + handling.id} className="form-group">
                                            <label key={'label_' + handling.id} className="label">Bifogad handling</label>
                                            <p key={'key_' + handling.id}>{handling.name}</p>
                                        </div>
                                    </Column>
                                )}
                            </Row>
                        </FormFieldset>
                    </PageSubContent>
                    <div className="toolbar mobile-toolbar">
                        <ButtonGroup>
                            <ButtonTop name="abort-signing-button"
                                icon="clear"
                                text={'Avbryt underskrifter'}
                                onClick={this.showAbortSigningModal}
                            />
                            <DocumentPullupList>
                                <ul className="dropdown-menu">
                                    <li>
                                        <LinkButtonTop text={'Ansökan'}
                                            title={'Ansökan'}
                                            link={`/api/ansokan-service/ansokan/${this.props.ansokanId + '/pdf'} `}
                                            icon={'picture_as_pdf'} isOpenedInNewTab={true} />
                                    </li>
                                    <li>
                                        {this.props.bifogadHandlingList.map((handling, index) =>
                                            <LinkButtonTop text={handling.name}
                                                title={handling.name}
                                                link={`/api/ansokan-service/ansokan/${this.props.ansokanId}/bifogadHandling/${handling.id}`}
                                                icon={'picture_as_pdf'} isOpenedInNewTab={true}
                                                key={'key_' + handling.id} />
                                        )}
                                    </li>
                                </ul>
                            </DocumentPullupList>
                        </ButtonGroup>
                        <ButtonGroup>
                            <ButtonTop
                                text="Skicka in ansökan"
                                icon="check"
                                id="ansokan-submit"
                                data-selenium-id="ansokan-submit"
                                isActive={true}
                                onClick={this.showSendInConfirmationModal} />
                        </ButtonGroup>
                    </div>
                </div>

                {this.state.showReadyToBeSentInModal &&
                    <ReadyToBeSentInModal closeModalCallback={() => this.showReadyToBeSentInModal(false)} />}
                {this.state.showSendInConfirmationModal &&
                    <SendInConfirmationModal onClickJaKnapp={this.submitAnsokanToMyndighet}
                        closeModalCallback={() => this.showSendInConfirmationModal(false)} />}
                {this.state.showAbortSigningModal &&
                    <AbortSigningModal closeModalCallback={() => this.showAbortSigningModal(false)}
                        onClickJaKnapp={this.abortSigning} />
                }
                {this.state.abortSigningError &&
                    <ModalDialogAlert
                        modalTitle={'Ett fel uppstod'}
                        id={'modalAbortSigningErrorDialog'}
                        closeModalCallback={() => this.setState({abortSigningError: false})}
                        description={
                            <span>Ett fel uppstod när signeringen skulle avbrytas. Vänligen försök igen om en liten stund. Skulle problemet kvarstå vänligen kontakta&nbsp;
                                <a href="https://www.lantmateriet.se/kontakt">kundcenter</a>.
                            </span>
                        }
                    />
                }
                {this.state.submitAnsokanError &&
                    <ModalDialogAlert
                        modalTitle={'Ett fel uppstod'}
                        id={'submitAnsokanErrorDialog'}
                        closeModalCallback={() => this.setState({submitAnsokanError: false})}
                        description={
                            <span>Ett fel uppstod när ansökan skulle skickas in. Vänligen försök igen om en liten stund. Om detta inte skulle hjälpa, logga ut och in igen och försök skicka in ansökan på nytt. Skulle problemet kvarstå vänligen kontakta&nbsp;
                                <a href="https://www.lantmateriet.se/kontakt">kundcenter</a>.
                            </span>
                        }
                    />
                }
            </>
        )
    }
}

export function ReadyToBeSentInModal({closeModalCallback}) {
    return <ModalDialogSub modalTitle={'Ansökan är färdig att skickas in'}
        description={<>För att skicka in ansökan väljer du <strong>skicka in ansökan</strong> längst
            ned till höger på
            sidan.</>}
        closeModalCallback={closeModalCallback}
    />
}

export function SendInConfirmationModal({closeModalCallback, onClickJaKnapp}) {
    return <ModalDialogSub modalTitle={'Skicka in ansökan'}
        id={'kostnadsInformation'}
        description={<>Lantmäterimyndighetens kostnader baseras på tidsåtgång eller fast pris. Även om
            ansökan återkallas
            eller om ärendet inte kan fullföljas av annan orsak tar Lantmäterimyndigheten betalt för
            upparbetade kostnader
            av sökande till förrättningen. Är det fler sökande och överenskommelse finns om fördelning av
            förrättningskostnaderna följs normalt denna fördelning.
            <br /><br />
            Vill du skicka in ansökan?</>}
        acceptButton={{text: 'Ja', onClick: onClickJaKnapp}}
        declineButton={{text: 'Nej'}}
        closeModalCallback={closeModalCallback}
    />
}

export default connect(mapStateToProps, mapDispatchToProps)(SigningFinished)
