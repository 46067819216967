import { FeatureToggle } from './FeatureToggle'
import { fetchToggles } from './featureToggleRepository'
import { mapFeatureToggleJsonToFeatureToggles } from './FeatureToggleMapper'
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

const initialState: {
    toggles: Map<string, FeatureToggle>,
    pending: boolean,
    errorMsg: string
} = {
    toggles: new Map<string, FeatureToggle>(),
    pending: false,
    errorMsg: ''
}

export type LoadSuccessActionPayload = {
    toggles: Map<string, FeatureToggle>
}

type LoadFailActionPayload = {
    errorMsg: string
}


const featureTogglesSlice = createSlice({
    name: 'toggles',
    initialState: initialState,
    reducers: {
        loadFeatureTogglesSuccess(state, action: PayloadAction<LoadSuccessActionPayload>) {
            state.toggles = action.payload.toggles
        },
        loadFeatureTogglesFailed(state, action: PayloadAction<LoadFailActionPayload>) {
            state.errorMsg = action.payload.errorMsg
            state.pending = false
        },
        loadFeatureToggles(state) {
            state.pending = true
        }
    }
})

export const {loadFeatureTogglesSuccess, loadFeatureTogglesFailed, loadFeatureToggles} = featureTogglesSlice.actions;

export default featureTogglesSlice.reducer;


export function getToggles () {
  return function action (dispatch: Dispatch) {
    dispatch(loadFeatureToggles())
    return fetchToggles().then((response) => {
      return response
    }).then((toggles) => {
      const mappedToggles = mapFeatureToggleJsonToFeatureToggles(toggles)
      dispatch(loadFeatureTogglesSuccess({toggles: mappedToggles}))
      return mappedToggles
    }).catch((err: Error) => {
      dispatch(loadFeatureTogglesFailed({errorMsg: err.message}))
      throw err
    })
  }
}
