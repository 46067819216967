import {Komplettering, KOMPLETTERINGSTATUS} from "../../../../domain/Komplettering"
import {useAppDispatch} from "../../../../hooks"
// @ts-ignore
import {ListItemActionButton} from 'redet-react-components'
import React, {memo, ReactNode, useEffect, useRef, useState} from "react"
import {signKompletteringAbort} from "../../../../redux/modules/arendeKomplettering"
import {SIGNINGINSTANCE_STATUS} from "../../../../domain/signing/SigningStatus"
import {ArendeKompletteringSigningUrlModal, DeleteKompletteringConfirmationModal} from "../../komplettering/kompletteringShared"
import {removeKomplettering} from "../../../../redux/modules/arendeKompletteringar";
import {ListItemFlex} from "../../../General/list/ListItemFlex";
import {KompletteringProcessingListItem} from "./listitems/KompletteringProcessingListItem";
import {KompletteringSubmittedListItem} from "./listitems/KompletteringSubmittedListItem";
import {KompletteringDraftListItem} from "./listitems/KompletteringDraftListItem";
import {KompletteringDefaultListItem} from "./listitems/KompletteringDefaultListItem";
import {useKompletteringStatusPoll} from "../../komplettering/hooks/useKompletteringStatusPoll";
import {ListItemFlexGroup} from "../../../General/list/ListItemFlexGroup";
import KompletteringHelper from "../../../../helpers/KompletteringHelper";
import {KompletteringTimedOutListItem} from "./listitems/KompletteringTimedOutListItem";

interface Props {
    komplettering: Komplettering,
    isReloading?: boolean,
    onDeleted?: (id: string) => void,
    onSigningCompleted?: (id: string) => void,
    onError?: (id: string, error: Error) => void,
}

/**
 * En "kompletterings-parser" som pollar efter statusförändringar och renderar
 * en listitem utifrån kompletteringens status
 * @param kompletteringId
 * @param onDeleted
 * @param onSigningCompleted
 * @param onError
 * @constructor
 */
export function KompletteringItemView({komplettering, isReloading = false, onDeleted, onSigningCompleted, onError, ...rest}: Props) {

    const [currentKomplettering, setCurrentKomplettering] = useState<Komplettering>(komplettering)

    const _prevSigningStatusRef = useRef<SIGNINGINSTANCE_STATUS>()
    const getPrevSigningStatusRef = () => _prevSigningStatusRef.current
    const setPrevSigningStatusRef = (status: SIGNINGINSTANCE_STATUS) => _prevSigningStatusRef.current = status

    const [signingUrl, setSigningUrl] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<Error>()
    const [processingMessage, setProcessingMessage] = useState<string>()

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
    const [showSigningUrlModal, setShowSigningUrlModal] = useState(false)

    //skapa pollnings hook för att hämta status på ett 3000ms interval, disabla från start
    const {
        kompletteringStatus,
        signingStatus,
        startPolling,
        stopPolling,
        error
    } = useKompletteringStatusPoll(komplettering.kompletteringId, {interval: 3000, enabled: false});

    const isCompleted = () => getPrevSigningStatusRef() === SIGNINGINSTANCE_STATUS.SUCCEEDED && currentSigningStatus?.status === SIGNINGINSTANCE_STATUS.NOT_STARTED

    const dispatch = useAppDispatch()

    useEffect(() => {

        setCurrentKomplettering(komplettering)
        setPrevSigningStatusRef(komplettering.signingStatus?.status)
        validateStatusPolling(komplettering.status)

    }, [komplettering])

    useEffect(() => {
        //om ärendet är klart
        if (isCompleted()) {
            //stoppa pollning
            stopPolling()
            //trigga reload av lista i parent för att rensa bort
            if (onSigningCompleted)
                onSigningCompleted(kompletteringId)
            setProcessingMessage('Skickar in komplettering')
        }
        if (signingStatus)
            setPrevSigningStatusRef(signingStatus.status)
    }, [signingStatus])

    useEffect(() => {
        if (kompletteringStatus && !isCompleted())
            validateStatusPolling(kompletteringStatus)
    }, [kompletteringStatus])

    useEffect(() => {
        const currentError = error ?? errorMessage
        if (currentError && onError) {
            stopPolling()
            //skicka upp felet till parent
            onError(kompletteringId, currentError)
        }
    }, [error, errorMessage])

    //statiska värden som inte ändras
    const kompletteringId = currentKomplettering.kompletteringId
    const submittedDate = currentKomplettering.inskickadDatum
    const kompletteringTyp = currentKomplettering.typ
    const createdDate = currentKomplettering.skapad
    const kvittensId = currentKomplettering.kvittensId

    const getListItemTitle = (): string => {
        if(!isActiveKomplettering() && currentKompletteringStatus === KOMPLETTERINGSTATUS.UTKAST)
            return 'Utkast'
        return KompletteringHelper.getKompletteringTypeTitle(currentKomplettering.typ, currentKomplettering.handlingTyp)
    }

    const currentSigningStatus = signingStatus || currentKomplettering.signingStatus
    const currentKompletteringStatus = kompletteringStatus || currentKomplettering.status

    function validateStatusPolling(status: KOMPLETTERINGSTATUS) {
        status !== KOMPLETTERINGSTATUS.INSKICKAD && status !== KOMPLETTERINGSTATUS.AVBOJD ?
            startPolling() :
            stopPolling()
    }

    const isSubmitting = () => currentSigningStatus?.status === SIGNINGINSTANCE_STATUS.SUCCEEDED
    const isActiveKomplettering = () => {
        // om kompletteringen inte är inskickad och det finns en pågående signering
        return currentKompletteringStatus &&
            currentKompletteringStatus !== KOMPLETTERINGSTATUS.INSKICKAD &&
            currentSigningStatus?.status !== SIGNINGINSTANCE_STATUS.SUCCEEDED &&
            currentSigningStatus?.signerStatus && currentSigningStatus.signerStatus.length > 0
    }

    function displayUrlModal(url: string, show = true) {
        setSigningUrl(url)
        setShowSigningUrlModal(show)
    }

    function displayDeleteKompletteringConfirmationModal(show = true) {
        setShowDeleteConfirmationModal(show)
    }

    async function deleteKomplettering() {
        //stoppa pollningen
        stopPolling()

        let serverError: Error | undefined

        //visa laddningsindikator
        setProcessingMessage('Tar bort komplettering ...')
        //om det finns en signeringsinstans tar vi bort den först
        if (currentSigningStatus?.signerStatus && currentSigningStatus?.signerStatus.length > 0) {
            await dispatch(signKompletteringAbort(kompletteringId))
                .catch((err: Error) => {
                    serverError = err
                })
        }
        if (!serverError) {
            //om ok, ta bort komplettering från ärendet och avisera parent
            await dispatch(removeKomplettering(kompletteringId))
                .then(() => {
                    if (onDeleted)
                        onDeleted(kompletteringId)
                })
                .catch((err: Error) => {
                    serverError = err
                })
        }
        setErrorMessage(serverError)
    }

    /**
     * Skapar en listitem för kompletteringen utifrån status
     */
    const getListItemNode = (): ReactNode => {

        if (error || errorMessage)
            // fel hanteras av parent
            return <></>

        if (processingMessage !== undefined)
            return <>{getLoadingItemNode(processingMessage)}</>

        if (isSubmitting())
            return <KompletteringProcessingListItem data-testid={'komplettering-processing-list-item'}
                                                    data-selenium-id={'komplettering-processing-list-item'}
                                                    id={kompletteringId}
                                                    header={getListItemTitle()}
                                                    createdDate={createdDate}
                                                    {...rest}/>

        if (currentSigningStatus && currentKompletteringStatus && isActiveKomplettering())
            return <KompletteringDefaultListItem data-testid={'komplettering-default-list-item'}
                                                 data-selenium-id={'komplettering-default-list-item'}
                                                 id={kompletteringId}
                                                 header={getListItemTitle()}
                                                 kompletteringId={kompletteringId}
                                                 kompletteringTyp={kompletteringTyp}
                                                 kompletteringStatus={currentKompletteringStatus}
                                                 signingStatus={currentSigningStatus}
                                                 kvittensId={kvittensId}
                                                 createdDate={createdDate}
                                                 submittedDate={submittedDate}
                                                 onDeleteClick={() => displayDeleteKompletteringConfirmationModal()}
                                                 onInviteClick={(event, url) => displayUrlModal(url)}
                                                 {...rest}/>
        //other
        switch (currentKompletteringStatus) {
            case KOMPLETTERINGSTATUS.INSKICKAD:
                return (<KompletteringSubmittedListItem data-testid={'komplettering-submitted-list-item'}
                                                        data-selenium-id={'komplettering-submitted-list-item'}
                                                        id={kompletteringId}
                                                        header={getListItemTitle()}
                                                        submittedDate={submittedDate}
                                                        {...rest}/>)
            case KOMPLETTERINGSTATUS.UTKAST:
                return (<KompletteringDraftListItem data-testid={'komplettering-draft-list-item'}
                                                    data-selenium-id={'komplettering-draft-list-item'}
                                                    id={kompletteringId}
                                                    header={getListItemTitle()}
                                                    createdDate={createdDate}
                                                    onDeleteClick={() => displayDeleteKompletteringConfirmationModal(true)}
                                                    {...rest}/>)
            case KOMPLETTERINGSTATUS.UTGATT:
                return (<KompletteringTimedOutListItem data-testid={'komplettering-timed-out-list-item'}
                                                    data-selenium-id={'komplettering-timed-out-list-item'}
                                                    id={kompletteringId}
                                                    header={getListItemTitle()}
                                                    createdDate={createdDate}
                                                    onDeleteClick={() => displayDeleteKompletteringConfirmationModal(true)}
                                                    {...rest}/>)
            default:
                // här hamnar vi när signingstatus är okänt, borde inte ske
                return <>{getLoadingItemNode('Hämtar signeringsstatus ...')}</>
        }
    }

    const getLoadingItemNode = (message: string = ''): ReactNode => {
        return (<ListItemFlex>
            {{
                actions: <ListItemActionButton
                    id={`processing-item-${kompletteringId}`}
                    icon={'hourglass_full'}
                    isDisabled={true}
                    ariaLabel={message}
                />,
                items: <ListItemFlexGroup title={getListItemTitle()} status={{text: message, icon: 'schedule'}}/>
            }}
        </ListItemFlex>)
    }

    return (<>
        {/* listitem */}
        {getListItemNode()}
        {/* modaler */}
        {showSigningUrlModal &&
            <ArendeKompletteringSigningUrlModal closeModalCallback={() => setShowSigningUrlModal(false)}
                                                epost={signingUrl}/>
        }
        {showDeleteConfirmationModal &&
            <DeleteKompletteringConfirmationModal kompletteringTitle={getListItemTitle()}
                                                  closeModalCallback={() => setShowDeleteConfirmationModal(false)}
                                                  onClickJaKnapp={() => deleteKomplettering()}/>}
    </>)
}

export const KompletteringItemViewMemo = memo(KompletteringItemView,
    (pre, next) => {
        //only re-render in parent if removed or added
        return pre.komplettering.kompletteringId === next.komplettering.kompletteringId
    }
)

export default KompletteringItemView
