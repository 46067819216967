import {useContext, useEffect} from "react";
//@ts-ignore
import { ButtonGroup, PageTopContent, LinkButtonTop, PageSubContent, ButtonToolbarV2 } from "redet-react-components";
import { AnsokanWizardSDGContext } from "./AnsokanWizardSDGContext";
import ApplicationSDGList from "./ApplicationSDGList";
import {
  filterFilesByCount,
  filterFilesBySize,
  filterFilesByAllowedTypes,
} from "./HandlingUtils";
import UploadFilesApplication from "./UploadFilesApplication";
import { useTranslation } from "react-i18next";
import LinkButton from "./LinksForSDG";
import {scrollToMainTag} from "./util";

export default function UploadApplicationForm() {
  const { ansokan, setAnsokan } = useContext(AnsokanWizardSDGContext);
  const { t, i18n: {language} } = useTranslation();

  useEffect(() => {
        scrollToMainTag()
    }, []);

  return (
    <>
      <PageTopContent
        title={t("application.title")}
        headerLevel={2}
        headerId={"applicationPdfId"}
      />
      <br />
      <PageSubContent
        title={t("application.step1")}
        headerLevel={3}
        headerId={"downloadApplicationId"}
      >
        <p>{t("application.download")}</p>
          <ButtonToolbarV2>
              <ButtonGroup>
                  <>
                      {language === 'en' &&
                          <LinkButtonTop icon={'file_download'} text={t('application.downloadBtn')}
                                         download={"/ApplicationForm.pdf"} isOpenedInNewTab={true}
                                         link={'/ApplicationForm.pdf'}/>}
                      {language === 'sv' &&
                          <LinkButtonTop icon={'file_download'} text={t('application.downloadBtn')}
                                         download={"/Ansokan.pdf"} isOpenedInNewTab={true} link={'/Ansokan.pdf'}/>}
                  </>
              </ButtonGroup>

          </ButtonToolbarV2>
      </PageSubContent>
      <PageSubContent
        title={t("application.step2")}
        headerId={"completeApplicationId"}
        headerLevel={3}
      >
        <p>{t("application.complete")}</p>
      </PageSubContent>
      <PageSubContent
        title={t("application.step3")}
        headerLevel={3}
        headerId={"uploadApplicationId"}
      >
        <UploadFilesApplication
              multiple={false}
              selectedFiles={(selected) => setAnsokan(selected[0])}
              validationMethods={[
                (selected) => filterFilesByCount(selected, ansokan ? [ansokan] : [], 1),
                (selected) => filterFilesByAllowedTypes(selected, ['application/pdf']),
                filterFilesBySize]}
                labelText={<>
                <p>{t('application.upload')}</p>
                {!ansokan &&
                <p><strong>{t('application.mustUpload')}</strong></p>}
                </> }
              />
           {ansokan &&
            <ApplicationSDGList deleteHandlingCallback={() => setAnsokan(undefined)} items={[ansokan]}/>
          }
          <br/>
      </PageSubContent>
      <div className="toolbar mobile-toolbar">
      <ButtonGroup/>
        <ButtonGroup>
          <LinkButton to={"/euCitizenApplication/additionalDocuments"} disabled={!ansokan} icon="arrow_forward" text={t('application.frwrdBtn')}/>
        </ButtonGroup>
      </div>
  </>
  );
}
