import memoize from 'lru-memoize'
import {
  createValidator,
  email,
  integer,
  maxLength,
  minLength,
  oneInteger,
  required,
  samordningsNumber,
  swedishSocialSecurityNumber,
} from 'utils/validation'
import invoiceFormValidations, { invoiceAlternativeFieldValidations, } from '../invoicingFormValidations'
import commonContactFieldsValidations from '../Common/commonContactFieldsValidations'
import {individualEmailValidation, individualIdNumberValidation, individualPersonnummerValidation,} from '../../../utils/contactsValidationsHelper'
import { validatePhonenumber } from '../../../utils/telephoneNumberValidation'

export const individualContactFieldValidations = {
  personnummer: [integer, swedishSocialSecurityNumber],
  samordningsnummer: [integer, samordningsNumber],
  ovrigtIdNummer: [oneInteger],
  namn: [required, minLength(4), maxLength(180)],
  epost: [email, maxLength(100)],
  telefonMobil: [validatePhonenumber],
  telefonHem: [validatePhonenumber],
  telefonDagtid: [maxLength(20)],
}

export function individualContactFormValidations (formValues, contactList, contactId, ansokanValidations) {
  individualPersonnummerValidation(formValues)
  individualEmailValidation(formValues)
  individualIdNumberValidation(formValues)

  let validations = { ...individualContactFieldValidations, ...commonContactFieldsValidations(formValues, contactList, contactId, ansokanValidations) }
  validations = formValues && formValues.fakturaAdressAngiven ? { ...validations, ...invoiceFormValidations } : validations
  validations = formValues && formValues.alternativAdressAngiven ? { ...validations, ...invoiceAlternativeFieldValidations } : validations
  return createValidator(formValues, validations)
}

export default memoize(10)(individualContactFormValidations)
