// @flow
// 1.21 En kontaktperson med rollen sökande måste ha en epostadress angiven

import { BLOCKING, SOKANDE_MASTE_HA_EPOSTADRESS } from './constants'
import { getNamn, harOmbud } from '../../../../utils/contactsHelper'
import { Validation } from './model/Validation'

function createError (invalidContacts): ?Array<Validation> {
  const invalidContactsName = invalidContacts.map(contacts => getNamn(contacts))
  if (invalidContacts) {
    return invalidContactsName.map((name) => (new Validation(SOKANDE_MASTE_HA_EPOSTADRESS, `Sökande ${name} saknar e-postadress.`, 'contacts', BLOCKING, undefined)))
  }
  return undefined
}

const sokandeUtanEpostadress = contact => {
  if (!contact.data.rollSokande) {
    return false
  }
  if (contact.type === 'contacts/CONTACT_TYPE_INDIVIDUAL') {
    return !contact.data.epost
  }
  return false
}

function validate (state: Object): ?Array<Validation> {
  const contacts = state.contacts.contactList

  const contactsWithoutEpost = contacts.filter(sokandeUtanEpostadress)
  //Om man har ett ombud så ska inte detta vara ett krav
  const invalidContacts = contactsWithoutEpost.filter(contact => (!harOmbud(contacts, contact.id)))
  if (invalidContacts.length > 0) {
    return createError(invalidContacts)
  }
  return undefined
}

export default validate
