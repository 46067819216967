// @ts-ignore
import {ButtonTop, VarningsRuta} from 'redet-react-components'
import React from 'react'
import {formatToDateAndTime} from '../../../helpers/dates'
import PropTypes from "prop-types"


interface Props {
    failedToSubmit?: boolean,
    inskickadDatum?: any,
    closeModal?: Function
}

export function KompletteringInskickadStep(props: Props) {

    const addInskickadDatumToInfo = (value: any) => {
        const formattedDateString = formatToDateAndTime(value)
        return (formattedDateString !== '') ? ' och har fått datum ' + formattedDateString : ''
    }

    return (
        <>
            {
                props.failedToSubmit ?
                    <VarningsRuta
                        warningMessages={[
                            {
                                msg: `Det gick inte att skicka in just nu men din komplettering kommer att skickas in 
                                automatiskt inom kort${addInskickadDatumToInfo(props.inskickadDatum)}.`
                            }
                        ]}
                        ariaTitle={"Inskicksinformation"}>
                    </VarningsRuta>
                    :
                    <>
                        <h3 id={'skickaInKvittensTitelId'}>Inskickad handling</h3>
                        <p className="introduction" id={'skickaInKvittensIntroId'}>Handlingen är nu
                            inskickad{addInskickadDatumToInfo(props.inskickadDatum)}.</p>
                    </>
            }
            <p id={'skickaInKvittensLoptextId'}>Notera ditt inskickningsdatum, det kan
                behövas vid kontakt med myndigheten gällande din kompletterande handling.
                Din komplettering kommer att diarieföras och läggas till i ärendet som underlag för handläggning.</p>
            <ButtonTop text={'OK'}
                       icon="check"
                       id="closeWizard"
                       onClick={props.closeModal}/>
        </>
    )
}

KompletteringInskickadStep.propTypes = {
    failedToSubmit: PropTypes.bool,
    inskickadDatum: PropTypes.string,
    closeModal: PropTypes.func
}