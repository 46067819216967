import { useCookies } from 'react-cookie';
import { FEATURE_TOOGLE_COOKIE_NAME } from '../redux/modules/featureToggle'
import { useAppDispatch } from 'hooks';
import { disableAllFeatures, enableFeature } from 'redux/modules/featureToggle'

export function useCookieFeatureToggleHelper() {
 
    const [cookies, setCookie, removeCookie] = useCookies([FEATURE_TOOGLE_COOKIE_NAME]);
    const dispatch = useAppDispatch();

    function enableFeatureToggle(featureToEnable: String) {
        const currentCookie = cookies[FEATURE_TOOGLE_COOKIE_NAME]
        let newCookie
        if (currentCookie) {
          newCookie = {
            enabledFeatureToggles: [
              ...currentCookie.enabledFeatureToggles.filter((cookieFeature: String) => cookieFeature !== featureToEnable),
              featureToEnable
            ]
          }
        } else {
          newCookie = { enabledFeatureToggles: [featureToEnable] }
        }
      
        setCookie(FEATURE_TOOGLE_COOKIE_NAME, JSON.stringify(newCookie), {
          httpOnly: false,
          expires: new Date(2030, 0),
          secure: false,
          sameSite: 'lax',
          path: '/'
        })
        dispatch(enableFeature(featureToEnable));
        
    }

    function clearFeatureToggles() {
        dispatch(disableAllFeatures())
        removeCookie(FEATURE_TOOGLE_COOKIE_NAME);
    }

    function applyFeatureTogglesFromCookie() {
         // Apply feature toggles if enabled
        const featureToggles: { enabledFeatureToggles: String[] }  = cookies[FEATURE_TOOGLE_COOKIE_NAME];
        if (featureToggles) {
            featureToggles.enabledFeatureToggles.forEach((enabledToggle: String) => dispatch(enableFeature(enabledToggle)));
        }
    }

    return { enableFeatureToggle, clearFeatureToggles, applyFeatureTogglesFromCookie };
}