import React from 'react'
import { EmailInputFinalForm, TextInputFinalForm } from '../../General/forms/finalForm'
import { Column, Row } from 'redet-react-components'

export default function OrganizationPhoneEmailForm () {
  return (
    <div>
      <Row>
        <Column columns="col-xs-12 col-sm-6">
          <TextInputFinalForm
            field={'orgTelefon'}
            label={'Telefon, organisation'}
            type={'tel'}
            maxLength={20}
          />
        </Column>
      </Row>
      <Row>
        <Column columns="col-xs-12 col-sm-6">
          <TextInputFinalForm
            field={'orgTelefonKontaktperson'}
            label={'Telefon kontaktperson direkt'}
            type={'tel'}
            maxLength={20}
          />
        </Column>
        <Column columns="col-xs-12 col-sm-6">
          <TextInputFinalForm
            field={'orgTelefonKontaktpersonMobil'}
            label={'Telefon kontaktperson mobil'}
            type={'tel'}
            maxLength={20}
          />
        </Column>
      </Row>
      <Row>
        <Column columns="col-xs-12 col-sm-6">
          <EmailInputFinalForm
            field={'orgEmail'}
            label={'E-post, organisation'}
            maxLength={100}
          />
        </Column>
        <Column columns="col-xs-12 col-sm-6">
          <EmailInputFinalForm
            field={'orgKontaktpersonEmail'}
            label={'E-post, kontaktperson'}
            maxLength={100}
            required={false}
          />
        </Column>
      </Row>
    </div>
  )
}
