// Regler från wikisida:
// http://wiki.lmv.lm.se/pages/viewpage.action?pageId=380830315

import ruleMinstEnFastighet from './ruleMinstEnFastighet'
import ruleMinstEnAtgard from './ruleMinstEnAtgard'
import ruleMinstEnSokande from './ruleMinstEnSokande'
import ruleSokandeMasteHaEpost from './ruleSokandeMasteHaEpost'
import ruleMinstEnAktmottagare from './ruleMinstEnAktmottagare'
import ruleMinstEnFakturamottagare from './ruleMinstEnFakturamottagare'
import ruleMinstEnBifogadHandling from './ruleMinstEnBifogadHandling'
import kostnadsfordelningMasteAnges from './ruleKostnadsfordelningMasteAnges'
import ruleMinstTvaFastigheterVidFastighetsreglering from './ruleMinstTvaFastigheterVidFastighetsreglering'
import ruleMinstTvaFastigheterVidSammanlaggning from './ruleMinstTvaFastigheterVidSammanlaggning'
import ruleUpprattareMasteHaEpost from './ruleUpprattareMasteHaEpost'
import ruleMinstEnKartskiss from './ruleMinstEnKartskiss'
import ruleIngaValideringsfelFinnsForAtgarder from './ruleIngaValideringsfelFinnsForAtgarder'
import ruleOmbudMasteForetradaNagon from './ruleOmbudMasteForetradaNagon'
import ruleForetraddMasteVaraSokande from './ruleForetraddMasteVaraSokande'
import ruleSokandeUtanPnrMasteHaOmbud from './ruleSokandeUtanPnrMasteHaOmbud'
import ruleSokandeUtanBankIdMasteHaOmbud from './ruleSokandeUtanBankIdMasteHaOmbud'
import ruleSokandeOrganizationMustHaveOmbud from './ruleSokandeOrganizationMustHaveOmbud'
import ruleIngaValideringsfelFinnsForKontakter from './ruleIngaValideringsfelFinnsForKontakter'
import ruleContactMustHaveIdNumber from './ruleContactMustHaveIdNumber'

const ruleList = [
  ruleMinstEnFastighet,
  ruleMinstEnAtgard,
  ruleOmbudMasteForetradaNagon,
  ruleForetraddMasteVaraSokande,
  ruleSokandeUtanPnrMasteHaOmbud,
  ruleSokandeUtanBankIdMasteHaOmbud,
  ruleSokandeOrganizationMustHaveOmbud,
  ruleContactMustHaveIdNumber,
  ruleMinstEnSokande,
  ruleSokandeMasteHaEpost,
  ruleUpprattareMasteHaEpost,
  ruleMinstEnAktmottagare,
  ruleMinstEnFakturamottagare,
  kostnadsfordelningMasteAnges,
  ruleMinstTvaFastigheterVidFastighetsreglering,
  ruleMinstTvaFastigheterVidSammanlaggning,
  ruleMinstEnBifogadHandling,
  ruleMinstEnKartskiss,
  ruleIngaValideringsfelFinnsForAtgarder,
  ruleIngaValideringsfelFinnsForKontakter
]

export default ruleList
