import {useEffect, useRef} from "react";

/**
 * Enkel hook som anropar en given function med angivet interval,
 * inväntar response
 * @param callback
 * @param delay (null för att stoppa)
 */
export function usePolling<T>(callback: (() => Promise<T>) | (() => void), delay?: number | null) {

    const savedCallback = useRef(() => {
    })
    const pollingId = useRef<NodeJS.Timeout | undefined>()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (delay !== null) {
            const tick = async () => {
                await savedCallback.current()
                pollingId.current = setTimeout(tick, delay)
            }
            pollingId.current = setTimeout(tick, delay || 0)
        }
        //clean-up
        return () => {
            clearTimeout(pollingId.current)
        }

        // async w/ interval ??
        // if (delay !== null) {
        //     pollingId.current = setInterval(() => {
        //         savedCallback.current()
        //     }, delay || 0)
        // }
        // //clean-up
        // return () => clearInterval(pollingId.current)

    }, [callback, delay])
}
