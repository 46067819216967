import Log from 'utils/log'
import { Overlay } from 'ol'

export default class MeasureTooltip {
  constructor (mapController) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
  }

  rebuild () {
    if (this.toolTip) {
      this.toolTip.getElement().parentNode.removeChild(this.toolTip.getElement())
    }
    const newDiv = document.createElement('div')
    newDiv.className = 'tooltip-measure'
    newDiv.style = 'pointer-events: none'
    const newOverLay = new Overlay({
      element: newDiv,
      offset: [0, -15],
      positioning: 'bottom-left'
    })
    newOverLay.set('isMeasureTooltip', true)
    this.toolTip = newOverLay
    this.mapController.getMap().addOverlay(this.toolTip)
  }

  saveAtOffset (x, y) {
    this.toolTip.getElement().className = 'tooltip-static'
    this.toolTip.setOffset([x, y])
    this.toolTip = null
    this.rebuild()
  }

  changeOffset (x, y) {
    this.toolTip.setOffset([x, y])
  }

  updateContentAndLocation (content, location) {
    this.toolTip.getElement().innerHTML = content
    this.toolTip.setPosition(location)
  }

  reset () {
    this.mapController.getMap().removeOverlay(this.toolTip)
    this.rebuild()
  }
}
