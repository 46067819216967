import Log from '../utils/log'
import { AjaxClientError } from '../utils/errorHandler'

const repositoryUrl = '/api/forrattningsinfo/forattningsarende/'
const log = new Log('ansokanRepository')

export class ArendeNotFoundException {
  constructor (errorMessage = '') {
    this.errorMessage = errorMessage
  }
}

export class BehorighetSaknasException {
  constructor (errorMessage = '') {
    this.errorMessage = errorMessage
  }
}

export function loadList (fetcher, filter) {
  return fetcher.fetching(repositoryUrl, {
        cache: 'no-store',
        method: 'POST',
        body: JSON.stringify(filter),
      },
      { 'Content-Type': 'application/json' }
  ).then(response => {
    return response.json()
  }).then((arenden) => {
    log.info('Arende list successfully loaded from server')
    return { arenden }
  }).catch(err => {
    log.error('Error encountered while loading arende list from server')
    // Custom error handling
    throw new AjaxClientError(repositoryUrl, err.status, err, err.statusText)

  })
}

export class LoadArendeRequest {
  constructor (arendeNo, asHandlaggare = false) {
    this.arendeNo = arendeNo
    this.asHandlaggare = asHandlaggare
  }
}

export function load (fetcher, loadArendeRequest) {
  if (!loadArendeRequest || !loadArendeRequest.arendeNo) {
    const msg = 'The key argument is missing'
    log.error('Load operation aborted: ' + msg)
    throw new Error(msg)
  }
  let url = repositoryUrl + loadArendeRequest.arendeNo

  if (loadArendeRequest.asHandlaggare) {
    url += '?asHandlaggare=true'
  }

  return fetcher.fetching(url, { cache: 'no-store' })
      .then((response) => {
        return response.json()
      }).then(arende => {
        log.info('Ärende successfully loaded from server')
        return arende
      }).catch(err => {
        // Custom error handling
        switch (err.status) {
          case 403:
            throw new BehorighetSaknasException('Behörighet att visa ärende saknas')
          case 404:
            throw new ArendeNotFoundException('Ärende not found or access denied')
          default:
            throw new AjaxClientError(url, err.status, err, err.statusText)
        }
      })
}
