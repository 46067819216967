import {getQuota, HandlingType, loadList, remove, upload} from 'repository/bifogadHandlingRepository'
import { Fetcher } from '../../repository/fetcher'
import { addProgress, endProgress } from './fullscreenLoading'
import { ANSOKAN_FORM_DESTROY } from 'redux/modules/sharedActions'
import { bifogadeHandlingarSorter } from '../../helpers/sorters'
import { sumAndFormatFileSize } from '../../helpers/fileHelpers'
import {ListedBifogadHandling} from "../../domain/ListedBifogadHandling";
import {RootState} from "../../store";
import {Dispatch} from "redux";
import {logError} from "../../utils/errorHandler";

const ACTION_DISPLAY_UPLOAD_FORM = 'BifogadHandling/ACTION_DISPLAY_UPLOAD_FORM'
export const ACTION_CLOSE_UPLOAD_FORM = 'BifogadHandling/ACTION_CLOSE_UPLOAD_FORM'

const ACTION_FETCH_BIFOGAD_HANLDINGAR_PENDING = 'BifogadHandling/ACTION_FETCH_BIFOGAD_HANLDINGAR_PENDING'
export const ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS = 'BifogadHandling/ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS'
const ACTION_FETCH_BIFOGAD_HANLDINGAR_FAILED = 'BifogadHandling/ACTION_FETCH_BIFOGAD_HANLDINGAR_FAILED'

const ACTION_UPLOAD_BIFOGAD_HANLDING_PENDING = 'BifogadHandling/ACTION_UPLOAD_BIFOGAD_HANLDING_PENDING'
export const ACTION_UPLOAD_BIFOGAD_HANLDING_SUCCESS = 'BifogadHandling/ACTION_UPLOAD_BIFOGAD_HANLDING_SUCCESS'
const ACTION_UPLOAD_BIFOGAD_HANLDING_FAILED = 'BifogadHandling/ACTION_UPLOAD_BIFOGAD_HANLDING_FAILED'

const ACTION_REMOVE_BIFOGAD_HANDLING_PENDING = 'BifogadHandling/ACTION_REMOVE_BIFOGAD_HANDLING_PENDING'
export const ACTION_REMOVE_BIFOGAD_HANDLING_SUCCESS = 'BifogadHandling/ACTION_REMOVE_BIFOGAD_HANDLING_SUCCESS'
const ACTION_REMOVE_BIFOGAD_HANDLING_FAILED = 'BifogadHandling/ACTION_REMOVE_BIFOGAD_HANDLING_FAILED'

const ACTION_GET_QUOTA_PENDING = 'BifogadHandling/GET_QUOTA_PENDING'
const ACTION_GET_QUOTA_SUCCESS = 'BifogadHandling/ACTION_GET_QUOTA_SUCCESS'
const ACTION_GET_QUOTA_FAILED = 'BifogadHandling/ACTION_GET_QUOTA_FAILED'

export const USER_CHOOSE_NOT_TO_UPLOAD_BIFOGAD_HANDLIG = 'BifogadHandling/USER_CHOOSE_NOT_TO_UPLOAD_BIFOGAD_HANDLIG'
export const USER_CHOOSE_TO_UPLOAD_BIFOGAD_HANDLIG = 'BifogadHandling/USER_CHOOSE_TO_UPLOAD_BIFOGAD_HANDLIG'

export const ANSOKAN_LOAD_SUCCESSFUL = 'ansokanRepository/LOAD_SUCCESSFUL'

export const PENDING_FILES = 'BifogadHandling/PENDING_FILES'
export const NO_PENDING_FILES = 'BifogadHandling/NO_PENDING_FILES'

const initialState = {
  list: [],
  isLoadingList: false,
  isUploadFormVisible: false,
  isUploading: false,
  isDeleting: false,
  error: '',
  hasUserChosenNotToUploadBifogadHandlig: false,
  numberOfBifogadeHandlingar: 0,
}

export default function reducer (state:any = initialState, action :{type:string, result?: any, msg?: string, choice?: boolean}) {
  switch (action.type) {
    case NO_PENDING_FILES:
    case PENDING_FILES:
      return {
        ...state,
      }

    case ACTION_DISPLAY_UPLOAD_FORM:
      return {
        ...state,
        isUploadFormVisible: true,
      }

    case ACTION_CLOSE_UPLOAD_FORM:
      return {
        ...state,
        isUploadFormVisible: false,
      }

    case ACTION_FETCH_BIFOGAD_HANLDINGAR_PENDING:
      return {
        ...state,
        isLoadingList: true,
      }

    case ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS:
      return {
        ...state,
        list: action.result,
        isLoadingList: false,
        numberOfBifogadeHandlingar: action.result.length
      }

    case ACTION_FETCH_BIFOGAD_HANLDINGAR_FAILED:
      return {
        ...state,
        isLoadingList: false,
      }

    case ACTION_UPLOAD_BIFOGAD_HANLDING_PENDING:
      return {
        ...state,
        isUploading: true,
      }

    case ACTION_UPLOAD_BIFOGAD_HANLDING_SUCCESS:
      return {
        ...state,
        isUploading: false,
        numberOfBifogadeHandlingar: state.numberOfBifogadeHandlingar + 1
      }
    case ACTION_UPLOAD_BIFOGAD_HANLDING_FAILED:
      return {
        ...state,
        isUploading: false,
      }

    case ACTION_REMOVE_BIFOGAD_HANDLING_PENDING:
      return {
        ...state,
        isDeleting: true,
      }

    case ACTION_REMOVE_BIFOGAD_HANDLING_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        numberOfBifogadeHandlingar: state.numberOfBifogadeHandlingar - 1
      }
    case ACTION_REMOVE_BIFOGAD_HANDLING_FAILED:
      return {
        ...state,
        isDeleting: false,
      }

    case ACTION_GET_QUOTA_FAILED:
      return {
        ...state,
        error: action.msg,
      }
    case USER_CHOOSE_NOT_TO_UPLOAD_BIFOGAD_HANDLIG:
    case USER_CHOOSE_TO_UPLOAD_BIFOGAD_HANDLIG:
      return {
        ...state,
        hasUserChosenNotToUploadBifogadHandlig: action.choice,
      }
    case ANSOKAN_LOAD_SUCCESSFUL:
      return {
        ...state,
        hasUserChosenNotToUploadBifogadHandlig: action.result.data.hasUserChosenNotToUploadBifogadHandlig === undefined ? false : action.result.data.hasUserChosenNotToUploadBifogadHandlig,
        numberOfBifogadeHandlingar: action.result.data.numberOfBifogadeHandlingar === undefined ? 0 : action.result.data.numberOfBifogadeHandlingar
      }
    case ANSOKAN_FORM_DESTROY:
      return initialState
    default:
      return state
  }
}

export function displayUploadForm () {
  return {
    type: ACTION_DISPLAY_UPLOAD_FORM,
  }
}

export function setUserChooseNotToUploadBifogadHandlig (choice:boolean) {
  if (choice) {
    return {
      type: USER_CHOOSE_NOT_TO_UPLOAD_BIFOGAD_HANDLIG,
      choice: true
    }
  } else {
    return {
      type: USER_CHOOSE_TO_UPLOAD_BIFOGAD_HANDLIG,
      choice: false
    }
  }
}

export function closeUploadForm () {
  return {
    type: ACTION_CLOSE_UPLOAD_FORM,
  }
}

export type BifogadHandlingListItem = {
  id: string
  name: string,
  href: string,
  handling: ListedBifogadHandling,
  size: string,
  pages: string,
  index: number,
  key: string
}
export const restructureAndSortBifogadeHandlingar = (bifogadeHandlingar: ListedBifogadHandling[], ansokanId: string): BifogadHandlingListItem[] => {
  const sortedList = bifogadeHandlingar.sort(bifogadeHandlingarSorter)
  return sortedList.map((handling, index) => ({
    id: handling._id,
    name: handling.listName,
    href: '/api/ansokan-service/ansokan/' + ansokanId + '/bifogadHandling/' + handling._id,
    handling: handling,
    size: sumAndFormatFileSize(handling.files),
    pages: 'innehåller ' + handling.files.length + ' sidor',
    index: index,
    key: `${handling._id}KeyId`,
  }))
}

export function getBifogadHandlingList (ansokanId: string) {
  return function action(dispatch: Dispatch<any>, getState: () => RootState) {
    const fetcher = new Fetcher(dispatch, getState)
    dispatch({type: ACTION_FETCH_BIFOGAD_HANLDINGAR_PENDING})
    return loadList(fetcher, ansokanId)
        .then(bifogadeHandlingar => {
          const sortedBifogadeHandlingar = restructureAndSortBifogadeHandlingar(bifogadeHandlingar, ansokanId)
          dispatch({
            type: ACTION_FETCH_BIFOGAD_HANLDINGAR_SUCCESS,
            result: sortedBifogadeHandlingar
          })
          return sortedBifogadeHandlingar
        }).catch(error => {
          dispatch({
            type: ACTION_FETCH_BIFOGAD_HANLDINGAR_FAILED,
            msg: error.statusText,
          })
          logError(error)
          return Promise.reject()
        })
  }
}

export function removeBifogadHandling (ansokanId: string, handlingId: string) {
  return function action (dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({ type: ACTION_REMOVE_BIFOGAD_HANDLING_PENDING })
    dispatch(addProgress(false))
    const fetcher = new Fetcher(dispatch, getState)
    return remove(fetcher, ansokanId, handlingId)
      .then(() => {
        dispatch({ type: ACTION_REMOVE_BIFOGAD_HANDLING_SUCCESS })
      }).catch(error => {
        dispatch({
          type: ACTION_REMOVE_BIFOGAD_HANDLING_FAILED,
          msg: error.statusText
        })
      }).finally(() => {
        dispatch(endProgress())
      })
  }
}

export function getBifogadHandlingQuotaForAnsokan (ansokanId: string) {
  return function action(dispatch: Dispatch<any>, getState: () => RootState) {
    dispatch({ type: ACTION_GET_QUOTA_PENDING })
    dispatch(addProgress(false))
    const fetcher = new Fetcher(dispatch, getState)
    return getQuota(fetcher, ansokanId)
      .then(quota => {
        dispatch({ type: ACTION_GET_QUOTA_SUCCESS })
        return quota
      }).catch(error => {
        dispatch({
          type: ACTION_GET_QUOTA_FAILED,
          msg: error.statusText
        })
      }).finally(() => {
        dispatch(endProgress())
      })
  }
}

export function uploadBifogadHandling(ansokanId: string, bifogadHandling: HandlingType, onProgressCallback: (percent?: number) => {}, abortController: AbortController = new AbortController()) {
  return function action (dispatch: Dispatch<any>) {
    dispatch({type: ACTION_UPLOAD_BIFOGAD_HANLDING_PENDING})
    return upload(ansokanId, bifogadHandling, onProgressCallback, abortController)
        .then(response => {
          dispatch({type: ACTION_UPLOAD_BIFOGAD_HANLDING_SUCCESS})
          return response
        }).catch((error: Error) => {
          dispatch({type: ACTION_UPLOAD_BIFOGAD_HANLDING_FAILED})
          throw error
        })
  }
}
