import {useState} from 'react'
//@ts-ignore
import { FormFieldset, WizardNavigationButtonToolbar } from 'redet-react-components'
import { EpostInputFieldWithValidation } from '../../../../components/General/EpostInputFieldWithValidation'

type Props = {
  setNotifieringsemail: (email: string) => void,
  initNotifieringsemail: string,
  prevButtonText?: string
  //Injectas med clone element från WizardDialogStep :sick!
  nextStep?: () => void
  previousStep?: () => void
}

export function YrkandeWizardStepEmail(props: Props){

  const [notifieringEmail, setNotifieringEmail] = useState(props.initNotifieringsemail)
  const [notifieringEmailError, setNotifieringEmailError] = useState(false)

  const setNotifieringemailEmailUppdateringarValue = (email: string) => {
    setNotifieringEmail(email);
    props.setNotifieringsemail(email);
  }

  const isFormValid = () => {
    return notifieringEmail?.length > 2 && !notifieringEmailError
  }

  
    return (
      <>
      <FormFieldset title={'E-post'}
                    description={'Ange din e-postadress för att ta emot kvittens vid inskickande.'}>
        <EpostInputFieldWithValidation inputFieldHasWarningValidation={false}
                                       id={'kvittensEmailInput'}
                                       defaultEpost={notifieringEmail}
                                       isRequired={true}
                                       callbackValidEmail={setNotifieringemailEmailUppdateringarValue}
                                       disabledButton={setNotifieringEmailError}/>
      </FormFieldset>

      <WizardNavigationButtonToolbar prevButtonText={props.prevButtonText}
                                     onClickPrevButton={props.previousStep}
                                     nextButtonText={'Underskrift'}
                                     isNextButtonDisabled={!isFormValid()}
                                     onClickNextButton={props.nextStep}/>
    </>
    );


}
