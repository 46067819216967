// @flow
// 1.21 En kontaktperson med rollen sökande måste ha en epostadress angiven

import { BLOCKING, SOKANDE_MASTE_HA_PERSONNUMMER } from './constants'
import { getNamn, harOmbud } from '../../../../utils/contactsHelper'
import { Validation } from './model/Validation'

function createError (invalidContacts): ?Array<Validation> {
  const invalidContactsName = invalidContacts.map(contacts => getNamn(contacts))
  if (invalidContacts) {
    return invalidContactsName.map((name) => (new Validation(SOKANDE_MASTE_HA_PERSONNUMMER, `Sökande ${name} saknar personnummer. Ange vilken sökande som ska agera företrädare och skriva under för hens räkning.`, 'contacts', BLOCKING, undefined)))
  }
  return undefined
}

const sokandeUtanPnr = contact => {
  if (!contact.data.rollSokande) {
    return false
  }
  if (contact.type === 'contacts/CONTACT_TYPE_INDIVIDUAL') {
    return !contact.data.personnummer
  }
  return false
}

function validate (state: Object): ?Array<Validation> {
  const contacts = state.contacts.contactList

  const invalidContacts = contacts.filter(sokandeUtanPnr)
  const utanPersNrOchIngaOmbud = invalidContacts.filter(contact => (!harOmbud(contacts, contact.id)))
  if (utanPersNrOchIngaOmbud.length > 0) {
    return createError(utanPersNrOchIngaOmbud)
  }
  return undefined
}

export default validate
