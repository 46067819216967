import { User } from "domain/User";
import { Dispatch, SetStateAction } from "react";
import {
    // @ts-ignore
    Checkbox, Column, FormFieldset, PageSubContent, Row, VarningsRuta, WizardIntorduction, WizardNavigationButtonToolbar
} from "redet-react-components";


type Props = {
    user: User
    arendenummer: string
    meddelande: string
    epostKontakt: string
    telefonKontakt: string
    epostKvittens: string
    godkantVillkor: boolean
    setGodkantVillkor: Dispatch<SetStateAction<boolean>>
    submitSkrivelse: () => Promise<void>
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
    previousStep?: () => void
}

export function ForhandsgranskaStep(props: Props) {

    function validInputsInSkrivelse() {
        return !!(props.user?.fullName && props.meddelande && props.epostKvittens);
    }

    function isNextButtonDisabled() {
        // Function that controls next button disable-prop hence weird conditional statement
        if(props.godkantVillkor) {
            return !validInputsInSkrivelse();
        } return true;
    }

    return <>
        <WizardIntorduction title={'Förhandsgranskning av meddelandet'}>
            <VarningsRuta intro={<p>Den inskickade handlingen kan bli tillgänglig för de som är berörda av ärendet i lantmäterimyndighetens e-tjänst för lantmäteriförrättningar.
                Den inskickade handlingen kan även skickas till de som är berörda av ärendet och till andra myndigheter, samt arkiveras.
                Ändamålet med behandlingen är ärendehandläggning. Ytterligare information om hantering av allmänna handlingar hittar du
                <a title={'här'} href={'https://www.lantmateriet.se/personuppgifter'} rel="external noopener noreferrer" target="_blank"> här</a>.</p>}
                          ariaTitle={"Information om handlingar"}/>
            <PageSubContent headerId={"skrivelseForhandsgranskningId"} headerLevel={2} title={'Förhandsgranskning'}>
                <FormFieldset description={'Jag skickar in följande meddelande till myndigheten'}>
                    {props.user?.fullName &&
                        <Row>
                            <Column data-testid="avsandareId" className={'form-group col-xs-12 col-sm-6 col-xl-12'}>
                                <label className={'label'}>Avsändare</label>
                                <p id={'avsandareId'} className="preview-message">{props.user?.fullName}</p>
                            </Column>
                        </Row>
                    }
                    {props.arendenummer &&
                        <Row>
                            <Column data-testid="arendenummerId" className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>Ärendenummer</label>
                                <p id={'arendenummerId'} className="preview-message">{props.arendenummer}</p>
                            </Column>
                        </Row>
                    }
                    {props.meddelande &&
                        <Row>
                            <Column data-testid="meddelandeId" className={'form-group col-xs-12 col-sm-12 col-xl-12'}>
                                <label className={'label'}>Meddelande</label>
                                <p id={'meddelandeId'} className="preview-message">{props.meddelande.split('\n')}</p>
                            </Column>
                        </Row>
                    }
                    {props.epostKvittens &&
                        <Row>
                            <Column data-testid={"epostKvittensId"} className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>E-post kvittens</label>
                                <p id={'epostKvittensId'} className="preview-message">{props.epostKvittens}</p>
                            </Column>
                        </Row>
                    }
                    {props.epostKontakt &&
                        <Row>
                            <Column data-testid={"epostKontaktId"} className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>E-post kontakt</label>
                                <p id={'epostKontaktId'} className="preview-message">{props.epostKontakt}</p>
                            </Column>
                        </Row>
                    }
                    {props.telefonKontakt &&
                        <Row>
                            <Column data-testid={"telefonnummerId"} className={'form-group col-xs-12 col-sm-4 col-xl-12'}>
                                <label className={'label'}>Telefonnummer kontakt</label>
                                <p id={'telefonnummerId'} className="preview-message">{props.telefonKontakt}</p>
                            </Column>
                        </Row>
                    }
                </FormFieldset>
            </PageSubContent>
            <Checkbox
                id={"godkantVillkorId"}
                label={"Jag har granskat informationen ovan och är medveten om att all information ovan blir allmän handling"}
                isChecked={props.godkantVillkor}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => props.setGodkantVillkor(evt.target.checked)}
            />
        </WizardIntorduction>
        <WizardNavigationButtonToolbar
            prevButtonText={"Ange epost för kvittens"}
            isNextButtonDisabled={isNextButtonDisabled()}
            nextButtonText={"Skicka in"}
            onClickNextButton={() => {
                props.submitSkrivelse();
                if(props.nextStep){props.nextStep()};
                }
            }
            onClickPrevButton={props.previousStep}/>
    </>;
}
export default ForhandsgranskaStep;