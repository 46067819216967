import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {useEffect, useState} from "react";
// @ts-ignore
import {ButtonTile} from "redet-react-components";
import {visaNotisModal} from "./notisReducer";

export function NotisButton() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const notiser = useSelector((store: RootState) => store.notiser.notiser)
    const [antalNotiser, setAntalNotiser] = useState(0)
    const [hasOlastaNotiser, setHasOlastaNotiser] = useState(false)
    useEffect(() => {
        const enabledNotiser = notiser.filter(notis => notis.enabled);
        setAntalNotiser(enabledNotiser.length)
        setHasOlastaNotiser(enabledNotiser.some(notis => notis.unread))
    }, [notiser]);
    return antalNotiser > 0 ?
        <ButtonTile id={"notisNavButton"}
                    onClick={() => dispatch(visaNotisModal())}
                    badgeNumber={antalNotiser}
                    isBadgeUnread={hasOlastaNotiser}
                    text={t("navigation.information")}
                    icon={"info_outline"}/> : null;
}
