import {ARENDE_STATUS, ArendestatusType} from 'utils/globalConstants'
// @ts-ignore
import {ExternalLink} from "redet-react-components";

type Props = {
    status: ARENDE_STATUS
    currentStatus: ARENDE_STATUS
    description: string | JSX.Element
    arendeStatusTypes: ArendestatusType[]
}

function ArendeStatusTile(props: Props){

    const findIndexOfStatus = (status: ARENDE_STATUS) => {
      const arendeStatus = props.arendeStatusTypes.find(arendeStatus => arendeStatus.value === status) || {index: -1};
      return arendeStatus.index
    }

    const isLessThanCurrentStatus = (status: ARENDE_STATUS) => {
      return findIndexOfStatus(props.currentStatus) > findIndexOfStatus(status)
    }

    const isCurrentStatus = (status: ARENDE_STATUS) => {
      return status === props.currentStatus
    }

    const isFinalStatus = (status: ARENDE_STATUS) => {
      return props.arendeStatusTypes.find(arendeStatus => arendeStatus.index === Math.max(...props.arendeStatusTypes.map(arendeStatusSecond => arendeStatusSecond.index)))?.value === status
    }

    const getStatusClassAddition = (status: ARENDE_STATUS) => {
      if (isLessThanCurrentStatus(status) || isFinalStatus(props.currentStatus)) {
        return 'completed'
      } else if (isCurrentStatus(status)) {
        return 'completed active'
      }
      return ''
    }

    return (
      <li className={getStatusClassAddition(props.status)}>
        <header>{props.arendeStatusTypes.find(status => status.value === props.status)?.description}</header>
          <p className="description">{props.description}</p>
      </li>
    )
}

export default ArendeStatusTile