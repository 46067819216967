import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {changeFastigheter, discardChanges, loadFastighetsomraden} from 'redux/modules/map'
import {disableAcceptButton, enableAcceptButton} from '../../redux/modules/modalDialog'
// @ts-ignore
import {Checkbox, ModalDialogSub} from 'redet-react-components'
import TooManyRequestModal from '../../pages/TooManyRequestModal'
import {checkIfResponseIsTooManyRequests} from '../../utils/errorHandler'
import {RootState} from "../../store";
import { useNavigate } from 'react-router-dom'

const mapStateToProps = (store: RootState) => {
    return {
        valdaFastigheter: store.map.fastigheter,
        fastigheter: store.fastigheter.fastigheterList,
        acceptbuttonDisabled: store.modalDialog.disableAcceptButton
    }
}

const mapDispatchToProps = {
    changeFastigheter,
    enableAcceptButton,
    disableAcceptButton,
    loadFastighetsomraden,
    discardChanges
}

export type FastigheterIKartanProps =
    {
        fastigheter: any[],
        valdaFastigheter: string[],
        changeFastigheter: (fastigheter: any[]) => void,
        enableAcceptButton: () => void,
        disableAcceptButton: (text: string) => void,
        onButtonClose: () => void,
        loadFastighetsomraden: (fastigheter: string[]) => Promise<any>,
        discardChanges: () => void,
        tooManyRequestCallback: (hasTooManyRequestsBeenMade: boolean) => void,
        acceptbuttonDisabled: boolean,
        showTooManyRequestModal: boolean
    }


export function FastigheterIKartan(props: FastigheterIKartanProps) {
    const navigate = useNavigate()
    const [userHasSelectedCheckbox, setUserHasSelectedCheckbox] = useState(false)
    useEffect(() => {
        // If user has added a fastighet, force user to make a choice at the start of a new map.
        if (props.fastigheter.length > 0) {
            props.disableAcceptButton('Du måste göra ett val innan du kan gå vidare')
        }
    }, []);

    function toggleSelected(selectedFastighetObjektidentitet?: string) {
        setUserHasSelectedCheckbox(true)
        props.enableAcceptButton()

        let state: any[] = []

        if (selectedFastighetObjektidentitet) {
            if (props.valdaFastigheter.includes(selectedFastighetObjektidentitet)) {
                state = props.valdaFastigheter.filter((selectedRegisterbeteckning) => selectedRegisterbeteckning !== selectedFastighetObjektidentitet)
            } else {
                state = props.valdaFastigheter.concat([selectedFastighetObjektidentitet])
            }
        }
        props.changeFastigheter(state) //ändrar i bakgrundskartan redan här
    }

    function loadFastigheter() {
        props.loadFastighetsomraden(props.valdaFastigheter)
            .then((res) => {
                    const tooManyRequestsError = checkIfResponseIsTooManyRequests(res)
                    if (tooManyRequestsError) {
                        props.tooManyRequestCallback(true)
                    }
                }
            )
    }

    function discardMap() {
        props.discardChanges()
        navigate("../kartskiss", {replace: true});
    }

    const fastigheter = props.fastigheter.map((fastighet, index) => {
        return (
            <Checkbox label={fastighet.registerbeteckning}
                      key={fastighet.data.objektidentitet}
                      id={fastighet.data.objektidentitet}
                      onChange={() => {
                          toggleSelected(fastighet.data.objektidentitet)
                      }}
                      checked={props.valdaFastigheter.some(valdFastighetObjektidentitet => valdFastighetObjektidentitet === fastighet.data.objektidentitet)}/>
        )
    })

    const ingenFastighetSelected = (props.valdaFastigheter.length === 0 && userHasSelectedCheckbox) || props.fastigheter.length === 0

    return (
        <>
            <ModalDialogSub modalTitle={'Välj fastighet'} elementsToAllowFocus={['modal-dialog-id']}
                            closeModalCallback={props.onButtonClose}
                            acceptButton={{
                                text: 'Ok',
                                onClick: loadFastigheter,
                                isDisabled: props.acceptbuttonDisabled
                            }}
                            declineButton={{text: 'Avbryt', onClick: discardMap}}>
                {props.fastigheter.length > 0 &&
                    <p>Genom att bocka i rutan för en fastighetsbeteckning zoomas kartan till fastigheten och denna
                        blir markerad med blått.
                        Vill du själv zooma till rätt läge utifrån Sverigekartan bocka i <strong>Ingen</strong>.
                    </p>}
                {props.fastigheter.length === 0 &&
                    <p>Du har inte lagt till någon fastighet i din ansökan. Stäng denna kartskiss och lägg till en
                        eller flera fastigheter om du vill
                        basera kartskissen på dem.</p>}
                <p className="modal-question">Vilken eller vilka fastigheter vill du basera din kartskiss på?</p>
                <div id="fastighetsval-lista" className="modal-form">
                    {fastigheter}
                    <Checkbox
                        id={'startaMedTomKartaCheckboxId'}
                        name={'Ingen'}
                        label={'Ingen'}
                        checked={ingenFastighetSelected}
                        data-selenium-id="startaMedTomKarta"
                        onChange={() => {
                            toggleSelected(undefined)
                        }}/>
                </div>
            </ModalDialogSub>
            {props.showTooManyRequestModal &&
                <TooManyRequestModal closeModalCallback={()=>{}}/>}
        </>
    )

}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(FastigheterIKartan)
