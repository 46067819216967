import Log from 'utils/log'
import { v4 as uuidv4 } from 'uuid'
import { formatArea, formatLength } from '../measurement.js'
import { getDefaultDrawingStylesWithMeasurementLabels } from '../styles'
import { Draw } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { LineString } from 'ol/geom'

export default class PolygonInteraction {
  constructor (mapController, measureTooltip, setCursorFunc, ol, interactionDestructors, registerCurrentMultipointInteractionFunc, unregisterCurrentMultipointInteractionFunc, featureHandler) {
    this.log = new Log(this.constructor.name)
    this.mapController = mapController
    this.measureTooltip = measureTooltip
    this.setCursorFunc = setCursorFunc
    this.ol = ol
    this.interactionDestructors = interactionDestructors
    this.registerCurrentMultipointInteractionFunc = registerCurrentMultipointInteractionFunc
    this.unregisterCurrentMultipointInteractionFunc = unregisterCurrentMultipointInteractionFunc
    this.featureHandler = featureHandler
  }

  init (drawMeasurements) {
    const interaction = new Draw({
      features: this.featureHandler.getDrawingFeatures(),
      type: 'Polygon',
      style: drawMeasurements ? (feature) => getDefaultDrawingStylesWithMeasurementLabels(feature, this.mapController.getMap()) : undefined
    })

    const drawStart = interaction.on('drawstart',
      (evt) => {
        this.registerCurrentMultipointInteractionFunc(interaction, evt.feature)
        evt.feature.set('drawPolygonMeasurements', drawMeasurements)

        if (!drawMeasurements) {
          evt.feature.getGeometry().on('change', (event) => {
            const geom = event.target
            if (geom.getCoordinates()[0].length > 2) {
              const output = formatArea(geom, this.mapController.getMap().getView().getProjection(), true)
              this.measureTooltip.changeOffset(0, 10)
              this.measureTooltip.updateContentAndLocation(output, geom.getInteriorPoint().getCoordinates())
            } else if (geom.getCoordinates()[0].length === 2) {
              const output = formatLength(new LineString([geom.getCoordinates()[0][0], geom.getCoordinates()[0][1]]), this.mapController.getMap().getView().getProjection(), true)
              this.measureTooltip.updateContentAndLocation(output, geom.getCoordinates()[0][1])
            } else {
              this.measureTooltip.reset()
            }
          }, this)
        }

      })

    const draw = this.mapController.getMap().on('singleclick', () => {
    })

    const drawEnd = interaction.on('drawend', (event) => {
      event.feature.setId(uuidv4())
      if (!drawMeasurements) {
        this.measureTooltip.reset()
      }
      this.unregisterCurrentMultipointInteractionFunc()
    })

    this.interactionDestructors.push(() => {
      this.log.debug('Unregister POLYGON interaction')
      unByKey(drawStart)
      unByKey(draw)
      unByKey(drawEnd)
      this.mapController.getMap().removeInteraction(interaction)
      this.setCursorFunc('auto')
    })

    this.log.debug('Register POLYGON interaction')
    this.mapController.getMap().addInteraction(interaction)

    this.setCursorFunc('crosshair')
  }
}
