import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {createAnsokan, deleteAnsokan} from "redux/modules/ansokanCRUD";
import {useLocation, useNavigate} from "react-router-dom";
import {
    ModalDialogAlert,
    // @ts-ignore
    ButtonToolbar, ButtonTop, Column, ExternalLink, ModalDialogSub, PageContentHeader,
    // @ts-ignore
    SidePanelChapter, VarningsRuta, VideoPlayerModal
} from "redet-react-components";

import Term from "../../views/Hjalp/TermLank";
import {logError} from "../../utils/errorHandler";
import {visaMoment} from "redux/modules/help";
import {AnsokanListState, fetchAnsokanList} from "redux/modules/ansokanList";
import {RootState} from "store";
import {loadArendeList} from "redux/modules/arendeList";
import {Arende} from "domain/Arende";
import Hjalp from "views/Hjalp/Hjalp";
import {KontaktaOssHelpContent} from "views/Hjalp/texter/KontaktaOssHelpContent";
import {PersonuppgifterHelpContent} from "views/Hjalp/texter/PersonuppgifterHelpContent";
import {JumpLinkToMain} from "components/General/JumpLinkToMain";
import {Navigation} from "components"
import {PageHeader} from "components/General/forms/layoutElements";
import {IE11modalDialogWarning} from "pages/Ansokan";
import {Lantmateriforattningfilm} from "components/ansokan/Lantmateriforattningfilm";
import {AnsokanList} from "./components/AnsokanList/AnsokanList";
import {ArendeList} from "./components/ArendeList/ArendeList";
import {ArendeFilter} from "./components/ArendeList/ArendeFilter";
import {ArendeFilterType, DefaultArendeFilter} from "./components/ArendeList/ArendeListFilter";
import {ANSOKAN_STATUS_DRAFT} from "../../utils/globalConstants";
import FilterQueryHelper from "../../utils/filterQueryHelper";
import { FeedbackHelpContent } from "views/Hjalp/texter/FeedbackHelpContent";
import { useAppDispatch } from "hooks";

const MAX_NUMBER_OF_ANSOKNINGAR = 10;

export const Welcome = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const [showTooManyAnsokningarModal, setShowTooManyAnsokningarModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showMovieModal, setMovieModal] = useState(false);
    const [arendeError, setArendeError] = useState(false)

    const isHjalpVisible = useSelector((state: RootState) => state.help.visible)

    // Ansokan
    const ansokanList: AnsokanListState = useSelector((state: RootState) => state.ansokanList)

    // Arende
    const arenden: Arende[] = useSelector((state: RootState) => state.arendeList.list)
    const arendenIsLoading: boolean = useSelector((state: RootState) => state.arendeList.isLoading)

    const [arendeFilter, setArendeFilter] = useState<ArendeFilterType | undefined>(undefined)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (isHjalpVisible) {
            dispatch(visaMoment("help-support", "help-personuppgift", true))
        } else {
            dispatch(visaMoment("help-support", "help-personuppgift", false))
        }

        const filter = FilterQueryHelper.getArendeFilterFromQuery(new URLSearchParams(location.search)) || FilterQueryHelper.getArendeFilterFromStorage() || arendeFilter
        if (filter) {
            setArendeFilter(filter)
            appendFilterQueryToUrl(filter)
        }

        fetchAnsokanListOrLogError();
        fetchArendeList(filter);
    }, []);

    const fetchArendeList = (filter: ArendeFilterType = DefaultArendeFilter) => {
        try {
            dispatch(loadArendeList(filter)).then(() => {
                setArendeError(false);
            }).catch(() => {
                setArendeError(true)
            })
        } catch (error: any) {
            setArendeError(true)
        }
    }

    const fetchAnsokanListOrLogError = () => {
        try {
            dispatch(fetchAnsokanList()).catch((err: any) => {
                logError(err, "Ansokanlist-component")
            })
        } catch (err: any) {
            logError(err, "Ansokanlist-component");
        }
    };

    const createNew = (event: any) => {
        event.preventDefault();
        const drafts = ansokanList.list.filter((item: any) => item.ansokanStatus === ANSOKAN_STATUS_DRAFT)
        if (drafts.length < MAX_NUMBER_OF_ANSOKNINGAR) {
            dispatch(createAnsokan())
                .then((ansokanId: string) => {
                    navigate(`/privat/ansokan/${ansokanId}`+"/start");
                })
                .catch((err: any) => {
                    logError(err, "Welcome-component");
                    setShowErrorModal(true);
                })
        } else {
            setShowTooManyAnsokningarModal(true);
        }
    }

    const deleteAnsokanCallback = (id: string) => {
        dispatch(deleteAnsokan(id)).then(() => fetchAnsokanListOrLogError());
    };

    const dinaAnsokaningarIngress = (
        <>
            Här kan du skapa en ansökan och se de utkast till ansökningar som du ännu
            inte skickat in.
        </>
    );

    const dinaAnsokningarDescription =
        <>
            Här ansöker du om lantmäteriförrättning - Alltså förändring av fastigheter,
            gemensamhetsanläggningar, servitut etc. Observera att ansökan om lagfart
            och inteckning eller inskrivning av avtalsservitut inte skickas in i denna
            tjänst, mer information finns att se här:
            <ExternalLink url={'https://www.lantmateriet.se/sv/fastighet-och-mark/kopa-aga-salja-eller-ge-bort/'}
                                  openInNewTab={true}
                                  text={'Lagfart och inteckning'}/>
            {" "} eller {" "}
            <ExternalLink url={'https://www.lantmateriet.se/sv/fastighet-och-mark/tillgang-till-annans-fastighet/Servitut/ansok-om-inskrivning-av-avtalsservitut/'}
                                  openInNewTab={true}
                                  text={'Servitut'}/>
            <br/>

            Beroende på omfattningen kan din ansökan ta olika lång tid att slutföra,
            men räkna med att det kan ta mer än en timme. Du kan alltid återkomma och
            fortsätta med din ansökan senare.
        </>
        ;
    const pagaendeArendenIngress = (
        <>
            Här kan du se vilka pågående ärenden du är berörd av. De ärenden som visas är lantmäteriförrättningar
            och ärenden hos lantmäteriet för bildande av samfällighetsförening.
            Efter att en ansökan skickats in kommer den diarieföras som ett eller
            flera förättningsärenden. Efter några dagar kan du se dina ärenden och
            ärenden som du berörs av här.
        </>
    );

    const pagaendeArendenDescription = (
        <>
            Uppgifterna om ärendet tas bort 30 dagar efter att ärendet
            slutförts. Handlingarna finns därefter att nå via tjänsten{" "}
            <strong>
                <ExternalLink
                    url={"https://www.lantmateriet.se/sv/fastighet-och-mark/information-om-fastigheter/Min-fastighet/"}
                    text={"Min fastighet"}
                />
            </strong>{" "}
            där du som privatperson kan logga in med e-legitimation. Du kan även vända
            dig till{" "}
            <Term label={"Lantmäteriets kundcenter"} moment={"help-support"} />.
        </>
    );

    const updateFilter = (filter: ArendeFilterType) => {
        appendFilterQueryToUrl(filter)
        fetchArendeList(filter);
    }

    const appendFilterQueryToUrl = (filter: ArendeFilterType) => {
        //create url query params
        const urlQuery = FilterQueryHelper.createUrlQueryString(filter)
        navigate({
            pathname: location.pathname,
            search: urlQuery || ''
        });
    }

    return (
        <>
            <JumpLinkToMain />
            <header>
                <Navigation />
                <PageHeader />
            </header>
            <main id={"dinaAnsokaningarSectionId"}>
                <IE11modalDialogWarning />
                <div className="container">
                    <div className="row flip-xs-sm">
                        <Column columns="col-md-12 col-lg-6">
                            <PageContentHeader
                                title={"Dina ansökningar"}
                                headerLevel={2}
                                headerId={"nyAnsokanRubrikHeaderId"}
                                introduction={dinaAnsokaningarIngress}
                                description={dinaAnsokningarDescription}
                                id={"nyAnsokanHeaderId"}
                            >
                            {showMovieModal &&
                                <VideoPlayerModal closeModalCallback={()=> setMovieModal(false)} ariaLabel={"Informationsfilm om en lantmäteriförrättning."}>
                                <Lantmateriforattningfilm />
                                </VideoPlayerModal>
                            }

                                <ButtonToolbar>
                                    <ButtonTop
                                        id={"ansokan-ny"}
                                        data-selenium-id={"ansokan-ny"}
                                        onClick={createNew}
                                        text="Skapa ny ansökan"
                                        icon="add"
                                    />
                                    <ButtonTop
                                        id={"visa-film"}
                                        text="Visa instruktionsfilm"
                                        icon="play_circle_outline"
                                        onClick={() => setMovieModal(true)}
                                    />
                                </ButtonToolbar>
                            </PageContentHeader>
                            {ansokanList && (
                                <AnsokanList
                                    ansokningarIsLoading={ansokanList.isLoading}
                                    ansokningarList={ansokanList.list}
                                    ansokningarListLoadFailed={ansokanList.failed}
                                    deleteAnsokan={deleteAnsokanCallback}
                                ></AnsokanList>
                            )}
                            <br />
                            <VarningsRuta
                                ariaTitle={"Information om ansökningar"}
                                description={
                                    "Du kan ta bort en ansökan om den inte längre är aktuell så länge du inte har skickat in den." +
                                    " När ansökan är färdigifylld och undertecknad samt ev. bilagor är bestyrkta kan du skicka in den." +
                                    " Ansökan kommer då att behandlas och visas som ett pågående ärende till höger inom några arbetsdagar."
                                }
                            />
                        </Column>
                        <Column
                            columns="col-md-12 col-lg-6"
                            id="lantmateriforattningsarendenSectionId"
                        >
                            <PageContentHeader
                                title={"Dina pågående ärenden"}
                                headerLevel={2}
                                headerId={"arendeRubrikHeaderId"}
                                introduction={pagaendeArendenIngress}
                                description={pagaendeArendenDescription}
                            />

                            <ArendeFilter filter={arendeFilter} onFilterChange={updateFilter} />
                            <ArendeList showArendenLoadingSpinner={arendenIsLoading} arenden={arenden} pageSize={10} error={arendeError} />

                        </Column>
                    </div>
                </div>
                {showTooManyAnsokningarModal && (
                    <ModalDialogSub
                        modalTitle={"För många ansökningar"}
                        description={`Du kan endast ha ${MAX_NUMBER_OF_ANSOKNINGAR} sparade ansökningar. För att skapa en ny måste du först ta bort en befintlig.`}
                        closeModalCallback={() => setShowTooManyAnsokningarModal(false)}
                    />
                )}
                {showErrorModal && (
                    <ModalDialogAlert
                        modalTitle={"Tekniskt fel"}
                        ingress={"Ett tekniskt fel uppstod när en ansökan skulle skapas."}
                        description={
                            <span>
                                Vänligen försök igen om en liten stund. Skulle problemet kvarstå
                                vänligen kontakta{" "}
                                <a href="https://www.lantmateriet.se/kontakt">kundcenter</a>.
                            </span>
                        }
                        acceptButton={{
                            text: "Ok",
                            icon: "check",
                            // @ts-ignore
                            onClick: () => setShowErrorModal(false),
                        }}
                    />
                )}
            </main>
            <Hjalp>
                <SidePanelChapter name={"help-support"}>
                    <KontaktaOssHelpContent/>
                </SidePanelChapter>
                <SidePanelChapter name={"help-personuppgift"}>
                    <PersonuppgifterHelpContent/>
                </SidePanelChapter>
                <SidePanelChapter name={'help-feedback'}>
                    <FeedbackHelpContent/>
                </SidePanelChapter>
            </Hjalp>
        </>
    );
};

export default Welcome
