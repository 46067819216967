import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ModalDialogAlert } from 'redet-react-components'

/**
 * @deprecated Använd istället ModalDialogAlert direkt från RRC.
 */
export class ModalDialogError extends Component {
  static propTypes = {
    modalTitle: PropTypes.string.isRequired,
    button: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      onClickFunction: PropTypes.func
    }),
    ingress: PropTypes.any,
    description: PropTypes.any,
    elementsToAllowFocus: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.any
  }

  static defaultProps = {
    elementsToAllowFocus: [],
    button: {
      text: 'OK',
      icon: 'check'
    }
  }

  render () {
    const { button, ...rest } = this.props
    return (
      <ModalDialogAlert acceptButton={{ text: button.text, icon: button.icon, onClick: button.onClickFunction }}
                        {...rest}/>
    )
  }
}

export default ModalDialogError
