// @flow
// 1.11: Om fler än 1 fakturamottagare finns så ska måste
// fördelningen mellan dessa anges

import { KOSTNADSFORDELNING_MASTE_ANGES, WARNING } from './constants'
import { Validation } from './model/Validation'

function validate (state: Object): ?Validation {
  const contacts = state.contacts.contactList
  const fakturamottagare = contacts.filter(contact => contact.data.rollFakturamottagare)

  if (fakturamottagare.length > 1) {
    const samtligaHarFordelning = fakturamottagare.every(mottagare => mottagare.data.andelAvForattningskostnaden)
    if (!samtligaHarFordelning) {
      return new Validation(KOSTNADSFORDELNING_MASTE_ANGES, 'Du har angett fler än en betalare och inte beskrivit hur kostnaden ska fördelas mellan personerna.', 'contacts', WARNING, undefined)
    }
  }
  return undefined
}

export default validate
