import { contactSorter } from 'helpers/sorters'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    displayContactForm,
    removeContact
} from 'redux/modules/contacts'
import { IndividualContactWizardForm, OrganizationContactWizardForm, Term, ValidationError } from 'views'
import { PageWizardNavigationToolbar } from '../../components/General/PageWizardNavigationToolbar'
import { visaMoment } from '../../redux/modules/help'
import { scrollToMainTag } from '../../utils/scrollToElement'
import { ContactListItem } from './ContactsListItem'
import ImportContactsButton from './ImportContactsButton'
import { Contact, ContactTypes } from 'domain/Contact'
import { useAppDispatch } from 'hooks'
//@ts-ignore
import { ButtonGroup, ButtonToolbarV2, ButtonTop, ModalDialogSub, PageContentHeader } from 'redet-react-components'
import { RootState } from 'store'
import { ValidationTypes } from 'views/validation/ValidationError'
import { PaginationToolbar } from "../../components/General/PaginationToolbar"
import AddManualContactsModal from './AddManualContactModal'

export const Contacts = () => {

    const dispatch = useAppDispatch();

    const contacts: Contact[] = useSelector((store: RootState) => store.contacts.contactList);
    const showContactModal: boolean = useSelector((store: RootState) => store.contacts.showContactModal);
    const ansokanValidations = useSelector((store: RootState) => store.validation.errors);
    const isHjalpVisible = useSelector((state: RootState) => state.help.visible);

    const [contact, setContact] = useState<Contact | undefined>();
    const [contactType, setContactType] = useState<ContactTypes | undefined>();

    const [contactIdToBeDeleted, setContactIdToBeDeleted] = useState<string | undefined>();

    const [showDeleteContactModalState, setShowDeleteContactModalState] = useState(false);
    const [showAddManualContactModalState, setShowAddManualContactModalState] = useState(false);
    const [sliceListStartIndex, setSliceListStartIndex] = useState(0);
    const [sliceListEndIndex, setSliceListEndIndex] = useState<number | undefined>();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        dispatch(displayContactForm(false));
        if (isHjalpVisible) {
            dispatch(visaMoment("help-contactinformation", "Kontaktuppgifter", true))
        } else {
            dispatch(visaMoment("help-contactinformation", "Kontaktuppgifter", false))
        }
        scrollToMainTag();
    }, []);

    const onClickListItemCallback = (contact: Contact) => {
        displayContactModalAndSetState(contact, contact.type)
    }

    const onClickCreateIndividualContactButton = () => {
        displayContactModalAndSetState(undefined, ContactTypes.CONTACT_TYPE_INDIVIDUAL)
    }
    const onClickCreateOrganizationContactButton = () => {
        displayContactModalAndSetState(undefined, ContactTypes.CONTACT_TYPE_ORGANIZATION)
    }

    const displayContactModalAndSetState = (contact: Contact | undefined, contactType: ContactTypes) => {
        setContact(contact);
        setContactType(contactType);
        dispatch(displayContactForm(true));
    }

    const showDeleteContactModal = (show: boolean, contactId?: string) => {
        setShowDeleteContactModalState(show);
        setContactIdToBeDeleted(contactId)
    }

    const showAddManualContactModal = ({show = true}) => {
        setShowAddManualContactModalState(show)
    }

  const setListSliceIndex = (startIndex: number, endIndex: number) => {
    setSliceListStartIndex(startIndex);
    setSliceListEndIndex(endIndex);
  }


    const contactsList = () => {
      return contacts
          .slice()
          .sort(contactSorter)
          .slice(sliceListStartIndex, sliceListEndIndex);
    }

    return (
        <>
            <div id="section-kontaktuppgifter">
                <PageContentHeader
                    title={'Kontaktuppgifter och kostnader'}
                    headerLevel={2}
                    headerId={'contactsHeaderId'}
                    description={
                        <>
                            Här kan du lägga till
                            {' '}
                            <Term
                                label="sökande"
                                moment="help-contactinformation"
                                term="term-rollSokande"
                            />{' '}
                            och andra berörda parter.<br></br>
                            För att lägga till fastighetsägare klickar du på{' '}
                            <strong>Hämta ägare</strong>, de som inte kan hämtas eller som inte är fastighetsägare lägger du till genom att klicka på{' '}
                            <strong>Lägg till part manuellt</strong>.
                        </>
                    }
                >
                    <ButtonToolbarV2>
                        <ButtonGroup>

                            <ImportContactsButton />

                            <ButtonTop
                    text={'Lägg till part manuellt'}
                                icon={'add'}
                                onClick={showAddManualContactModal}
                                id="openAddContactModal"
                                data-selenium-id="openAddContactModal"
                            />
                        </ButtonGroup>
                    </ButtonToolbarV2>
                </PageContentHeader>
                {contacts && contacts.length > 0 &&
            <>
                <div className="list alternating headless" data-selenium-id="kontaktuppgifter-lista"
                    id="kontaktuppgifter-lista">
                    {contactsList()
                        .map((contact, index) =>
                            <ContactListItem key={index}
                                contactList={contacts}
                                contact={contact}
                                ansokanValidations={ansokanValidations}
                                onClickListItemCallback={onClickListItemCallback}
                                onClickRemoveContactButton={showDeleteContactModal} />)}
                </div>
                <PaginationToolbar
                    list={contacts}
                    pageSize={pageSize}
                    listSliceIndexsCallback={setListSliceIndex} />
            </>
                }
                <ValidationError tag={ValidationTypes.CONTACTS} />
                <PageWizardNavigationToolbar buttonLeftText={'Lägg till en kartskiss'} buttonLeftLink={'../kartskiss'}
                    buttonRightText={'Bifoga handlingar'} buttonRightLink={'../handlingar'} />
            </div>
            {showContactModal &&
                <>
                    {contactType === ContactTypes.CONTACT_TYPE_INDIVIDUAL ? (
                        <IndividualContactWizardForm contact={contact} />) : (
                        <OrganizationContactWizardForm contact={contact} />)}
                </>
            }
            {showDeleteContactModalState &&
                <DeleteContactModal onClickJaKnapp={() => dispatch(removeContact({id: contactIdToBeDeleted}))}
                    closeModalCallback={() => showDeleteContactModal(false)} />
            }
            {showAddManualContactModalState &&
                <AddManualContactsModal closeModalCallback={() => showAddManualContactModal({show: false})}
                    onAddPrivatperson={onClickCreateIndividualContactButton}
                    onAddOrganisation={onClickCreateOrganizationContactButton} />
            }
        </>
    )
}

export function DeleteContactModal(props: {closeModalCallback: () => void, onClickJaKnapp: () => void}) {
    return <ModalDialogSub modalTitle="Ta bort kontaktuppgift"
        description="Vill du ta bort den här kontaktuppgiften?"
        acceptButton={{text: 'Ja', onClick: props.onClickJaKnapp}}
        declineButton={{text: 'Nej'}}
        closeModalCallback={props.closeModalCallback}

    />
}

export default Contacts;

