import {DeltarPaSammantrade} from "../../../../domain/Svarslistor";
import {
    //@ts-ignore
    Column, FormFieldset, Row, TextInput, ButtonToolbarV2, ButtonGroup, ButtonTop
} from "redet-react-components";
import React, {ChangeEvent, MouseEvent, useState} from "react";
import {email} from "../../../../utils/validation";
import {validatePhonenumber} from "../../../../utils/telephoneNumberValidation";
import {BekraftaDelgivningContext} from "../BekraftaDelgivningContext";
import DelgivningSelectNarvaroSammantrade from "./DelgivningSelectNarvaroSammantrade";

type Props = {
    //Injectas med clone element från WizardDialogStep :sick!
    nextStep?: () => void
    previousStep?: () => void
}

export function DelgivningKontaktuppgifterStep({previousStep, nextStep}: Props) {

    const {delgivning, epost, setEpost, telefonNr, setTelefonNr, narvaro, setNarvaro} = React.useContext(BekraftaDelgivningContext);

    const [emailValidationMsg, setEmailValidationMsg] = useState<string>()
    const [phoneNoValidationMsg, setPhoneNoValidationMsg] = useState<string>()

    const onNextStepClick = (evt: MouseEvent<HTMLInputElement>) => {
        //validate
        const emailError = email(epost)
        setEmailValidationMsg(emailError ?? '')
        const phoneError = validatePhonenumber(telefonNr)
        setPhoneNoValidationMsg(phoneError ?? '')
        //if valid, go to next step!
        if (nextStep && !emailError && !phoneError)
            nextStep()
    }

    return <>
        {delgivning && <>
            {/*kontaktuppgifter*/}
            <FormFieldset title={'Kontaktuppgifter'}
                          description={'Ange din e-post och telefonnummer (valfritt)'}>
                <div className='form-group'>
                    <Row>
                        <Column columns='col-xs-12 col-md-6'>
                            <TextInput label={'E-post'} isRequired={false}
                                       data-selenium-id={'contact-email-input'}
                                       id={'emailAddress'}
                                       type={'email'}
                                       name={"emailAddress"}
                                       autoComplete={'email'}
                                       maxLength={100}
                                       validationErrorMsg={emailValidationMsg}
                                       value={epost}
                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => setEpost(evt.target.value)}/>
                        </Column>
                        <Column columns='col-xs-12 col-md-6'>
                            <TextInput
                                label={'Telefonnummer'}
                                data-selenium-id={'contact-phone-input'}
                                id={'phoneNo'}
                                name={"Telefonnummer"}
                                autoComplete={'tel'}
                                type={'tel'}
                                validationErrorMsg={phoneNoValidationMsg}
                                value={telefonNr}
                                onChange={(evt: ChangeEvent<HTMLInputElement>) => setTelefonNr(evt.target.value)}
                            />
                        </Column>
                    </Row>
                </div>
            </FormFieldset>
            {/*närvaro sammanträde*/}
            {delgivning.efterfragaDeltagandePaSammantrade && <DelgivningSelectNarvaroSammantrade
                value={narvaro}
                onValueChange={(value:DeltarPaSammantrade) => setNarvaro(value)}/>}
            {/*navigeringsknappar*/}
            <ButtonToolbarV2>
                <ButtonGroup>
                    <ButtonTop text={'Välj delgivning'}
                               onClick={previousStep}
                               icon={'arrow_back'}
                               id={'previous-step-button'}
                               ariaLabel={'Tillbaka till delgivningar'}/>
                </ButtonGroup>
                <ButtonGroup>
                    <ButtonTop text={'Bekräfta'}
                               onClick={onNextStepClick}
                               icon={'arrow_forward'}
                               id={'next-step-button'}
                               ariaLabel={'Vidare till bekräfta'}/>
                </ButtonGroup>
            </ButtonToolbarV2>
        </>}
    </>
}