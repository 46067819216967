import {FeatureToggleNotis} from "../components/featureToggle/FeatureToggle";

export const convertToArrayIfNot = (input: any): any[] => {
    return input.constructor === Array ? input : [input]
}

export const containsOnlyValidCharsForAFastighetsbeteckning = (input: string): boolean => {
    // If there are one or more characters in the input that does not match our allowed list
    if (/([^a-zA-Z0-9åäöéüÅÄÖÉÜ&:\-*\s])+/i.test(input)) {
        return false
    }
    return true
}

export const filterDocumentByAktbeteckning = (documentList: any[]): any[] => {
    return documentList.filter((document) => /^A[^a-zA-Z].*/.test(document.akt))
}

export const filterAnsokningar = (documentList: any[]): any[] => {
    return documentList?.filter(document =>
        document.documentTypeID === 'ANSÖKAN'
    )
}

/*
-1 current före next
1 next före current
0 ingen ändring
 */
export function sortNotiserOfTypeErrorAndUnreadAtTop(notiserBeforeSorted: FeatureToggleNotis[]): FeatureToggleNotis[] {
    notiserBeforeSorted.sort(function (current, next) {
            if (current.metadata.severity?.toLowerCase() === next.metadata.severity?.toLowerCase()) return 0
            if (current.metadata.severity?.toLowerCase() === 'error') return -1
            if (current.metadata.severity?.toLowerCase() === 'warning' && next.metadata.severity?.toLowerCase() === 'information') return -1
            return 1
        }
    )
    notiserBeforeSorted.sort(function (current, next) {
            if (current.unread === next.unread) return 0
            if (current.unread) return -1
            return 1
        }
    )
    return notiserBeforeSorted
}

export const getUniqueListOfDocuments = (list: any[]): any[] => {
    const result: any[] = []
    const map = new Map()
    if(list === undefined) {
        return result;
    }
    for (const item of list) {
        if (!map.has(item.akt)) {
            map.set(item.akt, true)    // set any value to Map
            result.push({
                documentNumber: item.documentNumber,
                documentName: item.documentName,
                publishedDate: item.publishedDate,
                documentPart: item.documentPart,
                akt: item.akt,
                documentTypeID: item.documentTypeID
            })
        }
    }
    return result.sort((docA, docB) => (docA.akt > docB.akt) ? 1 : ((docB.akt > docA.akt) ? -1 : 0))
}